import { Component } from '@angular/core';

@Component({
    selector: 'td-title-skeleton',
    templateUrl: './title-skeleton.component.html',
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
})
export class TitleSkeletonComponent {}
