import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormControlStatus, ValidatorFn, Validators } from '@angular/forms';
import { validateAddress, ValidationResult } from '@taquito/utils';
import { TaquitoTezosDomainsClient } from '@tezos-domains/taquito-client';
import { Subject } from 'rxjs';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { TezosDomainsClientService } from '../tezos/integration/tezos-domains-client.service';
import { TdAsyncValidatorsFactory } from '../utils/form-validators';

@Component({
    selector: 'td-recipient',
    templateUrl: './recipient.component.html',
    styleUrls: ['./recipient.component.scss'],
})
export class RecipientComponent implements OnInit, OnDestroy {
    @Input() control: FormControl | AbstractControl<any>;
    @Input() required: boolean;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() noKT: boolean;

    innerControl: FormControl;
    resolvedAddress: string | null;
    rawValue: string | null;
    resolving: boolean;

    private unsubscribe = new Subject<void>();
    constructor(private asyncValidatorsFactory: TdAsyncValidatorsFactory, private tezosDomainsClientService: TezosDomainsClientService) {}

    ngOnInit(): void {
        const validators: ValidatorFn[] = [];
        if (this.required) {
            validators.push(Validators.required);
        }

        this.innerControl = new FormControl('', validators, this.asyncValidatorsFactory.validRecipient({ disallowKT: this.noKT }));

        this.innerControl.statusChanges
            .pipe(
                takeUntil(this.unsubscribe),
                switchMap(status =>
                    this.tezosDomainsClientService.current.pipe(
                        first(),
                        map(client => [status, client] as [FormControlStatus, TaquitoTezosDomainsClient])
                    )
                )
            )
            .subscribe(async ([status, client]) => {
                this.resolvedAddress = null;

                this.resolving = status === 'PENDING';

                if (this.innerControl.valid) {
                    this.control.setErrors(null);
                    this.rawValue = this.innerControl.value;

                    if (validateAddress(this.innerControl.value) === ValidationResult.VALID) {
                        this.control.setValue(this.innerControl.value);
                    } else {
                        this.control.setValue(null);
                        const address = await client.resolver.resolveNameToAddress(this.innerControl.value);
                        this.resolvedAddress = address;
                        this.control.setValue(address);
                    }
                } else if (this.innerControl.invalid) {
                    this.rawValue = null;
                    this.control.setValue(null);
                    this.control.setErrors({ innerValueInvalid: true });
                }
            });

        this.control.statusChanges.pipe(takeUntil(this.unsubscribe)).subscribe(s => {
            if (s === 'DISABLED') {
                this.innerControl.disable({ emitEvent: false });
            } else {
                this.innerControl.enable({ emitEvent: false });
            }
        });

        setTimeout(() => {
            if (this.innerControl.invalid) {
                this.control.setErrors({ innerValueInvalid: true });
            }
        });
    }

    reset() {
        setTimeout(() => {
            this.innerControl.setValue(null, { emitEvent: false });
            this.innerControl.markAsPristine();
            this.innerControl.markAsUntouched();
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
