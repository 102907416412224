<td-card-skeleton>
    <div class="d-flex flex-column" *ngFor="let i of [].constructor(items); let last = last">
        <div class="avatar-row d-flex align-items-center">
            <ngx-skeleton-loader appearance="circle"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{ height: '40px', width: media.isActive('xs') ? '150px' : '300px' }"></ngx-skeleton-loader>
        </div>
        <ngx-skeleton-loader class="d-flex" [theme]="{ height: '20px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader *ngIf="!last" [theme]="{ height: '1px' }"></ngx-skeleton-loader>
    </div>
</td-card-skeleton>
