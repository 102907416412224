import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import dayjs from 'dayjs';
import 'dayjs/plugin/duration';
import 'dayjs/plugin/utc';

@Component({
    selector: 'td-wait-for-commitment',
    templateUrl: './wait-for-commitment.component.html',
})
export class WaitForCommitmentComponent implements OnInit, OnDestroy {
    @Input() from: dayjs.Dayjs;
    @Input() to: dayjs.Dayjs;
    @Output() done: EventEmitter<void> = new EventEmitter();

    waitProgress: number | null;
    waitTime: string | null;

    private waitPeriod: number;
    private interval: any;

    ngOnInit(): void {
        this.waitPeriod = dayjs.duration(this.to.diff(this.from)).asMilliseconds();

        this.interval = setInterval(() => this.updateTimer(), 1000);
        setTimeout(() => this.updateTimer());
    }

    ngOnDestroy() {
        clearInterval(this.interval);
    }

    private updateTimer() {
        const remaining = dayjs.duration(this.to.diff(dayjs())).asMilliseconds();
        this.waitTime = dayjs.utc(remaining).format('m:ss');

        this.waitProgress = Math.min((100 / this.waitPeriod) * (this.waitPeriod - remaining), 100);

        if (remaining <= 0) {
            clearInterval(this.interval);
            this.waitTime = null;
            this.done.emit();
        }
    }
}
