import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TaquitoTezosDomainsClient } from '@tezos-domains/taquito-client';
import { BigNumber } from 'bignumber.js';
import dayjs from 'dayjs';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, Subscription, combineLatest, interval } from 'rxjs';
import { debounce, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { FilterHistoryStateService } from '../browser/filter-history-state.service';
import { Configuration } from '../configuration';
import { ListOption, ListOptionType } from '../filters/list-filter.component';
import { DateFilter, PriceFilter } from '../filters/model';
import { SortOption } from '../filters/sorter.component';
import { OfferOrderField } from '../graphql/graphql.generated';
import { HorizontalScrollComponent } from '../shared/horizontal-scroll.component';

export interface HotOfferFilterState {
    hotOfferPrice?: PriceFilter | null;
    hotOfferDate?: DateFilter | null;
    hotOfferCategories?: OfferCategory[];
    hotOfferDomain?: string;
    hotOfferSorting?: SortOption;
}

export interface HotOfferFilter {
    price: { from?: BigNumber; to?: BigNumber } | null;
    domainName?: string;
    date: DateFilter;
    categories: OfferCategory[];
    allCategoriesSelected: boolean;
    sorting: SortOption;
}

export enum OfferCategory {
    MoreThanFiveLetters = 'moreThanFiveLetters',
    FourLetters = 'fourLetters',
    ThreeLetters = 'threeLetters',
}

@Component({
    selector: 'td-hot-offer-filter',
    templateUrl: './hot-offer-filter.component.html',
    styleUrls: ['./hot-offer-filter.component.scss'],
})
export class HotOfferFilterComponent implements OnInit, OnChanges, OnDestroy {
    @Input() tzDomainsClient: TaquitoTezosDomainsClient;
    @Output() filterChange = new EventEmitter<HotOfferFilter>();

    @ViewChild(HorizontalScrollComponent) horizontalScroll: HorizontalScrollComponent;

    sortOptions: SortOption[] = [
        { label: this.transloco.translate('hot-offer-list.sort.name-asc'), value: OfferOrderField.DomainName, direction: 'asc' },
        { label: this.transloco.translate('hot-offer-list.sort.name-desc'), value: OfferOrderField.DomainName, direction: 'desc' },
        { label: this.transloco.translate('hot-offer-list.sort.price-asc'), value: OfferOrderField.Price, direction: 'asc' },
        { label: this.transloco.translate('hot-offer-list.sort.price-desc'), value: OfferOrderField.Price, direction: 'desc' },
        { label: this.transloco.translate('hot-offer-list.sort.recent'), value: OfferOrderField.CreatedAt, direction: 'desc' },
        { label: this.transloco.translate('hot-offer-list.sort.oldest'), value: OfferOrderField.CreatedAt, direction: 'asc' },
        { label: this.transloco.translate('hot-offer-list.sort.ending-soon'), value: OfferOrderField.EndsAt, direction: 'asc' },
    ];
    defaultSort = this.sortOptions[4];

    minDate = dayjs(this.config.minEventsFilterDate);
    maxDate = dayjs().endOf('day');

    private categoryFilters = [OfferCategory.MoreThanFiveLetters, OfferCategory.FourLetters, OfferCategory.ThreeLetters];
    categoryOptions: ListOption[] = this.categoryFilters.map(c => this.categoryToOption(c));

    initialDateFilter: DateFilter | null = null;
    initialAddress = '';
    initialDomain = '';
    initialPriceFilter: PriceFilter | null = null;
    initialOfferCategories: OfferCategory[] = [];
    initialSorting = this.defaultSort;

    private price$: BehaviorSubject<PriceFilter | null>;
    private date$: BehaviorSubject<DateFilter | null>;
    private offerCategory$: BehaviorSubject<OfferCategory[]>;
    private domain$: BehaviorSubject<string>;
    private sorting$: BehaviorSubject<SortOption>;

    private formSubscription: Subscription | null = null;

    constructor(private historyStateService: FilterHistoryStateService, private config: Configuration, private transloco: TranslocoService) {}

    ngOnInit(): void {
        const state = this.historyStateService.get<HotOfferFilterState>();

        this.initialDateFilter = state.hotOfferDate ?? null;

        this.initialDomain = state.hotOfferDomain ?? '';
        this.initialPriceFilter = state.hotOfferPrice ?? null;
        this.initialOfferCategories = state.hotOfferCategories ?? [];
        this.initialSorting = state.hotOfferSorting ?? this.defaultSort;

        this.price$ = new BehaviorSubject<PriceFilter | null>(this.initialPriceFilter);
        this.date$ = new BehaviorSubject<DateFilter | null>(this.initialDateFilter);
        this.offerCategory$ = new BehaviorSubject<OfferCategory[]>(this.initialOfferCategories);
        this.domain$ = new BehaviorSubject<string>(this.initialDomain);
        this.sorting$ = new BehaviorSubject<SortOption>(this.initialSorting);

        this.initializeFilter();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes['tzDomainsClient'].isFirstChange()) {
            this.initializeFilter();
        }
    }

    ngOnDestroy(): void {
        this.formSubscription?.unsubscribe();
        this.formSubscription = null;
    }

    priceChanged(data: PriceFilter | null) {
        this.price$.next(data);
    }

    dateChanged(data: DateFilter | null) {
        this.date$.next(data ?? this.initialDateFilter);
    }

    categoriesChanged(data: string[]) {
        this.offerCategory$.next(data.map(x => <OfferCategory>x));
    }

    domainChanged(data: string) {
        this.domain$.next(data);
    }

    sortChange(data: SortOption): void {
        this.sorting$.next(data);
    }

    private initializeFilter() {
        this.formSubscription?.unsubscribe();
        this.formSubscription = combineLatest([this.price$, this.date$, this.offerCategory$, this.domain$, this.sorting$])
            .pipe(
                debounce(() => interval(300)), // easier to unittest
                tap(([price, date, types, domain, sorting]) => this.saveState(price, date, types, domain, sorting)),
                map(([price, date, types, domain, sorting]) => this.toFilterModel(price, date, types, domain, sorting)),
                distinctUntilChanged<HotOfferFilter>(isEqual)
            )
            .subscribe(filter => {
                this.horizontalScroll?.refreshScroll();
                this.filterChange.next(filter);
            });
    }

    private toFilterModel(
        price: PriceFilter | null,
        date: DateFilter | null,
        offerCategories: OfferCategory[],
        domain: string,
        sorting: SortOption
    ): HotOfferFilter {
        const model: HotOfferFilter = {
            domainName: domain,
            categories: offerCategories,
            date: this.getDateFilter(date),
            price: null,
            allCategoriesSelected: !offerCategories.length || offerCategories.length === this.categoryOptions.length,
            sorting,
        };

        if (price && (price.min || price.max)) {
            model.price = {};
            if (price.min) {
                model.price.from = new BigNumber(Number(price.min) * 1e6);
            }

            if (price.max) {
                model.price.to = new BigNumber(Number(price.max) * 1e6);
            }
        }

        return model;
    }

    private getDateFilter(date: DateFilter | null): DateFilter {
        if (!date) {
            return { from: this.minDate };
        }

        return {
            from: date.from.startOf('day'),
            to: date.to?.endOf('day'),
        };
    }

    private categoryToOption(categ: OfferCategory): ListOption {
        return new ListOption(categ, this.translateCategory(categ), ListOptionType.Item);
    }

    private translateCategory(categ: OfferCategory): string {
        /**
         * t(hot-offer-list.category.moreThanFiveLetters)
         * t(hot-offer-list.category.fourLetters)
         * t(hot-offer-list.category.threeLetters)
         */
        return this.transloco.translate(`hot-offer-list.category.${categ}`);
    }

    private saveState(price: PriceFilter | null, date: DateFilter | null, categories: OfferCategory[], domain: string, sorting: SortOption): void {
        this.historyStateService.merge<HotOfferFilterState>({
            hotOfferPrice: price,
            hotOfferDate: date,
            hotOfferCategories: categories,
            hotOfferDomain: domain,
            hotOfferSorting: sorting,
        });
    }
}
