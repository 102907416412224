import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { DomainDetailQuery } from '../graphql/graphql.generated';
import { OperationStatusDoneEvent } from '../shared/operation-status.component';
import { BuyOfferBrokerService } from '../tezos/nft/buy-offer-broker.service';
import { SmartContractOperationEvent } from '../tezos/tezos.service';
import { TdValidators } from '../utils/form-validators';

@Component({
    selector: 'td-place-buy-offer',
    templateUrl: './place-buy-offer.component.html',
    styleUrls: ['./place-buy-offer.component.scss'],
})
export class PlaceBuyOfferComponent implements OnInit {
    private readonly defaultOfferExpiration = 90;

    form: FormGroup<{
        price: FormControl<number>;
        expiration: FormControl<number>;
    }>;
    operation: Observable<SmartContractOperationEvent> | null;

    domain: NonNullable<DomainDetailQuery['domain']>;
    isEdit: boolean;
    success: boolean;
    maxValidOfferExpiration: dayjs.Dayjs | null;
    offerExpiresLate = false;

    fee = 0;
    total = 0;

    constructor(
        private formBuilder: FormBuilder,
        private buyOfferBrokerService: BuyOfferBrokerService,
        @Inject(MAT_DIALOG_DATA) private data: { domain: NonNullable<DomainDetailQuery['domain']> },
        private dialogRef: MatDialogRef<PlaceBuyOfferComponent>,
        private config: Configuration
    ) {}

    ngOnInit() {
        this.domain = this.data.domain;
        this.maxValidOfferExpiration = this.calculateMaxValidExpiration(this.domain);
        this.offerExpiresLate = dayjs().add(this.defaultOfferExpiration, 'days').isAfter(this.maxValidOfferExpiration);

        this.form = this.formBuilder.group({
            price: this.formBuilder.control(0, {
                nonNullable: true,
                validators: [Validators.required, TdValidators.number(6), Validators.min(1)],
            }),
            expiration: this.formBuilder.control(this.defaultOfferExpiration, { nonNullable: true, validators: [TdValidators.number()] }),
        });

        this.form.controls.price.valueChanges.subscribe(price => {
            const totalPrice = this.priceWithFee(price);
            this.fee = totalPrice.fee.toNumber();
            this.total = totalPrice.total.toNumber();
        });

        this.form.controls.expiration.valueChanges.subscribe(val => {
            this.offerExpiresLate = dayjs().add(val, 'days').isAfter(this.maxValidOfferExpiration);
        });
    }

    operationDone(event: OperationStatusDoneEvent) {
        if (event.success) {
            this.dialogRef.updateSize('600px', '300px');
            this.success = true;
        } else {
            this.form.enable();
        }
    }

    cancel() {
        this.dialogRef.close(false);
    }

    continue() {
        this.dialogRef.close(true);
    }

    save() {
        const params = {
            price: new BigNumber(this.form.value.price!).times(1e6).toNumber(),
            priceWithFee: new BigNumber(this.total).toNumber(),
            expiration: this.form.value.expiration ? dayjs().add(this.form.value.expiration, 'days').toDate() : null,
            tokenId: this.domain.tokenId!,
        };

        this.form.disable();

        this.operation = this.buyOfferBrokerService.placeOffer(params);
    }

    private priceWithFee(price: number): { fee: BigNumber; total: BigNumber } {
        const p = new BigNumber(price).times(1e6);
        const fee = p.multipliedBy(25_000).dividedBy(1e6);
        const total = p.plus(fee);

        return { fee, total };
    }

    private calculateMaxValidExpiration(domain: NonNullable<DomainDetailQuery['domain']>): dayjs.Dayjs | null {
        if (!domain.expires) {
            return null;
        }

        return domain.expires.subtract(this.config.domainIsExpiringThreshold.time, this.config.domainIsExpiringThreshold.units);
    }
}
