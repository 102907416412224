<ng-container *transloco="let t">
    <td-address-page scope="all" #page>
        <ng-container *ngIf="page.address === page.wallet?.address">
            <h2>{{ t('favourites.page-title') }}</h2>
            <td-favourites-table
                class="smooth-shadow"
                (loaded)="loadedFavouritesCount = $event"
                (loading)="loading = $event"
                [hidden]="!loadedFavouritesCount && !loading"
            ></td-favourites-table>
            <td-alert [state]="'info'" *ngIf="loadedFavouritesCount === 0 && !loading"> {{ t('favourites.no-favs') }} </td-alert>
        </ng-container>
    </td-address-page>
</ng-container>
