import { FavouritesModule } from '@/favourites/favourites.module';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AuctionsModule } from '../auctions/auctions.module';
import { BuyOffersModule } from '../buy-offers/buy-offers.module';
import { CtaModule } from '../cta/cta.module';
import { DomainCommonModule } from '../domain-common/domain-common.module';
import { ErrorModule } from '../error/error.module';
import { FiltersModule } from '../filters/filters.module';
import { LayoutModule } from '../layout/layout.module';
import { NotificationsModule } from '../notifications/notifications.module';
import { SharedModule } from '../shared/shared.module';
import { SkeletonModule } from '../skeleton/skeleton.module';
import { AuctionComponent } from './auction.component';
import { BidComponent } from './bid.component';
import { BulkBuyComponent } from './bulk-registration/bulk-buy.component';
import { BulkDomainItemComponent } from './bulk-registration/bulk-domain-item.component';
import { BulkFilterComponent } from './bulk-registration/bulk-filter.component';
import { RegisterComponent } from './bulk-registration/register.component';
import { BulkRenewComponent } from './bulk-renew.component';
import { BuyComponent } from './buy.component';
import { DnsBridgeComponent } from './dns-bridge/dns-bridge.component';
import { DnsVerifyApiService } from './dns-bridge/dns-verify-api.service';
import { DomainDetailComponent } from './domain-detail.component';
import { DomainListComponent } from './domain-list.component';
import { DomainRecordFormComponent } from './domain-record-form.component';
import { DomainSummaryTableComponent } from './domain-summary-table.component';
import { DomainTableComponent } from './domain-table.component';
import { EditWebsiteComponent } from './edit-website.component';
import { ExpiredDomainsFilterComponent } from './expired-domains-filter.component';
import { ExpiredDomainsTableComponent } from './expired-domains-table.component';
import { ExpiredDomainsComponent } from './expired-domains.component';
import { ExpiringDomainsWarningComponent } from './expiring-domains-warning.component';
import { LatestExpiredDomainsComponent } from './latest-expired-domains.component';
import { OperatorBadgeComponent } from './operator-badge.component';
import { OperatorPermissionsComponent } from './operator-permissions/operator-permissions.component';
import { OperatorSearchComponent } from './operator-permissions/operator-search.component';
import { RenewComponent } from './renew.component';
import { SearchResultsComponent } from './search-results.component';
import { SettleComponent } from './settle.component';
import { TransferOwnershipComponent } from './transfer-ownership.component';
import { WaitForCommitmentComponent } from './wait-for-commitment.component';
import { BulkTransferComponent } from './bulk-transfer.component';
import { TransferWarningComponent } from './transfer-warning.component';

@NgModule({
    declarations: [
        DomainListComponent,
        DomainDetailComponent,
        DomainRecordFormComponent,
        BuyComponent,
        WaitForCommitmentComponent,
        RenewComponent,
        DomainTableComponent,
        DomainSummaryTableComponent,
        AuctionComponent,
        BidComponent,
        SettleComponent,
        TransferOwnershipComponent,
        EditWebsiteComponent,
        SearchResultsComponent,
        BulkRenewComponent,
        ExpiringDomainsWarningComponent,
        OperatorPermissionsComponent,
        OperatorSearchComponent,
        OperatorBadgeComponent,
        ExpiredDomainsTableComponent,
        ExpiredDomainsComponent,
        LatestExpiredDomainsComponent,
        ExpiredDomainsFilterComponent,
        DnsBridgeComponent,
        BulkFilterComponent,
        BulkDomainItemComponent,
        BulkBuyComponent,
        RegisterComponent,
        BulkTransferComponent,
        TransferWarningComponent,
    ],
    providers: [DnsVerifyApiService],
    imports: [
        SharedModule,
        ReactiveFormsModule,
        ErrorModule,
        CtaModule,
        LayoutModule,
        AuctionsModule,
        NotificationsModule,
        SkeletonModule,
        FiltersModule,
        BuyOffersModule,
        DomainCommonModule,
        FavouritesModule,
    ],
    exports: [DomainSummaryTableComponent, ExpiringDomainsWarningComponent, LatestExpiredDomainsComponent],
})
export class DomainsModule {}
