<div class="d-flex flex-column flex-fill h-100" *transloco="let t">
    <mat-dialog-content class="d-flex flex-column flex-grow-1">
        <td-alert [state]="'danger'">
            {{ t('airdrop.constitution.reject-message') }}
        </td-alert>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex justify-content-end align-items-end w-100 min-height-auto">
        <button mat-flat-button (click)="cancel()">{{ t('actions.cancel') }}</button>
        <button mat-flat-button color="warn" (click)="ok()">{{ t('actions.reject') }}</button>
    </mat-dialog-actions>
</div>
