import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { Configuration } from '../configuration';
import { PoolStats } from './models';

@Component({
    selector: 'td-widget-apr',
    templateUrl: './widget-apr.component.html',
    styleUrls: ['./widget-apr.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetAprComponent {
    private readonly config = inject(Configuration);

    @Input() vm: PoolStats;

    readonly showAverageApr = this.config.governance.showAverageApr;
}
