import { Component, Input, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import { encode } from 'js-base64';

import { EventsRecord } from '../graphql/events-table-data-source';
import { getEventType } from './utils';

@Component({
    selector: 'td-event-text',
    templateUrl: './event-text.component.html',
    styleUrls: ['./event-text.component.scss'],
})
export class EventTextComponent implements OnInit {
    @Input() event: EventsRecord;
    @Input() scope: string;
    @Input() povAddress?: string;
    domainName: string | null;
    duration: plugin.Duration | null;
    hash: string | null;
    amount: number | null;
    address: string | null;
    reverseRecord: string | null;
    tokenId: number | null;
    type: string;

    ngOnInit() {
        if ('durationInDays' in this.event) {
            this.duration = dayjs.duration(this.event.durationInDays, 'day');
        }

        if ('maybeDurationInDays' in this.event && this.event.maybeDurationInDays) {
            this.duration = dayjs.duration(this.event.maybeDurationInDays, 'day');
        }

        if ('domainName' in this.event) {
            this.domainName = this.event.domainName;
        }

        if ('maybeDomainName' in this.event) {
            this.domainName = this.event.maybeDomainName || null;
        }

        if ('name' in this.event) {
            this.domainName = this.event.name || null;
        }

        if ('operationGroupHash' in this.event) {
            this.hash = this.event.operationGroupHash || null;
        }

        if ('amount' in this.event) {
            this.amount = this.event.amount?.toNumber() || null;
        }

        if ('tokenId' in this.event) {
            this.tokenId = this.event.tokenId;
        }

        if ('secondaryAddress' in this.event) {
            if (this.event.secondaryAddress === this.povAddress) {
                this.address = this.event.sourceAddress;
                this.reverseRecord = this.convertReverseRecord(this.event.sourceAddressReverseRecord);
            } else {
                this.address = this.event.secondaryAddress;
            }

            if ('secondaryReverseRecord' in this.event) {
                if (this.event.secondaryAddress !== this.povAddress) {
                    this.reverseRecord = this.convertReverseRecord(this.event.secondaryReverseRecord);
                }
            }
        }

        this.type = getEventType(this.event, this.povAddress);
    }

    private convertReverseRecord(reverseRecord: any): string | null {
        if (reverseRecord) {
            return 'B64:' + encode(JSON.stringify(reverseRecord));
        } else {
            return null;
        }
    }
}
