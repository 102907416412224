<form class="d-flex flex-column flex-fill h-100" *ngIf="form" [formGroup]="form">
    <h1 mat-dialog-title class="text-truncate flex-shrink-0">Send funds</h1>
    <mat-dialog-content class="d-flex flex-column flex-grow-1">
        <td-operation-status [operation]="operation" (done)="operationDone($event)" #op></td-operation-status>
        <div class="d-flex flex-column gap-2">
            <mat-form-field appearance="outline">
                <mat-label>Recipient</mat-label>
                <input formControlName="to" placeholder="Domain or address to send funds to" matInput />
                <mat-error><td-validation-messages [control]="form.get('to')!" [context]="{ name: 'Recipient' }"></td-validation-messages></mat-error>
            </mat-form-field>
            <td-alert class="mb-4" state="info" *ngIf="resolvedAddress"> Resolved address: {{ resolvedAddress }} </td-alert>
            <mat-form-field appearance="outline">
                <mat-label>Amount (XTZ)</mat-label>
                <input formControlName="amount" placeholder="Amount to send" matInput type="number" />
                <mat-error><td-validation-messages [control]="form.get('amount')!" [context]="{ name: 'Amount' }"></td-validation-messages></mat-error>
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex justify-content-end align-items-end w-100 min-height-auto">
        <button mat-flat-button color="primary" (click)="send()" [disabled]="!form.valid || op.inProgress">Send</button>
        <button mat-button (click)="cancel()" [disabled]="op.inProgress">Cancel</button>
    </mat-dialog-actions>
</form>
