import { Component } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable, of } from 'rxjs';
import { first, map, shareReplay, switchMap } from 'rxjs/operators';
import { TezosService } from '../tezos/tezos.service';
import { StorageSchema } from '../utils/storage';
import { ClaimInfoService } from './claim-info.service';
import { ClaimMeta, ClaimStatus } from './models';

@Component({
    selector: 'td-blocked-user',
    templateUrl: './blocked-user.component.html',
    styleUrls: ['./blocked-user.component.scss'],
})
export class BlockedUserComponent {
    vm$: Observable<{ blocked: boolean; meta?: ClaimMeta }>;

    constructor(claimInfo: ClaimInfoService, private tezosService: TezosService, private storageMap: StorageMap) {
        this.vm$ = tezosService.activeWallet.pipe(
            switchMap(w => {
                if (!w) {
                    return of(null);
                }
                const storage = StorageSchema.airdropBlockedNotificationStatus(w!.address);

                return storageMap
                    .watch<boolean>(storage.key, storage.schema)
                    .pipe(switchMap(airdropBlockIgnored => (airdropBlockIgnored ? of(null) : claimInfo.canClaimTokens(w.address))));
            }),
            map(state => ({
                blocked: state?.status === ClaimStatus.Blocked,
                meta: state?.meta,
            })),
            shareReplay({ refCount: true, bufferSize: 1 })
        );
    }

    dismiss() {
        this.tezosService.activeWallet.pipe(first()).subscribe(w => {
            const storage = StorageSchema.airdropBlockedNotificationStatus(w!.address);
            this.storageMap.set(storage.key, true).subscribe();
        });
    }
}
