import { Injectable, inject } from '@angular/core';
import { chunk, cloneDeep, flatten } from 'lodash-es';
import { Observable, of, zip } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { DelegateGQL, DelegatesGQL, DelegatesQueryVariables, Order_By } from '../governance-core/governance-graphql.generated';
import { ReverseRecordListGQL } from '../graphql/graphql.generated';
import { BlockedDelegatesService } from './blocked-delegates.service';
import { Delegate } from './models';

@Injectable({
    providedIn: 'root',
})
export class DelegatesFinderService {
    private blockedDelegatesService = inject(BlockedDelegatesService);
    private reverseRecordListGQL = inject(ReverseRecordListGQL);
    private delegatesGql = inject(DelegatesGQL);
    private delegateGql = inject(DelegateGQL);

    loadReverseRecords(addresses: string[]) {
        if (!addresses.length) {
            return of([]);
        }

        const list = chunk(addresses, 50).map(addressList =>
            this.reverseRecordListGQL
                .fetch({ where: { address: { in: addressList } }, first: 50 })
                .pipe(map(({ data }) => data.reverseRecords.edges.map(e => e.node)))
        );

        return zip(...list).pipe(
            map(lists => flatten(lists)),
            filter(x => !!x)
        );
    }

    loadTopPublicDelegates(): Observable<Delegate[]> {
        return this.loadDelegates({
            where: {
                public: { _eq: true },
            },
            order_by: {
                voting_power: Order_By.DescNullsLast,
            },
            limit: 200,
        });
    }

    private loadDelegates(query: DelegatesQueryVariables): Observable<Delegate[]> {
        return this.blockedDelegatesService.blockedDelegates$.pipe(
            switchMap(blockedDelegates => {
                const q = cloneDeep(query);

                q.where = q.where || {};
                q.where.address = q.where.address || {};

                if (q.where.address._nin) {
                    throw new Error('NotIn filter on Address is not yet supported - code needs to be updated');
                }

                q.where.address._nin = blockedDelegates;

                return this.delegatesGql.fetch(q).pipe(map(({ data }) => data.delegates));
            })
        );
    }

    loadDelegate(address: string): Observable<Delegate | null> {
        return this.delegateGql.fetch({ address }, { fetchPolicy: 'network-only' }).pipe(map(({ data }) => data.delegates?.[0] ?? null));
    }
}
