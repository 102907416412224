import { Component, HostListener, inject } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BulkDomainsDataService } from './file-data.service';
import { FileDragService } from './file-drag.service';

@UntilDestroy()
@Component({
    selector: 'td-file-drag',
    templateUrl: './file-drag.component.html',
    styleUrls: ['./file-drag.component.scss'],
})
export class FileDragComponent {
    private fileData = inject(BulkDomainsDataService);
    private fileDrag = inject(FileDragService);
    private router = inject(Router);

    isDragOverElement = false;

    @HostListener('window:dragover', ['$event'])
    @HostListener('dragover', ['$event'])
    onOverElement(e: DragEvent) {
        if (!e.dataTransfer) return;

        e.preventDefault();
        e.stopPropagation();

        this.isDragOverElement = true;
        this.fileDrag.setOverWindow(true);
    }

    @HostListener('dragleave', ['$event'])
    onLeaveElement(e: DragEvent) {
        if (!e.dataTransfer) return;

        e.preventDefault();
        e.stopPropagation();

        this.isDragOverElement = false;
        this.fileDrag.setOverWindow(false);
    }

    @HostListener('drop', ['$event'])
    async onDrop(e: DragEvent) {
        if (!e.dataTransfer) return;

        e.preventDefault();
        e.stopPropagation();

        this.isDragOverElement = false;

        const { isValid, file } = this.hasValidFile(e);

        if (!isValid || !file) return;

        await this.fileData.setDomains(file);
        this.fileDrag.setOverWindow(false);

        this.router.navigate(['multi-registration']);
    }

    private hasValidFile(e: DragEvent) {
        if (!e.dataTransfer) return { isValid: false, file: null };

        const transferTypes = e.dataTransfer.types;
        const items = e.dataTransfer.items;

        const isFile = transferTypes.includes('Files');
        if (!items.length) {
            return { isValid: false };
        }
        const isValid = isFile && Boolean(items.length && items[0].type.includes('text/'));

        if (!isValid) {
            return { isValid, file: null };
        }

        const file = items[0].getAsFile();

        return { isValid, file };
    }
}
