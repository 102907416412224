import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Observable, ReplaySubject, concat, interval } from 'rxjs';
import { filter, first, map, pairwise } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { WindowRef } from './browser/window-ref';
import { TezosBeaconWalletManager } from './tezos/connector/tezos-wallet-manager';

export interface AppVersion {
    app: {
        branch: string;
        hash: string;
        version: string;
    };
    dependencies: { [dep: string]: string };
}

declare global {
    interface Window {
        VERSION: AppVersion;
    }
}

@Injectable({ providedIn: 'root' })
export class AppService {
    get updateAvailable(): Observable<VersionReadyEvent> {
        return this.updates.versionUpdates.pipe(
            filter(x => x.type === 'VERSION_READY'),
            map(x => <VersionReadyEvent>x)
        );
    }

    get version(): AppVersion {
        return this.windowRef.nativeWindow['VERSION'];
    }

    get timezone(): string {
        return this.windowRef.nativeWindow.Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    private onlineStream = new ReplaySubject<boolean>(1);
    get online(): Observable<boolean> {
        return this.onlineStream;
    }

    get isOnline() {
        return this.windowRef.nativeWindow.navigator.onLine;
    }

    get goingOnlineFromOffline(): Observable<boolean> {
        return this.online.pipe(
            pairwise(),
            filter(([prev, curr]) => !prev && curr),
            map(() => true)
        );
    }

    constructor(
        appRef: ApplicationRef,
        private updates: SwUpdate,
        private windowRef: WindowRef,
        private http: HttpClient,
        private tezosBeaconWalletManager: TezosBeaconWalletManager
    ) {
        this.onlineStream.next(this.windowRef.nativeWindow.navigator.onLine);
        this.checkConnection();

        this.windowRef.nativeWindow.addEventListener('online', () => this.checkConnection());
        this.windowRef.nativeWindow.addEventListener('offline', () => this.onlineStream.next(false));

        if (updates.isEnabled) {
            const stable = appRef.isStable.pipe(first(isStable => isStable === true));
            const checkInterval = interval(6 * 60 * 60 * 1000);

            // Allow the app to stabilize first, before starting polling for updates with `interval()`.
            concat(stable, checkInterval).subscribe(() => updates.checkForUpdate());
        }
    }

    applyUpdate() {
        this.updates.activateUpdate().then(() => this.windowRef.nativeWindow.location.reload());
    }

    openConnect(successCallback?: () => void) {
        this.tezosBeaconWalletManager.connect().pipe(first()).subscribe(successCallback);
    }

    async checkForUpdate() {
        if (this.updates.isEnabled) {
            await this.updates.checkForUpdate();
        }
    }

    checkForConnection() {
        this.checkConnection();
    }

    private checkConnection() {
        this.http.head('/?ngsw-bypass').subscribe({
            next: () => this.onlineStream.next(true),
            error: () => this.onlineStream.next(false),
        });
    }
}
