<div class="d-flex flex-column flex-fill h-100" *transloco="let t">
    <h1 mat-dialog-title class="text-truncate flex-shrink-0">{{ t('withdraw.title') }}</h1>
    <mat-dialog-content class="d-flex flex-column flex-grow-1">
        <td-operation-status class="flex-shrink-0" [operation]="operation" (done)="operationDone($event)" #op></td-operation-status>
        <div>{{ t('withdraw.info') }}</div>
        <mat-form-field appearance="standard" class="permanently-disabled">
            <mat-label>{{ t('fields.recipient') }}</mat-label>
            <input disabled matInput [value]="wallet.address" required />
        </mat-form-field>

        <td-money-input [label]="t('fields.amount')" [control]="control" [displayOnly]="true"></td-money-input>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex justify-content-end align-items-end w-100 min-height-auto">
        <button mat-flat-button color="primary" (click)="withdraw()" [disabled]="op.inProgress">
            {{ t('actions.withdraw') }}
        </button>
        <button mat-button (click)="cancel()" [disabled]="op.inProgress">{{ t('actions.cancel') }}</button>
    </mat-dialog-actions>
</div>
