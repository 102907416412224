<ng-container *transloco="let t">
    <!-- 
        t(app.dark)
        t(app.light)
     -->

    <button mat-icon-button class="lg no-underline" [matTooltip]="t(tooltip)" (click)="toggleTheme()">
        <fa-icon [icon]="themeStateIcon" size="lg"></fa-icon>
    </button>
</ng-container>
