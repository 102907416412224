import { Injectable, Type } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { StandardRecordMetadataKey } from '@tezos-domains/core';

import { ContentUrlComponent } from './editors/content-url.component';
import { EhtAddressComponent } from './editors/eth-address.component';
import { GovernanceUrlComponent } from './editors/governance-url.component';
import { GravatarComponent } from './editors/gravatar.component';
import { JsonComponent } from './editors/json.component';
import { NumberComponent, NumberEditorArgs } from './editors/number.component';
import { StringComponent, StringEditorArgs } from './editors/string.component';

export interface DataFieldEditorDescriptor {
    key: string;
    displayName: string;
    editorComponent: Type<any>;
    editorComponentArgs?: any;
}

@Injectable({ providedIn: 'root' })
export class DataFieldsService {
    private fields: Map<string, DataFieldEditorDescriptor> = new Map();

    constructor(private translation: TranslocoService) {
        /**
         * t(data.keys.td:ttl)
         * t(data.keys.openid:name)
         * t(data.keys.openid:email)
         * t(data.keys.openid:nickname)
         * t(data.keys.openid:website)
         * t(data.keys.openid:address)
         * t(data.keys.gravatar:hash)
         * t(data.keys.twitter:handle)
         * t(data.keys.instagram:handle)
         * t(data.keys.github:username)
         * t(data.keys.gitlab:username)
         * t(data.keys.keybase:username)
         * t(data.keys.project:repository_url)
         * t(data.keys.web:content_url)
         * t(data.keys.web:redirect_url)
         * t(data.keys.web:governance_profile_url)
         * t(data.keys.etherlink:address)
         */
        this.registerFieldEditor(StandardRecordMetadataKey.ETHERLINK_ADDRESS, EhtAddressComponent);
        this.registerNumberField(StandardRecordMetadataKey.TTL, { min: 0 });
        this.registerStringField(StandardRecordMetadataKey.OPENID_NAME);
        this.registerStringField(StandardRecordMetadataKey.OPENID_NICKNAME);
        this.registerStringField(StandardRecordMetadataKey.OPENID_EMAIL, { type: 'email' });
        this.registerStringField(StandardRecordMetadataKey.OPENID_WEBSITE, { type: 'url' });
        this.registerJsonField(StandardRecordMetadataKey.OPENID_ADDRESS);
        this.registerFieldEditor(StandardRecordMetadataKey.GRAVATAR_HASH, GravatarComponent);
        this.registerStringField(StandardRecordMetadataKey.TWITTER, { type: 'twitter', prefix: '@' });
        this.registerStringField(StandardRecordMetadataKey.INSTAGRAM);
        this.registerStringField(StandardRecordMetadataKey.GITHUB_PROFILE);
        this.registerStringField(StandardRecordMetadataKey.GITLAB_PROFILE);
        this.registerStringField(StandardRecordMetadataKey.KEYBASE);
        this.registerStringField(StandardRecordMetadataKey.REPOSITORY_URL, { type: 'url' });
        this.registerFieldEditor(StandardRecordMetadataKey.WEB_CONTENT_URL, ContentUrlComponent);
        this.registerStringField(StandardRecordMetadataKey.WEB_REDIRECT_URL, { type: 'url' });
        this.registerFieldEditor(StandardRecordMetadataKey.GOVERNANCE_PROFILE_URL, GovernanceUrlComponent);
    }

    getFields(): Map<string, DataFieldEditorDescriptor> {
        return this.fields;
    }

    registerFieldEditor(key: string, editorComponent: Type<any>, editorComponentArgs?: any) {
        const descriptor: DataFieldEditorDescriptor = {
            key,
            displayName: this.translation.translate(`data.keys.${key}`),
            editorComponent,
            editorComponentArgs,
        };

        this.fields.set(key, descriptor);
    }

    registerNumberField(key: string, args?: NumberEditorArgs) {
        this.registerFieldEditor(key, NumberComponent, args);
    }

    registerStringField(key: string, args?: StringEditorArgs) {
        this.registerFieldEditor(key, StringComponent, args);
    }

    registerJsonField(key: string) {
        this.registerFieldEditor(key, JsonComponent);
    }
}
