<ng-container *transloco="let t">
    <div class="buy stepper d-flex flex-column" *ngIf="notificationSubscriptionVisible$ | async; let notificationModel">
        <h2 [tdDynamicHtml]="t('buy-bulk.title', { price: pricePerYear })"></h2>
        <td-alert state="warning" *ngIf="showDomainNameLengthWarning" class="mb-3">
            {{ t('buy-bulk.long-domain-name-warning', {tld, length:configuration.maxWebsiteDomainNameLength }) }}
        </td-alert>
        <h3>{{ t('buy-bulk.sub-title', { count: names.length }) }}</h3>
        <mat-stepper orientation="vertical" [disableRipple]="true" #stepper [linear]="true" [@.disabled]="!stepperAnimation">
            <mat-step [editable]="false" [completed]="phase > phases.COMMIT">
                <ng-template matStepLabel>{{ t('buy-bulk.step-1-title') }}</ng-template>
                <td-operation-status [operation]="commitOperation" (done)="commitDone($event)" [largeRequest]="true" #commitOp></td-operation-status>
                <div class="d-flex flex-column flex-sm-row gap-2">
                    <div class="description">
                        {{ t('buy-bulk.step-1-description') }}
                    </div>
                    <div class="d-flex flex-column action">
                        <button
                            mat-flat-button
                            (click)="commit()"
                            [disabled]="phase !== phases.COMMIT || commitOp.inProgress || commitOp.success"
                            color="primary"
                        >
                            {{ phase === phases.INIT ? t('buy-bulk.checking-for-commit') : t('actions.commit') }}
                        </button>

                        <td-wait-for-commitment
                            class="mt-3"
                            *ngIf="waitUntil"
                            [from]="waitFrom"
                            [to]="waitUntil"
                            (done)="setNextPhase(notificationModel.visible)"
                        ></td-wait-for-commitment>
                    </div>
                </div>
            </mat-step>
            <mat-step [editable]="false" [completed]="phase > phases.BUY">
                <ng-template matStepLabel>{{ t('buy-bulk.step-2-title') }}</ng-template>
                <td-operation-status
                    [operation]="buyOperation"
                    (done)="buyDone($event, notificationModel.visible)"
                    [largeRequest]="true"
                    #buyOp
                ></td-operation-status>
                <div class="d-flex flex-column flex-sm-row gap-2">
                    <div class="description">
                        {{ t('buy-bulk.step-2-description') }}
                    </div>
                    <form class="d-flex flex-column gap-2 action" [formGroup]="buyForm">
                        <mat-form-field appearance="standard">
                            <mat-label>{{ t('fields.registration-period') }}</mat-label>
                            <input formControlName="duration" type="number" matInput required min="1" max="100" />
                            <span matSuffix>{{ t('general.years') }}</span>
                            <mat-error>
                                <td-validation-messages [control]="buyForm.get('duration')!" [context]="{ name: t('fields.registration-period') }">
                                </td-validation-messages>
                            </mat-error>
                        </mat-form-field>
                        <td-money-input [label]="t('general.price')" [control]="buyForm.get('price')!" [displayOnly]="true"></td-money-input>
                        <td-address-editor
                            [control]="buyForm.get('address')!"
                            [label]="t('fields.domain-address')"
                            [placeholder]="t('fields.address-placeholder')"
                        ></td-address-editor>
                        <button mat-flat-button (click)="buy()" [disabled]="buyForm.invalid || buyOp.inProgress" color="primary">
                            {{ t('actions.register') }}
                        </button>
                    </form>
                </div>
            </mat-step>
            <mat-step [editable]="false" *ngIf="notificationModel.visible" [completed]="phase > phases.SUBSCRIBE">
                <ng-template matStepLabel>{{ t('buy-bulk.step-3-title') }}</ng-template>

                <td-subscribe [outsideDialog]="true" (cancel)="skipSubscription()" [showTitle]="false" [from]="notificationSource">
                    <td-subscribe-button
                        [buttonText]="t('buy-bulk.alternative-notification')"
                        [allowedNotificationProviders]="['google', 'ics', 'office']"
                        [event]="subscriptionEvent"
                        [buttonStyle]="{ color: 'basic', stroked: true }"
                        (calendarOptionSelected)="skipSubscription()"
                    ></td-subscribe-button>
                </td-subscribe>
            </mat-step>
            <mat-step [editable]="false">
                <ng-template matStepLabel>{{ t('buy-bulk.step-4-title') }}</ng-template>
                <div class="d-flex flex-column flex-sm-row gap-2">
                    <div class="description">
                        {{ t('buy-bulk.step-4-description') }}
                    </div>
                    <div class="d-flex flex-column gap-2 action">
                        <button mat-flat-button (click)="done()" color="primary">
                            {{ t('actions.manage-your-domains') }}
                        </button>
                    </div>
                </div>
                <td-share
                    class="mt-4 pt-4"
                    [text]="names.length > 1 ? t('buy-bulk.share-text', { count: names.length }) : t('buy-bulk.share-text', { name: names[0] })"
                    [domainName]="names.length > 1 ? null : names[0]"
                ></td-share>
            </mat-step>
            <ng-template matStepperIcon="done">
                <fa-icon icon="check"></fa-icon>
            </ng-template>
        </mat-stepper>
    </div>
</ng-container>
