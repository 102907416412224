<div class="d-flex flex-column flex-fill h-100" *transloco="let t">
    <h1 mat-dialog-title class="text-truncate flex-shrink-0 mb-4">{{ t('gvPool.start-delegation.title') }}</h1>
    <mat-dialog-content class="d-flex flex-column flex-grow-1">
        <td-operation-status class="w-100" [operation]="operation" (done)="operationDone($event)" #op></td-operation-status>
        <div class="flex-shrink-0 d-flex flex-column gap-2">
            <td-delegation-selector (delegationSelected)="delegateSelected($event)" [delegate]="data.delegate" [showScroll]="false"></td-delegation-selector>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex justify-content-end align-items-end gap-2 w-100 min-height-auto">
        <button mat-button (click)="cancel()">{{ t('actions.cancel') }}</button>
        <button mat-flat-button color="primary" (click)="submit()" [disabled]="wantsToDelegate && !delegate">
            {{ wantsToDelegate ? t('actions.delegate') : t('actions.stop-delegation') }}
        </button>
    </mat-dialog-actions>
</div>
