import { Component, OnDestroy, OnInit } from '@angular/core';
import { interval } from 'rxjs';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DataSourceFactory } from '../graphql/data-source-factory';
import { EventsDataSource } from '../graphql/events-table-data-source';
import { EventType } from '../graphql/graphql.generated';
@UntilDestroy()
@Component({
    selector: 'td-home-purchases',
    templateUrl: './purchases.component.html',
    styleUrls: ['./purchases.component.scss'],
})
export class PurchasesComponent implements OnInit, OnDestroy {
    dataSource: EventsDataSource;
    canShowLoading = true;

    constructor(private dataSourceFactory: DataSourceFactory) {}

    ngOnInit() {
        this.dataSource = this.dataSourceFactory.createEventsDataSource();

        this.dataSource.load({
            first: 10,
            where: {
                type: {
                    in: [
                        EventType.AuctionBidEvent,
                        EventType.AuctionSettleEvent,
                        EventType.DomainBuyEvent,
                        EventType.DomainClaimEvent,
                        EventType.DomainRenewEvent,
                        EventType.DomainGrantEvent,
                        EventType.OfferExecutedEvent,
                        EventType.OfferPlacedEvent,
                        EventType.BuyOfferExecutedEvent,
                        EventType.BuyOfferPlacedEvent,
                    ],
                },
            },
        });

        interval(5 * 60 * 1000)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.canShowLoading = false;
                this.dataSource.loadMore('up');
            });
    }

    ngOnDestroy() {
        this.dataSource.disconnect();
    }
}
