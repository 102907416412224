import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import BigNumber from 'bignumber.js';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { Logger } from '../browser/logger';
import { Configuration } from '../configuration';

interface GateIoResponse {
    last: number;
    lowest_ask: number;
    highest_bid: number;
    change_percentage: number;
    base_volume: number;
    quote_volume: number;
    high_24h: number;
    low_24h: number;
}

@Injectable({
    providedIn: 'root',
})
export class TEDTokenPriceService {
    private http = inject(HttpClient);
    private config = inject(Configuration);
    private log = inject(Logger);

    private usdPrice$ = timer(0, 10 * 60 * 1000).pipe(
        switchMap(() => {
            if (!this.config.governance.tedExchangeUrl) {
                return of({ price: null });
            }

            return this.http.get<GateIoResponse[]>(`${this.config.governance.tedExchangeUrl}`).pipe(
                map(data => {
                    if (!data?.length) {
                        return { price: null };
                    }

                    return this.mapPrice(data[0]);
                }),
                catchError(err => {
                    this.log.error('[TEDTokenPriceService] Unable to fetch price from GateIO', err);
                    return of({ price: null });
                })
            );
        }),
        map(({ price }) => price),
        shareReplay({ bufferSize: 1, refCount: false })
    );

    convert(amount: BigNumber): Observable<{ loading: boolean; price: BigNumber | null }> {
        return this.usdPrice$.pipe(
            map(rate => (rate ? { loading: false, price: amount.dividedBy(1e6).multipliedBy(rate) } : { loading: false, price: null })),
            startWith({ loading: true, price: null })
        );
    }

    private mapPrice(data: GateIoResponse): { price: BigNumber } {
        const price = new BigNumber(data.last);

        return { price };
    }
}
