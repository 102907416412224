import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { getLabel, getTld } from '@tezos-domains/core';
import { DomainAcquisitionInfo } from '@tezos-domains/manager';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { Observable, ReplaySubject, Subject, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { OperationStatusDoneEvent } from '../shared/operation-status.component';
import { TezosWallet } from '../tezos/models';
import { SmartContractOperationEvent, TezosService } from '../tezos/tezos.service';
import { TdValidators } from '../utils/form-validators';

@Component({
    selector: 'td-bid',
    templateUrl: './bid.component.html',
    styleUrls: ['./bid.component.scss'],
})
export class BidComponent implements OnInit, OnDestroy {
    operation: Observable<SmartContractOperationEvent> | null;
    form: FormGroup<{ bid: FormControl<number> }>;
    wallet: TezosWallet;
    bidderBalance: ReplaySubject<number> = new ReplaySubject(1);
    minBid: number;
    bidAdditionalPeriod: number;
    extendedDate: dayjs.Dayjs | null;
    auctionEnd: dayjs.Dayjs;

    private label: string;
    private tld: string;

    private unsubscribe = new Subject<void>();

    constructor(
        private tezosService: TezosService,
        private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: { name: string; acquisitionInfo: DomainAcquisitionInfo },
        private dialogRef: MatDialogRef<BidComponent>
    ) {}

    ngOnInit(): void {
        this.label = getLabel(this.data.name);
        this.tld = getTld(this.data.name);

        this.tezosService.activeWallet.pipe(takeUntil(this.unsubscribe)).subscribe(w => {
            this.wallet = w!;
            this.wallet.bidderBalances
                .pipe(
                    takeUntil(this.unsubscribe),
                    map(b => b.find(x => x.tld === this.tld)?.balance || 0)
                )
                .subscribe(b => {
                    this.bidderBalance.next(b);
                });
        });

        this.bidAdditionalPeriod = dayjs.duration(this.data.acquisitionInfo.auctionDetails.bidAdditionalPeriod, 'milliseconds').asHours();
        this.auctionEnd = dayjs(this.data.acquisitionInfo.auctionDetails.auctionEnd);

        timer(0, 60 * 1000)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.checkAdditionalPeriod());

        this.minBid = this.data.acquisitionInfo.auctionDetails.nextMinimumBid / 1e6;
        this.form = this.formBuilder.group({
            bid: this.formBuilder.control(this.minBid, {
                nonNullable: true,
                validators: [Validators.required, TdValidators.number(6), Validators.min(this.minBid)],
            }),
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    bid() {
        const form = this.form.value;
        this.form.disable();
        this.operation = this.tezosService.execute(client =>
            client.manager.bid(this.tld, { label: this.label, bid: new BigNumber(form.bid!).times(1e6).toNumber() })
        );
    }

    cancel() {
        this.dialogRef.close(false);
    }

    operationDone(event: OperationStatusDoneEvent) {
        if (event.success) {
            this.dialogRef.close(true);
        } else {
            this.form.enable();
        }
    }

    getAmount(bidderBalance: number) {
        const bid = this.form.get('bid')!;
        return Math.max(0, bid.invalid ? this.data.acquisitionInfo.auctionDetails.nextMinimumBid : bid.value * 1e6 - bidderBalance);
    }

    private checkAdditionalPeriod() {
        const now = dayjs();
        const extendedEnd = now.add(this.data.acquisitionInfo.auctionDetails.bidAdditionalPeriod, 'milliseconds');

        if (now.isAfter(this.auctionEnd)) {
            this.extendedDate = null;
            this.form.disable();
            return;
        }

        if (extendedEnd.isAfter(this.auctionEnd)) {
            this.extendedDate = extendedEnd;
        } else {
            this.extendedDate = null;
        }
    }
}
