import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { GovActionService } from '../airdrop/gov-action.service';
import { DelegationStatus, ReverseRecord } from '../delegation/models';
import { OperationStatusDoneEvent } from '../shared/operation-status.component';
import { SmartContractOperationEvent } from '../tezos/tezos.service';

@Component({
    selector: 'td-change-delegation',
    templateUrl: './change-delegation.component.html',
    styleUrls: ['./change-delegation.component.scss'],
})
export class ChangeDelegationComponent {
    private dialogRef = inject(MatDialogRef<ChangeDelegationComponent>);
    private govAction = inject(GovActionService);
    data = inject<{ delegate: DelegationStatus | null }>(MAT_DIALOG_DATA) ?? { delegate: null };
    wantsToDelegate = true;

    delegate: DelegationStatus | null = null;
    operation: Observable<SmartContractOperationEvent> | null;

    delegateSelected({ wantsToDelegate, delegate }: { wantsToDelegate: boolean; delegate: ReverseRecord | null }) {
        this.delegate = delegate ? { address: delegate.address, votingPower: 0, reverseRecord: delegate } : null;
        this.wantsToDelegate = wantsToDelegate;
    }

    submit() {
        if (this.wantsToDelegate && !this.delegate) {
            return;
        }

        this.operation = this.delegate ? this.govAction.q.delegate(this.delegate.address).send() : this.govAction.q.removeDelegate().send();
    }

    operationDone(event: OperationStatusDoneEvent) {
        if (event.success) {
            this.dialogRef.close({ delegate: this.delegate });
        }
    }

    cancel() {
        this.dialogRef.close();
    }
}
