import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform, inject } from '@angular/core';
import BigNumber from 'bignumber.js';

@Pipe({
    name: 'tdCurrency',
})
export class TDCurrencyPipe implements PipeTransform {
    private currencyPipe = inject(CurrencyPipe);

    transform(value: number | BigNumber | null | undefined, decimals = 2): string | null {
        if (value == null) return '';

        return this.currencyPipe.transform(BigNumber(value).toNumber(), 'USD', 'symbol', '1.0-' + decimals);
    }
}
