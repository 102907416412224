import { Component, Input, OnInit } from '@angular/core';

import { EventsRecord } from '../graphql/events-table-data-source';
import { EventType } from '../graphql/graphql.generated';

@Component({
    selector: 'td-event-balance',
    templateUrl: './event-balance.component.html',
    styleUrls: ['./event-balance.component.scss'],
})
export class EventBalanceComponent implements OnInit {
    @Input() event: EventsRecord;
    @Input() povAddress?: string;
    balance: number | null;
    color: string;
    sign: string | null;

    ngOnInit(): void {
        if ('balance' in this.event) {
            this.balance = this.event.balance.toNumber();
        }

        switch (this.event.type) {
            case EventType.AuctionBidEvent:
                if (this.event.sourceAddress === this.povAddress) {
                    this.setMinus();
                } else {
                    this.balance = null;
                }
                break;
            case EventType.DomainBuyEvent:
            case EventType.DomainRenewEvent:
                this.setMinus();
                break;
            case EventType.AuctionWithdrawEvent:
                this.setPlus();
                break;
            case EventType.OfferExecutedEvent:
                if (this.event.sourceAddress === this.povAddress) {
                    this.setMinus();
                } else {
                    if ('altBalance' in this.event) {
                        this.balance = this.event.altBalance.toNumber();
                    }
                    this.setPlus();
                }
                break;
        }
    }

    private setMinus() {
        this.sign = '-';
        this.color = 'txt-danger';
    }

    private setPlus() {
        this.sign = '+';
        this.color = 'txt-success';
    }
}
