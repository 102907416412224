<mat-form-field appearance="standard" *transloco="let t" class="w-100">
    <mat-label>{{ label }}</mat-label>
    <input class="flex-grow-1" [formControl]="$any(control)" [placeholder]="placeholder" matInput [required]="required" />
    <div matSuffix class="d-flex gap-1">
        <td-badge
            [color]="wallet && control.value === wallet.address ? 'primary' : 'accent'"
            [disabled]="control.disabled"
            [hover]="control.value !== wallet?.address"
        >
            <div
                class="badge-button d-flex align-items-center"
                (click)="setMy(); $event.stopPropagation()"
                [matTooltip]="t('address-editor.my-address.tooltip')"
                [matTooltipDisabled]="control.disabled"
                [ngClass]="{ 'no-underline': control.disabled }"
            >
                <fa-icon icon="user-shield"></fa-icon>
                <span class="d-none d-sm-inline">{{ t('address-editor.my-address.title') }}</span>
            </div>
        </td-badge>
        <td-badge *ngIf="!required" [color]="!control.value ? 'primary' : 'accent'" [disabled]="control.disabled" [hover]="control.value">
            <div
                class="badge-button d-flex align-items-center"
                (click)="clear(); $event.stopPropagation()"
                [matTooltip]="t('address-editor.empty.tooltip')"
                [matTooltipDisabled]="control.disabled"
                [ngClass]="{ 'no-underline': control.disabled }"
            >
                <fa-icon class="tight" icon="user-times"></fa-icon>
                <span class="d-none d-sm-inline">{{ t('address-editor.empty.title') }}</span>
            </div>
        </td-badge>
    </div>
    <mat-error>
        <td-validation-messages [control]="control" [context]="{ name: label }"></td-validation-messages>
    </mat-error>
</mat-form-field>
