import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import escape from 'lodash-es/escape';
import { combineLatest, merge, Observable, of, Subject, timer } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import { TezosNetworkService } from '../tezos/tezos-network.service';
import {
    SmartContractOperationCompletedEvent,
    SmartContractOperationConfirmationEvent,
    SmartContractOperationEvent,
    SmartContractOperationPermissionsGrantedEvent,
    SmartContractOperationSentEvent,
} from '../tezos/tezos.service';
import { shorten } from '../utils/convert';
import { AlertState } from './alert.component';
import { MessageService } from './message-service.service';

export type OperationStatusDoneEvent = {
    success: boolean;
};

class SmartContractOperationSuspendEvent {}

@Component({
    selector: 'td-operation-status',
    templateUrl: './operation-status.component.html',
    styleUrls: ['./operation-status.component.scss'],
})
export class OperationStatusComponent implements OnDestroy {
    @Input() largeRequest = false;

    @Input() set operation(value: Observable<SmartContractOperationEvent> | null) {
        this.reset();

        if (!value) {
            return;
        }

        this.op = value;

        timer(2000)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => (this.walletTimeout = !this.sent));

        combineLatest([merge(value, this.suspendStream), this.tezosNetworkService.activeNetwork])
            .pipe(
                takeUntil(this.unsubscribe),
                map(([event, network]) => {
                    this.suspended = false;

                    if (event instanceof SmartContractOperationPermissionsGrantedEvent) {
                        return { message: this.largeRequest ? 'operation.requested-long' : 'operation.requested' };
                    } else if (event instanceof SmartContractOperationSuspendEvent) {
                        this.suspended = true;
                        this.done.emit({ success: false });
                        return { message: 'operation.suspended' };
                    } else if (event instanceof SmartContractOperationSentEvent) {
                        this.sent = true;
                        this.hash = event.operation.opHash;
                        this.link = `${network.statsUrl}/${event.operation.opHash}`;
                        return { message: 'operation.sent' };
                    } else if (event instanceof SmartContractOperationConfirmationEvent) {
                        if (event.currentConfirmation === event.expectedConfirmations) {
                            // required number of confirmations reached, but not indexed yet
                            return {
                                message: 'operation.indexer',
                            };
                        } else {
                            return {
                                message: 'operation.confirmations',
                                params: {
                                    current: event.currentConfirmation + 1,
                                    total: event.expectedConfirmations,
                                },
                            };
                        }
                    } else if (event instanceof SmartContractOperationCompletedEvent) {
                        this.success = true;
                        this.done.emit({ success: true });
                        this.messageService.success({
                            messageKey: 'operation.success-toast',
                            messageParameters: { hash: shorten(this.hash), url: this.link },
                        });
                        return { message: 'operation.success' };
                    }

                    return { message: '' };
                }),
                catchError(err => {
                    this.suspended = false;
                    this.error = true;
                    this.done.emit({ success: false });
                    if (err.errorCode) {
                        /**
                         * t(operation.error.COMMITMENT_TOO_OLD)
                         * t(operation.error.BID_TOO_LOW)
                         * t(operation.error.NOT_SETTLEABLE)
                         * t(operation.error.LABEL_EXPIRED)
                         * t(operation.error.AUCTION_ENDED)
                         * t(operation.error.NOT_AUTHORIZED)
                         * t(operation.error.CONTRACT_BALANCE_TOO_LOW)
                         * t(operation.error.ABORTED)
                         * t(operation.error.INDEXER_TIMEOUT)
                         * t(operation.error.GOV_INDEXER_TIMEOUT)
                         * t(operation.error.NOT_TRUSTED_SENDER)
                         * t(operation.error.STORAGE_EXHAUSTED_OPERATION)
                         * t(operation.error.COMMITMENT_EXISTS)
                         * t(operation.error.TIMESTAMP_TOO_OLD)
                         */
                        return of({ message: `operation.error.${err.errorCode}`, params: {} });
                    }

                    return of({ message: 'operation.error.UNKNOWN', params: { error: escape(err.message?.replace(/\{/g, `'{'`)?.replace(/\}/g, `'}'`)) } });
                }),
                /**
                 * t(operation.confirmations)
                 * t(operation.indexer)
                 * t(operation.error.UNKNOWN)
                 * t(operation.requested)
                 * t(operation.sent)
                 * t(operation.success)
                 * t(operation.suspended)
                 * t(operation.success-toast)
                 */
                switchMap(data => this.translation.selectTranslate(data.message, data.params))
            )
            .subscribe(m => (this.message = m));
    }
    @Output() done = new EventEmitter<OperationStatusDoneEvent>();

    message: string | null;
    link: string | null;
    success: boolean;
    error: boolean;
    sent: boolean;
    suspended: boolean;
    walletTimeout: boolean;
    get inProgress(): boolean {
        return !!this.op && !this.suspended && !(this.success || this.error);
    }
    get state(): AlertState {
        switch (true) {
            case this.error:
                return 'danger';
            case this.suspended:
                return 'warning';
            case this.success:
                return 'success';
            default:
                return 'info';
        }
    }

    private unsubscribe = new Subject<void>();
    private suspendStream = new Subject<SmartContractOperationEvent>();
    private op: Observable<SmartContractOperationEvent> | null;
    private hash: string;

    constructor(private translation: TranslocoService, private messageService: MessageService, private tezosNetworkService: TezosNetworkService) {}

    redo() {
        this.suspendStream.next(new SmartContractOperationSuspendEvent());
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private reset() {
        this.message = null;
        this.link = null;
        this.op = null;
        this.success = false;
        this.error = false;
        this.sent = false;
        this.walletTimeout = false;

        this.unsubscribe.next();
    }
}
