import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
    transform(value: string | null | undefined, maxLength = 50, start?: number, end?: number, separator = '...'): string {
        if (!value) {
            return '';
        }

        if (value.length <= maxLength) {
            return value;
        }

        if (!start) {
            start = maxLength / 2;
        }

        if (!end) {
            end = maxLength / 2;
        }

        if (start + end > value.length) {
            throw new Error(`Invalid start/end (${start}, ${end}) for truncate of value '${value}'.`);
        }

        return `${value.substring(0, start)}${separator}${value.substring(value.length - end)}`;
    }
}
