import { Injectable } from '@angular/core';
import { AppService } from '../app-service';
import { AuctionDetailDataSource } from './auction-detail-data-source';
import { AuctionTableDataSource } from './auction-table-data-source';
import { BuyOfferTableDataSource } from './buy-offer-table-data-source';
import { CurrentBuyOfferDataSource } from './current-buy-offer-data-source';
import { DomainDetailDataSource } from './domain-detail-data-source';
import { DomainTableDataSource } from './domain-table-data-source';
import { DomainsWithAuctionListDataSource } from './domains-with-auction-data-source';
import { EventsDataSource } from './events-table-data-source';
import {
    AuctionDetailGQL,
    AuctionListGQL,
    BuyOfferListGQL,
    CurrentBuyOfferGQL,
    DomainDetailGQL,
    DomainsListGQL,
    DomainsWithAuctionListGQL,
    EventsGQL,
    OfferListGQL,
    ReverseRecordDetailGQL,
    ReverseRecordListGQL,
    StatsGQL,
    UserDataGQL,
} from './graphql.generated';
import { OfferTableDataSource } from './offer-table-data-source';
import { ReverseRecordDetailDataSource } from './reverse-record-data-source';
import { ReverseRecordTableDataSource } from './reverse-record-table-data-source';
import { StatsDataSource } from './stats-data-source';
import { UserDataDataSource } from './user-data-data-source';

@Injectable({
    providedIn: 'root',
})
export class DataSourceFactory {
    constructor(
        private domainListGQL: DomainsListGQL,
        private reverseRecordListGQL: ReverseRecordListGQL,
        private statsGQL: StatsGQL,
        private eventsGQL: EventsGQL,
        private domainDetailGQL: DomainDetailGQL,
        private reverseRecordDetailGQL: ReverseRecordDetailGQL,
        private auctionListGQL: AuctionListGQL,
        private auctionDetailGQL: AuctionDetailGQL,
        private userDataGQL: UserDataGQL,
        private offerListGQL: OfferListGQL,
        private buyOfferListGQL: BuyOfferListGQL,
        private currentBuyOfferGQL: CurrentBuyOfferGQL,
        private domainsWithAuctionGQL: DomainsWithAuctionListGQL,
        private appService: AppService
    ) {}

    createDomainsDataSource() {
        return new DomainTableDataSource({ query: this.domainListGQL, appService: this.appService });
    }

    createDomainsWithAuctionDataSource() {
        return new DomainsWithAuctionListDataSource({ query: this.domainsWithAuctionGQL, appService: this.appService });
    }

    createReverseRecordsDataSource() {
        return new ReverseRecordTableDataSource({ query: this.reverseRecordListGQL, appService: this.appService });
    }

    createStatsDataSource() {
        return new StatsDataSource({ query: this.statsGQL, appService: this.appService });
    }

    createEventsDataSource() {
        return new EventsDataSource({ query: this.eventsGQL, appService: this.appService });
    }

    createDomainDetailDataSource() {
        return new DomainDetailDataSource({ query: this.domainDetailGQL, appService: this.appService });
    }

    createAuctionDetailDataSource() {
        return new AuctionDetailDataSource({ query: this.auctionDetailGQL, appService: this.appService });
    }

    createReverseRecordDetailDataSource() {
        return new ReverseRecordDetailDataSource({ query: this.reverseRecordDetailGQL, appService: this.appService });
    }

    createAuctionTableDataSource() {
        return new AuctionTableDataSource({ query: this.auctionListGQL, appService: this.appService });
    }

    createUserDataDataSource() {
        return new UserDataDataSource({ query: this.userDataGQL, appService: this.appService });
    }

    createOfferTableDataSource() {
        return new OfferTableDataSource({ query: this.offerListGQL, appService: this.appService });
    }

    createBuyOfferTableDataSource() {
        return new BuyOfferTableDataSource({ query: this.buyOfferListGQL, appService: this.appService });
    }

    createCurrentBuyOfferDataSource() {
        return new CurrentBuyOfferDataSource({ query: this.currentBuyOfferGQL, appService: this.appService });
    }
}
