import { inject } from '@angular/core';
import { BulkDomainsDataService } from '../../file-processing/file-data.service';
import { RegistrationDomainsStateService } from './registration-domains.state.service';

export const bulkRegistrationDeactivate = () => {
    const stateService = inject(RegistrationDomainsStateService);
    const fileData = inject(BulkDomainsDataService);
    stateService.clear();
    fileData.clear();

    return true;
};
