import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DomainAcquisitionInfo, DomainAcquisitionState } from '@tezos-domains/manager';
import { TLDConfiguration } from '@tezos-domains/manager/dist/src/manager/model';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { getExistingAuction } from '../domains/utils/aquisition-info';
import { DataSourceFactory } from '../graphql/data-source-factory';
import { DomainsWithAuctionListQuery, RecordValidity } from '../graphql/graphql.generated';
import { FavouritesService } from '../shared/favourites.service';
import { TezosDomainsClientService } from '../tezos/integration/tezos-domains-client.service';
import { TldConfigurationService } from '../tezos/integration/tld-configuration.service';
import { Unarray } from '../utils/types';

interface ExatchMatchData {
    name: string;
    price: BigNumber;
    seller: string;
    expiration?: dayjs.Dayjs | null;
    isTaken?: boolean;
    buttonDisabled?: boolean;
    buttonText?: string;
    acquisitionInfo: DomainAcquisitionInfo;
    buttonAction: () => void;
}

type Domain = Unarray<DomainsWithAuctionListQuery['domains']['edges']>['node'];

@UntilDestroy()
@Component({
    selector: 'td-favourites-table',
    templateUrl: './favourites-table.component.html',
    styleUrls: ['./favourites-table.component.scss'],
})
export class FavouritesTableComponent implements OnInit {
    @Input() address: string;
    @Input() first = 50;
    @Output() loaded = new EventEmitter<number>();
    @Output() initialLoading = new BehaviorSubject<boolean>(true);
    @Output() loading = new BehaviorSubject<boolean>(true);

    private favService = inject(FavouritesService);
    private tldConfigService = inject(TldConfigurationService);
    private tezosDomainsClientService = inject(TezosDomainsClientService);
    private router = inject(Router);

    dataSource = inject(DataSourceFactory).createDomainsWithAuctionDataSource();

    data$: Observable<ExatchMatchData[]>;

    now = dayjs();

    ngOnInit() {
        combineLatest([this.dataSource.initialLoading$.pipe(first(l => !l)), this.loading.pipe(first(l => !l))])
            .pipe(first(), untilDestroyed(this))
            .subscribe(() => this.initialLoading.next(false));

        this.favService.favourites$.subscribe(({ favs: domains }) => {
            this.loading.next(true);

            const favDomains = domains.slice(0, this.first).map(d => d.id);

            this.dataSource.load({
                where: {
                    name: { in: favDomains },
                    validity: RecordValidity.All,
                },
                first: this.first,
            });
        });

        const tldConfig$ = this.tezosDomainsClientService.defaultTld$.pipe(switchMap(tld => this.tldConfigService.get(tld)));
        const visibleFavourites$ = this.favService.favourites$.pipe(map(d => d.favs.slice(0, this.first).map(f => f.id)));

        this.data$ = combineLatest([visibleFavourites$, this.dataSource.data$, tldConfig$]).pipe(
            map(([favourites, existingDomains, tldConfig]) => {
                return favourites.map(favName => {
                    const existingDomain = existingDomains.find((d: any) => d.name === favName);
                    const acquisitionInfo = this.tezosDomainsClientService.calculateAcquisitionInfo({
                        name: favName,
                        tldConfiguration: tldConfig,
                        existingAuction: existingDomain ? getExistingAuction(existingDomain.lastAuction) : undefined,
                        existingDomain: existingDomain ? { expiry: existingDomain.expires?.toDate() || null } : undefined,
                    });

                    const domains = this.buildDomainData(favName, existingDomain, acquisitionInfo, tldConfig);
                    return domains;
                });
            }),
            tap(d => {
                this.loaded.next(d.length);
                this.loading.next(false);
            })
        );
    }

    private buildDomainData(
        name: string,
        existingDomain: Domain | null | undefined,
        acquisitionInfo: DomainAcquisitionInfo,
        tldConfig: TLDConfiguration
    ): ExatchMatchData {
        const data: Partial<ExatchMatchData> = {};

        // data.seller = existingDomain?.owner;
        data.name = name;
        data.acquisitionInfo = acquisitionInfo;
        data.expiration = existingDomain?.expires;
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        data.buttonAction = () => {};

        existingDomain = existingDomain;
        switch (acquisitionInfo?.acquisitionState) {
            case DomainAcquisitionState.CanBeClaimed:
                /**
                 * t(actions.claimed)
                 * t(actions.claim)
                 */
                data.price = tldConfig.claimPrice;
                data.buttonDisabled = true;
                break;
            case DomainAcquisitionState.CanBeBought:
                const pricePerYear = new BigNumber(acquisitionInfo.calculatePrice(365));
                data.price = pricePerYear;
                data.buttonText = 'actions.register';
                data.buttonAction = () => this.router.navigate(['/domain', name]);
                break;
            case DomainAcquisitionState.Taken: {
                data.buttonText = 'general.taken';
                data.buttonDisabled = true;
                break;
            }
            case DomainAcquisitionState.CanBeSettled:
                data.buttonText = 'general.taken';
                data.buttonDisabled = true;
                data.isTaken = true;
                break;
            case DomainAcquisitionState.CanBeAuctioned:
            case DomainAcquisitionState.AuctionInProgress:
                data.buttonText = 'actions.go-to-auction';
                data.expiration = dayjs(acquisitionInfo.auctionDetails.auctionEnd);
                data.price = new BigNumber(acquisitionInfo.auctionDetails.nextMinimumBid);
                data.buttonAction = () => this.router.navigate(['/domain', name]);

                break;
            case DomainAcquisitionState.Unobtainable:
                data.buttonText = 'general.not-available';
                data.buttonDisabled = true;
                break;
        }

        return data as ExatchMatchData;
    }
}
