<div class="card smooth-shadow">
    <h2>Indexer state</h2>
    <div *ngIf="lastBlock && lastIndexedBlock">
        <ng-container *ngTemplateOutlet="icon; context: { fail: lastIndexedBlock < lastBlock - 1 }"></ng-container>
        block level indexed {{ lastIndexedBlock }}/{{ lastBlock }}
    </div>
</div>

<ng-template #icon let-fail="fail">
    <fa-icon icon="exclamation-circle" class="txt-danger" *ngIf="fail"></fa-icon>
    <fa-icon icon="check" class="txt-success" *ngIf="!fail"></fa-icon>
</ng-template>
