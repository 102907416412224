import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TransactionWalletOperation, WalletOperation, WalletTransferParams } from '@taquito/taquito';
import { RecordMetadata, getLabel, getParent } from '@tezos-domains/core';
import { TezosDomainsOperationFactory } from '@tezos-domains/manager';
import { TaquitoTezosDomainsClient } from '@tezos-domains/taquito-client';
import { Observable } from 'rxjs';
import { DomainListRecord } from '../graphql/domain-table-data-source';
import { OperationStatusDoneEvent } from '../shared/operation-status.component';
import { TezosWallet } from '../tezos/models';
import { SmartContractOperationEvent, TezosService } from '../tezos/tezos.service';
import { dataArrayToObj, emptyStringToNull } from '../utils/convert';
import { BulkRenewComponent } from './bulk-renew.component';
import { ExpiringDomainsStatsService } from './expiring-domains-stats.service';
import { isOperator } from './utils/is-operator';

@UntilDestroy()
@Component({
    selector: 'td-bulk-transfer',
    templateUrl: './bulk-transfer.component.html',
    styleUrls: ['./bulk-transfer.component.scss'],
})
export class BulkTransferComponent implements OnInit {
    operation: Observable<SmartContractOperationEvent> | null;
    wallet: TezosWallet;
    domains: NonNullable<DomainListRecord>[];

    form: FormGroup<{
        owner: FormControl<string>;
        clearAddress: FormControl<boolean>;
    }>;

    transferConfirmationPending = false;

    constructor(
        private tezosService: TezosService,
        private formBuilder: NonNullableFormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: { domains: NonNullable<DomainListRecord>[] },
        private dialogRef: MatDialogRef<BulkRenewComponent>,
        private expiringStats: ExpiringDomainsStatsService
    ) {}

    ngOnInit(): void {
        this.tezosService.activeWallet.pipe(untilDestroyed(this)).subscribe(w => (this.wallet = w!));
        this.domains = this.data.domains;

        this.form = this.formBuilder.group({
            owner: this.formBuilder.control(''),
            clearAddress: this.formBuilder.control(this.domains.some(d => d.address)),
        });
    }

    requiresTransferConfirmation(pending: boolean) {
        this.transferConfirmationPending = pending;
    }

    transfer() {
        this.form.disable();

        this.execute(client => {
            const transferParams = this.domains.map(d => ({
                domain: d,
                owner: this.form.value.owner!,
                address: emptyStringToNull(d.address),
            }));

            return client.manager.batch(async b => {
                const ops: WalletTransferParams[] = [];

                for (const params of transferParams) {
                    if (params.domain.owner === this.wallet.address || isOperator(params.domain, this.wallet.address)) {
                        if (params.domain.tokenId) {
                            if (this.form.value.clearAddress) {
                                const addressOp = await this.removeAddress(b, params.domain);
                                ops.push(addressOp);
                            }

                            const transferOp = await b.transfer(params.domain.name, params.owner);
                            ops.push(transferOp);
                        } else {
                            const op = await b.updateRecord({
                                owner: params.owner,
                                data: new RecordMetadata(dataArrayToObj(params.domain.data)),
                                address: this.form.value.clearAddress ? null : params.address,
                                name: params.domain.name,
                            });
                            ops.push(op);
                        }
                    } else {
                        const op = await b.setChildRecord({
                            owner: params.owner,
                            data: new RecordMetadata(dataArrayToObj(params.domain.data)),
                            address: this.form.value.clearAddress ? null : params.address,
                            label: getLabel(params.domain.name),
                            parent: getParent(params.domain.name),
                        });
                        ops.push(op);
                    }
                }
                return ops;
            });
        });
    }

    cancel() {
        this.dialogRef.close(false);
    }

    operationDone(event: OperationStatusDoneEvent) {
        if (event.success) {
            this.dialogRef.close(true);
            this.expiringStats.refresh();
        } else {
            this.form.enable();
        }
    }

    private removeAddress(builder: TezosDomainsOperationFactory<WalletTransferParams>, domain: DomainListRecord) {
        return builder.updateRecord({
            address: null,
            data: new RecordMetadata(dataArrayToObj(domain.data)),
            name: domain.name,
            owner: domain.owner,
        });
    }

    private execute(op: (client: TaquitoTezosDomainsClient) => Promise<TransactionWalletOperation | WalletOperation>) {
        this.form.disable();

        const operation = this.tezosService.execute(op);

        this.operation = operation;
    }
}
