import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { getLabel, getTld } from '@tezos-domains/core';
import { DomainAcquisitionInfo } from '@tezos-domains/manager';
import dayjs from 'dayjs';
import { Observable, Subject, combineLatest, forkJoin } from 'rxjs';
import { map, shareReplay, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { Logger } from '../browser/logger';
import { DomainListRecord } from '../graphql/domain-table-data-source';
import { OperationStatusDoneEvent } from '../shared/operation-status.component';
import { TezosDomainsClientService } from '../tezos/integration/tezos-domains-client.service';
import { TezosWallet } from '../tezos/models';
import { SmartContractOperationEvent, TezosService } from '../tezos/tezos.service';
import { TdValidators } from '../utils/form-validators';
import { ExpiringDomainsStatsService } from './expiring-domains-stats.service';

@Component({
    selector: 'td-bulk-renew',
    templateUrl: './bulk-renew.component.html',
    styleUrls: ['./bulk-renew.component.scss'],
})
export class BulkRenewComponent implements OnInit, OnDestroy {
    operation: Observable<SmartContractOperationEvent> | null;
    form: FormGroup<{ duration: FormControl<number>; price: FormControl<number> }>;
    wallet: TezosWallet;
    domains: NonNullable<DomainListRecord>[];

    private unsubscribe = new Subject<void>();
    loading$: Observable<boolean>;
    domainList$: Observable<{ domain: DomainListRecord; info: DomainAcquisitionInfo | null; newExpiration: dayjs.Dayjs | null }[]>;

    constructor(
        private tezosService: TezosService,
        formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: { domains: NonNullable<DomainListRecord>[] },
        private dialogRef: MatDialogRef<BulkRenewComponent>,
        private tezosDomainsClientService: TezosDomainsClientService,
        private log: Logger,
        private expiringStats: ExpiringDomainsStatsService
    ) {
        this.form = formBuilder.group({
            duration: formBuilder.control(1, {
                nonNullable: true,
                validators: [Validators.required, TdValidators.number(), Validators.min(1), Validators.max(100)],
            }),
            price: formBuilder.control(0, { nonNullable: true }),
        });
    }

    ngOnInit(): void {
        this.domains = this.data.domains;

        const domainAcquisitionInfos$ = this.loadDomainAcquisitionInfo(this.domains);

        this.domainList$ = combineLatest([domainAcquisitionInfos$, this.durationChange$()]).pipe(
            map(([infos, duration]) =>
                infos.map((info, index) => ({ domain: this.domains[index], newExpiration: this.calculateNewExpiration(this.domains[index], duration), info }))
            )
        );

        this.loading$ = domainAcquisitionInfos$.pipe(
            take(1),
            map(() => false),
            startWith(true)
        );

        this.getPriceChanges$(domainAcquisitionInfos$)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(price => this.form.get('price')!.setValue(price));

        this.tezosService.activeWallet.pipe(takeUntil(this.unsubscribe)).subscribe(w => (this.wallet = w!));
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    renew() {
        this.form.disable();
        this.operation = this.tezosService.execute(client => {
            return client.manager.batch(async b => {
                const renewalCalls = this.domains.map(domain => b.renew(getTld(domain.name), { label: getLabel(domain.name), duration: this.getDuration() }));

                return await Promise.all(renewalCalls);
            });
        });
    }

    cancel() {
        this.dialogRef.close(false);
    }

    operationDone(event: OperationStatusDoneEvent) {
        if (event.success) {
            this.dialogRef.close(true);
            this.expiringStats.refresh();
        } else {
            this.form.enable();
        }
    }

    private loadDomainAcquisitionInfo(domains: DomainListRecord[]): Observable<(DomainAcquisitionInfo | null)[]> {
        return this.tezosDomainsClientService.current.pipe(
            switchMap(tezosDomains => {
                const tldPromises = domains.map(d =>
                    tezosDomains.manager.getTldConfiguration(getTld(d.name)).catch(e => {
                        this.log.error('Error loading acquisition info', e);
                        return null;
                    })
                );
                return forkJoin(tldPromises);
            }),
            map(tldConfigs => {
                return tldConfigs.map((tldConfig, index) => {
                    if (!tldConfig) {
                        return null;
                    }

                    const domain = domains[index];
                    return this.tezosDomainsClientService.calculateAcquisitionInfo({
                        name: domain.name,
                        tldConfiguration: tldConfig,
                        existingDomain: { expiry: domain.expires?.toDate() || null },
                    });
                });
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
            takeUntil(this.unsubscribe)
        );
    }

    private getPriceChanges$(domainAcquisitionInfos$: Observable<(DomainAcquisitionInfo | null)[]>) {
        return combineLatest([domainAcquisitionInfos$, this.durationChange$()]).pipe(
            map(([tldAcquisitionInfos, _]) => {
                const allPrices = tldAcquisitionInfos.filter(info => info).map(info => info!.calculatePrice(this.getDuration()));
                const totalPrice = allPrices.reduce((total, price) => (total += price), 0) / 1e6;

                return totalPrice;
            })
        );
    }

    private calculateNewExpiration(domain: DomainListRecord | null | undefined, duration: number): dayjs.Dayjs | null {
        return domain?.expires?.add(duration, 'days') ?? null;
    }

    private durationChange$(): Observable<number> {
        return this.form.get('duration')!.valueChanges.pipe(
            startWith(this.form.value.duration),
            map(() => this.getDuration())
        );
    }

    private getDuration() {
        return this.form.get('duration')!.value * 365;
    }
}
