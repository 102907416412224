import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { CookieService } from 'ngx-cookie';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TrackingService } from '../browser/tracking.service';

import { Configuration } from '../configuration';
import { LocaleService } from '../i18n/locale.service';
import { StorageSchema } from '../utils/storage';

interface LangInfo {
    id: string;
    label: string;
    locale: string;
}

@Component({
    selector: 'td-language-switcher',
    templateUrl: './language-switcher.component.html',
})
export class LanguageSwitcherComponent implements OnInit, OnDestroy {
    language: LangInfo;
    languages: LangInfo[];

    private unsubscribe = new Subject<void>();

    constructor(
        private translocoService: TranslocoService,
        private localeService: LocaleService,
        private trackingService: TrackingService,
        private cookieService: CookieService,
        private configuration: Configuration
    ) {}

    ngOnInit(): void {
        this.languages = this.translocoService.getAvailableLangs() as LangInfo[];

        this.translocoService.langChanges$.pipe(takeUntil(this.unsubscribe)).subscribe(lang => {
            this.language = this.languages.find(l => l.id === lang)!;
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    setLanguage(lang: LangInfo) {
        this.translocoService.setActiveLang(lang.id);
        this.setCookie(lang);
        this.localeService.loadLocale(lang.locale);
        this.trackingService.event('language_switch', { lang: lang.id });
        this.trackingService.properties({ app_lang: lang.id });
    }

    private setCookie(lang: LangInfo) {
        this.cookieService.put(StorageSchema.userPreferredLanguage.key, lang.id, {
            domain: this.configuration.cookieDomain,
            expires: new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000),
        });
    }
}
