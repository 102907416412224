import { Component, OnInit } from '@angular/core';

import { AppService } from '../app-service';

@Component({
    selector: 'td-update-available',
    templateUrl: './update-available.component.html',
    styleUrls: ['./update-available.component.scss'],
})
export class UpdateAvailableComponent implements OnInit {
    show = false;

    constructor(private appService: AppService) {}

    ngOnInit(): void {
        this.appService.updateAvailable.subscribe(() => {
            this.show = true;
        });
    }

    update() {
        this.appService.applyUpdate();
    }
}
