import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { filter, first, mapTo } from 'rxjs/operators';
import { UserData, UserDataService } from '../address/user-data.service';
import { PageService } from '../browser/page.service';
import { LatestBuyOffersComponent } from '../buy-offers/latest-buy-offers.component';
import { Configuration } from '../configuration';
import { LatestExpiredDomainsComponent } from '../domains/latest-expired-domains.component';
import { PoolCtaComponent } from '../gv-pool/cta.component';
import { GovPoolWidgetComponent } from '../gv-pool/pool-widget.component';
import { TezosWallet } from '../tezos/models';
import { TezosService } from '../tezos/tezos.service';
import { AirdropAnnouncementComponent } from './../airdrop/airdrop-announcement.component';
import { HotAuctionsComponent } from './hot-auctions.component';
import { HotOffersComponent } from './hot-offers.component';
import { PurchasesComponent } from './purchases.component';
import { FavouritesService } from '@/shared/favourites.service';

@UntilDestroy()
@Component({
    selector: 'td-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit {
    userData: UserData | null;
    loading = new BehaviorSubject<boolean>(true);

    @ViewChild(PurchasesComponent) purchases: PurchasesComponent;
    @ViewChild(HotAuctionsComponent) hotAuctions: HotAuctionsComponent;
    @ViewChild(HotOffersComponent) hotOffers: HotOffersComponent;
    @ViewChild(LatestExpiredDomainsComponent) expiredDomains: LatestExpiredDomainsComponent;
    @ViewChild(LatestBuyOffersComponent) buyOffers: LatestBuyOffersComponent;
    @ViewChild(AirdropAnnouncementComponent) airdrop: AirdropAnnouncementComponent;
    @ViewChild(GovPoolWidgetComponent) governancePool?: GovPoolWidgetComponent;
    @ViewChild(PoolCtaComponent) poolCta?: PoolCtaComponent;

    wallet$: Observable<TezosWallet | null>;
    airdropStarted = this.config.airdrop.start < new Date();
    hasFavourites = false;

    constructor(
        private pageService: PageService,
        private tezosService: TezosService,
        private userDataService: UserDataService,
        private favouriteService: FavouritesService,
        private config: Configuration
    ) {
        this.wallet$ = this.tezosService.activeWallet;
    }

    ngOnInit() {
        this.pageService.setDefaultTitle();

        this.userDataService.current.pipe(untilDestroyed(this)).subscribe(d => (this.userData = d));
        this.favouriteService.favourites$.pipe(untilDestroyed(this)).subscribe(d => (this.hasFavourites = d.rawFavs.length > 0));
        this.userDataService.refresh();
    }

    ngAfterViewInit() {
        const all: Observable<boolean>[] = [
            this.hotAuctions.table.dataSource.initialLoading$,
            this.hotOffers.table.dataSource.initialLoading$,
            this.purchases.dataSource.initialLoading$,
            this.expiredDomains.loading$,
            this.governancePool ? this.governancePool.loading$ : of(false),
            this.poolCta ? this.poolCta.loading$ : of(false),
            this.buyOffers.initialLoading$,
            this.airdrop.vm$.pipe(mapTo(false)),
        ];

        combineLatest(all)
            .pipe(
                filter(x => x.every(x => !x)),
                first()
            )
            .subscribe(() => setTimeout(() => this.loading.next(false), 0));
    }
}
