<h1>Tezos Domains Testing Wallet</h1>

<td-alert state="warning">
    <div class="d-flex flex-column">
        <div>Be aware that this wallet makes real transfers on the network. It is for testing purposes only.</div>
        <div><strong> Safety of your funds is not guaranteed.</strong></div>
    </div>
</td-alert>

<div class="d-flex flex-column align-items-center">
    <div class="balance my-4">Current balance: <td-tez [value]="wallet?.balance | async"></td-tez></div>

    <div class="d-flex">
        <button mat-flat-button (click)="send()" color="primary"><fa-icon icon="paper-plane"></fa-icon>Send</button>
    </div>
</div>
