import { Injectable } from '@angular/core';
import { TLDConfiguration } from '@tezos-domains/manager/dist/src/manager/model';
import { Observable, throwError } from 'rxjs';
import { catchError, shareReplay, switchMap } from 'rxjs/operators';
import { Logger } from '../../browser/logger';
import { TezosDomainsClientService } from './tezos-domains-client.service';

@Injectable({
    providedIn: 'root',
})
export class TldConfigurationService {
    constructor(private log: Logger, private tezosDomainsClient: TezosDomainsClientService) {}

    get(tld: string): Observable<TLDConfiguration> {
        return this.tezosDomainsClient.current.pipe(
            switchMap(tezosDomains => tezosDomains.manager.getTldConfiguration(tld)),
            shareReplay({ bufferSize: 1, refCount: true }),
            catchError(e => {
                this.log.error('Error loading TLD Configuration info', e);

                return throwError(e);
            })
        );
    }
}
