import { Component, Input, OnInit } from '@angular/core';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { filter } from 'rxjs/operators';

import { Configuration } from '../configuration';
import { EmptyProfile, SocialNetwork, SocialNetworkType, TzProfile, TzprofilesService } from '../tzprofiles/tzprofiles.service';

@UntilDestroy()
@Component({
    selector: 'td-tzprofile',
    templateUrl: './tzprofile.component.html',
    styleUrls: ['./tzprofile.component.scss'],
})
export class TzprofileComponent implements OnInit {
    @Input() address: string;
    @Input() showGenericLink = true;
    @Input() set tzProfile(value: TzProfile | null | undefined) {
        this.profile = value ? { ...value, verified: value.verified.map(n => this.mapVerifiedNetwork(n)) } : null;
    }

    profile: TzProfile | null = null;

    tzprofileLink: string | null;

    icon: Map<SocialNetworkType, [IconPrefix, IconName]> = new Map([
        ['discord', ['fab', 'discord']],
        ['twitter', ['fab', 'twitter']],
        ['github', ['fab', 'github']],
        ['dns', ['fas', 'globe']],
    ]);

    constructor(private tzprofilesService: TzprofilesService, private configuration: Configuration) {}

    ngOnInit(): void {
        if (!this.configuration.tzprofilesAppUrl) {
            return;
        }

        if (this.profile === EmptyProfile) {
            return;
        }

        if (this.profile) {
            this.tzprofileLink = `${this.configuration.tzprofilesAppUrl}/view/mainnet/${this.address}`;
            return;
        }

        this.tzprofilesService
            .getProfile(this.address)
            .pipe(
                filter(p => !!p),
                untilDestroyed(this)
            )
            .subscribe(profile => {
                this.tzProfile = profile;
                this.tzprofileLink = `${this.configuration.tzprofilesAppUrl}/view/mainnet/${this.address}`;
            });
    }

    private mapVerifiedNetwork(socialNetwork: SocialNetwork): SocialNetwork {
        let value = socialNetwork.value;

        if (socialNetwork.type === 'discord') {
            return socialNetwork;
        }

        if (socialNetwork.type === 'dns' && !value.startsWith('http')) {
            value = `https://${value}`;
        }

        return { ...socialNetwork, value };
    }
}
