<div *transloco="let t">
    <td-register-bulk-cta class="mb-4" (rawDomainsChange)="ctaDomainsChange($event)"></td-register-bulk-cta>

    <td-error *ngIf="domainList.error$ | async; let error" [message]="error.message" [title]="t('general.error')"></td-error>
    <td-alert state="warning" *ngIf="showMaxDomainsWarning" class="mb-4">
        <div [tdDynamicHtml]="t('buy-bulk.too-many', { count: configuration.maxBulkCreate })"></div>
    </td-alert>

    <ng-container *ngIf="(domainList.initialLoading$ | async) === false">
        <div class="card smooth-shadow">
            <div class="d-flex justify-content-end pb-3" *ngIf="selection.hasValue()">
                <button mat-flat-button color="primary" (click)="continueRegistration()">{{ t('actions.continue-bulk-registration') }}</button>
            </div>
            <ng-container *ngFor="let domain of data$ | async; let last = last">
                <td-bulk-domain-item
                    [domainName]="domain.name"
                    [price]="domain.price"
                    [buttonText]="domain.buttonText ? t(domain.buttonText) : ''"
                    [buttonDisabled]="domain.buttonDisabled"
                    class="full-card-width"
                    [canSelect]="!domain.buttonDisabled"
                    [selected]="selection.isSelected(domain)"
                    (selectionChange)="toggleDomainSelection(domain)"
                >
                </td-bulk-domain-item>
                <mat-divider *ngIf="!last"></mat-divider>
            </ng-container>
        </div>
        <div *ngIf="domainList.loading$ | async" class="w-100 mt-3">
            <div class="d-flex flex-column align-items-center">
                <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
            </div>
        </div>
    </ng-container>
</div>
