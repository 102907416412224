<div *transloco="let t" [hidden]="hidden" class="card smooth-shadow home-item d-flex flex-column">
    <h1 class="mb-3">{{ t('gvPool.cta.title') }}</h1>
    <div class="d-flex flex-column gap-2 gap-sm-3 flex-sm-row">
        <div>
            <div [innerHTML]="t('gvPool.cta.description', { link: 'governance/dao' | landingPageUrl })"></div>
            <div *ngIf="stats$ | async as stats" class="my-3" data-testid="apr-value">
                {{ showAverageApr ? t('gvPool.cta.average-apr') : t('gvPool.cta.current-apr') }}
                <span class="fw-bold">{{ (showAverageApr ? stats.poolAverageAPR : stats.poolCurrentAPR) | apr }}</span>
                <fa-icon
                    *ngIf="showAverageApr"
                    icon="question-circle"
                    class="accent help ms-1 no-underline"
                    matTooltipPosition="above"
                    [matTooltip]="t('gvPool.average-apr-help')"
                ></fa-icon>
            </div>
        </div>
        <div class="d-flex flex-column gap-3 exchanges" *ngIf="plentyUrl">
            <a target="_blank" [href]="plentyUrl" mat-flat-button color="primary" *ngIf="plentyUrl">{{ t('gvPool.cta.buy-plenty') }}</a>
        </div>
    </div>
</div>
