import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'trustUrl',
})
export class TrustUrlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(url: string | undefined | null): any {
        if (!url) {
            return '';
        }

        return this.sanitizer.bypassSecurityTrustUrl(url);
    }
}
