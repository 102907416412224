import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { getLabel, RecordMetadata, StandardRecordMetadataKey, getParent } from '@tezos-domains/core';
import { TransactionWalletOperation } from '@taquito/taquito';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DomainDetailQuery } from '../graphql/graphql.generated';
import { TezosWallet } from '../tezos/models';
import { SmartContractOperationEvent, TezosService } from '../tezos/tezos.service';
import { TdValidators } from '../utils/form-validators';
import { DomainRecordFormComponent } from './domain-record-form.component';
import { dataArrayToObj, emptyStringToNull } from '../utils/convert';
import { OperationStatusDoneEvent } from '../shared/operation-status.component';
import { TaquitoTezosDomainsClient } from '@tezos-domains/taquito-client';
import { isOperator } from './utils/is-operator';

@Component({
    selector: 'td-edit-website',
    templateUrl: './edit-website.component.html',
    styleUrls: ['./edit-website.component.scss'],
})
export class EditWebsiteComponent implements OnInit, OnDestroy {
    form: UntypedFormGroup;
    operation: Observable<SmartContractOperationEvent> | null;
    wallet: TezosWallet;

    domain: NonNullable<DomainDetailQuery['domain']>;
    metadata: RecordMetadata;

    private unsubscribe = new Subject<void>();

    constructor(
        private formBuilder: UntypedFormBuilder,
        private tezosService: TezosService,
        @Inject(MAT_DIALOG_DATA) private data: { domain: NonNullable<DomainDetailQuery['domain']> },
        private dialogRef: MatDialogRef<DomainRecordFormComponent>
    ) {}

    ngOnInit() {
        this.domain = this.data.domain;
        this.tezosService.activeWallet.pipe(takeUntil(this.unsubscribe)).subscribe(w => (this.wallet = w!));

        this.metadata = new RecordMetadata(dataArrayToObj(this.domain.data));

        const redirectUrl = this.metadata.getJson(StandardRecordMetadataKey.WEB_REDIRECT_URL);

        this.form = this.formBuilder.group({
            mode: this.formBuilder.control(redirectUrl ? 'redirect' : 'content', [Validators.required]),
            contentUrl: this.formBuilder.control(this.metadata.getJson(StandardRecordMetadataKey.WEB_CONTENT_URL), [
                Validators.required,
                TdValidators.contentUrl,
            ]),
            redirectUrl: this.formBuilder.control(redirectUrl, [Validators.required, TdValidators.url()]),
        });

        this.form
            .get('mode')!
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.updateForm());

        this.updateForm();
    }

    operationDone(event: OperationStatusDoneEvent) {
        if (event.success) {
            this.dialogRef.close(true);
        } else {
            this.form.enable();
            this.updateForm();
        }
    }

    cancel() {
        this.dialogRef.close(false);
    }

    save() {
        const mode = this.form.value.mode;
        if (mode === 'content') {
            this.metadata.set(StandardRecordMetadataKey.WEB_REDIRECT_URL, null);
            this.metadata.setJson(StandardRecordMetadataKey.WEB_CONTENT_URL, this.form.value.contentUrl);
        } else if (mode === 'redirect') {
            this.metadata.set(StandardRecordMetadataKey.WEB_CONTENT_URL, null);
            this.metadata.setJson(StandardRecordMetadataKey.WEB_REDIRECT_URL, this.form.value.redirectUrl);
        } else {
            this.metadata.set(StandardRecordMetadataKey.WEB_REDIRECT_URL, null);
            this.metadata.set(StandardRecordMetadataKey.WEB_CONTENT_URL, null);
        }

        const params = {
            address: emptyStringToNull(this.domain.address),
            data: this.metadata,
            owner: this.domain.owner,
        };

        if (this.domain.owner === this.wallet.address || isOperator(this.domain, this.wallet.address)) {
            this.execute(client =>
                client.manager.updateRecord({
                    ...params,
                    name: this.domain.name,
                })
            );
        } else {
            this.execute(client =>
                client.manager.setChildRecord({
                    ...params,
                    label: getLabel(this.domain.name!),
                    parent: getParent(this.domain.name),
                })
            );
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private execute(op: (client: TaquitoTezosDomainsClient) => Promise<TransactionWalletOperation>) {
        this.form.disable();

        const operation = this.tezosService.execute(op);

        this.operation = operation;
    }

    private updateForm() {
        const mode = this.form.get('mode')!.value;
        const redirectUrl = this.form.get('redirectUrl')!;
        const contentUrl = this.form.get('contentUrl')!;
        if (mode === 'content') {
            redirectUrl.disable();
            contentUrl.enable();
        } else if (mode === 'redirect') {
            redirectUrl.enable();
            contentUrl.disable();
        } else {
            redirectUrl.disable();
            contentUrl.disable();
        }
    }
}
