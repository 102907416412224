import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { FavouritesTableComponent } from './favourites-table.component';
import { LatestFavouritesTableComponent } from './latest-favourites-table.component';
import { FavouritesPageComponent } from './favourites-page.component';
import { DomainCommonModule } from '../domain-common/domain-common.module';

@NgModule({
    declarations: [LatestFavouritesTableComponent, FavouritesTableComponent, FavouritesPageComponent],
    exports: [FavouritesPageComponent, LatestFavouritesTableComponent],
    imports: [SharedModule, DomainCommonModule],
})
export class FavouritesModule {}
