<div
    class="alert m-0 d-flex flex-column"
    [ngClass]="{
        'p-0': !title,
        success: state === 'success',
        danger: state === 'danger',
        warning: state === 'warning',
        info: state === 'info',
        small: small
    }"
>
    <h1 class="ms-2" *ngIf="title">{{ title }}</h1>
    <div class="mx-2 d-flex align-items-center">
        <div *ngIf="inProgress" class="me-2 ms-1">
            <mat-spinner [diameter]="24"></mat-spinner>
        </div>
        <ng-container *ngIf="!inProgress && showIcon">
            <fa-icon size="2x" class="alert-icon me-2" *ngIf="state === 'success'" icon="check-double"></fa-icon>
            <fa-icon size="2x" class="alert-icon me-2" *ngIf="state === 'danger'" icon="exclamation-circle"></fa-icon>
            <fa-icon size="2x" class="alert-icon me-2" *ngIf="state === 'warning'" icon="exclamation-triangle"></fa-icon>
            <fa-icon size="2x" class="alert-icon me-2" *ngIf="state === 'info'" icon="info-circle"></fa-icon>
        </ng-container>
        <div class="text-wrap w-100">
            <ng-content></ng-content>
        </div>
    </div>
</div>
