import { Component, OnInit } from '@angular/core';
import { TezosToolkitService } from '../tezos/integration/tezos-toolkit.service';
import { BlockGQL } from '../graphql/graphql.generated';
import { first } from 'rxjs/operators';

@Component({
    selector: 'td-state',
    templateUrl: './state.component.html',
    styleUrls: ['./state.component.scss'],
})
export class StateComponent implements OnInit {
    lastBlock?: number;
    lastIndexedBlock?: number;

    constructor(private tezosToolkitService: TezosToolkitService, private blockGQL: BlockGQL) {}

    ngOnInit(): void {
        this.loadBlockState();
    }

    private loadBlockState() {
        this.blockGQL.fetch({}, { fetchPolicy: 'no-cache' }).subscribe(b => {
            this.lastIndexedBlock = b.data.block?.level;
        });

        this.tezosToolkitService.current.pipe(first()).subscribe(async tezos => {
            const block = await tezos.rpc.getBlockHeader();
            this.lastBlock = block.level;
        });
    }
}
