import { PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CtaModule } from '../cta/cta.module';
import { DelegationModule } from '../delegation/delegation.module';
import { FiltersModule } from '../filters/filters.module';
import { SharedModule } from '../shared/shared.module';
import { SkeletonModule } from '../skeleton/skeleton.module';
import { AprPipe } from './apr.pipe';
import { ChangeDelegationComponent } from './change-delegation.component';
import { PoolCtaComponent } from './cta.component';
import { DelegateInfoComponent } from './delegate-info.component';
import { DepositComponent } from './deposit.component';
import { GovPageComponent } from './gov-page.component';
import { HistoryFilterComponent } from './history-filter.component';
import { HomebaseLogoComponent } from './homebase-logo.component';
import { ModalAprComponent } from './modal-apr.component';
import { GovPoolWidgetComponent } from './pool-widget.component';
import { SelfDelegatePageComponent } from './self-delegate-page.component';
import { WidgetAprComponent } from './widget-apr.component';
import { WithdrawComponent } from './withdraw.component';

@NgModule({
    declarations: [
        GovPoolWidgetComponent,
        GovPageComponent,
        DepositComponent,
        WithdrawComponent,
        HomebaseLogoComponent,
        AprPipe,
        HistoryFilterComponent,
        ChangeDelegationComponent,
        PoolCtaComponent,
        WidgetAprComponent,
        ModalAprComponent,
        SelfDelegatePageComponent,
        DelegateInfoComponent,
    ],
    providers: [PercentPipe],
    exports: [GovPoolWidgetComponent, PoolCtaComponent, AprPipe, DelegateInfoComponent],
    imports: [SharedModule, ReactiveFormsModule, FiltersModule, DelegationModule, SkeletonModule, CtaModule],
})
export class GovPoolModule {}
