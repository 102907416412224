<ng-container *transloco="let t">
    <div class="d-flex flex-column align-items-center" *ngIf="status === 'working'">
        <mat-progress-spinner [diameter]="72" color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>
    <div *ngIf="status === 'success'">
        <td-alert [title]="t('notifications-confirm.success-title')" state="success">
            {{ t('notifications-confirm.confirmed', { address: data.address }) }}
        </td-alert>
    </div>
    <div *ngIf="status === 'KeyNotFound'">
        <td-error [title]="t('notifications-confirm.error-title')" [message]="t('notifications-confirm.invalid-key')"></td-error>
    </div>
    <div *ngIf="status === 'KeyExpired'">
        <td-error [title]="t('notifications-confirm.error-title')" [message]="t('notifications-confirm.expired-key')"></td-error>
    </div>
    <div *ngIf="status === 'error'">
        <td-error [title]="t('notifications-confirm.error-title')" [message]="t('notifications-confirm.unknown-error')"></td-error>
    </div>
    <div class="mt-4 d-flex flex-column align-items-center">
        <a mat-stroked-button color="primary" [routerLink]="['/launch']">
            <fa-icon icon="layer-group"></fa-icon>
            {{ t('general.go-to-dashboard') }}
        </a>
    </div>
</ng-container>
