import { DataSource } from '@angular/cdk/table';

import { Unarray } from '../utils/types';
import { DataSourceBase, ProcessedData, DataSourceOptions } from '../data-source-base';
import { DomainsListQueryVariables, DomainsListQuery, DomainOrderField, OrderDirection, Maybe } from './graphql.generated';
import { getLevel, getParent } from '@tezos-domains/core';
import { Dayjs } from 'dayjs';

export type DomainListRecord = Unarray<DomainsListQuery['domains']['edges']>['node'];

export class DomainTableDataSource
    extends DataSourceBase<DomainsListQuery, DomainListRecord[], DomainsListQueryVariables>
    implements DataSource<DomainListRecord>
{
    constructor(options: DataSourceOptions<DomainsListQuery, DomainsListQueryVariables>) {
        super({
            ...options,
            defaultVariables: { first: 30 },
        });
    }

    protected transformData(data: DomainsListQuery): ProcessedData<DomainListRecord[]> {
        const baseLevel = this.loadMetadata.baseLevel || 2;
        const isReversed = this.loadVariables.order?.field === DomainOrderField.Domain && this.loadVariables.order.direction === OrderDirection.Desc;
        const isFiltered = !!this.loadVariables.where.name?.like;
        const domains = data.domains.edges.map(e => e.node);
        let result: DomainListRecord[] = [];

        if (isFiltered || this.loadMetadata.autofillHierarchy === false) {
            result = domains;
        } else {
            if (isReversed) {
                domains.reverse();
            }

            for (const domain of domains) {
                const parentName = getParent(domain.name);
                const parent = result.find(d => d.name === parentName);

                if (domain.level === baseLevel || parent) {
                    result.push(domain);
                } else {
                    const ancestor = this.getNearestExistingAncestor(result, parentName, baseLevel);
                    this.fill(domain.name, ancestor ? ancestor.level + 1 : baseLevel, domain.expires).forEach(d => result.push(d));
                    result.push(domain);
                }
            }

            if (isReversed) {
                result.reverse();
            }
        }

        return {
            data: result,
            pageInfo: data.domains.pageInfo,
            isEmpty: !data.domains.edges.length,
        };
    }

    private fill(from: string, level: number, expires: Maybe<Dayjs> | undefined) {
        let name = from;
        const fromLevel = getLevel(from);
        const result: DomainListRecord[] = [];

        for (let i = 0; i < fromLevel - level; i++) {
            name = getParent(name);

            result.unshift({
                name,
                level: getLevel(name),
                id: name,
                expires: expires ?? null,
                owner: 'generated',
                operators: [],
                data: [],
            });
        }

        return result;
    }

    private getNearestExistingAncestor(results: DomainListRecord[], name: string, baseLevel: number): DomainListRecord | null {
        while (true) {
            name = getParent(name);
            if (getLevel(name) < baseLevel) {
                return null;
            }

            const parent = results.find(r => r.name === name);
            if (parent) {
                return parent;
            }
        }
    }
}
