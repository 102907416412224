import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FaConfig, FaIconComponent, FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faApple, faDiscord, faDiscourse, faGithub, faGoogle, faTelegramPlane, faTwitter, faWindows } from '@fortawesome/free-brands-svg-icons';
import { faCheckCircle, faCircle, faClock, faDotCircle, faHeart } from '@fortawesome/free-regular-svg-icons';
import {
    faArrowAltCircleRight,
    faArrowLeft,
    faArrowRight,
    faAsterisk,
    faBackspace,
    faBars,
    faBell,
    faBook,
    faCalendarAlt,
    faCalendarWeek,
    faCheck,
    faCheckDouble,
    faChevronDown,
    faChevronUp,
    faCog,
    faCoins,
    faCommentAlt,
    faCommentDollar,
    faCommentSlash,
    faCommentsDollar,
    faCopy,
    faEdit,
    faEllipsisV,
    faEnvelope,
    faExclamationCircle,
    faExclamationTriangle,
    faExternalLinkAlt,
    faFileLines,
    faGavel,
    faGlobe,
    faInfoCircle,
    faLandmark,
    faLanguage,
    faLayerGroup,
    faLink,
    faMoon,
    faPaperPlane,
    faPeopleArrows,
    faPlus,
    faProjectDiagram,
    faQuestion,
    faQuestionCircle,
    faRankingStar,
    faRedoAlt,
    faRocket,
    faSearch,
    faShare,
    faShieldAlt,
    faCircle as faSolidCircle,
    faHeart as faSolidHeart,
    faSortAmountDown,
    faSortAmountUp,
    faSun,
    faTag,
    faTimes,
    faUnlink,
    faUserCircle,
    faUserPlus,
    faUserShield,
    faUserTimes,
    faUsers,
    faWallet,
} from '@fortawesome/free-solid-svg-icons';
import { TranslocoModule } from '@ngneat/transloco';
import { DynamicModule } from 'ng-dynamic-component';
import { AvatarModule } from 'ngx-avatars';
import { GravatarModule } from 'ngx-gravatar';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SHARE_BUTTONS_CONFIG, ShareButtonsConfig, ShareModule } from 'ngx-sharebuttons';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { GraphQLModule } from '../graphql/graphql.module';
import { LocaleModule } from '../i18n/transloco-root.module';
import { ActionInputComponent } from './action-input.component';
import { AddressEditorComponent } from './address-editor.component';
import { AddressPageComponent } from './address-page.component';
import { AddressComponent } from './address.component';
import { AdvancedSelectAddComponent } from './advanced-select-add.component';
import { AdvancedSelectComponent } from './advanced-select.component';
import { AlertComponent } from './alert.component';
import { BadgeComponent } from './badge.component';
import { CopyToClipboardComponent } from './copy-to-clipboard.component';
import { CountdownComponent } from './countdown.component';
import { TDCurrencyPipe } from './currency.pipe';
import { DarkModeComponent } from './dark-mode/dark-mode.component';
import { DataEditorComponent } from './data-editor/data-editor.component';
import { BytesComponent } from './data-editor/editors/bytes.component';
import { ContentUrlComponent } from './data-editor/editors/content-url.component';
import { GovernanceUrlComponent } from './data-editor/editors/governance-url.component';
import { EhtAddressComponent } from './data-editor/editors/eth-address.component';
import { GravatarComponent } from './data-editor/editors/gravatar.component';
import { JsonComponent } from './data-editor/editors/json.component';
import { NumberComponent } from './data-editor/editors/number.component';
import { StringComponent } from './data-editor/editors/string.component';
import { UnknownComponent } from './data-editor/editors/unknown.component';
import { DataKeyPipe } from './data-key.pipe';
import { DataViewerComponent } from './data-viewer.component';
import { DividerWithTextComponent } from './divider-with-text.component';
import { DomainComponent } from './domain.component';
import { DynamicHtmlDirective } from './dynamic-html.directive';
import { EmbeddableComponentsService } from './embeddable-components.service';
import { ErrorComponent } from './error.component';
import { EscapePipe } from './escape.pipe';
import { FormatMutezPipe } from './format-mutez.pipe';
import { FormatTedPipe } from './format-ted.pipe';
import { FromMutezPipe } from './from-mutez.pipe';
import { HorizontalScrollComponent } from './horizontal-scroll.component';
import { faTez, faTzProfiles } from './icon-definitions';
import { IdenticonComponent } from './identicon.component';
import { LandingPageUrlPipe } from './landing-page-url.pipe';
import { LanguageSwitcherComponent } from './language-switcher.component';
import { MoneyInputComponent } from './money-input.component';
import { OperationStatusComponent } from './operation-status.component';
import { PlainLinkComponent } from './plain-link.component';
import { ProgressButtonComponent } from './progress-button.component';
import { RecipientComponent } from './recipient.component';
import { ShareComponent } from './share.component';
import { TezComponent } from './tez.component';
import { TruncatePipe } from './truncate.pipe';
import { TrustAsHtmlPipe } from './trust-as-html.pipe';
import { TrustUrlPipe } from './trust-url.pipe';
import { TzprofileComponent } from './tzprofile.component';
import { ValidationMessagesComponent } from './validation-messages.component';
import { StarComponent } from './star.component';

const modules = [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    LocaleModule,
    CdkStepperModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatMenuModule,
    MatToolbarModule,
    MatChipsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatProgressBarModule,
    MatStepperModule,
    MatTooltipModule,
    MatTableModule,
    MatSortModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    MatListModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatRadioModule,
    MatCheckboxModule,
    GraphQLModule,
    TranslocoModule,
    InfiniteScrollModule,
    NgxSkeletonLoaderModule,
    DynamicModule,
    GravatarModule,
    AvatarModule,
    ShareModule,
];

@NgModule({
    declarations: [
        AddressComponent,
        DomainComponent,
        CopyToClipboardComponent,
        OperationStatusComponent,
        ValidationMessagesComponent,
        IdenticonComponent,
        AdvancedSelectComponent,
        AdvancedSelectAddComponent,
        TruncatePipe,
        AlertComponent,
        LanguageSwitcherComponent,
        DynamicHtmlDirective,
        TezComponent,
        ActionInputComponent,
        DataViewerComponent,
        DataEditorComponent,
        NumberComponent,
        StringComponent,
        BytesComponent,
        BadgeComponent,
        DataKeyPipe,
        AddressEditorComponent,
        JsonComponent,
        TrustAsHtmlPipe,
        LandingPageUrlPipe,
        GravatarComponent,
        TrustUrlPipe,
        CountdownComponent,
        PlainLinkComponent,
        MoneyInputComponent,
        AddressPageComponent,
        ErrorComponent,
        UnknownComponent,
        RecipientComponent,
        EscapePipe,
        ContentUrlComponent,
        TzprofileComponent,
        ShareComponent,
        HorizontalScrollComponent,
        DividerWithTextComponent,
        ProgressButtonComponent,
        DarkModeComponent,
        FromMutezPipe,
        FormatTedPipe,
        FormatMutezPipe,
        TDCurrencyPipe,
        GovernanceUrlComponent,
        EhtAddressComponent,
        StarComponent,
    ],
    providers: [FromMutezPipe, FormatMutezPipe, FormatTedPipe, CurrencyPipe],
    imports: [...modules, FormsModule, ReactiveFormsModule],
    exports: [
        ...modules,
        AddressComponent,
        DomainComponent,
        CopyToClipboardComponent,
        OperationStatusComponent,
        ValidationMessagesComponent,
        IdenticonComponent,
        AdvancedSelectComponent,
        TruncatePipe,
        AlertComponent,
        LanguageSwitcherComponent,
        DynamicHtmlDirective,
        TezComponent,
        ActionInputComponent,
        DataViewerComponent,
        DataEditorComponent,
        BadgeComponent,
        DataKeyPipe,
        AddressEditorComponent,
        TrustAsHtmlPipe,
        LandingPageUrlPipe,
        TrustUrlPipe,
        CountdownComponent,
        MoneyInputComponent,
        AddressPageComponent,
        ErrorComponent,
        RecipientComponent,
        EscapePipe,
        TzprofileComponent,
        HorizontalScrollComponent,
        ShareComponent,
        DividerWithTextComponent,
        ProgressButtonComponent,
        DarkModeComponent,
        FromMutezPipe,
        FormatTedPipe,
        FormatMutezPipe,
        TDCurrencyPipe,
        StarComponent,
    ],
})
export class SharedModule {
    constructor(faIconLibrary: FaIconLibrary, faConfig: FaConfig, embeddableComponentsService: EmbeddableComponentsService) {
        faConfig.fixedWidth = true;

        faIconLibrary.addIcons(
            faCopy,
            faCheckDouble,
            faCheck,
            faExclamationCircle,
            faLink,
            faUnlink,
            faBars,
            faPlus,
            faWallet,
            faEdit,
            faTag,
            faCircle,
            faGlobe,
            faExclamationTriangle,
            faChevronDown,
            faChevronUp,
            faCheckCircle,
            faDotCircle,
            faSearch,
            faRedoAlt,
            faBars,
            faQuestionCircle,
            faQuestion,
            faLink,
            faArrowLeft,
            faCog,
            faUserCircle,
            faUserPlus,
            faTimes,
            faFileLines,
            faShare,
            faInfoCircle,
            faEllipsisV,
            faLandmark,
            faLanguage,
            faCommentAlt,
            faArrowRight,
            faPaperPlane,
            faProjectDiagram,
            faTez,
            faTzProfiles,
            faRocket,
            faBackspace,
            faRankingStar,
            faUserShield,
            faUsers,
            faUserTimes,
            faCoins,
            faGavel,
            faLayerGroup,
            faBell,
            faExternalLinkAlt,
            faEnvelope,
            faGoogle,
            faCalendarWeek,
            faCalendarAlt,
            faWindows,
            faApple,
            faDiscord,
            faBars,
            faAsterisk,
            faShieldAlt,
            faBook,
            faPeopleArrows,
            faArrowAltCircleRight,
            faCommentDollar,
            faCommentsDollar,
            faCommentSlash,
            faClock,
            faTwitter,
            faGithub,
            faTelegramPlane,
            faDiscourse,
            faSun,
            faMoon,
            faSortAmountDown,
            faSortAmountUp,
            faSolidCircle,
            faHeart,
            faSolidHeart
        );

        embeddableComponentsService.registerEmbeddableComponent(DomainComponent);
        embeddableComponentsService.registerEmbeddableComponent(AddressComponent);
        embeddableComponentsService.registerEmbeddableComponent(TezComponent);
        embeddableComponentsService.registerEmbeddableComponent(PlainLinkComponent);
        embeddableComponentsService.registerEmbeddableComponent(FaIconComponent);
    }

    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                {
                    provide: MAT_DIALOG_DEFAULT_OPTIONS,
                    useValue: {
                        width: '600px',
                        height: '500px',
                        disableClose: true,
                        hasBackdrop: true,
                    } as MatDialogConfig,
                },
                {
                    provide: SHARE_BUTTONS_CONFIG,
                    useValue: {
                        twitterAccount: 'TezosDomains',
                    } as ShareButtonsConfig,
                },
            ],
        };
    }
}
