<ng-container *transloco="let t">
    <td-alert state="warning" class="mb-4" *ngIf="show">
        <div class="my-3 d-flex align-items-center">
            <span class="flex-grow-1">{{ t('app.offline') }}</span>
            <button class="mx-2 flex-shrink-0" mat-stroked-button (click)="check()">
                <fa-icon icon="redo-alt"></fa-icon>
                {{ t('actions.try-again') }}
            </button>
        </div>
    </td-alert>
</ng-container>
