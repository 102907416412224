import { Injectable, inject } from '@angular/core';

import { OpKind, TezosToolkit, TransferParams } from '@taquito/taquito';
import BigNumber from 'bignumber.js';
import { Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { TezosNetwork } from '../tezos/models';
import { TezosNetworkService } from '../tezos/tezos-network.service';
import { SmartContractOperationEvent, TezosService } from '../tezos/tezos.service';
import { AirdropClaim } from './models';

const isAirdrop = true;

export class GovActionImpl {
    private queuedOps: Array<(tezos: TezosToolkit, network: TezosNetwork) => Promise<TransferParams[]>> = [];

    constructor(private tezosNetworkService: TezosNetworkService, private tezosService: TezosService) {}

    claim(...claims: AirdropClaim[]): GovActionImpl {
        for (const claimData of claims) {
            this.queuedOps.push(async (tezos: TezosToolkit, network: TezosNetwork) => {
                const contract = await tezos.wallet.at(network.vestingContract);
                const claimOp = contract.methods.claim(claimData.amount, claimData.from, isAirdrop, claimData.proof).toTransferParams({ storageLimit: 400 });

                return [claimOp];
            });
        }

        return this;
    }

    deposit(amount: BigNumber, owner: string): GovActionImpl {
        this.queuedOps.push(async (tezos: TezosToolkit, network: TezosNetwork) => {
            const ops = [];

            const tedContract = await tezos.wallet.at(network.tedTokenContract);
            const poolContract = await tezos.wallet.at(network.tedPoolContract);

            const addOperatorOp = tedContract.methods
                .update_operators([{ add_operator: { owner, operator: poolContract.address, token_id: 0 } }])
                .toTransferParams({ storageLimit: 400 });

            const depositOp = poolContract.methods.deposit(amount).toTransferParams({ storageLimit: 400 });

            const removeOperatorOp = tedContract.methods
                .update_operators([{ remove_operator: { owner, operator: poolContract.address, token_id: 0 } }])
                .toTransferParams({ storageLimit: 400 });

            ops.push(addOperatorOp);
            ops.push(depositOp);
            ops.push(removeOperatorOp);

            return ops;
        });

        return this;
    }

    withdraw(amount: BigNumber, owner: string): GovActionImpl {
        this.queuedOps.push(async (tezos: TezosToolkit, network: TezosNetwork) => {
            const ops = [];

            const votesContract = await tezos.wallet.at(network.tedVotesContract);
            const poolContract = await tezos.wallet.at(network.tedPoolContract);

            const addOperatorOp = votesContract.methods
                .update_operators([{ add_operator: { owner, operator: poolContract.address, token_id: 0 } }])
                .toTransferParams({ storageLimit: 400 });

            const withdrawOp = poolContract.methods.withdraw(amount).toTransferParams({ storageLimit: 400 });

            const removeOperatorOp = votesContract.methods
                .update_operators([{ remove_operator: { owner, operator: poolContract.address, token_id: 0 } }])
                .toTransferParams({ storageLimit: 400 });

            ops.push(addOperatorOp);
            ops.push(withdrawOp);
            ops.push(removeOperatorOp);

            return ops;
        });

        return this;
    }

    delegate(address?: string): GovActionImpl {
        this.queuedOps.push(async (tezos: TezosToolkit, network: TezosNetwork) => {
            const votesContract = await tezos.wallet.at(network.tedVotesContract);
            const setDelegateOp = votesContract.methods.set_delegate(address).toTransferParams({ storageLimit: 400 });
            return [setDelegateOp];
        });

        return this;
    }

    removeDelegate(): GovActionImpl {
        return this.delegate(); // removes delegate if called with no address
    }

    send(): Observable<SmartContractOperationEvent> {
        if (this.queuedOps.length === 0) {
            throw new Error('No operations queued');
        }

        return this.tezosNetworkService.activeNetwork.pipe(
            first(),
            switchMap(network =>
                this.tezosService.execute(
                    async (_, tezos) => {
                        const batchOps: TransferParams[] = [];

                        for (const op of this.queuedOps) {
                            batchOps.push(...(await op(tezos, network)));
                        }

                        const batch = tezos.wallet.batch(
                            batchOps.map(o => ({
                                kind: OpKind.TRANSACTION,
                                ...o,
                            }))
                        );

                        return batch.send();
                    },
                    { waitForIndexer: false }
                )
            )
        );
    }
}

@Injectable({
    providedIn: 'root',
})
export class GovActionService {
    private tezosNetworkService = inject(TezosNetworkService);
    private tezosService = inject(TezosService);

    get q() {
        return new GovActionImpl(this.tezosNetworkService, this.tezosService);
    }
}
