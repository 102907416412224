import { Component, Input, OnChanges, SimpleChanges, OnInit, OnDestroy } from '@angular/core';
import { MediaObserver } from '@/shared/media-observer.service';
import { RecordMetadata, StandardRecordMetadataKey, ReverseRecordDomainInfo } from '@tezos-domains/core';
import { takeUntil, startWith } from 'rxjs/operators';
import { Subject, combineLatest, from } from 'rxjs';

import { TezosDomainsClientService } from '../tezos/integration/tezos-domains-client.service';
import { ReverseRecordService, ReverseRecord } from '../domains/reverse-record.service';
import { dataArrayToObj } from '../utils/convert';
import { TezosService } from '../tezos/tezos.service';
import { TezosNetworkService } from '../tezos/tezos-network.service';

export type AddressComponentReverseRecord = Pick<ReverseRecord, 'domain'> | null | undefined;

@Component({
    selector: 'td-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnChanges, OnInit, OnDestroy {
    @Input() address: string;
    @Input() link: boolean;
    @Input() copy: boolean;
    @Input() shorten: boolean;
    @Input() shortenName: boolean;
    @Input() icon: boolean;
    @Input() text?: boolean | 'address-only' | 'name-only' | 'name-or-address';
    @Input() stats: boolean;
    @Input() iconSize: number;
    @Input() reverseRecord: AddressComponentReverseRecord;
    @Input() resolveReverseRecord: boolean;
    @Input() route: any[];
    @Input() profile: boolean;
    @Input() customName: string;

    private _name: string | null;
    gravatarHash: string | null;
    gravatarEmail: string | null;
    statsLink: string | null;

    private unsubscribe = new Subject<void>();
    private changes = new Subject<void>();

    get name() {
        return this.customName ?? this._name;
    }

    get short() {
        if (typeof this.shorten === 'undefined') {
            return this.media.isActive('xs');
        } else {
            return this.shorten;
        }
    }

    constructor(
        private media: MediaObserver,
        private tezosDomainsClientService: TezosDomainsClientService,
        private reverseRecordService: ReverseRecordService,
        private tezosService: TezosService,
        private tezosNetworkService: TezosNetworkService
    ) {}

    ngOnInit() {
        combineLatest([
            this.tezosService.activeWallet,
            this.reverseRecordService.current,
            this.tezosDomainsClientService.current,
            this.tezosNetworkService.activeNetwork,
            this.changes.pipe(startWith(<unknown>null)), //get arround incorrectly reported deprecation warning
        ])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(([wallet, currentUserReverseRecord, client, network]) => {
                if (this.stats) {
                    this.statsLink = `${network.statsUrl}/${this.address}`;
                }

                if (!this.address) {
                    this.resetReverseRecord();
                    return;
                }

                if (this.reverseRecord) {
                    this.applyGQLReverseRecord(this.reverseRecord);
                    return;
                }

                if (this.resolveReverseRecord) {
                    if (this.address === wallet?.address && currentUserReverseRecord) {
                        this.applyGQLReverseRecord(currentUserReverseRecord);
                        return;
                    }

                    from(client.resolver.resolveReverseRecord(this.address)).subscribe(reverseRecord => {
                        if (reverseRecord) {
                            this.applyClientReverseRecord(reverseRecord);
                        }
                    });
                    return;
                }

                this.resetReverseRecord();
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.address || changes.resolveReverseRecord || changes.reverseRecord || changes.stats) {
            this.changes.next();
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private applyClientReverseRecord(reverseRecordDomain: ReverseRecordDomainInfo) {
        this._name = reverseRecordDomain.name;
        this.extractAvatar(reverseRecordDomain.data);
    }

    private applyGQLReverseRecord(reverseRecord: AddressComponentReverseRecord) {
        if (!reverseRecord || !reverseRecord.domain) {
            return;
        }

        this._name = reverseRecord.domain.name || null;
        this.extractAvatar(new RecordMetadata(dataArrayToObj(reverseRecord.domain.data)));
    }

    private resetReverseRecord() {
        this._name = null;
        this.gravatarEmail = null;
        this.gravatarHash = null;
    }

    private extractAvatar(data: RecordMetadata) {
        this.gravatarHash = data.getJson(StandardRecordMetadataKey.GRAVATAR_HASH);
        this.gravatarEmail = data.getJson(StandardRecordMetadataKey.OPENID_EMAIL);
    }
}
