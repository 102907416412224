<button mat-button [matMenuTriggerFor]="menu">
    <span>{{ language.label }}</span>
    <fa-icon class="ms-2 me-0" icon="chevron-down"></fa-icon>
</button>
<mat-menu #menu="matMenu">
    <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)" class="d-flex align-items-center">
        <fa-icon [icon]="['far', 'circle']" *ngIf="lang.id !== language.id"></fa-icon>
        <fa-icon class="accent" [icon]="['far', 'dot-circle']" *ngIf="lang.id === language.id"></fa-icon>
        <span>{{ lang.label }}</span>
    </button>
</mat-menu>
