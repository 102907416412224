<ng-container *transloco="let t">
    <td-alert [state]="'info'" *ngIf="vm$ | async; let canClaimAirdrop">
        <div class="my-1 d-flex align-items-center">
            <span class="fw-500 flex-grow-1">{{ t('airdrop.announcement.text') }}</span>
            <a class="mx-2 flex-shrink-0" mat-flat-button color="primary" [routerLink]="['/airdrop/start']">
                <fa-icon icon="rocket"></fa-icon>{{ t('airdrop.announcement.cta') }}
            </a>
        </div>
    </td-alert>
</ng-container>
