import { Component, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TaquitoTezosDomainsClient } from '@tezos-domains/taquito-client';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataSourceFactory } from '../../graphql/data-source-factory';
import { EventsDataSource } from '../../graphql/events-table-data-source';
import { EventsFilter, EventsQueryVariables } from '../../graphql/graphql.generated';
import { TezosDomainsClientService } from '../../tezos/integration/tezos-domains-client.service';
import { TezosNetwork } from '../../tezos/models';
import { TezosNetworkService } from '../../tezos/tezos-network.service';
import { ActivityListComponent } from './activity-list.component';
import { ActivityFormFilter } from './model';

@UntilDestroy()
@Component({
    selector: 'td-activity',
    templateUrl: './activity.component.html',
    styleUrls: ['./activity.component.scss'],
})
export class ActivityComponent implements OnInit {
    @ViewChild(ActivityListComponent) list: ActivityListComponent;

    viewModel$: Observable<{
        network: TezosNetwork;
        client: TaquitoTezosDomainsClient;
    }>;

    dataSource: EventsDataSource;

    private filter: ActivityFormFilter;

    constructor(
        private dataSourceFactory: DataSourceFactory,
        private tezosNetworkService: TezosNetworkService,
        private tezosDomainsClientService: TezosDomainsClientService
    ) {}

    ngOnInit(): void {
        this.dataSource = this.dataSourceFactory.createEventsDataSource();

        const activeNetwork$ = this.tezosNetworkService.activeNetwork;
        const tzDomainsClient$ = this.tezosDomainsClientService.current;

        this.viewModel$ = combineLatest([activeNetwork$, tzDomainsClient$]).pipe(map(([network, tzDomainsClient]) => ({ network, client: tzDomainsClient })));
    }

    filterChanged(filter: ActivityFormFilter) {
        this.filter = filter;
        this.loadData(this.filter);
    }

    private loadData(filter: ActivityFormFilter) {
        this.list?.resetScroll();

        const where = this.buildQueryVariables(filter);
        const variables: EventsQueryVariables = { where };

        this.dataSource.load(variables);
    }

    private buildQueryVariables(filter: ActivityFormFilter): EventsFilter {
        const where: EventsFilter = {
            block: {
                timestamp: { greaterThanOrEqualTo: filter.date.from, lessThanOrEqualTo: filter.date.to },
            },
        };

        if (filter.types.length) {
            where.type = { in: filter.types };
        }

        if (filter.address) {
            where.address = { equalTo: filter.address };
        }

        if (filter.domainName) {
            where.domainName = { equalTo: filter.domainName };
        }

        if (filter.price) {
            where.price = {};

            if (filter.price.from) {
                where.price.greaterThanOrEqualTo = filter.price.from;
            }

            if (filter.price.to) {
                where.price.lessThanOrEqualTo = filter.price.to;
            }
        }
        return where;
    }
}
