import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Subject } from 'rxjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import localeData from 'dayjs/plugin/localeData';
import dayjs from 'dayjs';

@Injectable({ providedIn: 'root' })
export class LocaleService {
    private locale: string;
    private localeStream = new Subject<string>();

    constructor(private dateAdapter: DateAdapter<dayjs.Dayjs>) {}

    get localeChange() {
        return this.localeStream.asObservable();
    }

    getLocale() {
        return this.locale;
    }

    setup() {
        dayjs.extend(relativeTime);
        dayjs.extend(localizedFormat);
        dayjs.extend(duration);
        dayjs.extend(utc);
        dayjs.extend(localeData);
    }

    async loadLocale(locale: string) {
        this.locale = locale;

        if (locale !== 'en') {
            const localeData = await import(`dayjs/locale/${locale}.js`);
            dayjs.locale(localeData);
        }

        this.dateAdapter.setLocale(locale);
        this.localeStream.next(locale);
    }
}
