<ng-container *transloco="let t">
    <div class="mb-3 me-2 d-flex" [style]="{ overflow: 'hidden' }">
        <td-horizontal-scroll class="flex-grow-1">
            <div class="d-flex gap-2">
                <td-address-filter (addressChange)="addressChanged($event)"></td-address-filter>
                <td-list-filter
                    [options]="leaderboardListOptions"
                    [buttonLabel]="'delegates-leaderboard.filters.list-types.button-label'"
                    [selectedOptions]="initialLeaderboardTypes"
                    (selectedChange)="leaderboardTypesChanged($event)"
                ></td-list-filter>
            </div>
        </td-horizontal-scroll>
        <td-sorter [defaultSort]="defaultSort" [sortOptions]="sortOptions" (sortChange)="sortChange($event)"></td-sorter>
    </div>
</ng-container>
