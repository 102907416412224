import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, Subject, combineLatest, race } from 'rxjs';
import { map, mapTo, skip, takeUntil, takeWhile } from 'rxjs/operators';
import { AirdropAnnouncementComponent } from '../airdrop/airdrop-announcement.component';

import { LatestFavouritesTableComponent } from '@/favourites/latest-favourites-table.component';
import { AuctionHistorySummaryTableComponent } from '../auctions/auction-history-summary-table.component';
import { AuctionSummaryTableComponent } from '../auctions/auction-summary-table.component';
import { BuyOfferListComponent } from '../buy-offers/buy-offer-list.component';
import { BuyOfferFilterModel, BuyOfferType } from '../buy-offers/buy-offer.models';
import { IgnoredOffersService } from '../buy-offers/ignored-offers.service';
import { Configuration } from '../configuration';
import { DomainSummaryTableComponent } from '../domains/domain-summary-table.component';
import { EventSummaryTableComponent } from '../events/event-summary-table.component';
import { OfferOrderField } from '../graphql/graphql.generated';
import { GovPoolWidgetComponent } from '../gv-pool/pool-widget.component';
import { OfferSummaryTableComponent } from '../offers/offer-summary-table.component';
import { ReverseRecordSummaryTableComponent } from '../reverse-records/reverse-record-summary-table.component';
import { AddressPageComponent } from '../shared/address-page.component';
import { TezosService } from '../tezos/tezos.service';
import { AuctionFundsComponent } from './auction-funds.component';

@UntilDestroy()
@Component({
    selector: 'td-address-detail',
    templateUrl: './address-detail.component.html',
    styleUrls: ['./address-detail.component.scss'],
})
export class AddressDetailComponent implements OnDestroy, AfterViewInit {
    showAuctionFunds: boolean;
    loading = new BehaviorSubject<boolean>(true);
    someOffersIgnored = false;
    visibleFavCount = 0;

    @ViewChild(AuctionSummaryTableComponent) auctions: AuctionSummaryTableComponent;
    @ViewChild(OfferSummaryTableComponent) offers: OfferSummaryTableComponent;
    @ViewChild(DomainSummaryTableComponent) domains: DomainSummaryTableComponent;
    @ViewChild(ReverseRecordSummaryTableComponent) reverseRecords: ReverseRecordSummaryTableComponent;
    @ViewChild(AuctionHistorySummaryTableComponent) auctionHistory: AuctionHistorySummaryTableComponent;
    @ViewChild(AuctionFundsComponent) auctionFunds: AuctionFundsComponent;
    @ViewChild(EventSummaryTableComponent) events: EventSummaryTableComponent;
    @ViewChild(AddressPageComponent) page: AddressPageComponent;
    @ViewChild('receivedBuyOfferList') receivedBuyOfferList: BuyOfferListComponent;
    @ViewChild('sentBuyOfferList') sentBuyOfferList: BuyOfferListComponent;
    @ViewChild(AirdropAnnouncementComponent) airdrop: AirdropAnnouncementComponent;
    @ViewChild(GovPoolWidgetComponent) govPool: GovPoolWidgetComponent;
    @ViewChild(LatestFavouritesTableComponent) latestFavs: LatestFavouritesTableComponent;

    private unsubscribe = new Subject<void>();

    receivedBuyOfferFilter: BuyOfferFilterModel = {
        sorting: { label: '', value: OfferOrderField.CreatedAt, direction: 'desc' },
        allOfferTypesSelected: false,
        offerTypes: [BuyOfferType.Received],
    };

    sentBuyOfferFilter: BuyOfferFilterModel = {
        sorting: { label: '', value: OfferOrderField.CreatedAt, direction: 'desc' },
        allOfferTypesSelected: false,
        offerTypes: [BuyOfferType.Sent],
    };

    airdropStarted = this.config.airdrop.start < new Date();

    constructor(
        private tezosService: TezosService,
        private cdr: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        private ignoredOffersService: IgnoredOffersService,
        private config: Configuration
    ) {
        this.ignoredOffersService.ignoredOffers$.pipe(untilDestroyed(this)).subscribe(offers => (this.someOffersIgnored = !!offers.size));
    }

    ngAfterViewInit() {
        this.tezosService.activeWallet.pipe(takeUntil(this.unsubscribe)).subscribe(w => {
            if (!this.page.validAddress) {
                return;
            }

            const all: Observable<any>[] = [
                this.auctions.dataSource.initialLoading$,
                this.domains.dataSource.initialLoading$,
                this.reverseRecords.dataSource.initialLoading$,
                this.auctionHistory.dataSource.initialLoading$,
                this.auctionHistory.totalDataSource.initialLoading$,
                this.offers.dataSource.initialLoading$,
            ];

            if (w?.address === this.page.address) {
                this.cdr.detectChanges();

                all.push(this.receivedBuyOfferList.dataLoading);
                all.push(this.sentBuyOfferList.dataLoading);
                all.push(this.airdrop.vm$.pipe(mapTo(false)));

                if (this.govPool) {
                    all.push(this.govPool.loading$.pipe(takeWhile(x => x == true, true)));
                }

                if (this.latestFavs) {
                    all.push(this.latestFavs.initialLoading);
                }

                all.push(this.auctionFunds.bidderBalance.pipe(map(b => !b)));
                if (this.events.dataSource) {
                    all.push(this.events.dataSource.initialLoading$);
                }
            }

            combineLatest(all)
                .pipe(takeUntil(race(this.activatedRoute.params.pipe(skip(1)), this.unsubscribe)))
                .subscribe(r => setTimeout(() => this.loading.next(r.some(x => x)), 0));
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
