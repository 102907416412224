<div class="d-flex flex-column" *transloco="let t">
    <td-error
        *ngIf="!validAddress"
        [message]="t('general.invalid-address-error', { address: address | escape })"
        [title]="t('general.title-invalid-address')"
    ></td-error>

    <ng-container *ngIf="initialized && wallet !== undefined && validAddress">
        <div class="d-flex align-items-center" [class.mb-2]="!disableBottomTitlePadding">
            <div *ngIf="wallet && address === wallet.address" class="w-100 flex-grow-1">
                <ng-content select="[my-title]"></ng-content>
            </div>
            <div *ngIf="!wallet || address !== wallet.address" class="w-100 flex-grow-1">
                <h1 class="m-0"><ng-content select="[other-subtitle]"></ng-content></h1>
                <td-address
                    [reverseRecord]="address === wallet?.address ? currentUserReverseRecord : reverseRecord"
                    [address]="address"
                    [link]="false"
                    class="mt-2 mb-3 ms-3"
                    [profile]="true"
                ></td-address>
            </div>
            <div class="flex-shrink-0">
                <ng-content select="[title-side]"></ng-content>
            </div>
        </div>
    </ng-container>

    <ng-content *ngIf="initialized"></ng-content>
</div>
