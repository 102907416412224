import { DataSource } from '@angular/cdk/table';

import { Unarray } from '../utils/types';
import { DataSourceBase, DataSourceOptions, ProcessedData } from '../data-source-base';
import { BuyOfferListQuery, BuyOfferListQueryVariables } from './graphql.generated';

export type BuyOfferRecord = Unarray<BuyOfferListQuery['buyOffers']['edges']>['node'];

export class BuyOfferTableDataSource
    extends DataSourceBase<BuyOfferListQuery, BuyOfferRecord[], BuyOfferListQueryVariables>
    implements DataSource<BuyOfferRecord>
{
    constructor(options: DataSourceOptions<BuyOfferListQuery, BuyOfferListQueryVariables>) {
        super({
            ...options,
            defaultVariables: { first: 30 },
        });
    }

    protected transformData(data: BuyOfferListQuery): ProcessedData<BuyOfferRecord[]> {
        return {
            data: data.buyOffers.edges.map(e => e.node),
            pageInfo: data.buyOffers.pageInfo,
            isEmpty: !data.buyOffers.edges.length,
        };
    }
}
