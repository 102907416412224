import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { generateNonce } from '@tezos-domains/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { StorageSchema } from '../utils/storage';

@Injectable({
    providedIn: 'root',
})
export class NonceService {
    constructor(private storageMap: StorageMap) {}

    getNonce(name: string, owner: string): Observable<number> {
        const storageMeta = StorageSchema.nonce(name, owner);

        return this.storageMap.get(storageMeta.key, storageMeta.schema).pipe(
            map(nonce => {
                if (nonce == null) {
                    nonce = generateNonce();
                    this.storageMap.set(storageMeta.key, nonce, storageMeta.schema).subscribe();
                }
                return nonce;
            })
        );
    }

    clearNonce(name: string, owner: string) {
        this.storageMap.delete(StorageSchema.nonce(name, owner).key).subscribe();
    }
}
