import { AbortedBeaconError, TransactionInvalidBeaconError } from '@airgap/beacon-sdk';
import { Injectable } from '@angular/core';
import { InternalOperationResult, OperationContentsAndResultTransaction } from '@taquito/rpc';
import { TezosToolkit, WalletOperation } from '@taquito/taquito';
import { LatinDomainNameValidator } from '@tezos-domains/core';
import { TaquitoTezosDomainsClient } from '@tezos-domains/taquito-client';
import { Observable, ReplaySubject, Subject, combineLatest, from, of, throwError } from 'rxjs';
import { filter, first, map, shareReplay, skip, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Logger } from '../browser/logger';
import { TrackingService } from '../browser/tracking.service';
import { extractErrorCode } from '../utils/convert';
import { ClaimableTldsService } from './claimable-tlds.service';
import { TezosBeaconWalletManager } from './connector/tezos-wallet-manager';
import { IndexerService } from './indexer.service';
import { TezosDomainsClientService } from './integration/tezos-domains-client.service';
import { TezosToolkitService } from './integration/tezos-toolkit.service';
import { TezosError, TezosErrorCode, TezosNetwork, TezosWallet } from './models';
import { TezosNetworkService } from './tezos-network.service';
import { TezosWalletService } from './tezos-wallet.service';

export class SmartContractOperationEvent {}

export class SmartContractOperationPermissionsGrantedEvent {}

export class SmartContractOperationSentEvent {
    constructor(public readonly operation: WalletOperation) {}
}

export class SmartContractOperationConfirmationEvent {
    constructor(public readonly currentConfirmation: number, public readonly expectedConfirmations: number) {}
}

export class SmartContractOperationCompletedEvent {}

@Injectable({
    providedIn: 'root',
})
export class TezosService {
    private network: TezosNetwork;
    private unsubscribe = new Subject<void>();

    private activeWalletStream = new ReplaySubject<TezosWallet | null>(1);
    get activeWallet(): Observable<TezosWallet | null> {
        return this.activeWalletStream;
    }

    private tezosToolkitStream = new ReplaySubject<TezosToolkit>(1);
    get tezosToolkit(): Observable<TezosToolkit> {
        return this.tezosToolkitStream;
    }

    constructor(
        tezosNetworkService: TezosNetworkService,
        tezosBeaconWalletManager: TezosBeaconWalletManager,
        private tezosToolkitService: TezosToolkitService,
        private tezosDomainsClientService: TezosDomainsClientService,
        private tezosWalletService: TezosWalletService,
        private indexerService: IndexerService,
        private log: Logger,
        private trackingService: TrackingService,
        claimableTldsService: ClaimableTldsService
    ) {
        tezosNetworkService.activeNetwork
            .pipe(
                tap(n => (this.network = n)),
                switchMap(() => tezosBeaconWalletManager.activeWallet)
            )
            .subscribe(c => {
                this.unsubscribe.next();

                this.activeWalletStream.next(this.tezosWalletService.activateWallet(c));
                this.tezosToolkitService.setConfig(this.network.rpcUrl, { wallet: tezosBeaconWalletManager.beaconWallet });
                this.tezosToolkitService.current.pipe(first()).subscribe(t => this.tezosToolkitStream.next(t));
            });

        combineLatest([tezosToolkitService.current, claimableTldsService.getTlds()])
            .pipe(withLatestFrom(tezosNetworkService.activeNetwork))
            .subscribe(([[tezos, tlds], network]) => {
                this.tezosDomainsClientService.setConfig({
                    tezos: tezos,
                    network: network.name as any,
                    caching: { enabled: true },
                    contractAddresses: network.customContractAddresses,
                    tlds: network.customTlds ? network.customTlds.map(t => ({ name: t, validator: LatinDomainNameValidator })) : undefined,
                    claimableTlds: tlds.map(t => ({ name: t, validator: LatinDomainNameValidator })),
                });
            });
    }

    execute(
        fn: (client: TaquitoTezosDomainsClient, tezos: TezosToolkit) => Promise<WalletOperation>,
        { usingGovIndexer = false, waitForIndexer = true }: { usingGovIndexer?: boolean; waitForIndexer: boolean } = { waitForIndexer: true }
    ): Observable<SmartContractOperationEvent> {
        return new Observable(observer => {
            observer.next(new SmartContractOperationPermissionsGrantedEvent());

            this.activeWallet
                .pipe(
                    switchMap(w => w!.isBalanceLow.pipe(map(l => ({ wallet: w!, isLow: l })))),
                    first(),
                    switchMap(x => {
                        if (x.isLow) {
                            this.tezosWalletService.refreshBalance();

                            return x.wallet.isBalanceLow.pipe(skip(1));
                        } else {
                            return of(false);
                        }
                    }),
                    switchMap(isBalanceLow => {
                        if (isBalanceLow) {
                            return throwError(() => new TezosError('Insufficient balance.', TezosErrorCode.CONTRACT_BALANCE_TOO_LOW));
                        } else {
                            return combineLatest([this.tezosDomainsClientService.current, this.tezosToolkitService.current]);
                        }
                    }),
                    first(),
                    switchMap(([c, t]) => from(this.executeWithCatch(() => fn(c, t)))),
                    tap(operation => observer.next(new SmartContractOperationSentEvent(operation))),
                    switchMap(operation => {
                        const confirmations = operation.confirmationObservable(this.network.confirmations).pipe(filter(c => c.currentConfirmation > 0));

                        const firstConfirmation = confirmations.pipe(first());
                        const lastConfirmation = confirmations.pipe(first(c => c.currentConfirmation === c.expectedConfirmation));

                        return combineLatest([
                            confirmations.pipe(map(confirmation => ({ operation, confirmation }))),
                            confirmations.pipe(
                                first(),
                                switchMap(() => from(operation.operationResults()))
                            ),
                            combineLatest([firstConfirmation, lastConfirmation]).pipe(
                                switchMap(([first, _]) =>
                                    waitForIndexer ? this.indexerService.whenBlockIndexed(first.block.hash, usingGovIndexer).pipe(map(() => true)) : of(true)
                                ),
                                startWith(false)
                            ),
                        ]);
                    })
                )
                .subscribe({
                    next: ([update, operationResults, isIndexed]) => {
                        const transactionOperations = operationResults?.map(r => r as OperationContentsAndResultTransaction) ?? [];
                        if (transactionOperations.some(t => t.metadata.operation_result.status !== 'applied')) {
                            const failedTransactions = transactionOperations
                                .map(t => t.metadata.internal_operation_results?.find(o => o.result.status === 'failed'))
                                .filter(r => !!r)
                                .map(r => r as InternalOperationResult);
                            let errorCode: TezosErrorCode | null = null;
                            if (failedTransactions.length) {
                                errorCode = extractErrorCode(failedTransactions[0].result.errors);
                            }

                            if (!errorCode) {
                                const backtrackedOperations = transactionOperations.filter(t => t.metadata.operation_result.status === 'backtracked');
                                if (backtrackedOperations.length) {
                                    errorCode = extractErrorCode(backtrackedOperations[0].metadata.operation_result.errors);
                                }
                            }

                            observer.error(
                                new TezosError(
                                    `Operation was not applied (statuses: ${transactionOperations.map(t => t.metadata.operation_result.status).join(', ')}).`,
                                    errorCode
                                )
                            );
                            return;
                        }

                        if (update.confirmation.completed && isIndexed) {
                            observer.next(new SmartContractOperationCompletedEvent());
                            observer.complete();
                            this.tezosWalletService.refreshBalance();
                        } else {
                            observer.next(
                                new SmartContractOperationConfirmationEvent(update.confirmation.currentConfirmation, update.confirmation.expectedConfirmation)
                            );
                        }
                    },
                    error: err => {
                        observer.error(err);
                    },
                });
        }).pipe(shareReplay<SmartContractOperationEvent>());
    }

    private async executeWithCatch(fn: () => Promise<WalletOperation>) {
        try {
            return await fn();
        } catch (err) {
            this.log.error('Error executing operation', err);
            const error = this.parseError(err);

            this.trackingService.exception(error.errorCode || error.message);

            throw error;
        }
    }

    private parseError(error: Error): TezosError {
        let errorCode: TezosErrorCode | null = null;
        if (error instanceof TransactionInvalidBeaconError) {
            errorCode = extractErrorCode(error.data as any);
        }

        if (error instanceof AbortedBeaconError) {
            errorCode = TezosErrorCode.ABORTED;
        }

        return new TezosError(error.message, errorCode);
    }
}
