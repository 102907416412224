import { DataSourceBase, ProcessedData, DataSourceOptions } from '../data-source-base';
import { AuctionDetailQuery, AuctionDetailQueryVariables } from './graphql.generated';

export class AuctionDetailDataSource extends DataSourceBase<AuctionDetailQuery, AuctionDetailQuery, AuctionDetailQueryVariables> {
    constructor(options: DataSourceOptions<AuctionDetailQuery, AuctionDetailQueryVariables>) {
        super(options);
    }

    protected transformData(data: AuctionDetailQuery): ProcessedData<AuctionDetailQuery> {
        return {
            data,
        };
    }
}
