<div *transloco="let t">
    <td-error *ngIf="dataSource.error$ | async; let error" [message]="error.message" [title]="t('general.error')"></td-error>

    <div class="table-filter">
        <form [formGroup]="form" action="." (ngSubmit)="reload()" class="d-flex flex-column flex-sm-row justify-content-between">
            <div class="d-flex flex-grow-1 flex-sm-grow-0">
                <mat-form-field class="flex-grow-1 flex-sm-grow-0" appearance="standard" floatLabel="never">
                    <input
                        matInput
                        formControlName="filter"
                        autocapitalize="off"
                        autocomplete="off"
                        autocorrect="off"
                        type="search"
                        type="search"
                        [placeholder]="t('domain-table.filter-placeholder')"
                    />
                    <fa-icon matSuffix class="clear-button primary" icon="times" (click)="form.get('filter')!.setValue('')"></fa-icon>
                </mat-form-field>
            </div>
            <mat-button-toggle-group class="categories-button" formControlName="category" *ngIf="wallet?.address === address">
                <mat-button-toggle *ngFor="let category of categories" class="category-button" color="primary" [value]="category.name">
                    <!-- t(auction-table.filter-category-all) -->
                    <!-- t(auction-table.filter-category-won) -->
                    <!-- t(auction-table.filter-category-lost) -->
                    {{ t('auction-table.filter-category-' + category.name) }}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </form>
    </div>

    <div *ngIf="(dataSource.empty$ | async) && (hideResults | async) === false" class="empty-message p-3 w-100 text-center smooth-shadow">
        {{ t('auction-history-table.no-auctions') }}
    </div>

    <div class="table-container smooth-shadow d-flex" infiniteScroll (scrolled)="scrolled()" [hidden]="(dataSource.empty$ | async) || (hideResults | async)">
        <mat-table
            class="table"
            [dataSource]="dataSource"
            matSort
            (matSortChange)="reload()"
            [matSortActive]="sortField"
            [matSortDirection]="sortDirection"
            matSortDisableClear
            #sort="matSort"
        >
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header="DOMAIN_NAME">{{ t('fields.name') }}</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div class="text-truncate w-100">
                        <td-domain [domain]="row.domainName" [route]="['/auction', row.domainName, row.startedAtLevel]"></td-domain>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="ended">
                <mat-header-cell class="col-ended" *matHeaderCellDef mat-sort-header="ENDS_AT">{{ t('fields.ended') }}</mat-header-cell>
                <mat-cell class="col-ended" *matCellDef="let row">
                    <span [matTooltip]="row.endsAtUtc | dayjsDate">
                        <td-humanized-date [value]="row.endsAtUtc"></td-humanized-date>
                    </span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="status">
                <mat-header-cell class="col-status" *matHeaderCellDef>{{ t('fields.status') }}</mat-header-cell>
                <mat-cell class="col-status" *matCellDef="let row">
                    <td-badge *ngIf="row.highestBid.bidder !== address" color="danger">
                        {{ t('auction-table.status.lost') }}
                    </td-badge>
                    <td-badge *ngIf="row.highestBid.bidder === address" color="success">
                        {{ t('auction-table.status.won') }}
                    </td-badge>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="highestBid">
                <mat-header-cell class="col-bid" *matHeaderCellDef mat-sort-header="HIGHEST_BID_AMOUNT">{{ t('fields.winning-bid') }}</mat-header-cell>
                <mat-cell class="col-bid" *matCellDef="let row">
                    <td-tez [value]="row.highestBid.amount"></td-tez>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell class="col-actions" *matHeaderCellDef></mat-header-cell>
                <mat-cell class="col-actions" *matCellDef="let row">
                    <a mat-flat-button color="primary" [routerLink]="['/auction', row.domainName, row.startedAtLevel]">{{ t('actions.view-history') }}</a>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columns"></mat-row>
        </mat-table>
    </div>
    <div *ngIf="dataSource.loading$ | async" class="w-100 mt-3 d-flex justify-content-center">
        <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>
</div>
