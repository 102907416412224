<ng-container *transloco="let t">
    <a
        *ngIf="showGenericLink && profile"
        [attr.href]="tzprofileLink"
        target="_blank"
        class="ms-1 no-underline"
        [matTooltip]="t('general.go-to-tzprofile', { who: profile.alias })"
    >
        <fa-icon [icon]="[$any('fac'), $any('tzprofiles')]"></fa-icon>
    </a>

    <div *ngIf="!showGenericLink" class="d-flex gap-2">
        <ng-container *ngFor="let network of profile?.verified">
            <td-copy-to-clipboard
                *ngIf="network.type === 'discord'; else linkTemplate"
                [matTooltip]="t('airdrop.delegate.tzprofile-tooltip', { profile: network.value })"
                [customIcon]="true"
                [text]="network.value"
                class="no-underline"
                (click)="$event.stopPropagation()"
            >
                <fa-icon [icon]="icon.get(network.type)!" class="social-icon"></fa-icon>
            </td-copy-to-clipboard>
            <ng-template #linkTemplate>
                <a
                    href="{{ network.value }}"
                    target="_blank"
                    [matTooltip]="t('airdrop.delegate.tzprofile-link-tooltip', { profile: network.value })"
                    class="no-underline"
                >
                    <fa-icon [icon]="icon.get(network.type)!" class="social-icon"></fa-icon>
                </a>
            </ng-template>
        </ng-container>
    </div>
</ng-container>
