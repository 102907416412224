<div class="domain w-100" *transloco="let t">
    <a *ngIf="link !== false" [routerLink]="route || ['/domain', domain]" class="text-truncate">{{ domain | truncate : maxChars }}</a>
    <span *ngIf="link === false" class="text-truncate">{{ domain | truncate : maxChars }}</span>
    <td-star [name]="domain" *ngIf="favourite"></td-star>
    <td-copy-to-clipboard
        class="no-underline"
        [matTooltip]="t('general.copy-to-clipboard', { what: 'name' })"
        *ngIf="copy"
        [text]="domain"
    ></td-copy-to-clipboard>
    <td-tzprofile *ngIf="profileFor" [address]="profileFor"></td-tzprofile>
</div>
