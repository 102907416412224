<div *transloco="let t" class="d-flex flex-column">
    <mat-form-field appearance="standard" class="w-100">
        <mat-label>{{ key }}</mat-label>
        <input matInput [formControl]="innerControl" />
        <mat-error>
            <td-validation-messages [control]="innerControl" [context]="{ name: key }"></td-validation-messages>
        </mat-error>
    </mat-form-field>
    <a [attr.href]="'https://blog.tezos.domains/tezos-domains-is-looking-for-delegates-a1949706a8e6'" target="_blank" class="pt-2">
        <fa-icon icon="question-circle" class="me-1"></fa-icon>{{ t('data-editor.governance-url.faq') }}
    </a>
</div>
