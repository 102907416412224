import { Directive, Input, ElementRef, OnDestroy, DoCheck, Injector } from '@angular/core';

import { EmbeddableComponentsService, DynamicHtml } from './embeddable-components.service';

@Directive({
    selector: '[tdDynamicHtml]',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        '[class.dynamic-html]': 'true',
    },
})
export class DynamicHtmlDirective implements OnDestroy, DoCheck {
    @Input() set tdDynamicHtml(value: string) {
        this.destroy();
        this.dynamicHtml = this.embeddableComponentsService.createDynamicHtml(this.elementRef.nativeElement, this.injector, value);
    }

    private dynamicHtml: DynamicHtml;

    constructor(private elementRef: ElementRef<HTMLElement>, private embeddableComponentsService: EmbeddableComponentsService, private injector: Injector) {}

    ngDoCheck() {
        this.dynamicHtml.detectChanges();
    }

    ngOnDestroy() {
        this.destroy();
    }

    private destroy() {
        if (this.dynamicHtml) {
            this.dynamicHtml.destroy();
        }
    }
}
