import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BulkDomainsDataService } from '../../file-processing/file-data.service';
import { TezosWallet } from '../../tezos/models';
import { TezosService } from '../../tezos/tezos.service';
import { DomainsForRegistration, RegistrationDomainsStateService } from './registration-domains.state.service';

@UntilDestroy()
@Component({
    selector: 'td-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
    private fileData = inject(BulkDomainsDataService);
    private tezosService = inject(TezosService);
    private router = inject(Router);
    private stateService = inject(RegistrationDomainsStateService);

    wallet?: TezosWallet | null;
    data: DomainsForRegistration;

    ngOnInit() {
        this.tezosService.activeWallet.pipe(untilDestroyed(this)).subscribe(w => {
            this.wallet = w;
        });

        const data = this.stateService.domains;
        if (!data) {
            return this.router.navigateByUrl('/multi-registration');
        }

        this.data = data;
    }

    domainsBought() {
        this.fileData.clear();
        this.stateService.clear();

        this.router.navigateByUrl(`/address/${this.wallet!.address}/domains`);
    }
}
