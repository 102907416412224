import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import dayjs from 'dayjs';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, Subject, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { FilterHistoryStateService } from '../browser/filter-history-state.service';
import { Configuration } from '../configuration';
import { ListOption, ListOptionType } from '../filters/list-filter.component';
import { DateFilter } from '../filters/model';
import { RadioListOption } from '../filters/radio-list-filter.component';
import { SortOption } from '../filters/sorter.component';
import { DomainOrderField } from '../graphql/graphql.generated';
import { HorizontalScrollComponent } from '../shared/horizontal-scroll.component';

export enum DomainCategory {
    MoreThanFiveLetters = 'moreThanFiveLetters',
    FourLetters = 'fourLetters',
    ThreeLetters = 'threeLetters',
}

export enum DomainNameType {
    All = 'all',
    Numbers = 'numbers',
    Letters = 'letters',
}

export interface ExpiredDomainsFilter {
    domainName?: string;
    allCategoriesSelected: boolean;
    categories: DomainCategory[];
    sorting: SortOption;
    domainNameType: DomainNameType;
    expirationDate: DateFilter | null;
}

export interface ExpiredDomainFilterState {
    hotOfferCategories?: DomainCategory[];
    domainNameType?: DomainNameType;
    hotOfferDomain?: string;
    hotOfferSorting?: SortOption;
    expirationDate?: DateFilter | null;
}

@Component({
    selector: 'td-expired-domains-filter',
    templateUrl: './expired-domains-filter.component.html',
    styleUrls: ['./expired-domains-filter.component.scss'],
})
export class ExpiredDomainsFilterComponent implements OnInit, OnDestroy {
    @Output() filterChange = new EventEmitter<ExpiredDomainsFilter>();

    @ViewChild(HorizontalScrollComponent) horizontalScroll: HorizontalScrollComponent;

    sortOptions: SortOption[] = [
        { label: this.transloco.translate('expired-domains.sort.name-asc'), value: DomainOrderField.Domain, direction: 'asc' },
        { label: this.transloco.translate('expired-domains.sort.name-desc'), value: DomainOrderField.Domain, direction: 'desc' },
        { label: this.transloco.translate('expired-domains.sort.recent'), value: DomainOrderField.ExpiresAt, direction: 'desc' },
        { label: this.transloco.translate('expired-domains.sort.oldest'), value: DomainOrderField.ExpiresAt, direction: 'asc' },
    ];
    defaultSort = this.sortOptions[2];

    initialDomain = '';
    initialOfferCategories: DomainCategory[] = [];
    readonly defaultDomainNameType = DomainNameType.All;
    initialDomainNameType = DomainNameType.All;
    initialSorting = this.defaultSort;

    maxDate = dayjs().endOf('day');
    minDate = dayjs(this.config.minEventsFilterDate);

    initialDateFilter: DateFilter | null = null;

    private categoryFilters = [DomainCategory.MoreThanFiveLetters, DomainCategory.FourLetters, DomainCategory.ThreeLetters];
    categoryOptions: ListOption[] = this.categoryFilters.map(c => this.categoryToOption(c));
    domainNameTypeOptions: RadioListOption[] = [
        new RadioListOption(DomainNameType.All, this.transloco.translate(`expired-domains.domain-name-type.all`)),
        new RadioListOption(DomainNameType.Numbers, this.transloco.translate(`expired-domains.domain-name-type.numbers`)),
        new RadioListOption(DomainNameType.Letters, this.transloco.translate(`expired-domains.domain-name-type.letters`)),
    ];

    private offerCategory$: BehaviorSubject<DomainCategory[]>;
    private domainNameTypes$: BehaviorSubject<DomainNameType>;
    private domain$: BehaviorSubject<string>;
    private sorting$: BehaviorSubject<SortOption>;
    private date$: BehaviorSubject<DateFilter | null>;

    private destroyed$ = new Subject<void>();
    private formSubscription: Subscription | null = null;

    constructor(private historyStateService: FilterHistoryStateService, private transloco: TranslocoService, private config: Configuration) {}

    ngOnInit(): void {
        const state = this.historyStateService.get<ExpiredDomainFilterState>();

        this.initialDateFilter = state.expirationDate ?? null;

        this.initialDomain = state.hotOfferDomain ?? '';
        this.initialOfferCategories = state.hotOfferCategories ?? [];
        this.initialSorting = state.hotOfferSorting ?? this.defaultSort;
        this.initialDomainNameType = state.domainNameType ?? this.defaultDomainNameType;

        this.offerCategory$ = new BehaviorSubject<DomainCategory[]>(this.initialOfferCategories);
        this.domainNameTypes$ = new BehaviorSubject<DomainNameType>(state.domainNameType ?? this.defaultDomainNameType);
        this.domain$ = new BehaviorSubject<string>(this.initialDomain);
        this.sorting$ = new BehaviorSubject<SortOption>(this.initialSorting);
        this.date$ = new BehaviorSubject<DateFilter | null>(this.initialDateFilter);

        this.initializeFilter();
    }

    categoriesChanged(data: string[]) {
        this.offerCategory$.next(data.map(x => <DomainCategory>x));
    }

    domainChanged(data: string) {
        this.domain$.next(data);
    }

    domainNameTypeChanged(data: string) {
        this.domainNameTypes$.next(<DomainNameType>data);
    }

    dateChanged(data: DateFilter | null) {
        this.date$.next(data ?? this.initialDateFilter);
    }

    sortChange(data: SortOption): void {
        this.sorting$.next(data);
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();

        this.formSubscription?.unsubscribe();
        this.formSubscription = null;
    }

    private initializeFilter() {
        this.formSubscription?.unsubscribe();
        this.formSubscription = combineLatest([this.offerCategory$, this.domainNameTypes$, this.domain$, this.sorting$, this.date$])
            .pipe(
                tap(([types, domainNameType, domain, sorting, date]) => this.saveState(types, domainNameType, domain, sorting, date)),
                map(([types, domainNameType, domain, sorting, date]) => this.toFilterModel(types, domainNameType, domain, sorting, date)),
                distinctUntilChanged<ExpiredDomainsFilter>(isEqual)
            )
            .subscribe(filter => {
                this.horizontalScroll?.refreshScroll();
                this.filterChange.next(filter);
            });
    }

    private categoryToOption(categ: DomainCategory): ListOption {
        return new ListOption(categ, this.translateCategory(categ), ListOptionType.Item);
    }
    private translateCategory(categ: DomainCategory): string {
        /**
         * t(expired-domains.category.moreThanFiveLetters)
         * t(expired-domains.category.fourLetters)
         * t(expired-domains.category.threeLetters)
         */
        return this.transloco.translate(`expired-domains.category.${categ}`);
    }

    private toFilterModel(
        offerCategories: DomainCategory[],
        domainNameType: DomainNameType,
        domain: string,
        sorting: SortOption,
        date: DateFilter | null
    ): ExpiredDomainsFilter {
        const model: ExpiredDomainsFilter = {
            domainName: domain,
            domainNameType,
            categories: offerCategories,
            allCategoriesSelected: !offerCategories.length || offerCategories.length === this.categoryOptions.length,
            sorting,
            expirationDate: date,
        };

        return model;
    }

    private saveState(categories: DomainCategory[], domainNameType: DomainNameType, domain: string, sorting: SortOption, date: DateFilter | null): void {
        this.historyStateService.merge<ExpiredDomainFilterState>({
            domainNameType,
            hotOfferCategories: categories,
            hotOfferDomain: domain,
            hotOfferSorting: sorting,
            expirationDate: date,
        });
    }
}
