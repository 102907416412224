<ng-container *transloco="let t">
    <div class="d-flex flex-column flex-sm-row gap-2">
        <button mat-flat-button color="primary" [disabled]="false" (click)="makeOffer()" *ngIf="canMakeOffer">
            {{ t('actions.buy-offer.make-offer') }}
        </button>
        <button mat-flat-button color="primary" [disabled]="false" (click)="acceptOffer()" *ngIf="canAcceptOffer">
            {{ t('actions.buy-offer.accept') }}
        </button>
        <button mat-flat-button color="primary" [disabled]="false" (click)="ignoreOffer()" *ngIf="canIgnoreOffer">
            {{ t('actions.buy-offer.ignore') }}
        </button>
        <button mat-flat-button color="primary" [disabled]="false" (click)="withdrawOffer()" *ngIf="canWithdrawOffer">
            {{ t(withdrawText ?? 'actions.withdraw') }}
        </button>
    </div>
</ng-container>
