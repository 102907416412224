<ng-container *transloco="let t">
    <td-horizontal-scroll class="mb-3">
        <div class="d-flex gap-2">
            <td-date-filter [min]="minDate" [max]="maxDate" [initialFilter]="initialDateFilter" (dateRangeChange)="dateChanged($event)"></td-date-filter>
            <td-list-filter [options]="eventTypeOptions" [selectedOptions]="initialEventTypes" (selectedChange)="typesChanged($event)"></td-list-filter>
            <td-price-filter
                (priceFilterChange)="priceChanged($event)"
                [initialPrice]="initialPriceFilter"
                [title]="t('activity.filters.price.title')"
            ></td-price-filter>
            <td-domain-filter (domainChange)="domainChanged($event)" [initialDomain]="initialDomain" [tzDomainsClient]="tzDomainsClient"></td-domain-filter>
            <td-address-filter (addressChange)="addressChanged($event)" [initialAddress]="initialAddress"></td-address-filter>
        </div>
    </td-horizontal-scroll>
</ng-container>
