import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { TezosDomainsClientService } from '../tezos/integration/tezos-domains-client.service';
import { SearchService } from '../utils/search.service';

@Injectable({
    providedIn: 'root',
})
export class BulkDomainsDataService {
    private tezosDomainsClientService = inject(TezosDomainsClientService);
    private searchService = inject(SearchService);

    private _rawText: string | undefined | null = '';
    private _domainNames = new BehaviorSubject<string[]>([]);
    private _fileName = '';

    readonly domainNames = combineLatest([this.tezosDomainsClientService.current, this._domainNames]).pipe(
        map(([c, domainNames]) => {
            const sanitizedNames = domainNames.map(domainName => this.searchService.sanitizeDomainName(c, domainName)).filter(x => x);

            return [...new Set(sanitizedNames)];
        }),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    get rawText() {
        return this._rawText;
    }

    get fileName() {
        return this._fileName;
    }

    isValidFile(file: File | null | undefined): boolean {
        if (!file) {
            return false;
        }

        return file.type.startsWith('text/');
    }

    clear() {
        this.setDomains('');
        this._rawText = '';
    }

    async setDomains(file: string): Promise<void>;
    async setDomains(file: File | null | undefined): Promise<void>;
    async setDomains(file: string | File | null | undefined): Promise<void> {
        if (typeof file === 'string') {
            this._rawText = file;
            this._fileName = '';

            const tokens = this.parseDomainTokens(file);
            this._domainNames.next(tokens);

            return;
        }

        if (!this.isValidFile(file)) {
            return;
        }

        let data: string | undefined = '';

        try {
            data = await file?.text();
            this._fileName = file?.name ?? '';
        } catch (e) {
            console.error(e);
        }

        if (!data) {
            return;
        }

        this._rawText = data;
        const tokens = this.parseDomainTokens(data);

        this._domainNames.next(tokens);
    }

    private parseDomainTokens(data: string) {
        return data
            .split(/\r?\n|,|\t/)
            .map(x => x.trim())
            .filter(x => x);
    }
}
