import { Component } from '@angular/core';
import { Validators, ValidatorFn } from '@angular/forms';

import { EditorComponentBase } from './editor-component-base';

export interface NumberEditorArgs {
    min?: number;
}

@Component({
    selector: 'td-number',
    templateUrl: './number.component.html',
    styleUrls: ['./number.component.scss'],
})
export class NumberComponent extends EditorComponentBase<NumberEditorArgs> {
    protected createValidators() {
        const validators: ValidatorFn[] = [];

        if (this.args?.min != null) {
            validators.push(Validators.min(this.args.min));
        }

        return validators;
    }
}
