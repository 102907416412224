import { Title } from '@angular/platform-browser';
import { TranslocoService, HashMap } from '@ngneat/transloco';

import { Configuration } from '../configuration';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PageService {
    constructor(private title: Title, private translation: TranslocoService, private configuration: Configuration) {}

    /**
     * t(page-title.settings)
     * t(page-title.address-all)
     * t(page-title.address-domains)
     * t(page-title.address-reverse-records)
     * t(page-title.address-auctions)
     * t(page-title.address-auction-history)
     * t(page-title.address-activity)
     * t(page-title.address-offers)
     * t(page-title.address-buy-offers)
     * t(page-title.my-all)
     * t(page-title.my-domains)
     * t(page-title.my-reverse-records)
     * t(page-title.my-auctions)
     * t(page-title.my-auction-history)
     * t(page-title.my-activity)
     * t(page-title.my-offers)
     * t(page-title.my-buy-offers)
     * t(page-title.domain)
     * t(page-title.auction)
     * t(page-title.hot-auctions)
     * t(page-title.hot-offers)
     * t(page-title.reverse-record)
     * t(page-title.search)
     * t(page-title.reward-history)
     * t(page-title.error)
     * t(page-title.not-found)
     * t(page-title.delegates-leaderboard)
     * t(page-title.delegators-list)
     */
    setTitle(key: string, params?: HashMap<any>) {
        this.translation.selectTranslate(`page-title.${key}`, params).subscribe(t => this.title.setTitle(`${t} - ${this.configuration.appName}`));
    }

    setDefaultTitle() {
        this.title.setTitle(this.configuration.appName);
    }
}
