<ng-container *transloco="let t">
    <div class="mb-3 d-flex gap-2" [style]="{ overflow: 'hidden' }">
        <td-horizontal-scroll class="d-flex flex-grow-1">
            <div class="d-flex gap-2">
                <td-domain-filter
                    (domainChange)="domainChanged($event)"
                    [initialDomain]="initialDomain"
                    [tzDomainsClient]="tzDomainsClient"
                    [validateDomainName]="false"
                ></td-domain-filter>
                <td-price-filter
                    (priceFilterChange)="priceChanged($event)"
                    [initialPrice]="initialPriceFilter"
                    [title]="t('hot-offer-list.filters.price.title')"
                ></td-price-filter>
                <td-date-filter [min]="minDate" [max]="maxDate" [initialFilter]="initialDateFilter" (dateRangeChange)="dateChanged($event)"></td-date-filter>
                <!-- t(hot-offer-list.filters.categories.button-label) -->
                <td-list-filter
                    [options]="categoryOptions"
                    [buttonLabel]="'hot-offer-list.filters.categories.button-label'"
                    [selectedOptions]="initialOfferCategories"
                    (selectedChange)="categoriesChanged($event)"
                ></td-list-filter>
            </div>
        </td-horizontal-scroll>
        <td-sorter [defaultSort]="defaultSort" [sortOptions]="sortOptions" (sortChange)="sortChange($event)"></td-sorter>
    </div>
</ng-container>
