<ng-container *transloco="let t">
    <div *ngIf="dataSource.initialLoading$ | async" class="w-100 mt-3 d-flex justify-content-center">
        <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>

    <div
        *ngIf="enableEmptyMessage && (dataSource.empty$ | async)"
        class="empty-message p-3 w-100 text-center"
        [ngClass]="{ 'smooth-shadow': noShadow !== true }"
    >
        {{ t('auction-table.no-auctions') }}
    </div>

    <div [hidden]="dataSource.initialLoading$ | async">
        <td-error *ngIf="dataSource.error$ | async; let error" [message]="error.message" [title]="t('general.error')"></td-error>

        <div
            class="table-container d-flex"
            [ngClass]="{ 'smooth-shadow': noShadow !== true }"
            [hidden]="(dataSource.empty$ | async) || (dataSource.initialLoading$ | async)"
        >
            <mat-table class="table" [dataSource]="dataSource">
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>{{ t('fields.name') }}</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <div class="text-truncate w-100">
                            <td-domain [domain]="row.domainName"></td-domain>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="timeLeft">
                    <mat-header-cell class="col-time" *matHeaderCellDef>{{ t('fields.ends') }}</mat-header-cell>
                    <mat-cell class="col-time" *matCellDef="let row">
                        <span *ngIf="row.state === states.InProgress" [matTooltip]="row.endsAtUtc | dayjsDate">
                            <td-humanized-date [value]="row.endsAtUtc"></td-humanized-date>
                        </span>
                        <span *ngIf="row.state === states.CanBeSettled" [matTooltip]="row.endsAtUtc | dayjsDate">{{ t('auction-table.ended') }}</span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="status">
                    <mat-header-cell class="col-status" *matHeaderCellDef>{{ t('fields.status') }}</mat-header-cell>
                    <mat-cell class="col-status" *matCellDef="let row">
                        <ng-container *ngIf="row.state === states.CanBeSettled">
                            <td-badge color="success">{{ t('auction-table.status.won') }}</td-badge>
                            <fa-icon
                                icon="question-circle"
                                class="accent help ms-1"
                                matTooltipPosition="above"
                                [matTooltip]="t('auction-table.settle-tooltip')"
                            ></fa-icon>
                        </ng-container>
                        <td-badge *ngIf="row.state === states.InProgress && row.highestBid.bidder !== address" color="danger">
                            {{ t('auction-table.status.outbid') }}
                        </td-badge>
                        <td-badge *ngIf="row.state === states.InProgress && row.highestBid.bidder === address" color="success">
                            {{ t('auction-table.status.highestBid') }}
                        </td-badge>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="currentBid">
                    <mat-header-cell class="col-bid" *matHeaderCellDef>{{ t('fields.current-bid') }}</mat-header-cell>
                    <mat-cell class="col-bid" *matCellDef="let row">
                        <td-tez [value]="row.highestBid.amount"></td-tez>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <mat-header-cell class="col-actions" *matHeaderCellDef></mat-header-cell>
                    <mat-cell class="col-actions d-flex justify-content-end align-items-center" *matCellDef="let row">
                        <a mat-flat-button color="primary" [routerLink]="['/domain', row.domainName]">
                            <span *ngIf="row.state !== states.CanBeSettled">{{ t('actions.go-to-auction') }}</span>
                            <span *ngIf="row.state === states.CanBeSettled">{{ t('actions.settle') }}</span>
                        </a>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns"></mat-row>
            </mat-table>
        </div>

        <div class="mt-3 ms-3" *ngIf="(dataSource.empty$ | async) === false">
            <a [routerLink]="['/address', address, 'auctions']">{{ t('general.see-more') }}</a>
        </div>
    </div>
</ng-container>
