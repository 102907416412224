<h1>Themepark</h1>

<ng-container *ngTemplateOutlet="basic"></ng-container>
<div class="card smooth-shadow p-3">
    <ng-container *ngTemplateOutlet="basic"></ng-container>
</div>

<ng-template #dlg>
    <div class="d-flex flex-column h-100">
        <h1 matDialogTitle class="text-truncate flex-shrink-0">Test dialog</h1>
        <mat-dialog-content class="d-flex flex-column flex-grow-1">
            <ng-container *ngTemplateOutlet="basic"></ng-container>
        </mat-dialog-content>
        <mat-dialog-actions class="d-flex justify-content-end align-items-end w-100 min-height-auto">
            <button mat-button [disabled]="true">Accept</button>
            <button mat-button matDialogClose>Close</button>
        </mat-dialog-actions>
    </div>
</ng-template>

<ng-template #basic>
    <h1>Heading 1</h1>
    <h2>Heading 2</h2>
    <h3>Heading 3</h3>
    <h4>Heading 4</h4>
    <h5>Heading 5</h5>
    <h6>Heading 6</h6>

    <p>Some text</p>
    <p class="mat-body-strong">Bold text</p>
    <p><strong>Bolder text</strong></p>
    <p class="primary">Primary text</p>
    <p class="accent">Accent text</p>
    <p class="txt-danger">Error text</p>
    <p class="txt-info">Info text</p>
    <p class="txt-success">Success text</p>
    <p class="txt-warning">Warning text</p>

    <div class="mb-3 d-flex flex-column gap-3">
        <td-alert state="info">Some info</td-alert>
        <td-alert state="danger">Some error</td-alert>
        <td-alert state="warning">Some warning</td-alert>
        <td-alert state="success">Some success</td-alert>
        <td-alert state="info" [inProgress]="true">Something in progress</td-alert>
    </div>
    <div class="mb-3 d-flex gap-3">
        <button mat-flat-button color="primary" (click)="openDialog(dlg)">Button (open dialog)</button>
        <button mat-flat-button color="primary" [disabled]="true">Button</button>
        <button mat-flat-button color="primary"><fa-icon icon="plus"></fa-icon>Button</button>
        <button mat-stroked-button color="primary">Button</button>
        <button mat-icon-button class="lg" color="primary"><fa-icon icon="cog" size="lg"></fa-icon></button>
        <button mat-icon-button class="big" color="primary"><fa-icon icon="cog" size="2x"></fa-icon></button>
    </div>
    <div class="mb-3 d-flex gap-3">
        <button mat-flat-button color="accent" (click)="showMessage('success')">Button (success toast)</button>
        <button mat-flat-button color="accent" [disabled]="true">Button</button>
        <button mat-flat-button color="accent"><fa-icon icon="plus"></fa-icon>Button</button>
        <button mat-stroked-button color="accent">Button</button>
        <button mat-icon-button class="lg" color="accent"><fa-icon icon="cog" size="lg"></fa-icon></button>
        <button mat-icon-button class="big" color="accent"><fa-icon icon="cog" size="2x"></fa-icon></button>
    </div>
    <div class="mb-3 d-flex gap-3">
        <button mat-flat-button (click)="showMessage('error')">Button (error toast)</button>
        <button mat-flat-button [disabled]="true">Button</button>
        <button mat-flat-button><fa-icon icon="plus"></fa-icon>Button</button>
        <button mat-stroked-button>Button</button>
        <button mat-icon-button class="lg"><fa-icon icon="cog" size="lg"></fa-icon></button>
        <button mat-icon-button class="big"><fa-icon icon="cog" size="2x"></fa-icon></button>
    </div>
    <div class="mb-3 d-flex flex-column gap-2">
        <mat-form-field appearance="standard">
            <mat-label>Label</mat-label>
            <input placeholder="Placeholder..." matInput />
        </mat-form-field>
        <mat-form-field appearance="standard" class="mat-form-field-invalid">
            <mat-label>Label</mat-label>
            <input placeholder="Placeholder..." matInput />
        </mat-form-field>
    </div>
</ng-template>
