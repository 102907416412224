import { Component, ViewChild } from '@angular/core';

import { HotOffersSummaryTableComponent } from '../offers/hot-offers-summary-table.component';

@Component({
    selector: 'td-home-hot-offers',
    templateUrl: './hot-offers.component.html',
    styleUrls: ['./hot-offers.component.scss'],
})
export class HotOffersComponent {
    @ViewChild(HotOffersSummaryTableComponent) table: HotOffersSummaryTableComponent;
}
