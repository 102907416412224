import { Component, Input, OnInit } from '@angular/core';
import { BigNumber } from 'bignumber.js';
import { EventsRecord } from '../../graphql/events-table-data-source';

@Component({
    selector: 'td-activity-item',
    templateUrl: './activity-item.component.html',
    styleUrls: ['./activity-item.component.scss'],
})
export class ActivityItemComponent implements OnInit {
    amount: BigNumber;

    @Input() event: EventsRecord;

    ngOnInit(): void {
        if ('amount' in this.event) {
            this.amount = this.event.amount;
        }
    }
}
