<td-address-page scope="all" #page *transloco="let t" [disableBottomTitlePadding]="page.address === page.wallet?.address">
    <td-dashboard-title my-title></td-dashboard-title>
    <span other-subtitle>{{ t('address-detail.title-other') }}</span>

    <ng-container *ngIf="page.validAddress">
        <ng-container *ngIf="loading | async">
            <td-title-skeleton></td-title-skeleton>
            <td-table-skeleton [rows]="3" [seeMore]="true"></td-table-skeleton>
            <td-table-skeleton [rows]="3" [seeMore]="true"></td-table-skeleton>
            <td-table-skeleton [rows]="3" [seeMore]="true"></td-table-skeleton>
        </ng-container>

        <div class="d-flex flex-column" [hidden]="loading | async">
            <td-airdrop-announcement
                class="mb-3"
                #airdrop
                *ngIf="page.address === page.wallet?.address"
                [hidden]="!(airdrop.vm$ | async)"
            ></td-airdrop-announcement>

            <td-expiring-domains-warning
                class="mb-3"
                [address]="page.address"
                #expiringDomains
                [hidden]="!(expiringDomains.stats$ | async)"
            ></td-expiring-domains-warning>

            <td-vesting-widget
                *ngIf="page.address === page.wallet?.address && airdropStarted"
                class="mb-4"
                #vestingWidget
                [address]="page.address"
                [hidden]="!(vestingWidget.vm$ | async)?.state"
            ></td-vesting-widget>
            <td-pool-widget
                *ngIf="page.address === page.wallet?.address && airdropStarted"
                class="mb-4"
                #poolWidget
                [hidden]="!(poolWidget.vm$ | async)"
            ></td-pool-widget>
            <div
                class="card smooth-shadow flex-column"
                *ngIf="page.address === page.wallet?.address"
                [hidden]="!receivedBuyOfferList.someOffersLoaded && !someOffersIgnored"
                [ngClass]="{
                    empty: !receivedBuyOfferList.someOffersLoaded && !someOffersIgnored,
                    'd-flex': receivedBuyOfferList.someOffersLoaded || someOffersIgnored
                }"
            >
                <h2>{{ t('address-detail.title-buy-offers-received') }}</h2>
                <td-buy-offer-list
                    class="full-card-width"
                    #receivedBuyOfferList
                    [hidden]="!receivedBuyOfferList.someOffersLoaded"
                    [maxCount]="5"
                    [scrollToLoad]="false"
                    [showShadow]="false"
                    [pollInterval]="10 * 60 * 1_000"
                    [filter]="receivedBuyOfferFilter"
                    [wallet]="page.wallet"
                    [address]="page.address"
                    [allowedToIgnoreOffer]="true"
                ></td-buy-offer-list>
                <div *ngIf="!receivedBuyOfferList.someOffersLoaded && someOffersIgnored">{{ t('address-detail.some-ignored-buy-offers') }}</div>

                <div class="mt-3 d-flex">
                    <a [routerLink]="['/address', page.wallet?.address, 'buy-offers', 'received']">{{ t('general.see-all') }}</a>
                </div>
            </div>

            <div
                class="card smooth-shadow flex-column"
                *ngIf="page.address === page.wallet?.address"
                [hidden]="!sentBuyOfferList.someOffersLoaded"
                [ngClass]="{ empty: !sentBuyOfferList.someOffersLoaded, 'd-flex': sentBuyOfferList.someOffersLoaded }"
            >
                <h2>{{ t('address-detail.title-buy-offers-sent') }}</h2>
                <td-buy-offer-list
                    class="full-card-width"
                    #sentBuyOfferList
                    [maxCount]="5"
                    [scrollToLoad]="false"
                    [showShadow]="false"
                    [pollInterval]="10 * 60 * 1_000"
                    [filter]="sentBuyOfferFilter"
                    [wallet]="page.wallet"
                    [address]="page.address"
                ></td-buy-offer-list>

                <div class="mt-3">
                    <a [routerLink]="['/address', page.wallet?.address, 'buy-offers', 'sent']">{{ t('general.see-more') }}</a>
                </div>
            </div>

            <div class="card smooth-shadow">
                <ng-container *ngTemplateOutlet="sectionTitle; context: { key: 'address-detail.title-domains' }"></ng-container>

                <td-domain-summary-table
                    class="full-card-width"
                    [noShadow]="true"
                    [enableEmptyMessage]="true"
                    [address]="page.address"
                ></td-domain-summary-table>
            </div>
            <div class="card smooth-shadow" [hidden]="(reverseRecords.empty$ | async) === true">
                <ng-container *ngTemplateOutlet="sectionTitle; context: { key: 'address-detail.title-reverse-records' }"></ng-container>

                <td-reverse-record-summary-table
                    #reverseRecords
                    class="full-card-width"
                    [noShadow]="true"
                    [enableEmptyMessage]="true"
                    [address]="page.address"
                ></td-reverse-record-summary-table>
            </div>
            <div class="card smooth-shadow" [hidden]="(offers.empty$ | async) === true">
                <ng-container *ngTemplateOutlet="sectionTitle; context: { key: 'address-detail.title-offers' }"></ng-container>

                <td-offer-summary-table
                    #offers
                    class="full-card-width"
                    [noShadow]="true"
                    [enableEmptyMessage]="true"
                    [address]="page.address"
                ></td-offer-summary-table>
            </div>
            <div class="card smooth-shadow" [hidden]="(auctions.empty$ | async) === true && (history.totalDataSource.empty$ | async) === true">
                <ng-container *ngTemplateOutlet="sectionTitle; context: { key: 'address-detail.title-auctions' }"></ng-container>

                <h3 *ngIf="(auctions.empty$ | async) === false">{{ t('address-detail.title-current-auctions') }}</h3>

                <td-auction-summary-table
                    #auctions
                    class="full-card-width"
                    [noShadow]="true"
                    [enableEmptyMessage]="false"
                    [address]="page.address"
                ></td-auction-summary-table>

                <h3 class="mt-4" *ngIf="(history.empty$ | async) === false">{{ t('address-detail.title-auctions-history') }}</h3>

                <td-auction-history-summary-table #history class="full-card-width" [address]="page.address"></td-auction-history-summary-table>
            </div>
            <div class="card smooth-shadow" *ngIf="page.wallet?.address === page.address" [hidden]="!(auctionFunds.bidderBalance | async)?.balance">
                <h2>{{ t('address-detail.title-auction-funds') }}</h2>
                <div class="mb-3">
                    {{ t('address-detail.auction-funds-description') }}
                    <a [attr.href]="'about/auctions#auction-balance' | landingPageUrl" target="_blank">
                        {{ t('general.learn-more') }}
                    </a>
                </div>

                <td-auction-funds #auctionFunds></td-auction-funds>
            </div>
            <div *ngIf="page.address === page.wallet?.address" class="card smooth-shadow" [hidden]="!visibleFavCount">
                <td-latest-favourites-table [address]="page.address" (loaded)="visibleFavCount = $event"></td-latest-favourites-table>
            </div>
            <div *ngIf="page.address === page.wallet?.address" class="card smooth-shadow" [hidden]="(events.dataSource?.empty$ | async) === true">
                <ng-container *ngTemplateOutlet="sectionTitle; context: { key: 'address-detail.title-activity' }"></ng-container>

                <td-event-summary-table #events [address]="page.address" class="full-card-width"></td-event-summary-table>
            </div>
        </div>
    </ng-container>

    <!-- t(address-detail.title-domains-my) -->
    <!-- t(address-detail.title-domains-other) -->
    <!-- t(address-detail.title-reverse-records-my) -->
    <!-- t(address-detail.title-reverse-records-other) -->
    <!-- t(address-detail.title-auctions-my) -->
    <!-- t(address-detail.title-auctions-other) -->
    <!-- t(address-detail.title-offers-my) -->
    <!-- t(address-detail.title-offers-other) -->
    <!-- t(address-detail.title-activity-my) -->
    <ng-template #sectionTitle let-key="key">
        <h2 *ngIf="page.address === page.wallet?.address">{{ t(key + '-my') }}</h2>
        <h2 *ngIf="page.address !== page.wallet?.address">{{ t(key + '-other') }}</h2>
    </ng-template>
</td-address-page>
