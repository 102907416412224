import { Component, Input } from '@angular/core';

@Component({
    selector: 'td-domain',
    templateUrl: './domain.component.html',
    styleUrls: ['./domain.component.scss'],
})
export class DomainComponent {
    @Input() domain: string;
    @Input() copy: boolean;
    @Input() favourite: boolean;
    @Input() link: boolean;
    @Input() route: any[];
    @Input() profileFor?: string;
    @Input() maxChars = 200;
}
