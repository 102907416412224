<div class="d-flex flex-column flex-fill h-100" *transloco="let t">
    <div class="d-flex flex-column flex-shrink-0">
        <h1 mat-dialog-title class="text-truncate flex-shrink-0">{{ t('remove-offer-form.title', { name: offer.domain?.name }) }}</h1>
        <td-operation-status class="w-100" [operation]="operation" (done)="operationDone($event)" #op></td-operation-status>
    </div>
    <mat-dialog-content class="d-flex flex-column flex-grow-1">
        {{ t('remove-offer-form.info') }}
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex justify-content-end align-items-end w-100 min-height-auto">
        <button mat-button (click)="cancel()" [disabled]="op.inProgress">{{ t('actions.cancel') }}</button>
        <button mat-flat-button color="primary" (click)="save()" [disabled]="submit || op.inProgress">
            {{ t('actions.remove-offer') }}
        </button>
    </mat-dialog-actions>
</div>
