import { DataSource } from '@angular/cdk/table';

import { Unarray } from '../utils/types';
import { DataSourceBase, ProcessedData, DataSourceOptions } from '../data-source-base';
import { EventsQuery, EventsQueryVariables } from './graphql.generated';

export type EventsRecord = Unarray<EventsQuery['events']['edges']>['node'];

export class EventsDataSource extends DataSourceBase<EventsQuery, EventsRecord[], EventsQueryVariables> implements DataSource<EventsRecord> {
    constructor(options: DataSourceOptions<EventsQuery, EventsQueryVariables>) {
        super({
            ...options,
            defaultVariables: { first: 30 },
        });
    }

    protected transformData(data: EventsQuery): ProcessedData<EventsRecord[]> {
        return {
            data: data.events.edges.map(e => e.node),
            pageInfo: data.events.pageInfo,
            isEmpty: !data.events.edges.length,
        };
    }
}
