import { Component, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { trigger, style, animate, transition, AnimationEvent } from '@angular/animations';

@Component({
    selector: 'td-copy-to-clipboard',
    templateUrl: './copy-to-clipboard.component.html',
    styleUrls: ['./copy-to-clipboard.component.scss'],
    animations: [
        trigger('fadeText', [
            transition(':enter', [style({ opacity: 0 }), animate('250ms', style({ opacity: 1 }))]),
            transition(':leave', [animate('200ms', style({ opacity: 0 }))]),
        ]),
    ],
})
export class CopyToClipboardComponent {
    @Input() text: string;
    @Input() label: string;
    @Input() customIcon: boolean;

    copyDone: boolean;
    showIcon = true;

    constructor(private clipboard: Clipboard) {}

    copy() {
        const success = this.clipboard.copy(this.text);

        if (success) {
            this.copyDone = true;
            this.showIcon = false;

            setTimeout(() => (this.copyDone = false), 2000);
        }
    }

    animationDone(event: AnimationEvent) {
        if (event.toState === 'void') {
            this.showIcon = true;
        }
    }
}
