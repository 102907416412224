import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export type ActionInputButtonType = 'icon' | 'button';
export type ActionInputInputType = 'search' | 'text';

@Component({
    selector: 'td-action-input',
    templateUrl: './action-input.component.html',
    styleUrls: ['./action-input.component.scss'],
})
export class ActionInputComponent {
    private _keystrokeAction = new Subject<string>();

    @Input() placeholder: string;
    @Input() buttonType?: ActionInputButtonType;
    @Input() buttonColor?: ThemePalette;
    @Input() inputType: ActionInputInputType;
    @Input() buttonText: string;
    @Input() buttonIcon?: IconProp;
    @Input() emitOnKeyStroke = false;

    @Input() set value(v: string) {
        this.input = v;
    }

    @Output() action = new EventEmitter<string>();
    @Output() keystrokeAction = this._keystrokeAction.pipe(debounceTime(300), distinctUntilChanged());

    hasFocus: boolean;
    input: string | undefined;

    @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

    submit() {
        const input = this.input;

        const processedInput = input ? input.trim() : null;

        if (!processedInput) {
            return;
        }

        this.action.next(processedInput);
    }

    inputChange() {
        const input = this.input;

        const processedInput = input ? input.trim() : null;

        this._keystrokeAction.next(processedInput ?? '');
    }

    clear() {
        this.input = undefined;
        this.searchInput.nativeElement.blur();
    }

    focus() {
        this.searchInput.nativeElement.focus();
    }
}
