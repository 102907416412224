import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { TaquitoTezosDomainsClient } from '@tezos-domains/taquito-client';
import { TdValidators } from '../utils/form-validators';
import { wrapFocus } from '../utils/hacks';

@Component({
    selector: 'td-domain-filter',
    templateUrl: './domain-filter.component.html',
    styleUrls: ['./domain-filter.component.scss'],
})
export class DomainFilterComponent implements OnInit {
    @Input() tzDomainsClient?: TaquitoTezosDomainsClient;
    @Input() initialDomain = '';
    @Input() validateDomainName = true;
    @Output() domainChange = new EventEmitter<string>();

    @ViewChild('domainInput') domainInput!: ElementRef;
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    form: UntypedFormGroup;
    domain = '';
    domainDisplay: 'noDomain' | 'domain' = 'noDomain';

    constructor(private formBuilder: UntypedFormBuilder) {}

    ngOnInit(): void {
        if (this.validateDomainName && !this.tzDomainsClient) {
            throw new Error('If the domain names needs validation, `tzDomainsClient` must be provided');
        }

        const validator = this.validateDomainName ? TdValidators.domainName(this.tzDomainsClient!.validator) : undefined;
        this.form = this.formBuilder.group({
            domainFilter: this.formBuilder.control('', validator),
        });

        if (this.initialDomain) {
            this.form.get('domainFilter')?.setValue(this.initialDomain);
            this.domain = this.initialDomain;
            this.domainDisplay = this.getDomainDisplay();
        }
    }

    onClick() {
        this.trigger.openMenu();
    }

    submit(): void {
        if (this.form.valid) {
            this.domain = this.form.value.domainFilter;
            this.domainChange.next(this.domain);
        }

        this.trigger.closeMenu();
    }

    clear() {
        this.form.reset();
        this.domain = '';
        this.domainChange.next(this.domain);
        this.trigger.closeMenu();
    }

    menuClosed() {
        this.domainDisplay = this.domain ? 'domain' : 'noDomain';
    }

    menuOpened() {
        wrapFocus(() => this.domainInput.nativeElement.focus());
    }

    isDisabled() {
        return !this.form.valid || !this.form.value.domainFilter;
    }

    private getDomainDisplay() {
        return this.domain ? 'domain' : 'noDomain';
    }
}
