<ng-container *transloco="let t">
    <td-domain-listing
        [domainName]="offer.domain?.name"
        [price]="offer.priceWithoutFee"
        [expiration]="expiration"
        [seller]="offer.buyer"
        [requireConnect]="true"
        [buttonsTemplate]="buttonsTemplate"
        [badgesTemplate]="badgesTemplate"
        [layoutSmall]="'row'"
        [responsiveAvatar]="canSelectOffer || showOfferSelectorPlaceholder"
    >
        <div pre-avatar *ngIf="canSelectOffer || showOfferSelectorPlaceholder">
            <div class="checkbox-placeholder" *ngIf="showOfferSelectorPlaceholder"></div>
            <mat-checkbox (click)="$event.stopPropagation()" (change)="toggleSelectOne($event)" [checked]="isSelected" *ngIf="canSelectOffer"></mat-checkbox>
        </div>
        <div extra-info class="buyer d-flex flex-column flex-sm-row">
            <div class="d-flex">
                {{ t('buy-offer-list.created-by') }}
                <td-address
                    [address]="offer.buyer"
                    [reverseRecord]="offer.buyerReverseRecord"
                    [copy]="false"
                    [text]="'name-or-address'"
                    [icon]="false"
                    class="ms-1"
                ></td-address>
            </div>
            <td-badge class="d-flex flex-grow-0 ms-0 ms-sm-1" color="primary" *ngIf="canShowYouBadge && wallet && offer.buyer === wallet?.address">{{
                t('badges.you')
            }}</td-badge>
        </div>
    </td-domain-listing>

    <ng-template #buttonsTemplate>
        <td-buy-offer-actions
            [buyOffer]="offer"
            [wallet]="wallet"
            [allowedToIgnoreOffer]="allowedToIgnoreOffer"
            (offerActedOn)="offerActedOn.next($event)"
        ></td-buy-offer-actions>
    </ng-template>

    <ng-template #badgesTemplate>
        <div class="d-flex gap-1">
            <td-offer-state-badge [excludedStates]="excludedBadgeOfferStates" [offer]="offer"></td-offer-state-badge>
        </div>
    </ng-template>
</ng-container>
