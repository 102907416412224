import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { of } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { Configuration } from '../configuration';

@Injectable({
    providedIn: 'root',
})
export class BlockedDelegatesService {
    private readonly config = inject(Configuration);
    private readonly httpClient = inject(HttpClient);

    blockedDelegates$ = this.httpClient.get<string[]>(`${this.config.storageUrl}/blocked-delegates.json`).pipe(
        catchError(() => of([])),
        shareReplay({ refCount: true, bufferSize: 1 })
    );
}
