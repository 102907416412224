<form class="d-flex flex-column flex-fill h-100" [formGroup]="form" *transloco="let t">
    <h1 mat-dialog-title class="text-truncate flex-shrink-0">{{ t('settle.title', { name: data.name }) }}</h1>
    <mat-dialog-content class="d-flex flex-column flex-grow-1 gap-2">
        <td-operation-status class="w-100" [operation]="operation" (done)="operationDone($event)" #op></td-operation-status>
        <td-address-editor
            [control]="form.get('address')!"
            [label]="t('fields.domain-address')"
            [placeholder]="t('fields.address-placeholder')"
        ></td-address-editor>
        <mat-slide-toggle *ngIf="!reverseRecord?.domain" formControlName="createReverseRecord">
            <span>{{ t('fields.create-reverse-record') }}</span>
            <fa-icon class="accent ms-2" matTooltipPosition="above" [matTooltip]="t('buy.create-reverse-record-info')" icon="question-circle"></fa-icon>
        </mat-slide-toggle>
        <div>
            <span>{{ t('fields.registration-period') }}: </span>
            <strong>{{ duration | humanizeDuration }}</strong>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex justify-content-end align-items-end w-100 min-height-auto">
        <button mat-flat-button color="primary" (click)="settle()" [disabled]="form.invalid || op.inProgress">{{ t('actions.settle') }}</button>
        <button mat-button (click)="cancel()" [disabled]="form.disabled">{{ t('actions.cancel') }}</button>
    </mat-dialog-actions>
</form>
