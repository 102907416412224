<ng-container *transloco="let t">
    <ng-container *ngIf="vm$ | async; let vm">
        <td-alert [state]="'info'" *ngIf="vm.hasVestingTokensToClaim">
            <div class="my-1 d-flex align-items-center">
                <span class="fw-500 flex-grow-1">{{ t('airdrop.vesting-reminder.text') }}</span>
                <a mat-flat-button color="primary" [routerLink]="['/address', vm.address]">
                    <fa-icon icon="layer-group"></fa-icon>
                    {{ t('general.go-to-dashboard') }}
                </a>
            </div>
        </td-alert>
    </ng-container>
</ng-container>
