import { Component, OnInit, OnDestroy } from '@angular/core';
import { TezosToolkit } from '@taquito/taquito';
import { MatDialog } from '@angular/material/dialog';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BigNumber } from 'bignumber.js';

import { TezosToolkitService } from '../tezos/integration/tezos-toolkit.service';
import { TezosWallet } from '../tezos/models';
import { TezosService } from '../tezos/tezos.service';
import { WalletSendComponent } from './wallet-send.component';

@Component({
    selector: 'td-wallet',
    templateUrl: './wallet.component.html',
    styleUrls: ['./wallet.component.scss'],
})
export class WalletComponent implements OnInit, OnDestroy {
    tezos: TezosToolkit;
    wallet: TezosWallet | null;
    balance: BigNumber;

    private unsubscribe = new Subject<void>();

    constructor(private tezosToolkitService: TezosToolkitService, private tezosService: TezosService, private dialog: MatDialog) {}

    ngOnInit(): void {
        combineLatest([this.tezosToolkitService.current, this.tezosService.activeWallet])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(([tezos, wallet]) => {
                this.tezos = tezos;
                if (wallet) {
                    this.wallet = wallet;
                }
            });
    }

    send() {
        this.dialog.open(WalletSendComponent);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
