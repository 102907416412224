import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatDayjsDateModule } from '@vanrossumict/material-dayjs-adapter';
import { CookieModule } from 'ngx-cookie';
import { ToastrModule } from 'ngx-toastr';
import { filter } from 'rxjs/operators';

import { A11yModule } from '@angular/cdk/a11y';
import { ApolloModule } from 'apollo-angular';
import { environment } from '../environments/environment';
import { AddressModule } from './address/address.module';
import { AirdropModule } from './airdrop/airdrop.module';
import { AppRoutingModule } from './app-routing.module';
import { AppService } from './app-service';
import { AppComponent } from './app.component';
import { AuctionsModule } from './auctions/auctions.module';
import { HistoryStateService } from './browser/history-state.service';
import { BuyOffersModule } from './buy-offers/buy-offers.module';
import { Configuration, CONFIGURATION } from './configuration';
import { DelegationModule } from './delegation/delegation.module';
import { DevModule } from './dev/dev.module';
import { DomainCommonModule } from './domain-common/domain-common.module';
import { DomainsModule } from './domains/domains.module';
import { GlobalErrorHandler } from './error-handler';
import { EventsModule } from './events/events.module';
import { FavouritesModule } from './favourites/favourites.module';
import { FileProcessingModule } from './file-processing/file-processing.module';
import { GovernanceCoreModule } from './governance-core/governance-core.module';
import { GraphQLModule } from './graphql/graphql.module';
import { GovPoolModule } from './gv-pool/gov-pool.module';
import { HomeModule } from './home/home.module';
import { localeInitializer, TranslocoRootModule } from './i18n/transloco-root.module';
import { LayoutModule } from './layout/layout.module';
import { NotificationsModule } from './notifications/notifications.module';
import { OffersCoreModule } from './offers-core/offers-core.module';
import { OffersModule } from './offers/offers.module';
import { ReverseRecordsModule } from './reverse-records/reverse-records.module';
import { SettingsModule } from './settings/settings.module';
import { SharedModule } from './shared/shared.module';
import { SkeletonModule } from './skeleton/skeleton.module';
import { TezosDomainsClientService } from './tezos/integration/tezos-domains-client.service';
import { TezosModule } from './tezos/tezos.module';

export const DATE_PICKER_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'LL',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            positionClass: 'td-toast-position',
            timeOut: 10000,
            extendedTimeOut: 2000,
            progressBar: true,
            enableHtml: true,
            tapToDismiss: false,
            closeButton: true,
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
        }),
        CookieModule.withOptions({ secure: true }),
        RouterModule,
        HttpClientModule,
        ApolloModule,
        GraphQLModule,
        A11yModule,
        LayoutModule,
        HomeModule,
        DomainsModule,
        SharedModule,
        SettingsModule,
        DevModule,
        AddressModule,
        ReverseRecordsModule,
        AuctionsModule,
        TezosModule.forRoot(),
        SharedModule.forRoot(),
        TranslocoRootModule,
        NotificationsModule,
        HttpClientModule,
        SkeletonModule,
        EventsModule,
        MatDayjsDateModule,
        OffersCoreModule,
        OffersModule,
        BuyOffersModule,
        FileProcessingModule,
        OffersModule,
        DelegationModule,
        AirdropModule,
        GovPoolModule,
        GovernanceCoreModule,
        DomainCommonModule,
        FavouritesModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        { provide: Configuration, useExisting: CONFIGURATION },
        localeInitializer,
        Title,
        { provide: MAT_DATE_FORMATS, useValue: DATE_PICKER_FORMATS },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
    ],
})
export class AppModule {
    constructor(
        _a: AppService,
        _h: HistoryStateService,
        private router: Router,
        private dialog: MatDialog,
        private tezosDomainsClientService: TezosDomainsClientService
    ) {
        // Ensure that AppService and HistoryStateService are constructed

        this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => this.dialog.closeAll());

        // Prewarm
        setTimeout(() => this.tezosDomainsClientService.current.subscribe(c => c.manager.getTldConfiguration(c.validator.supportedTLDs[0])), 2000);
    }
}
