import { InjectionToken } from '@angular/core';
import { ManipulateType } from 'dayjs';

import { HardwareWalletSettings, TezosNetwork } from './tezos/models';

export class Configuration {
    network: TezosNetwork;
    appName: string;
    hardwareWallet: HardwareWalletSettings;
    safeBalanceThreshold: number;
    landingPageUrl: string;
    mailingApiUrl: string;
    storageUrl: string;
    decentralizedWebsiteUrl: string;
    cookieDomain: string;
    tzprofilesAppUrl: string;
    domainIsExpiringThreshold: { time: number; units: ManipulateType };
    expiringDomainThreshold: { time: number; units: ManipulateType };
    expiringDomainEarlyWarningThreshold: { time: number; units: ManipulateType };
    minEventsFilterDate: Date;
    dnsVerifyApiUrl: string;
    maxWebsiteDomainNameLength: number;
    buyOfferFee: number;
    allowImpersonation: boolean;
    maxBulkCreate: number;
    airdrop: {
        delegates: string[];
        start: Date;
        end: Date;
    };
    governance: {
        homebaseUrl: string;
        plentyUrl: string;
        tedExchangeUrl: string;
        showAverageApr?: boolean | null;
    };
}

export const CONFIGURATION = new InjectionToken<Configuration>('configuration');
