<form class="d-flex flex-column flex-fill h-100" [formGroup]="form" *transloco="let t">
    <div class="d-flex flex-column flex-shrink-0">
        <h1 mat-dialog-title class="text-truncate" *ngIf="!reverseRecord.owner">
            {{ t('reverse-record-form.title-create') }}
        </h1>
        <h1 mat-dialog-title class="text-truncate" *ngIf="reverseRecord.owner">
            {{ t('reverse-record-form.title-edit') }}
        </h1>
        <td-operation-status class="w-100" [operation]="operation" (done)="operationDone($event)" #op></td-operation-status>
    </div>
    <mat-dialog-content class="d-flex flex-column flex-grow-1 gap-2">
        <td-alert state="warning" *ngIf="reverseRecord.domain && overriddenName && reverseRecord.domain.name !== form.get('name')!.value">
            {{ t('reverse-record-form.existing-name-warning', { currentName: reverseRecord.domain.name }) }}
        </td-alert>
        <mat-form-field appearance="standard" class="permanently-disabled">
            <mat-label>{{ t('fields.address') }}</mat-label>
            <input disabled matInput [value]="reverseRecord.address" required />
        </mat-form-field>
        <mat-form-field appearance="standard">
            <mat-label>{{ t('fields.name') }}</mat-label>
            <mat-autocomplete #applicableDomainsAuto="matAutocomplete">
                <mat-option *ngFor="let domain of applicableDomainsDataSource.data$ | async" [value]="domain.name">{{ domain.name }}</mat-option>
            </mat-autocomplete>
            <input formControlName="name" [placeholder]="t('fields.name-placeholder')" matInput [matAutocomplete]="applicableDomainsAuto" />
            <fa-icon matSuffix class="clear-button" icon="times" (click)="clearName(); $event.stopPropagation()"></fa-icon>
            <mat-error>
                <td-validation-messages [control]="form.get('name')!" [context]="{ name: t('fields.name') }"></td-validation-messages>
            </mat-error>
        </mat-form-field>
        <td-address-editor
            [control]="form.get('owner')!"
            [label]="t('fields.reverse-record-owner')"
            [placeholder]="t('fields.owner-placeholder')"
            [required]="true"
        ></td-address-editor>
        <div class="d-flex flex-column gap-3">
            <td-alert state="warning" *ngIf="transferOwnerRequested">
                <div class="d-flex flex-column gap-2">
                    <ng-container *ngIf="!transferOwnerConfirmed">
                        <div>{{ t('general.owner-transfer-warning') }}</div>
                        <td-action-input
                            buttonType="button"
                            [buttonText]="t('actions.confirm')"
                            inputType="text"
                            [placeholder]="t('general.enter-confirm-phrase')"
                            (action)="confirmTransfer($event)"
                            buttonColor="primary"
                        ></td-action-input>
                    </ng-container>
                    <div *ngIf="transferOwnerConfirmed">{{ t('general.owner-transfer-confirmed') }}</div>
                </div>
            </td-alert>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex justify-content-end align-items-end w-100 min-height-auto">
        <button mat-flat-button color="primary" *ngIf="!reverseRecord.owner" (click)="create()" [disabled]="!form.valid || op.inProgress">
            {{ t('actions.set') }}
        </button>
        <button
            mat-flat-button
            color="primary"
            *ngIf="reverseRecord.owner"
            (click)="save()"
            [disabled]="!form.valid || op.inProgress || (transferOwnerRequested && !transferOwnerConfirmed)"
        >
            {{ t('actions.save') }}
        </button>
        <button mat-button (click)="cancel()" [disabled]="op.inProgress">{{ t('actions.cancel') }}</button>
    </mat-dialog-actions>
</form>
