import { Component, OnInit, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TransactionWalletOperation } from '@taquito/taquito';
import { getLabel, RecordMetadata } from '@tezos-domains/core';
import { TaquitoTezosDomainsClient } from '@tezos-domains/taquito-client';
import { Observable, Subject } from 'rxjs';
import { takeUntil, first } from 'rxjs/operators';

import { TdValidators, TdAsyncValidatorsFactory } from '../utils/form-validators';
import { SmartContractOperationEvent, TezosService } from '../tezos/tezos.service';
import { TezosWallet } from '../tezos/models';
import { DomainDetailQuery } from '../graphql/graphql.generated';
import { OperationStatusDoneEvent } from '../shared/operation-status.component';
import { ReverseRecordService, ReverseRecord } from './reverse-record.service';
import { emptyStringToNull } from '../utils/convert';
import { TezosDomainsClientService } from '../tezos/integration/tezos-domains-client.service';
import { isOperator } from './utils/is-operator';

@Component({
    selector: 'td-subdomain-form',
    templateUrl: './domain-record-form.component.html',
    styleUrls: ['./domain-record-form.component.scss'],
})
export class DomainRecordFormComponent implements OnInit, OnDestroy, AfterViewInit {
    form: UntypedFormGroup;
    operation: Observable<SmartContractOperationEvent> | null;
    wallet: TezosWallet;
    reverseRecord: ReverseRecord | null;
    disableAnimation = true;

    parent: string;
    domain: NonNullable<Partial<DomainDetailQuery['domain']>>;

    private unsubscribe = new Subject<void>();

    constructor(
        private formBuilder: UntypedFormBuilder,
        private tezosService: TezosService,
        @Inject(MAT_DIALOG_DATA) private data: { parent: string; domain: NonNullable<Partial<DomainDetailQuery['domain']>> },
        private dialogRef: MatDialogRef<DomainRecordFormComponent>,
        private reverseRecordService: ReverseRecordService,
        private asyncValidatorFactory: TdAsyncValidatorsFactory,
        private tezosDomainsClientService: TezosDomainsClientService
    ) {}

    ngOnInit(): void {
        this.parent = this.data.parent;
        this.domain = this.data.domain;
        this.tezosService.activeWallet.pipe(takeUntil(this.unsubscribe)).subscribe(w => (this.wallet = w!));
        this.reverseRecordService.current.pipe(takeUntil(this.unsubscribe)).subscribe(r => (this.reverseRecord = r));

        const controls: { [name: string]: AbstractControl } = {
            address: this.formBuilder.control(this.domain.address, [TdValidators.tezosAddress()]),
            owner: this.formBuilder.control(this.domain.owner || this.wallet.address, [Validators.required, TdValidators.tezosAddress(false)]),
            data: this.formBuilder.group({}),
        };

        if (!this.domain.owner) {
            this.tezosDomainsClientService.current.pipe(first()).subscribe(client => {
                controls.label = this.formBuilder.control(
                    '',
                    [Validators.required, TdValidators.label(this.parent, client.validator)],
                    [this.asyncValidatorFactory.subdomainNameAvailable(this.parent)]
                );
            });
        }

        this.form = this.formBuilder.group(controls);

        if (this.domain.owner) {
            this.form.get('owner')!.disable();
        }
    }

    // Workaround for angular component issue #13870
    ngAfterViewInit(): void {
        // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
        setTimeout(() => (this.disableAnimation = false));
    }

    create() {
        this.execute(client =>
            client.manager.setChildRecord({
                ...this.form.value,
                address: emptyStringToNull(this.form.value.address),
                parent: this.parent,
                data: new RecordMetadata(this.form.value.data),
            })
        );
    }

    save() {
        if (this.domain.owner === this.wallet.address || isOperator(this.domain, this.wallet.address)) {
            this.execute(client =>
                client.manager.updateRecord({
                    ...this.form.value,
                    address: emptyStringToNull(this.form.value.address),
                    name: this.domain.name,
                    data: new RecordMetadata(this.form.value.data),
                })
            );
        } else {
            this.execute(client =>
                client.manager.setChildRecord({
                    ...this.form.value,
                    address: emptyStringToNull(this.form.value.address),
                    label: getLabel(this.domain.name!),
                    parent: this.parent,
                    data: new RecordMetadata(this.form.value.data),
                })
            );
        }
    }

    operationDone(event: OperationStatusDoneEvent) {
        if (event.success) {
            this.dialogRef.close(true);
        } else {
            this.form.enable();
        }
    }

    cancel() {
        this.dialogRef.close(false);
    }

    clearAddress() {
        this.form.get('address')!.setValue(null);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private execute(op: (client: TaquitoTezosDomainsClient) => Promise<TransactionWalletOperation>) {
        this.form.disable();

        const operation = this.tezosService.execute(op);

        this.operation = operation;
    }
}
