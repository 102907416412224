import { A11yModule } from '@angular/cdk/a11y';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FiltersModule } from '../filters/filters.module';
import { SharedModule } from '../shared/shared.module';
import { ActivityFormComponent } from './activity/activity-form.component';
import { ActivityItemComponent } from './activity/activity-item.component';
import { ActivityListComponent } from './activity/activity-list.component';
import { ActivityComponent } from './activity/activity.component';
import { EventBalanceComponent } from './event-balance.component';
import { EventCtaComponent } from './event-cta.component';
import { EventSummaryTableComponent } from './event-summary-table.component';
import { EventTableComponent } from './event-table.component';
import { EventTextComponent } from './event-text.component';
import { EventsListComponent } from './events-list.component';
@NgModule({
    declarations: [
        EventTextComponent,
        EventBalanceComponent,
        EventSummaryTableComponent,
        EventTableComponent,
        EventsListComponent,
        EventCtaComponent,
        ActivityListComponent,
        ActivityFormComponent,
        ActivityComponent,
        ActivityItemComponent,
    ],
    imports: [SharedModule, FormsModule, ReactiveFormsModule, MatDatepickerModule, FiltersModule, A11yModule],
    exports: [EventTextComponent, EventBalanceComponent, EventSummaryTableComponent, EventCtaComponent],
})
export class EventsModule {}
