<ng-container *transloco="let t">
    <div class="d-flex flex-column gap-2">
        <div>
            {{ showAverageApr ? t('gvPool.average-apr') : t('gvPool.current-apr') }}
            <fa-icon
                *ngIf="showAverageApr"
                icon="question-circle"
                class="accent help ms-1 no-underline"
                matTooltipPosition="above"
                [matTooltip]="t('gvPool.average-apr-help')"
            ></fa-icon>
        </div>
        <div class="value fw-500">{{ (showAverageApr ? vm.poolAverageAPR : vm.poolCurrentAPR) | apr }}</div>
        <div>
            <a href="{{ 'governance/pool' | landingPageUrl }}">{{ t('gvPool.widget.more-info') }}</a>
        </div>
    </div>
</ng-container>
