import { Component, OnInit } from '@angular/core';
import { AppService } from '../app-service';

@Component({
    selector: 'td-offline',
    templateUrl: './offline.component.html',
    styleUrls: ['./offline.component.scss'],
})
export class OfflineComponent implements OnInit {
    show: boolean;

    constructor(private appService: AppService) {}

    ngOnInit(): void {
        this.appService.online.subscribe(online => (this.show = !online));
    }

    check() {
        this.appService.checkForConnection();
    }
}
