import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import dayjs, { Dayjs } from 'dayjs';
import { BuyOfferRecord } from '../graphql/buy-offer-table-data-source';
import { OfferState } from '../graphql/graphql.generated';
import { TezosWallet } from '../tezos/models';
import { OfferAfterActionState } from './buy-offer.models';

@Component({
    selector: 'td-buy-offer-listing',
    templateUrl: './buy-offer-listing.component.html',
    styleUrls: ['./buy-offer-listing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuyOfferListingComponent implements OnInit {
    @Input() offer: BuyOfferRecord;
    @Input() wallet: TezosWallet | null;
    @Input() allowedToIgnoreOffer = false;
    @Input() canShowYouBadge = false;
    @Input() showOfferSelectorPlaceholder = false;
    @Input() canSelectOffer = false;
    @Input() isSelected = false;

    @Output() offerSelected = new EventEmitter<boolean>();
    @Output() offerActedOn = new EventEmitter<OfferAfterActionState>();

    expiration: Dayjs | undefined;

    excludedBadgeOfferStates = [OfferState.Active];

    ngOnInit() {
        this.expiration = this.offer.expiration?.isAfter(dayjs()) ? this.offer.expiration : undefined;
    }

    toggleSelectOne(evt: MatCheckboxChange): void {
        this.isSelected = evt.checked;
        this.offerSelected.next(this.isSelected);
    }
}
