import { AuctionState, DomainDetailQuery } from '../../graphql/graphql.generated';
import { Unarray } from '../../utils/types';

type AuctionInput = DomainDetailQuery['auction'] | Unarray<DomainDetailQuery['lastAuction']['edges']>['node'] | undefined;

export function getExistingAuction(auction: AuctionInput):
    | {
          endsAt: Date;
          ownedUntil: Date;
          lastBid: number;
          lastBidder: string;
      }
    | undefined {
    if (!auction) {
        return;
    }

    if (auction.state === AuctionState.Settled) {
        return;
    }

    return {
        endsAt: auction.endsAt.toDate(),
        ownedUntil: auction.ownedUntil.toDate(),
        lastBid: auction.highestBid.amount.toNumber(),
        lastBidder: auction.highestBid.bidder,
    };
}
