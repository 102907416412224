import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Configuration } from '../configuration';
import { DataSourceFactory } from '../graphql/data-source-factory';
import { DomainListRecord, DomainTableDataSource } from '../graphql/domain-table-data-source';
import { DomainOrderField, OrderDirection } from '../graphql/graphql.generated';
import { TezosWallet } from '../tezos/models';
import { TezosService } from '../tezos/tezos.service';
import { isExpiring } from './domain.helpers';
import { ReverseRecord, ReverseRecordService } from './reverse-record.service';
import { isOperator } from './utils/is-operator';

@UntilDestroy()
@Component({
    selector: 'td-domain-summary-table',
    templateUrl: './domain-summary-table.component.html',
    styleUrls: ['./domain-summary-table.component.scss'],
})
export class DomainSummaryTableComponent implements OnInit, OnChanges {
    @Input() address: string;
    @Input() enableEmptyMessage: boolean;
    @Input() noShadow: boolean;

    dataSource: DomainTableDataSource;
    userReverseRecord: ReverseRecord | null;
    columns: string[];
    wallet: TezosWallet | null;
    operatorTooltip = '';

    private emptyStream = new ReplaySubject<boolean>(1);

    get empty$() {
        return this.emptyStream;
    }

    constructor(
        private dataSourceFactory: DataSourceFactory,
        private reverseRecordService: ReverseRecordService,
        private tezosService: TezosService,
        private configuration: Configuration
    ) {}

    ngOnInit() {
        this.dataSource = this.dataSourceFactory.createDomainsDataSource();
        this.dataSource.empty$.pipe(untilDestroyed(this), delay(0)).subscribe(this.emptyStream);

        this.reverseRecordService.current.pipe(untilDestroyed(this)).subscribe(r => {
            this.userReverseRecord = r;
        });

        this.tezosService.activeWallet.pipe(untilDestroyed(this)).subscribe(w => {
            this.wallet = w;
            if (w?.address === this.address) {
                this.columns = ['name', 'expires', 'actions'];
            } else {
                this.columns = ['name', 'expires'];
            }
        });

        this.load();
    }

    ngOnChanges() {
        if (this.dataSource) {
            this.load();
        }
    }

    isDomainOfLoggedOnUser(domain: DomainListRecord): boolean {
        return domain.owner === this.wallet?.address || domain.parentOwner === this.wallet?.address || isOperator(domain, this.address);
    }

    domainIsExpiring(domain: DomainListRecord): boolean {
        return isExpiring(domain, this.configuration.expiringDomainThreshold);
    }

    isOperatorFor(domain: DomainListRecord): boolean {
        return isOperator(domain, this.address);
    }

    private load() {
        this.dataSource.load(
            {
                where: { or: [{ owner: { equalTo: this.address } }, { address: { equalTo: this.address } }, { operators: { include: this.address } }] },
                order: { field: DomainOrderField.Level, direction: OrderDirection.Asc },
                first: 4,
            },
            undefined,
            { autofillHierarchy: false }
        );
    }
}
