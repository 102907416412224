<td-address-page scope="offers" #page *transloco="let t">
    <h1 my-title>{{ t('offer-list.title-my') }}</h1>
    <span other-subtitle>{{ t('offer-list.title-owned-by') }}</span>

    <div class="mb-3 d-flex justify-content-end align-items-center" *ngIf="!!selected.length">
        <button mat-flat-button color="primary" (click)="cancelOffers(page.wallet!)" [disabled]="!selected.length || !page.wallet?.address">
            {{ t('offer-list.bulk-cancel', { count: selected.length }) }}
        </button>
    </div>

    <td-offer-table
        *ngIf="page.validAddress"
        [address]="page.address"
        (domainsSelected)="domainsSelected($event)"
        [hideFilters]="!!selected.length"
    ></td-offer-table>
</td-address-page>
