import { Injectable } from '@angular/core';
import { AppService } from '../app-service';
import { DelegatesLeaderboardGQL, DelegatorListGQL, PoolHistoryGQL } from '../governance-core/governance-graphql.generated';
import { ReverseRecordListGQL } from '../graphql/graphql.generated';
import { TzprofilesService } from '../tzprofiles/tzprofiles.service';
import { DelegatesLeaderboardDataSource } from './delegates-leaderboard-data-source';
import { DelegatorListDataSource } from './delegators-data-source';
import { PoolHistoryDataSource } from './pool-history-data-source';

@Injectable({
    providedIn: 'root',
})
export class GovernanceDataSourceFactory {
    constructor(
        private poolHistory: PoolHistoryGQL,
        private delegatesLeaderboardQuery: DelegatesLeaderboardGQL,
        private reverseRecordListGQL: ReverseRecordListGQL,
        private delegatorListGQL: DelegatorListGQL,
        private tzProfilesQuery: TzprofilesService,
        private appService: AppService
    ) {}

    createPoolHistoryDataSource() {
        return new PoolHistoryDataSource({ query: this.poolHistory, appService: this.appService });
    }

    createDelegatesLeaderboardDataSource() {
        return new DelegatesLeaderboardDataSource(
            { query: this.delegatesLeaderboardQuery, appService: this.appService },
            this.tzProfilesQuery,
            this.reverseRecordListGQL
        );
    }

    createDelegatorsDataSource() {
        return new DelegatorListDataSource({ query: this.delegatorListGQL, appService: this.appService }, this.reverseRecordListGQL);
    }
}
