import { Component } from '@angular/core';

import { EditorComponentBase } from './editor-component-base';
import { TdValidators } from '../../../utils/form-validators';

@Component({
    selector: 'td-gravatar',
    templateUrl: './gravatar.component.html',
    styleUrls: ['./gravatar.component.scss'],
})
export class GravatarComponent extends EditorComponentBase<any> {
    protected createValidators() {
        return [TdValidators.md5];
    }
}
