import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';
import { WithdrawComponent } from './address/withdraw.component';
import { RejectWarningComponent } from './airdrop/reject-warning.component';
import { ExecuteBuyOfferComponent } from './buy-offers/execute-buy-offer.component';
import { PlaceBuyOfferComponent } from './buy-offers/place-buy-offer.component';
import { RemoveBuyOfferComponent } from './buy-offers/remove-buy-offer.component';
import { BuyOfferRecord } from './graphql/buy-offer-table-data-source';
import { DomainDetailQuery, ReverseRecordDetailQuery } from './graphql/graphql.generated';
import { OfferRecord } from './graphql/offer-table-data-source';
import { NotificationsService } from './notifications/notifications.service';
import { SubscribeComponent } from './notifications/subscribe.component';
import { ExecuteOfferComponent } from './offers/execute-offer.component';
import { PlaceOfferComponent } from './offers/place-offer.component';
import { RemoveOfferComponent } from './offers/remove-offer.component';
import { ReverseRecordFormComponent } from './reverse-records/reverse-record-form.component';
import { TezosWallet } from './tezos/models';
import { TezosWalletService } from './tezos/tezos-wallet.service';
import { TezosService } from './tezos/tezos.service';

@Injectable({ providedIn: 'root' })
export class OverlayService {
    private wallet: TezosWallet | null = null;

    constructor(
        private dialog: MatDialog,
        private tezosWalletService: TezosWalletService,
        private tezosService: TezosService,
        private router: Router,
        private notificationsService: NotificationsService
    ) {
        this.tezosService.activeWallet.subscribe(w => (this.wallet = w));
    }

    openReverseRecordEdit(reverseRecord: ReverseRecordDetailQuery['reverseRecord']) {
        return this.dialog.open(ReverseRecordFormComponent, {
            data: { reverseRecord: reverseRecord },
            height: '600px',
            panelClass: 'big-dialog-pane',
        });
    }

    openSubscribe() {
        return this.dialog.open(SubscribeComponent, {
            height: '540px',
            disableClose: false,
            panelClass: 'big-dialog-pane',
        });
    }

    openWithdraw() {
        const dialog = this.dialog.open(WithdrawComponent, {
            height: '340px',
            panelClass: 'big-dialog-pane',
        });

        dialog.afterClosed().subscribe(result => {
            if (result) {
                this.tezosWalletService.refreshBidderBalance();
            }
        });

        return dialog;
    }

    openExecuteOffer(offer: OfferRecord, successCallback?: () => void) {
        const dialog = this.dialog.open(ExecuteOfferComponent, {
            data: { offer },
            height: '500px',
        });

        dialog.afterClosed().subscribe(result => {
            if (result) {
                if (successCallback) successCallback();
            }
        });
    }

    openExecuteBuyOffer(offer: BuyOfferRecord, successCallback?: () => void) {
        const dialog = this.dialog.open(ExecuteBuyOfferComponent, {
            data: { offer },
            height: '500px',
        });

        dialog.afterClosed().subscribe(result => {
            if (result) {
                if (successCallback) successCallback();
            }
        });
    }

    openPlaceBuyOffer(domain: DomainDetailQuery['domain'], successCallback?: () => void) {
        const dialog = this.dialog.open(PlaceBuyOfferComponent, {
            data: { domain },
            height: '650px',
        });

        dialog.afterClosed().subscribe(async result => {
            if (result) {
                await this.router.navigate(['/address', this.wallet?.address, 'buy-offers']);
                if (successCallback) successCallback();
            }
        });
    }

    openPlaceOffer(offer: OfferRecord, successCallback?: () => void) {
        const dialog = this.dialog.open(PlaceOfferComponent, {
            data: { offer: offer },
            height: '600px',
        });

        dialog.afterClosed().subscribe(result => {
            if (result) {
                if (successCallback) successCallback();
            }
        });
    }

    openRemoveOffer(offer: OfferRecord, successCallback?: () => void) {
        const dialog = this.dialog.open(RemoveOfferComponent, {
            data: { offer: offer },
            height: '220px',
        });

        dialog.afterClosed().subscribe(result => {
            if (result) {
                if (successCallback) successCallback();
            }
        });
    }

    openRemoveBuyOffer(offer: BuyOfferRecord, successCallback?: () => void) {
        const dialog = this.dialog.open(RemoveBuyOfferComponent, {
            data: { offer: offer },
            height: '250px',
        });

        dialog.afterClosed().subscribe(result => {
            if (result) {
                if (successCallback) successCallback();
            }
        });
    }

    openNotificationPopup(from: string) {
        if (this.notificationsService.isEnabled) {
            combineLatest([this.notificationsService.currentSubscription, this.notificationsService.promptBlocked(from)])
                .pipe(first())
                .subscribe(([subscription, blocked]) => {
                    if (subscription.status === 'not-subscribed' && !blocked) {
                        this.dialog.open(SubscribeComponent, {
                            height: '500px',
                            panelClass: 'big-dialog-pane',
                            data: { from },
                        });
                    }
                });
        }
    }

    openRejectWarning(successCallback: (result: boolean) => void) {
        const dialog = this.dialog.open(RejectWarningComponent, {
            height: '200px',
        });

        dialog.afterClosed().subscribe(successCallback);
    }
}
