import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WindowRef } from '../browser/window-ref';

@Component({
    selector: 'td-share',
    templateUrl: './share.component.html',
    styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnInit {
    @Input() text: string;
    @Input() domainName?: string | null;

    url: string;

    constructor(private router: Router, private windowRef: WindowRef) {}

    ngOnInit() {
        this.url = this.domainName
            ? this.windowRef.nativeWindow.location.origin + this.router.serializeUrl(this.router.createUrlTree(['/domain', this.domainName]))
            : this.windowRef.nativeWindow.location.origin;
    }
}
