import { BigMapAbstraction } from '@taquito/taquito';
import { ContractConfig } from '@tezos-domains/core';
import { BigNumber } from 'bignumber.js';
import { Observable } from 'rxjs';

export interface BidderBalance {
    tld: string;
    balance: number;
}

export interface TezosBeaconWallet {
    address: string;
    icon?: string;
    walletName?: string;
}

export interface TezosWallet extends TezosBeaconWallet {
    balance: Observable<number>;
    tedBalance$: Observable<BigNumber>;
    votesBalance$: Observable<BigNumber>;
    isBalanceLow: Observable<boolean>;
    bidderBalances: Observable<BidderBalance[]>;
}

export interface TezosNetwork {
    name: string;
    apiUrl: string;
    blockIndexedTimeoutMs: number;
    tzprofilesApiUrl: string;
    rewardsApiUrl: string;
    rpcUrl: string;
    rpcUrls: { name: string; url: string; enabled: boolean | 'true' | 'false' }[];
    statsUrl: string;
    contracts?: ContractConfig;
    confirmations: number;
    chainId: string;
    harbingerNormalizerContract: string;
    directBrokerContract: string;
    buyOfferBrokerContract: string;
    tokenContract: string;
    tedTokenContract: string;
    tedVotesContract: string;
    tedPoolContract: string;
    vestingContract: string;
    oracleRegistrarContract: string;
    customContractAddresses?: ContractConfig;
    customTlds?: string[];
}

export interface HardwareWalletSettings {
    derivationPath: string;
}

export class EncodedString {
    constructor(public readonly value: string) {}
}

export interface TLDRegistrarStorage {
    records: BigMapAbstraction;
    commitments: BigMapAbstraction;
    max_commitment_age: BigNumber;
    min_commitment_age: BigNumber;
    min_bid_per_day: BigNumber;
}

export interface ProxyStorage {
    contract: string;
}

export interface NameRegistryStorage {
    store: {
        records: BigMapAbstraction;
        reverse_records: BigMapAbstraction;
        validity_map: BigMapAbstraction;
    };
}

export enum TezosErrorCode {
    COMMITMENT_TOO_OLD = 'COMMITMENT_TOO_OLD', // When registering and commitment is expired
    BID_TOO_LOW = 'BID_TOO_LOW', // When someone bids before you
    NOT_SETTLEABLE = 'NOT_SETTLEABLE', // When it's not possible to settle anymore (settlement expired)
    LABEL_EXPIRED = 'LABEL_EXPIRED', // When renewing label that is already expired
    AUCTION_ENDED = 'AUCTION_ENDED', // When trying to bid after auction ends
    NOT_AUTHORIZED = 'NOT_AUTHORIZED', // When owner changes when editing
    CONTRACT_BALANCE_TOO_LOW = 'CONTRACT_BALANCE_TOO_LOW', // When balance is lower than amount required for a transaction
    ABORTED = 'ABORTED', // When wallet dialog canceled
    CONNECT = 'CONNECT', // When unable to connect wallet
    INDEXER_TIMEOUT = 'INDEXER_TIMEOUT', // When indexer is not caught up
    GOV_INDEXER_TIMEOUT = 'GOV_INDEXER_TIMEOUT', // When indexer is not caught up
    NOT_TRUSTED_SENDER = 'NOT_TRUSTED_SENDER', // When someone fucks up contracts
    STORAGE_EXHAUSTED_OPERATION = 'STORAGE_EXHAUSTED_OPERATION', // When storage fee estimation is wrong
    COMMITMENT_EXISTS = 'COMMITMENT_EXISTS', // When trying to create commitment for the same domain with the same nonce
    TIMESTAMP_TOO_OLD = 'TIMESTAMP_TOO_OLD', // When trying to claim a domain where the signature is expired
}

export class TezosError extends Error {
    constructor(message: string, public errorCode: TezosErrorCode | null) {
        super(message);
    }
}
