import { Injectable, isDevMode } from '@angular/core';

enum LogLevel {
    Debug,
    Warn,
    Error,
}

@Injectable({
    providedIn: 'root',
})
export class Logger {
    log(value: any, ...rest: any[]) {
        if (this.isEnabled(LogLevel.Debug)) {
            console.log(value, ...rest);
        }
    }

    warn(value: any, ...rest: any[]) {
        if (this.isEnabled(LogLevel.Warn)) {
            console.warn(value, ...rest);
        }
    }

    error(value: any, ...rest: any[]) {
        if (this.isEnabled(LogLevel.Error)) {
            console.error(value, ...rest);
        }
    }

    private isEnabled(level: LogLevel): boolean {
        return level === LogLevel.Error || level === LogLevel.Warn || isDevMode();
    }
}
