<div *transloco="let t">
    <td-error *ngIf="dataSource.error$ | async; let error" [message]="error.message" [title]="t('general.error')"></td-error>

    <div class="table-filter" *ngIf="!hideFilters">
        <form [formGroup]="form" action="." (ngSubmit)="reload()" class="d-flex flex-column flex-sm-row justify-content-between">
            <div class="d-flex flex-grow-1 flex-sm-grow-0">
                <mat-form-field class="flex-grow-1 flex-sm-grow-0" appearance="standard" floatLabel="never">
                    <input
                        matInput
                        formControlName="filter"
                        autocapitalize="off"
                        autocomplete="off"
                        autocorrect="off"
                        type="search"
                        type="search"
                        [placeholder]="t('domain-table.filter-placeholder')"
                    />
                    <fa-icon matSuffix class="clear-button primary" icon="times" (click)="form.get('filter')!.setValue('')"></fa-icon>
                </mat-form-field>
            </div>
            <mat-button-toggle-group class="categories-button" formControlName="category">
                <mat-button-toggle *ngFor="let category of categories" color="primary" class="category-button" [value]="category.name">
                    <!-- t(domain-table.filter-category-all) -->
                    <!-- t(domain-table.filter-category-direct) -->
                    <!-- t(domain-table.filter-category-expiring) -->
                    {{ t('domain-table.filter-category-' + category.name, { level: baseLevel }) }}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </form>
    </div>

    <div
        *ngIf="(dataSource.empty$ | async) && (hideResults | async) === false"
        class="empty-message p-3 w-100 text-center"
        [ngClass]="{ 'smooth-shadow': noShadow !== true }"
    >
        {{ t('domain-table.no-domains') }}
    </div>

    <div
        class="table-container d-flex"
        [ngClass]="{ 'smooth-shadow': noShadow !== true }"
        infiniteScroll
        (scrolled)="scrolled()"
        [class.d-none]="(dataSource.empty$ | async) || (hideResults | async)"
    >
        <mat-table
            class="table"
            [dataSource]="dataSource"
            matSort
            (matSortChange)="sortList()"
            [matSortActive]="sortField"
            [matSortDirection]="sortDirection"
            matSortDisableClear
            #sort="matSort"
        >
            <ng-container matColumnDef="selection">
                <mat-header-cell class="col-selection" *matHeaderCellDef>
                    <mat-checkbox
                        (change)="$event ? toggleSelectAll() : null"
                        [checked]="selection.hasValue() && areAllSelected()"
                        [indeterminate]="selection.hasValue() && !areAllSelected()"
                    >
                    </mat-checkbox
                ></mat-header-cell>
                <mat-cell class="col-selection d-flex align-items-center" *matCellDef="let row">
                    <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="toggleSelectOne($event, row)"
                        [checked]="selection.isSelected(row)"
                        *ngIf="row.level == 2"
                    >
                    </mat-checkbox>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header="DOMAIN">{{ t('fields.name') }}</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <a class="w-100 d-flex align-items-center" [routerLink]="['/domain', row.name]" [ngClass]="{ 'txt-secondary': row.owner === 'generated' }">
                        <ng-container *ngTemplateOutlet="indent; context: { level: row.level, sort: sort }"></ng-container>
                        <span class="text-truncate">{{ row.name }}</span>
                        <td-address
                            *ngIf="userReverseRecord?.domain?.name === row.name"
                            [address]="userReverseRecord!.address"
                            [text]="false"
                            [reverseRecord]="userReverseRecord"
                            class="ms-2"
                        ></td-address>
                        <td-operator-badge *ngIf="isOperatorFor(row)" [address]="address"> </td-operator-badge>
                    </a>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="expires">
                <mat-header-cell class="col-expires" *matHeaderCellDef mat-sort-header="EXPIRES_AT">{{ t('fields.expires') }}</mat-header-cell>
                <mat-cell class="col-expires" *matCellDef="let row">
                    <span *ngIf="row.level == 2" [matTooltip]="row.expires | dayjsDate" [ngClass]="{ 'no-underline': !row.expires }">
                        <td-humanized-date [value]="row.expires" [textWhenEmpty]="t('general.never')"></td-humanized-date>
                    </span>
                    <fa-icon
                        class="ms-1 icon-warning no-underline"
                        icon="exclamation-triangle"
                        *ngIf="shouldShowExpiringDomainWarning(row)"
                        [matTooltip]="t('domain.expiring-soon')"
                    ></fa-icon>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell class="col-actions" *matHeaderCellDef></mat-header-cell>
                <mat-cell class="col-actions justify-content-end" *matCellDef="let row">
                    <a *ngIf="isLoggedOnUserDomain(row)" mat-flat-button color="primary" [routerLink]="['/domain', row.name]">
                        {{ t('actions.manage') }}
                    </a>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="visibleColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: visibleColumns"></mat-row>
        </mat-table>
    </div>
    <div *ngIf="dataSource.loading$ | async" class="w-100 mt-3 d-flex justify-content-center">
        <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>
</div>

<ng-template #indent let-level="level" let-sort="sort">
    <ng-container *ngIf="!loadedWithFilter">
        <div [style.min-width]="(level - baseLevel) * (media.isActive('xs') ? 0.5 : 1.5) + 'em'"></div>
        <div *ngIf="level > baseLevel">
            <fa-icon class="me-2" [flip]="!(sort.direction === 'desc' && sort.active === 'DOMAIN') ? 'vertical' : undefined" icon="share"></fa-icon>
        </div>
    </ng-container>
</ng-template>
