import { Component, inject } from '@angular/core';
import { Configuration } from '../configuration';

@Component({
    selector: 'td-global-announcement',
    templateUrl: './global-announcement.component.html',
    styleUrls: ['./global-announcement.component.scss'],
})
export class GlobalAnnouncementComponent {
    plentyUrl = inject(Configuration).governance.plentyUrl ?? '';
}
