import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable, of, ReplaySubject } from 'rxjs';
import { map, catchError, delayWhen, switchMap, filter, first } from 'rxjs/operators';

import { Configuration } from '../configuration';
import { StorageSchema } from '../utils/storage';
import { TezosService } from '../tezos/tezos.service';

export interface SubscribeRequest {
    address: string;
    email: string;
}

export interface ConfirmResponse {
    address: string;
    email: string;
}

export interface UnsubscribeResponse {
    address: string;
}

export interface MailApiResponse<T> {
    status: 'OK' | 'ERROR';
    failReason?: string;
    data: T;
}

export type SubscriptionStatus = 'not-subscribed' | 'unconfirmed' | 'confirmed';

export interface NotificationsSubscription {
    email: string | null;
    status: SubscriptionStatus;
}

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    private subscriptionStatusStream = new ReplaySubject<NotificationsSubscription>(1);

    constructor(private configuration: Configuration, private http: HttpClient, private storageMap: StorageMap, private tezosService: TezosService) {
        this.tezosService.activeWallet
            .pipe(
                filter(w => !!w),
                switchMap(w => {
                    const storage = StorageSchema.notificationStatus(w!.address);
                    return this.storageMap.watch<NotificationsSubscription>(storage.key, storage.schema);
                }),
                map<NotificationsSubscription | undefined, NotificationsSubscription>(s => s ?? { email: null, status: 'not-subscribed' })
            )
            .subscribe(s => this.subscriptionStatusStream.next(s));
    }

    get currentSubscription() {
        return this.subscriptionStatusStream.asObservable();
    }

    get isEnabled() {
        return !!this.configuration.mailingApiUrl;
    }

    promptBlocked(from: string): Observable<boolean> {
        const storage = StorageSchema.blockNotificationPopup(from);

        return this.storageMap.get(storage.key).pipe(
            first(),
            map(v => !!v)
        );
    }

    subscribe(request: SubscribeRequest): Observable<MailApiResponse<unknown>> {
        return this.request('subscribe', request).pipe(
            delayWhen(r => {
                if (r.status === 'OK') {
                    const storage = StorageSchema.notificationStatus(request.address);
                    return this.storageMap.set(storage.key, { email: request.email, status: 'unconfirmed' }, storage.schema);
                }

                return of(null);
            })
        );
    }

    confirm(key: string): Observable<MailApiResponse<ConfirmResponse>> {
        return this.request<ConfirmResponse>('confirm', { key }).pipe(
            delayWhen(r => {
                if (r.status === 'OK') {
                    const storage = StorageSchema.notificationStatus(r.data.address);
                    return this.storageMap.set(storage.key, { email: r.data.email, status: 'confirmed' }, storage.schema);
                }

                return of(null);
            })
        );
    }

    unsubscribe(key: string): Observable<MailApiResponse<UnsubscribeResponse>> {
        return this.request<UnsubscribeResponse>('unsubscribe', { key }).pipe(
            delayWhen(r => {
                if (r.status === 'OK') {
                    return this.storageMap.delete(StorageSchema.notificationStatus(r.data.address).key);
                }

                return of(null);
            })
        );
    }

    private request<T, R = any>(url: string, request: R): Observable<MailApiResponse<T>> {
        return this.http.post(`${this.configuration.mailingApiUrl}/${url}`, request).pipe(
            map(r => <MailApiResponse<T>>{ status: 'OK', data: r }),
            catchError((e: HttpErrorResponse) => of(<MailApiResponse<T>>{ status: 'ERROR', failReason: e.error?.reason }))
        );
    }
}
