<ng-container *transloco="let t">
    <form [formGroup]="form" action=".">
        <div class="hidden-date-input">
            <mat-date-range-input [max]="max?.toDate()" [min]="min.toDate()" [rangePicker]="picker">
                <input matStartDate formControlName="from" [attr.placeholder]="t('filters.date.from-label')" />
                <input matEndDate formControlName="to" [attr.placeholder]="t('filters.date.until-label')" />
            </mat-date-range-input>

            <mat-date-range-picker [touchUi]="showTouchUi" #picker>
                <mat-date-range-picker-actions>
                    <button mat-stroked-button (click)="clearDateRange()" matDateRangePickerCancel>{{ t('actions.clear') }}</button>
                    <button mat-flat-button color="primary" matDateRangePickerApply>{{ t('actions.filter') }}</button>
                </mat-date-range-picker-actions>
            </mat-date-range-picker>
        </div>

        <button mat-stroked-button (click)="picker.open()" [ngClass]="{ 'highlighted-stroked': display !== 'noFilter' }">
            <ng-container [ngSwitch]="display">
                <ng-container *ngSwitchCase="'noFilter'">{{ t('filters.date.no-filter') }}</ng-container>
                <ng-container *ngSwitchCase="'startDate'">{{ t('filters.date.lower-bound') }} {{ model?.from | dayjsDate: 'short' }}</ng-container>
                <ng-container *ngSwitchCase="'interval'">{{ model?.from | dayjsDate: 'short' }} - {{ model?.to | dayjsDate: 'short' }}</ng-container>
            </ng-container>
        </button>
    </form>
</ng-container>
