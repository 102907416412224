<ng-container *transloco="let t">
    <div *ngIf="dataSource.loading$ | async" class="w-100 mt-3 d-flex justify-content-center">
        <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>

    <div *ngIf="!(dataSource.initialLoading$ | async)">
        <td-error *ngIf="dataSource.error$ | async; let error" [message]="error.message" [title]="t('general.error')"></td-error>

        <div class="table-container d-flex" *ngIf="!(dataSource.empty$ | async) && !(dataSource.initialLoading$ | async)">
            <mat-table class="table" [dataSource]="dataSource">
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>{{ t('fields.name') }}</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <div class="text-truncate w-100">
                            <td-domain [domain]="row.domainName" [route]="['/auction', row.domainName, row.startedAtLevel]"></td-domain>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="ended">
                    <mat-header-cell class="col-ended" *matHeaderCellDef>{{ t('fields.ended') }}</mat-header-cell>
                    <mat-cell class="col-ended" *matCellDef="let row">
                        <span [matTooltip]="row.endsAtUtc | dayjsDate">
                            <td-humanized-date [value]="row.endsAtUtc"></td-humanized-date>
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="status">
                    <mat-header-cell class="col-status" *matHeaderCellDef>{{ t('fields.status') }}</mat-header-cell>
                    <mat-cell class="col-status" *matCellDef="let row">
                        <td-badge *ngIf="row.highestBid.bidder !== address" color="danger">
                            {{ t('auction-table.status.lost') }}
                        </td-badge>
                        <td-badge *ngIf="row.highestBid.bidder === address" color="success">
                            {{ t('auction-table.status.won') }}
                        </td-badge>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="highestBid">
                    <mat-header-cell class="col-bid" *matHeaderCellDef>{{ t('fields.winning-bid') }}</mat-header-cell>
                    <mat-cell class="col-bid" *matCellDef="let row">
                        <td-tez [value]="row.highestBid.amount"></td-tez>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <mat-header-cell class="col-actions" *matHeaderCellDef></mat-header-cell>
                    <mat-cell class="col-actions d-flex justify-content-end align-items-center" *matCellDef="let row">
                        <a mat-flat-button color="primary" [routerLink]="['/auction', row.domainName, row.startedAtLevel]">{{ t('actions.view-history') }}</a>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns"></mat-row>
            </mat-table>
        </div>

        <div class="mt-3 ms-3" *ngIf="(totalDataSource.empty$ | async) === false">
            <a [routerLink]="['/address', address, 'auction-history']">{{ t('auction-table.see-full-history') }}</a>
        </div>
    </div>
</ng-container>
