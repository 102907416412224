<ng-container *ngIf="enabled">
    <ng-container *transloco="let t">
        <ng-container *ngIf="event">
            <ng-container *ngIf="status === 'not-subscribed' || status === 'unconfirmed'">
                <button type="button" *ngIf="!buttonStyle?.stroked" [matMenuTriggerFor]="menu" mat-flat-button color="primary" (click)="subscribe()">
                    <ng-container *ngTemplateOutlet="buttonTextTemplate"></ng-container>
                </button>
                <button type="button" *ngIf="buttonStyle?.stroked" [matMenuTriggerFor]="menu" mat-stroked-button (click)="subscribe()">
                    <ng-container *ngTemplateOutlet="buttonTextTemplate"></ng-container>
                </button>
            </ng-container>
            <button type="button" *ngIf="status === 'confirmed'" [matMenuTriggerFor]="menu" mat-stroked-button (click)="subscribe()">
                <ng-container *ngTemplateOutlet="buttonTextTemplate"></ng-container>
            </button>
        </ng-container>
        <ng-container *ngIf="!event">
            <button type="button" *ngIf="status === 'not-subscribed' || status === 'unconfirmed'" mat-flat-button color="primary" (click)="subscribe()">
                <ng-container *ngTemplateOutlet="buttonTextTemplate"></ng-container>
            </button>
            <button type="button" *ngIf="status === 'confirmed'" mat-stroked-button (click)="subscribe()">
                <ng-container *ngTemplateOutlet="buttonTextTemplate"></ng-container>
            </button>
        </ng-container>

        <mat-menu #menu="matMenu">
            <button type="button" mat-menu-item (click)="email()" *ngIf="emailIsAllowed">
                <fa-icon icon="envelope" size="lg"></fa-icon>{{ t('notifications.subscribe-button.email') }}
            </button>
            <!-- t(notifications.subscribe-button.google-calendar) -->
            <!-- t(notifications.subscribe-button.ics-calendar) -->
            <!-- t(notifications.subscribe-button.office-calendar) -->
            <a *ngFor="let link of calendarLinks" mat-menu-item target="_blank" [attr.href]="link.link | trustUrl">
                <fa-icon [icon]="link.icon" size="lg"></fa-icon>{{ t(link.textKey) }}
            </a>
        </mat-menu>

        <ng-template #buttonTextTemplate>
            <fa-icon icon="bell"></fa-icon>
            {{ buttonText ?? t('notifications.subscribe-button.subscribe') }}
        </ng-template>
    </ng-container>
</ng-container>
