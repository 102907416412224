import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { MatMenuTrigger } from '@angular/material/menu';
import { flatMap, keyBy } from 'lodash-es';

export enum ListOptionType {
    Item = 'item',
    Separator = 'separator',
}

export class ListOption {
    readonly id: string;
    readonly values: string[];

    constructor(values: string, label: string, type: ListOptionType);
    constructor(values: string[], label: string, type: ListOptionType);
    constructor(readonly _values: string | string[], public readonly label: string, public readonly type: ListOptionType) {
        if (Array.isArray(_values)) {
            this.id = _values.join('%');
            this.values = _values;
        } else {
            this.id = _values;
            this.values = [_values];
        }
    }
}

@Component({
    selector: 'td-list-filter',
    templateUrl: './list-filter.component.html',
    styleUrls: ['./list-filter.component.scss'],
})
export class ListFilterComponent implements OnInit {
    @Input() options: ListOption[];
    @Input() selectedOptions: string[];
    @Input() buttonLabel?: string;
    @Output() selectedChange = new EventEmitter<string[]>();

    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
    @ViewChild('selectionList') selectionList: MatSelectionList;

    selectedCount = 0;
    selectedItems: string[] = [];
    allItems: ListOption[] = [];

    optionType = ListOptionType;

    initiallySelected: Record<string, string>;

    ngOnInit() {
        this.allItems = [...this.options];
        this.initiallySelected = keyBy(this.selectedOptions);
        this.selectedCount = this.selectedOptions?.length ?? 0;
    }

    onClick() {
        this.trigger.openMenu();
    }

    selectionChange() {
        this.selectedCount = this.selectionList.selectedOptions.selected.length;
        this.selectedItems = flatMap(this.selectionList.selectedOptions.selected.map(s => (<ListOption>s.value).values));
    }

    submit(): void {
        this.selectedChange.next(this.selectedItems);
        this.trigger.closeMenu();
    }

    /**
     * Used by mat-selection-list to determine if an item is selected
     */
    compareWith = (x: any, y: any) => x && y && x === y;

    clear() {
        this.selectionList.deselectAll();
        this.selectedCount = 0;
        this.selectedChange.next([]);
        this.trigger.closeMenu();
    }
}
