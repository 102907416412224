import { Component, OnInit } from '@angular/core';
import { BlockGQL } from '../graphql/graphql.generated';

import dayjs from 'dayjs';

@Component({
    selector: 'td-outdated-data',
    templateUrl: './outdated-data.component.html',
    styleUrls: ['./outdated-data.component.scss'],
})
export class OutdatedDataComponent implements OnInit {
    show = false;

    constructor(private blockGQL: BlockGQL) {}

    ngOnInit(): void {
        this.blockGQL.watch({}, { fetchPolicy: 'network-only', pollInterval: 5 * 60 * 1000 }).valueChanges.subscribe(b => {
            if (b.data.block) {
                this.show = dayjs().diff(b.data.block.timestamp, 'minute') > 10;
            }
        });
    }
}
