import { Injectable } from '@angular/core';
import { ToastrService, IndividualConfig } from 'ngx-toastr';
import { TranslocoService, HashMap } from '@ngneat/transloco';
import { combineLatest, of } from 'rxjs';
import { first } from 'rxjs/operators';

export type MessageOptions = {
    message?: string;
    messageKey?: string;
    messageParameters?: HashMap<any>;
    title?: string;
    titleKey?: string;
    titleParameters?: HashMap<any>;
} & Partial<IndividualConfig>;

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    constructor(private toastr: ToastrService, private translation: TranslocoService) {}

    error(options: MessageOptions) {
        /**
         * t(error.api-error-message)
         * t(error.balance-refresh-title)
         * t(error.bidder-balance-refresh-title)
         * t(error.reverse-record-fetch-title)
         * t(error.user-data-fetch-title)
         * t(error.commitment-fetch-title)
         */
        this.show('error', { titleKey: 'general.error', ...options });
    }

    success(options: MessageOptions) {
        /** t(general.success) */
        this.show('success', { titleKey: 'general.success', ...options });
    }

    private show(type: 'success' | 'error', options: MessageOptions) {
        if (!(options.title || options.titleKey)) {
            throw new Error('title or titleKey must be specified');
        }

        if (!(options.message || options.messageKey)) {
            throw new Error('message or messageKey must be specified');
        }

        combineLatest([
            options.title ? of(options.title) : this.translation.selectTranslate(options.titleKey!, options.titleParameters),
            options.message ? of(options.message) : this.translation.selectTranslate(options.messageKey!, options.messageParameters),
        ])
            .pipe(first())
            .subscribe(([title, message]) => {
                this.toastr[type](message, title, options);
            });
    }
}
