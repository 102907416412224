import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import BigNumber from 'bignumber.js';
import { Observable, combineLatest, of } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { AppService } from '../app-service';
import { Configuration } from '../configuration';
import { DelegationStatus } from '../delegation/models';
import { ReverseRecord, ReverseRecordService } from '../domains/reverse-record.service';
import { DelegationStatusService } from '../gv-pool/delegation-status.service';
import { PoolStatsService } from '../gv-pool/pool-stats.service';
import { DayjsDatePipe } from '../i18n/dayjs-date.pipe';
import { OperationStatusDoneEvent } from '../shared/operation-status.component';
import { TezosWallet } from '../tezos/models';
import { TezosWalletService } from '../tezos/tezos-wallet.service';
import { SmartContractOperationEvent, TezosService } from '../tezos/tezos.service';
import { ClaimInfoService } from './claim-info.service';
import { GovActionService } from './gov-action.service';
import { AirdropClaimState, ClaimStatus } from './models';

enum ClaimStep {
    start = 0,
    explanation,
    distribution,
    // constitution,
    delegation,
    success,
}

interface ClaimViewModel {
    wallet?: TezosWallet | null;
    claimState: AirdropClaimState | null;
    desiredStep: ClaimStep;
    airdropStarted: boolean;
    currentDelegate: DelegationStatus | null;
}

@UntilDestroy()
@Component({
    selector: 'td-claim',
    templateUrl: './claim.component.html',
    styleUrls: ['./claim.component.scss'],
})
export class ClaimComponent implements OnInit {
    reverseRecord: ReverseRecord | null = null;
    operation: Observable<SmartContractOperationEvent> | null;

    @ViewChild(MatStepper) stepper: MatStepper;

    vm$: Observable<ClaimViewModel>;
    claimStatus = ClaimStatus;
    currentStep: number = ClaimStep.start;
    operationInProgress = false;
    tokenAmount = BigNumber(0);

    airdropAccumulationStart = this.dayJsPipe.transform(new Date('2021-04-06T00:00:00Z'), 'short');
    airdropAccumulationEnd = this.dayJsPipe.transform(new Date('2023-04-30T00:00:00Z'), 'short');

    constructor(
        private tezosService: TezosService,
        private tezosWalletService: TezosWalletService,
        private reverseRecordService: ReverseRecordService,
        private appService: AppService,
        private claimInfo: ClaimInfoService,
        private poolStats: PoolStatsService,
        private govAction: GovActionService,
        private delegationStatus: DelegationStatusService,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public config: Configuration,
        private dayJsPipe: DayjsDatePipe
    ) {}

    ngOnInit(): void {
        const desiredClaimStep$ = this.route.params.pipe(map(p => (ClaimStep[p.step] ?? ClaimStep.start) as unknown as ClaimStep));

        this.vm$ = combineLatest([desiredClaimStep$, this.tezosService.activeWallet, this.delegationStatus.delegationStatus$]).pipe(
            tap(([_, wallet]) => {
                if (!wallet) {
                    this.appService.openConnect();
                }
            }),
            switchMap(([desiredStep, wallet, currentDelegate]) =>
                wallet
                    ? this.claimInfo
                          .canClaimTokens(wallet.address)
                          .pipe(map(claimState => ({ wallet, claimState, desiredStep, airdropStarted: this.airdropHasStarted(), currentDelegate })))
                    : of({ wallet: null, claimState: null, desiredStep, airdropStarted: this.airdropHasStarted(), currentDelegate })
            ),
            startWith(<ClaimViewModel>{
                wallet: undefined,
                claimState: null,
                desiredStep: ClaimStep.start,
                airdropStarted: this.airdropHasStarted(),
            }),
            tap(model => {
                if (model.claimState?.status === ClaimStatus.Unclaimed) {
                    this.tokenAmount = model.claimState.amountClaimable;
                }

                this.currentStep = model.desiredStep;
            })
        );

        this.reverseRecordService.current.pipe(untilDestroyed(this)).subscribe(r => (this.reverseRecord = r));
    }

    connect(): void {
        this.appService.openConnect();
    }

    delegateSelected(
        data: {
            wantsToDepositVotes: boolean;
            wantsToDelegate: boolean;
            delegate: ReverseRecord | null;
        },
        claimState: AirdropClaimState | null,
        owner: string
    ): void {
        if (!claimState) {
            return;
        }

        const govActions = this.govAction.q.claim(...claimState.claimableClaims);

        if (data.wantsToDepositVotes) {
            govActions.deposit(claimState.amountClaimable, owner);
        }

        if (data.wantsToDelegate && data.delegate) {
            govActions.delegate(data.delegate.address);
        }

        this.operation = govActions.send();
        this.operationInProgress = true;
    }

    operationDone(event: OperationStatusDoneEvent, claimState: AirdropClaimState) {
        if (event.success) {
            this.poolStats.refresh(claimState.claimableClaims);
            this.tezosWalletService.refreshTokenBalance();
            this.delegationStatus.refresh();

            const nextStep = this.currentStep + 1;
            this.navigateTo(nextStep);
        }

        this.operationInProgress = false;
    }

    onApprovalChange(approved: boolean): void {
        if (approved) {
            this.next();
        } else {
            this.stepper.reset();
            this.navigateTo(ClaimStep.start);
        }
    }

    clear(): void {
        this.operation = null;
        this.operationInProgress = false;
    }

    next(): void {
        const nextStep = this.currentStep + 1;
        this.navigateTo(nextStep);
    }

    back(): void {
        this.currentStep--;
        this.location.back();
    }

    private navigateTo(step: ClaimStep) {
        const routeStep = ClaimStep[step] != null ? step : ClaimStep.start;
        this.router.navigateByUrl(`/airdrop/${ClaimStep[routeStep].toLowerCase()}`);
    }

    private airdropHasStarted() {
        return this.config.airdrop.start < new Date();
    }
}
