import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { RecipientComponent } from 'src/app/shared/recipient.component';

@Component({
    selector: 'td-operator-search',
    templateUrl: './operator-search.component.html',
    styleUrls: ['./operator-search.component.scss'],
})
export class OperatorSearchComponent {
    @Output() address = new EventEmitter<string>();

    form: FormGroup<{
        inputAddress: FormControl<string>;
    }>;

    @ViewChild(RecipientComponent) recipient: RecipientComponent;

    constructor(private formBuilder: FormBuilder) {
        this.form = this.formBuilder.group({
            inputAddress: this.formBuilder.control('', { nonNullable: true }),
        });
    }

    submitSearch(): void {
        if (this.form.invalid) {
            return;
        }

        this.address.next(this.form.get('inputAddress')!.value);
        this.recipient.reset();
        this.form.reset();
    }
}
