import { inject, Injectable } from '@angular/core';
import BigNumber from 'bignumber.js';
import { uniqBy } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AirdropClaim } from '../airdrop/models';
import { Configuration } from '../configuration';
import {
    PoolStatisticsGQL,
    PoolStatisticsQuery,
    PoolStatisticsUnauthenticatedGQL,
    PoolStatisticsUnauthenticatedQuery,
} from '../governance-core/governance-graphql.generated';
import { TezosWallet } from '../tezos/models';
import { TezosWalletService } from '../tezos/tezos-wallet.service';
import { TezosService } from '../tezos/tezos.service';
import { Unarray } from '../utils/types';
import { calculateDepositedBalance } from './deposited-balance.calculator';
import { PoolStats } from './models';
import { PoolContractStatsService, PoolContractStorage, TedVContractService, TedVContractStorage } from './pool-contract-stats.service';

export type IndexerClaim = Unarray<PoolStatisticsQuery['claims']>;

@Injectable({
    providedIn: 'root',
})
export class PoolStatsService {
    private poolStatisticsQuery = inject(PoolStatisticsGQL);
    private poolStatisticsUnauthenticatedQuery = inject(PoolStatisticsUnauthenticatedGQL);
    private tezosService = inject(TezosService);
    private tezosWalletService = inject(TezosWalletService);
    private config = inject(Configuration);
    private poolContract = inject(PoolContractStatsService);
    private tedvContract = inject(TedVContractService);

    private refresh$ = new BehaviorSubject<void>(void 0);
    private _loading$ = new BehaviorSubject<boolean>(true);
    private recentlyClaimed$ = new BehaviorSubject<IndexerClaim[]>([]);

    readonly loading$ = this._loading$.asObservable();

    readonly stats$ = this.tezosService.activeWallet.pipe(
        tap(() => this._loading$.next(true)),
        switchMap(wallet => {
            if (!wallet) {
                return combineLatest([this.poolContract.storage$, this.tedvContract.storage$, this.loadStatsUnauthenticated()]).pipe(
                    map(([poolStorage, tedvStorage, indexerData]) => this.mapPoolStats(indexerData, null, BigNumber(0), BigNumber(0), poolStorage, tedvStorage))
                );
            }

            return combineLatest([
                wallet.tedBalance$,
                wallet.votesBalance$,
                this.poolContract.storage$,
                this.tedvContract.storage$,
                this.loadStats(wallet.address),
            ]).pipe(
                map(([tedBalance, votesBalance, poolStorage, tedvStorage, indexerData]) =>
                    this.mapPoolStats(indexerData, wallet, tedBalance, votesBalance, poolStorage, tedvStorage)
                )
            );
        }),
        tap(() => this._loading$.next(false)),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    constructor() {
        // initialize stats as soon as possible
        this.stats$.subscribe();
        this.tezosService.activeWallet.pipe(filter(wallet => !wallet)).subscribe(() => this.recentlyClaimed$.next([]));
    }

    refresh(recentlyClaimed: AirdropClaim[] = []): void {
        if (recentlyClaimed.length) {
            this.recentlyClaimed$.next(this.toIndexerClaims(recentlyClaimed));
        }
        this.poolContract.refresh();
        this.refresh$.next();
        this.tezosWalletService.refreshTokenBalance();
    }

    private toIndexerClaims(recentlyClaimed: AirdropClaim[]): IndexerClaim[] {
        return recentlyClaimed.map(c => ({
            __typename: 'claims',
            address: c.owner,
            from_airdrop: true,
            amount: c.amount,
            claimable_from: c.from,
        }));
    }

    private loadStats(address: string): Observable<PoolStatisticsQuery | undefined> {
        return this.refresh$.pipe(
            tap(() => this._loading$.next(true)),
            switchMap(() => {
                const serverResult$ = this.poolStatisticsQuery
                    .fetch({ address, votesContractAddress: this.config.network.tedVotesContract }, { fetchPolicy: 'network-only' })
                    .pipe(
                        catchError(() => of(undefined)),
                        map(result => result?.data)
                    );

                return combineLatest([serverResult$, this.recentlyClaimed$]).pipe(
                    map(([serverData, recentlyClaimed]) => {
                        if (!serverData) {
                            return undefined;
                        }
                        return {
                            ...serverData!,
                            claims: uniqBy([...(serverData?.claims ?? []), ...recentlyClaimed], c => c.claimable_from.toISOString()),
                        };
                    })
                );
            })
        );
    }

    private loadStatsUnauthenticated(): Observable<PoolStatisticsUnauthenticatedQuery | undefined> {
        return this.refresh$.pipe(
            tap(() => this._loading$.next(true)),
            switchMap(() =>
                this.poolStatisticsUnauthenticatedQuery
                    .fetch({ votesContractAddress: this.config.network.tedVotesContract }, { fetchPolicy: 'network-only' })
                    .pipe(
                        catchError(() => of(undefined)),
                        map(result => result?.data)
                    )
            )
        );
    }

    private mapPoolStats(
        indexerData: PoolStatisticsQuery | PoolStatisticsUnauthenticatedQuery | undefined,
        wallet: TezosWallet | null,
        tedBalance: BigNumber,
        votesBalance: BigNumber,
        poolStorage: PoolContractStorage | null,
        tedvStorage: TedVContractStorage | null
    ) {
        if (!indexerData || !poolStorage) {
            return null;
        }

        const stats = {
            owner: wallet?.address || null,

            tedRewarded: BigNumber(0),
            poolAverageAPR: BigNumber(indexerData.rewardPeriodsAggregate.aggregate?.avg?.Apr ?? 0),
            poolCurrentAPR: BigNumber(indexerData.poolStates?.[0].current_apr ?? 0),
            totalVoters: indexerData.currentBalancesAggregate.aggregate?.count ?? 0,

            poolSize: BigNumber(poolStorage.poolSize),
            sharesMinted: BigNumber(poolStorage.votesMinted),
            totalSupply: BigNumber(tedvStorage?.totalSupply ?? 0),

            tedBalance,
            votesBalance,
            depositedTedBalance: calculateDepositedBalance({ poolStorage, votesBalance }),
            claims: [],
        } as PoolStats;

        if ('rewardsAggregate' in indexerData) {
            stats.tedRewarded = BigNumber(indexerData.rewardsAggregate.aggregate?.sum?.Amount ?? 0);
        }

        if ('claims' in indexerData) {
            stats.claims = indexerData.claims.map(c => ({
                address: c.address,
                airdrop: c.from_airdrop,
                from: c.claimable_from,
                amount: BigNumber(c.amount),
            }));
        }

        return stats;
    }
}
