<ng-container *transloco="let t">
    <div class="d-flex flex-column">
        <h1 class="fw-500">{{ t('airdrop.delegate-list.title') }}</h1>
        <div class="step-1 mx-1 mx-sm-2">
            <mat-checkbox
                [checked]="wantsToDepositVotes"
                class="mt-4 mb-2"
                [style]="'font-size: 18px'"
                (change)="wantsToDepositVotes = $event.checked; selected = null"
            >
                <span class="wrap-mat-radio-label text-wrap"> {{ t('airdrop.delegate-list.wants-rewards') }} </span>
            </mat-checkbox>

            <div class="mx-2 mx-sm-4 py-3">
                <div class="d-flex flex-column" *ngIf="wantsToDepositVotes">
                    <div class="mb-4 flex-shrink-0">
                        {{ t('airdrop.delegate-list.deposit-info', { amount: tokenAmount | formatTed }) }}
                        <div class="mt-1" *ngIf="poolStats$ | async as poolStats">
                            {{ showAverageApr ? t('airdrop.delegate-list.average-apr') : t('airdrop.delegate-list.current-apr') }}&nbsp;
                            <span class="fw-bold">{{ (showAverageApr ? poolStats.poolAverageAPR : poolStats.poolCurrentAPR) | apr }}</span>
                        </div>
                    </div>
                    <td-delegation-selector (delegationSelected)="onDelegationSelected($event)" [delegate]="currentDelegate"></td-delegation-selector>
                </div>
                <div class="no-reward" *ngIf="!wantsToDepositVotes">
                    <div class="mb-3 flex-shrink-0">
                        {{ t('airdrop.delegate-list.no-rewards') }}
                    </div>
                </div>

                <div class="my-4 d-flex flex-column-reverse flex-sm-row align-items-stretch align-items-sm-end justify-content-end gap-3">
                    <button mat-flat-button (click)="back.next()">{{ t('actions.back') }}</button>
                    <button
                        mat-flat-button
                        color="primary"
                        (click)="continue()"
                        [disabled]="(wantsToDepositVotes && wantsToDelegate && !selected) || disabled || tokenAmount.eq(0)"
                    >
                        <span class="text-wrap">{{ t('airdrop.delegate.action.claim', { amount: tokenAmount | formatTed }) }}</span>
                    </button>
                </div>

                <ng-container *ngTemplateOutlet="operationStatusTemplate"></ng-container>
            </div>
        </div>
    </div>
</ng-container>
