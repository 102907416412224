import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { HardwareWalletSettingsService } from '../tezos/hardware-wallet-settings.service';
import { AdvancedSelectOption } from '../shared/advanced-select.component';
import { TdValidators } from '../utils/form-validators';

@Component({
    selector: 'td-derivation-path-editor',
    templateUrl: './derivation-path-editor.component.html',
})
export class DerivationPathEditorComponent implements OnInit, OnDestroy {
    derivationPath: string;
    builtInDerivationPaths: AdvancedSelectOption[] = [
        { label: 'Ledger Live', value: `44'/1729'/0'/0'` },
        { label: 'Galleon', value: `44'/1729'/0'/0'/0'` },
    ];
    validators = [TdValidators.derivationPath];

    private unsubscribe = new Subject<void>();

    constructor(private hardwareWalletSettingsService: HardwareWalletSettingsService) {}

    ngOnInit(): void {
        this.hardwareWalletSettingsService.activeSettings.pipe(takeUntil(this.unsubscribe)).subscribe(s => {
            this.derivationPath = s.derivationPath;
        });
    }

    setDerivationPath() {
        this.hardwareWalletSettingsService.setDerivationPath(this.derivationPath);
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
