<div
    *transloco="let t"
    class="card smooth-shadow home-item d-flex flex-column"
    [hidden]="(offers.empty$ | async) !== false"
    [ngClass]="{ empty: offers.empty$ | async }"
>
    <div class="d-flex w-100 mb-2">
        <h2 class="flex-grow-1 mb-0">{{ t('home.hot-offers-title') }}</h2>
        <div class="d-flex align-items-center flex-shrink-0">
            <a [routerLink]="['/listings']">{{ t('general.see-more') }}</a>
        </div>
    </div>
    <td-hot-offers-summary-table #offers class="full-card-width" [noShadow]="true"></td-hot-offers-summary-table>
    <div class="mt-3 w-100">
        <a [routerLink]="['/listings']">{{ t('general.see-more') }}</a>
    </div>
</div>
