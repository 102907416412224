import { Component, Input } from '@angular/core';

@Component({
    selector: 'td-table-skeleton',
    templateUrl: './table-skeleton.component.html',
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
})
export class TableSkeletonComponent {
    @Input() rows: number;
    @Input() seeMore: boolean;
}
