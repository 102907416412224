<div *transloco="let t">
    <td-error class="mb-3" *ngIf="dataSource.error$ | async; let error" [message]="error.message" [title]="t('general.error')"></td-error>

    <h1>Audit</h1>

    <div *ngIf="form">
        <form [formGroup]="form" action="." (ngSubmit)="reload()">
            <div class="d-flex flex-column">
                <div class="d-flex gap-3">
                    <mat-form-field class="w-100" appearance="standard">
                        <mat-label>{{ t('event-table.date-label') }}</mat-label>
                        <mat-date-range-input [max]="maxDate" [min]="minDate" [rangePicker]="picker">
                            <input matStartDate formControlName="from" [attr.placeholder]="t('event-table.date-from-label')" />
                            <input matEndDate formControlName="until" [attr.placeholder]="t('event-table.date-until-label')" />
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>

                        <mat-date-range-picker startView="year" [startAt]="minDate" #picker>
                            <mat-date-range-picker-actions>
                                <button mat-stroked-button (click)="form.reset()" matDateRangePickerCancel>{{ t('actions.clear') }}</button>
                                <button mat-flat-button color="primary" matDateRangePickerApply>{{ t('actions.apply') }}</button>
                            </mat-date-range-picker-actions>
                        </mat-date-range-picker>
                    </mat-form-field>
                    <mat-form-field class="w-100" appearance="standard">
                        <mat-label>Types</mat-label>
                        <mat-select formControlName="types" multiple>
                            <mat-option *ngFor="let type of types" [value]="type">{{ type }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="d-flex gap-3">
                    <mat-form-field class="w-100" appearance="standard">
                        <mat-label>Source Address</mat-label>
                        <input formControlName="address" matInput />
                        <mat-error>
                            <td-validation-messages [control]="form.get('address')!" [context]="{ name: 'Source Address' }"></td-validation-messages>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100" appearance="standard">
                        <mat-label>Domain Name</mat-label>
                        <input formControlName="name" matInput />
                        <mat-error>
                            <td-validation-messages [control]="form.get('name')!" [context]="{ name: 'Domain Name' }"></td-validation-messages>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>

    <div *ngIf="(dataSource.empty$ | async) && (hideResults | async) === false" class="empty-message p-3 w-100 text-center smooth-shadow">
        {{ t('event-table.no-events') }}
    </div>

    <div
        *ngIf="network"
        class="table-container smooth-shadow d-flex"
        infiniteScroll
        (scrolled)="scrolled()"
        [hidden]="(dataSource.empty$ | async) || (hideResults | async)"
    >
        <mat-table [style.flex]="'1 0 700px'" [dataSource]="dataSource">
            <ng-container matColumnDef="time">
                <mat-cell [style.flex]="'0 0 150px'" *matCellDef="let row">
                    <span [matTooltip]="row.block.timestamp | dayjsDate"><td-humanized-date [value]="row.block.timestamp"></td-humanized-date></span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="address">
                <mat-cell [style.flex]="'0 0 326px'" *matCellDef="let row">
                    <div class="flex-grow-1" class="text-truncate pe-3">
                        <td-address [address]="row.sourceAddress" [reverseRecord]="row.sourceAddressReverseRecord" [copy]="false"></td-address>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="text">
                <mat-cell class="flex-grow-1" *matCellDef="let row">
                    <div class="text-truncate">
                        <td-event-text [event]="row"></td-event-text>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="link">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let row" class="flex-grow-0" [style.flex-basis]="'auto'">
                    <a
                        *ngIf="row.operationGroupHash"
                        class="me-3 txt-primary"
                        [attr.href]="'https://better-call.dev/' + network.name + '/opg/' + row.operationGroupHash + '/contents'"
                        target="_blank"
                    >
                        <fa-icon icon="external-link-alt"></fa-icon>
                    </a>
                </mat-cell>
            </ng-container>
            <mat-row *matRowDef="let row; columns: ['time', 'address', 'text', 'link']"></mat-row>
        </mat-table>
    </div>
    <div *ngIf="dataSource.loading$ | async" class="w-100 d-flex justify-content-center">
        <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>
</div>
