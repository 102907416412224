import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import dayjs from 'dayjs';
import 'dayjs/plugin/relativeTime';
import { interval } from 'rxjs';
import { LocaleService } from './locale.service';

@UntilDestroy()
@Component({
    selector: 'td-humanized-date',
    templateUrl: './humanized-date.component.html',
})
export class HumanizedDateComponent implements OnInit {
    private _value?: dayjs.Dayjs | null;

    @Input() textWhenEmpty?: string | null;
    @Input() readOnly = false;
    @Input() prefix = '';

    @Input() set value(value: dayjs.Dayjs | Date | null | undefined) {
        if (value instanceof Date) {
            value = dayjs(value);
        }

        this._value = value;
        this.transform();
    }

    @Input() withoutSuffix: boolean;

    displayValue: string;

    constructor(private localeService: LocaleService) {}

    ngOnInit() {
        this.transform();
        if (this.readOnly) {
            return;
        }

        interval(30_000)
            .pipe(untilDestroyed(this))
            .subscribe(() => this.transform());
    }

    private transform() {
        const value = this._value;
        if (!value) {
            this.displayValue = this.textWhenEmpty ?? '';
            return;
        }

        this.displayValue = value.locale(this.localeService.getLocale()).fromNow(this.withoutSuffix);
    }
}
