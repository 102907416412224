import { DataSourceBase, DataSourceOptions, ProcessedData } from '../data-source-base';
import { DomainDetailQuery, DomainDetailQueryVariables } from './graphql.generated';

export class DomainDetailDataSource extends DataSourceBase<DomainDetailQuery, DomainDetailQuery, DomainDetailQueryVariables> {
    constructor(options: DataSourceOptions<DomainDetailQuery, DomainDetailQueryVariables>) {
        super(options);
    }

    protected transformData(data: DomainDetailQuery): ProcessedData<DomainDetailQuery> {
        return {
            data,
        };
    }
}
