<div class="address d-flex align-items-center" *transloco="let t">
    <div [class.me-2]="text !== false" *ngIf="icon !== false">
        <img ngxGravatar *ngIf="gravatarHash || gravatarEmail" [md5Hash]="gravatarHash!" [email]="gravatarEmail!" [size]="iconSize || 24" />
        <identicon *ngIf="!gravatarHash && !gravatarEmail" [address]="address" [size]="iconSize || 24"></identicon>
    </div>
    <div *ngIf="text !== false" [style.minWidth]="'calc(100% - ' + (icon !== false ? '29' : '0') + 'px)'" class="d-flex flex-column">
        <div *ngIf="name && text !== 'address-only'" class="d-flex flex-column">
            <span *ngIf="text !== 'name-or-address'" class="text-truncate rr font-monospace d-flex">
                <ng-container *ngTemplateOutlet="nameText"></ng-container> <ng-container *ngTemplateOutlet="transcludedContent"></ng-container>
            </span>
            <span *ngIf="text === 'name-or-address'" class="text-truncate rr font-monospace d-flex">
                <a *ngIf="link !== false" [routerLink]="route || ['/address', address]">
                    <ng-container *ngTemplateOutlet="nameText"></ng-container>
                </a>
                <ng-container *ngIf="link === false">
                    <ng-container *ngTemplateOutlet="nameText"></ng-container>
                </ng-container>
                <ng-container *ngTemplateOutlet="transcludedContent"></ng-container>
            </span>
        </div>
        <div *ngIf="[undefined, true, 'address-only'].includes(text) || (text === 'name-or-address' && !name)" class="d-flex">
            <a *ngIf="link !== false" [routerLink]="route || ['/address', address]">
                <ng-container *ngTemplateOutlet="addressText"></ng-container>
            </a>
            <ng-container *ngIf="link === false">
                <ng-container *ngTemplateOutlet="addressText"></ng-container>
            </ng-container>
            <td-copy-to-clipboard
                [matTooltip]="t('general.copy-to-clipboard', { what: 'address' })"
                *ngIf="copy !== false"
                [text]="address"
                class="ms-2 no-underline"
            ></td-copy-to-clipboard>
            <td-tzprofile *ngIf="profile" [address]="address"></td-tzprofile>
            <a *ngIf="statsLink" [attr.href]="statsLink" target="_blank" class="ms-2 stats-link" [matTooltip]="t('general.view-in-explorer')">
                <fa-icon icon="external-link-alt" class="me-2"></fa-icon>
            </a>
        </div>
    </div>
</div>

<ng-template #addressText>
    <span class="font-monospace txt">{{ short ? (address | truncate : 30 : 7 : 4) : address }}</span>
</ng-template>

<ng-template #nameText>
    {{ shortenName ? (name | truncate : 15 : 7 : 6 : '..') : name }}
</ng-template>

<ng-template #transcludedContent>
    <ng-content></ng-content>
</ng-template>
