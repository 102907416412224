import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Configuration } from '../configuration';
import { ClaimableTlDsListGQL } from '../graphql/graphql.generated';

@Injectable({
    providedIn: 'root',
})
export class ClaimableTldsService {
    constructor(private claimableTLDsGql: ClaimableTlDsListGQL, private configuration: Configuration) {}

    getTlds(): Observable<string[]> {
        return this.claimableTLDsGql
            .fetch(
                { where: { owner: { equalTo: this.configuration.network.oracleRegistrarContract }, level: { equalTo: 1 } } },
                { fetchPolicy: 'no-cache', errorPolicy: 'none' }
            )
            .pipe(map(({ data }) => data.domains.edges.map(e => e.node.name)));
    }
}
