<div
    *transloco="let t"
    class="card smooth-shadow home-item w-100 d-flex flex-column"
    [hidden]="!expiredDomains.someDomainsLoaded"
    [ngClass]="{ empty: !expiredDomains.someDomainsLoaded }"
>
    <div class="d-flex mb-2">
        <h2 class="flex-grow-1 mb-0">{{ t('home.expired-domains-title') }}</h2>
        <div class="d-flex align-items-center flex-shrink-0">
            <a [routerLink]="['/domains/expired']">{{ t('general.see-more') }}</a>
        </div>
    </div>
    <td-expired-domains-table
        class="full-card-width"
        #expiredDomains
        [maxCount]="5"
        [scrollToLoad]="false"
        [showShadow]="false"
        [showFilter]="false"
        [pollInterval]="10 * 60 * 1_000"
        (dataLoading)="onDataLoading($event)"
    ></td-expired-domains-table>

    <div class="mt-3 d-flex">
        <a [routerLink]="['/domains/expired']">{{ t('general.see-more') }}</a>
    </div>
</div>
