import { Component, Input } from '@angular/core';

@Component({
    selector: 'td-divider-with-text',
    templateUrl: './divider-with-text.component.html',
    styleUrls: ['./divider-with-text.component.scss'],
})
export class DividerWithTextComponent {
    @Input() text: string;
}
