import { Component, Input } from '@angular/core';

export type AlertState = 'danger' | 'warning' | 'info' | 'success';

@Component({
    selector: 'td-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
    @Input() title: string;
    @Input() state: AlertState;
    @Input() inProgress: boolean;
    @Input() showIcon = true;

    @Input() small = false;
}
