<div class="d-flex flex-column flex-fill h-100 account-content" *transloco="let t" (click)="$event.stopPropagation()">
    <div class="mb-2 d-flex align-items-center flex-shrink-0">
        <h1 mat-dialog-title class="text-truncate m-0 flex-grow-1">{{ t('account.title') }}</h1>
        <button mat-icon-button class="lg" (click)="close()" class="dialog-close">
            <fa-icon size="lg" icon="times"></fa-icon>
        </button>
    </div>
    <mat-dialog-content class="d-flex flex-column flex-grow-1">
        <ng-container *ngIf="wallet && beaconWallet">
            <div class="mb-2 d-flex align-items-center">
                <div class="d-flex align-items-center flex-grow-1">
                    <span>{{ t('account.connected-with') }}</span>
                    <img class="wallet-logo ms-1" *ngIf="beaconWallet.icon" [attr.src]="beaconWallet.icon" />
                    <strong class="ms-1">{{ beaconWallet.walletName }}</strong>
                </div>

                <button mat-stroked-button (click)="change()">{{ t('general.change') }}</button>
            </div>
            <div class="mb-2 d-flex flex-column">
                <div class="d-flex align-items-center">
                    <td-address class="me-2" [text]="false" [iconSize]="32" [address]="wallet.address" [reverseRecord]="reverseRecord"></td-address>
                    <div class="d-flex flex-column flex-grow-1">
                        <div *ngIf="reverseRecord && reverseRecord.domain" class="reverse-record font-monospace text-truncate">
                            {{ reverseRecord.domain.name }}
                        </div>
                        <div *ngIf="!reverseRecord || !reverseRecord.domain">
                            <span>{{ t('account.reverse-record-not-set') }} </span>
                            <a (click)="close()" [routerLink]="['/reverse-record', wallet.address]">{{ t('account.setup-reverse-record') }}</a>
                        </div>
                        <td-address [address]="wallet.address" [link]="false" [icon]="false" [stats]="true"></td-address>
                    </div>
                </div>
                <span class="balance d-flex align-items-center">
                    <div>{{ t('general.balance') }}: <td-tez [value]="wallet.balance | async"></td-tez></div>
                    <fa-icon
                        [matTooltip]="t('account.low-balance')"
                        *ngIf="wallet.isBalanceLow | async"
                        icon="exclamation-triangle"
                        class="txt-warning px-1 no-underline"
                    ></fa-icon>
                </span>
            </div>
        </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex align-items-end w-100 min-height-auto">
        <a *ngIf="wallet" (click)="close()" mat-flat-button color="primary" [routerLink]="['/address', wallet.address]" class="mb-1 w-100">
            <fa-icon icon="layer-group"></fa-icon>
            {{ t('general.go-to-dashboard') }}
        </a>
    </mat-dialog-actions>
</div>
