import { Component, Input } from '@angular/core';
import BigNumber from 'bignumber.js';

@Component({
    selector: 'td-tokens',
    templateUrl: './tokens.component.html',
    styleUrls: ['./tokens.component.scss'],
})
export class TokensComponent {
    @Input() label: string;
    @Input() value: BigNumber;
}
