import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ErrorModule } from '../error/error.module';
import { SharedModule } from '../shared/shared.module';
import { ConfirmComponent } from './confirm.component';
import { SubscribeButtonComponent } from './subscribe-button.component';
import { SubscribeComponent } from './subscribe.component';
import { UnsubscribeComponent } from './unsubscribe.component';

@NgModule({
    declarations: [ConfirmComponent, UnsubscribeComponent, SubscribeButtonComponent, SubscribeComponent],
    exports: [SubscribeButtonComponent, SubscribeComponent],
    imports: [SharedModule, ErrorModule, ReactiveFormsModule],
})
export class NotificationsModule {}
