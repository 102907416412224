import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'td-latest-favourites-table',
    templateUrl: './latest-favourites-table.component.html',
    styleUrls: ['./latest-favourites-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LatestFavouritesTableComponent {
    @Input() address: string;
    @Output() loaded = new EventEmitter<number>();
    @Output() initialLoading = new BehaviorSubject<boolean>(true);
}
