import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DomainNameValidationResult, getLevel, tokenizeDomainName } from '@tezos-domains/core';
import { TaquitoTezosDomainsClient } from '@tezos-domains/taquito-client';
import trim from 'lodash-es/trim';
import { Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { TrackingService } from '../browser/tracking.service';
import { DomainService } from '../domains/domain.service';
import { TezosDomainsClientService } from '../tezos/integration/tezos-domains-client.service';

@Injectable({
    providedIn: 'root',
})
export class SearchService {
    constructor(
        private tezosDomainsClientService: TezosDomainsClientService,
        private router: Router,
        private trackingService: TrackingService,
        private domainService: DomainService
    ) {}

    search(term: string, replaceUrl?: boolean): Observable<void> {
        this.trackingService.event('search', { search_term: term });

        if (!term) {
            this.router.navigate(['/']);
            return of(void 0);
        }

        return this.tezosDomainsClientService.current.pipe(
            first(),
            switchMap(c => {
                if (c.validator.isValidWithKnownTld(term) === DomainNameValidationResult.VALID && getLevel(term) > 2) {
                    return this.domainService.isDomainAvailable(term).pipe(map(available => ({ client: c, subdomainExists: !available })));
                }
                return of({ client: c, subdomainExists: false });
            }),
            map(({ client, subdomainExists }) => {
                if (subdomainExists) {
                    if (this.router.url.startsWith(`/domain/${term}`)) {
                        return;
                    }

                    this.router.navigate(['/domain', term], { replaceUrl });
                }

                const sanitizedTerm = this.sanitizeDomainName(client, term);
                if (sanitizedTerm) {
                    this.router.navigate(['/search'], { replaceUrl, queryParams: { q: term } });
                }
            })
        );
    }

    sanitizeDomainName(client: TaquitoTezosDomainsClient, name: string): string {
        if (!name) {
            return '';
        }
        const defaultTld = client.validator.supportedTLDs[0];

        name = name.toLowerCase();

        const tokens = tokenizeDomainName(name)
            .map(t => trim(t.replace(/[^a-z0-9-]/g, '').trim(), '-'))
            .filter(t => t);

        if (!tokens.length) {
            return '';
        }

        if (tokens.length === 1) {
            return `${tokens[0]}.${defaultTld}`;
        }

        let tld = tokens[tokens.length - 1];

        if (tokens.length > 1) {
            tokens.splice(-1, 1);
        }

        const label = tokens.join('');

        if (!client.validator.supportedTLDs.includes(tld)) {
            tld = defaultTld;
        }

        return `${label}.${tld}`;
    }
}
