import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MatDialogRef } from '@angular/material/dialog';
import { AppService } from '../app-service';
import { ReverseRecord, ReverseRecordService } from '../domains/reverse-record.service';
import { DomainListRecord } from '../graphql/domain-table-data-source';
import { EventsRecord } from '../graphql/events-table-data-source';
import { TezosBeaconWalletManager } from '../tezos/connector/tezos-wallet-manager';
import { TezosBeaconWallet, TezosWallet } from '../tezos/models';
import { TezosService } from '../tezos/tezos.service';

@Component({
    selector: 'td-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit, OnDestroy {
    wallet: TezosWallet | null;
    reverseRecord: ReverseRecord | null;
    domains: DomainListRecord[] | null;
    events: EventsRecord[] | null;

    private unsubscribe = new Subject<void>();
    beaconWallet: TezosBeaconWallet | null;

    constructor(
        private tezosService: TezosService,
        private tezosBeaconWalletManager: TezosBeaconWalletManager,
        private reverseRecordService: ReverseRecordService,
        private router: Router,
        private appService: AppService,
        private dialogRef: MatDialogRef<any>
    ) {}

    change() {
        this.tezosBeaconWalletManager.disconnect().subscribe(async () => {
            this.close();

            await this.router.navigate(['/']);

            this.appService.openConnect();
        });
    }

    ngOnInit(): void {
        this.tezosService.activeWallet.pipe(takeUntil(this.unsubscribe)).subscribe(w => (this.wallet = w));
        this.reverseRecordService.current.pipe(takeUntil(this.unsubscribe)).subscribe(r => (this.reverseRecord = r));

        this.tezosBeaconWalletManager.activeWallet.pipe(takeUntil(this.unsubscribe)).subscribe(c => (this.beaconWallet = c));
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
