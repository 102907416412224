import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { TezosService } from '../tezos/tezos.service';
import { TezosWallet } from '../tezos/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'td-address-editor',
    templateUrl: './address-editor.component.html',
    styleUrls: ['./address-editor.component.scss'],
})
export class AddressEditorComponent implements OnInit, OnDestroy {
    @Input() control: AbstractControl;
    @Input() placeholder: string;
    @Input() label: string;
    @Input() required: boolean;

    wallet: TezosWallet | null;

    private unsubscribe = new Subject<void>();

    constructor(private tezosService: TezosService) {}

    ngOnInit() {
        this.tezosService.activeWallet.pipe(takeUntil(this.unsubscribe)).subscribe(w => (this.wallet = w));
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    clear() {
        if (!this.control.disabled) {
            this.control.setValue('');
        }
    }

    setMy() {
        if (!this.control.disabled) {
            this.control.setValue(this.wallet?.address);
        }
    }
}
