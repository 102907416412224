import { Component, OnInit } from '@angular/core';
import { PageService } from '../browser/page.service';

@Component({
    selector: 'td-not-found',
    templateUrl: './not-found.component.html',
})
export class NotFoundComponent implements OnInit {
    constructor(private pageService: PageService) {}

    ngOnInit() {
        this.pageService.setTitle('not-found');
    }
}
