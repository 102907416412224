import { Injectable } from '@angular/core';
import { TezosToolkit } from '@taquito/taquito';
import { Observable, combineLatest, from, ReplaySubject, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';

import { TezosService } from '../tezos.service';
import { TezosNetworkService } from '../tezos-network.service';
import { TezosNetwork } from '../models';
import { Logger } from '../../browser/logger';

export type HarbingerNormalizerResult = [string, BigNumber];

@Injectable({
    providedIn: 'root',
})
export class HarbingerService {
    private usdPriceStream: ReplaySubject<number>;

    constructor(private tezosService: TezosService, private tezosNetworkService: TezosNetworkService, private log: Logger) {}

    convert(amount: number): Observable<number> {
        if (!this.usdPriceStream) {
            this.usdPriceStream = new ReplaySubject(1);
            timer(0, 10 * 60 * 1000)
                .pipe(
                    switchMap(() => combineLatest([this.tezosService.tezosToolkit, this.tezosNetworkService.activeNetwork])),
                    switchMap(([tezos, network]) => from(this.getTezosPrice(tezos, network)))
                )
                .subscribe(([price, updated]) => {
                    if (updated.isBefore(dayjs().subtract(2, 'hours'))) {
                        this.log.warn(`[Harbinger] Last update ${updated.fromNow()} (${updated.toISOString()}). Not using outdated value.`);
                    } else {
                        this.usdPriceStream.next(price / 1e6);
                    }
                });
        }

        return this.usdPriceStream.pipe(map(price => amount * price));
    }

    private async getTezosPrice(tezos: TezosToolkit, network: TezosNetwork): Promise<[number, dayjs.Dayjs]> {
        if (!network.harbingerNormalizerContract) {
            return [0, dayjs()];
        }

        const contract = await tezos.wallet.at(network.harbingerNormalizerContract);

        try {
            const data = (await contract.contractViews.getPrice('XTZ-USD').executeView({ viewCaller: contract.address })) as HarbingerNormalizerResult;
            const priceData = this.mapPriceViewData(data);

            return [priceData.computedPrice, priceData.lastUpdateTime];
        } catch (ex) {
            this.log.error('[Harbinger] Unable to fetch price from harbingerNormalizer', ex);

            return [0, dayjs()];
        }
    }

    private mapPriceViewData(data: HarbingerNormalizerResult) {
        return {
            lastUpdateTime: dayjs(data[0]),
            computedPrice: data[1].toNumber(),
        };
    }
}
