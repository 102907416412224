import { Component, OnInit, Input, forwardRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ValidatorFn } from '@angular/forms';
import { takeUntil, map, first, switchMap } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import cloneDeep from 'lodash-es/cloneDeep';

import { AdvancedSelectAddComponent } from './advanced-select-add.component';
import { StorageSchema } from '../utils/storage';

export interface AdvancedSelectOption {
    label: string;
    value: string;
}

interface AdvancedSelectOptionInternal extends AdvancedSelectOption {
    isBuiltIn?: boolean;
}

@Component({
    selector: 'td-advanced-select',
    templateUrl: './advanced-select.component.html',
    styleUrls: ['./advanced-select.component.scss'],
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AdvancedSelectComponent), multi: true }],
})
export class AdvancedSelectComponent implements OnInit, ControlValueAccessor, OnDestroy {
    @Input() options: AdvancedSelectOption[];
    @Input() key: string;
    @Input() valueValidators: ValidatorFn[] = [];
    @Input() addConfig?: { title: string; labelLabel: string; valueLabel: string };
    @Output() valueChange = new EventEmitter<string | null>();

    allOptions: Observable<AdvancedSelectOptionInternal[]>;
    selectedOption: AdvancedSelectOptionInternal | null;

    private onChange: any;
    private unsubscribe = new Subject<void>();

    constructor(private storageMap: StorageMap, private dialog: MatDialog) {}

    writeValue(obj: any): void {
        this.allOptions.pipe(first()).subscribe(options => {
            this.selectedOption = options.find(o => o.value === obj) || null;
        });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    registerOnTouched(): void {}

    setOption(option: AdvancedSelectOptionInternal | null) {
        this.selectedOption = option;
        const value = option ? option.value : null;

        this.valueChange.next(value);
        this.onChange(value);
    }

    ngOnInit(): void {
        this.options = this.options ? cloneDeep(this.options) : [];
        this.allOptions = this.storageMap
            .watch<AdvancedSelectOption[]>(StorageSchema.customOptions(this.key).key, StorageSchema.customOptions(this.key).schema)
            .pipe(
                takeUntil(this.unsubscribe),
                map(options =>
                    this.options
                        .map(o => {
                            const newOption: AdvancedSelectOptionInternal = o;
                            newOption.isBuiltIn = true;
                            return newOption;
                        })
                        .concat(options || [])
                )
            );
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    addOption() {
        this.allOptions.pipe(first()).subscribe(options => {
            const dialog = this.dialog.open(AdvancedSelectAddComponent, {
                data: {
                    ...this.addConfig,
                    existingValues: options.map(o => o.value),
                    valueValidators: this.valueValidators,
                },
                width: '350px',
                height: '300px',
            });

            dialog.afterClosed().subscribe((result: AdvancedSelectOption | null) => {
                if (result) {
                    this.addCustomOption(result);
                }
            });
        });
    }

    removeOption(option: AdvancedSelectOption) {
        this.updateStoredOptions(options => {
            options = options.filter(o => !(o.value === option.value && o.label === option.label));

            return options;
        }).subscribe(() => {
            if (this.selectedOption?.value === option.value && this.selectedOption.label === option.label) {
                this.setOption(null);
            }
        });
    }

    private addCustomOption(option: AdvancedSelectOption) {
        this.updateStoredOptions(options => {
            options.push(option);

            return options;
        }).subscribe(() => {
            this.setOption(option);
        });
    }

    private updateStoredOptions(fn: (options: AdvancedSelectOption[]) => AdvancedSelectOption[]) {
        return this.storageMap.get<AdvancedSelectOption[]>(StorageSchema.customOptions(this.key).key, StorageSchema.customOptions(this.key).schema).pipe(
            switchMap(options => {
                const newOptions = fn(options || []);
                return this.storageMap.set(StorageSchema.customOptions(this.key).key, newOptions, StorageSchema.customOptions(this.key).schema);
            })
        );
    }
}
