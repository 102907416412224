import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort, SortDirection } from '@angular/material/sort';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DomainAcquisitionInfo, DomainAcquisitionState } from '@tezos-domains/manager';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { DataSourceFactory } from '../graphql/data-source-factory';
import { DomainTableDataSource } from '../graphql/domain-table-data-source';
import {
    DomainOrder,
    DomainOrderField,
    DomainsFilter,
    DomainsListQuery,
    DomainsListQueryVariables,
    LabelCompositionType,
    OrderDirection,
    RecordValidity,
} from '../graphql/graphql.generated';
import { TezosDomainsClientService } from '../tezos/integration/tezos-domains-client.service';
import { TldConfigurationService } from '../tezos/integration/tld-configuration.service';
import { Unarray } from '../utils/types';
import { DomainCategory, DomainNameType, ExpiredDomainsFilter } from './expired-domains-filter.component';

export type ExpiredDomainsTableState = {
    domainsTable?: {
        sort: {
            field: string;
            direction: SortDirection;
        };
    };
};

const domainCategoryLetters = {
    [DomainCategory.ThreeLetters]: { min: 7, max: 7 },
    [DomainCategory.FourLetters]: { min: 8, max: 8 },
    [DomainCategory.MoreThanFiveLetters]: { min: 9, max: Number.POSITIVE_INFINITY },
};

export interface ExpiredDomainData {
    expiration?: dayjs.Dayjs;
    expiredAt: dayjs.Dayjs;
    buttonText: string;
    buttonAction: () => void;
    buttonDisabled?: boolean;
    name: string;
    price: BigNumber;
}

type Domain = Unarray<DomainsListQuery['domains']['edges']>['node'];

@UntilDestroy()
@Component({
    selector: 'td-expired-domains-table',
    templateUrl: './expired-domains-table.component.html',
    styleUrls: ['./expired-domains-table.component.scss'],
})
export class ExpiredDomainsTableComponent implements OnInit {
    @Input() maxCount: number;
    @Input() showFilter = true;
    @Input() scrollToLoad = true;
    @Input() showShadow = true;
    @Input() pollInterval?: number;

    @Output() dataLoading = new EventEmitter<boolean>();

    @ViewChild(MatSort) sorter: MatSort;
    @ViewChild(InfiniteScrollDirective) infiniteScroll: InfiniteScrollDirective;

    data$: Observable<ExpiredDomainData[]>;
    dataSource: DomainTableDataSource;

    sortField: string;
    sortDirection: SortDirection;

    someDomainsLoaded = true;
    loading = true;

    constructor(
        private dataSourceFactory: DataSourceFactory,
        private router: Router,
        private tezosDomainsClientService: TezosDomainsClientService,
        private tldConfigurationService: TldConfigurationService
    ) {}

    ngOnInit(): void {
        this.dataSource = this.dataSourceFactory.createDomainsDataSource();

        this.dataSource.loading$
            .pipe(
                filter(l => l),
                untilDestroyed(this)
            )
            .subscribe(() => (this.loading = true));

        this.data$ = this.dataSource.data$.pipe(
            switchMap(domains => this.tezosDomainsClientService.defaultTld$.pipe(map(tld => ({ domains, tld })))),
            switchMap(({ domains, tld }) => {
                if (!domains?.length) {
                    return of([]);
                }

                return this.tldConfigurationService.get(tld).pipe(
                    map(tldConfig => {
                        return domains.map(d => {
                            const acquisitionInfo = this.tezosDomainsClientService.calculateAcquisitionInfo({
                                name: d.name,
                                tldConfiguration: tldConfig,
                                existingDomain: { expiry: d.expires?.toDate() ?? null },
                            });

                            return this.mapDomainModel(d, acquisitionInfo);
                        });
                    })
                );
            }),
            tap(data => {
                this.someDomainsLoaded = !!data?.length;
                this.setLoading(false);
            }),
            untilDestroyed(this)
        );

        if (!this.showFilter) {
            this.onFilterChange({
                allCategoriesSelected: true,
                sorting: { label: '', value: DomainOrderField.ExpiresAt, direction: 'desc' },
                categories: [],
                domainNameType: DomainNameType.All,
                expirationDate: null,
            });
        }
    }

    private mapDomainModel(domain: Domain, acquisitionInfo: DomainAcquisitionInfo) {
        const data: Partial<ExpiredDomainData> = {};

        data.buttonAction = () => this.router.navigate(['/domain', domain.name]);
        data.expiredAt = domain.expires!;
        data.name = domain.name;
        data.buttonDisabled = false;

        switch (acquisitionInfo.acquisitionState) {
            case DomainAcquisitionState.CanBeBought:
                const pricePerYear = new BigNumber(acquisitionInfo.calculatePrice(365));
                data.price = pricePerYear;
                data.buttonText = 'actions.register';
                break;
            case DomainAcquisitionState.CanBeAuctioned:
            case DomainAcquisitionState.AuctionInProgress:
                data.buttonText = 'actions.go-to-auction';
                data.expiration = dayjs(acquisitionInfo.auctionDetails.auctionEnd);
                data.price = new BigNumber(acquisitionInfo.auctionDetails.nextMinimumBid);
                break;
        }

        return data as ExpiredDomainData;
    }

    trackBy(_: number, domain: ExpiredDomainData) {
        return domain.name;
    }

    scrolled() {
        if (!this.scrollToLoad) {
            return;
        }

        this.dataSource.loadMore();
    }

    onFilterChange(filter: ExpiredDomainsFilter): void {
        if (this.infiniteScroll) {
            this.infiniteScroll.destroyScroller();
            this.infiniteScroll.setup();
        }

        const where: DomainsFilter = {
            validity: RecordValidity.Expired,
            level: { equalTo: 2 },
        };

        if (filter.domainName) {
            where.name = { like: filter.domainName };
        }

        if (filter.domainNameType && filter.domainNameType !== 'all') {
            where.label = {
                composition: { in: [filter.domainNameType === DomainNameType.Letters ? LabelCompositionType.Letters : LabelCompositionType.Numbers] },
            };
        }

        if (filter.expirationDate) {
            where.expiresAtUtc = { greaterThanOrEqualTo: filter.expirationDate.from, lessThanOrEqualTo: filter.expirationDate.to };
        }

        if (!filter.allCategoriesSelected) {
            const filters = this.buildFilterFromCategories(filter.categories);
            if (filters.length > 1) {
                where.or = filters;
            } else {
                where.name = {
                    ...where.name,
                    ...filters[0].name,
                };
            }
        }

        const order: DomainOrder = {
            field: <DomainOrderField>filter.sorting.value,
            direction: filter.sorting.direction === 'asc' ? OrderDirection.Asc : OrderDirection.Desc,
        };

        const variables: DomainsListQueryVariables = {
            where,
            order,
        };

        if (this.maxCount) {
            variables.first = this.maxCount;
        }

        this.setLoading(true);
        this.someDomainsLoaded = false;
        this.dataSource.load(variables, this.pollInterval ? { pollInterval: this.pollInterval } : undefined);
    }

    private setLoading(loading: boolean) {
        this.loading = loading;
        this.dataLoading.next(this.loading);
    }

    private buildFilterFromCategories(categories: DomainCategory[]): DomainsFilter[] {
        // handle 3, 5+
        if (
            categories.length === 2 &&
            categories.some(c => c === DomainCategory.ThreeLetters) &&
            categories.some(c => c === DomainCategory.MoreThanFiveLetters)
        )
            return categories.map(c => {
                switch (c) {
                    case DomainCategory.MoreThanFiveLetters:
                        return { name: { length: { greaterThanOrEqualTo: 9 } }, validity: RecordValidity.All };
                    case DomainCategory.FourLetters:
                        return { name: { length: { equalTo: 8 } }, validity: RecordValidity.All };
                    case DomainCategory.ThreeLetters:
                        return { name: { length: { equalTo: 7 } }, validity: RecordValidity.All };
                }
            });

        const letterCount = categories.map(c => domainCategoryLetters[c]).sort((a, b) => a.min - b.min);
        const min = letterCount[0].min;
        const max = letterCount.at(-1)?.max ?? Number.POSITIVE_INFINITY;

        const filter: DomainsFilter = {
            name: { length: { greaterThanOrEqualTo: min } },
        };

        if (Number.isFinite(max)) {
            filter.name!.length!.lessThanOrEqualTo = max;
        }

        return [filter];
    }
}
