import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SmartContractOperationEvent, TezosService } from '../tezos/tezos.service';
import { TezosWallet } from '../tezos/models';
import { OperationStatusDoneEvent } from '../shared/operation-status.component';

@Component({
    selector: 'td-withdraw',
    templateUrl: './withdraw.component.html',
    styleUrls: ['./withdraw.component.scss'],
})
export class WithdrawComponent implements OnInit, OnDestroy {
    operation: Observable<SmartContractOperationEvent> | null;
    wallet: TezosWallet;
    balance: number;
    tlds: string[];
    control: FormControl<number | null>;

    private unsubscribe = new Subject<void>();

    constructor(private tezosService: TezosService, private dialogRef: MatDialogRef<WithdrawComponent>) {}

    ngOnInit(): void {
        this.tezosService.activeWallet.pipe(takeUntil(this.unsubscribe)).subscribe(w => {
            this.wallet = w!;
            this.wallet.bidderBalances.pipe(takeUntil(this.unsubscribe)).subscribe(bidderBalances => {
                this.balance = bidderBalances.reduce((s, b) => s + b.balance, 0);
                this.tlds = bidderBalances.filter(b => b.balance > 0).map(b => b.tld);
                this.control = new FormControl(this.balance / 1e6);
            });
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    withdraw() {
        this.operation = this.tezosService.execute(client =>
            client.manager.batch(async b => await Promise.all(this.tlds.map(async tld => await b.withdraw(tld, this.wallet.address))))
        );
    }

    cancel() {
        this.dialogRef.close(false);
    }

    operationDone(event: OperationStatusDoneEvent) {
        if (event.success) {
            this.dialogRef.close(true);
        }
    }
}
