import { Pipe, PipeTransform } from '@angular/core';
import BigNumber from 'bignumber.js';

@Pipe({
    name: 'fromMutez',
})
export class FromMutezPipe implements PipeTransform {
    transform(value: number | BigNumber): number {
        return new BigNumber(value).dividedBy(1e6).toNumber();
    }
}
