<td-card-skeleton>
    <div class="d-flex flex-column">
        <div class="flex-grow-1">
            <ngx-skeleton-loader *ngFor="let i of [].constructor(rows)" [theme]="{ height: media.isActive('xs') ? '40px' : '20px' }"></ngx-skeleton-loader>
        </div>
        <div class="d-flex flex-column flex-sm-row gap-0 gap-sm-3">
            <ngx-skeleton-loader
                *ngFor="let i of [].constructor(buttons)"
                class="mb-0"
                [theme]="{ height: '36px', width: media.isActive('xs') ? '100%' : '128px' }"
            ></ngx-skeleton-loader>
        </div>
    </div>
</td-card-skeleton>
