import { NgModule } from '@angular/core';
import { FiltersModule } from '../filters/filters.module';
import { SharedModule } from '../shared/shared.module';
import { DelegateFinderComponent } from './delegate-finder.component';
import { DelegateItemComponent } from './delegate-item.component';
import { DelegatesBoardFilterComponent } from './delegates-board-filter.component';
import { DelegatesBoardComponent } from './delegates-board.component';
import { DelegatorListComponent } from './delegator-page.component';
import { DelegationSelectorComponent } from './delegation-selector.component';
import { DelegatorTableComponent } from './delegator-table.component';

@NgModule({
    declarations: [
        DelegateFinderComponent,
        DelegateItemComponent,
        DelegationSelectorComponent,
        DelegatorListComponent,
        DelegatesBoardComponent,
        DelegatesBoardFilterComponent,
        DelegatorTableComponent,
    ],
    imports: [SharedModule, FiltersModule],
    exports: [DelegateFinderComponent, DelegateItemComponent, DelegationSelectorComponent],
})
export class DelegationModule {}
