import { Injectable } from '@angular/core';
import { TranslocoLoader } from '@ngneat/transloco';
import { from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ImportLoader implements TranslocoLoader {
    getTranslation(lang: string) {
        return from(import(`../../i18n/${lang}.json`));
    }
}
