import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, first, map, skip } from 'rxjs/operators';
import { DataSourceFactory } from '../graphql/data-source-factory';
import { UserDataDataSource } from '../graphql/user-data-data-source';
import { TezosWallet } from '../tezos/models';
import { TezosService } from '../tezos/tezos.service';
import { ErrorPresenterService } from '../utils/error-presenter.service';

export interface UserData {
    secondLevelDomainCount: number;
    totalAuctionCount: number;
    totalBuyOfferCount: number;
    address: string;
}

@Injectable({
    providedIn: 'root',
})
export class UserDataService {
    private userDataStream: ReplaySubject<UserData | null> = new ReplaySubject(1);
    private dataSource: UserDataDataSource;
    private wallet: TezosWallet | null = null;

    get current(): Observable<UserData | null> {
        return this.userDataStream;
    }

    constructor(private tezosService: TezosService, dataSourceFactory: DataSourceFactory, private errorPresenterService: ErrorPresenterService) {
        this.dataSource = dataSourceFactory.createUserDataDataSource();

        this.dataSource.data$
            .pipe(
                filter(data => !!data),
                map(data => {
                    return {
                        secondLevelDomainCount: data.domains.totalCount,
                        totalAuctionCount: data.auctions.totalCount,
                        totalBuyOfferCount: data.buyOffers.totalCount,
                        address: this.wallet?.address ?? '',
                    } as UserData;
                })
            )
            .subscribe(userData => this.userDataStream.next(userData));

        this.dataSource.error$.pipe(filter(e => !!e)).subscribe(e => this.errorPresenterService.apiErrorToast('user-data-fetch', e));

        this.tezosService.activeWallet.pipe(skip(1)).subscribe(() => {
            this.load();
        });
    }

    private load() {
        this.tezosService.activeWallet.pipe(first()).subscribe(wallet => {
            this.wallet = wallet;

            if (!wallet) {
                this.userDataStream.next(null);
            } else {
                this.dataSource.load({ address: wallet.address });
            }
        });
    }

    refresh() {
        this.load();
    }
}
