<ng-container *transloco="let t">
    <div *ngIf="(dataSource.empty$ | async) && !hideResults" class="empty-message p-3 w-100 text-center smooth-shadow">
        {{ t('event-table.no-events') }}
    </div>

    <ng-container *ngIf="(dataSource.initialLoading$ | async) === false">
        <td-error *ngIf="dataSource.error$ | async; let error" [message]="error.message" [title]="t('general.error')"></td-error>

        <div [hidden]="dataSource.initialLoading$ | async" infiniteScroll (scrolled)="scrolled()">
            <ng-container *ngIf="dataSource.data$ | async; let events">
                <div class="card smooth-shadow" *ngIf="events.length">
                    <div class="d-flex flex-column flex-grow-1 full-card-width">
                        <ng-container *ngFor="let event of events; let last = last">
                            <td-activity-item [event]="$any(event)"></td-activity-item>
                            <mat-divider *ngIf="!last"></mat-divider>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <div *ngIf="dataSource.loading$ | async" class="w-100 mt-3 d-flex justify-content-center">
        <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>
</ng-container>
