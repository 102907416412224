import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSort, SortDirection } from '@angular/material/sort';
import mergeObject from 'lodash-es/merge';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { BehaviorSubject, merge, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { HistoryStateService } from '../browser/history-state.service';
import { AuctionTableDataSource } from '../graphql/auction-table-data-source';
import { DataSourceFactory } from '../graphql/data-source-factory';
import { AuctionListQueryVariables, AuctionOrder, AuctionOrderField, AuctionsFilter, AuctionState } from '../graphql/graphql.generated';
import { TezosWallet } from '../tezos/models';
import { TezosService } from '../tezos/tezos.service';

type FilterCategory = {
    name: AuctionHistoryTableFilterCategory;
    predicateBuilder: () => AuctionsFilter;
};

export type AuctionHistoryTableFilterCategory = 'all' | 'won' | 'lost';

export type AuctionTableState = {
    auctionHistoryTable?: {
        sort: {
            field: string;
            direction: SortDirection;
        };
        form: any;
    };
};

type ActionStateCategory = 'all' | 'won' | 'lost';
@Component({
    selector: 'td-auction-history-table',
    templateUrl: './auction-history-table.component.html',
    styleUrls: ['./auction-history-table.component.scss'],
})
export class AuctionHistoryTableComponent implements OnInit, OnDestroy, OnChanges {
    @Input() address: string;

    dataSource: AuctionTableDataSource;
    form: FormGroup<{
        filter: FormControl<string>;
        category: FormControl<ActionStateCategory>;
    }>;
    categories: FilterCategory[];

    sortField: string;
    sortDirection: SortDirection;
    hideResults = new BehaviorSubject<boolean>(true);
    states = AuctionState;
    columns: string[] = [];
    wallet: TezosWallet | null;

    private unsubscribe = new Subject<void>();

    @ViewChild(MatSort) sorter: MatSort;
    @ViewChild(InfiniteScrollDirective) infiniteScroll: InfiniteScrollDirective;

    constructor(
        private dataSourceFactory: DataSourceFactory,
        private formBuilder: FormBuilder,
        private historyStateService: HistoryStateService,
        private tezosService: TezosService
    ) {}

    ngOnInit(): void {
        this.dataSource = this.dataSourceFactory.createAuctionTableDataSource();

        this.categories = [
            {
                name: 'all',
                predicateBuilder: () => ({
                    and: [
                        {
                            bidders: { include: this.address },
                        },
                        {
                            or: [
                                {
                                    highestBidder: { notEqualTo: this.address },
                                    state: { in: [AuctionState.CanBeSettled] },
                                },
                                {
                                    state: { in: [AuctionState.Settled, AuctionState.SettlementExpired] },
                                },
                            ],
                        },
                    ],
                }),
            },
            {
                name: 'won',
                predicateBuilder: () => ({
                    highestBidder: { equalTo: this.address },
                    state: { in: [AuctionState.Settled, AuctionState.SettlementExpired] },
                }),
            },
            {
                name: 'lost',
                predicateBuilder: () => ({
                    highestBidder: { notEqualTo: this.address },
                    bidders: { include: this.address },
                    state: { in: [AuctionState.Settled, AuctionState.SettlementExpired, AuctionState.CanBeSettled] },
                }),
            },
        ];

        this.form = this.formBuilder.group({
            filter: this.formBuilder.control('', { nonNullable: true }),
            category: this.formBuilder.control<ActionStateCategory>('all', { nonNullable: true }),
        });

        merge(this.form.get('filter')!.valueChanges.pipe(debounceTime(300)), this.form.get('category')!.valueChanges.pipe(debounceTime(0)))
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.hideResults.next(true);
                this.reload();
            });

        this.dataSource.initialLoading$.pipe(takeUntil(this.unsubscribe)).subscribe(l => {
            if (!l) {
                this.hideResults.next(false);
            }
        });

        this.restoreState();

        this.tezosService.activeWallet.pipe(takeUntil(this.unsubscribe)).subscribe(w => {
            this.wallet = w;
            if (w?.address === this.address) {
                this.columns = ['name', 'ended', 'status', 'highestBid', 'actions'];
            } else {
                this.columns = ['name', 'ended', 'highestBid'];
            }
        });
    }

    ngOnChanges() {
        // check because changes fire before init
        if (this.categories && this.form) {
            this.restoreState();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    scrolled() {
        this.dataSource.loadMore();
    }

    reload() {
        if (this.infiniteScroll) {
            this.infiniteScroll.destroyScroller();
            this.infiniteScroll.setup();
        }

        const where: AuctionsFilter = mergeObject(
            {
                domainName: { like: this.form.value.filter },
            },
            this.getCategoryFilter()
        );

        const variables: AuctionListQueryVariables = {
            where,
            order: this.getSort(),
        };

        this.historyStateService.merge<AuctionTableState>({
            auctionHistoryTable: { form: this.form.value, sort: { field: this.sorter.active, direction: this.sorter.direction } },
        });

        this.dataSource.load(variables);
    }

    private restoreState() {
        this.hideResults.next(true);
        const state = this.historyStateService.get<AuctionTableState>();

        if (state.auctionHistoryTable) {
            this.form.setValue(state.auctionHistoryTable.form, { emitEvent: false });
            this.sortField = state.auctionHistoryTable.sort.field;
            this.sortDirection = state.auctionHistoryTable.sort.direction;
        } else {
            this.sortField = AuctionOrderField.EndsAt;
            this.sortDirection = 'desc';
        }

        setTimeout(() => this.reload());
    }

    private getCategoryFilter() {
        const categoryName = this.form.value.category;
        const category = this.categories.find(c => c.name === categoryName)!;
        return category.predicateBuilder();
    }

    private getSort(): AuctionOrder {
        return {
            field: this.sorter.active as any,
            direction: (this.sorter.direction.toUpperCase() as any) || null,
        };
    }
}
