import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  jsonb: any;
  timestamptz: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "dipdup_contract" */
export type Dipdup_Contract = {
  __typename?: 'dipdup_contract';
  address: Scalars['String'];
  created_at: Scalars['timestamptz'];
  name: Scalars['String'];
  typename?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "dipdup_contract" */
export type Dipdup_Contract_Aggregate = {
  __typename?: 'dipdup_contract_aggregate';
  aggregate?: Maybe<Dipdup_Contract_Aggregate_Fields>;
  nodes: Array<Dipdup_Contract>;
};

/** aggregate fields of "dipdup_contract" */
export type Dipdup_Contract_Aggregate_Fields = {
  __typename?: 'dipdup_contract_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Dipdup_Contract_Max_Fields>;
  min?: Maybe<Dipdup_Contract_Min_Fields>;
};


/** aggregate fields of "dipdup_contract" */
export type Dipdup_Contract_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dipdup_Contract_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "dipdup_contract". All fields are combined with a logical 'AND'. */
export type Dipdup_Contract_Bool_Exp = {
  _and?: InputMaybe<Array<Dipdup_Contract_Bool_Exp>>;
  _not?: InputMaybe<Dipdup_Contract_Bool_Exp>;
  _or?: InputMaybe<Array<Dipdup_Contract_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  typename?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Dipdup_Contract_Max_Fields = {
  __typename?: 'dipdup_contract_max_fields';
  address?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  typename?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "dipdup_contract_metadata" */
export type Dipdup_Contract_Metadata = {
  __typename?: 'dipdup_contract_metadata';
  contract: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  metadata: Scalars['jsonb'];
  network: Scalars['String'];
  update_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "dipdup_contract_metadata" */
export type Dipdup_Contract_MetadataMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "dipdup_contract_metadata" */
export type Dipdup_Contract_Metadata_Aggregate = {
  __typename?: 'dipdup_contract_metadata_aggregate';
  aggregate?: Maybe<Dipdup_Contract_Metadata_Aggregate_Fields>;
  nodes: Array<Dipdup_Contract_Metadata>;
};

/** aggregate fields of "dipdup_contract_metadata" */
export type Dipdup_Contract_Metadata_Aggregate_Fields = {
  __typename?: 'dipdup_contract_metadata_aggregate_fields';
  avg?: Maybe<Dipdup_Contract_Metadata_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dipdup_Contract_Metadata_Max_Fields>;
  min?: Maybe<Dipdup_Contract_Metadata_Min_Fields>;
  stddev?: Maybe<Dipdup_Contract_Metadata_Stddev_Fields>;
  stddev_pop?: Maybe<Dipdup_Contract_Metadata_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dipdup_Contract_Metadata_Stddev_Samp_Fields>;
  sum?: Maybe<Dipdup_Contract_Metadata_Sum_Fields>;
  var_pop?: Maybe<Dipdup_Contract_Metadata_Var_Pop_Fields>;
  var_samp?: Maybe<Dipdup_Contract_Metadata_Var_Samp_Fields>;
  variance?: Maybe<Dipdup_Contract_Metadata_Variance_Fields>;
};


/** aggregate fields of "dipdup_contract_metadata" */
export type Dipdup_Contract_Metadata_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dipdup_Contract_Metadata_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dipdup_Contract_Metadata_Avg_Fields = {
  __typename?: 'dipdup_contract_metadata_avg_fields';
  id?: Maybe<Scalars['Float']>;
  update_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dipdup_contract_metadata". All fields are combined with a logical 'AND'. */
export type Dipdup_Contract_Metadata_Bool_Exp = {
  _and?: InputMaybe<Array<Dipdup_Contract_Metadata_Bool_Exp>>;
  _not?: InputMaybe<Dipdup_Contract_Metadata_Bool_Exp>;
  _or?: InputMaybe<Array<Dipdup_Contract_Metadata_Bool_Exp>>;
  contract?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  network?: InputMaybe<String_Comparison_Exp>;
  update_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Dipdup_Contract_Metadata_Max_Fields = {
  __typename?: 'dipdup_contract_metadata_max_fields';
  contract?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  update_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Dipdup_Contract_Metadata_Min_Fields = {
  __typename?: 'dipdup_contract_metadata_min_fields';
  contract?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  update_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "dipdup_contract_metadata". */
export type Dipdup_Contract_Metadata_Order_By = {
  contract?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  network?: InputMaybe<Order_By>;
  update_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "dipdup_contract_metadata" */
export enum Dipdup_Contract_Metadata_Select_Column {
  /** column name */
  Contract = 'contract',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Network = 'network',
  /** column name */
  UpdateId = 'update_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export type Dipdup_Contract_Metadata_Stddev_Fields = {
  __typename?: 'dipdup_contract_metadata_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  update_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dipdup_Contract_Metadata_Stddev_Pop_Fields = {
  __typename?: 'dipdup_contract_metadata_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  update_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dipdup_Contract_Metadata_Stddev_Samp_Fields = {
  __typename?: 'dipdup_contract_metadata_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  update_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dipdup_contract_metadata" */
export type Dipdup_Contract_Metadata_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dipdup_Contract_Metadata_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dipdup_Contract_Metadata_Stream_Cursor_Value_Input = {
  contract?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  network?: InputMaybe<Scalars['String']>;
  update_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Dipdup_Contract_Metadata_Sum_Fields = {
  __typename?: 'dipdup_contract_metadata_sum_fields';
  id?: Maybe<Scalars['Int']>;
  update_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Dipdup_Contract_Metadata_Var_Pop_Fields = {
  __typename?: 'dipdup_contract_metadata_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  update_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dipdup_Contract_Metadata_Var_Samp_Fields = {
  __typename?: 'dipdup_contract_metadata_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  update_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dipdup_Contract_Metadata_Variance_Fields = {
  __typename?: 'dipdup_contract_metadata_variance_fields';
  id?: Maybe<Scalars['Float']>;
  update_id?: Maybe<Scalars['Float']>;
};

/** aggregate min on columns */
export type Dipdup_Contract_Min_Fields = {
  __typename?: 'dipdup_contract_min_fields';
  address?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  typename?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "dipdup_contract". */
export type Dipdup_Contract_Order_By = {
  address?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  typename?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "dipdup_contract" */
export enum Dipdup_Contract_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Name = 'name',
  /** column name */
  Typename = 'typename',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Streaming cursor of the table "dipdup_contract" */
export type Dipdup_Contract_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dipdup_Contract_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dipdup_Contract_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  typename?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "dipdup_head" */
export type Dipdup_Head = {
  __typename?: 'dipdup_head';
  created_at: Scalars['timestamptz'];
  hash: Scalars['String'];
  level: Scalars['Int'];
  name: Scalars['String'];
  timestamp: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "dipdup_head" */
export type Dipdup_Head_Aggregate = {
  __typename?: 'dipdup_head_aggregate';
  aggregate?: Maybe<Dipdup_Head_Aggregate_Fields>;
  nodes: Array<Dipdup_Head>;
};

/** aggregate fields of "dipdup_head" */
export type Dipdup_Head_Aggregate_Fields = {
  __typename?: 'dipdup_head_aggregate_fields';
  avg?: Maybe<Dipdup_Head_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dipdup_Head_Max_Fields>;
  min?: Maybe<Dipdup_Head_Min_Fields>;
  stddev?: Maybe<Dipdup_Head_Stddev_Fields>;
  stddev_pop?: Maybe<Dipdup_Head_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dipdup_Head_Stddev_Samp_Fields>;
  sum?: Maybe<Dipdup_Head_Sum_Fields>;
  var_pop?: Maybe<Dipdup_Head_Var_Pop_Fields>;
  var_samp?: Maybe<Dipdup_Head_Var_Samp_Fields>;
  variance?: Maybe<Dipdup_Head_Variance_Fields>;
};


/** aggregate fields of "dipdup_head" */
export type Dipdup_Head_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dipdup_Head_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dipdup_Head_Avg_Fields = {
  __typename?: 'dipdup_head_avg_fields';
  level?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dipdup_head". All fields are combined with a logical 'AND'. */
export type Dipdup_Head_Bool_Exp = {
  _and?: InputMaybe<Array<Dipdup_Head_Bool_Exp>>;
  _not?: InputMaybe<Dipdup_Head_Bool_Exp>;
  _or?: InputMaybe<Array<Dipdup_Head_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  hash?: InputMaybe<String_Comparison_Exp>;
  level?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Dipdup_Head_Max_Fields = {
  __typename?: 'dipdup_head_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  hash?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Dipdup_Head_Min_Fields = {
  __typename?: 'dipdup_head_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  hash?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "dipdup_head". */
export type Dipdup_Head_Order_By = {
  created_at?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "dipdup_head" */
export enum Dipdup_Head_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Hash = 'hash',
  /** column name */
  Level = 'level',
  /** column name */
  Name = 'name',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "dipdup_head_status" */
export type Dipdup_Head_Status = {
  __typename?: 'dipdup_head_status';
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregated selection of "dipdup_head_status" */
export type Dipdup_Head_Status_Aggregate = {
  __typename?: 'dipdup_head_status_aggregate';
  aggregate?: Maybe<Dipdup_Head_Status_Aggregate_Fields>;
  nodes: Array<Dipdup_Head_Status>;
};

/** aggregate fields of "dipdup_head_status" */
export type Dipdup_Head_Status_Aggregate_Fields = {
  __typename?: 'dipdup_head_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Dipdup_Head_Status_Max_Fields>;
  min?: Maybe<Dipdup_Head_Status_Min_Fields>;
};


/** aggregate fields of "dipdup_head_status" */
export type Dipdup_Head_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dipdup_Head_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "dipdup_head_status". All fields are combined with a logical 'AND'. */
export type Dipdup_Head_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Dipdup_Head_Status_Bool_Exp>>;
  _not?: InputMaybe<Dipdup_Head_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Dipdup_Head_Status_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Dipdup_Head_Status_Max_Fields = {
  __typename?: 'dipdup_head_status_max_fields';
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Dipdup_Head_Status_Min_Fields = {
  __typename?: 'dipdup_head_status_min_fields';
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "dipdup_head_status". */
export type Dipdup_Head_Status_Order_By = {
  name?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** select columns of table "dipdup_head_status" */
export enum Dipdup_Head_Status_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status'
}

/** Streaming cursor of the table "dipdup_head_status" */
export type Dipdup_Head_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dipdup_Head_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dipdup_Head_Status_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Dipdup_Head_Stddev_Fields = {
  __typename?: 'dipdup_head_stddev_fields';
  level?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dipdup_Head_Stddev_Pop_Fields = {
  __typename?: 'dipdup_head_stddev_pop_fields';
  level?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dipdup_Head_Stddev_Samp_Fields = {
  __typename?: 'dipdup_head_stddev_samp_fields';
  level?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dipdup_head" */
export type Dipdup_Head_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dipdup_Head_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dipdup_Head_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  hash?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Dipdup_Head_Sum_Fields = {
  __typename?: 'dipdup_head_sum_fields';
  level?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Dipdup_Head_Var_Pop_Fields = {
  __typename?: 'dipdup_head_var_pop_fields';
  level?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dipdup_Head_Var_Samp_Fields = {
  __typename?: 'dipdup_head_var_samp_fields';
  level?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dipdup_Head_Variance_Fields = {
  __typename?: 'dipdup_head_variance_fields';
  level?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "dipdup_index" */
export type Dipdup_Index = {
  __typename?: 'dipdup_index';
  config_hash: Scalars['String'];
  created_at: Scalars['timestamptz'];
  level: Scalars['Int'];
  name: Scalars['String'];
  /** NEW: NEW\nSYNCING: SYNCING\nREALTIME: REALTIME\nROLLBACK: ROLLBACK\nONESHOT: ONESHOT */
  status: Scalars['String'];
  template?: Maybe<Scalars['String']>;
  template_values?: Maybe<Scalars['jsonb']>;
  /** operation: operation\nbig_map: big_map\nhead: head\ntoken_transfer: token_transfer */
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "dipdup_index" */
export type Dipdup_IndexTemplate_ValuesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "dipdup_index" */
export type Dipdup_Index_Aggregate = {
  __typename?: 'dipdup_index_aggregate';
  aggregate?: Maybe<Dipdup_Index_Aggregate_Fields>;
  nodes: Array<Dipdup_Index>;
};

/** aggregate fields of "dipdup_index" */
export type Dipdup_Index_Aggregate_Fields = {
  __typename?: 'dipdup_index_aggregate_fields';
  avg?: Maybe<Dipdup_Index_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dipdup_Index_Max_Fields>;
  min?: Maybe<Dipdup_Index_Min_Fields>;
  stddev?: Maybe<Dipdup_Index_Stddev_Fields>;
  stddev_pop?: Maybe<Dipdup_Index_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dipdup_Index_Stddev_Samp_Fields>;
  sum?: Maybe<Dipdup_Index_Sum_Fields>;
  var_pop?: Maybe<Dipdup_Index_Var_Pop_Fields>;
  var_samp?: Maybe<Dipdup_Index_Var_Samp_Fields>;
  variance?: Maybe<Dipdup_Index_Variance_Fields>;
};


/** aggregate fields of "dipdup_index" */
export type Dipdup_Index_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dipdup_Index_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dipdup_Index_Avg_Fields = {
  __typename?: 'dipdup_index_avg_fields';
  level?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dipdup_index". All fields are combined with a logical 'AND'. */
export type Dipdup_Index_Bool_Exp = {
  _and?: InputMaybe<Array<Dipdup_Index_Bool_Exp>>;
  _not?: InputMaybe<Dipdup_Index_Bool_Exp>;
  _or?: InputMaybe<Array<Dipdup_Index_Bool_Exp>>;
  config_hash?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  level?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  template?: InputMaybe<String_Comparison_Exp>;
  template_values?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Dipdup_Index_Max_Fields = {
  __typename?: 'dipdup_index_max_fields';
  config_hash?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  level?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  /** NEW: NEW\nSYNCING: SYNCING\nREALTIME: REALTIME\nROLLBACK: ROLLBACK\nONESHOT: ONESHOT */
  status?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  /** operation: operation\nbig_map: big_map\nhead: head\ntoken_transfer: token_transfer */
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Dipdup_Index_Min_Fields = {
  __typename?: 'dipdup_index_min_fields';
  config_hash?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  level?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  /** NEW: NEW\nSYNCING: SYNCING\nREALTIME: REALTIME\nROLLBACK: ROLLBACK\nONESHOT: ONESHOT */
  status?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  /** operation: operation\nbig_map: big_map\nhead: head\ntoken_transfer: token_transfer */
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "dipdup_index". */
export type Dipdup_Index_Order_By = {
  config_hash?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  template?: InputMaybe<Order_By>;
  template_values?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "dipdup_index" */
export enum Dipdup_Index_Select_Column {
  /** column name */
  ConfigHash = 'config_hash',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Level = 'level',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  Template = 'template',
  /** column name */
  TemplateValues = 'template_values',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export type Dipdup_Index_Stddev_Fields = {
  __typename?: 'dipdup_index_stddev_fields';
  level?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dipdup_Index_Stddev_Pop_Fields = {
  __typename?: 'dipdup_index_stddev_pop_fields';
  level?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dipdup_Index_Stddev_Samp_Fields = {
  __typename?: 'dipdup_index_stddev_samp_fields';
  level?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dipdup_index" */
export type Dipdup_Index_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dipdup_Index_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dipdup_Index_Stream_Cursor_Value_Input = {
  config_hash?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  level?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  /** NEW: NEW\nSYNCING: SYNCING\nREALTIME: REALTIME\nROLLBACK: ROLLBACK\nONESHOT: ONESHOT */
  status?: InputMaybe<Scalars['String']>;
  template?: InputMaybe<Scalars['String']>;
  template_values?: InputMaybe<Scalars['jsonb']>;
  /** operation: operation\nbig_map: big_map\nhead: head\ntoken_transfer: token_transfer */
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Dipdup_Index_Sum_Fields = {
  __typename?: 'dipdup_index_sum_fields';
  level?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Dipdup_Index_Var_Pop_Fields = {
  __typename?: 'dipdup_index_var_pop_fields';
  level?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dipdup_Index_Var_Samp_Fields = {
  __typename?: 'dipdup_index_var_samp_fields';
  level?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dipdup_Index_Variance_Fields = {
  __typename?: 'dipdup_index_variance_fields';
  level?: Maybe<Scalars['Float']>;
};

/** Model update created within versioned transactions */
export type Dipdup_Model_Update = {
  __typename?: 'dipdup_model_update';
  /** INSERT: INSERT\nUPDATE: UPDATE\nDELETE: DELETE */
  action: Scalars['String'];
  created_at: Scalars['timestamptz'];
  data?: Maybe<Scalars['jsonb']>;
  id: Scalars['Int'];
  index: Scalars['String'];
  level: Scalars['Int'];
  model_name: Scalars['String'];
  model_pk: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** Model update created within versioned transactions */
export type Dipdup_Model_UpdateDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "dipdup_model_update" */
export type Dipdup_Model_Update_Aggregate = {
  __typename?: 'dipdup_model_update_aggregate';
  aggregate?: Maybe<Dipdup_Model_Update_Aggregate_Fields>;
  nodes: Array<Dipdup_Model_Update>;
};

/** aggregate fields of "dipdup_model_update" */
export type Dipdup_Model_Update_Aggregate_Fields = {
  __typename?: 'dipdup_model_update_aggregate_fields';
  avg?: Maybe<Dipdup_Model_Update_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dipdup_Model_Update_Max_Fields>;
  min?: Maybe<Dipdup_Model_Update_Min_Fields>;
  stddev?: Maybe<Dipdup_Model_Update_Stddev_Fields>;
  stddev_pop?: Maybe<Dipdup_Model_Update_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dipdup_Model_Update_Stddev_Samp_Fields>;
  sum?: Maybe<Dipdup_Model_Update_Sum_Fields>;
  var_pop?: Maybe<Dipdup_Model_Update_Var_Pop_Fields>;
  var_samp?: Maybe<Dipdup_Model_Update_Var_Samp_Fields>;
  variance?: Maybe<Dipdup_Model_Update_Variance_Fields>;
};


/** aggregate fields of "dipdup_model_update" */
export type Dipdup_Model_Update_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dipdup_Model_Update_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dipdup_Model_Update_Avg_Fields = {
  __typename?: 'dipdup_model_update_avg_fields';
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dipdup_model_update". All fields are combined with a logical 'AND'. */
export type Dipdup_Model_Update_Bool_Exp = {
  _and?: InputMaybe<Array<Dipdup_Model_Update_Bool_Exp>>;
  _not?: InputMaybe<Dipdup_Model_Update_Bool_Exp>;
  _or?: InputMaybe<Array<Dipdup_Model_Update_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  index?: InputMaybe<String_Comparison_Exp>;
  level?: InputMaybe<Int_Comparison_Exp>;
  model_name?: InputMaybe<String_Comparison_Exp>;
  model_pk?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Dipdup_Model_Update_Max_Fields = {
  __typename?: 'dipdup_model_update_max_fields';
  /** INSERT: INSERT\nUPDATE: UPDATE\nDELETE: DELETE */
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  model_name?: Maybe<Scalars['String']>;
  model_pk?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Dipdup_Model_Update_Min_Fields = {
  __typename?: 'dipdup_model_update_min_fields';
  /** INSERT: INSERT\nUPDATE: UPDATE\nDELETE: DELETE */
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  model_name?: Maybe<Scalars['String']>;
  model_pk?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "dipdup_model_update". */
export type Dipdup_Model_Update_Order_By = {
  action?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  model_name?: InputMaybe<Order_By>;
  model_pk?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "dipdup_model_update" */
export enum Dipdup_Model_Update_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  Level = 'level',
  /** column name */
  ModelName = 'model_name',
  /** column name */
  ModelPk = 'model_pk',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export type Dipdup_Model_Update_Stddev_Fields = {
  __typename?: 'dipdup_model_update_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dipdup_Model_Update_Stddev_Pop_Fields = {
  __typename?: 'dipdup_model_update_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dipdup_Model_Update_Stddev_Samp_Fields = {
  __typename?: 'dipdup_model_update_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dipdup_model_update" */
export type Dipdup_Model_Update_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dipdup_Model_Update_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dipdup_Model_Update_Stream_Cursor_Value_Input = {
  /** INSERT: INSERT\nUPDATE: UPDATE\nDELETE: DELETE */
  action?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  index?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Scalars['Int']>;
  model_name?: InputMaybe<Scalars['String']>;
  model_pk?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Dipdup_Model_Update_Sum_Fields = {
  __typename?: 'dipdup_model_update_sum_fields';
  id?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Dipdup_Model_Update_Var_Pop_Fields = {
  __typename?: 'dipdup_model_update_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dipdup_Model_Update_Var_Samp_Fields = {
  __typename?: 'dipdup_model_update_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dipdup_Model_Update_Variance_Fields = {
  __typename?: 'dipdup_model_update_variance_fields';
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "dipdup_schema" */
export type Dipdup_Schema = {
  __typename?: 'dipdup_schema';
  created_at: Scalars['timestamptz'];
  hash: Scalars['String'];
  name: Scalars['String'];
  /** manual: manual\nmigration: migration\nrollback: rollback\nconfig_modified: config_modified\nschema_modified: schema_modified */
  reindex?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "dipdup_schema" */
export type Dipdup_Schema_Aggregate = {
  __typename?: 'dipdup_schema_aggregate';
  aggregate?: Maybe<Dipdup_Schema_Aggregate_Fields>;
  nodes: Array<Dipdup_Schema>;
};

/** aggregate fields of "dipdup_schema" */
export type Dipdup_Schema_Aggregate_Fields = {
  __typename?: 'dipdup_schema_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Dipdup_Schema_Max_Fields>;
  min?: Maybe<Dipdup_Schema_Min_Fields>;
};


/** aggregate fields of "dipdup_schema" */
export type Dipdup_Schema_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dipdup_Schema_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "dipdup_schema". All fields are combined with a logical 'AND'. */
export type Dipdup_Schema_Bool_Exp = {
  _and?: InputMaybe<Array<Dipdup_Schema_Bool_Exp>>;
  _not?: InputMaybe<Dipdup_Schema_Bool_Exp>;
  _or?: InputMaybe<Array<Dipdup_Schema_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  hash?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  reindex?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Dipdup_Schema_Max_Fields = {
  __typename?: 'dipdup_schema_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  hash?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** manual: manual\nmigration: migration\nrollback: rollback\nconfig_modified: config_modified\nschema_modified: schema_modified */
  reindex?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Dipdup_Schema_Min_Fields = {
  __typename?: 'dipdup_schema_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  hash?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** manual: manual\nmigration: migration\nrollback: rollback\nconfig_modified: config_modified\nschema_modified: schema_modified */
  reindex?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "dipdup_schema". */
export type Dipdup_Schema_Order_By = {
  created_at?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  reindex?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "dipdup_schema" */
export enum Dipdup_Schema_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Hash = 'hash',
  /** column name */
  Name = 'name',
  /** column name */
  Reindex = 'reindex',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Streaming cursor of the table "dipdup_schema" */
export type Dipdup_Schema_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dipdup_Schema_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dipdup_Schema_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  hash?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  /** manual: manual\nmigration: migration\nrollback: rollback\nconfig_modified: config_modified\nschema_modified: schema_modified */
  reindex?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "dipdup_token_metadata" */
export type Dipdup_Token_Metadata = {
  __typename?: 'dipdup_token_metadata';
  contract: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  metadata: Scalars['jsonb'];
  network: Scalars['String'];
  token_id: Scalars['String'];
  update_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "dipdup_token_metadata" */
export type Dipdup_Token_MetadataMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "dipdup_token_metadata" */
export type Dipdup_Token_Metadata_Aggregate = {
  __typename?: 'dipdup_token_metadata_aggregate';
  aggregate?: Maybe<Dipdup_Token_Metadata_Aggregate_Fields>;
  nodes: Array<Dipdup_Token_Metadata>;
};

/** aggregate fields of "dipdup_token_metadata" */
export type Dipdup_Token_Metadata_Aggregate_Fields = {
  __typename?: 'dipdup_token_metadata_aggregate_fields';
  avg?: Maybe<Dipdup_Token_Metadata_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dipdup_Token_Metadata_Max_Fields>;
  min?: Maybe<Dipdup_Token_Metadata_Min_Fields>;
  stddev?: Maybe<Dipdup_Token_Metadata_Stddev_Fields>;
  stddev_pop?: Maybe<Dipdup_Token_Metadata_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dipdup_Token_Metadata_Stddev_Samp_Fields>;
  sum?: Maybe<Dipdup_Token_Metadata_Sum_Fields>;
  var_pop?: Maybe<Dipdup_Token_Metadata_Var_Pop_Fields>;
  var_samp?: Maybe<Dipdup_Token_Metadata_Var_Samp_Fields>;
  variance?: Maybe<Dipdup_Token_Metadata_Variance_Fields>;
};


/** aggregate fields of "dipdup_token_metadata" */
export type Dipdup_Token_Metadata_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dipdup_Token_Metadata_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dipdup_Token_Metadata_Avg_Fields = {
  __typename?: 'dipdup_token_metadata_avg_fields';
  id?: Maybe<Scalars['Float']>;
  update_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dipdup_token_metadata". All fields are combined with a logical 'AND'. */
export type Dipdup_Token_Metadata_Bool_Exp = {
  _and?: InputMaybe<Array<Dipdup_Token_Metadata_Bool_Exp>>;
  _not?: InputMaybe<Dipdup_Token_Metadata_Bool_Exp>;
  _or?: InputMaybe<Array<Dipdup_Token_Metadata_Bool_Exp>>;
  contract?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  network?: InputMaybe<String_Comparison_Exp>;
  token_id?: InputMaybe<String_Comparison_Exp>;
  update_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Dipdup_Token_Metadata_Max_Fields = {
  __typename?: 'dipdup_token_metadata_max_fields';
  contract?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['String']>;
  update_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Dipdup_Token_Metadata_Min_Fields = {
  __typename?: 'dipdup_token_metadata_min_fields';
  contract?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['String']>;
  update_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "dipdup_token_metadata". */
export type Dipdup_Token_Metadata_Order_By = {
  contract?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  network?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  update_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "dipdup_token_metadata" */
export enum Dipdup_Token_Metadata_Select_Column {
  /** column name */
  Contract = 'contract',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Network = 'network',
  /** column name */
  TokenId = 'token_id',
  /** column name */
  UpdateId = 'update_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export type Dipdup_Token_Metadata_Stddev_Fields = {
  __typename?: 'dipdup_token_metadata_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  update_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dipdup_Token_Metadata_Stddev_Pop_Fields = {
  __typename?: 'dipdup_token_metadata_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  update_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dipdup_Token_Metadata_Stddev_Samp_Fields = {
  __typename?: 'dipdup_token_metadata_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  update_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dipdup_token_metadata" */
export type Dipdup_Token_Metadata_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dipdup_Token_Metadata_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dipdup_Token_Metadata_Stream_Cursor_Value_Input = {
  contract?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  network?: InputMaybe<Scalars['String']>;
  token_id?: InputMaybe<Scalars['String']>;
  update_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Dipdup_Token_Metadata_Sum_Fields = {
  __typename?: 'dipdup_token_metadata_sum_fields';
  id?: Maybe<Scalars['Int']>;
  update_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Dipdup_Token_Metadata_Var_Pop_Fields = {
  __typename?: 'dipdup_token_metadata_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  update_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dipdup_Token_Metadata_Var_Samp_Fields = {
  __typename?: 'dipdup_token_metadata_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  update_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dipdup_Token_Metadata_Variance_Fields = {
  __typename?: 'dipdup_token_metadata_variance_fields';
  id?: Maybe<Scalars['Float']>;
  update_id?: Maybe<Scalars['Float']>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "dipdup_contract" */
  dipdup_contract: Array<Dipdup_Contract>;
  /** fetch aggregated fields from the table: "dipdup_contract" */
  dipdup_contract_aggregate: Dipdup_Contract_Aggregate;
  /** fetch data from the table: "dipdup_contract" using primary key columns */
  dipdup_contract_by_pk?: Maybe<Dipdup_Contract>;
  /** fetch data from the table: "dipdup_contract_metadata" */
  dipdup_contract_metadata: Array<Dipdup_Contract_Metadata>;
  /** fetch aggregated fields from the table: "dipdup_contract_metadata" */
  dipdup_contract_metadata_aggregate: Dipdup_Contract_Metadata_Aggregate;
  /** fetch data from the table: "dipdup_contract_metadata" using primary key columns */
  dipdup_contract_metadata_by_pk?: Maybe<Dipdup_Contract_Metadata>;
  /** fetch data from the table: "dipdup_head" */
  dipdup_head: Array<Dipdup_Head>;
  /** fetch aggregated fields from the table: "dipdup_head" */
  dipdup_head_aggregate: Dipdup_Head_Aggregate;
  /** fetch data from the table: "dipdup_head" using primary key columns */
  dipdup_head_by_pk?: Maybe<Dipdup_Head>;
  /** fetch data from the table: "dipdup_head_status" */
  dipdup_head_status: Array<Dipdup_Head_Status>;
  /** fetch aggregated fields from the table: "dipdup_head_status" */
  dipdup_head_status_aggregate: Dipdup_Head_Status_Aggregate;
  /** fetch data from the table: "dipdup_index" */
  dipdup_index: Array<Dipdup_Index>;
  /** fetch aggregated fields from the table: "dipdup_index" */
  dipdup_index_aggregate: Dipdup_Index_Aggregate;
  /** fetch data from the table: "dipdup_index" using primary key columns */
  dipdup_index_by_pk?: Maybe<Dipdup_Index>;
  /** fetch data from the table: "dipdup_model_update" */
  dipdup_model_update: Array<Dipdup_Model_Update>;
  /** fetch aggregated fields from the table: "dipdup_model_update" */
  dipdup_model_update_aggregate: Dipdup_Model_Update_Aggregate;
  /** fetch data from the table: "dipdup_model_update" using primary key columns */
  dipdup_model_update_by_pk?: Maybe<Dipdup_Model_Update>;
  /** fetch data from the table: "dipdup_schema" */
  dipdup_schema: Array<Dipdup_Schema>;
  /** fetch aggregated fields from the table: "dipdup_schema" */
  dipdup_schema_aggregate: Dipdup_Schema_Aggregate;
  /** fetch data from the table: "dipdup_schema" using primary key columns */
  dipdup_schema_by_pk?: Maybe<Dipdup_Schema>;
  /** fetch data from the table: "dipdup_token_metadata" */
  dipdup_token_metadata: Array<Dipdup_Token_Metadata>;
  /** fetch aggregated fields from the table: "dipdup_token_metadata" */
  dipdup_token_metadata_aggregate: Dipdup_Token_Metadata_Aggregate;
  /** fetch data from the table: "dipdup_token_metadata" using primary key columns */
  dipdup_token_metadata_by_pk?: Maybe<Dipdup_Token_Metadata>;
  /** fetch data from the table: "tzprofiles" */
  tzprofiles: Array<Tzprofiles>;
  /** fetch aggregated fields from the table: "tzprofiles" */
  tzprofiles_aggregate: Tzprofiles_Aggregate;
  /** fetch data from the table: "tzprofiles" using primary key columns */
  tzprofiles_by_pk?: Maybe<Tzprofiles>;
};


export type Query_RootDipdup_ContractArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Contract_Order_By>>;
  where?: InputMaybe<Dipdup_Contract_Bool_Exp>;
};


export type Query_RootDipdup_Contract_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Contract_Order_By>>;
  where?: InputMaybe<Dipdup_Contract_Bool_Exp>;
};


export type Query_RootDipdup_Contract_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootDipdup_Contract_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Contract_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Contract_Metadata_Order_By>>;
  where?: InputMaybe<Dipdup_Contract_Metadata_Bool_Exp>;
};


export type Query_RootDipdup_Contract_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Contract_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Contract_Metadata_Order_By>>;
  where?: InputMaybe<Dipdup_Contract_Metadata_Bool_Exp>;
};


export type Query_RootDipdup_Contract_Metadata_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootDipdup_HeadArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Head_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Head_Order_By>>;
  where?: InputMaybe<Dipdup_Head_Bool_Exp>;
};


export type Query_RootDipdup_Head_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Head_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Head_Order_By>>;
  where?: InputMaybe<Dipdup_Head_Bool_Exp>;
};


export type Query_RootDipdup_Head_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootDipdup_Head_StatusArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Head_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Head_Status_Order_By>>;
  where?: InputMaybe<Dipdup_Head_Status_Bool_Exp>;
};


export type Query_RootDipdup_Head_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Head_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Head_Status_Order_By>>;
  where?: InputMaybe<Dipdup_Head_Status_Bool_Exp>;
};


export type Query_RootDipdup_IndexArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Index_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Index_Order_By>>;
  where?: InputMaybe<Dipdup_Index_Bool_Exp>;
};


export type Query_RootDipdup_Index_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Index_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Index_Order_By>>;
  where?: InputMaybe<Dipdup_Index_Bool_Exp>;
};


export type Query_RootDipdup_Index_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootDipdup_Model_UpdateArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Model_Update_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Model_Update_Order_By>>;
  where?: InputMaybe<Dipdup_Model_Update_Bool_Exp>;
};


export type Query_RootDipdup_Model_Update_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Model_Update_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Model_Update_Order_By>>;
  where?: InputMaybe<Dipdup_Model_Update_Bool_Exp>;
};


export type Query_RootDipdup_Model_Update_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootDipdup_SchemaArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Schema_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Schema_Order_By>>;
  where?: InputMaybe<Dipdup_Schema_Bool_Exp>;
};


export type Query_RootDipdup_Schema_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Schema_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Schema_Order_By>>;
  where?: InputMaybe<Dipdup_Schema_Bool_Exp>;
};


export type Query_RootDipdup_Schema_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootDipdup_Token_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Token_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Token_Metadata_Order_By>>;
  where?: InputMaybe<Dipdup_Token_Metadata_Bool_Exp>;
};


export type Query_RootDipdup_Token_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Token_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Token_Metadata_Order_By>>;
  where?: InputMaybe<Dipdup_Token_Metadata_Bool_Exp>;
};


export type Query_RootDipdup_Token_Metadata_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTzprofilesArgs = {
  distinct_on?: InputMaybe<Array<Tzprofiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tzprofiles_Order_By>>;
  where?: InputMaybe<Tzprofiles_Bool_Exp>;
};


export type Query_RootTzprofiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tzprofiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tzprofiles_Order_By>>;
  where?: InputMaybe<Tzprofiles_Bool_Exp>;
};


export type Query_RootTzprofiles_By_PkArgs = {
  account: Scalars['String'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "dipdup_contract" */
  dipdup_contract: Array<Dipdup_Contract>;
  /** fetch aggregated fields from the table: "dipdup_contract" */
  dipdup_contract_aggregate: Dipdup_Contract_Aggregate;
  /** fetch data from the table: "dipdup_contract" using primary key columns */
  dipdup_contract_by_pk?: Maybe<Dipdup_Contract>;
  /** fetch data from the table: "dipdup_contract_metadata" */
  dipdup_contract_metadata: Array<Dipdup_Contract_Metadata>;
  /** fetch aggregated fields from the table: "dipdup_contract_metadata" */
  dipdup_contract_metadata_aggregate: Dipdup_Contract_Metadata_Aggregate;
  /** fetch data from the table: "dipdup_contract_metadata" using primary key columns */
  dipdup_contract_metadata_by_pk?: Maybe<Dipdup_Contract_Metadata>;
  /** fetch data from the table in a streaming manner : "dipdup_contract_metadata" */
  dipdup_contract_metadata_stream: Array<Dipdup_Contract_Metadata>;
  /** fetch data from the table in a streaming manner : "dipdup_contract" */
  dipdup_contract_stream: Array<Dipdup_Contract>;
  /** fetch data from the table: "dipdup_head" */
  dipdup_head: Array<Dipdup_Head>;
  /** fetch aggregated fields from the table: "dipdup_head" */
  dipdup_head_aggregate: Dipdup_Head_Aggregate;
  /** fetch data from the table: "dipdup_head" using primary key columns */
  dipdup_head_by_pk?: Maybe<Dipdup_Head>;
  /** fetch data from the table: "dipdup_head_status" */
  dipdup_head_status: Array<Dipdup_Head_Status>;
  /** fetch aggregated fields from the table: "dipdup_head_status" */
  dipdup_head_status_aggregate: Dipdup_Head_Status_Aggregate;
  /** fetch data from the table in a streaming manner : "dipdup_head_status" */
  dipdup_head_status_stream: Array<Dipdup_Head_Status>;
  /** fetch data from the table in a streaming manner : "dipdup_head" */
  dipdup_head_stream: Array<Dipdup_Head>;
  /** fetch data from the table: "dipdup_index" */
  dipdup_index: Array<Dipdup_Index>;
  /** fetch aggregated fields from the table: "dipdup_index" */
  dipdup_index_aggregate: Dipdup_Index_Aggregate;
  /** fetch data from the table: "dipdup_index" using primary key columns */
  dipdup_index_by_pk?: Maybe<Dipdup_Index>;
  /** fetch data from the table in a streaming manner : "dipdup_index" */
  dipdup_index_stream: Array<Dipdup_Index>;
  /** fetch data from the table: "dipdup_model_update" */
  dipdup_model_update: Array<Dipdup_Model_Update>;
  /** fetch aggregated fields from the table: "dipdup_model_update" */
  dipdup_model_update_aggregate: Dipdup_Model_Update_Aggregate;
  /** fetch data from the table: "dipdup_model_update" using primary key columns */
  dipdup_model_update_by_pk?: Maybe<Dipdup_Model_Update>;
  /** fetch data from the table in a streaming manner : "dipdup_model_update" */
  dipdup_model_update_stream: Array<Dipdup_Model_Update>;
  /** fetch data from the table: "dipdup_schema" */
  dipdup_schema: Array<Dipdup_Schema>;
  /** fetch aggregated fields from the table: "dipdup_schema" */
  dipdup_schema_aggregate: Dipdup_Schema_Aggregate;
  /** fetch data from the table: "dipdup_schema" using primary key columns */
  dipdup_schema_by_pk?: Maybe<Dipdup_Schema>;
  /** fetch data from the table in a streaming manner : "dipdup_schema" */
  dipdup_schema_stream: Array<Dipdup_Schema>;
  /** fetch data from the table: "dipdup_token_metadata" */
  dipdup_token_metadata: Array<Dipdup_Token_Metadata>;
  /** fetch aggregated fields from the table: "dipdup_token_metadata" */
  dipdup_token_metadata_aggregate: Dipdup_Token_Metadata_Aggregate;
  /** fetch data from the table: "dipdup_token_metadata" using primary key columns */
  dipdup_token_metadata_by_pk?: Maybe<Dipdup_Token_Metadata>;
  /** fetch data from the table in a streaming manner : "dipdup_token_metadata" */
  dipdup_token_metadata_stream: Array<Dipdup_Token_Metadata>;
  /** fetch data from the table: "tzprofiles" */
  tzprofiles: Array<Tzprofiles>;
  /** fetch aggregated fields from the table: "tzprofiles" */
  tzprofiles_aggregate: Tzprofiles_Aggregate;
  /** fetch data from the table: "tzprofiles" using primary key columns */
  tzprofiles_by_pk?: Maybe<Tzprofiles>;
  /** fetch data from the table in a streaming manner : "tzprofiles" */
  tzprofiles_stream: Array<Tzprofiles>;
};


export type Subscription_RootDipdup_ContractArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Contract_Order_By>>;
  where?: InputMaybe<Dipdup_Contract_Bool_Exp>;
};


export type Subscription_RootDipdup_Contract_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Contract_Order_By>>;
  where?: InputMaybe<Dipdup_Contract_Bool_Exp>;
};


export type Subscription_RootDipdup_Contract_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootDipdup_Contract_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Contract_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Contract_Metadata_Order_By>>;
  where?: InputMaybe<Dipdup_Contract_Metadata_Bool_Exp>;
};


export type Subscription_RootDipdup_Contract_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Contract_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Contract_Metadata_Order_By>>;
  where?: InputMaybe<Dipdup_Contract_Metadata_Bool_Exp>;
};


export type Subscription_RootDipdup_Contract_Metadata_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootDipdup_Contract_Metadata_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dipdup_Contract_Metadata_Stream_Cursor_Input>>;
  where?: InputMaybe<Dipdup_Contract_Metadata_Bool_Exp>;
};


export type Subscription_RootDipdup_Contract_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dipdup_Contract_Stream_Cursor_Input>>;
  where?: InputMaybe<Dipdup_Contract_Bool_Exp>;
};


export type Subscription_RootDipdup_HeadArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Head_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Head_Order_By>>;
  where?: InputMaybe<Dipdup_Head_Bool_Exp>;
};


export type Subscription_RootDipdup_Head_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Head_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Head_Order_By>>;
  where?: InputMaybe<Dipdup_Head_Bool_Exp>;
};


export type Subscription_RootDipdup_Head_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootDipdup_Head_StatusArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Head_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Head_Status_Order_By>>;
  where?: InputMaybe<Dipdup_Head_Status_Bool_Exp>;
};


export type Subscription_RootDipdup_Head_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Head_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Head_Status_Order_By>>;
  where?: InputMaybe<Dipdup_Head_Status_Bool_Exp>;
};


export type Subscription_RootDipdup_Head_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dipdup_Head_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Dipdup_Head_Status_Bool_Exp>;
};


export type Subscription_RootDipdup_Head_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dipdup_Head_Stream_Cursor_Input>>;
  where?: InputMaybe<Dipdup_Head_Bool_Exp>;
};


export type Subscription_RootDipdup_IndexArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Index_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Index_Order_By>>;
  where?: InputMaybe<Dipdup_Index_Bool_Exp>;
};


export type Subscription_RootDipdup_Index_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Index_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Index_Order_By>>;
  where?: InputMaybe<Dipdup_Index_Bool_Exp>;
};


export type Subscription_RootDipdup_Index_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootDipdup_Index_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dipdup_Index_Stream_Cursor_Input>>;
  where?: InputMaybe<Dipdup_Index_Bool_Exp>;
};


export type Subscription_RootDipdup_Model_UpdateArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Model_Update_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Model_Update_Order_By>>;
  where?: InputMaybe<Dipdup_Model_Update_Bool_Exp>;
};


export type Subscription_RootDipdup_Model_Update_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Model_Update_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Model_Update_Order_By>>;
  where?: InputMaybe<Dipdup_Model_Update_Bool_Exp>;
};


export type Subscription_RootDipdup_Model_Update_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootDipdup_Model_Update_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dipdup_Model_Update_Stream_Cursor_Input>>;
  where?: InputMaybe<Dipdup_Model_Update_Bool_Exp>;
};


export type Subscription_RootDipdup_SchemaArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Schema_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Schema_Order_By>>;
  where?: InputMaybe<Dipdup_Schema_Bool_Exp>;
};


export type Subscription_RootDipdup_Schema_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Schema_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Schema_Order_By>>;
  where?: InputMaybe<Dipdup_Schema_Bool_Exp>;
};


export type Subscription_RootDipdup_Schema_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootDipdup_Schema_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dipdup_Schema_Stream_Cursor_Input>>;
  where?: InputMaybe<Dipdup_Schema_Bool_Exp>;
};


export type Subscription_RootDipdup_Token_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Token_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Token_Metadata_Order_By>>;
  where?: InputMaybe<Dipdup_Token_Metadata_Bool_Exp>;
};


export type Subscription_RootDipdup_Token_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dipdup_Token_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dipdup_Token_Metadata_Order_By>>;
  where?: InputMaybe<Dipdup_Token_Metadata_Bool_Exp>;
};


export type Subscription_RootDipdup_Token_Metadata_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootDipdup_Token_Metadata_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dipdup_Token_Metadata_Stream_Cursor_Input>>;
  where?: InputMaybe<Dipdup_Token_Metadata_Bool_Exp>;
};


export type Subscription_RootTzprofilesArgs = {
  distinct_on?: InputMaybe<Array<Tzprofiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tzprofiles_Order_By>>;
  where?: InputMaybe<Tzprofiles_Bool_Exp>;
};


export type Subscription_RootTzprofiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tzprofiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tzprofiles_Order_By>>;
  where?: InputMaybe<Tzprofiles_Bool_Exp>;
};


export type Subscription_RootTzprofiles_By_PkArgs = {
  account: Scalars['String'];
};


export type Subscription_RootTzprofiles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tzprofiles_Stream_Cursor_Input>>;
  where?: InputMaybe<Tzprofiles_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "tzprofiles" */
export type Tzprofiles = {
  __typename?: 'tzprofiles';
  account: Scalars['String'];
  alias?: Maybe<Scalars['String']>;
  contract: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  discord?: Maybe<Scalars['String']>;
  domain_name?: Maybe<Scalars['String']>;
  ethereum?: Maybe<Scalars['String']>;
  failed: Scalars['Boolean'];
  github?: Maybe<Scalars['String']>;
  invalid_claims: Scalars['jsonb'];
  logo?: Maybe<Scalars['String']>;
  resolved: Scalars['Boolean'];
  twitter?: Maybe<Scalars['String']>;
  unprocessed_claims: Scalars['jsonb'];
  valid_claims: Scalars['jsonb'];
  website?: Maybe<Scalars['String']>;
};


/** columns and relationships of "tzprofiles" */
export type TzprofilesInvalid_ClaimsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "tzprofiles" */
export type TzprofilesUnprocessed_ClaimsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "tzprofiles" */
export type TzprofilesValid_ClaimsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "tzprofiles" */
export type Tzprofiles_Aggregate = {
  __typename?: 'tzprofiles_aggregate';
  aggregate?: Maybe<Tzprofiles_Aggregate_Fields>;
  nodes: Array<Tzprofiles>;
};

/** aggregate fields of "tzprofiles" */
export type Tzprofiles_Aggregate_Fields = {
  __typename?: 'tzprofiles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tzprofiles_Max_Fields>;
  min?: Maybe<Tzprofiles_Min_Fields>;
};


/** aggregate fields of "tzprofiles" */
export type Tzprofiles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tzprofiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "tzprofiles". All fields are combined with a logical 'AND'. */
export type Tzprofiles_Bool_Exp = {
  _and?: InputMaybe<Array<Tzprofiles_Bool_Exp>>;
  _not?: InputMaybe<Tzprofiles_Bool_Exp>;
  _or?: InputMaybe<Array<Tzprofiles_Bool_Exp>>;
  account?: InputMaybe<String_Comparison_Exp>;
  alias?: InputMaybe<String_Comparison_Exp>;
  contract?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  discord?: InputMaybe<String_Comparison_Exp>;
  domain_name?: InputMaybe<String_Comparison_Exp>;
  ethereum?: InputMaybe<String_Comparison_Exp>;
  failed?: InputMaybe<Boolean_Comparison_Exp>;
  github?: InputMaybe<String_Comparison_Exp>;
  invalid_claims?: InputMaybe<Jsonb_Comparison_Exp>;
  logo?: InputMaybe<String_Comparison_Exp>;
  resolved?: InputMaybe<Boolean_Comparison_Exp>;
  twitter?: InputMaybe<String_Comparison_Exp>;
  unprocessed_claims?: InputMaybe<Jsonb_Comparison_Exp>;
  valid_claims?: InputMaybe<Jsonb_Comparison_Exp>;
  website?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Tzprofiles_Max_Fields = {
  __typename?: 'tzprofiles_max_fields';
  account?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  contract?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  discord?: Maybe<Scalars['String']>;
  domain_name?: Maybe<Scalars['String']>;
  ethereum?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Tzprofiles_Min_Fields = {
  __typename?: 'tzprofiles_min_fields';
  account?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  contract?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  discord?: Maybe<Scalars['String']>;
  domain_name?: Maybe<Scalars['String']>;
  ethereum?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "tzprofiles". */
export type Tzprofiles_Order_By = {
  account?: InputMaybe<Order_By>;
  alias?: InputMaybe<Order_By>;
  contract?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  discord?: InputMaybe<Order_By>;
  domain_name?: InputMaybe<Order_By>;
  ethereum?: InputMaybe<Order_By>;
  failed?: InputMaybe<Order_By>;
  github?: InputMaybe<Order_By>;
  invalid_claims?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  resolved?: InputMaybe<Order_By>;
  twitter?: InputMaybe<Order_By>;
  unprocessed_claims?: InputMaybe<Order_By>;
  valid_claims?: InputMaybe<Order_By>;
  website?: InputMaybe<Order_By>;
};

/** select columns of table "tzprofiles" */
export enum Tzprofiles_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Alias = 'alias',
  /** column name */
  Contract = 'contract',
  /** column name */
  Description = 'description',
  /** column name */
  Discord = 'discord',
  /** column name */
  DomainName = 'domain_name',
  /** column name */
  Ethereum = 'ethereum',
  /** column name */
  Failed = 'failed',
  /** column name */
  Github = 'github',
  /** column name */
  InvalidClaims = 'invalid_claims',
  /** column name */
  Logo = 'logo',
  /** column name */
  Resolved = 'resolved',
  /** column name */
  Twitter = 'twitter',
  /** column name */
  UnprocessedClaims = 'unprocessed_claims',
  /** column name */
  ValidClaims = 'valid_claims',
  /** column name */
  Website = 'website'
}

/** Streaming cursor of the table "tzprofiles" */
export type Tzprofiles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tzprofiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tzprofiles_Stream_Cursor_Value_Input = {
  account?: InputMaybe<Scalars['String']>;
  alias?: InputMaybe<Scalars['String']>;
  contract?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  discord?: InputMaybe<Scalars['String']>;
  domain_name?: InputMaybe<Scalars['String']>;
  ethereum?: InputMaybe<Scalars['String']>;
  failed?: InputMaybe<Scalars['Boolean']>;
  github?: InputMaybe<Scalars['String']>;
  invalid_claims?: InputMaybe<Scalars['jsonb']>;
  logo?: InputMaybe<Scalars['String']>;
  resolved?: InputMaybe<Scalars['Boolean']>;
  twitter?: InputMaybe<Scalars['String']>;
  unprocessed_claims?: InputMaybe<Scalars['jsonb']>;
  valid_claims?: InputMaybe<Scalars['jsonb']>;
  website?: InputMaybe<Scalars['String']>;
};

export type ProfileQueryVariables = Exact<{
  address: Scalars['String'];
}>;


export type ProfileQuery = { __typename?: 'query_root', tzprofiles_by_pk?: { __typename?: 'tzprofiles', account: string, domain_name?: string | null, valid_claims: any } | null };

export type ProfileListQueryVariables = Exact<{
  where: Tzprofiles_Bool_Exp;
}>;


export type ProfileListQuery = { __typename?: 'query_root', tzprofiles: Array<{ __typename?: 'tzprofiles', account: string, domain_name?: string | null, valid_claims: any }> };

export const ProfileDocument = gql`
    query Profile($address: String!) {
  tzprofiles_by_pk(account: $address) {
    account
    domain_name
    valid_claims
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProfileGQL extends Apollo.Query<ProfileQuery, ProfileQueryVariables> {
    document = ProfileDocument;
    client = 'tzprofiles';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProfileListDocument = gql`
    query ProfileList($where: tzprofiles_bool_exp!) {
  tzprofiles(where: $where) {
    account
    domain_name
    valid_claims
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProfileListGQL extends Apollo.Query<ProfileListQuery, ProfileListQueryVariables> {
    document = ProfileListDocument;
    client = 'tzprofiles';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }