import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { ReplaySubject, Observable } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';

import { TezosService } from '../tezos/tezos.service';
import { StorageSchema } from '../utils/storage';
import { DataSourceFactory } from '../graphql/data-source-factory';
import { ReverseRecordDetailDataSource } from '../graphql/reverse-record-data-source';
import { ReverseRecordDetailQuery } from '../graphql/graphql.generated';
import { safeJsonParse } from '../utils/convert';
import { ErrorPresenterService } from '../utils/error-presenter.service';

export type ReverseRecord = NonNullable<ReverseRecordDetailQuery['reverseRecord']>;

@Injectable({
    providedIn: 'root',
})
export class ReverseRecordService {
    private reverseRecordStream: ReplaySubject<ReverseRecord | null> = new ReplaySubject(1);
    private reverseRecordDetailDataSource: ReverseRecordDetailDataSource;

    get current(): Observable<ReverseRecord | null> {
        return this.reverseRecordStream;
    }

    constructor(
        private tezosService: TezosService,
        private storageMap: StorageMap,
        private errorPresenterService: ErrorPresenterService,
        dataSourceFactory: DataSourceFactory
    ) {
        this.reverseRecordDetailDataSource = dataSourceFactory.createReverseRecordDetailDataSource();
        this.broadcastReverseRecord(false);

        this.reverseRecordDetailDataSource.data$
            .pipe(
                filter(data => !!data),
                switchMap(data => {
                    if (!data.reverseRecord) {
                        return this.storageMap.delete(StorageSchema.reverseRecord.key);
                    } else {
                        return this.storageMap.set(StorageSchema.reverseRecord.key, JSON.stringify(data.reverseRecord), StorageSchema.reverseRecord.schema);
                    }
                })
            )
            .subscribe(() => this.broadcastReverseRecord(true));

        this.reverseRecordDetailDataSource.error$.pipe(filter(e => !!e)).subscribe(e => this.errorPresenterService.apiErrorToast('reverse-record-fetch', e));

        this.load();
    }

    private load() {
        this.tezosService.activeWallet.subscribe(wallet => {
            if (!wallet) {
                this.reverseRecordStream.next(null);
                this.storageMap.delete(StorageSchema.reverseRecord.key).subscribe();
            } else {
                this.reverseRecordDetailDataSource.load({ address: wallet.address });
            }
        });
    }

    private broadcastReverseRecord(sendIfNull: boolean) {
        this.storageMap
            .get(StorageSchema.reverseRecord.key, StorageSchema.reverseRecord.schema)
            .pipe(
                filter(r => !!r || sendIfNull),
                map(r => (safeJsonParse(r!) || null) as ReverseRecord | null)
            )
            .subscribe(r => {
                this.reverseRecordStream.next(r);
            });
    }

    refresh() {
        this.load();
    }
}
