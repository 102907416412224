import { Component } from '@angular/core';

@Component({
    selector: 'td-card-skeleton',
    templateUrl: './card-skeleton.component.html',
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
})
export class CardSkeletonComponent {}
