import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FavouritesService } from './favourites.service';
import { TezosService } from '@/tezos/tezos.service';
import { map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'td-star',
    templateUrl: './star.component.html',
    styleUrls: ['./star.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StarComponent {
    private favService = inject(FavouritesService);
    private tezos = inject(TezosService);
    private domainName = '';

    private selected$: Observable<boolean>;
    vm$ = this.tezos.activeWallet.pipe(
        switchMap(w => {
            if (!w) {
                return of(null);
            }

            return this.selected$.pipe(map(s => ({ selected: s })));
        })
    );

    @Input() set name(val: string) {
        this.selected$ = this.favService.isFavourite$(val);
        this.domainName = val;
    }

    toggleFavourite() {
        this.favService.toggleFavourite(this.domainName).subscribe();
    }
}
