import { Component, inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { BuyOfferRecord } from '../graphql/buy-offer-table-data-source';
import { OperationStatusDoneEvent } from '../shared/operation-status.component';
import { BuyOfferBrokerService } from '../tezos/nft/buy-offer-broker.service';
import { SmartContractOperationEvent } from '../tezos/tezos.service';

@Component({
    selector: 'td-remove-buy-offer',
    templateUrl: './remove-buy-offer.component.html',
    styleUrls: ['./remove-buy-offer.component.scss'],
})
export class RemoveBuyOfferComponent implements OnInit {
    private data = inject<{ offer: BuyOfferRecord }>(MAT_DIALOG_DATA);
    private dialogRef = inject(MatDialogRef);
    private buyOfferBrokerService = inject(BuyOfferBrokerService);

    operation: Observable<SmartContractOperationEvent> | null;
    submit: boolean;

    offer: BuyOfferRecord;

    ngOnInit() {
        this.offer = this.data.offer;
    }

    operationDone(event: OperationStatusDoneEvent) {
        if (event.success) {
            this.dialogRef.close(true);
        } else {
            this.submit = false;
        }
    }

    cancel() {
        this.dialogRef.close(false);
    }

    save() {
        this.submit = true;
        this.operation = this.buyOfferBrokerService.removeOffer(this.offer.tokenId);
    }
}
