<div *transloco="let t" class="card smooth-shadow home-item d-flex flex-column gap-2" [hidden]="isHidden" [ngClass]="{ empty: isHidden }">
    <ng-container *ngIf="vm$ | async; let vm">
        <ng-container *ngIf="vm.state">
            <h2 class="w-100 mb-2">
                {{ t('airdrop.vesting-widget.title') }}
            </h2>
            <td-operation-status [operation]="operation" (done)="operationDone($event, vm.state)" #op></td-operation-status>
            <div
                class="w-100"
                [innerHTML]="
                    t('airdrop.vesting-widget.description', {
                        launch: airdropLaunchDate | dayjsDate,
                        link: 'https://blog.tezos.domains/tezos-domains-airdrop-details-33d3a9bb961d',
                        totalWeeks: vm.state.totalClaims - 1,
                        percentPerWeek: 0.75 / (vm.state.totalClaims - 1) | percent : '1.2-2'
                    })
                "
            ></div>
            <div
                [innerHTML]="
                    t('airdrop.vesting-widget.claimed', {
                        amountClaimed: vm.state.amountClaimed | formatTed,
                        totalAmount: vm.state.totalAmount | formatTed
                    })
                "
            ></div>
            <div
                *ngIf="vm.claimsLeft"
                [innerHTML]="
                    t('airdrop.vesting-widget.locked', {
                        amountLocked: vm.state.amountLocked | formatTed,
                        claimsLeft: vm.claimsLeft
                    })
                "
            ></div>

            <div *ngIf="vm.nextUnlockDate && vm.state.amountClaimable.eq(0)">
                {{ t('airdrop.vesting-widget.next-tranche') }}
                <span [matTooltip]="vm.nextUnlockDate | dayjsDate"> <td-humanized-date [value]="vm.nextUnlockDate"></td-humanized-date></span>
            </div>
            <div class="w-100 d-flex flex-column flex-sm-row justify-content-end">
                <button class="mt-1" mat-flat-button color="primary" *ngIf="vm.state.amountClaimable.isGreaterThan(0)" (click)="claim(vm.state, vm.hasDeposit)">
                    {{
                        vm.hasDeposit
                            ? t('airdrop.vesting-widget.claim-deposit', { amount: vm.state.amountClaimable | formatTed })
                            : t('airdrop.vesting-widget.claim', { amount: vm.state.amountClaimable | formatTed })
                    }}
                </button>
            </div>
        </ng-container>
    </ng-container>
</div>
