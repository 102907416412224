import { Component, EventEmitter, OnInit, Output, ViewChild, inject } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import dayjs from 'dayjs';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { FilterHistoryStateService } from '../browser/filter-history-state.service';
import { Configuration } from '../configuration';
import { ListOption, ListOptionType } from '../filters/list-filter.component';
import { DateFilter } from '../filters/model';
import { HorizontalScrollComponent } from '../shared/horizontal-scroll.component';

interface RewardHistoryFilterState {
    historyDate?: DateFilter | null;
    historyEventType?: string[];
}
export interface RewardHistoryFilter {
    date: DateFilter;
    types: string[];
}

@Component({
    selector: 'td-history-filter',
    templateUrl: './history-filter.component.html',
    styleUrls: ['./history-filter.component.scss'],
})
export class HistoryFilterComponent implements OnInit {
    private readonly config = inject(Configuration);
    private readonly historyStateService = inject(FilterHistoryStateService);
    private readonly transloco = inject(TranslocoService);

    @Output() filterChange = new EventEmitter<RewardHistoryFilter>();

    @ViewChild(HorizontalScrollComponent) horizontalScroll: HorizontalScrollComponent;

    maxDate = dayjs().endOf('day');
    minDate = dayjs(this.config.minEventsFilterDate);
    eventTypeOptions: ListOption[] = this.mapAllowedOptions();

    initialDateFilter: DateFilter | null = null;
    initialEventTypes: string[] = [];

    private types$: BehaviorSubject<string[]>;
    private date$: BehaviorSubject<DateFilter | null>;
    private filters$: Subscription | null = null;

    ngOnInit(): void {
        const state = this.historyStateService.get<RewardHistoryFilterState>();

        this.initialDateFilter = state.historyDate ?? null;
        this.initialEventTypes = state.historyEventType ?? [];

        this.date$ = new BehaviorSubject<DateFilter | null>(this.initialDateFilter);
        this.types$ = new BehaviorSubject<string[]>(this.initialEventTypes);

        this.initializeFilter();
    }

    dateChanged(data: DateFilter | null) {
        this.date$.next(data ?? this.initialDateFilter);
    }

    typesChanged(data: string[]) {
        this.types$.next(data);
    }

    private initializeFilter() {
        this.filters$?.unsubscribe();
        this.filters$ = combineLatest([this.date$, this.types$])
            .pipe(
                debounceTime(300),
                tap(([date, types]) => this.saveState(date, types)),
                map(([date, types]) => this.toFilterModel(date, types)),
                distinctUntilChanged<RewardHistoryFilter>(isEqual)
            )
            .subscribe(filter => {
                this.horizontalScroll?.refreshScroll();
                this.filterChange.next(filter);
            });
    }

    private toFilterModel(date: DateFilter | null, types: string[]): RewardHistoryFilter {
        const model: RewardHistoryFilter = {
            types: types,
            date: this.getDateFilter(date),
        };

        return model;
    }

    private getDateFilter(date: DateFilter | null): DateFilter {
        if (!date) {
            return { from: this.minDate, to: this.maxDate };
        }

        return {
            from: date.from.startOf('day'),
            to: date.to?.endOf('day'),
        };
    }

    private mapAllowedOptions(): ListOption[] {
        return [
            new ListOption('reward', this.translateType('reward'), ListOptionType.Item),
            new ListOption('withdrawal', this.translateType('withdrawal'), ListOptionType.Item),
            new ListOption('deposit', this.translateType('deposit'), ListOptionType.Item),
            new ListOption('received', this.translateType('received'), ListOptionType.Item),
            new ListOption('sent', this.translateType('sent'), ListOptionType.Item),
        ];
    }

    private translateType(type: string): string {
        /**
         * t(gov-pool-event.reward)
         * t(gov-pool-event.withdrawal)
         * t(gov-pool-event.deposit)
         * t(gov-pool-event.received)
         * t(gov-pool-event.sent)
         */
        return this.transloco.translate(`gov-pool-event.${type}`);
    }

    private saveState(date: DateFilter | null, types: string[]): void {
        this.historyStateService.merge<RewardHistoryFilterState>({
            historyDate: date,
            historyEventType: types,
        });
    }
}
