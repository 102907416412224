<ng-container *ngIf="vm$ | async; let vm">
    <div
        *transloco="let t"
        class="card smooth-shadow home-item w-100 d-flex flex-column"
        [hidden]="!buyOfferList.someOffersLoaded"
        [ngClass]="{ empty: !buyOfferList.someOffersLoaded }"
    >
        <div class="d-flex mb-2">
            <h2 class="flex-grow-1 mb-0">{{ t('home.latest-buy-offers-title') }}</h2>
            <div class="d-flex align-items-center">
                <a [routerLink]="['/buy-offers']">{{ t('general.see-more') }}</a>
            </div>
        </div>
        <td-buy-offer-list
            class="full-card-width"
            #buyOfferList
            [maxCount]="5"
            [scrollToLoad]="false"
            [showShadow]="false"
            [pollInterval]="10 * 60 * 1_000"
            [filter]="filter"
            [wallet]="vm.wallet"
            [address]="vm.wallet?.address"
            [loadAddressSpecificData]="false"
            [allowedToIgnoreOffer]="true"
            [canShowYouBadge]="true"
            (dataLoading)="onDataLoading($event)"
        ></td-buy-offer-list>

        <div class="mt-3 d-flex">
            <a [routerLink]="['/buy-offers']">{{ t('general.see-more') }}</a>
        </div>
    </div>
</ng-container>
