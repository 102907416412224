import { A11yModule } from '@angular/cdk/a11y';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SharedModule } from '../shared/shared.module';
import { AddressFilterComponent } from './address-filter.component';
import { DateFilterComponent } from './date-filter.component';
import { DomainFilterComponent } from './domain-filter.component';
import { ListFilterComponent } from './list-filter.component';
import { RadioListFilterComponent } from './radio-list-filter.component';
import { PriceFilterComponent } from './price-filter.component';
import { SorterComponent } from './sorter.component';

@NgModule({
    declarations: [
        PriceFilterComponent,
        DateFilterComponent,
        ListFilterComponent,
        RadioListFilterComponent,
        AddressFilterComponent,
        DomainFilterComponent,
        SorterComponent,
    ],
    imports: [SharedModule, FormsModule, ReactiveFormsModule, MatDatepickerModule, A11yModule],
    exports: [
        PriceFilterComponent,
        DateFilterComponent,
        ListFilterComponent,
        RadioListFilterComponent,
        AddressFilterComponent,
        DomainFilterComponent,
        SorterComponent,
    ],
})
export class FiltersModule {}
