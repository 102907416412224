<div *transloco="let t" class="w-100 px-3 py-2 d-flex align-items-center">
    <div class="w-100 item-left d-flex flex-column">
        <div class="mb-2 d-flex align-items-center flex-grow-1">
            <td-address
                class="mt-1 flex-shrink-0"
                [text]="false"
                [iconSize]="32"
                [address]="event.sourceAddress"
                [reverseRecord]="event.sourceAddressReverseRecord"
            ></td-address>
            <div class="ms-2 header-info d-flex flex-column">
                <td-address
                    class="d-flex"
                    [address]="event.sourceAddress"
                    [reverseRecord]="event.sourceAddressReverseRecord"
                    [copy]="false"
                    [icon]="false"
                ></td-address>
                <div class="d-flex">
                    <span class="txt-secondary flex-shrink-0" [matTooltip]="event.block.timestamp | dayjsDate">
                        <td-humanized-date [value]="event.block.timestamp"></td-humanized-date>
                    </span>
                </div>
            </div>
        </div>

        <td-event-text scope="home" [event]="event"></td-event-text>
    </div>
    <div class="txt-lg me-2 flex-shrink-0">
        <td-tez *ngIf="amount" [value]="amount"></td-tez>
    </div>
</div>
