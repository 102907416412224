<form class="d-flex flex-column flex-fill h-100" [formGroup]="form" *transloco="let t">
    <h1 mat-dialog-title class="text-truncate flex-shrink-0">{{ t('gvPool.withdraw.title') }}</h1>

    <mat-dialog-content class="d-flex flex-column flex-grow-1">
        <td-operation-status class="w-100" [operation]="operation" (done)="operationDone($event)" #op></td-operation-status>
        <div class="flex-shrink-0 d-flex flex-column gap-2">
            <div class="d-flex">
                <div class="flex-grow-1">{{ t('gvPool.withdraw.available-to-withdraw') }}</div>
                <div>{{ data.depositedTedBalance | formatTed }} TED</div>
            </div>
            <td-modal-apr [stats]="data"></td-modal-apr>
            <mat-form-field appearance="standard" class="amount mt-3">
                <mat-label>{{ t('gvPool.withdraw.amount') }}</mat-label>
                <input formControlName="amount" type="number" matInput min="0" required />
                <button matSuffix mat-flat-button color="primary" aria-label="Max" (click)="maxAmount($event)" data-testid="max">
                    {{ t('gvPool.withdraw.max') }}
                </button>
                <mat-error>
                    <td-validation-messages [control]="form.get('amount')!" [context]="{ name: '' }"></td-validation-messages>
                </mat-error>
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex justify-content-end align-items-end gap-2 w-100 min-height-auto">
        <button mat-button (click)="cancel()" [disabled]="form.disabled">{{ t('actions.cancel') }}</button>
        <button mat-flat-button color="primary" (click)="submit()" [disabled]="form.invalid || op.inProgress || !form.value.amount">
            {{ t('actions.withdraw') }}
        </button>
    </mat-dialog-actions>
</form>
