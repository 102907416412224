import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import BigNumber from 'bignumber.js';
import { Observable } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { Configuration } from '../configuration';
import { DelegationStatus } from '../delegation/models';
import { TezosWalletService } from '../tezos/tezos-wallet.service';
import { ChangeDelegationComponent } from './change-delegation.component';
import { DelegationStatusService } from './delegation-status.service';
import { DepositComponent } from './deposit.component';
import { PoolStats } from './models';
import { PoolStatsService } from './pool-stats.service';
import { TEDTokenPriceService } from './ted-token-price.service';
import { WithdrawComponent } from './withdraw.component';

@UntilDestroy()
@Component({
    selector: 'td-pool-widget',
    templateUrl: './pool-widget.component.html',
    styleUrls: ['./pool-widget.component.scss'],
})
export class GovPoolWidgetComponent {
    private delegationStatusService = inject(DelegationStatusService);
    private tezosWallet = inject(TezosWalletService);
    private poolService = inject(PoolStatsService);
    private configuration = inject(Configuration);
    private dialog = inject(MatDialog);
    private tedTokenPrice = inject(TEDTokenPriceService);

    @Input() showSeeMore = true;
    @Input() hideWhenNoTokens = false;

    @Output() depositedAmountChanged = new EventEmitter<void>();

    hidden = true;

    readonly homebaseUrl = this.configuration.governance.homebaseUrl;
    readonly plentyUrl = this.configuration.governance.plentyUrl;

    readonly loading$ = this.poolService.loading$;
    readonly vm$ = this.poolService.stats$.pipe(
        delay(10),
        tap(data => (this.hidden = !data || (this.hideWhenNoTokens && data.tedBalance.isZero() && data.votesBalance.isZero())))
    );

    delegateData: DelegationStatus | null = null;

    constructor() {
        this.delegationStatusService.delegationStatus$.pipe(untilDestroyed(this)).subscribe(data => (this.delegateData = data));
    }

    deposit(stats: PoolStats) {
        const modal = this.dialog.open(DepositComponent, {
            data: { poolStats: stats, delegate: this.delegateData },
            height: '540px',
            panelClass: 'big-dialog-pane',
        });

        modal.afterClosed().subscribe(result => {
            if (result) {
                this.refresh();
                this.depositedAmountChanged.next();
            }
        });
    }

    withdraw(stats: PoolStats) {
        const modal = this.dialog.open(WithdrawComponent, { data: stats, height: '340px', panelClass: 'big-dialog-pane' });

        modal.afterClosed().subscribe(result => {
            if (result) {
                this.refresh();
                this.depositedAmountChanged.next();
            }
        });
    }

    changeDelegation() {
        const modal = this.dialog.open(ChangeDelegationComponent, {
            data: { delegate: this.delegateData },
            height: '700px',
            width: '700px',
            panelClass: 'big-dialog-pane',
        });

        modal.afterClosed().subscribe(result => {
            if (result) {
                this.delegationStatusService.refresh();
                this.delegateData = result.delegate;
            }
        });
    }

    convertTedToUSD(ted: BigNumber | number): Observable<{ loading: boolean; price: BigNumber | null }> {
        return this.tedTokenPrice.convert(BigNumber(ted));
    }

    private refresh(): void {
        this.delegationStatusService.refresh();
        this.tezosWallet.refreshTokenBalance();
        this.poolService.refresh();
    }
}
