import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'td-money-input',
    templateUrl: './money-input.component.html',
    styleUrls: ['./money-input.component.scss'],
})
export class MoneyInputComponent {
    hasFocus: boolean;

    @Input() control: AbstractControl;
    @Input() label: string;
    @Input() displayOnly: boolean;

    @ViewChild('input', { static: false }) input?: ElementRef;
}
