import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FileDragService {
    private _overWindow = new Subject<boolean>();

    readonly overWindow = this._overWindow.asObservable();

    setOverWindow(isOver: boolean) {
        this._overWindow.next(isOver);
    }
}
