import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import cloneDeep from 'lodash-es/cloneDeep';
import merge from 'lodash-es/merge';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class HistoryStateService {
    private states: Record<number, any> = {};
    private currentStateId: number;

    constructor(router: Router) {
        router.events.pipe(filter(e => e instanceof NavigationStart)).subscribe((e: NavigationStart) => {
            this.currentStateId = e.id;

            if (e.restoredState) {
                const state = this.states[e.restoredState.navigationId];
                this.states[e.id] = cloneDeep(state) || {};
                delete this.states[e.restoredState.navigationId];
            } else {
                this.states[e.id] = {};
            }
        });
    }

    merge<T = any>(state: T) {
        merge(this.get(), state);
    }

    get<T = any>(): T {
        return this.states[this.currentStateId] || ({} as T);
    }
}
