<td-alert class="operation-status" [state]="state" [inProgress]="inProgress" *ngIf="message">
    <div class="d-flex align-items-center">
        <div class="status-message flex-grow-1" [tdDynamicHtml]="message"></div>
        <div class="buttons d-flex gap-2" *transloco="let t">
            <button mat-stroked-button name="redo" *ngIf="walletTimeout && !suspended && !sent && !error" (click)="redo()" color="primary">
                {{ t('actions.redo') }}
            </button>
            <a mat-stroked-button name="view" *ngIf="link" [href]="link" target="_blank" color="primary">{{ t('actions.view') }}</a>
        </div>
    </div>
</td-alert>
