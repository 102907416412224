import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

export enum RadioListOptionType {
    Item = 'item',
    Separator = 'separator',
}

export class RadioListOption {
    readonly id: string;

    constructor(readonly value: string, public readonly label: string, public readonly type: RadioListOptionType = RadioListOptionType.Item) {
        this.id = value;
    }
}

@Component({
    selector: 'td-radio-list-filter',
    templateUrl: './radio-list-filter.component.html',
    styleUrls: ['./radio-list-filter.component.scss'],
})
export class RadioListFilterComponent implements OnInit {
    @Input() options: RadioListOption[];
    @Input() defaultSelectedOption: string;
    @Input() initialSelectedOption?: string;
    @Input() buttonLabel?: string;
    @Output() selectedChange = new EventEmitter<string>();

    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    allItems: RadioListOption[] = [];

    optionType = RadioListOptionType;

    selectedItem: RadioListOption;

    ngOnInit() {
        if (!this.defaultSelectedOption) {
            throw new Error('initialSelectedOption must be supplied and have a value');
        }

        if (!this.options || !this.options.length) {
            throw new Error('the list of options cannot be empty');
        }

        if (!this.options.some(o => o.value === this.defaultSelectedOption)) {
            throw new Error('selectedOption is not part of the supplied option list');
        }

        this.allItems = [...this.options];
        this.selectedItem = this.allItems.find(item => item.value === this.initialSelectedOption ?? this.defaultSelectedOption)!;
    }

    onClick() {
        this.trigger.openMenu();
    }

    selectionChange(val: RadioListOption) {
        this.selectedItem = val;
    }

    submit(): void {
        this.selectedChange.next(this.selectedItem.value);
        this.trigger.closeMenu();
    }

    clear() {
        this.selectedItem = this.allItems.find(i => i.value === this.defaultSelectedOption)!;
        this.selectedChange.next(this.defaultSelectedOption);
        this.trigger.closeMenu();
    }
}
