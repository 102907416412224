import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'a[href]:not([routerLink]):not([plain-link]):not([mat-stroked-button])',
    template: '<ng-content></ng-content>',
})
export class PlainLinkComponent implements OnInit {
    @Input() content: string;

    private omittedUrls: string[] = ['javascript', 'mailto', 'tel'];

    constructor(private router: Router, private elementRef: ElementRef) {}

    @HostListener('click', ['$event']) onClick(event: Event) {
        const element = event.currentTarget as HTMLAnchorElement;
        const url = element.href;

        if (this.omittedUrls.some(o => url.startsWith(o))) {
            return;
        }

        if (element.target && element.target !== '_self') {
            return;
        }

        const hrefAttr = element.getAttribute('href');
        event.preventDefault();

        if (hrefAttr === '#' || hrefAttr === '') {
            return;
        }

        this.router.navigateByUrl(element.pathname + element.search);
    }

    ngOnInit() {
        const element = this.elementRef.nativeElement;

        if (this.content) {
            element.innerHTML = this.content;
        }
    }
}
