<form class="d-flex flex-fill flex-column h-100 gap-1" [formGroup]="form" *transloco="let t">
    <h1 mat-dialog-title>{{ data.title || t('advanced-select.add-title') }}</h1>
    <mat-dialog-content class="d-flex flex-column">
        <div class="d-flex flex-column h-100 gap-2">
            <mat-form-field appearance="standard">
                <mat-label>{{ data.labelLabel || t('fields.label') }}</mat-label>
                <input formControlName="label" type="text" matInput required />
                <mat-error>
                    <td-validation-messages [control]="form.get('label')!" [context]="{ name: data.labelLabel || t('fields.label') }"></td-validation-messages>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>{{ data.valueLabel || t('fields.value') }}</mat-label>
                <input formControlName="value" type="text" matInput required />
                <mat-error>
                    <td-validation-messages [control]="form.get('value')!" [context]="{ name: data.valueLabel || t('fields.value') }"></td-validation-messages>
                </mat-error>
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex justify-content-end align-items-end gap-2 w-100">
        <button mat-flat-button color="primary" (click)="add()" [disabled]="form.invalid">{{ t('actions.add') }}</button>
        <button mat-button (click)="cancel()">{{ t('actions.cancel') }}</button>
    </mat-dialog-actions>
</form>
