import { DataSource } from '@angular/cdk/table';
import { assign, keyBy } from 'lodash-es';
import { Observable, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DataSourceBase, DataSourceOptions, DataSourcePagination, ProcessedData } from '../data-source-base';
import { ReverseRecordListGQL, ReverseRecordListQuery } from '../graphql/graphql.generated';
import { EmptyProfile, TzProfile, TzprofilesService } from '../tzprofiles/tzprofiles.service';
import { Unarray } from '../utils/types';
import { GovernanceDataSourcePagination } from './governance-datasource-pagination';
import { type DelegatesLeaderboardQuery, type DelegatesLeaderboardQueryVariables } from './governance-graphql.generated';

type Delegate = Unarray<DelegatesLeaderboardQuery['delegates']>;

export type LeaderboardDelegate = Omit<Delegate, 'currentDelegations_aggregate'> & { delegatorCount: number; public: boolean };
export type ReverseRecord = Unarray<ReverseRecordListQuery['reverseRecords']['edges']>['node'];

type ExtraDelegateInfo = { rRecords: ReverseRecord[]; profiles: Record<string, TzProfile> };
export type DelegateVM = { rRecord: ReverseRecord; profile: TzProfile; delegateInfo: LeaderboardDelegate };

const PageSize = 40;

export class DelegatesLeaderboardDataSource
    extends DataSourceBase<DelegatesLeaderboardQuery, DelegateVM[], DelegatesLeaderboardQueryVariables, ExtraDelegateInfo>
    implements DataSource<DelegateVM>
{
    private rrRecordDictionary: Record<string, ReverseRecord>;
    private tzProfilesDictionary: Record<string, TzProfile>;

    constructor(
        options: DataSourceOptions<DelegatesLeaderboardQuery, DelegatesLeaderboardQueryVariables>,
        private tzProfilesQuery: TzprofilesService,
        private reverseRecordListGQL: ReverseRecordListGQL
    ) {
        super({
            ...options,
            defaultVariables: { limit: PageSize },
        });

        this.stopQuery.subscribe(() => {
            this.rrRecordDictionary = {};
        });
    }

    protected getExtraData(data: DelegatesLeaderboardQuery): Observable<ExtraDelegateInfo> | null {
        if (!data) {
            return null;
        }

        const visibleDataSet = data.delegates.slice(0, this.pagination.currentPagingState.limit + this.pagination.currentPagingState.offset);
        const lastPageDelegators = visibleDataSet.slice(-PageSize);
        const addresses = lastPageDelegators.map(d => d.address);

        return combineLatest([this.loadReverseRecords(addresses), this.tzProfilesQuery.getProfiles(addresses)]).pipe(
            map(([rrList, tzProfiles]) => {
                return {
                    rRecords: rrList,
                    profiles: tzProfiles,
                };
            }),
            tap(({ rRecords, profiles }) => {
                const rrDict = keyBy(rRecords, 'address');

                this.rrRecordDictionary = assign(this.rrRecordDictionary, rrDict);
                this.tzProfilesDictionary = assign(this.tzProfilesDictionary, profiles);
            })
        );
    }

    protected transformData(data: DelegatesLeaderboardQuery): ProcessedData<DelegateVM[]> {
        return {
            data: data.delegates.slice(0, this.pagination.currentPagingState.limit + this.pagination.currentPagingState.offset).map(d => ({
                delegateInfo: {
                    __typename: d.__typename,
                    voting_power: d.voting_power,
                    address: d.address,
                    public: d.public,
                    delegatorCount: d.currentDelegations_aggregate.aggregate?.count ?? 0,
                },
                profile: this.tzProfilesDictionary[d.address] ?? EmptyProfile,
                rRecord: this.rrRecordDictionary[d.address] ?? this.partialReverseRecord(d.address),
            })),
            isEmpty: !data.delegates.length,
            totalCount: data.delegates.length,
        };
    }

    protected createPagination(): DataSourcePagination<DelegatesLeaderboardQueryVariables> {
        return new GovernanceDataSourcePagination<DelegatesLeaderboardQueryVariables>({ limit: PageSize });
    }

    private loadReverseRecords(addresses: string[]) {
        return this.reverseRecordListGQL
            .fetch({ where: { address: { in: addresses } }, first: PageSize })
            .pipe(map(({ data }) => data.reverseRecords.edges.map(e => e.node)));
    }

    private partialReverseRecord(address: string): ReverseRecord {
        return { id: '', address: address, owner: address };
    }
}
