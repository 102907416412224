<ng-container *transloco="let t">
    <div class="d-flex mb-2">
        <div class="w-100">
            <h1>{{ t('delegates-leaderboard.page-title') }}</h1>
        </div>
    </div>

    <td-delegates-board-filter (filterChange)="filterChanged($event)"></td-delegates-board-filter>

    <ng-container *ngIf="vm$ | async as delegateList">
        <div class="empty-message p-3 w-100 text-center smooth-shadow" *ngIf="!delegateList.length">{{ t('delegates-leaderboard.no-delegates') }}</div>

        <div class="delegate-list mt-1 flex-grow-1" infiniteScroll (scrolled)="scrolled()">
            <a [routerLink]="['/address', delegate.rRecord.address, 'delegators']" *ngFor="let delegate of delegateList">
                <td-delegate-item
                    [address]="delegate.rRecord.address"
                    [reverseRecord]="delegate.rRecord"
                    [tzProfile]="delegate.profile"
                    [votingPower]="delegate.delegateInfo.voting_power"
                    [delegatorCount]="delegate.delegateInfo.delegatorCount"
                    [isPublic]="delegate.delegateInfo.public"
                    [height]="'lg'"
                ></td-delegate-item>
            </a>
        </div>
    </ng-container>
    <div *ngIf="dataSource.loading$ | async" class="w-100 mt-3 d-flex justify-content-center">
        <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>
</ng-container>
