<ng-container *transloco="let t">
    <ng-container *ngIf="tzData$ | async; let tzData">
        <ng-container *ngIf="tzData.showAllListings; else addressOffers">
            <h1>{{ t('buy-offers-page.all.title') }}</h1>

            <td-buy-offer-filter
                [tzDomainsClient]="tzData.tzDomainsClient"
                [preferredOfferType]="tzData.preferredOfferType"
                [showOfferTypeFilter]="false"
                (filterChange)="onFilterChange($event)"
                [hidden]="selectedOffers.length"
            ></td-buy-offer-filter>

            <ng-container *ngIf="selectedOffers.length; then selectedItemsTemplate"> </ng-container>

            <td-buy-offer-list
                [filter]="filter"
                [wallet]="tzData.wallet"
                [canShowYouBadge]="tzData.showAllListings"
                [loadAddressSpecificData]="false"
                [canSelectOffers]="true"
                (offerSelectedChange)="onOfferSelected($event)"
            ></td-buy-offer-list>
        </ng-container>

        <ng-template #addressOffers>
            <td-address-page scope="buy-offers" #page>
                <h1 my-title>{{ t('buy-offers-page.title-my') }}</h1>
                <span other-subtitle>{{ t('buy-offers-page.title-offers-by') }}</span>

                <td-buy-offer-filter
                    [tzDomainsClient]="tzData.tzDomainsClient"
                    [preferredOfferType]="tzData.preferredOfferType"
                    (filterChange)="onFilterChange($event)"
                    [hidden]="selectedOffers.length"
                ></td-buy-offer-filter>

                <ng-container *ngIf="selectedOffers.length; then selectedItemsTemplate"> </ng-container>

                <td-buy-offer-list
                    [filter]="filter"
                    [wallet]="page.wallet"
                    [address]="page.address"
                    [canSelectOffers]="true"
                    (offerSelectedChange)="onOfferSelected($event)"
                ></td-buy-offer-list>
            </td-address-page>
        </ng-template>

        <ng-template #selectedItemsTemplate>
            <div class="mb-4 me-2 d-flex justify-content-end">
                <div class="flex-grow-1">
                    <button mat-flat-button color="default" (click)="toggleSelectAll()">
                        {{ allSelected ? t('buy-offers-page.deselect-all') : t('buy-offers-page.select-all') }}
                    </button>
                </div>
                <button mat-flat-button color="primary" (click)="withdrawSelected()">
                    {{ t('bulk-remove-buy-offer-form.withdraw-selected', { count: selectedOffers.length }) }}
                </button>
            </div>
        </ng-template>
    </ng-container>
</ng-container>
