import { Component } from '@angular/core';

import { EditorComponentBase } from './editor-component-base';
import { TdValidators } from '../../../utils/form-validators';
import { safeJsonParse } from '../../../utils/convert';

@Component({
    selector: 'td-json',
    templateUrl: './json.component.html',
    styleUrls: ['./json.component.scss'],
})
export class JsonComponent extends EditorComponentBase<any> {
    protected decode(rawValue: string) {
        const decoded = super.decode(rawValue);

        return decoded ? JSON.stringify(decoded) : null;
    }

    protected encode(value: any) {
        const json = safeJsonParse(value);

        return json ? super.encode(json) : null;
    }

    protected createValidators() {
        return [TdValidators.json];
    }
}
