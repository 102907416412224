import dayjs from 'dayjs';
import BigNumber from 'bignumber.js';
import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bpchar: string;
  float8: BigNumber;
  interval: any;
  jsonb: any;
  numeric: any;
  timestamp: dayjs.Dayjs;
  uuid: string;
};

/** columns and relationships of "indexer.action" */
export type Action = {
  __typename?: 'Action';
  actionType: Scalars['String'];
  amount?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  block: Block;
  blockHash: Scalars['bpchar'];
  fromAddress?: Maybe<Scalars['bpchar']>;
  operationGroupHash: Scalars['bpchar'];
  order: Scalars['Int'];
  ownerAddress?: Maybe<Scalars['bpchar']>;
  toAddress?: Maybe<Scalars['bpchar']>;
  /** An object relationship */
  token?: Maybe<Token>;
  tokenContractAddress: Scalars['bpchar'];
  tokenId: Scalars['numeric'];
};

/** aggregated selection of "indexer.action" */
export type Action_Aggregate = {
  __typename?: 'Action_aggregate';
  aggregate?: Maybe<Action_Aggregate_Fields>;
  nodes: Array<Action>;
};

/** aggregate fields of "indexer.action" */
export type Action_Aggregate_Fields = {
  __typename?: 'Action_aggregate_fields';
  avg?: Maybe<Action_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Action_Max_Fields>;
  min?: Maybe<Action_Min_Fields>;
  stddev?: Maybe<Action_Stddev_Fields>;
  stddev_pop?: Maybe<Action_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Action_Stddev_Samp_Fields>;
  sum?: Maybe<Action_Sum_Fields>;
  var_pop?: Maybe<Action_Var_Pop_Fields>;
  var_samp?: Maybe<Action_Var_Samp_Fields>;
  variance?: Maybe<Action_Variance_Fields>;
};


/** aggregate fields of "indexer.action" */
export type Action_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Action_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Action_Avg_Fields = {
  __typename?: 'Action_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  tokenId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "indexer.action". All fields are combined with a logical 'AND'. */
export type Action_Bool_Exp = {
  _and?: InputMaybe<Array<Action_Bool_Exp>>;
  _not?: InputMaybe<Action_Bool_Exp>;
  _or?: InputMaybe<Array<Action_Bool_Exp>>;
  actionType?: InputMaybe<String_Comparison_Exp>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  block?: InputMaybe<Block_Bool_Exp>;
  blockHash?: InputMaybe<Bpchar_Comparison_Exp>;
  fromAddress?: InputMaybe<Bpchar_Comparison_Exp>;
  operationGroupHash?: InputMaybe<Bpchar_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  ownerAddress?: InputMaybe<Bpchar_Comparison_Exp>;
  toAddress?: InputMaybe<Bpchar_Comparison_Exp>;
  token?: InputMaybe<Token_Bool_Exp>;
  tokenContractAddress?: InputMaybe<Bpchar_Comparison_Exp>;
  tokenId?: InputMaybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type Action_Max_Fields = {
  __typename?: 'Action_max_fields';
  actionType?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['numeric']>;
  blockHash?: Maybe<Scalars['bpchar']>;
  fromAddress?: Maybe<Scalars['bpchar']>;
  operationGroupHash?: Maybe<Scalars['bpchar']>;
  order?: Maybe<Scalars['Int']>;
  ownerAddress?: Maybe<Scalars['bpchar']>;
  toAddress?: Maybe<Scalars['bpchar']>;
  tokenContractAddress?: Maybe<Scalars['bpchar']>;
  tokenId?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Action_Min_Fields = {
  __typename?: 'Action_min_fields';
  actionType?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['numeric']>;
  blockHash?: Maybe<Scalars['bpchar']>;
  fromAddress?: Maybe<Scalars['bpchar']>;
  operationGroupHash?: Maybe<Scalars['bpchar']>;
  order?: Maybe<Scalars['Int']>;
  ownerAddress?: Maybe<Scalars['bpchar']>;
  toAddress?: Maybe<Scalars['bpchar']>;
  tokenContractAddress?: Maybe<Scalars['bpchar']>;
  tokenId?: Maybe<Scalars['numeric']>;
};

/** Ordering options when selecting data from "indexer.action". */
export type Action_Order_By = {
  actionType?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  block?: InputMaybe<Block_Order_By>;
  blockHash?: InputMaybe<Order_By>;
  fromAddress?: InputMaybe<Order_By>;
  operationGroupHash?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  ownerAddress?: InputMaybe<Order_By>;
  toAddress?: InputMaybe<Order_By>;
  token?: InputMaybe<Token_Order_By>;
  tokenContractAddress?: InputMaybe<Order_By>;
  tokenId?: InputMaybe<Order_By>;
};

/** select columns of table "indexer.action" */
export enum Action_Select_Column {
  /** column name */
  ActionType = 'actionType',
  /** column name */
  Amount = 'amount',
  /** column name */
  BlockHash = 'blockHash',
  /** column name */
  FromAddress = 'fromAddress',
  /** column name */
  OperationGroupHash = 'operationGroupHash',
  /** column name */
  Order = 'order',
  /** column name */
  OwnerAddress = 'ownerAddress',
  /** column name */
  ToAddress = 'toAddress',
  /** column name */
  TokenContractAddress = 'tokenContractAddress',
  /** column name */
  TokenId = 'tokenId'
}

/** aggregate stddev on columns */
export type Action_Stddev_Fields = {
  __typename?: 'Action_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  tokenId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Action_Stddev_Pop_Fields = {
  __typename?: 'Action_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  tokenId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Action_Stddev_Samp_Fields = {
  __typename?: 'Action_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  tokenId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "Action" */
export type Action_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Action_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Action_Stream_Cursor_Value_Input = {
  actionType?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['numeric']>;
  blockHash?: InputMaybe<Scalars['bpchar']>;
  fromAddress?: InputMaybe<Scalars['bpchar']>;
  operationGroupHash?: InputMaybe<Scalars['bpchar']>;
  order?: InputMaybe<Scalars['Int']>;
  ownerAddress?: InputMaybe<Scalars['bpchar']>;
  toAddress?: InputMaybe<Scalars['bpchar']>;
  tokenContractAddress?: InputMaybe<Scalars['bpchar']>;
  tokenId?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Action_Sum_Fields = {
  __typename?: 'Action_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  order?: Maybe<Scalars['Int']>;
  tokenId?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Action_Var_Pop_Fields = {
  __typename?: 'Action_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  tokenId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Action_Var_Samp_Fields = {
  __typename?: 'Action_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  tokenId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Action_Variance_Fields = {
  __typename?: 'Action_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  tokenId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "indexer.balance" */
export type Balance = {
  __typename?: 'Balance';
  amount: Scalars['numeric'];
  operationGroupHash: Scalars['bpchar'];
  ownerAddress: Scalars['bpchar'];
  /** An object relationship */
  token?: Maybe<Token>;
  tokenContractAddress: Scalars['bpchar'];
  tokenId: Scalars['numeric'];
  /** An object relationship */
  validFromBlock: Block;
  validFromBlockHash: Scalars['bpchar'];
  /** An object relationship */
  validUntilBlock?: Maybe<Block>;
  validUntilBlockHash?: Maybe<Scalars['bpchar']>;
};

/** aggregated selection of "indexer.balance" */
export type Balance_Aggregate = {
  __typename?: 'Balance_aggregate';
  aggregate?: Maybe<Balance_Aggregate_Fields>;
  nodes: Array<Balance>;
};

/** aggregate fields of "indexer.balance" */
export type Balance_Aggregate_Fields = {
  __typename?: 'Balance_aggregate_fields';
  avg?: Maybe<Balance_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Balance_Max_Fields>;
  min?: Maybe<Balance_Min_Fields>;
  stddev?: Maybe<Balance_Stddev_Fields>;
  stddev_pop?: Maybe<Balance_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Balance_Stddev_Samp_Fields>;
  sum?: Maybe<Balance_Sum_Fields>;
  var_pop?: Maybe<Balance_Var_Pop_Fields>;
  var_samp?: Maybe<Balance_Var_Samp_Fields>;
  variance?: Maybe<Balance_Variance_Fields>;
};


/** aggregate fields of "indexer.balance" */
export type Balance_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Balance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Balance_Avg_Fields = {
  __typename?: 'Balance_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  tokenId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "indexer.balance". All fields are combined with a logical 'AND'. */
export type Balance_Bool_Exp = {
  _and?: InputMaybe<Array<Balance_Bool_Exp>>;
  _not?: InputMaybe<Balance_Bool_Exp>;
  _or?: InputMaybe<Array<Balance_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  operationGroupHash?: InputMaybe<Bpchar_Comparison_Exp>;
  ownerAddress?: InputMaybe<Bpchar_Comparison_Exp>;
  token?: InputMaybe<Token_Bool_Exp>;
  tokenContractAddress?: InputMaybe<Bpchar_Comparison_Exp>;
  tokenId?: InputMaybe<Numeric_Comparison_Exp>;
  validFromBlock?: InputMaybe<Block_Bool_Exp>;
  validFromBlockHash?: InputMaybe<Bpchar_Comparison_Exp>;
  validUntilBlock?: InputMaybe<Block_Bool_Exp>;
  validUntilBlockHash?: InputMaybe<Bpchar_Comparison_Exp>;
};

/** aggregate max on columns */
export type Balance_Max_Fields = {
  __typename?: 'Balance_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  operationGroupHash?: Maybe<Scalars['bpchar']>;
  ownerAddress?: Maybe<Scalars['bpchar']>;
  tokenContractAddress?: Maybe<Scalars['bpchar']>;
  tokenId?: Maybe<Scalars['numeric']>;
  validFromBlockHash?: Maybe<Scalars['bpchar']>;
  validUntilBlockHash?: Maybe<Scalars['bpchar']>;
};

/** aggregate min on columns */
export type Balance_Min_Fields = {
  __typename?: 'Balance_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  operationGroupHash?: Maybe<Scalars['bpchar']>;
  ownerAddress?: Maybe<Scalars['bpchar']>;
  tokenContractAddress?: Maybe<Scalars['bpchar']>;
  tokenId?: Maybe<Scalars['numeric']>;
  validFromBlockHash?: Maybe<Scalars['bpchar']>;
  validUntilBlockHash?: Maybe<Scalars['bpchar']>;
};

/** Ordering options when selecting data from "indexer.balance". */
export type Balance_Order_By = {
  amount?: InputMaybe<Order_By>;
  operationGroupHash?: InputMaybe<Order_By>;
  ownerAddress?: InputMaybe<Order_By>;
  token?: InputMaybe<Token_Order_By>;
  tokenContractAddress?: InputMaybe<Order_By>;
  tokenId?: InputMaybe<Order_By>;
  validFromBlock?: InputMaybe<Block_Order_By>;
  validFromBlockHash?: InputMaybe<Order_By>;
  validUntilBlock?: InputMaybe<Block_Order_By>;
  validUntilBlockHash?: InputMaybe<Order_By>;
};

/** select columns of table "indexer.balance" */
export enum Balance_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  OperationGroupHash = 'operationGroupHash',
  /** column name */
  OwnerAddress = 'ownerAddress',
  /** column name */
  TokenContractAddress = 'tokenContractAddress',
  /** column name */
  TokenId = 'tokenId',
  /** column name */
  ValidFromBlockHash = 'validFromBlockHash',
  /** column name */
  ValidUntilBlockHash = 'validUntilBlockHash'
}

/** aggregate stddev on columns */
export type Balance_Stddev_Fields = {
  __typename?: 'Balance_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  tokenId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Balance_Stddev_Pop_Fields = {
  __typename?: 'Balance_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  tokenId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Balance_Stddev_Samp_Fields = {
  __typename?: 'Balance_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  tokenId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "Balance" */
export type Balance_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Balance_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Balance_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  operationGroupHash?: InputMaybe<Scalars['bpchar']>;
  ownerAddress?: InputMaybe<Scalars['bpchar']>;
  tokenContractAddress?: InputMaybe<Scalars['bpchar']>;
  tokenId?: InputMaybe<Scalars['numeric']>;
  validFromBlockHash?: InputMaybe<Scalars['bpchar']>;
  validUntilBlockHash?: InputMaybe<Scalars['bpchar']>;
};

/** aggregate sum on columns */
export type Balance_Sum_Fields = {
  __typename?: 'Balance_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  tokenId?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Balance_Var_Pop_Fields = {
  __typename?: 'Balance_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  tokenId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Balance_Var_Samp_Fields = {
  __typename?: 'Balance_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  tokenId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Balance_Variance_Fields = {
  __typename?: 'Balance_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  tokenId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "indexer.block" */
export type Block = {
  __typename?: 'Block';
  hash: Scalars['bpchar'];
  level: Scalars['Int'];
  predecessor: Scalars['bpchar'];
  timestamp: Scalars['timestamp'];
};

/** aggregated selection of "indexer.block" */
export type Block_Aggregate = {
  __typename?: 'Block_aggregate';
  aggregate?: Maybe<Block_Aggregate_Fields>;
  nodes: Array<Block>;
};

/** aggregate fields of "indexer.block" */
export type Block_Aggregate_Fields = {
  __typename?: 'Block_aggregate_fields';
  avg?: Maybe<Block_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Block_Max_Fields>;
  min?: Maybe<Block_Min_Fields>;
  stddev?: Maybe<Block_Stddev_Fields>;
  stddev_pop?: Maybe<Block_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Block_Stddev_Samp_Fields>;
  sum?: Maybe<Block_Sum_Fields>;
  var_pop?: Maybe<Block_Var_Pop_Fields>;
  var_samp?: Maybe<Block_Var_Samp_Fields>;
  variance?: Maybe<Block_Variance_Fields>;
};


/** aggregate fields of "indexer.block" */
export type Block_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Block_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Block_Avg_Fields = {
  __typename?: 'Block_avg_fields';
  level?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "indexer.block". All fields are combined with a logical 'AND'. */
export type Block_Bool_Exp = {
  _and?: InputMaybe<Array<Block_Bool_Exp>>;
  _not?: InputMaybe<Block_Bool_Exp>;
  _or?: InputMaybe<Array<Block_Bool_Exp>>;
  hash?: InputMaybe<Bpchar_Comparison_Exp>;
  level?: InputMaybe<Int_Comparison_Exp>;
  predecessor?: InputMaybe<Bpchar_Comparison_Exp>;
  timestamp?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** aggregate max on columns */
export type Block_Max_Fields = {
  __typename?: 'Block_max_fields';
  hash?: Maybe<Scalars['bpchar']>;
  level?: Maybe<Scalars['Int']>;
  predecessor?: Maybe<Scalars['bpchar']>;
  timestamp?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Block_Min_Fields = {
  __typename?: 'Block_min_fields';
  hash?: Maybe<Scalars['bpchar']>;
  level?: Maybe<Scalars['Int']>;
  predecessor?: Maybe<Scalars['bpchar']>;
  timestamp?: Maybe<Scalars['timestamp']>;
};

/** Ordering options when selecting data from "indexer.block". */
export type Block_Order_By = {
  hash?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  predecessor?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
};

/** select columns of table "indexer.block" */
export enum Block_Select_Column {
  /** column name */
  Hash = 'hash',
  /** column name */
  Level = 'level',
  /** column name */
  Predecessor = 'predecessor',
  /** column name */
  Timestamp = 'timestamp'
}

/** aggregate stddev on columns */
export type Block_Stddev_Fields = {
  __typename?: 'Block_stddev_fields';
  level?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Block_Stddev_Pop_Fields = {
  __typename?: 'Block_stddev_pop_fields';
  level?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Block_Stddev_Samp_Fields = {
  __typename?: 'Block_stddev_samp_fields';
  level?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "Block" */
export type Block_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Block_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Block_Stream_Cursor_Value_Input = {
  hash?: InputMaybe<Scalars['bpchar']>;
  level?: InputMaybe<Scalars['Int']>;
  predecessor?: InputMaybe<Scalars['bpchar']>;
  timestamp?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Block_Sum_Fields = {
  __typename?: 'Block_sum_fields';
  level?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Block_Var_Pop_Fields = {
  __typename?: 'Block_var_pop_fields';
  level?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Block_Var_Samp_Fields = {
  __typename?: 'Block_var_samp_fields';
  level?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Block_Variance_Fields = {
  __typename?: 'Block_variance_fields';
  level?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** columns and relationships of "indexer.contract" */
export type Contract = {
  __typename?: 'Contract';
  address: Scalars['bpchar'];
  /** An array relationship */
  contractFeatures: Array<ContractFeature>;
  /** An aggregate relationship */
  contractFeatures_aggregate: ContractFeature_Aggregate;
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  firstBlock: Block;
  firstBlockHash: Scalars['bpchar'];
  firstOperationGroupHash: Scalars['bpchar'];
  metadataState: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  tokens: Array<Token>;
  /** An aggregate relationship */
  tokens_aggregate: Token_Aggregate;
};


/** columns and relationships of "indexer.contract" */
export type ContractContractFeaturesArgs = {
  distinct_on?: InputMaybe<Array<ContractFeature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractFeature_Order_By>>;
  where?: InputMaybe<ContractFeature_Bool_Exp>;
};


/** columns and relationships of "indexer.contract" */
export type ContractContractFeatures_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContractFeature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractFeature_Order_By>>;
  where?: InputMaybe<ContractFeature_Bool_Exp>;
};


/** columns and relationships of "indexer.contract" */
export type ContractTokensArgs = {
  distinct_on?: InputMaybe<Array<Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Token_Order_By>>;
  where?: InputMaybe<Token_Bool_Exp>;
};


/** columns and relationships of "indexer.contract" */
export type ContractTokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Token_Order_By>>;
  where?: InputMaybe<Token_Bool_Exp>;
};

/** columns and relationships of "indexer.contract_feature" */
export type ContractFeature = {
  __typename?: 'ContractFeature';
  /** An object relationship */
  contract: Contract;
  contractAddress: Scalars['bpchar'];
  feature: Scalars['String'];
};

/** aggregated selection of "indexer.contract_feature" */
export type ContractFeature_Aggregate = {
  __typename?: 'ContractFeature_aggregate';
  aggregate?: Maybe<ContractFeature_Aggregate_Fields>;
  nodes: Array<ContractFeature>;
};

export type ContractFeature_Aggregate_Bool_Exp = {
  count?: InputMaybe<ContractFeature_Aggregate_Bool_Exp_Count>;
};

export type ContractFeature_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ContractFeature_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ContractFeature_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "indexer.contract_feature" */
export type ContractFeature_Aggregate_Fields = {
  __typename?: 'ContractFeature_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ContractFeature_Max_Fields>;
  min?: Maybe<ContractFeature_Min_Fields>;
};


/** aggregate fields of "indexer.contract_feature" */
export type ContractFeature_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ContractFeature_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "indexer.contract_feature" */
export type ContractFeature_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ContractFeature_Max_Order_By>;
  min?: InputMaybe<ContractFeature_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "indexer.contract_feature". All fields are combined with a logical 'AND'. */
export type ContractFeature_Bool_Exp = {
  _and?: InputMaybe<Array<ContractFeature_Bool_Exp>>;
  _not?: InputMaybe<ContractFeature_Bool_Exp>;
  _or?: InputMaybe<Array<ContractFeature_Bool_Exp>>;
  contract?: InputMaybe<Contract_Bool_Exp>;
  contractAddress?: InputMaybe<Bpchar_Comparison_Exp>;
  feature?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type ContractFeature_Max_Fields = {
  __typename?: 'ContractFeature_max_fields';
  contractAddress?: Maybe<Scalars['bpchar']>;
  feature?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "indexer.contract_feature" */
export type ContractFeature_Max_Order_By = {
  contractAddress?: InputMaybe<Order_By>;
  feature?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ContractFeature_Min_Fields = {
  __typename?: 'ContractFeature_min_fields';
  contractAddress?: Maybe<Scalars['bpchar']>;
  feature?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "indexer.contract_feature" */
export type ContractFeature_Min_Order_By = {
  contractAddress?: InputMaybe<Order_By>;
  feature?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "indexer.contract_feature". */
export type ContractFeature_Order_By = {
  contract?: InputMaybe<Contract_Order_By>;
  contractAddress?: InputMaybe<Order_By>;
  feature?: InputMaybe<Order_By>;
};

/** select columns of table "indexer.contract_feature" */
export enum ContractFeature_Select_Column {
  /** column name */
  ContractAddress = 'contractAddress',
  /** column name */
  Feature = 'feature'
}

/** Streaming cursor of the table "ContractFeature" */
export type ContractFeature_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContractFeature_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContractFeature_Stream_Cursor_Value_Input = {
  contractAddress?: InputMaybe<Scalars['bpchar']>;
  feature?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "indexer.contract" */
export type Contract_Aggregate = {
  __typename?: 'Contract_aggregate';
  aggregate?: Maybe<Contract_Aggregate_Fields>;
  nodes: Array<Contract>;
};

/** aggregate fields of "indexer.contract" */
export type Contract_Aggregate_Fields = {
  __typename?: 'Contract_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Contract_Max_Fields>;
  min?: Maybe<Contract_Min_Fields>;
};


/** aggregate fields of "indexer.contract" */
export type Contract_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contract_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "indexer.contract". All fields are combined with a logical 'AND'. */
export type Contract_Bool_Exp = {
  _and?: InputMaybe<Array<Contract_Bool_Exp>>;
  _not?: InputMaybe<Contract_Bool_Exp>;
  _or?: InputMaybe<Array<Contract_Bool_Exp>>;
  address?: InputMaybe<Bpchar_Comparison_Exp>;
  contractFeatures?: InputMaybe<ContractFeature_Bool_Exp>;
  contractFeatures_aggregate?: InputMaybe<ContractFeature_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  firstBlock?: InputMaybe<Block_Bool_Exp>;
  firstBlockHash?: InputMaybe<Bpchar_Comparison_Exp>;
  firstOperationGroupHash?: InputMaybe<Bpchar_Comparison_Exp>;
  metadataState?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  tokens?: InputMaybe<Token_Bool_Exp>;
  tokens_aggregate?: InputMaybe<Token_Aggregate_Bool_Exp>;
};

/** aggregate max on columns */
export type Contract_Max_Fields = {
  __typename?: 'Contract_max_fields';
  address?: Maybe<Scalars['bpchar']>;
  description?: Maybe<Scalars['String']>;
  firstBlockHash?: Maybe<Scalars['bpchar']>;
  firstOperationGroupHash?: Maybe<Scalars['bpchar']>;
  metadataState?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Contract_Min_Fields = {
  __typename?: 'Contract_min_fields';
  address?: Maybe<Scalars['bpchar']>;
  description?: Maybe<Scalars['String']>;
  firstBlockHash?: Maybe<Scalars['bpchar']>;
  firstOperationGroupHash?: Maybe<Scalars['bpchar']>;
  metadataState?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "indexer.contract". */
export type Contract_Order_By = {
  address?: InputMaybe<Order_By>;
  contractFeatures_aggregate?: InputMaybe<ContractFeature_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  firstBlock?: InputMaybe<Block_Order_By>;
  firstBlockHash?: InputMaybe<Order_By>;
  firstOperationGroupHash?: InputMaybe<Order_By>;
  metadataState?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tokens_aggregate?: InputMaybe<Token_Aggregate_Order_By>;
};

/** select columns of table "indexer.contract" */
export enum Contract_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Description = 'description',
  /** column name */
  FirstBlockHash = 'firstBlockHash',
  /** column name */
  FirstOperationGroupHash = 'firstOperationGroupHash',
  /** column name */
  MetadataState = 'metadataState',
  /** column name */
  Name = 'name'
}

/** Streaming cursor of the table "Contract" */
export type Contract_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contract_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contract_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['bpchar']>;
  description?: InputMaybe<Scalars['String']>;
  firstBlockHash?: InputMaybe<Scalars['bpchar']>;
  firstOperationGroupHash?: InputMaybe<Scalars['bpchar']>;
  metadataState?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "indexer.current_balance" */
export type CurrentBalance = {
  __typename?: 'CurrentBalance';
  amount?: Maybe<Scalars['numeric']>;
  operationGroupHash?: Maybe<Scalars['bpchar']>;
  ownerAddress?: Maybe<Scalars['bpchar']>;
  /** An object relationship */
  token?: Maybe<Token>;
  tokenContractAddress?: Maybe<Scalars['bpchar']>;
  tokenId?: Maybe<Scalars['numeric']>;
  validFromBlockHash?: Maybe<Scalars['bpchar']>;
  /** An object relationship */
  valid_from_block?: Maybe<Block>;
};

/** aggregated selection of "indexer.current_balance" */
export type CurrentBalance_Aggregate = {
  __typename?: 'CurrentBalance_aggregate';
  aggregate?: Maybe<CurrentBalance_Aggregate_Fields>;
  nodes: Array<CurrentBalance>;
};

/** aggregate fields of "indexer.current_balance" */
export type CurrentBalance_Aggregate_Fields = {
  __typename?: 'CurrentBalance_aggregate_fields';
  avg?: Maybe<CurrentBalance_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CurrentBalance_Max_Fields>;
  min?: Maybe<CurrentBalance_Min_Fields>;
  stddev?: Maybe<CurrentBalance_Stddev_Fields>;
  stddev_pop?: Maybe<CurrentBalance_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CurrentBalance_Stddev_Samp_Fields>;
  sum?: Maybe<CurrentBalance_Sum_Fields>;
  var_pop?: Maybe<CurrentBalance_Var_Pop_Fields>;
  var_samp?: Maybe<CurrentBalance_Var_Samp_Fields>;
  variance?: Maybe<CurrentBalance_Variance_Fields>;
};


/** aggregate fields of "indexer.current_balance" */
export type CurrentBalance_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CurrentBalance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CurrentBalance_Avg_Fields = {
  __typename?: 'CurrentBalance_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  tokenId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "indexer.current_balance". All fields are combined with a logical 'AND'. */
export type CurrentBalance_Bool_Exp = {
  _and?: InputMaybe<Array<CurrentBalance_Bool_Exp>>;
  _not?: InputMaybe<CurrentBalance_Bool_Exp>;
  _or?: InputMaybe<Array<CurrentBalance_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  operationGroupHash?: InputMaybe<Bpchar_Comparison_Exp>;
  ownerAddress?: InputMaybe<Bpchar_Comparison_Exp>;
  token?: InputMaybe<Token_Bool_Exp>;
  tokenContractAddress?: InputMaybe<Bpchar_Comparison_Exp>;
  tokenId?: InputMaybe<Numeric_Comparison_Exp>;
  validFromBlockHash?: InputMaybe<Bpchar_Comparison_Exp>;
  valid_from_block?: InputMaybe<Block_Bool_Exp>;
};

/** aggregate max on columns */
export type CurrentBalance_Max_Fields = {
  __typename?: 'CurrentBalance_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  operationGroupHash?: Maybe<Scalars['bpchar']>;
  ownerAddress?: Maybe<Scalars['bpchar']>;
  tokenContractAddress?: Maybe<Scalars['bpchar']>;
  tokenId?: Maybe<Scalars['numeric']>;
  validFromBlockHash?: Maybe<Scalars['bpchar']>;
};

/** aggregate min on columns */
export type CurrentBalance_Min_Fields = {
  __typename?: 'CurrentBalance_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  operationGroupHash?: Maybe<Scalars['bpchar']>;
  ownerAddress?: Maybe<Scalars['bpchar']>;
  tokenContractAddress?: Maybe<Scalars['bpchar']>;
  tokenId?: Maybe<Scalars['numeric']>;
  validFromBlockHash?: Maybe<Scalars['bpchar']>;
};

/** Ordering options when selecting data from "indexer.current_balance". */
export type CurrentBalance_Order_By = {
  amount?: InputMaybe<Order_By>;
  operationGroupHash?: InputMaybe<Order_By>;
  ownerAddress?: InputMaybe<Order_By>;
  token?: InputMaybe<Token_Order_By>;
  tokenContractAddress?: InputMaybe<Order_By>;
  tokenId?: InputMaybe<Order_By>;
  validFromBlockHash?: InputMaybe<Order_By>;
  valid_from_block?: InputMaybe<Block_Order_By>;
};

/** select columns of table "indexer.current_balance" */
export enum CurrentBalance_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  OperationGroupHash = 'operationGroupHash',
  /** column name */
  OwnerAddress = 'ownerAddress',
  /** column name */
  TokenContractAddress = 'tokenContractAddress',
  /** column name */
  TokenId = 'tokenId',
  /** column name */
  ValidFromBlockHash = 'validFromBlockHash'
}

/** aggregate stddev on columns */
export type CurrentBalance_Stddev_Fields = {
  __typename?: 'CurrentBalance_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  tokenId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CurrentBalance_Stddev_Pop_Fields = {
  __typename?: 'CurrentBalance_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  tokenId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CurrentBalance_Stddev_Samp_Fields = {
  __typename?: 'CurrentBalance_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  tokenId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "CurrentBalance" */
export type CurrentBalance_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CurrentBalance_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CurrentBalance_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  operationGroupHash?: InputMaybe<Scalars['bpchar']>;
  ownerAddress?: InputMaybe<Scalars['bpchar']>;
  tokenContractAddress?: InputMaybe<Scalars['bpchar']>;
  tokenId?: InputMaybe<Scalars['numeric']>;
  validFromBlockHash?: InputMaybe<Scalars['bpchar']>;
};

/** aggregate sum on columns */
export type CurrentBalance_Sum_Fields = {
  __typename?: 'CurrentBalance_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  tokenId?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type CurrentBalance_Var_Pop_Fields = {
  __typename?: 'CurrentBalance_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  tokenId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CurrentBalance_Var_Samp_Fields = {
  __typename?: 'CurrentBalance_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  tokenId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CurrentBalance_Variance_Fields = {
  __typename?: 'CurrentBalance_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  tokenId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** columns and relationships of "indexer.pool_state" */
export type PoolState = {
  __typename?: 'PoolState';
  apr: Scalars['float8'];
  id: Scalars['Int'];
  lastAccumulatedRewardsAt: Scalars['timestamp'];
  poolSize: Scalars['numeric'];
  rewardRate: Scalars['numeric'];
  sharesMinted: Scalars['numeric'];
  /** An object relationship */
  validFromBlock: Block;
  validFromBlockHash: Scalars['bpchar'];
  /** An object relationship */
  validUntilBlock?: Maybe<Block>;
  validUntilBlockHash?: Maybe<Scalars['bpchar']>;
};

/** aggregated selection of "indexer.pool_state" */
export type PoolState_Aggregate = {
  __typename?: 'PoolState_aggregate';
  aggregate?: Maybe<PoolState_Aggregate_Fields>;
  nodes: Array<PoolState>;
};

/** aggregate fields of "indexer.pool_state" */
export type PoolState_Aggregate_Fields = {
  __typename?: 'PoolState_aggregate_fields';
  avg?: Maybe<PoolState_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<PoolState_Max_Fields>;
  min?: Maybe<PoolState_Min_Fields>;
  stddev?: Maybe<PoolState_Stddev_Fields>;
  stddev_pop?: Maybe<PoolState_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PoolState_Stddev_Samp_Fields>;
  sum?: Maybe<PoolState_Sum_Fields>;
  var_pop?: Maybe<PoolState_Var_Pop_Fields>;
  var_samp?: Maybe<PoolState_Var_Samp_Fields>;
  variance?: Maybe<PoolState_Variance_Fields>;
};


/** aggregate fields of "indexer.pool_state" */
export type PoolState_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PoolState_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type PoolState_Avg_Fields = {
  __typename?: 'PoolState_avg_fields';
  apr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  poolSize?: Maybe<Scalars['Float']>;
  rewardRate?: Maybe<Scalars['Float']>;
  sharesMinted?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "indexer.pool_state". All fields are combined with a logical 'AND'. */
export type PoolState_Bool_Exp = {
  _and?: InputMaybe<Array<PoolState_Bool_Exp>>;
  _not?: InputMaybe<PoolState_Bool_Exp>;
  _or?: InputMaybe<Array<PoolState_Bool_Exp>>;
  apr?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lastAccumulatedRewardsAt?: InputMaybe<Timestamp_Comparison_Exp>;
  poolSize?: InputMaybe<Numeric_Comparison_Exp>;
  rewardRate?: InputMaybe<Numeric_Comparison_Exp>;
  sharesMinted?: InputMaybe<Numeric_Comparison_Exp>;
  validFromBlock?: InputMaybe<Block_Bool_Exp>;
  validFromBlockHash?: InputMaybe<Bpchar_Comparison_Exp>;
  validUntilBlock?: InputMaybe<Block_Bool_Exp>;
  validUntilBlockHash?: InputMaybe<Bpchar_Comparison_Exp>;
};

/** aggregate max on columns */
export type PoolState_Max_Fields = {
  __typename?: 'PoolState_max_fields';
  apr?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  lastAccumulatedRewardsAt?: Maybe<Scalars['timestamp']>;
  poolSize?: Maybe<Scalars['numeric']>;
  rewardRate?: Maybe<Scalars['numeric']>;
  sharesMinted?: Maybe<Scalars['numeric']>;
  validFromBlockHash?: Maybe<Scalars['bpchar']>;
  validUntilBlockHash?: Maybe<Scalars['bpchar']>;
};

/** aggregate min on columns */
export type PoolState_Min_Fields = {
  __typename?: 'PoolState_min_fields';
  apr?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  lastAccumulatedRewardsAt?: Maybe<Scalars['timestamp']>;
  poolSize?: Maybe<Scalars['numeric']>;
  rewardRate?: Maybe<Scalars['numeric']>;
  sharesMinted?: Maybe<Scalars['numeric']>;
  validFromBlockHash?: Maybe<Scalars['bpchar']>;
  validUntilBlockHash?: Maybe<Scalars['bpchar']>;
};

/** Ordering options when selecting data from "indexer.pool_state". */
export type PoolState_Order_By = {
  apr?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastAccumulatedRewardsAt?: InputMaybe<Order_By>;
  poolSize?: InputMaybe<Order_By>;
  rewardRate?: InputMaybe<Order_By>;
  sharesMinted?: InputMaybe<Order_By>;
  validFromBlock?: InputMaybe<Block_Order_By>;
  validFromBlockHash?: InputMaybe<Order_By>;
  validUntilBlock?: InputMaybe<Block_Order_By>;
  validUntilBlockHash?: InputMaybe<Order_By>;
};

/** select columns of table "indexer.pool_state" */
export enum PoolState_Select_Column {
  /** column name */
  Apr = 'apr',
  /** column name */
  Id = 'id',
  /** column name */
  LastAccumulatedRewardsAt = 'lastAccumulatedRewardsAt',
  /** column name */
  PoolSize = 'poolSize',
  /** column name */
  RewardRate = 'rewardRate',
  /** column name */
  SharesMinted = 'sharesMinted',
  /** column name */
  ValidFromBlockHash = 'validFromBlockHash',
  /** column name */
  ValidUntilBlockHash = 'validUntilBlockHash'
}

/** aggregate stddev on columns */
export type PoolState_Stddev_Fields = {
  __typename?: 'PoolState_stddev_fields';
  apr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  poolSize?: Maybe<Scalars['Float']>;
  rewardRate?: Maybe<Scalars['Float']>;
  sharesMinted?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type PoolState_Stddev_Pop_Fields = {
  __typename?: 'PoolState_stddev_pop_fields';
  apr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  poolSize?: Maybe<Scalars['Float']>;
  rewardRate?: Maybe<Scalars['Float']>;
  sharesMinted?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type PoolState_Stddev_Samp_Fields = {
  __typename?: 'PoolState_stddev_samp_fields';
  apr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  poolSize?: Maybe<Scalars['Float']>;
  rewardRate?: Maybe<Scalars['Float']>;
  sharesMinted?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "PoolState" */
export type PoolState_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PoolState_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PoolState_Stream_Cursor_Value_Input = {
  apr?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  lastAccumulatedRewardsAt?: InputMaybe<Scalars['timestamp']>;
  poolSize?: InputMaybe<Scalars['numeric']>;
  rewardRate?: InputMaybe<Scalars['numeric']>;
  sharesMinted?: InputMaybe<Scalars['numeric']>;
  validFromBlockHash?: InputMaybe<Scalars['bpchar']>;
  validUntilBlockHash?: InputMaybe<Scalars['bpchar']>;
};

/** aggregate sum on columns */
export type PoolState_Sum_Fields = {
  __typename?: 'PoolState_sum_fields';
  apr?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  poolSize?: Maybe<Scalars['numeric']>;
  rewardRate?: Maybe<Scalars['numeric']>;
  sharesMinted?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type PoolState_Var_Pop_Fields = {
  __typename?: 'PoolState_var_pop_fields';
  apr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  poolSize?: Maybe<Scalars['Float']>;
  rewardRate?: Maybe<Scalars['Float']>;
  sharesMinted?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type PoolState_Var_Samp_Fields = {
  __typename?: 'PoolState_var_samp_fields';
  apr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  poolSize?: Maybe<Scalars['Float']>;
  rewardRate?: Maybe<Scalars['Float']>;
  sharesMinted?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type PoolState_Variance_Fields = {
  __typename?: 'PoolState_variance_fields';
  apr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  poolSize?: Maybe<Scalars['Float']>;
  rewardRate?: Maybe<Scalars['Float']>;
  sharesMinted?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "indexer.token" */
export type Token = {
  __typename?: 'Token';
  /** An object relationship */
  contract: Contract;
  contractAddress: Scalars['bpchar'];
  decimals?: Maybe<Scalars['Int']>;
  /** An object relationship */
  firstBlock: Block;
  firstBlockHash: Scalars['bpchar'];
  firstOperationGroupHash: Scalars['bpchar'];
  id: Scalars['numeric'];
  metadata?: Maybe<Scalars['jsonb']>;
  metadataState: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
};


/** columns and relationships of "indexer.token" */
export type TokenMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "indexer.token" */
export type Token_Aggregate = {
  __typename?: 'Token_aggregate';
  aggregate?: Maybe<Token_Aggregate_Fields>;
  nodes: Array<Token>;
};

export type Token_Aggregate_Bool_Exp = {
  count?: InputMaybe<Token_Aggregate_Bool_Exp_Count>;
};

export type Token_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Token_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Token_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "indexer.token" */
export type Token_Aggregate_Fields = {
  __typename?: 'Token_aggregate_fields';
  avg?: Maybe<Token_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Token_Max_Fields>;
  min?: Maybe<Token_Min_Fields>;
  stddev?: Maybe<Token_Stddev_Fields>;
  stddev_pop?: Maybe<Token_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Token_Stddev_Samp_Fields>;
  sum?: Maybe<Token_Sum_Fields>;
  var_pop?: Maybe<Token_Var_Pop_Fields>;
  var_samp?: Maybe<Token_Var_Samp_Fields>;
  variance?: Maybe<Token_Variance_Fields>;
};


/** aggregate fields of "indexer.token" */
export type Token_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Token_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "indexer.token" */
export type Token_Aggregate_Order_By = {
  avg?: InputMaybe<Token_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Token_Max_Order_By>;
  min?: InputMaybe<Token_Min_Order_By>;
  stddev?: InputMaybe<Token_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Token_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Token_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Token_Sum_Order_By>;
  var_pop?: InputMaybe<Token_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Token_Var_Samp_Order_By>;
  variance?: InputMaybe<Token_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Token_Avg_Fields = {
  __typename?: 'Token_avg_fields';
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "indexer.token" */
export type Token_Avg_Order_By = {
  decimals?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "indexer.token". All fields are combined with a logical 'AND'. */
export type Token_Bool_Exp = {
  _and?: InputMaybe<Array<Token_Bool_Exp>>;
  _not?: InputMaybe<Token_Bool_Exp>;
  _or?: InputMaybe<Array<Token_Bool_Exp>>;
  contract?: InputMaybe<Contract_Bool_Exp>;
  contractAddress?: InputMaybe<Bpchar_Comparison_Exp>;
  decimals?: InputMaybe<Int_Comparison_Exp>;
  firstBlock?: InputMaybe<Block_Bool_Exp>;
  firstBlockHash?: InputMaybe<Bpchar_Comparison_Exp>;
  firstOperationGroupHash?: InputMaybe<Bpchar_Comparison_Exp>;
  id?: InputMaybe<Numeric_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  metadataState?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  symbol?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Token_Max_Fields = {
  __typename?: 'Token_max_fields';
  contractAddress?: Maybe<Scalars['bpchar']>;
  decimals?: Maybe<Scalars['Int']>;
  firstBlockHash?: Maybe<Scalars['bpchar']>;
  firstOperationGroupHash?: Maybe<Scalars['bpchar']>;
  id?: Maybe<Scalars['numeric']>;
  metadataState?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "indexer.token" */
export type Token_Max_Order_By = {
  contractAddress?: InputMaybe<Order_By>;
  decimals?: InputMaybe<Order_By>;
  firstBlockHash?: InputMaybe<Order_By>;
  firstOperationGroupHash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadataState?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  symbol?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Token_Min_Fields = {
  __typename?: 'Token_min_fields';
  contractAddress?: Maybe<Scalars['bpchar']>;
  decimals?: Maybe<Scalars['Int']>;
  firstBlockHash?: Maybe<Scalars['bpchar']>;
  firstOperationGroupHash?: Maybe<Scalars['bpchar']>;
  id?: Maybe<Scalars['numeric']>;
  metadataState?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "indexer.token" */
export type Token_Min_Order_By = {
  contractAddress?: InputMaybe<Order_By>;
  decimals?: InputMaybe<Order_By>;
  firstBlockHash?: InputMaybe<Order_By>;
  firstOperationGroupHash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadataState?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  symbol?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "indexer.token". */
export type Token_Order_By = {
  contract?: InputMaybe<Contract_Order_By>;
  contractAddress?: InputMaybe<Order_By>;
  decimals?: InputMaybe<Order_By>;
  firstBlock?: InputMaybe<Block_Order_By>;
  firstBlockHash?: InputMaybe<Order_By>;
  firstOperationGroupHash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  metadataState?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  symbol?: InputMaybe<Order_By>;
};

/** select columns of table "indexer.token" */
export enum Token_Select_Column {
  /** column name */
  ContractAddress = 'contractAddress',
  /** column name */
  Decimals = 'decimals',
  /** column name */
  FirstBlockHash = 'firstBlockHash',
  /** column name */
  FirstOperationGroupHash = 'firstOperationGroupHash',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MetadataState = 'metadataState',
  /** column name */
  Name = 'name',
  /** column name */
  Symbol = 'symbol'
}

/** aggregate stddev on columns */
export type Token_Stddev_Fields = {
  __typename?: 'Token_stddev_fields';
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "indexer.token" */
export type Token_Stddev_Order_By = {
  decimals?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Token_Stddev_Pop_Fields = {
  __typename?: 'Token_stddev_pop_fields';
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "indexer.token" */
export type Token_Stddev_Pop_Order_By = {
  decimals?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Token_Stddev_Samp_Fields = {
  __typename?: 'Token_stddev_samp_fields';
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "indexer.token" */
export type Token_Stddev_Samp_Order_By = {
  decimals?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Token" */
export type Token_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Token_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Token_Stream_Cursor_Value_Input = {
  contractAddress?: InputMaybe<Scalars['bpchar']>;
  decimals?: InputMaybe<Scalars['Int']>;
  firstBlockHash?: InputMaybe<Scalars['bpchar']>;
  firstOperationGroupHash?: InputMaybe<Scalars['bpchar']>;
  id?: InputMaybe<Scalars['numeric']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  metadataState?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Token_Sum_Fields = {
  __typename?: 'Token_sum_fields';
  decimals?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "indexer.token" */
export type Token_Sum_Order_By = {
  decimals?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Token_Var_Pop_Fields = {
  __typename?: 'Token_var_pop_fields';
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "indexer.token" */
export type Token_Var_Pop_Order_By = {
  decimals?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Token_Var_Samp_Fields = {
  __typename?: 'Token_var_samp_fields';
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "indexer.token" */
export type Token_Var_Samp_Order_By = {
  decimals?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Token_Variance_Fields = {
  __typename?: 'Token_variance_fields';
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "indexer.token" */
export type Token_Variance_Order_By = {
  decimals?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "bpchar". All fields are combined with logical 'AND'. */
export type Bpchar_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bpchar']>;
  _gt?: InputMaybe<Scalars['bpchar']>;
  _gte?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['bpchar']>;
  _in?: InputMaybe<Array<Scalars['bpchar']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['bpchar']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['bpchar']>;
  _lt?: InputMaybe<Scalars['bpchar']>;
  _lte?: InputMaybe<Scalars['bpchar']>;
  _neq?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['bpchar']>;
  _nin?: InputMaybe<Array<Scalars['bpchar']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['bpchar']>;
};

/** columns and relationships of "indexer.claim" */
export type Claims = {
  __typename?: 'claims';
  address: Scalars['bpchar'];
  amount: Scalars['numeric'];
  /** An object relationship */
  block?: Maybe<Block>;
  block_hash?: Maybe<Scalars['bpchar']>;
  claimable_from: Scalars['timestamp'];
  from_airdrop: Scalars['Boolean'];
  id: Scalars['uuid'];
  timestamp: Scalars['timestamp'];
};

/** aggregated selection of "indexer.claim" */
export type Claims_Aggregate = {
  __typename?: 'claims_aggregate';
  aggregate?: Maybe<Claims_Aggregate_Fields>;
  nodes: Array<Claims>;
};

/** aggregate fields of "indexer.claim" */
export type Claims_Aggregate_Fields = {
  __typename?: 'claims_aggregate_fields';
  avg?: Maybe<Claims_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Claims_Max_Fields>;
  min?: Maybe<Claims_Min_Fields>;
  stddev?: Maybe<Claims_Stddev_Fields>;
  stddev_pop?: Maybe<Claims_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Claims_Stddev_Samp_Fields>;
  sum?: Maybe<Claims_Sum_Fields>;
  var_pop?: Maybe<Claims_Var_Pop_Fields>;
  var_samp?: Maybe<Claims_Var_Samp_Fields>;
  variance?: Maybe<Claims_Variance_Fields>;
};


/** aggregate fields of "indexer.claim" */
export type Claims_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Claims_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Claims_Avg_Fields = {
  __typename?: 'claims_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "indexer.claim". All fields are combined with a logical 'AND'. */
export type Claims_Bool_Exp = {
  _and?: InputMaybe<Array<Claims_Bool_Exp>>;
  _not?: InputMaybe<Claims_Bool_Exp>;
  _or?: InputMaybe<Array<Claims_Bool_Exp>>;
  address?: InputMaybe<Bpchar_Comparison_Exp>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  block?: InputMaybe<Block_Bool_Exp>;
  block_hash?: InputMaybe<Bpchar_Comparison_Exp>;
  claimable_from?: InputMaybe<Timestamp_Comparison_Exp>;
  from_airdrop?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  timestamp?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** aggregate max on columns */
export type Claims_Max_Fields = {
  __typename?: 'claims_max_fields';
  address?: Maybe<Scalars['bpchar']>;
  amount?: Maybe<Scalars['numeric']>;
  block_hash?: Maybe<Scalars['bpchar']>;
  claimable_from?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  timestamp?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Claims_Min_Fields = {
  __typename?: 'claims_min_fields';
  address?: Maybe<Scalars['bpchar']>;
  amount?: Maybe<Scalars['numeric']>;
  block_hash?: Maybe<Scalars['bpchar']>;
  claimable_from?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  timestamp?: Maybe<Scalars['timestamp']>;
};

/** Ordering options when selecting data from "indexer.claim". */
export type Claims_Order_By = {
  address?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  block?: InputMaybe<Block_Order_By>;
  block_hash?: InputMaybe<Order_By>;
  claimable_from?: InputMaybe<Order_By>;
  from_airdrop?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
};

/** select columns of table "indexer.claim" */
export enum Claims_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Amount = 'amount',
  /** column name */
  BlockHash = 'block_hash',
  /** column name */
  ClaimableFrom = 'claimable_from',
  /** column name */
  FromAirdrop = 'from_airdrop',
  /** column name */
  Id = 'id',
  /** column name */
  Timestamp = 'timestamp'
}

/** aggregate stddev on columns */
export type Claims_Stddev_Fields = {
  __typename?: 'claims_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Claims_Stddev_Pop_Fields = {
  __typename?: 'claims_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Claims_Stddev_Samp_Fields = {
  __typename?: 'claims_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "claims" */
export type Claims_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Claims_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Claims_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['bpchar']>;
  amount?: InputMaybe<Scalars['numeric']>;
  block_hash?: InputMaybe<Scalars['bpchar']>;
  claimable_from?: InputMaybe<Scalars['timestamp']>;
  from_airdrop?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  timestamp?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Claims_Sum_Fields = {
  __typename?: 'claims_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Claims_Var_Pop_Fields = {
  __typename?: 'claims_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Claims_Var_Samp_Fields = {
  __typename?: 'claims_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Claims_Variance_Fields = {
  __typename?: 'claims_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "indexer.current_delegation" */
export type CurrentDelegations = {
  __typename?: 'currentDelegations';
  amount?: Maybe<Scalars['numeric']>;
  from?: Maybe<Scalars['bpchar']>;
  is_self_delegating?: Maybe<Scalars['Boolean']>;
  to?: Maybe<Scalars['bpchar']>;
  valid_from_block_hash?: Maybe<Scalars['bpchar']>;
};

/** aggregated selection of "indexer.current_delegation" */
export type CurrentDelegations_Aggregate = {
  __typename?: 'currentDelegations_aggregate';
  aggregate?: Maybe<CurrentDelegations_Aggregate_Fields>;
  nodes: Array<CurrentDelegations>;
};

export type CurrentDelegations_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<CurrentDelegations_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<CurrentDelegations_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<CurrentDelegations_Aggregate_Bool_Exp_Count>;
};

export type CurrentDelegations_Aggregate_Bool_Exp_Bool_And = {
  arguments: CurrentDelegations_Select_Column_CurrentDelegations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CurrentDelegations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type CurrentDelegations_Aggregate_Bool_Exp_Bool_Or = {
  arguments: CurrentDelegations_Select_Column_CurrentDelegations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CurrentDelegations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type CurrentDelegations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CurrentDelegations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CurrentDelegations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "indexer.current_delegation" */
export type CurrentDelegations_Aggregate_Fields = {
  __typename?: 'currentDelegations_aggregate_fields';
  avg?: Maybe<CurrentDelegations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CurrentDelegations_Max_Fields>;
  min?: Maybe<CurrentDelegations_Min_Fields>;
  stddev?: Maybe<CurrentDelegations_Stddev_Fields>;
  stddev_pop?: Maybe<CurrentDelegations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CurrentDelegations_Stddev_Samp_Fields>;
  sum?: Maybe<CurrentDelegations_Sum_Fields>;
  var_pop?: Maybe<CurrentDelegations_Var_Pop_Fields>;
  var_samp?: Maybe<CurrentDelegations_Var_Samp_Fields>;
  variance?: Maybe<CurrentDelegations_Variance_Fields>;
};


/** aggregate fields of "indexer.current_delegation" */
export type CurrentDelegations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CurrentDelegations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "indexer.current_delegation" */
export type CurrentDelegations_Aggregate_Order_By = {
  avg?: InputMaybe<CurrentDelegations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CurrentDelegations_Max_Order_By>;
  min?: InputMaybe<CurrentDelegations_Min_Order_By>;
  stddev?: InputMaybe<CurrentDelegations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CurrentDelegations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CurrentDelegations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CurrentDelegations_Sum_Order_By>;
  var_pop?: InputMaybe<CurrentDelegations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CurrentDelegations_Var_Samp_Order_By>;
  variance?: InputMaybe<CurrentDelegations_Variance_Order_By>;
};

/** aggregate avg on columns */
export type CurrentDelegations_Avg_Fields = {
  __typename?: 'currentDelegations_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "indexer.current_delegation" */
export type CurrentDelegations_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "indexer.current_delegation". All fields are combined with a logical 'AND'. */
export type CurrentDelegations_Bool_Exp = {
  _and?: InputMaybe<Array<CurrentDelegations_Bool_Exp>>;
  _not?: InputMaybe<CurrentDelegations_Bool_Exp>;
  _or?: InputMaybe<Array<CurrentDelegations_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  from?: InputMaybe<Bpchar_Comparison_Exp>;
  is_self_delegating?: InputMaybe<Boolean_Comparison_Exp>;
  to?: InputMaybe<Bpchar_Comparison_Exp>;
  valid_from_block_hash?: InputMaybe<Bpchar_Comparison_Exp>;
};

/** aggregate max on columns */
export type CurrentDelegations_Max_Fields = {
  __typename?: 'currentDelegations_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  from?: Maybe<Scalars['bpchar']>;
  to?: Maybe<Scalars['bpchar']>;
  valid_from_block_hash?: Maybe<Scalars['bpchar']>;
};

/** order by max() on columns of table "indexer.current_delegation" */
export type CurrentDelegations_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
  valid_from_block_hash?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CurrentDelegations_Min_Fields = {
  __typename?: 'currentDelegations_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  from?: Maybe<Scalars['bpchar']>;
  to?: Maybe<Scalars['bpchar']>;
  valid_from_block_hash?: Maybe<Scalars['bpchar']>;
};

/** order by min() on columns of table "indexer.current_delegation" */
export type CurrentDelegations_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
  valid_from_block_hash?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "indexer.current_delegation". */
export type CurrentDelegations_Order_By = {
  amount?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  is_self_delegating?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
  valid_from_block_hash?: InputMaybe<Order_By>;
};

/** select columns of table "indexer.current_delegation" */
export enum CurrentDelegations_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  From = 'from',
  /** column name */
  IsSelfDelegating = 'is_self_delegating',
  /** column name */
  To = 'to',
  /** column name */
  ValidFromBlockHash = 'valid_from_block_hash'
}

/** select "currentDelegations_aggregate_bool_exp_bool_and_arguments_columns" columns of table "indexer.current_delegation" */
export enum CurrentDelegations_Select_Column_CurrentDelegations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsSelfDelegating = 'is_self_delegating'
}

/** select "currentDelegations_aggregate_bool_exp_bool_or_arguments_columns" columns of table "indexer.current_delegation" */
export enum CurrentDelegations_Select_Column_CurrentDelegations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsSelfDelegating = 'is_self_delegating'
}

/** aggregate stddev on columns */
export type CurrentDelegations_Stddev_Fields = {
  __typename?: 'currentDelegations_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "indexer.current_delegation" */
export type CurrentDelegations_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CurrentDelegations_Stddev_Pop_Fields = {
  __typename?: 'currentDelegations_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "indexer.current_delegation" */
export type CurrentDelegations_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CurrentDelegations_Stddev_Samp_Fields = {
  __typename?: 'currentDelegations_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "indexer.current_delegation" */
export type CurrentDelegations_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "currentDelegations" */
export type CurrentDelegations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CurrentDelegations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CurrentDelegations_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  from?: InputMaybe<Scalars['bpchar']>;
  is_self_delegating?: InputMaybe<Scalars['Boolean']>;
  to?: InputMaybe<Scalars['bpchar']>;
  valid_from_block_hash?: InputMaybe<Scalars['bpchar']>;
};

/** aggregate sum on columns */
export type CurrentDelegations_Sum_Fields = {
  __typename?: 'currentDelegations_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "indexer.current_delegation" */
export type CurrentDelegations_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type CurrentDelegations_Var_Pop_Fields = {
  __typename?: 'currentDelegations_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "indexer.current_delegation" */
export type CurrentDelegations_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CurrentDelegations_Var_Samp_Fields = {
  __typename?: 'currentDelegations_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "indexer.current_delegation" */
export type CurrentDelegations_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CurrentDelegations_Variance_Fields = {
  __typename?: 'currentDelegations_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "indexer.current_delegation" */
export type CurrentDelegations_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "indexer.delegate" */
export type Delegates = {
  __typename?: 'delegates';
  address: Scalars['bpchar'];
  /** An array relationship */
  currentDelegations: Array<CurrentDelegations>;
  /** An aggregate relationship */
  currentDelegations_aggregate: CurrentDelegations_Aggregate;
  /** An array relationship */
  delegations: Array<Delegations>;
  /** An aggregate relationship */
  delegations_aggregate: Delegations_Aggregate;
  public: Scalars['Boolean'];
  voting_power: Scalars['numeric'];
};


/** columns and relationships of "indexer.delegate" */
export type DelegatesCurrentDelegationsArgs = {
  distinct_on?: InputMaybe<Array<CurrentDelegations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CurrentDelegations_Order_By>>;
  where?: InputMaybe<CurrentDelegations_Bool_Exp>;
};


/** columns and relationships of "indexer.delegate" */
export type DelegatesCurrentDelegations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CurrentDelegations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CurrentDelegations_Order_By>>;
  where?: InputMaybe<CurrentDelegations_Bool_Exp>;
};


/** columns and relationships of "indexer.delegate" */
export type DelegatesDelegationsArgs = {
  distinct_on?: InputMaybe<Array<Delegations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Delegations_Order_By>>;
  where?: InputMaybe<Delegations_Bool_Exp>;
};


/** columns and relationships of "indexer.delegate" */
export type DelegatesDelegations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Delegations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Delegations_Order_By>>;
  where?: InputMaybe<Delegations_Bool_Exp>;
};

/** aggregated selection of "indexer.delegate" */
export type Delegates_Aggregate = {
  __typename?: 'delegates_aggregate';
  aggregate?: Maybe<Delegates_Aggregate_Fields>;
  nodes: Array<Delegates>;
};

/** aggregate fields of "indexer.delegate" */
export type Delegates_Aggregate_Fields = {
  __typename?: 'delegates_aggregate_fields';
  avg?: Maybe<Delegates_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Delegates_Max_Fields>;
  min?: Maybe<Delegates_Min_Fields>;
  stddev?: Maybe<Delegates_Stddev_Fields>;
  stddev_pop?: Maybe<Delegates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Delegates_Stddev_Samp_Fields>;
  sum?: Maybe<Delegates_Sum_Fields>;
  var_pop?: Maybe<Delegates_Var_Pop_Fields>;
  var_samp?: Maybe<Delegates_Var_Samp_Fields>;
  variance?: Maybe<Delegates_Variance_Fields>;
};


/** aggregate fields of "indexer.delegate" */
export type Delegates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Delegates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Delegates_Avg_Fields = {
  __typename?: 'delegates_avg_fields';
  voting_power?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "indexer.delegate". All fields are combined with a logical 'AND'. */
export type Delegates_Bool_Exp = {
  _and?: InputMaybe<Array<Delegates_Bool_Exp>>;
  _not?: InputMaybe<Delegates_Bool_Exp>;
  _or?: InputMaybe<Array<Delegates_Bool_Exp>>;
  address?: InputMaybe<Bpchar_Comparison_Exp>;
  currentDelegations?: InputMaybe<CurrentDelegations_Bool_Exp>;
  currentDelegations_aggregate?: InputMaybe<CurrentDelegations_Aggregate_Bool_Exp>;
  delegations?: InputMaybe<Delegations_Bool_Exp>;
  delegations_aggregate?: InputMaybe<Delegations_Aggregate_Bool_Exp>;
  public?: InputMaybe<Boolean_Comparison_Exp>;
  voting_power?: InputMaybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type Delegates_Max_Fields = {
  __typename?: 'delegates_max_fields';
  address?: Maybe<Scalars['bpchar']>;
  voting_power?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Delegates_Min_Fields = {
  __typename?: 'delegates_min_fields';
  address?: Maybe<Scalars['bpchar']>;
  voting_power?: Maybe<Scalars['numeric']>;
};

/** Ordering options when selecting data from "indexer.delegate". */
export type Delegates_Order_By = {
  address?: InputMaybe<Order_By>;
  currentDelegations_aggregate?: InputMaybe<CurrentDelegations_Aggregate_Order_By>;
  delegations_aggregate?: InputMaybe<Delegations_Aggregate_Order_By>;
  public?: InputMaybe<Order_By>;
  voting_power?: InputMaybe<Order_By>;
};

/** select columns of table "indexer.delegate" */
export enum Delegates_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Public = 'public',
  /** column name */
  VotingPower = 'voting_power'
}

/** aggregate stddev on columns */
export type Delegates_Stddev_Fields = {
  __typename?: 'delegates_stddev_fields';
  voting_power?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Delegates_Stddev_Pop_Fields = {
  __typename?: 'delegates_stddev_pop_fields';
  voting_power?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Delegates_Stddev_Samp_Fields = {
  __typename?: 'delegates_stddev_samp_fields';
  voting_power?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "delegates" */
export type Delegates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Delegates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Delegates_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['bpchar']>;
  public?: InputMaybe<Scalars['Boolean']>;
  voting_power?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Delegates_Sum_Fields = {
  __typename?: 'delegates_sum_fields';
  voting_power?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Delegates_Var_Pop_Fields = {
  __typename?: 'delegates_var_pop_fields';
  voting_power?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Delegates_Var_Samp_Fields = {
  __typename?: 'delegates_var_samp_fields';
  voting_power?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Delegates_Variance_Fields = {
  __typename?: 'delegates_variance_fields';
  voting_power?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "indexer.delegation" */
export type Delegations = {
  __typename?: 'delegations';
  /** An object relationship */
  delegate: Delegates;
  from: Scalars['bpchar'];
  to: Scalars['bpchar'];
};

/** aggregated selection of "indexer.delegation" */
export type Delegations_Aggregate = {
  __typename?: 'delegations_aggregate';
  aggregate?: Maybe<Delegations_Aggregate_Fields>;
  nodes: Array<Delegations>;
};

export type Delegations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Delegations_Aggregate_Bool_Exp_Count>;
};

export type Delegations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Delegations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Delegations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "indexer.delegation" */
export type Delegations_Aggregate_Fields = {
  __typename?: 'delegations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Delegations_Max_Fields>;
  min?: Maybe<Delegations_Min_Fields>;
};


/** aggregate fields of "indexer.delegation" */
export type Delegations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Delegations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "indexer.delegation" */
export type Delegations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Delegations_Max_Order_By>;
  min?: InputMaybe<Delegations_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "indexer.delegation". All fields are combined with a logical 'AND'. */
export type Delegations_Bool_Exp = {
  _and?: InputMaybe<Array<Delegations_Bool_Exp>>;
  _not?: InputMaybe<Delegations_Bool_Exp>;
  _or?: InputMaybe<Array<Delegations_Bool_Exp>>;
  delegate?: InputMaybe<Delegates_Bool_Exp>;
  from?: InputMaybe<Bpchar_Comparison_Exp>;
  to?: InputMaybe<Bpchar_Comparison_Exp>;
};

/** aggregate max on columns */
export type Delegations_Max_Fields = {
  __typename?: 'delegations_max_fields';
  from?: Maybe<Scalars['bpchar']>;
  to?: Maybe<Scalars['bpchar']>;
};

/** order by max() on columns of table "indexer.delegation" */
export type Delegations_Max_Order_By = {
  from?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Delegations_Min_Fields = {
  __typename?: 'delegations_min_fields';
  from?: Maybe<Scalars['bpchar']>;
  to?: Maybe<Scalars['bpchar']>;
};

/** order by min() on columns of table "indexer.delegation" */
export type Delegations_Min_Order_By = {
  from?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "indexer.delegation". */
export type Delegations_Order_By = {
  delegate?: InputMaybe<Delegates_Order_By>;
  from?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
};

/** select columns of table "indexer.delegation" */
export enum Delegations_Select_Column {
  /** column name */
  From = 'from',
  /** column name */
  To = 'to'
}

/** Streaming cursor of the table "delegations" */
export type Delegations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Delegations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Delegations_Stream_Cursor_Value_Input = {
  from?: InputMaybe<Scalars['bpchar']>;
  to?: InputMaybe<Scalars['bpchar']>;
};

/** columns and relationships of "indexer.event" */
export type Event = {
  __typename?: 'event';
  address: Scalars['bpchar'];
  amount: Scalars['numeric'];
  /** An object relationship */
  block?: Maybe<Block>;
  block_hash?: Maybe<Scalars['bpchar']>;
  /** An object relationship */
  event_reward?: Maybe<Rewards>;
  id: Scalars['uuid'];
  timestamp: Scalars['timestamp'];
  type: Scalars['String'];
};

/** aggregated selection of "indexer.event" */
export type Event_Aggregate = {
  __typename?: 'event_aggregate';
  aggregate?: Maybe<Event_Aggregate_Fields>;
  nodes: Array<Event>;
};

/** aggregate fields of "indexer.event" */
export type Event_Aggregate_Fields = {
  __typename?: 'event_aggregate_fields';
  avg?: Maybe<Event_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Event_Max_Fields>;
  min?: Maybe<Event_Min_Fields>;
  stddev?: Maybe<Event_Stddev_Fields>;
  stddev_pop?: Maybe<Event_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Event_Stddev_Samp_Fields>;
  sum?: Maybe<Event_Sum_Fields>;
  var_pop?: Maybe<Event_Var_Pop_Fields>;
  var_samp?: Maybe<Event_Var_Samp_Fields>;
  variance?: Maybe<Event_Variance_Fields>;
};


/** aggregate fields of "indexer.event" */
export type Event_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Event_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Event_Avg_Fields = {
  __typename?: 'event_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "indexer.event". All fields are combined with a logical 'AND'. */
export type Event_Bool_Exp = {
  _and?: InputMaybe<Array<Event_Bool_Exp>>;
  _not?: InputMaybe<Event_Bool_Exp>;
  _or?: InputMaybe<Array<Event_Bool_Exp>>;
  address?: InputMaybe<Bpchar_Comparison_Exp>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  block?: InputMaybe<Block_Bool_Exp>;
  block_hash?: InputMaybe<Bpchar_Comparison_Exp>;
  event_reward?: InputMaybe<Rewards_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  timestamp?: InputMaybe<Timestamp_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Event_Max_Fields = {
  __typename?: 'event_max_fields';
  address?: Maybe<Scalars['bpchar']>;
  amount?: Maybe<Scalars['numeric']>;
  block_hash?: Maybe<Scalars['bpchar']>;
  id?: Maybe<Scalars['uuid']>;
  timestamp?: Maybe<Scalars['timestamp']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Event_Min_Fields = {
  __typename?: 'event_min_fields';
  address?: Maybe<Scalars['bpchar']>;
  amount?: Maybe<Scalars['numeric']>;
  block_hash?: Maybe<Scalars['bpchar']>;
  id?: Maybe<Scalars['uuid']>;
  timestamp?: Maybe<Scalars['timestamp']>;
  type?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "indexer.event". */
export type Event_Order_By = {
  address?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  block?: InputMaybe<Block_Order_By>;
  block_hash?: InputMaybe<Order_By>;
  event_reward?: InputMaybe<Rewards_Order_By>;
  id?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** select columns of table "indexer.event" */
export enum Event_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Amount = 'amount',
  /** column name */
  BlockHash = 'block_hash',
  /** column name */
  Id = 'id',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  Type = 'type'
}

/** aggregate stddev on columns */
export type Event_Stddev_Fields = {
  __typename?: 'event_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Event_Stddev_Pop_Fields = {
  __typename?: 'event_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Event_Stddev_Samp_Fields = {
  __typename?: 'event_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "event" */
export type Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['bpchar']>;
  amount?: InputMaybe<Scalars['numeric']>;
  block_hash?: InputMaybe<Scalars['bpchar']>;
  id?: InputMaybe<Scalars['uuid']>;
  timestamp?: InputMaybe<Scalars['timestamp']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Event_Sum_Fields = {
  __typename?: 'event_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Event_Var_Pop_Fields = {
  __typename?: 'event_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Event_Var_Samp_Fields = {
  __typename?: 'event_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Event_Variance_Fields = {
  __typename?: 'event_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

/** Boolean expression to compare columns of type "interval". All fields are combined with logical 'AND'. */
export type Interval_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['interval']>;
  _gt?: InputMaybe<Scalars['interval']>;
  _gte?: InputMaybe<Scalars['interval']>;
  _in?: InputMaybe<Array<Scalars['interval']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['interval']>;
  _lte?: InputMaybe<Scalars['interval']>;
  _neq?: InputMaybe<Scalars['interval']>;
  _nin?: InputMaybe<Array<Scalars['interval']>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "indexer.action" using primary key columns */
  action?: Maybe<Action>;
  /** fetch data from the table: "indexer.action" */
  actions: Array<Action>;
  /** fetch aggregated fields from the table: "indexer.action" */
  actionsAggregate: Action_Aggregate;
  /** fetch data from the table: "indexer.balance" using primary key columns */
  balance?: Maybe<Balance>;
  /** fetch data from the table: "indexer.balance" */
  balances: Array<Balance>;
  /** fetch aggregated fields from the table: "indexer.balance" */
  balancesAggregate: Balance_Aggregate;
  /** fetch data from the table: "indexer.block" using primary key columns */
  block?: Maybe<Block>;
  /** fetch data from the table: "indexer.block" */
  blocks: Array<Block>;
  /** fetch aggregated fields from the table: "indexer.block" */
  blocksAggregate: Block_Aggregate;
  /** fetch data from the table: "indexer.claim" using primary key columns */
  claim?: Maybe<Claims>;
  /** fetch data from the table: "indexer.claim" */
  claims: Array<Claims>;
  /** fetch aggregated fields from the table: "indexer.claim" */
  claimsAggreagate: Claims_Aggregate;
  /** fetch data from the table: "indexer.contract" using primary key columns */
  contract?: Maybe<Contract>;
  /** fetch data from the table: "indexer.contract_feature" using primary key columns */
  contractFeature?: Maybe<ContractFeature>;
  /** An array relationship */
  contractFeatures: Array<ContractFeature>;
  /** fetch aggregated fields from the table: "indexer.contract_feature" */
  contractFeaturesAggregate: ContractFeature_Aggregate;
  /** fetch data from the table: "indexer.contract" */
  contracts: Array<Contract>;
  /** fetch aggregated fields from the table: "indexer.contract" */
  contractsAggregate: Contract_Aggregate;
  /** fetch data from the table: "indexer.current_balance" */
  currentBalances: Array<CurrentBalance>;
  /** fetch aggregated fields from the table: "indexer.current_balance" */
  currentBalancesAggregate: CurrentBalance_Aggregate;
  /** An array relationship */
  currentDelegations: Array<CurrentDelegations>;
  /** fetch aggregated fields from the table: "indexer.current_delegation" */
  currentDelegationsAggregate: CurrentDelegations_Aggregate;
  /** fetch data from the table: "indexer.delegate" using primary key columns */
  delegate?: Maybe<Delegates>;
  /** fetch data from the table: "indexer.delegate" */
  delegates: Array<Delegates>;
  /** fetch aggregated fields from the table: "indexer.delegate" */
  delegatesAggregate: Delegates_Aggregate;
  /** fetch data from the table: "indexer.delegation" using primary key columns */
  delegation?: Maybe<Delegations>;
  /** An array relationship */
  delegations: Array<Delegations>;
  /** fetch aggregated fields from the table: "indexer.delegation" */
  delegationsAggregate: Delegations_Aggregate;
  /** fetch data from the table: "indexer.event" using primary key columns */
  event?: Maybe<Event>;
  /** fetch data from the table: "indexer.event" */
  events: Array<Event>;
  /** fetch aggregated fields from the table: "indexer.event" */
  eventsAggregate: Event_Aggregate;
  /** fetch data from the table: "indexer.pool_state" using primary key columns */
  poolState?: Maybe<PoolState>;
  /** fetch data from the table: "indexer.pool_state" */
  poolStates: Array<PoolState>;
  /** fetch aggregated fields from the table: "indexer.pool_state" */
  poolStatesAggregate: PoolState_Aggregate;
  /** fetch data from the table: "statistics.reward" using primary key columns */
  reward?: Maybe<Rewards>;
  /** fetch data from the table: "statistics.reward_period" using primary key columns */
  rewardPeriod?: Maybe<RewardPeriods>;
  /** fetch data from the table: "statistics.reward_period" */
  rewardPeriods: Array<RewardPeriods>;
  /** fetch aggregated fields from the table: "statistics.reward_period" */
  rewardPeriodsAggregate: RewardPeriods_Aggregate;
  /** fetch data from the table: "statistics.reward" */
  rewards: Array<Rewards>;
  /** fetch aggregated fields from the table: "statistics.reward" */
  rewardsAggregate: Rewards_Aggregate;
  /** fetch data from the table: "indexer.token" using primary key columns */
  token?: Maybe<Token>;
  /** An array relationship */
  tokens: Array<Token>;
  /** fetch aggregated fields from the table: "indexer.token" */
  tokensAggregate: Token_Aggregate;
};


export type Query_RootActionArgs = {
  blockHash: Scalars['bpchar'];
  order: Scalars['Int'];
};


export type Query_RootActionsArgs = {
  distinct_on?: InputMaybe<Array<Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Action_Order_By>>;
  where?: InputMaybe<Action_Bool_Exp>;
};


export type Query_RootActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Action_Order_By>>;
  where?: InputMaybe<Action_Bool_Exp>;
};


export type Query_RootBalanceArgs = {
  ownerAddress: Scalars['bpchar'];
  tokenContractAddress: Scalars['bpchar'];
  tokenId: Scalars['numeric'];
  validFromBlockHash: Scalars['bpchar'];
};


export type Query_RootBalancesArgs = {
  distinct_on?: InputMaybe<Array<Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Balance_Order_By>>;
  where?: InputMaybe<Balance_Bool_Exp>;
};


export type Query_RootBalancesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Balance_Order_By>>;
  where?: InputMaybe<Balance_Bool_Exp>;
};


export type Query_RootBlockArgs = {
  hash: Scalars['bpchar'];
};


export type Query_RootBlocksArgs = {
  distinct_on?: InputMaybe<Array<Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Order_By>>;
  where?: InputMaybe<Block_Bool_Exp>;
};


export type Query_RootBlocksAggregateArgs = {
  distinct_on?: InputMaybe<Array<Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Order_By>>;
  where?: InputMaybe<Block_Bool_Exp>;
};


export type Query_RootClaimArgs = {
  id: Scalars['uuid'];
};


export type Query_RootClaimsArgs = {
  distinct_on?: InputMaybe<Array<Claims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Claims_Order_By>>;
  where?: InputMaybe<Claims_Bool_Exp>;
};


export type Query_RootClaimsAggreagateArgs = {
  distinct_on?: InputMaybe<Array<Claims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Claims_Order_By>>;
  where?: InputMaybe<Claims_Bool_Exp>;
};


export type Query_RootContractArgs = {
  address: Scalars['bpchar'];
};


export type Query_RootContractFeatureArgs = {
  contractAddress: Scalars['bpchar'];
  feature: Scalars['String'];
};


export type Query_RootContractFeaturesArgs = {
  distinct_on?: InputMaybe<Array<ContractFeature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractFeature_Order_By>>;
  where?: InputMaybe<ContractFeature_Bool_Exp>;
};


export type Query_RootContractFeaturesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContractFeature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractFeature_Order_By>>;
  where?: InputMaybe<ContractFeature_Bool_Exp>;
};


export type Query_RootContractsArgs = {
  distinct_on?: InputMaybe<Array<Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contract_Order_By>>;
  where?: InputMaybe<Contract_Bool_Exp>;
};


export type Query_RootContractsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contract_Order_By>>;
  where?: InputMaybe<Contract_Bool_Exp>;
};


export type Query_RootCurrentBalancesArgs = {
  distinct_on?: InputMaybe<Array<CurrentBalance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CurrentBalance_Order_By>>;
  where?: InputMaybe<CurrentBalance_Bool_Exp>;
};


export type Query_RootCurrentBalancesAggregateArgs = {
  distinct_on?: InputMaybe<Array<CurrentBalance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CurrentBalance_Order_By>>;
  where?: InputMaybe<CurrentBalance_Bool_Exp>;
};


export type Query_RootCurrentDelegationsArgs = {
  distinct_on?: InputMaybe<Array<CurrentDelegations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CurrentDelegations_Order_By>>;
  where?: InputMaybe<CurrentDelegations_Bool_Exp>;
};


export type Query_RootCurrentDelegationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CurrentDelegations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CurrentDelegations_Order_By>>;
  where?: InputMaybe<CurrentDelegations_Bool_Exp>;
};


export type Query_RootDelegateArgs = {
  address: Scalars['bpchar'];
};


export type Query_RootDelegatesArgs = {
  distinct_on?: InputMaybe<Array<Delegates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Delegates_Order_By>>;
  where?: InputMaybe<Delegates_Bool_Exp>;
};


export type Query_RootDelegatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Delegates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Delegates_Order_By>>;
  where?: InputMaybe<Delegates_Bool_Exp>;
};


export type Query_RootDelegationArgs = {
  from: Scalars['bpchar'];
};


export type Query_RootDelegationsArgs = {
  distinct_on?: InputMaybe<Array<Delegations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Delegations_Order_By>>;
  where?: InputMaybe<Delegations_Bool_Exp>;
};


export type Query_RootDelegationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Delegations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Delegations_Order_By>>;
  where?: InputMaybe<Delegations_Bool_Exp>;
};


export type Query_RootEventArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEventsArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


export type Query_RootEventsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


export type Query_RootPoolStateArgs = {
  id: Scalars['Int'];
};


export type Query_RootPoolStatesArgs = {
  distinct_on?: InputMaybe<Array<PoolState_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PoolState_Order_By>>;
  where?: InputMaybe<PoolState_Bool_Exp>;
};


export type Query_RootPoolStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<PoolState_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PoolState_Order_By>>;
  where?: InputMaybe<PoolState_Bool_Exp>;
};


export type Query_RootRewardArgs = {
  Id: Scalars['Int'];
};


export type Query_RootRewardPeriodArgs = {
  Id: Scalars['Int'];
};


export type Query_RootRewardPeriodsArgs = {
  distinct_on?: InputMaybe<Array<RewardPeriods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RewardPeriods_Order_By>>;
  where?: InputMaybe<RewardPeriods_Bool_Exp>;
};


export type Query_RootRewardPeriodsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RewardPeriods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RewardPeriods_Order_By>>;
  where?: InputMaybe<RewardPeriods_Bool_Exp>;
};


export type Query_RootRewardsArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Order_By>>;
  where?: InputMaybe<Rewards_Bool_Exp>;
};


export type Query_RootRewardsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Order_By>>;
  where?: InputMaybe<Rewards_Bool_Exp>;
};


export type Query_RootTokenArgs = {
  contractAddress: Scalars['bpchar'];
  id: Scalars['numeric'];
};


export type Query_RootTokensArgs = {
  distinct_on?: InputMaybe<Array<Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Token_Order_By>>;
  where?: InputMaybe<Token_Bool_Exp>;
};


export type Query_RootTokensAggregateArgs = {
  distinct_on?: InputMaybe<Array<Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Token_Order_By>>;
  where?: InputMaybe<Token_Bool_Exp>;
};

/** columns and relationships of "statistics.reward_period" */
export type RewardPeriods = {
  __typename?: 'rewardPeriods';
  Apr?: Maybe<Scalars['float8']>;
  Duration: Scalars['interval'];
  From: Scalars['timestamp'];
  Id: Scalars['Int'];
  To: Scalars['timestamp'];
  /** An array relationship */
  reward_period_rewards: Array<Rewards>;
  /** An aggregate relationship */
  reward_period_rewards_aggregate: Rewards_Aggregate;
};


/** columns and relationships of "statistics.reward_period" */
export type RewardPeriodsReward_Period_RewardsArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Order_By>>;
  where?: InputMaybe<Rewards_Bool_Exp>;
};


/** columns and relationships of "statistics.reward_period" */
export type RewardPeriodsReward_Period_Rewards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Order_By>>;
  where?: InputMaybe<Rewards_Bool_Exp>;
};

/** aggregated selection of "statistics.reward_period" */
export type RewardPeriods_Aggregate = {
  __typename?: 'rewardPeriods_aggregate';
  aggregate?: Maybe<RewardPeriods_Aggregate_Fields>;
  nodes: Array<RewardPeriods>;
};

/** aggregate fields of "statistics.reward_period" */
export type RewardPeriods_Aggregate_Fields = {
  __typename?: 'rewardPeriods_aggregate_fields';
  avg?: Maybe<RewardPeriods_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<RewardPeriods_Max_Fields>;
  min?: Maybe<RewardPeriods_Min_Fields>;
  stddev?: Maybe<RewardPeriods_Stddev_Fields>;
  stddev_pop?: Maybe<RewardPeriods_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RewardPeriods_Stddev_Samp_Fields>;
  sum?: Maybe<RewardPeriods_Sum_Fields>;
  var_pop?: Maybe<RewardPeriods_Var_Pop_Fields>;
  var_samp?: Maybe<RewardPeriods_Var_Samp_Fields>;
  variance?: Maybe<RewardPeriods_Variance_Fields>;
};


/** aggregate fields of "statistics.reward_period" */
export type RewardPeriods_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RewardPeriods_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type RewardPeriods_Avg_Fields = {
  __typename?: 'rewardPeriods_avg_fields';
  Apr?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "statistics.reward_period". All fields are combined with a logical 'AND'. */
export type RewardPeriods_Bool_Exp = {
  Apr?: InputMaybe<Float8_Comparison_Exp>;
  Duration?: InputMaybe<Interval_Comparison_Exp>;
  From?: InputMaybe<Timestamp_Comparison_Exp>;
  Id?: InputMaybe<Int_Comparison_Exp>;
  To?: InputMaybe<Timestamp_Comparison_Exp>;
  _and?: InputMaybe<Array<RewardPeriods_Bool_Exp>>;
  _not?: InputMaybe<RewardPeriods_Bool_Exp>;
  _or?: InputMaybe<Array<RewardPeriods_Bool_Exp>>;
  reward_period_rewards?: InputMaybe<Rewards_Bool_Exp>;
  reward_period_rewards_aggregate?: InputMaybe<Rewards_Aggregate_Bool_Exp>;
};

/** aggregate max on columns */
export type RewardPeriods_Max_Fields = {
  __typename?: 'rewardPeriods_max_fields';
  Apr?: Maybe<Scalars['float8']>;
  From?: Maybe<Scalars['timestamp']>;
  Id?: Maybe<Scalars['Int']>;
  To?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type RewardPeriods_Min_Fields = {
  __typename?: 'rewardPeriods_min_fields';
  Apr?: Maybe<Scalars['float8']>;
  From?: Maybe<Scalars['timestamp']>;
  Id?: Maybe<Scalars['Int']>;
  To?: Maybe<Scalars['timestamp']>;
};

/** Ordering options when selecting data from "statistics.reward_period". */
export type RewardPeriods_Order_By = {
  Apr?: InputMaybe<Order_By>;
  Duration?: InputMaybe<Order_By>;
  From?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  To?: InputMaybe<Order_By>;
  reward_period_rewards_aggregate?: InputMaybe<Rewards_Aggregate_Order_By>;
};

/** select columns of table "statistics.reward_period" */
export enum RewardPeriods_Select_Column {
  /** column name */
  Apr = 'Apr',
  /** column name */
  Duration = 'Duration',
  /** column name */
  From = 'From',
  /** column name */
  Id = 'Id',
  /** column name */
  To = 'To'
}

/** aggregate stddev on columns */
export type RewardPeriods_Stddev_Fields = {
  __typename?: 'rewardPeriods_stddev_fields';
  Apr?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type RewardPeriods_Stddev_Pop_Fields = {
  __typename?: 'rewardPeriods_stddev_pop_fields';
  Apr?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type RewardPeriods_Stddev_Samp_Fields = {
  __typename?: 'rewardPeriods_stddev_samp_fields';
  Apr?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type RewardPeriods_Sum_Fields = {
  __typename?: 'rewardPeriods_sum_fields';
  Apr?: Maybe<Scalars['float8']>;
  Id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type RewardPeriods_Var_Pop_Fields = {
  __typename?: 'rewardPeriods_var_pop_fields';
  Apr?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type RewardPeriods_Var_Samp_Fields = {
  __typename?: 'rewardPeriods_var_samp_fields';
  Apr?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type RewardPeriods_Variance_Fields = {
  __typename?: 'rewardPeriods_variance_fields';
  Apr?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "statistics.reward" */
export type Rewards = {
  __typename?: 'rewards';
  Amount: Scalars['numeric'];
  Apr: Scalars['float8'];
  EventId: Scalars['uuid'];
  Id: Scalars['Int'];
  Owner: Scalars['bpchar'];
  PeriodId: Scalars['Int'];
  TotalBalance: Scalars['numeric'];
  /** An object relationship */
  period: RewardPeriods;
};

/** aggregated selection of "statistics.reward" */
export type Rewards_Aggregate = {
  __typename?: 'rewards_aggregate';
  aggregate?: Maybe<Rewards_Aggregate_Fields>;
  nodes: Array<Rewards>;
};

export type Rewards_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Rewards_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Rewards_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Rewards_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Rewards_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Rewards_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Rewards_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Rewards_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Rewards_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Rewards_Aggregate_Bool_Exp_Var_Samp>;
};

export type Rewards_Aggregate_Bool_Exp_Avg = {
  arguments: Rewards_Select_Column_Rewards_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rewards_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Rewards_Aggregate_Bool_Exp_Corr = {
  arguments: Rewards_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rewards_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Rewards_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Rewards_Select_Column_Rewards_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Rewards_Select_Column_Rewards_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Rewards_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Rewards_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rewards_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Rewards_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Rewards_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rewards_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Rewards_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Rewards_Select_Column_Rewards_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Rewards_Select_Column_Rewards_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Rewards_Aggregate_Bool_Exp_Max = {
  arguments: Rewards_Select_Column_Rewards_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rewards_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Rewards_Aggregate_Bool_Exp_Min = {
  arguments: Rewards_Select_Column_Rewards_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rewards_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Rewards_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Rewards_Select_Column_Rewards_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rewards_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Rewards_Aggregate_Bool_Exp_Sum = {
  arguments: Rewards_Select_Column_Rewards_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rewards_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Rewards_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Rewards_Select_Column_Rewards_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rewards_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "statistics.reward" */
export type Rewards_Aggregate_Fields = {
  __typename?: 'rewards_aggregate_fields';
  avg?: Maybe<Rewards_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Rewards_Max_Fields>;
  min?: Maybe<Rewards_Min_Fields>;
  stddev?: Maybe<Rewards_Stddev_Fields>;
  stddev_pop?: Maybe<Rewards_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rewards_Stddev_Samp_Fields>;
  sum?: Maybe<Rewards_Sum_Fields>;
  var_pop?: Maybe<Rewards_Var_Pop_Fields>;
  var_samp?: Maybe<Rewards_Var_Samp_Fields>;
  variance?: Maybe<Rewards_Variance_Fields>;
};


/** aggregate fields of "statistics.reward" */
export type Rewards_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rewards_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "statistics.reward" */
export type Rewards_Aggregate_Order_By = {
  avg?: InputMaybe<Rewards_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rewards_Max_Order_By>;
  min?: InputMaybe<Rewards_Min_Order_By>;
  stddev?: InputMaybe<Rewards_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rewards_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rewards_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rewards_Sum_Order_By>;
  var_pop?: InputMaybe<Rewards_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rewards_Var_Samp_Order_By>;
  variance?: InputMaybe<Rewards_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Rewards_Avg_Fields = {
  __typename?: 'rewards_avg_fields';
  Amount?: Maybe<Scalars['Float']>;
  Apr?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Float']>;
  PeriodId?: Maybe<Scalars['Float']>;
  TotalBalance?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "statistics.reward" */
export type Rewards_Avg_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Apr?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  PeriodId?: InputMaybe<Order_By>;
  TotalBalance?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "statistics.reward". All fields are combined with a logical 'AND'. */
export type Rewards_Bool_Exp = {
  Amount?: InputMaybe<Numeric_Comparison_Exp>;
  Apr?: InputMaybe<Float8_Comparison_Exp>;
  EventId?: InputMaybe<Uuid_Comparison_Exp>;
  Id?: InputMaybe<Int_Comparison_Exp>;
  Owner?: InputMaybe<Bpchar_Comparison_Exp>;
  PeriodId?: InputMaybe<Int_Comparison_Exp>;
  TotalBalance?: InputMaybe<Numeric_Comparison_Exp>;
  _and?: InputMaybe<Array<Rewards_Bool_Exp>>;
  _not?: InputMaybe<Rewards_Bool_Exp>;
  _or?: InputMaybe<Array<Rewards_Bool_Exp>>;
  period?: InputMaybe<RewardPeriods_Bool_Exp>;
};

/** aggregate max on columns */
export type Rewards_Max_Fields = {
  __typename?: 'rewards_max_fields';
  Amount?: Maybe<Scalars['numeric']>;
  Apr?: Maybe<Scalars['float8']>;
  EventId?: Maybe<Scalars['uuid']>;
  Id?: Maybe<Scalars['Int']>;
  Owner?: Maybe<Scalars['bpchar']>;
  PeriodId?: Maybe<Scalars['Int']>;
  TotalBalance?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "statistics.reward" */
export type Rewards_Max_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Apr?: InputMaybe<Order_By>;
  EventId?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Owner?: InputMaybe<Order_By>;
  PeriodId?: InputMaybe<Order_By>;
  TotalBalance?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rewards_Min_Fields = {
  __typename?: 'rewards_min_fields';
  Amount?: Maybe<Scalars['numeric']>;
  Apr?: Maybe<Scalars['float8']>;
  EventId?: Maybe<Scalars['uuid']>;
  Id?: Maybe<Scalars['Int']>;
  Owner?: Maybe<Scalars['bpchar']>;
  PeriodId?: Maybe<Scalars['Int']>;
  TotalBalance?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "statistics.reward" */
export type Rewards_Min_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Apr?: InputMaybe<Order_By>;
  EventId?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Owner?: InputMaybe<Order_By>;
  PeriodId?: InputMaybe<Order_By>;
  TotalBalance?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "statistics.reward". */
export type Rewards_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Apr?: InputMaybe<Order_By>;
  EventId?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Owner?: InputMaybe<Order_By>;
  PeriodId?: InputMaybe<Order_By>;
  TotalBalance?: InputMaybe<Order_By>;
  period?: InputMaybe<RewardPeriods_Order_By>;
};

/** select columns of table "statistics.reward" */
export enum Rewards_Select_Column {
  /** column name */
  Amount = 'Amount',
  /** column name */
  Apr = 'Apr',
  /** column name */
  EventId = 'EventId',
  /** column name */
  Id = 'Id',
  /** column name */
  Owner = 'Owner',
  /** column name */
  PeriodId = 'PeriodId',
  /** column name */
  TotalBalance = 'TotalBalance'
}

/** select "rewards_aggregate_bool_exp_avg_arguments_columns" columns of table "statistics.reward" */
export enum Rewards_Select_Column_Rewards_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Apr = 'Apr'
}

/** select "rewards_aggregate_bool_exp_corr_arguments_columns" columns of table "statistics.reward" */
export enum Rewards_Select_Column_Rewards_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Apr = 'Apr'
}

/** select "rewards_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "statistics.reward" */
export enum Rewards_Select_Column_Rewards_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Apr = 'Apr'
}

/** select "rewards_aggregate_bool_exp_max_arguments_columns" columns of table "statistics.reward" */
export enum Rewards_Select_Column_Rewards_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Apr = 'Apr'
}

/** select "rewards_aggregate_bool_exp_min_arguments_columns" columns of table "statistics.reward" */
export enum Rewards_Select_Column_Rewards_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Apr = 'Apr'
}

/** select "rewards_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "statistics.reward" */
export enum Rewards_Select_Column_Rewards_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Apr = 'Apr'
}

/** select "rewards_aggregate_bool_exp_sum_arguments_columns" columns of table "statistics.reward" */
export enum Rewards_Select_Column_Rewards_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Apr = 'Apr'
}

/** select "rewards_aggregate_bool_exp_var_samp_arguments_columns" columns of table "statistics.reward" */
export enum Rewards_Select_Column_Rewards_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Apr = 'Apr'
}

/** aggregate stddev on columns */
export type Rewards_Stddev_Fields = {
  __typename?: 'rewards_stddev_fields';
  Amount?: Maybe<Scalars['Float']>;
  Apr?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Float']>;
  PeriodId?: Maybe<Scalars['Float']>;
  TotalBalance?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "statistics.reward" */
export type Rewards_Stddev_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Apr?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  PeriodId?: InputMaybe<Order_By>;
  TotalBalance?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rewards_Stddev_Pop_Fields = {
  __typename?: 'rewards_stddev_pop_fields';
  Amount?: Maybe<Scalars['Float']>;
  Apr?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Float']>;
  PeriodId?: Maybe<Scalars['Float']>;
  TotalBalance?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "statistics.reward" */
export type Rewards_Stddev_Pop_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Apr?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  PeriodId?: InputMaybe<Order_By>;
  TotalBalance?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rewards_Stddev_Samp_Fields = {
  __typename?: 'rewards_stddev_samp_fields';
  Amount?: Maybe<Scalars['Float']>;
  Apr?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Float']>;
  PeriodId?: Maybe<Scalars['Float']>;
  TotalBalance?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "statistics.reward" */
export type Rewards_Stddev_Samp_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Apr?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  PeriodId?: InputMaybe<Order_By>;
  TotalBalance?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rewards_Sum_Fields = {
  __typename?: 'rewards_sum_fields';
  Amount?: Maybe<Scalars['numeric']>;
  Apr?: Maybe<Scalars['float8']>;
  Id?: Maybe<Scalars['Int']>;
  PeriodId?: Maybe<Scalars['Int']>;
  TotalBalance?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "statistics.reward" */
export type Rewards_Sum_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Apr?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  PeriodId?: InputMaybe<Order_By>;
  TotalBalance?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rewards_Var_Pop_Fields = {
  __typename?: 'rewards_var_pop_fields';
  Amount?: Maybe<Scalars['Float']>;
  Apr?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Float']>;
  PeriodId?: Maybe<Scalars['Float']>;
  TotalBalance?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "statistics.reward" */
export type Rewards_Var_Pop_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Apr?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  PeriodId?: InputMaybe<Order_By>;
  TotalBalance?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rewards_Var_Samp_Fields = {
  __typename?: 'rewards_var_samp_fields';
  Amount?: Maybe<Scalars['Float']>;
  Apr?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Float']>;
  PeriodId?: Maybe<Scalars['Float']>;
  TotalBalance?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "statistics.reward" */
export type Rewards_Var_Samp_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Apr?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  PeriodId?: InputMaybe<Order_By>;
  TotalBalance?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rewards_Variance_Fields = {
  __typename?: 'rewards_variance_fields';
  Amount?: Maybe<Scalars['Float']>;
  Apr?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Float']>;
  PeriodId?: Maybe<Scalars['Float']>;
  TotalBalance?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "statistics.reward" */
export type Rewards_Variance_Order_By = {
  Amount?: InputMaybe<Order_By>;
  Apr?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  PeriodId?: InputMaybe<Order_By>;
  TotalBalance?: InputMaybe<Order_By>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table in a streaming manner: "indexer.action" */
  Action_stream: Array<Action>;
  /** fetch data from the table in a streaming manner: "indexer.balance" */
  Balance_stream: Array<Balance>;
  /** fetch data from the table in a streaming manner: "indexer.block" */
  Block_stream: Array<Block>;
  /** fetch data from the table in a streaming manner: "indexer.contract_feature" */
  ContractFeature_stream: Array<ContractFeature>;
  /** fetch data from the table in a streaming manner: "indexer.contract" */
  Contract_stream: Array<Contract>;
  /** fetch data from the table in a streaming manner: "indexer.current_balance" */
  CurrentBalance_stream: Array<CurrentBalance>;
  /** fetch data from the table in a streaming manner: "indexer.pool_state" */
  PoolState_stream: Array<PoolState>;
  /** fetch data from the table in a streaming manner: "indexer.token" */
  Token_stream: Array<Token>;
  /** fetch data from the table: "indexer.action" using primary key columns */
  action?: Maybe<Action>;
  /** fetch data from the table: "indexer.action" */
  actions: Array<Action>;
  /** fetch aggregated fields from the table: "indexer.action" */
  actionsAggregate: Action_Aggregate;
  /** fetch data from the table: "indexer.balance" using primary key columns */
  balance?: Maybe<Balance>;
  /** fetch data from the table: "indexer.balance" */
  balances: Array<Balance>;
  /** fetch aggregated fields from the table: "indexer.balance" */
  balancesAggregate: Balance_Aggregate;
  /** fetch data from the table: "indexer.block" using primary key columns */
  block?: Maybe<Block>;
  /** fetch data from the table: "indexer.block" */
  blocks: Array<Block>;
  /** fetch aggregated fields from the table: "indexer.block" */
  blocksAggregate: Block_Aggregate;
  /** fetch data from the table: "indexer.claim" using primary key columns */
  claim?: Maybe<Claims>;
  /** fetch data from the table: "indexer.claim" */
  claims: Array<Claims>;
  /** fetch aggregated fields from the table: "indexer.claim" */
  claimsAggreagate: Claims_Aggregate;
  /** fetch data from the table in a streaming manner: "indexer.claim" */
  claims_stream: Array<Claims>;
  /** fetch data from the table: "indexer.contract" using primary key columns */
  contract?: Maybe<Contract>;
  /** fetch data from the table: "indexer.contract_feature" using primary key columns */
  contractFeature?: Maybe<ContractFeature>;
  /** An array relationship */
  contractFeatures: Array<ContractFeature>;
  /** fetch aggregated fields from the table: "indexer.contract_feature" */
  contractFeaturesAggregate: ContractFeature_Aggregate;
  /** fetch data from the table: "indexer.contract" */
  contracts: Array<Contract>;
  /** fetch aggregated fields from the table: "indexer.contract" */
  contractsAggregate: Contract_Aggregate;
  /** fetch data from the table: "indexer.current_balance" */
  currentBalances: Array<CurrentBalance>;
  /** fetch aggregated fields from the table: "indexer.current_balance" */
  currentBalancesAggregate: CurrentBalance_Aggregate;
  /** An array relationship */
  currentDelegations: Array<CurrentDelegations>;
  /** fetch aggregated fields from the table: "indexer.current_delegation" */
  currentDelegationsAggregate: CurrentDelegations_Aggregate;
  /** fetch data from the table in a streaming manner: "indexer.current_delegation" */
  currentDelegations_stream: Array<CurrentDelegations>;
  /** fetch data from the table: "indexer.delegate" using primary key columns */
  delegate?: Maybe<Delegates>;
  /** fetch data from the table: "indexer.delegate" */
  delegates: Array<Delegates>;
  /** fetch aggregated fields from the table: "indexer.delegate" */
  delegatesAggregate: Delegates_Aggregate;
  /** fetch data from the table in a streaming manner: "indexer.delegate" */
  delegates_stream: Array<Delegates>;
  /** fetch data from the table: "indexer.delegation" using primary key columns */
  delegation?: Maybe<Delegations>;
  /** An array relationship */
  delegations: Array<Delegations>;
  /** fetch aggregated fields from the table: "indexer.delegation" */
  delegationsAggregate: Delegations_Aggregate;
  /** fetch data from the table in a streaming manner: "indexer.delegation" */
  delegations_stream: Array<Delegations>;
  /** fetch data from the table: "indexer.event" using primary key columns */
  event?: Maybe<Event>;
  /** fetch data from the table in a streaming manner: "indexer.event" */
  event_stream: Array<Event>;
  /** fetch data from the table: "indexer.event" */
  events: Array<Event>;
  /** fetch aggregated fields from the table: "indexer.event" */
  eventsAggregate: Event_Aggregate;
  /** fetch data from the table: "indexer.pool_state" using primary key columns */
  poolState?: Maybe<PoolState>;
  /** fetch data from the table: "indexer.pool_state" */
  poolStates: Array<PoolState>;
  /** fetch aggregated fields from the table: "indexer.pool_state" */
  poolStatesAggregate: PoolState_Aggregate;
  /** fetch data from the table: "statistics.reward" using primary key columns */
  reward?: Maybe<Rewards>;
  /** fetch data from the table: "statistics.reward_period" using primary key columns */
  rewardPeriod?: Maybe<RewardPeriods>;
  /** fetch data from the table: "statistics.reward_period" */
  rewardPeriods: Array<RewardPeriods>;
  /** fetch aggregated fields from the table: "statistics.reward_period" */
  rewardPeriodsAggregate: RewardPeriods_Aggregate;
  /** fetch data from the table: "statistics.reward" */
  rewards: Array<Rewards>;
  /** fetch aggregated fields from the table: "statistics.reward" */
  rewardsAggregate: Rewards_Aggregate;
  /** fetch data from the table: "indexer.token" using primary key columns */
  token?: Maybe<Token>;
  /** An array relationship */
  tokens: Array<Token>;
  /** fetch aggregated fields from the table: "indexer.token" */
  tokensAggregate: Token_Aggregate;
};


export type Subscription_RootAction_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Action_Stream_Cursor_Input>>;
  where?: InputMaybe<Action_Bool_Exp>;
};


export type Subscription_RootBalance_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Balance_Stream_Cursor_Input>>;
  where?: InputMaybe<Balance_Bool_Exp>;
};


export type Subscription_RootBlock_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Block_Stream_Cursor_Input>>;
  where?: InputMaybe<Block_Bool_Exp>;
};


export type Subscription_RootContractFeature_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ContractFeature_Stream_Cursor_Input>>;
  where?: InputMaybe<ContractFeature_Bool_Exp>;
};


export type Subscription_RootContract_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Contract_Stream_Cursor_Input>>;
  where?: InputMaybe<Contract_Bool_Exp>;
};


export type Subscription_RootCurrentBalance_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CurrentBalance_Stream_Cursor_Input>>;
  where?: InputMaybe<CurrentBalance_Bool_Exp>;
};


export type Subscription_RootPoolState_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PoolState_Stream_Cursor_Input>>;
  where?: InputMaybe<PoolState_Bool_Exp>;
};


export type Subscription_RootToken_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Token_Stream_Cursor_Input>>;
  where?: InputMaybe<Token_Bool_Exp>;
};


export type Subscription_RootActionArgs = {
  blockHash: Scalars['bpchar'];
  order: Scalars['Int'];
};


export type Subscription_RootActionsArgs = {
  distinct_on?: InputMaybe<Array<Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Action_Order_By>>;
  where?: InputMaybe<Action_Bool_Exp>;
};


export type Subscription_RootActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Action_Order_By>>;
  where?: InputMaybe<Action_Bool_Exp>;
};


export type Subscription_RootBalanceArgs = {
  ownerAddress: Scalars['bpchar'];
  tokenContractAddress: Scalars['bpchar'];
  tokenId: Scalars['numeric'];
  validFromBlockHash: Scalars['bpchar'];
};


export type Subscription_RootBalancesArgs = {
  distinct_on?: InputMaybe<Array<Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Balance_Order_By>>;
  where?: InputMaybe<Balance_Bool_Exp>;
};


export type Subscription_RootBalancesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Balance_Order_By>>;
  where?: InputMaybe<Balance_Bool_Exp>;
};


export type Subscription_RootBlockArgs = {
  hash: Scalars['bpchar'];
};


export type Subscription_RootBlocksArgs = {
  distinct_on?: InputMaybe<Array<Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Order_By>>;
  where?: InputMaybe<Block_Bool_Exp>;
};


export type Subscription_RootBlocksAggregateArgs = {
  distinct_on?: InputMaybe<Array<Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Order_By>>;
  where?: InputMaybe<Block_Bool_Exp>;
};


export type Subscription_RootClaimArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootClaimsArgs = {
  distinct_on?: InputMaybe<Array<Claims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Claims_Order_By>>;
  where?: InputMaybe<Claims_Bool_Exp>;
};


export type Subscription_RootClaimsAggreagateArgs = {
  distinct_on?: InputMaybe<Array<Claims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Claims_Order_By>>;
  where?: InputMaybe<Claims_Bool_Exp>;
};


export type Subscription_RootClaims_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Claims_Stream_Cursor_Input>>;
  where?: InputMaybe<Claims_Bool_Exp>;
};


export type Subscription_RootContractArgs = {
  address: Scalars['bpchar'];
};


export type Subscription_RootContractFeatureArgs = {
  contractAddress: Scalars['bpchar'];
  feature: Scalars['String'];
};


export type Subscription_RootContractFeaturesArgs = {
  distinct_on?: InputMaybe<Array<ContractFeature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractFeature_Order_By>>;
  where?: InputMaybe<ContractFeature_Bool_Exp>;
};


export type Subscription_RootContractFeaturesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContractFeature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractFeature_Order_By>>;
  where?: InputMaybe<ContractFeature_Bool_Exp>;
};


export type Subscription_RootContractsArgs = {
  distinct_on?: InputMaybe<Array<Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contract_Order_By>>;
  where?: InputMaybe<Contract_Bool_Exp>;
};


export type Subscription_RootContractsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contract_Order_By>>;
  where?: InputMaybe<Contract_Bool_Exp>;
};


export type Subscription_RootCurrentBalancesArgs = {
  distinct_on?: InputMaybe<Array<CurrentBalance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CurrentBalance_Order_By>>;
  where?: InputMaybe<CurrentBalance_Bool_Exp>;
};


export type Subscription_RootCurrentBalancesAggregateArgs = {
  distinct_on?: InputMaybe<Array<CurrentBalance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CurrentBalance_Order_By>>;
  where?: InputMaybe<CurrentBalance_Bool_Exp>;
};


export type Subscription_RootCurrentDelegationsArgs = {
  distinct_on?: InputMaybe<Array<CurrentDelegations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CurrentDelegations_Order_By>>;
  where?: InputMaybe<CurrentDelegations_Bool_Exp>;
};


export type Subscription_RootCurrentDelegationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CurrentDelegations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CurrentDelegations_Order_By>>;
  where?: InputMaybe<CurrentDelegations_Bool_Exp>;
};


export type Subscription_RootCurrentDelegations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CurrentDelegations_Stream_Cursor_Input>>;
  where?: InputMaybe<CurrentDelegations_Bool_Exp>;
};


export type Subscription_RootDelegateArgs = {
  address: Scalars['bpchar'];
};


export type Subscription_RootDelegatesArgs = {
  distinct_on?: InputMaybe<Array<Delegates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Delegates_Order_By>>;
  where?: InputMaybe<Delegates_Bool_Exp>;
};


export type Subscription_RootDelegatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Delegates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Delegates_Order_By>>;
  where?: InputMaybe<Delegates_Bool_Exp>;
};


export type Subscription_RootDelegates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Delegates_Stream_Cursor_Input>>;
  where?: InputMaybe<Delegates_Bool_Exp>;
};


export type Subscription_RootDelegationArgs = {
  from: Scalars['bpchar'];
};


export type Subscription_RootDelegationsArgs = {
  distinct_on?: InputMaybe<Array<Delegations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Delegations_Order_By>>;
  where?: InputMaybe<Delegations_Bool_Exp>;
};


export type Subscription_RootDelegationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Delegations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Delegations_Order_By>>;
  where?: InputMaybe<Delegations_Bool_Exp>;
};


export type Subscription_RootDelegations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Delegations_Stream_Cursor_Input>>;
  where?: InputMaybe<Delegations_Bool_Exp>;
};


export type Subscription_RootEventArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEvent_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Event_Stream_Cursor_Input>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


export type Subscription_RootEventsArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


export type Subscription_RootEventsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


export type Subscription_RootPoolStateArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPoolStatesArgs = {
  distinct_on?: InputMaybe<Array<PoolState_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PoolState_Order_By>>;
  where?: InputMaybe<PoolState_Bool_Exp>;
};


export type Subscription_RootPoolStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<PoolState_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PoolState_Order_By>>;
  where?: InputMaybe<PoolState_Bool_Exp>;
};


export type Subscription_RootRewardArgs = {
  Id: Scalars['Int'];
};


export type Subscription_RootRewardPeriodArgs = {
  Id: Scalars['Int'];
};


export type Subscription_RootRewardPeriodsArgs = {
  distinct_on?: InputMaybe<Array<RewardPeriods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RewardPeriods_Order_By>>;
  where?: InputMaybe<RewardPeriods_Bool_Exp>;
};


export type Subscription_RootRewardPeriodsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RewardPeriods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RewardPeriods_Order_By>>;
  where?: InputMaybe<RewardPeriods_Bool_Exp>;
};


export type Subscription_RootRewardsArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Order_By>>;
  where?: InputMaybe<Rewards_Bool_Exp>;
};


export type Subscription_RootRewardsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Order_By>>;
  where?: InputMaybe<Rewards_Bool_Exp>;
};


export type Subscription_RootTokenArgs = {
  contractAddress: Scalars['bpchar'];
  id: Scalars['numeric'];
};


export type Subscription_RootTokensArgs = {
  distinct_on?: InputMaybe<Array<Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Token_Order_By>>;
  where?: InputMaybe<Token_Bool_Exp>;
};


export type Subscription_RootTokensAggregateArgs = {
  distinct_on?: InputMaybe<Array<Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Token_Order_By>>;
  where?: InputMaybe<Token_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type PoolStatisticsQueryVariables = Exact<{
  address: Scalars['bpchar'];
  votesContractAddress: Scalars['bpchar'];
}>;


export type PoolStatisticsQuery = { __typename?: 'query_root', rewardsAggregate: { __typename?: 'rewards_aggregate', aggregate?: { __typename?: 'rewards_aggregate_fields', sum?: { __typename?: 'rewards_sum_fields', Amount?: any | null } | null } | null }, rewardPeriodsAggregate: { __typename?: 'rewardPeriods_aggregate', aggregate?: { __typename?: 'rewardPeriods_aggregate_fields', avg?: { __typename?: 'rewardPeriods_avg_fields', Apr?: number | null } | null } | null }, currentBalancesAggregate: { __typename?: 'CurrentBalance_aggregate', aggregate?: { __typename?: 'CurrentBalance_aggregate_fields', count: number } | null }, poolStates: Array<{ __typename?: 'PoolState', current_apr: BigNumber }>, claims: Array<{ __typename?: 'claims', address: string, amount: any, claimable_from: dayjs.Dayjs, from_airdrop: boolean }> };

export type PoolStatisticsUnauthenticatedQueryVariables = Exact<{
  votesContractAddress: Scalars['bpchar'];
}>;


export type PoolStatisticsUnauthenticatedQuery = { __typename?: 'query_root', rewardPeriodsAggregate: { __typename?: 'rewardPeriods_aggregate', aggregate?: { __typename?: 'rewardPeriods_aggregate_fields', avg?: { __typename?: 'rewardPeriods_avg_fields', Apr?: number | null } | null } | null }, currentBalancesAggregate: { __typename?: 'CurrentBalance_aggregate', aggregate?: { __typename?: 'CurrentBalance_aggregate_fields', count: number } | null }, poolStates: Array<{ __typename?: 'PoolState', current_apr: BigNumber }> };

export type DelegatesQueryVariables = Exact<{
  where?: InputMaybe<Delegates_Bool_Exp>;
  order_by?: InputMaybe<Array<Delegates_Order_By> | Delegates_Order_By>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type DelegatesQuery = { __typename?: 'query_root', delegates: Array<{ __typename?: 'delegates', address: string, public: boolean, voting_power: any }> };

export type DelegatesLeaderboardQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Delegates_Order_By> | Delegates_Order_By>;
  where?: InputMaybe<Delegates_Bool_Exp>;
}>;


export type DelegatesLeaderboardQuery = { __typename?: 'query_root', delegates: Array<{ __typename?: 'delegates', public: boolean, voting_power: any, address: string, currentDelegations_aggregate: { __typename?: 'currentDelegations_aggregate', aggregate?: { __typename?: 'currentDelegations_aggregate_fields', count: number } | null } }> };

export type DelegatorListQueryVariables = Exact<{
  address: Scalars['bpchar'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CurrentDelegations_Order_By> | CurrentDelegations_Order_By>;
}>;


export type DelegatorListQuery = { __typename?: 'query_root', currentDelegations: Array<{ __typename?: 'currentDelegations', from?: string | null, amount?: any | null }> };

export type DelegateQueryVariables = Exact<{
  address: Scalars['bpchar'];
}>;


export type DelegateQuery = { __typename?: 'query_root', delegates: Array<{ __typename?: 'delegates', voting_power: any, address: string }> };

export type DelegationInfoQueryVariables = Exact<{
  address: Scalars['bpchar'];
}>;


export type DelegationInfoQuery = { __typename?: 'query_root', currentDelegationsAggregate: { __typename?: 'currentDelegations_aggregate', aggregate?: { __typename?: 'currentDelegations_aggregate_fields', count: number } | null }, delegate?: { __typename?: 'delegates', voting_power: any } | null, delegatingTo: Array<{ __typename?: 'currentDelegations', to?: string | null, amount?: any | null }> };

export type PoolHistoryQueryVariables = Exact<{
  where: Event_Bool_Exp;
  limit: Scalars['Int'];
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type PoolHistoryQuery = { __typename?: 'query_root', events: Array<{ __typename?: 'event', amount: any, timestamp: dayjs.Dayjs, type: string, event_reward?: { __typename?: 'rewards', Amount: any, Apr: BigNumber, TotalBalance: any } | null }> };

export type GovBlockQueryVariables = Exact<{
  hash?: InputMaybe<Scalars['bpchar']>;
}>;


export type GovBlockQuery = { __typename?: 'query_root', blocks: Array<{ __typename?: 'Block', timestamp: dayjs.Dayjs, hash: string, level: number }> };

export type GovLatestBlockQueryVariables = Exact<{ [key: string]: never; }>;


export type GovLatestBlockQuery = { __typename?: 'query_root', blocks: Array<{ __typename?: 'Block', timestamp: dayjs.Dayjs }> };

export const PoolStatisticsDocument = gql`
    query PoolStatistics($address: bpchar!, $votesContractAddress: bpchar!) {
  rewardsAggregate(where: {Owner: {_eq: $address}}) {
    aggregate {
      sum {
        Amount
      }
    }
  }
  rewardPeriodsAggregate(limit: 7, order_by: {From: desc_nulls_last}) {
    aggregate {
      avg {
        Apr
      }
    }
  }
  currentBalancesAggregate(
    where: {tokenContractAddress: {_eq: $votesContractAddress}}
  ) {
    aggregate {
      count(distinct: true)
    }
  }
  poolStates(limit: 1, order_by: {id: desc}) {
    current_apr: apr
  }
  claims(where: {address: {_eq: $address}}, order_by: {claimable_from: asc}) {
    address
    amount
    claimable_from
    from_airdrop
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PoolStatisticsGQL extends Apollo.Query<PoolStatisticsQuery, PoolStatisticsQueryVariables> {
    document = PoolStatisticsDocument;
    client = 'gvpool';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PoolStatisticsUnauthenticatedDocument = gql`
    query PoolStatisticsUnauthenticated($votesContractAddress: bpchar!) {
  rewardPeriodsAggregate(limit: 7, order_by: {From: desc_nulls_last}) {
    aggregate {
      avg {
        Apr
      }
    }
  }
  currentBalancesAggregate(
    where: {tokenContractAddress: {_eq: $votesContractAddress}}
  ) {
    aggregate {
      count(distinct: true)
    }
  }
  poolStates(limit: 1, order_by: {id: desc}) {
    current_apr: apr
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PoolStatisticsUnauthenticatedGQL extends Apollo.Query<PoolStatisticsUnauthenticatedQuery, PoolStatisticsUnauthenticatedQueryVariables> {
    document = PoolStatisticsUnauthenticatedDocument;
    client = 'gvpool';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DelegatesDocument = gql`
    query Delegates($where: delegates_bool_exp = {}, $order_by: [delegates_order_by!] = {}, $offset: Int, $limit: Int) {
  delegates(where: $where, order_by: $order_by, offset: $offset, limit: $limit) {
    address
    public
    voting_power
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DelegatesGQL extends Apollo.Query<DelegatesQuery, DelegatesQueryVariables> {
    document = DelegatesDocument;
    client = 'gvpool';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DelegatesLeaderboardDocument = gql`
    query DelegatesLeaderboard($limit: Int, $offset: Int, $order_by: [delegates_order_by!], $where: delegates_bool_exp) {
  delegates(limit: $limit, order_by: $order_by, where: $where, offset: $offset) {
    currentDelegations_aggregate(where: {amount: {_gt: "0"}}) {
      aggregate {
        count
      }
    }
    public
    voting_power
    address
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DelegatesLeaderboardGQL extends Apollo.Query<DelegatesLeaderboardQuery, DelegatesLeaderboardQueryVariables> {
    document = DelegatesLeaderboardDocument;
    client = 'gvpool';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DelegatorListDocument = gql`
    query DelegatorList($address: bpchar!, $limit: Int, $offset: Int, $order_by: [currentDelegations_order_by!]) {
  currentDelegations(
    limit: $limit
    offset: $offset
    order_by: $order_by
    where: {to: {_eq: $address}}
  ) {
    from
    amount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DelegatorListGQL extends Apollo.Query<DelegatorListQuery, DelegatorListQueryVariables> {
    document = DelegatorListDocument;
    client = 'gvpool';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DelegateDocument = gql`
    query Delegate($address: bpchar!) {
  delegates(where: {address: {_eq: $address}}) {
    voting_power
    address
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DelegateGQL extends Apollo.Query<DelegateQuery, DelegateQueryVariables> {
    document = DelegateDocument;
    client = 'gvpool';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DelegationInfoDocument = gql`
    query DelegationInfo($address: bpchar!) {
  currentDelegationsAggregate(where: {to: {_eq: $address}}) {
    aggregate {
      count
    }
  }
  delegate(address: $address) {
    voting_power
  }
  delegatingTo: currentDelegations(where: {from: {_eq: $address}}) {
    to
    amount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DelegationInfoGQL extends Apollo.Query<DelegationInfoQuery, DelegationInfoQueryVariables> {
    document = DelegationInfoDocument;
    client = 'gvpool';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PoolHistoryDocument = gql`
    query PoolHistory($where: event_bool_exp!, $limit: Int!, $offset: Int) {
  events(
    where: $where
    limit: $limit
    offset: $offset
    order_by: {timestamp: desc}
  ) {
    amount
    timestamp
    type
    event_reward {
      Amount
      Apr
      TotalBalance
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PoolHistoryGQL extends Apollo.Query<PoolHistoryQuery, PoolHistoryQueryVariables> {
    document = PoolHistoryDocument;
    client = 'gvpool';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GovBlockDocument = gql`
    query GovBlock($hash: bpchar) {
  blocks(
    where: {hash: {_eq: $hash}}
    limit: 1
    order_by: {timestamp: desc_nulls_last}
  ) {
    timestamp
    hash
    level
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GovBlockGQL extends Apollo.Query<GovBlockQuery, GovBlockQueryVariables> {
    document = GovBlockDocument;
    client = 'gvpool';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GovLatestBlockDocument = gql`
    query GovLatestBlock {
  blocks(limit: 1, order_by: {timestamp: desc_nulls_last}) {
    timestamp
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GovLatestBlockGQL extends Apollo.Query<GovLatestBlockQuery, GovLatestBlockQueryVariables> {
    document = GovLatestBlockDocument;
    client = 'gvpool';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }