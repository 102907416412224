import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, startWith } from 'rxjs/operators';

import { DataFieldsService, DataFieldEditorDescriptor } from './data-fields.service';
import { DataItem } from '../../graphql/graphql.generated';
import { UnknownComponent } from './editors/unknown.component';

export interface Key {
    displayName: string;
    name: string;
}

@Component({
    selector: 'td-data-editor',
    templateUrl: './data-editor.component.html',
    styleUrls: ['./data-editor.component.scss'],
})
export class DataEditorComponent implements OnInit, OnDestroy {
    @Input() form: UntypedFormGroup;
    @Input() data: Pick<DataItem, 'key' | 'rawValue'>[];

    keys: Key[] = [];
    availableKeys: Key[];
    filteredFields: Key[];

    addKeyForm: UntypedFormGroup;

    private unsubscribe = new Subject<void>();
    private standardFieldEditors: Map<string, DataFieldEditorDescriptor>;

    constructor(private formBuilder: UntypedFormBuilder, private dataFieldsService: DataFieldsService) {}

    ngOnInit(): void {
        this.standardFieldEditors = this.dataFieldsService.getFields();

        this.addKeyForm = this.formBuilder.group({
            key: this.formBuilder.control(''),
        });

        this.data.forEach(item => {
            this.keys.push(this.createKey(item.key));
            this.form.addControl(item.key, this.formBuilder.control(item.rawValue));
        });

        this.updateAvailableKeys();

        this.form.statusChanges.pipe(takeUntil(this.unsubscribe)).subscribe(s => {
            if (s === 'DISABLED') {
                this.addKeyForm.disable();
            } else {
                this.addKeyForm.enable();
            }
        });

        this.addKeyForm
            .get('key')!
            .valueChanges.pipe(takeUntil(this.unsubscribe), startWith(<unknown>null))
            .subscribe(() => this.updateFilteredFields());
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    removeKey(key: string) {
        this.keys = this.keys.filter(k => k.name !== key);
        this.form.removeControl(key);
        this.updateAvailableKeys();
    }

    addKey() {
        let key: Key | string | null = this.addKeyForm.value.key;
        if (typeof key === 'string') {
            key = this.createKey(key);
        }

        if (!key || !key.name || this.form.contains(key.name)) {
            return;
        }

        this.keys.push(key);
        this.form.addControl(key.name, this.formBuilder.control(null));
        this.updateAvailableKeys();

        this.addKeyForm.reset();
    }

    getFieldDescriptor(key: string): DataFieldEditorDescriptor {
        const standardField = this.standardFieldEditors.get(key);

        if (standardField) {
            return standardField;
        }

        return {
            key,
            displayName: key,
            editorComponent: UnknownComponent,
        };
    }

    display(value: Key): any {
        if (value) {
            return value.displayName;
        }

        return null;
    }

    private updateAvailableKeys() {
        this.availableKeys = [];

        this.standardFieldEditors.forEach((_, k) => {
            if (!this.keys.some(kk => kk.name === k)) {
                this.availableKeys.push(this.createKey(k));
            }
        });

        this.updateFilteredFields();
    }

    private updateFilteredFields() {
        const key: Key | string | null = this.addKeyForm.get('key')!.value;
        let term = typeof key === 'string' ? key : key?.name;

        if (!term) {
            this.filteredFields = this.availableKeys.slice();
        } else {
            term = term.trim().toLowerCase();
            this.filteredFields = this.availableKeys.filter(k => k.name.toLowerCase().includes(term!) || k.displayName.toLowerCase().includes(term!));
        }
    }

    private createKey(key: string): Key {
        const field = this.standardFieldEditors.get(key);

        return {
            name: field?.key || key,
            displayName: field?.displayName || key,
        };
    }
}
