import { Pipe, PipeTransform } from '@angular/core';

import { LocaleService } from './locale.service';

@Pipe({
    name: 'humanizeDuration',
})
export class HumanizeDurationPipe implements PipeTransform {
    constructor(private localeService: LocaleService) {}

    transform(value: plugin.Duration | null | undefined, withSuffix = true): string | undefined {
        if (!value) {
            return undefined;
        }

        return value.locale(this.localeService.getLocale()).humanize(withSuffix);
    }
}
