import { Component, Input } from '@angular/core';
import { createIcon } from '@download/blockies';
import { BigNumber } from 'bignumber.js';

/**
 * Borrowed from: https://github.com/airgap-it/beacon-extension/blob/master/src/app/components/identicon/identicon.component.ts
 */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'identicon',
    templateUrl: 'identicon.component.html',
    styleUrls: ['./identicon.component.scss'],
})
export class IdenticonComponent {
    identicon: string | null;
    sizePx = '24px';

    @Input()
    set address(value: string | null | undefined) {
        if (!value) {
            this.identicon = null;
            return;
        }

        this.identicon = createIcon({
            seed: `0${this.b582int(value)}`,
            spotcolor: '#000',
        }).toDataURL();
    }
    @Input() set size(value: number) {
        this.sizePx = `${value}px`;
    }

    private b582int(v: string) {
        let rv = new BigNumber(0);
        const alpha = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
        for (let i = 0; i < v.length; i++) {
            rv = rv.plus(new BigNumber(alpha.indexOf(v[v.length - 1 - i])).multipliedBy(new BigNumber(alpha.length).exponentiatedBy(i)));
        }

        return rv.toString(16);
    }
}
