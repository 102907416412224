import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RecordMetadata } from '@tezos-domains/core';
import { Observable } from 'rxjs';
import { BuyOfferRecord } from '../graphql/buy-offer-table-data-source';
import { OperationStatusDoneEvent } from '../shared/operation-status.component';
import { TezosWallet } from '../tezos/models';
import { BuyOfferBrokerService, ExecuteBuyOfferRequest } from '../tezos/nft/buy-offer-broker.service';
import { SmartContractOperationEvent, TezosService } from '../tezos/tezos.service';
import { dataArrayToObj } from '../utils/convert';
import { IgnoredOffersService } from './ignored-offers.service';

@UntilDestroy()
@Component({
    selector: 'td-execute-buy-offer',
    templateUrl: './execute-buy-offer.component.html',
    styleUrls: ['./execute-buy-offer.component.scss'],
})
export class ExecuteBuyOfferComponent implements OnInit {
    form: FormGroup;
    operation: Observable<SmartContractOperationEvent> | null;
    wallet: TezosWallet;
    offer: BuyOfferRecord;
    success: boolean;

    constructor(
        private buyOfferBrokerService: BuyOfferBrokerService,
        @Inject(MAT_DIALOG_DATA) private data: { offer: BuyOfferRecord },
        private dialogRef: MatDialogRef<ExecuteBuyOfferComponent>,
        private tezosService: TezosService,
        private ignoreOffersService: IgnoredOffersService
    ) {}

    ngOnInit() {
        this.offer = this.data.offer;
        this.tezosService.activeWallet.pipe(untilDestroyed(this)).subscribe(w => (this.wallet = w!));

        this.form = new FormGroup({
            price: new FormControl(this.offer.priceWithoutFee.dividedBy(1e6).toNumber()),
            clearAddress: new FormControl(true, [Validators.required]),
        });
    }

    operationDone(event: OperationStatusDoneEvent) {
        if (event.success) {
            this.ignoreOffersService.remove(this.offer);
            this.dialogRef.updateSize('600px', '300px');
            this.success = true;
        } else {
            this.form.enable();
        }
    }

    cancel() {
        this.dialogRef.close(false);
    }

    continue() {
        this.dialogRef.close(true);
    }

    save() {
        const form = this.form.value;
        this.form.disable();

        const params: ExecuteBuyOfferRequest = {
            tokenId: this.offer.tokenId,
            buyer: this.offer.buyer,
            price: this.offer.price.toNumber(),
        };

        if (form.clearAddress) {
            params.domain = {
                name: this.offer.domain!.name,
                data: new RecordMetadata(dataArrayToObj(this.offer.domain!.data)),
                address: null,
                owner: this.wallet.address,
            };
        }

        this.operation = this.buyOfferBrokerService.executeOffer(params);
    }
}
