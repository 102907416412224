import { NgModule } from '@angular/core';
import { ApolloClientOptions, ApolloLink, FieldPolicy, fromPromise, InMemoryCache, toPromise, TypePolicies } from '@apollo/client/core';
import { Observable, offsetLimitPagination, relayStylePagination } from '@apollo/client/utilities';
import { APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';

import { Configuration } from '../configuration';

function dateFieldPolicy(): FieldPolicy {
    return {
        read(existing: string) {
            if (existing) {
                return dayjs(existing);
            } else {
                return null;
            }
        },
    };
}

function dateFieldUTCPolicy(): FieldPolicy {
    return {
        read(existing: string) {
            if (existing) {
                return dayjs(existing + 'Z');
            } else {
                return null;
            }
        },
    };
}

function bigNumberPolicy(): FieldPolicy {
    return {
        read(existing: string) {
            if (existing) {
                return new BigNumber(existing);
            } else {
                return null;
            }
        },
    };
}

export const typePolicies: TypePolicies = {
    Query: {
        fields: {
            domains: relayStylePagination(['where', 'order']),
            reverseRecords: relayStylePagination(['where', 'order']),
            auctions: relayStylePagination(['where', 'order']),
            offers: relayStylePagination(['where', 'order']),
            events: relayStylePagination(['where']),
        },
    },
    Domain: {
        fields: {
            expiresAtUtc: dateFieldPolicy(),
        },
    },
    DomainData: {
        fields: {
            expiresAtUtc: dateFieldPolicy(),
        },
    },
    Bid: {
        fields: {
            timestamp: dateFieldPolicy(),
            amount: bigNumberPolicy(),
        },
    },
    Auction: {
        fields: {
            endsAtUtc: dateFieldPolicy(),
            ownedUntilUtc: dateFieldPolicy(),
        },
    },
    Block: {
        fields: {
            timestamp: dateFieldPolicy(),
        },
    },
    AuctionBidEvent: {
        fields: {
            bidAmount: bigNumberPolicy(),
            transactionAmount: bigNumberPolicy(),
        },
    },
    AuctionOutbidEvent: {
        fields: {
            bidAmount: bigNumberPolicy(),
            previousBidAmount: bigNumberPolicy(),
        },
    },
    AuctionSettleEvent: {
        fields: {
            winningBid: bigNumberPolicy(),
        },
    },
    AuctionEndEvent: {
        fields: {
            winningBid: bigNumberPolicy(),
        },
    },
    AuctionWithdrawEvent: {
        fields: {
            withdrawnAmount: bigNumberPolicy(),
        },
    },
    DomainBuyEvent: {
        fields: {
            price: bigNumberPolicy(),
        },
    },
    DomainRenewEvent: {
        fields: {
            price: bigNumberPolicy(),
        },
    },
    Balance: {
        fields: {
            balance: bigNumberPolicy(),
        },
    },
    Offer: {
        fields: {
            expiresAtUtc: dateFieldPolicy(),
            price: bigNumberPolicy(),
        },
    },
    BuyOffer: {
        fields: {
            expiresAtUtc: dateFieldPolicy(),
            price: bigNumberPolicy(),
            priceWithoutFee: bigNumberPolicy(),
        },
    },
    OfferPlacedEvent: {
        fields: {
            expiresAtUtc: dateFieldPolicy(),
            price: bigNumberPolicy(),
        },
    },
    OfferUpdatedEvent: {
        fields: {
            expiresAtUtc: dateFieldPolicy(),
            price: bigNumberPolicy(),
        },
    },
    OfferExecutedEvent: {
        fields: {
            price: bigNumberPolicy(),
            priceWithoutFee: bigNumberPolicy(),
        },
    },
    BuyOfferPlacedEvent: {
        fields: {
            expiresAtUtc: dateFieldPolicy(),
            priceWithoutFee: bigNumberPolicy(),
        },
    },
    BuyOfferUpdatedEvent: {
        fields: {
            expiresAtUtc: dateFieldPolicy(),
            price: bigNumberPolicy(),
        },
    },
    BuyOfferExecutedEvent: {
        fields: {
            price: bigNumberPolicy(),
            priceWithoutFee: bigNumberPolicy(),
        },
    },
};

export const typePoliciesGovPool: TypePolicies = {
    Query: {
        fields: {
            events: offsetLimitPagination(['where']),
            delegates: offsetLimitPagination(['where', 'order_by']),
            currentDelegations: offsetLimitPagination(['where', 'order_by']),
        },
    },
    currentDelegations: {
        fields: {
            amount: bigNumberPolicy(),
        },
    },
    delegatingTo: {
        fields: {
            amount: bigNumberPolicy(),
        },
    },
    delegates: {
        fields: {
            voting_power: bigNumberPolicy(),
        },
    },
    rewards_sum_fields: {
        fields: {
            Amount: bigNumberPolicy(),
        },
    },
    PoolState: {
        fields: {
            poolSize: bigNumberPolicy(),
            sharesMinted: bigNumberPolicy(),
            rewardRate: bigNumberPolicy(),
            lastAccumulatedRewardsAt: dateFieldUTCPolicy(),
        },
    },
    event: {
        fields: {
            amount: bigNumberPolicy(),
            sharesMinted: bigNumberPolicy(),
            rewardRate: bigNumberPolicy(),
            timestamp: dateFieldUTCPolicy(),
        },
    },
    rewards: {
        fields: {
            Amount: bigNumberPolicy(),
            TotalBalance: bigNumberPolicy(),
            Apr: bigNumberPolicy(),
        },
    },
    claims: {
        fields: {
            claimable_from: dateFieldUTCPolicy(),
            amount: bigNumberPolicy(),
        },
    },
};

export const lazy = (factory: () => Promise<ApolloLink>) =>
    new ApolloLink((operation, forward) =>
        fromPromise(
            factory().then(resolved => {
                return toPromise(resolved.request(operation, forward) || Observable.of());
            })
        )
    );

export function createApollo(httpLink: HttpLink, configuration: Configuration): ApolloClientOptions<any> {
    return {
        link: httpLink.create({ uri: configuration.network.apiUrl }),
        cache: new InMemoryCache({ typePolicies }),
    };
}

export function createNamedApollo(httpLink: HttpLink, configuration: Configuration): Record<string, ApolloClientOptions<any>> {
    return {
        stats: {
            name: 'stats',
            link: httpLink.create({ uri: configuration.network.apiUrl }),
            cache: new InMemoryCache(),
        },
        tzprofiles: {
            name: 'tzprofiles',
            link: httpLink.create({ uri: configuration.network.tzprofilesApiUrl }),
            cache: new InMemoryCache(),
        },
        gvpool: {
            name: 'gvpool',
            link: httpLink.create({ uri: configuration.network.rewardsApiUrl }),
            cache: new InMemoryCache({ typePolicies: typePoliciesGovPool }),
        },
    };
}

@NgModule({
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink, Configuration],
        },
        {
            provide: APOLLO_NAMED_OPTIONS,
            useFactory: createNamedApollo,
            deps: [HttpLink, Configuration],
        },
    ],
})
export class GraphQLModule {}
