<form class="d-flex flex-column flex-fill h-100" [formGroup]="form" *transloco="let t">
    <div class="d-flex flex-column">
        <h1 mat-dialog-title class="text-truncate">
            <ng-container *ngIf="!success">{{ t('place-buy-offer-form.title', { name: domain.name }) }}</ng-container>
            <ng-container *ngIf="success">{{ t('place-buy-offer-form.success-title', { name: domain.name }) }}</ng-container>
        </h1>
        <td-operation-status class="w-100" [operation]="operation" (done)="operationDone($event)" #op></td-operation-status>
    </div>
    <mat-dialog-content class="d-flex flex-column flex-grow-1">
        <div class="d-flex flex-column gap-2" *ngIf="!success">
            <td-money-input class="mt-2" [control]="form.get('price')!" [label]="t('fields.price')"></td-money-input>
            <div class="cost-summary">
                <div>{{ t('place-buy-offer-form.fee') }} <td-tez [value]="this.fee"></td-tez></div>
                <div class="fw-bold mt-2">{{ t('place-buy-offer-form.total') }} <td-tez [value]="this.total"></td-tez></div>
            </div>
            <mat-form-field appearance="standard">
                <mat-label>{{ t('fields.buy-offer-expiration') }}</mat-label>
                <input formControlName="expiration" type="number" matInput />
                <span matSuffix>{{ t('general.days') }}</span>
                <mat-error>
                    <td-validation-messages [control]="form.get('expiration')!" [context]="{ name: t('fields.buy-offer-expiration') }"></td-validation-messages>
                </mat-error>
            </mat-form-field>
            <td-alert state="warning" *ngIf="offerExpiresLate">
                <div [tdDynamicHtml]="t('place-buy-offer-form.after-expiration', { expiration: maxValidOfferExpiration | dayjsDate })"></div>
            </td-alert>
            <div [tdDynamicHtml]="t('place-buy-offer-form.fee-info')"></div>
            <div [tdDynamicHtml]="t('place-buy-offer-form.notes', { name: domain.name })"></div>
        </div>
        <div class="d-flex justify-content-center" *ngIf="success">
            <td-share class="mt-4 pt-4" [text]="t('place-buy-offer-form.share-text', { name: domain.name })" [domainName]="domain.name"></td-share>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex justify-content-end align-items-end w-100 min-height-auto">
        <ng-container *ngIf="!success">
            <button mat-flat-button color="primary" (click)="save()" [disabled]="form.invalid || op.inProgress">
                {{ t('actions.buy-offer.make-offer') }}
            </button>
            <button mat-button (click)="cancel()" [disabled]="op.inProgress">{{ t('actions.cancel') }}</button>
        </ng-container>
        <ng-container *ngIf="success">
            <button mat-flat-button color="primary" (click)="continue()">{{ t('actions.continue') }}</button>
        </ng-container>
    </mat-dialog-actions>
</form>
