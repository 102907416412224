import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import dayjs from 'dayjs';
import { Observable } from 'rxjs';

import { DomainRecordFormComponent } from '../domains/domain-record-form.component';
import { OfferRecord } from '../graphql/offer-table-data-source';
import { OperationStatusDoneEvent } from '../shared/operation-status.component';
import { TezosWallet } from '../tezos/models';
import { DirectBrokerService } from '../tezos/nft/direct-broker.service';
import { SmartContractOperationEvent, TezosService } from '../tezos/tezos.service';

@UntilDestroy()
@Component({
    selector: 'td-remove-offer',
    templateUrl: './remove-offer.component.html',
    styleUrls: ['./remove-offer.component.scss'],
})
export class RemoveOfferComponent implements OnInit {
    operation: Observable<SmartContractOperationEvent> | null;
    submit: boolean;
    wallet: TezosWallet;

    offer: OfferRecord;

    constructor(
        private directBrokerService: DirectBrokerService,
        @Inject(MAT_DIALOG_DATA) private data: { offer: OfferRecord },
        private dialogRef: MatDialogRef<DomainRecordFormComponent>,
        private tezosService: TezosService
    ) {}

    ngOnInit() {
        this.tezosService.activeWallet.pipe(untilDestroyed(this)).subscribe(w => (this.wallet = w!));
        this.offer = this.data.offer;
    }

    operationDone(event: OperationStatusDoneEvent) {
        if (event.success) {
            this.dialogRef.close(true);
        } else {
            this.submit = false;
        }
    }

    cancel() {
        this.dialogRef.close(false);
    }

    save() {
        this.submit = true;

        this.operation = this.directBrokerService.removeOffer({
            tokenId: this.offer.tokenId,
            removeOperator: this.wallet.address === this.offer.domain!.owner && !!this.offer.domain!.expires && this.offer.domain!.expires.isAfter(dayjs()),
        });
    }
}
