import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';
import { takeUntil, switchMap, filter } from 'rxjs/operators';

import { TezosService } from '../tezos/tezos.service';
import { TezosWalletService } from '../tezos/tezos-wallet.service';
import { OverlayService } from '../overlay.service';

@Component({
    selector: 'td-auction-funds',
    templateUrl: './auction-funds.component.html',
    styleUrls: ['./auction-funds.component.scss'],
})
export class AuctionFundsComponent implements OnInit, OnDestroy {
    bidderBalance = new ReplaySubject<{ balance: number }>(1);

    private unsubscribe = new Subject<void>();

    constructor(private tezosService: TezosService, private tezosWalletService: TezosWalletService, private overlayService: OverlayService) {}

    ngOnInit(): void {
        this.tezosService.activeWallet
            .pipe(
                takeUntil(this.unsubscribe),
                filter(w => !!w),
                switchMap(w => w!.bidderBalances)
            )
            .subscribe(balances => {
                this.bidderBalance.next({ balance: balances.reduce((a, b) => a + b.balance, 0) });
            });

        this.tezosWalletService.refreshBidderBalance();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    withdraw() {
        this.overlayService.openWithdraw();
    }
}
