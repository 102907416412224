<div *transloco="let t">
    <ng-container *ngIf="viewModel$ | async as viewModel">
        <td-error class="mb-3" *ngIf="dataSource.error$ | async; let error" [message]="error.message" [title]="t('general.error')"></td-error>

        <h1>{{ t('activity.title') }}</h1>

        <td-activity-form (filterChange)="filterChanged($event)" [network]="viewModel.network" [tzDomainsClient]="viewModel.client"></td-activity-form>
        <td-activity-list [network]="viewModel.network" [dataSource]="dataSource"></td-activity-list>
    </ng-container>
</div>
