import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RecordMetadata } from '@tezos-domains/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DomainRecordFormComponent } from '../domains/domain-record-form.component';
import { OfferRecord } from '../graphql/offer-table-data-source';
import { OperationStatusDoneEvent } from '../shared/operation-status.component';
import { TezosWallet } from '../tezos/models';
import { DirectBrokerService, ExecuteOfferRequest } from '../tezos/nft/direct-broker.service';
import { SmartContractOperationEvent, TezosService } from '../tezos/tezos.service';
import { dataArrayToObj } from '../utils/convert';

@Component({
    selector: 'td-execute-offer',
    templateUrl: './execute-offer.component.html',
    styleUrls: ['./execute-offer.component.scss'],
})
export class ExecuteOfferComponent implements OnInit, OnDestroy {
    form: UntypedFormGroup;
    operation: Observable<SmartContractOperationEvent> | null;
    wallet: TezosWallet;
    offer: OfferRecord;
    success: boolean;

    private unsubscribe = new Subject<void>();

    constructor(
        private directBrokerService: DirectBrokerService,
        @Inject(MAT_DIALOG_DATA) private data: { offer: OfferRecord },
        private dialogRef: MatDialogRef<DomainRecordFormComponent>,
        private tezosService: TezosService,
        private router: Router
    ) {}

    ngOnInit() {
        this.offer = this.data.offer;
        this.tezosService.activeWallet.pipe(takeUntil(this.unsubscribe)).subscribe(w => (this.wallet = w!));
        this.form = new UntypedFormGroup({
            price: new UntypedFormControl(this.offer.price.dividedBy(1e6).toNumber()),
            clearAddress: new UntypedFormControl(true, [Validators.required]),
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    operationDone(event: OperationStatusDoneEvent) {
        if (event.success) {
            this.dialogRef.updateSize('600px', '300px');
            this.success = true;
        } else {
            this.form.enable();
        }
    }

    cancel() {
        this.dialogRef.close(false);
    }
    close() {
        this.dialogRef.close(true);
    }

    async continue() {
        await this.router.navigate(['/domain', this.offer.domain!.name]);
        this.dialogRef.close(true);
    }

    save() {
        const form = this.form.value;
        this.form.disable();

        const params: ExecuteOfferRequest = {
            tokenId: this.offer.tokenId,
            seller: this.offer.seller,
            price: this.offer.price.toNumber(),
        };

        if (form.clearAddress) {
            params.domain = {
                name: this.offer.domain!.name,
                data: new RecordMetadata(dataArrayToObj(this.offer.domain!.data)),
                address: null,
                owner: this.wallet.address,
            };
        }

        this.operation = this.directBrokerService.executeOffer(params);
    }
}
