import { DataSource } from '@angular/cdk/table';
import { DataSourceBase, DataSourceOptions, DataSourcePagination, ProcessedData } from '../data-source-base';
import { GovernanceDataSourcePagination } from './governance-datasource-pagination';
import { Unarray } from '../utils/types';
import { PoolHistoryQuery, PoolHistoryQueryVariables } from './governance-graphql.generated';

export type PoolHistoryEvent = Unarray<PoolHistoryQuery['events']>;

export class PoolHistoryDataSource
    extends DataSourceBase<PoolHistoryQuery, PoolHistoryEvent[], PoolHistoryQueryVariables>
    implements DataSource<PoolHistoryEvent>
{
    constructor(options: DataSourceOptions<PoolHistoryQuery, PoolHistoryQueryVariables>) {
        super({
            ...options,
            defaultVariables: { limit: 30 },
        });
    }

    protected transformData(data: PoolHistoryQuery): ProcessedData<PoolHistoryEvent[]> {
        return {
            data: data.events,
            isEmpty: !data.events.length,
            totalCount: data.events.length,
        };
    }

    protected createPagination(): DataSourcePagination<PoolHistoryQueryVariables> {
        return new GovernanceDataSourcePagination<PoolHistoryQueryVariables>();
    }
}
