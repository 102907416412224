<div *transloco="let t">
    <td-error *ngIf="dataSource.error$ | async; let error" [message]="error.message" [title]="t('general.error')"></td-error>

    <div class="table-filter">
        <form [formGroup]="form" action="." (ngSubmit)="reload()" class="d-flex flex-column flex-sm-row justify-content-between">
            <div class="d-flex flex-grow-1 flex-sm-grow-0">
                <mat-form-field class="flex-grow-1 flex-sm-grow-0" appearance="standard" floatLabel="never">
                    <input
                        matInput
                        formControlName="filter"
                        autocapitalize="off"
                        autocomplete="off"
                        autocorrect="off"
                        type="search"
                        type="search"
                        [placeholder]="t('domain-table.filter-placeholder')"
                    />
                    <fa-icon matSuffix class="clear-button primary" icon="times" (click)="form.get('filter')!.setValue('')"></fa-icon>
                </mat-form-field>
            </div>
        </form>
    </div>

    <div *ngIf="(dataSource.empty$ | async) && (hideResults | async) === false" class="empty-message p-3 w-100 text-center smooth-shadow">
        {{ t('auction-table.no-auctions') }}
    </div>

    <div class="table-container smooth-shadow d-flex" infiniteScroll (scrolled)="scrolled()" [hidden]="(dataSource.empty$ | async) || (hideResults | async)">
        <mat-table
            class="table"
            [dataSource]="dataSource"
            matSort
            (matSortChange)="reload()"
            [matSortActive]="sortField"
            [matSortDirection]="sortDirection"
            matSortDisableClear
            #sort="matSort"
        >
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header="DOMAIN_NAME">{{ t('fields.name') }}</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div class="text-truncate w-100">
                        <td-domain [domain]="row.domainName"></td-domain>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="timeLeft">
                <mat-header-cell class="col-time" *matHeaderCellDef mat-sort-header="ENDS_AT">{{ t('fields.ends') }}</mat-header-cell>
                <mat-cell class="col-time" *matCellDef="let row">
                    <span [matTooltip]="row.endsAtUtc | dayjsDate"><td-humanized-date [value]="row.endsAtUtc"></td-humanized-date></span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="bidCount">
                <mat-header-cell class="col-bid-count" *matHeaderCellDef mat-sort-header="BID_COUNT">{{ t('fields.bid-count') }}</mat-header-cell>
                <mat-cell class="col-bid-count" *matCellDef="let row">
                    {{ row.bidCount }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="currentBid">
                <mat-header-cell class="col-bid" *matHeaderCellDef mat-sort-header="HIGHEST_BID_AMOUNT">{{ t('fields.current-bid') }}</mat-header-cell>
                <mat-cell class="col-bid" *matCellDef="let row">
                    <td-tez [value]="row.highestBid.amount"></td-tez>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="['name', 'timeLeft', 'bidCount', 'currentBid']"></mat-header-row>
            <mat-row *matRowDef="let row; columns: ['name', 'timeLeft', 'bidCount', 'currentBid']"></mat-row>
        </mat-table>
    </div>
    <div *ngIf="dataSource.loading$ | async" class="w-100 mt-3 d-flex justify-content-center">
        <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>
</div>
