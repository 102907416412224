<ng-container *transloco="let t">
    <td-advanced-select
        key="derivation_path"
        [options]="builtInDerivationPaths"
        [valueValidators]="validators"
        [(ngModel)]="derivationPath"
        (ngModelChange)="setDerivationPath()"
        [addConfig]="{
            title: t('settings.derivation-path-add-title'),
            labelLabel: t('settings.derivation-path-add-label'),
            valueLabel: t('settings.derivation-path-add-value')
        }"
    ></td-advanced-select>
</ng-container>
