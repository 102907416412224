import { Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSort, SortDirection } from '@angular/material/sort';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { HistoryStateService } from '../browser/history-state.service';
import { PageService } from '../browser/page.service';
import { AuctionTableDataSource } from '../graphql/auction-table-data-source';
import { DataSourceFactory } from '../graphql/data-source-factory';
import { AuctionListQueryVariables, AuctionOrder, AuctionOrderField, AuctionsFilter, AuctionState } from '../graphql/graphql.generated';
import { TezosWallet } from '../tezos/models';

export type AuctionTableState = {
    hotAuctionTable?: {
        sort: {
            field: string;
            direction: SortDirection;
        };
        form: any;
    };
};

@UntilDestroy()
@Component({
    selector: 'td-hot-auction-table',
    templateUrl: './hot-auction-table.component.html',
    styleUrls: ['./hot-auction-table.component.scss'],
})
export class HotAuctionTableComponent implements OnInit, OnChanges {
    dataSource: AuctionTableDataSource;
    form: FormGroup<{
        filter: FormControl<string>;
    }>;

    sortField: string;
    sortDirection: SortDirection;
    hideResults = new BehaviorSubject<boolean>(true);
    states = AuctionState;
    columns: string[] = [];
    wallet: TezosWallet | null;

    @ViewChild(MatSort) sorter: MatSort;
    @ViewChild(InfiniteScrollDirective) infiniteScroll: InfiniteScrollDirective;

    constructor(
        private dataSourceFactory: DataSourceFactory,
        private formBuilder: FormBuilder,
        private historyStateService: HistoryStateService,
        private pageService: PageService
    ) {}

    ngOnInit(): void {
        this.dataSource = this.dataSourceFactory.createAuctionTableDataSource();
        this.pageService.setTitle('hot-auctions');

        this.form = this.formBuilder.group({
            filter: this.formBuilder.control('', { nonNullable: true }),
        });

        this.form
            .get('filter')!
            .valueChanges.pipe(debounceTime(300), untilDestroyed(this))
            .subscribe(() => {
                this.hideResults.next(true);
                this.reload();
            });

        this.dataSource.initialLoading$.pipe(untilDestroyed(this)).subscribe(l => {
            if (!l) {
                this.hideResults.next(false);
            }
        });

        this.restoreState();
    }

    ngOnChanges() {
        // check because changes fire before init
        if (this.form) {
            this.restoreState();
        }
    }

    scrolled() {
        this.dataSource.loadMore();
    }

    reload() {
        if (this.infiniteScroll) {
            this.infiniteScroll.destroyScroller();
            this.infiniteScroll.setup();
        }

        const where: AuctionsFilter = {
            state: { in: [AuctionState.InProgress] },
            domainName: { like: this.form.value.filter },
        };

        const variables: AuctionListQueryVariables = {
            where,
            order: this.getSort(),
        };

        this.historyStateService.merge<AuctionTableState>({
            hotAuctionTable: { form: this.form.value, sort: { field: this.sorter.active, direction: this.sorter.direction } },
        });

        this.dataSource.load(variables);
    }

    private restoreState() {
        this.hideResults.next(true);
        const state = this.historyStateService.get<AuctionTableState>();

        if (state.hotAuctionTable) {
            this.form.setValue(state.hotAuctionTable.form, { emitEvent: false });
            this.sortField = state.hotAuctionTable.sort.field;
            this.sortDirection = state.hotAuctionTable.sort.direction;
        } else {
            this.sortField = AuctionOrderField.BidAmountSum;
            this.sortDirection = 'desc';
        }

        setTimeout(() => this.reload());
    }

    private getSort(): AuctionOrder {
        return {
            field: this.sorter.active as any,
            direction: (this.sorter.direction.toUpperCase() as any) || null,
        };
    }
}
