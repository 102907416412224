import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationResult, validateAddress } from '@taquito/utils';
import { DomainNameValidationResult, DomainNameValidator, getTld } from '@tezos-domains/core';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { TaquitoTezosDomainsClient } from '@tezos-domains/taquito-client';
import { DomainService } from '../domains/domain.service';
import { TezosDomainsClientService } from '../tezos/integration/tezos-domains-client.service';
import { isAddress as isEthAddress } from 'web3-validator';

export function isEmptyInputValue(value: any): boolean {
    // we don't check for string here so it also works with arrays
    return value == null || value.length === 0;
}

export class TdValidators {
    static tezosAddress(allowEntrypoint = true): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value)) {
                return null;
            }

            const value = control.value as string;

            const validation = validateAddress(value);

            if (validation === ValidationResult.VALID) {
                if (!allowEntrypoint && value.includes('%')) {
                    return { tezosAddress: { type: 'entrypoint' } };
                }

                return null;
            }

            return { tezosAddress: { type: 'invalid' } };
        };
    }

    static bytesString: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        if (isEmptyInputValue(control.value)) {
            return null;
        }

        const value = control.value as string;

        if (!/^(?:[a-f0-9]{2})+$/g.test(value)) {
            return { bytesString: true };
        }

        return null;
    };

    static twitterHandle: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        if (isEmptyInputValue(control.value)) {
            return null;
        }

        const value = control.value as string;

        if (!/^[a-zA-Z0-9_]{4,15}$/g.test(value)) {
            return { twitterHandle: true };
        }

        return null;
    };

    static etherlinkAddress: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        if (isEmptyInputValue(control.value)) {
            return null;
        }

        const value = control.value as string;

        if (!isEthAddress(value)) {
            return { etherlinkAddress: true };
        }

        return null;
    };

    static governanceProfile: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        if (isEmptyInputValue(control.value)) {
            return null;
        }

        const urlValidator = TdValidators.url();
        const urlValidationResult = urlValidator(control);

        if (urlValidationResult) {
            return urlValidationResult;
        }

        const value = control.value as string;

        if (!value.startsWith('https://talk.tezos.domains/t/how-to-become-a-delegate/34/')) {
            return { governanceProfile: true };
        }

        return null;
    };

    static label(parent: string, validator: DomainNameValidator): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value)) {
                return null;
            }

            const value = control.value as string;
            const name = `${value}.${parent}`;
            const tld = getTld(name);
            const validation = validator.isValidWithKnownTld(name);

            if (validation !== DomainNameValidationResult.VALID) {
                return { label: { type: `${tld}-${validation}` } };
            }

            if (value.includes('.')) {
                return { label: { type: `${tld}-${DomainNameValidationResult.UNSUPPORTED_CHARACTERS}` } };
            }

            return null;
        };
    }

    static domainName(validator: DomainNameValidator): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value)) {
                return null;
            }

            const value = control.value as string;
            const validation = validator.isValidWithKnownTld(value);

            if (validation !== DomainNameValidationResult.VALID) {
                return { domainName: true };
            }

            return null;
        };
    }

    static except(values: string[]): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value)) {
                return null;
            }

            const value = control.value as string;

            if (values.includes(value)) {
                return { except: { existing: values.join(', ') } };
            }

            return null;
        };
    }

    static url(forceHttps?: boolean): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value)) {
                return null;
            }

            const value = control.value as string;

            if (forceHttps && !value.startsWith('https://')) {
                return { url: true };
            }

            if (
                !/^(?:https?:\/\/)(?:\S+(?::\S*)?@)?(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*\.?)(?::\d{2,5})?(?:[\/?#]\S*)?$/gi.test(
                    value
                )
            ) {
                return { url: true };
            }

            return null;
        };
    }

    static contentUrl(control: AbstractControl): ValidationErrors | null {
        if (isEmptyInputValue(control.value)) {
            return null;
        }

        const value = control.value as string;

        if (
            !/^(?:(?:ipfs|ipns|https?):\/\/)(?:\S+(?::\S*)?@)?(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*\.?)(?::\d{2,5})?(?:[\/?#]\S*)?$/gi.test(
                value
            )
        ) {
            return { contentUrl: true };
        }

        return null;
    }

    static json: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        if (isEmptyInputValue(control.value)) {
            return null;
        }

        try {
            JSON.parse(control.value);

            return null;
        } catch {
            return { json: true };
        }
    };

    static md5: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        if (isEmptyInputValue(control.value)) {
            return null;
        }

        const value = control.value as string;

        if (!/^[a-f0-9]{32}$/i.test(value)) {
            return { md5: true };
        }

        return null;
    };

    static derivationPath: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        if (isEmptyInputValue(control.value)) {
            return null;
        }

        const value = control.value as string;

        if (!/^44'\/1729'(\/\d+')*$/.test(value)) {
            return { derivationPath: true };
        }

        return null;
    };

    static number(maxDecimalPlaces = 0): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value)) {
                return null;
            }

            const value = control.value as string;
            const regex = new RegExp(`^\\d+(\\.(?=\\d))?\\d{0,${maxDecimalPlaces}}$`);

            if (!regex.test(value)) {
                return { number: { maxDecimalPlaces } };
            }

            return null;
        };
    }
}

@Injectable({ providedIn: 'root' })
export class TdAsyncValidatorsFactory {
    private tezosDomains: TaquitoTezosDomainsClient;

    constructor(private domainService: DomainService, private tezosDomainsClientService: TezosDomainsClientService) {
        this.tezosDomainsClientService.current.subscribe(c => (this.tezosDomains = c));
    }

    subdomainNameAvailable(parent: string): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            if (isEmptyInputValue(control.value)) {
                return of(null);
            }

            const value = control.value as string;

            return timer(300).pipe(
                switchMap(() => this.domainService.isDomainAvailable(`${value}.${parent}`)),
                map(available => {
                    if (!available) {
                        return { duplicateSubdomain: true };
                    }

                    return null;
                }),
                catchError(err => of({ asyncError: { message: err?.message } }))
            );
        };
    }

    validRecipient(options?: { disallowKT?: boolean }): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            if (isEmptyInputValue(control.value)) {
                return of(null);
            }

            const value = control.value as string;

            const addressValidation = validateAddress(control.value);

            if (addressValidation === ValidationResult.VALID) {
                if (options?.disallowKT && value.startsWith('KT1')) {
                    return of({ validRecipient: { type: 'kt-not-allowed' } });
                }

                return of(null);
            } else {
                const validation = this.tezosDomains.validator.isValidWithKnownTld(value);

                if (validation === DomainNameValidationResult.VALID) {
                    return timer(300).pipe(
                        switchMap(() => this.tezosDomains.resolver.resolveNameToAddress(value)),
                        map(address => {
                            if (address) {
                                return null;
                            } else {
                                return { validRecipient: { type: 'no-address' } };
                            }
                        }),
                        catchError(err => of({ asyncError: { message: err?.message } }))
                    );
                }
            }

            return of({ validRecipient: { type: 'invalid-input' } });
        };
    }

    domainWithAddress(address: string) {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            if (isEmptyInputValue(control.value)) {
                return of(null);
            }

            const value = control.value as string;

            const validation = this.tezosDomains.validator.isValidWithKnownTld(value);

            if (validation === DomainNameValidationResult.VALID) {
                return timer(300).pipe(
                    switchMap(() => this.domainService.hasAddress(value, address)),
                    map(has => {
                        if (has) {
                            return null;
                        } else {
                            return { domainWithAddress: { type: 'address-mismatch' } };
                        }
                    }),
                    catchError(err => of({ asyncError: { message: err?.message } }))
                );
            }

            return of({ domainWithAddress: { type: 'invalid-input' } });
        };
    }
}
