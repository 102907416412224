import { Component, OnInit } from '@angular/core';
import { PageService } from '../browser/page.service';

@Component({
    selector: 'td-hot-offer-list',
    templateUrl: './hot-offer-list.component.html',
    styleUrls: ['./hot-offer-list.component.scss'],
})
export class HotOfferListComponent implements OnInit {
    constructor(private pageService: PageService) {}

    ngOnInit() {
        this.pageService.setTitle('hot-offers');
    }
}
