<ng-container *transloco="let t">
    <div class="w-100 px-4 py-2 d-flex gap-3 align-items-center">
        <div>
            <div class="checkbox-placeholder" *ngIf="!canSelect"></div>
            <mat-checkbox (click)="$event.stopPropagation()" *ngIf="canSelect" (change)="toggle($event)" [checked]="selected"></mat-checkbox>
        </div>
        <ngx-avatars [value]="name | slice : 0 : 2" size="40" class="d-none d-sm-block"></ngx-avatars>
        <div class="hide-overflow flex-grow-1 d-flex flex-sm-row">
            <div [ngClass]="{ 'hide-overflow': media.isActive('gt-xs') }" class="text-truncate d-flex flex-column flex-sm-row gap-0 gap-sm-2 flex-grow-1">
                <div class="d-flex flex-column">
                    <div class="d-flex">
                        <td-domain [domain]="name | truncate : 40" class="txt-truncate me-2" [maxChars]="200"></td-domain>
                        <fa-icon
                            *ngIf="label.length > configuration.maxWebsiteDomainNameLength"
                            class="icon-warning no-underline"
                            matTooltipPosition="above"
                            [matTooltip]="t('buy-bulk.long-domain-name-warning', {tld, length:configuration.maxWebsiteDomainNameLength })"
                            icon="triangle-exclamation"
                        ></fa-icon>
                    </div>
                </div>
            </div>
            <div class="txt-lg d-flex align-items-center justify-content-start" *ngIf="price">
                <td-tez [value]="price"></td-tez>
            </div>
        </div>

        <div class="d-flex align-items-center" *ngIf="buttonText">
            <button mat-flat-button color="primary" [disabled]="buttonDisabled" *ngIf="buttonText">
                {{ buttonText }}
            </button>
        </div>
    </div>
</ng-container>
