<ng-container *transloco="let t">
    <div *ngIf="(dataSource.empty$ | async) && (hideResults | async) === false" class="empty-message p-3 w-100 text-center smooth-shadow">
        {{ t('delegator-table.no-delegators') }}
    </div>

    <div
        class="table-container smooth-shadow d-flex"
        infiniteScroll
        (scrolled)="scrolled()"
        [class.d-none]="(dataSource.empty$ | async) || (hideResults | async)"
    >
        <mat-table
            class="table"
            [dataSource]="dataSource"
            matSort
            [matSortActive]="sortField"
            [matSortDirection]="sortDirection"
            (matSortChange)="sortChanged()"
            matSortDisableClear
        >
            <ng-container matColumnDef="address">
                <mat-header-cell *matHeaderCellDef>{{ t('delegator-table.header-address') }}</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <td-address [address]="row.from" [reverseRecord]="row.reverseRecord" class="ms-2"></td-address>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="amount">
                <mat-header-cell class="col-amount" *matHeaderCellDef mat-sort-header="amount">{{ t('delegator-table.header-votes') }}</mat-header-cell>
                <mat-cell *matCellDef="let row" class="col-amount">
                    <span class="text-truncate">{{ row.amount | formatMutez : '1.2-2' }}</span>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="['address', 'amount']"></mat-header-row>
            <mat-row *matRowDef="let row; columns: ['address', 'amount']"></mat-row>
        </mat-table>
    </div>
    <div *ngIf="dataSource.loading$ | async" class="w-100 mt-3 d-flex justify-content-center">
        <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>
</ng-container>
