<ng-container *transloco="let t">
    <button
        [matMenuTriggerFor]="menu"
        (menuOpened)="menuOpened()"
        mat-stroked-button
        (click)="priceClick()"
        [ngClass]="{ 'highlighted-stroked': priceDisplay != 'noPrice' }"
    >
        <ng-container [ngSwitch]="priceDisplay">
            <div *ngSwitchCase="'bounded'">
                {{ t('filters.price.filter-between') }}&nbsp;<td-tez [mutez]="false" [value]="crtPrice?.min!"></td-tez>&nbsp;-&nbsp;<td-tez
                    [mutez]="false"
                    [value]="crtPrice?.max!"
                ></td-tez>
            </div>
            <div *ngSwitchCase="'lowerBound'">{{ t('filters.price.filter-min') }}&nbsp;<td-tez [mutez]="false" [value]="crtPrice?.min!"></td-tez></div>
            <div *ngSwitchCase="'upperBound'">{{ t('filters.price.filter-max') }}&nbsp;<td-tez [mutez]="false" [value]="crtPrice?.max!"></td-tez></div>
            <div *ngSwitchCase="'noPrice'">{{ t('filters.price.filter-label') }}</div>
        </ng-container>
    </button>

    <mat-menu #menu="matMenu" class="price-filter-menu" (closed)="menuClosed()">
        <form [formGroup]="form" action="." (ngSubmit)="submit()">
            <div
                (click)="$event.stopPropagation()"
                (keydown.tab)="$event.stopPropagation()"
                (keydown.shift.tab)="$event.stopPropagation()"
                class="d-flex flex-column"
                cdkTrapFocus
            >
                <div class="p-4 d-flex flex-column flex-grow-1">
                    <div class="pb-3">{{ title }}</div>
                    <div class="d-flex flex-column flex-md-row gap-1 gap-md-3">
                        <mat-form-field class="w-100" appearance="standard">
                            <mat-label>{{ t('filters.price.from') }}</mat-label>
                            <input formControlName="min" matInput #minInput />
                            <mat-error>
                                <td-validation-messages [control]="form.get('min')!" [context]="{ name: t('filters.price.from') }"></td-validation-messages>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="w-100" appearance="standard">
                            <mat-label>{{ t('filters.price.to') }}</mat-label>
                            <input formControlName="max" matInput />
                            <mat-error>
                                <td-validation-messages [control]="form.get('max')!" [context]="{ name: t('filters.price.to') }"></td-validation-messages>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="p-3 d-flex justify-content-end align-items-end gap-2">
                    <button type="button" mat-stroked-button class="js-cancel-btn" (click)="clear()">{{ t('actions.clear') }}</button>
                    <button mat-flat-button color="primary" class="js-submit" [disabled]="isDisabled()" type="submit">{{ t('actions.filter') }}</button>
                </div>
            </div>
        </form>
    </mat-menu>
</ng-container>
