<form [formGroup]="form" action="." *transloco="let t">
    <div class="d-flex align-items-center">
        <td-recipient
            class="w-100 me-2"
            [required]="true"
            [control]="form.get('inputAddress')!"
            [label]="t('operator-permissions.new-operator-address')"
            [placeholder]="t('operator-permissions.please-enter-operator-address')"
        >
        </td-recipient>

        <button type="submit" mat-stroked-button [disabled]="form.invalid || !form.get('inputAddress')?.value" (click)="submitSearch()">
            <fa-icon icon="user-plus" class="mx-0"></fa-icon>
        </button>
    </div>
</form>
