import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSort, SortDirection } from '@angular/material/sort';
import mergeObject from 'lodash-es/merge';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { BehaviorSubject, merge, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { HistoryStateService } from '../browser/history-state.service';
import { DataSourceFactory } from '../graphql/data-source-factory';
import { OfferListQueryVariables, OfferOrder, OfferOrderField, OffersFilter, OfferState } from '../graphql/graphql.generated';
import { OfferRecord, OfferTableDataSource } from '../graphql/offer-table-data-source';
import { OverlayService } from '../overlay.service';
import { TezosWallet } from '../tezos/models';
import { TezosService } from '../tezos/tezos.service';

type FilterCategory = {
    name: OfferTableFilterCategory;
    predicateBuilder: () => OffersFilter;
};

export type OfferTableFilterCategory = 'all' | 'active' | 'sold';

export type OfferTableState = {
    auctionTable?: {
        sort: {
            field: string;
            direction: SortDirection;
        };
        form: any;
    };
};

@Component({
    selector: 'td-offer-table',
    templateUrl: './offer-table.component.html',
    styleUrls: ['./offer-table.component.scss'],
})
export class OfferTableComponent implements OnInit, OnChanges, OnDestroy {
    @Input() address: string;
    @Input() hideFilters = false;

    @Output() domainsSelected = new EventEmitter<OfferRecord[]>();

    dataSource: OfferTableDataSource;
    form: UntypedFormGroup;
    categories: FilterCategory[];
    selection = new SelectionModel<OfferRecord>(true, []);

    sortField: string;
    sortDirection: SortDirection;
    hideResults = new BehaviorSubject<boolean>(true);
    states = OfferState;
    columns: string[] = [];
    wallet: TezosWallet | null;
    invalidTooltipKeys: Record<string, string> = {
        DOMAIN_DOES_NOT_EXIST: 'offer-table.invalid-domain-does-not-exist',
        DOMAIN_OPERATORS_CONTRACT_MISSING: 'offer-table.invalid-operator-missing',
        DOMAIN_EXPIRED: 'offer-table.invalid-domain-expired-tooltip',
        OFFER_SELLER_DOMAIN_OWNER_MISMATCH: 'offer-table.invalid-seller',
    };

    cancellableOfferState: Record<string, boolean> = {
        [OfferState.Active]: true,
        [OfferState.DomainOperatorsContractMissing]: true,
        [OfferState.DomainExpired]: true,
        [OfferState.DomainDoesNotExist]: true,
        [OfferState.OfferSellerDomainOwnerMismatch]: true,
        [OfferState.DomainIsExpiringSoon]: true,
    };

    private unsubscribe = new Subject<void>();
    private loadedCancellableDomains: OfferRecord[];

    @ViewChild(MatSort) sorter: MatSort;
    @ViewChild(InfiniteScrollDirective) infiniteScroll: InfiniteScrollDirective;

    constructor(
        private dataSourceFactory: DataSourceFactory,
        private formBuilder: UntypedFormBuilder,
        private historyStateService: HistoryStateService,
        private tezosService: TezosService,
        private overlayService: OverlayService
    ) {}

    ngOnInit(): void {
        this.dataSource = this.dataSourceFactory.createOfferTableDataSource();

        this.categories = [
            {
                name: 'all',
                predicateBuilder: () => ({
                    state: {
                        notIn: [OfferState.Removed],
                    },
                }),
            },
            {
                name: 'active',
                predicateBuilder: () => ({
                    state: { in: [OfferState.Active] },
                }),
            },
            {
                name: 'sold',
                predicateBuilder: () => ({
                    state: { in: [OfferState.Executed] },
                }),
            },
        ];

        this.form = this.formBuilder.group({
            filter: this.formBuilder.control(''),
            category: this.formBuilder.control('all'),
        });

        merge(this.form.get('filter')!.valueChanges.pipe(debounceTime(300)), this.form.get('category')!.valueChanges.pipe(debounceTime(0)))
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.hideResults.next(true);
                this.reload();
            });

        this.dataSource.data$
            .pipe(
                filter(d => !!d),
                takeUntil(this.unsubscribe)
            )
            .subscribe(allData => {
                this.loadedCancellableDomains = allData.filter(r => !!this.cancellableOfferState[r.state]);
            });

        this.dataSource.initialLoading$.pipe(takeUntil(this.unsubscribe)).subscribe(l => {
            if (!l) {
                this.hideResults.next(false);
            }
        });

        this.restoreState();

        this.tezosService.activeWallet.pipe(takeUntil(this.unsubscribe)).subscribe(w => {
            this.wallet = w;
            if (w?.address === this.address) {
                this.columns = ['selection', 'name', 'expiration', 'price', 'status', 'actions'];
            } else {
                this.columns = ['name', 'expiration', 'price', 'status'];
            }
        });
    }

    edit(offer: OfferRecord) {
        this.overlayService.openPlaceOffer(offer, () => this.reload());
    }

    cancel(offer: OfferRecord) {
        this.overlayService.openRemoveOffer(offer, () => this.reload());
    }

    ngOnChanges() {
        // check because changes fire before init
        if (this.categories && this.form) {
            this.restoreState();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    scrolled() {
        this.dataSource.loadMore();
    }

    sortList() {
        this.selection.clear();
        this.domainsSelected.emit(this.selection.selected);

        this.reload();
    }

    clearSelection() {
        this.selection.clear();
        this.domainsSelected.next([]);
    }

    reload() {
        if (this.infiniteScroll) {
            this.infiniteScroll.destroyScroller();
            this.infiniteScroll.setup();
        }

        const where: OffersFilter = mergeObject(
            {
                domainName: { like: this.form.value.filter },
                sellerAddress: { equalTo: this.address },
            },
            this.getCategoryFilter()
        );

        const variables: OfferListQueryVariables = {
            where,
            order: this.getSort(),
        };

        this.historyStateService.merge<OfferTableState>({
            auctionTable: { form: this.form.value, sort: { field: this.sorter.active, direction: this.sorter.direction } },
        });

        this.dataSource.load(variables);
    }

    toggleSelectAll(): void {
        this.areAllSelected() ? this.selection.clear() : this.selection.select(...this.loadedCancellableDomains);
        this.domainsSelected.emit(this.selection.selected);
    }

    toggleSelectOne($event: MatCheckboxChange, row: OfferRecord): void {
        if ($event) {
            this.selection.toggle(row);
            this.domainsSelected.emit(this.selection.selected);
        }
    }

    areAllSelected(): boolean {
        return this.selection.selected.length === this.loadedCancellableDomains.length;
    }

    private restoreState() {
        this.hideResults.next(true);
        const state = this.historyStateService.get<OfferTableState>();

        if (state.auctionTable) {
            this.form.setValue(state.auctionTable.form, { emitEvent: false });
            this.sortField = state.auctionTable.sort.field;
            this.sortDirection = state.auctionTable.sort.direction;
        } else {
            this.sortField = OfferOrderField.CreatedAt;
            this.sortDirection = 'desc';
        }

        setTimeout(() => this.reload());
    }

    private getCategoryFilter() {
        const categoryName = this.form.value.category;
        const category = this.categories.find(c => c.name === categoryName)!;
        return category.predicateBuilder();
    }

    private getSort(): OfferOrder {
        return {
            field: this.sorter.active as any,
            direction: (this.sorter.direction.toUpperCase() as any) || null,
        };
    }
}
