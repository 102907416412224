import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { skip } from 'rxjs/operators';
import { MediaObserver } from './shared/media-observer.service';

@Component({
    selector: 'td-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    /**
     *
     */
    constructor(private media: MediaObserver, private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.media
            .asObservable()
            .pipe(skip(1))
            .subscribe(() => {
                this.cdr.detectChanges();
            });
    }
}
