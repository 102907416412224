<form class="d-flex flex-fill flex-column h-100 gap-1" [formGroup]="form" *transloco="let t">
    <h1 mat-dialog-title class="text-truncate mb-0 flex-shrink-0">{{ t('bulk-renew.renew-multiple-domains', { count: domains.length }) }}</h1>
    <td-operation-status class="flex-shrink-0" [operation]="operation" (done)="operationDone($event)" #op></td-operation-status>
    <mat-form-field appearance="standard">
        <mat-label>{{ t('fields.registration-period') }}</mat-label>
        <input formControlName="duration" type="number" matInput max="100" min="1" required />
        <span matSuffix>{{ t('general.years') }}</span>
        <mat-error>
            <td-validation-messages [control]="form.get('duration')!" [context]="{ name: t('fields.registration-period') }"></td-validation-messages>
        </mat-error>
    </mat-form-field>
    <mat-dialog-content class="flex-grow-1">
        <div class="d-flex flex-column h-100 gap-1">
            <ng-container *ngIf="loading$ | async; else renewContent">
                <div class="d-flex justify-content-center align-items-center h-100">
                    <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
                </div>
            </ng-container>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex justify-content-end align-items-end flex-column gap-3 w-100 flex-nowrap min-height-auto">
        <td-money-input [label]="t('general.price')" [control]="form.get('price')!" [displayOnly]="true" class="w-100"></td-money-input>
        <div class="d-flex justify-content-end align-items-end w-100">
            <button mat-flat-button color="primary" (click)="renew()" [disabled]="form.invalid || op.inProgress || (loading$ | async)">
                {{ t('actions.renew') }}
            </button>
            <button mat-button (click)="cancel()" [disabled]="form.disabled">{{ t('actions.cancel') }}</button>
        </div>
    </mat-dialog-actions>
</form>

<ng-template #renewContent>
    <ng-container *transloco="let t">
        <mat-list *ngIf="domainList$ | async as domainList" dense>
            <mat-list-item *ngFor="let item of domainList">
                <div class="d-flex w-100 gap-1">
                    <div
                        [matTooltip]="item.domain.name"
                        [matTooltipDisabled]="item.domain.name.length <= 30"
                        class="flex-grow-1 overflow-hidden js-domain-name"
                        [ngClass]="{ 'no-underline': item.domain.name.length <= 30 }"
                    >
                        <fa-icon
                            class="alert-icon me-2 no-underline"
                            icon="exclamation-triangle"
                            [matTooltip]="t('renew.another-owner-warning')"
                            *ngIf="wallet?.address !== item.domain.owner"
                        ></fa-icon>
                        <fa-icon
                            class="alert-icon me-2 no-underline"
                            icon="exclamation-triangle"
                            [matTooltip]="t('domain-detail.node-error-message')"
                            *ngIf="!item.info"
                        ></fa-icon>
                        {{ item.domain.name | truncate: 30 }}
                    </div>
                    <div
                        class="flex-shrink-0 text-end js-new-expiration new-expiration"
                        [innerHtml]="t('bulk-renew.until', { newExpiration: item.newExpiration | dayjsDate })"
                    ></div>
                </div>
            </mat-list-item>
        </mat-list>
    </ng-container>
</ng-template>
