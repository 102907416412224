import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import dayjs from 'dayjs';

import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { BehaviorSubject, Subject, interval, race } from 'rxjs';
import { takeUntil, debounceTime, skip } from 'rxjs/operators';
import { HistoryStateService } from '../browser/history-state.service';
import { DataSourceFactory } from '../graphql/data-source-factory';
import { EventsDataSource } from '../graphql/events-table-data-source';
import { EventsFilter, EventType, EventsQueryVariables } from '../graphql/graphql.generated';
import { TezosNetwork } from '../tezos/models';
import { TezosNetworkService } from '../tezos/tezos-network.service';
import { TdValidators } from '../utils/form-validators';
import { TezosDomainsClientService } from '../tezos/integration/tezos-domains-client.service';

interface AuditState {
    audit?: {
        form: any;
    };
}

@Component({
    selector: 'td-audit',
    templateUrl: './audit.component.html',
    styleUrls: ['./audit.component.scss'],
})
export class AuditComponent implements OnInit, OnDestroy {
    dataSource: EventsDataSource;
    form: UntypedFormGroup;
    maxDate = new Date();
    minDate = new Date(2021, 0, 1);

    hideResults = new BehaviorSubject<boolean>(true);
    network: TezosNetwork;
    columns: string[];
    types: string[];

    private unsubscribe = new Subject<void>();

    @ViewChild(MatSort) sorter: MatSort;
    @ViewChild(InfiniteScrollDirective) infiniteScroll: InfiniteScrollDirective;

    constructor(
        private dataSourceFactory: DataSourceFactory,
        private formBuilder: UntypedFormBuilder,
        private historyStateService: HistoryStateService,
        private tezosNetworkService: TezosNetworkService,
        private tezosDomainsClientService: TezosDomainsClientService
    ) {}

    ngOnInit(): void {
        this.tezosNetworkService.activeNetwork.pipe(takeUntil(this.unsubscribe)).subscribe(n => (this.network = n));
        this.dataSource = this.dataSourceFactory.createEventsDataSource();

        this.types = Object.values(EventType);

        this.tezosDomainsClientService.current.pipe(takeUntil(this.unsubscribe)).subscribe(c => {
            const init = !this.form;

            this.form = this.formBuilder.group({
                from: this.formBuilder.control(''),
                until: this.formBuilder.control(''),
                address: this.formBuilder.control('', TdValidators.tezosAddress()),
                name: this.formBuilder.control('', TdValidators.domainName(c.validator)),
                types: this.formBuilder.control([]),
            });

            this.form.valueChanges
                .pipe(debounceTime(300), takeUntil(race(this.unsubscribe, this.tezosDomainsClientService.current.pipe(skip(1)))))
                .subscribe(() => {
                    if (this.form.valid) {
                        this.hideResults.next(true);
                        this.reload();
                    }
                });

            if (init) {
                this.restoreState();
            }
        });

        this.dataSource.initialLoading$.pipe(takeUntil(this.unsubscribe)).subscribe(l => {
            if (!l) {
                this.hideResults.next(false);
            }
        });

        interval(2 * 60 * 1000)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.update());
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    scrolled() {
        this.dataSource.loadMore();
    }

    reload() {
        if (this.infiniteScroll) {
            this.infiniteScroll.destroyScroller();
            this.infiniteScroll.setup();
        }

        const where: EventsFilter = {
            block: {
                timestamp: { greaterThanOrEqualTo: dayjs(this.form.value.from).startOf('day'), lessThanOrEqualTo: dayjs(this.form.value.until).endOf('day') },
            },
        };

        if (this.form.value.types.length) {
            where.type = { in: this.form.value.types };
        }

        if (this.form.value.address) {
            where.address = { equalTo: this.form.value.address };
        }

        if (this.form.value.name) {
            where.domainName = { equalTo: this.form.value.name };
        }

        const variables: EventsQueryVariables = {
            where,
        };

        this.historyStateService.merge<AuditState>({
            audit: { form: this.form.value },
        });

        this.dataSource.load(variables);
    }

    update() {
        this.dataSource.loadMore('up');
    }

    private restoreState() {
        this.hideResults.next(true);
        const state = this.historyStateService.get<AuditState>();

        if (state.audit) {
            this.form.setValue(state.audit.form, { emitEvent: false });
        }

        setTimeout(() => this.reload());
    }
}
