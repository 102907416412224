import { DataSource } from '@angular/cdk/table';

import { Unarray } from '../utils/types';
import { DataSourceBase, ProcessedData, DataSourceOptions } from '../data-source-base';
import { OfferListQuery, OfferListQueryVariables } from './graphql.generated';

export type OfferRecord = Unarray<OfferListQuery['offers']['edges']>['node'];

export class OfferTableDataSource extends DataSourceBase<OfferListQuery, OfferRecord[], OfferListQueryVariables> implements DataSource<OfferRecord> {
    constructor(options: DataSourceOptions<OfferListQuery, OfferListQueryVariables>) {
        super({
            ...options,
            defaultVariables: { first: 30 },
        });
    }

    protected transformData(data: OfferListQuery): ProcessedData<OfferRecord[]> {
        return {
            data: data.offers.edges.map(e => e.node),
            pageInfo: data.offers.pageInfo,
            isEmpty: !data.offers.edges.length,
        };
    }
}
