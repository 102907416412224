import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DomainCommonModule } from '../domain-common/domain-common.module';
import { FiltersModule } from '../filters/filters.module';
import { OffersCoreModule } from '../offers-core/offers-core.module';
import { SharedModule } from '../shared/shared.module';
import { BulkRemoveBuyOffersComponent } from './bulk-remove-buy-offers.component';
import { BuyOfferActionsComponent } from './buy-offer-actions.component';
import { BuyOfferFilterComponent } from './buy-offer-filter.component';
import { BuyOfferListComponent } from './buy-offer-list.component';
import { BuyOfferListingComponent } from './buy-offer-listing.component';
import { BuyOffersPageComponent } from './buy-offers-page.component';
import { ExecuteBuyOfferComponent } from './execute-buy-offer.component';
import { LatestBuyOffersComponent } from './latest-buy-offers.component';
import { PlaceBuyOfferComponent } from './place-buy-offer.component';
import { RemoveBuyOfferComponent } from './remove-buy-offer.component';

@NgModule({
    declarations: [
        BuyOfferFilterComponent,
        BuyOfferListComponent,
        PlaceBuyOfferComponent,
        BuyOfferListingComponent,
        RemoveBuyOfferComponent,
        ExecuteBuyOfferComponent,
        BuyOffersPageComponent,
        LatestBuyOffersComponent,
        BuyOfferActionsComponent,
        BulkRemoveBuyOffersComponent,
    ],
    imports: [SharedModule, ReactiveFormsModule, FiltersModule, MatDatepickerModule, OffersCoreModule, DomainCommonModule],
    exports: [BuyOffersPageComponent, LatestBuyOffersComponent, PlaceBuyOfferComponent, BuyOfferListComponent, BuyOfferActionsComponent],
})
export class BuyOffersModule {}
