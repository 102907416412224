import { DataSourceBase, DataSourceOptions, ProcessedData } from '../data-source-base';
import { CurrentBuyOfferQuery, CurrentBuyOfferQueryVariables } from './graphql.generated';

export class CurrentBuyOfferDataSource extends DataSourceBase<CurrentBuyOfferQuery, CurrentBuyOfferQuery, CurrentBuyOfferQueryVariables> {
    constructor(options: DataSourceOptions<CurrentBuyOfferQuery, CurrentBuyOfferQueryVariables>) {
        super({
            ...options,
        });
    }

    protected transformData(data: CurrentBuyOfferQuery): ProcessedData<CurrentBuyOfferQuery> {
        return {
            data,
        };
    }
}
