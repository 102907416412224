import { Configuration } from '@/configuration';
import { PoolStatsService } from '@/gv-pool/pool-stats.service';
import { OperationStatusDoneEvent } from '@/shared/operation-status.component';
import { SmartContractOperationEvent } from '@/tezos/tezos.service';
import { Component, Input, inject } from '@angular/core';
import dayjs from 'dayjs';
import { Observable, combineLatest } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { ClaimInfoService } from './claim-info.service';
import { GovActionService } from './gov-action.service';
import { AirdropClaimState, ClaimStatus } from './models';

@Component({
    selector: 'td-vesting-widget',
    templateUrl: './vesting-widget.component.html',
    styleUrls: ['./vesting-widget.component.scss'],
})
export class VestingWidgetComponent {
    private readonly claimInfo = inject(ClaimInfoService);
    private readonly poolService = inject(PoolStatsService);
    private readonly config = inject(Configuration);
    private readonly govAction = inject(GovActionService);

    airdropLaunchDate = this.config.airdrop.start;
    isHidden = true;

    private airdropStarted = this.config.airdrop.start < new Date();
    operation: Observable<SmartContractOperationEvent> | null;

    vm$: Observable<{
        state: AirdropClaimState | null;
        nextUnlockDate: dayjs.Dayjs | null;
        claimsLeft: number;
        hasDeposit: boolean;
        owner: string;
    }>;

    @Input() set address(value: string) {
        this.vm$ = combineLatest([this.claimInfo.canClaimTokens(value), this.poolService.stats$]).pipe(
            debounceTime(0), // both could emit at the same time - this avoids the double calculation
            map(([state, poolStats]) => ({
                state: state,
                nextUnlockDate: state?.futureClaims[0]?.from ?? null,
                claimsLeft: state?.futureClaims.length ?? 0,
                hasDeposit: poolStats?.votesBalance.gt(0) ?? false,
                owner: value,
            })),
            tap(vm => (this.isHidden = !this.airdropStarted || !vm.state || vm.state.status !== ClaimStatus.ClaimedPartially))
        );
    }

    claim(state: AirdropClaimState, deposit: boolean) {
        let ops = this.govAction.q.claim(...state.claimableClaims);

        if (deposit) {
            ops = ops.deposit(state.amountClaimable, state.owner);
        }

        this.operation = ops.send();
    }

    operationDone(event: OperationStatusDoneEvent, claimState: AirdropClaimState) {
        if (event.success) {
            this.poolService.refresh(claimState.claimableClaims);
        }
    }
}
