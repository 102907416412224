import { Component, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TaquitoTezosDomainsClient } from '@tezos-domains/taquito-client';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataSourceFactory } from '../graphql/data-source-factory';
import { OfferListQueryVariables, OfferOrder, OfferOrderField, OffersFilter, OfferState, OrderDirection } from '../graphql/graphql.generated';
import { OfferRecord, OfferTableDataSource } from '../graphql/offer-table-data-source';
import { OverlayService } from '../overlay.service';
import { TezosDomainsClientService } from '../tezos/integration/tezos-domains-client.service';
import { HotOfferFilter, OfferCategory } from './hot-offer-filter.component';

@UntilDestroy()
@Component({
    selector: 'td-hot-offer-table',
    templateUrl: './hot-offer-table.component.html',
    styleUrls: ['./hot-offer-table.component.scss'],
})
export class HotOfferTableComponent implements OnInit {
    dataSource: OfferTableDataSource;

    tzDomainsClient$: Observable<TaquitoTezosDomainsClient>;
    hideResults = new BehaviorSubject(true);

    @ViewChild(InfiniteScrollDirective) infiniteScroll: InfiniteScrollDirective;

    constructor(
        private dataSourceFactory: DataSourceFactory,
        private overlayService: OverlayService,
        private tezosDomainsClientService: TezosDomainsClientService
    ) {}

    ngOnInit(): void {
        this.tzDomainsClient$ = this.tezosDomainsClientService.current;
        this.dataSource = this.dataSourceFactory.createOfferTableDataSource();

        this.dataSource.initialLoading$.pipe(untilDestroyed(this)).subscribe(loading => {
            if (!loading) {
                this.hideResults.next(false);
            }
        });
    }

    scrolled() {
        this.dataSource.loadMore();
    }

    executeOffer(offer: OfferRecord) {
        this.overlayService.openExecuteOffer(offer, () => this.dataSource.reload());
    }

    onFilterChange(filter: HotOfferFilter): void {
        if (this.infiniteScroll) {
            this.infiniteScroll.destroyScroller();
            this.infiniteScroll.setup();
        }

        const where: OffersFilter = {
            state: { in: [OfferState.Active] },
        };

        if (filter.domainName) {
            where.domainName = { like: filter.domainName };
        }

        if (!filter.allCategoriesSelected) {
            where.or = this.buildFilterFromCategories(filter.categories);
        }

        if (filter.price) {
            where.price = {};

            if (filter.price.from) {
                where.price!.greaterThanOrEqualTo = filter.price.from;
            }

            if (filter.price.to) {
                where.price!.lessThanOrEqualTo = filter.price.to;
            }
        }

        if (filter.date) {
            where.createdAtUtc = {};

            if (filter.date.from) {
                where.createdAtUtc!.greaterThanOrEqualTo = filter.date.from;
            }

            if (filter.date.to) {
                where.createdAtUtc!.lessThanOrEqualTo = filter.date.to;
            }
        }

        const orderField = <OfferOrderField>filter.sorting.value;
        if (orderField === OfferOrderField.EndsAt) {
            where.endsAtUtc = { isNull: false };
        }

        const order: OfferOrder = {
            field: <OfferOrderField>filter.sorting.value,
            direction: filter.sorting.direction === 'asc' ? OrderDirection.Asc : OrderDirection.Desc,
        };

        const variables: OfferListQueryVariables = {
            where,
            order,
        };

        this.dataSource.load(variables);
    }

    private buildFilterFromCategories(categories: OfferCategory[]): OffersFilter[] {
        return categories.map(c => {
            switch (c) {
                case OfferCategory.MoreThanFiveLetters:
                    return <OffersFilter>{ domainName: { length: { greaterThanOrEqualTo: 9 } } };
                case OfferCategory.FourLetters:
                    return <OffersFilter>{ domainName: { length: { equalTo: 8 } } };
                case OfferCategory.ThreeLetters:
                    return <OffersFilter>{ domainName: { length: { equalTo: 7 } } };
            }
        });
    }
}
