import { Injectable, inject } from '@angular/core';
import { keyBy } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Logger } from '../browser/logger';
import { Unarray, notNil } from '../utils/types';
import { ProfileGQL, ProfileListGQL, ProfileListQuery, ProfileQuery } from './tzprofiles-graphql.generated';

type TZProfileGenerated = ProfileQuery['tzprofiles_by_pk'] & Unarray<ProfileListQuery['tzprofiles']>;

export type SocialNetworkType = 'twitter' | 'github' | 'discord' | 'dns';

export interface SocialNetwork {
    value: string;
    type: SocialNetworkType;
}
export interface TzProfile {
    account: string;
    alias?: string;
    description?: string;
    logo?: string;
    website?: string;
    ethAddress?: string;
    verified: SocialNetwork[];
}

export const EmptyProfile: TzProfile = {
    account: '',
    verified: [],
};

type ClaimType =
    | 'VerifiableCredential'
    | 'BasicProfile'
    | 'TwitterVerification'
    | 'EthereumAddressControl'
    | 'DiscordVerification'
    | 'GitHubVerification'
    | 'DnsVerification';

interface BasicProfile {
    alias: string;
    description: string;
    logo: string;
    website: string;
}

interface Verification {
    sameAs: string;
}

interface Evidence {
    handle: string;
}

interface EthereumAddressControl {
    address: string;
}

type ClaimCredential = BasicProfile | EthereumAddressControl | Verification;
type ClaimEvidence = Evidence;

interface Claim {
    '@context': string[];
    credentialSubject: ClaimCredential;
    evidence: any;
    id: string;
    issuer: string;
    issuanceDate: string;
    proof: any;
    type: ClaimType[];
}

@Injectable({
    providedIn: 'root',
})
export class TzprofilesService {
    private logger = inject(Logger);

    constructor(private profileGQL: ProfileGQL, private profileListGQL: ProfileListGQL) {}

    getProfile(address: string): Observable<TzProfile | null> {
        return this.profileGQL.fetch({ address }, { fetchPolicy: 'cache-first' }).pipe(
            map(r => this.parseProfile(r.data?.tzprofiles_by_pk)),
            catchError(e => {
                this.logger.warn(`TzprofilesService - unable to load profile: ${address}`, e);
                return of(null);
            })
        );
    }

    getProfiles(addresses: string[]): Observable<{ [key: string]: TzProfile }> {
        if (!addresses.length) {
            return of({});
        }

        return this.profileListGQL.fetch({ where: { account: { _in: addresses } } }, { fetchPolicy: 'cache-first' }).pipe(
            map(r => r.data.tzprofiles.map(tzProfile => this.parseProfile(tzProfile)).filter(notNil)),
            map(profiles => keyBy(profiles, 'account')),
            catchError(e => {
                this.logger.warn('TzprofilesService - unable to load profiles', e);
                return of({});
            })
        );
    }

    private parseProfile(data?: TZProfileGenerated | null): TzProfile | null {
        if (!data) {
            return null;
        }

        const claims = data.valid_claims.map((c: [string, string, string]) => JSON.parse(c[1])) as Claim[];

        if (claims.length === 0) {
            return null;
        }

        const profile: TzProfile = {
            verified: [],
            account: data.account,
        };

        this.appendClaim<BasicProfile>(profile, claims, 'BasicProfile', (profile, data) => {
            profile.alias = data.alias;
            profile.description = data.description;
            profile.website = data.website;
            profile.logo = data.logo;
        });

        this.appendClaim<Verification>(profile, claims, 'TwitterVerification', (profile, data) => {
            profile.verified.push({ value: data.sameAs, type: 'twitter' });
        });
        this.appendClaim<EthereumAddressControl>(profile, claims, 'EthereumAddressControl', (profile, data) => (profile.ethAddress = data.address));
        this.appendClaim<Verification, Evidence>(profile, claims, 'DiscordVerification', (profile, _, evidence) => {
            profile.verified.push({ value: evidence.handle, type: 'discord' });
        });
        this.appendClaim<Verification>(profile, claims, 'GitHubVerification', (profile, data) => {
            profile.verified.push({ value: data.sameAs, type: 'github' });
        });
        this.appendClaim<Verification>(profile, claims, 'DnsVerification', (profile, data) => {
            profile.verified.push({ value: data.sameAs.replace('dns:', ''), type: 'dns' });
        });

        return profile;
    }

    private appendClaim<T extends ClaimCredential, E extends ClaimEvidence = never>(
        profile: TzProfile,
        claims: Claim[],
        type: ClaimType,
        mergeFn: (profile: TzProfile, credential: T, evidence: E) => void
    ): void {
        const claim = claims.find(c => c.type.includes(type));
        if (claim) {
            mergeFn(profile, claim.credentialSubject as T, claim.evidence as E);
        }
    }
}
