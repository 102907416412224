import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TezosWallet } from '../tezos/models';

@Component({
    selector: 'td-transfer-warning',
    templateUrl: './transfer-warning.component.html',
    styleUrls: ['./transfer-warning.component.scss'],
})
export class TransferWarningComponent implements OnInit, OnChanges {
    @Input() domains: { parentOwner?: string | undefined | null; owner: string | undefined | null }[];
    @Input() wallet: TezosWallet;
    @Input() newOwner: string | undefined;
    @Input() recipient: string | null;

    @Output() requireTransferConfirmation = new EventEmitter<boolean>();

    private approvedTransferTarget: string | null = null;

    isDifferentNewOwner = false;
    transferConfirmRequired = false;

    get transferOwnerConfirmed() {
        const owner = this.newOwner;

        return owner === this.approvedTransferTarget || (this.approvedTransferTarget && this.recipient === this.approvedTransferTarget);
    }

    ngOnInit() {
        this.transferConfirmRequired = this.domains.some(d => d.parentOwner !== this.wallet.address);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['recipient'].currentValue !== changes['recipient'].previousValue) {
            this.approvedTransferTarget = null;
        }

        this.isDifferentNewOwner = Boolean(this.newOwner && this.domains.some(d => d.owner !== this.newOwner));

        this.requireTransferConfirmation.next(this.transferConfirmRequired && !this.transferOwnerConfirmed);
    }

    confirmTransfer(phrase: string) {
        if (phrase === this.newOwner || (this.recipient && phrase === this.recipient)) {
            this.approvedTransferTarget = phrase;
        }
        this.requireTransferConfirmation.next(this.transferConfirmRequired && !this.transferOwnerConfirmed);
    }
}
