import { PercentPipe } from '@angular/common';
import { inject, Pipe, PipeTransform } from '@angular/core';
import BigNumber from 'bignumber.js';

@Pipe({
    name: 'apr',
})
export class AprPipe implements PipeTransform {
    private percentPipe = inject(PercentPipe);

    transform(value: number | BigNumber | null | undefined): string {
        if (value == null) return '';

        const v = new BigNumber(value);

        if (v.lt(0) || v.eq(0)) return '> 100%';
        if (v.gt(1)) return '> 100%';

        return this.percentPipe.transform(v.toNumber(), '1.0-2') ?? '';
    }
}
