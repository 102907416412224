import { Component, EventEmitter, Input, Output, TemplateRef, inject } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import BigNumber from 'bignumber.js';
import { Configuration } from '../configuration';
import { DelegationStatus, ReverseRecord } from '../delegation/models';
import { PoolStatsService } from '../gv-pool/pool-stats.service';

@UntilDestroy()
@Component({
    selector: 'td-delegate-list',
    templateUrl: './delegate-list.component.html',
    styleUrls: ['./delegate-list.component.scss'],
})
export class DelegateListComponent {
    private readonly config = inject(Configuration);
    private readonly poolStatistics = inject(PoolStatsService);

    @Input() tokenAmount: BigNumber;
    @Input() operationStatusTemplate: TemplateRef<any>;
    @Input() disabled = false;
    @Input() currentDelegate: DelegationStatus | null = null;

    @Output() delegateSelected = new EventEmitter<{
        wantsToDepositVotes: boolean;
        wantsToDelegate: boolean;
        delegate: ReverseRecord | null;
    }>();
    @Output() delegateStepChanged = new EventEmitter<void>();
    @Output() back = new EventEmitter<void>();

    selected: ReverseRecord | null = null;

    wantsToDepositVotes = true;
    wantsToDelegate = true;
    showAverageApr = this.config.governance.showAverageApr;

    poolStats$ = this.poolStatistics.stats$;

    onDelegationSelected(data: { wantsToDelegate: boolean; delegate: ReverseRecord | null }) {
        this.wantsToDelegate = data.wantsToDelegate;
        this.selected = data.delegate;
    }

    continue(): void {
        this.delegateSelected.next({
            wantsToDepositVotes: this.wantsToDepositVotes,
            wantsToDelegate: this.wantsToDelegate,
            delegate: this.wantsToDepositVotes && this.wantsToDelegate ? this.selected : null,
        });
    }
}
