<ng-container *transloco="let t">
    <div *ngIf="dataSource.initialLoading$ | async" class="w-100 mt-3 d-flex align-content-center">
        <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>

    <div
        *ngIf="enableEmptyMessage && (dataSource.empty$ | async)"
        class="empty-message p-3 w-100 text-center"
        [ngClass]="{ 'smooth-shadow': noShadow !== true }"
    >
        {{ t('domain-table.no-domains') }}
    </div>

    <div [hidden]="dataSource.initialLoading$ | async">
        <td-error *ngIf="dataSource.error$ | async; let error" [message]="error.message" [title]="t('general.error')"></td-error>

        <div
            class="table-container d-flex"
            [ngClass]="{ 'smooth-shadow': noShadow !== true }"
            [hidden]="(dataSource.empty$ | async) || (dataSource.initialLoading$ | async)"
        >
            <mat-table class="table" [dataSource]="dataSource">
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>{{ t('fields.name') }}</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <a class="w-100 d-flex align-items-center" [routerLink]="['/domain', row.name]">
                            <span class="text-truncate">{{ row.name }}</span>
                            <td-address
                                *ngIf="userReverseRecord?.domain?.name === row.name"
                                [address]="userReverseRecord!.address"
                                [text]="false"
                                [reverseRecord]="userReverseRecord"
                                class="ms-2"
                            ></td-address>
                            <td-operator-badge *ngIf="isOperatorFor(row)" [address]="address"> </td-operator-badge>
                        </a>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="expires">
                    <mat-header-cell *matHeaderCellDef class="fixed-column">{{ t('fields.expires') }}</mat-header-cell>
                    <mat-cell *matCellDef="let row" class="fixed-column">
                        <span [matTooltip]="row.expires | dayjsDate" [ngClass]="{ 'no-underline': !row.expires }">
                            <td-humanized-date [value]="row.expires" [textWhenEmpty]="t('general.never')"></td-humanized-date>
                        </span>
                        <fa-icon
                            class="ms-1 icon-warning no-underline"
                            icon="exclamation-triangle"
                            *ngIf="isDomainOfLoggedOnUser(row) && domainIsExpiring(row)"
                            [matTooltip]="t('domain.expiring-soon')"
                        ></fa-icon>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef class="fixed-column"></mat-header-cell>
                    <mat-cell class="d-flex align-items-center justify-content-end fixed-column" *matCellDef="let row">
                        <a *ngIf="isDomainOfLoggedOnUser(row)" mat-flat-button color="primary" [routerLink]="['/domain', row.name]">
                            {{ t('actions.manage') }}
                        </a>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns"></mat-row>
            </mat-table>
        </div>

        <div class="mt-3 ms-3" *ngIf="(dataSource.empty$ | async) === false">
            <a [routerLink]="['/address', address, 'domains']">{{ t('general.see-more') }}</a>
        </div>
    </div>
</ng-container>
