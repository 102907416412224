import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import dayjs from 'dayjs';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { BehaviorSubject, interval, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { HistoryStateService } from '../browser/history-state.service';
import { DataSourceFactory } from '../graphql/data-source-factory';
import { EventsDataSource } from '../graphql/events-table-data-source';
import { EventsFilter, EventsQueryVariables, EventType } from '../graphql/graphql.generated';
import { TezosNetwork, TezosWallet } from '../tezos/models';
import { TezosNetworkService } from '../tezos/tezos-network.service';

export type EventTableState = {
    eventTable?: {
        form: any;
    };
};

@Component({
    selector: 'td-event-table',
    templateUrl: './event-table.component.html',
    styleUrls: ['./event-table.component.scss'],
})
export class EventTableComponent implements OnInit, OnDestroy, OnChanges {
    @Input() address: string;

    dataSource: EventsDataSource;
    form: UntypedFormGroup;
    maxDate = new Date();
    minDate = new Date(2021, 0, 1);

    hideResults = new BehaviorSubject<boolean>(true);
    wallet: TezosWallet | null;
    network: TezosNetwork;
    columns: string[];

    private unsubscribe = new Subject<void>();

    @ViewChild(MatSort) sorter: MatSort;
    @ViewChild(InfiniteScrollDirective) infiniteScroll: InfiniteScrollDirective;

    constructor(
        private dataSourceFactory: DataSourceFactory,
        private formBuilder: UntypedFormBuilder,
        private historyStateService: HistoryStateService,
        private tezosNetworkService: TezosNetworkService
    ) {}

    ngOnInit(): void {
        this.tezosNetworkService.activeNetwork.pipe(takeUntil(this.unsubscribe)).subscribe(n => (this.network = n));
        this.dataSource = this.dataSourceFactory.createEventsDataSource();

        this.form = this.formBuilder.group({
            from: this.formBuilder.control(''),
            until: this.formBuilder.control(''),
        });

        this.form.valueChanges.pipe(debounceTime(300), takeUntil(this.unsubscribe)).subscribe(() => {
            this.hideResults.next(true);
            this.reload();
        });

        this.dataSource.initialLoading$.pipe(takeUntil(this.unsubscribe)).subscribe(l => {
            if (!l) {
                this.hideResults.next(false);
            }
        });

        this.columns = ['time', 'text', 'amount', 'link'];

        this.restoreState();

        interval(2 * 60 * 1000)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.update());
    }

    ngOnChanges() {
        // check because changes fire before init
        if (this.form) {
            this.restoreState();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    scrolled() {
        this.dataSource.loadMore();
    }

    reload() {
        if (this.infiniteScroll) {
            this.infiniteScroll.destroyScroller();
            this.infiniteScroll.setup();
        }

        const where: EventsFilter = {
            type: {
                in: [
                    EventType.AuctionBidEvent,
                    EventType.AuctionSettleEvent,
                    EventType.DomainBuyEvent,
                    EventType.DomainRenewEvent,
                    EventType.AuctionWithdrawEvent,
                    EventType.AuctionEndEvent,
                    EventType.DomainCommitEvent,
                    EventType.DomainSetChildRecordEvent,
                    EventType.DomainUpdateEvent,
                    EventType.ReverseRecordClaimEvent,
                    EventType.ReverseRecordUpdateEvent,
                    EventType.DomainGrantEvent,
                    EventType.DomainTransferEvent,
                    EventType.OfferPlacedEvent,
                    EventType.OfferUpdatedEvent,
                    EventType.OfferRemovedEvent,
                    EventType.OfferExecutedEvent,
                    EventType.BuyOfferExecutedEvent,
                    EventType.BuyOfferRemovedEvent,
                    EventType.BuyOfferPlacedEvent,
                    EventType.DomainUpdateOperatorsEvent,
                ],
            },
            address: { equalTo: this.address },
            block: {
                timestamp: { greaterThanOrEqualTo: dayjs(this.form.value.from).startOf('day'), lessThanOrEqualTo: dayjs(this.form.value.until).endOf('day') },
            },
        };

        const variables: EventsQueryVariables = {
            where,
        };

        this.historyStateService.merge<EventTableState>({
            eventTable: { form: this.form.value },
        });

        this.dataSource.load(variables);
    }

    update() {
        this.dataSource.loadMore('up');
    }

    private restoreState() {
        this.hideResults.next(true);
        const state = this.historyStateService.get<EventTableState>();

        if (state.eventTable) {
            this.form.setValue(state.eventTable.form, { emitEvent: false });
        }

        setTimeout(() => this.reload());
    }
}
