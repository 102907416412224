import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DomainDetailQuery } from '../graphql/graphql.generated';
import { TezosWallet } from '../tezos/models';
import { TezosService } from '../tezos/tezos.service';

@Component({
    selector: 'td-bid-table',
    templateUrl: './bid-table.component.html',
    styleUrls: ['./bid-table.component.scss'],
})
export class BidTableComponent implements OnInit, OnDestroy {
    @Input() auction: NonNullable<DomainDetailQuery['auction']>;
    @Input() isEnded: boolean;

    wallet: TezosWallet | null;

    private unsubscribe = new Subject<void>();

    constructor(private tezosService: TezosService) {}

    ngOnInit(): void {
        this.tezosService.activeWallet.pipe(takeUntil(this.unsubscribe)).subscribe(w => (this.wallet = w!));
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
