import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
@UntilDestroy()
@Component({
    selector: 'td-progress-button',
    templateUrl: './progress-button.component.html',
    styleUrls: ['./progress-button.component.scss'],
})
export class ProgressButtonComponent implements OnInit {
    @Input() loading: Observable<boolean>;
    @Input() idleText = '';
    @Input() inProgressText = '';

    inProgress = false;

    ngOnInit() {
        this.loading.pipe(untilDestroyed(this)).subscribe(loading => (this.inProgress = loading));
    }
}
