import { Component, OnInit, OnDestroy } from '@angular/core';
import { TaquitoTezosDomainsClient } from '@tezos-domains/taquito-client';
import { DomainNameValidationResult } from '@tezos-domains/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { skip, takeUntil, tap } from 'rxjs/operators';

import { PageService } from '../browser/page.service';
import { DomainService } from '../domains/domain.service';
import { AuctionDetailQuery, AuctionState } from '../graphql/graphql.generated';
import { DataSourceFactory } from '../graphql/data-source-factory';
import { AuctionDetailDataSource } from '../graphql/auction-detail-data-source';
import { TezosDomainsClientService } from '../tezos/integration/tezos-domains-client.service';
import { TezosService } from '../tezos/tezos.service';
import { TezosWallet } from '../tezos/models';

@Component({
    selector: 'td-history-auction',
    templateUrl: './history-auction.component.html',
    styleUrls: ['./history-auction.component.scss'],
})
export class HistoryAuctionComponent implements OnInit, OnDestroy {
    dataSource: AuctionDetailDataSource;
    auction: AuctionDetailQuery['auction'];
    name: string;
    errorTitleKey = '';
    errorMessage: string | null;
    states = AuctionState;
    wallet: TezosWallet | null;

    get isBidder() {
        return this.auction?.bids.some(b => b.bidder === this.wallet?.address);
    }

    private unsubscribe = new Subject<void>();
    private tezosDomains: TaquitoTezosDomainsClient;

    constructor(
        private dataSourceFactory: DataSourceFactory,
        private activatedRoute: ActivatedRoute,
        private pageService: PageService,
        private tezosDomainsClientService: TezosDomainsClientService,
        private domainService: DomainService,
        private tezosService: TezosService
    ) {}

    ngOnInit(): void {
        this.dataSource = this.dataSourceFactory.createAuctionDetailDataSource();
        combineLatest([this.activatedRoute.params, this.tezosDomainsClientService.current])
            .pipe(
                takeUntil(this.unsubscribe),
                tap(([_, client]) => (this.tezosDomains = client))
            )
            .subscribe(() => this.loadData());

        this.tezosService.activeWallet.pipe(takeUntil(this.unsubscribe)).subscribe(w => (this.wallet = w));

        this.dataSource.data$.pipe(takeUntil(this.unsubscribe)).subscribe(data => {
            this.auction = data?.auction || null;
        });

        this.dataSource.error$.pipe(takeUntil(this.unsubscribe)).subscribe(error => {
            if (error) {
                this.errorTitleKey = 'general.error';
                this.errorMessage = error.message;
            } else {
                this.errorMessage = null;
            }
        });
    }

    loadData() {
        this.name = this.activatedRoute.snapshot.params['name'];
        const level = +this.activatedRoute.snapshot.params['level'];
        this.pageService.setTitle('auction', { name: this.name });
        this.errorMessage = null;
        this.auction = null;

        const validation = this.tezosDomains.validator.isValidWithKnownTld(this.name);
        if (validation !== DomainNameValidationResult.VALID) {
            /** t(domain-detail.title-invalid-domain) */
            this.errorTitleKey = 'domain-detail.title-invalid-domain';
            this.domainService
                .getInvalidDomainMessage(this.name, validation)
                .pipe(takeUntil(this.activatedRoute.params.pipe(skip(1))))
                .subscribe(m => (this.errorMessage = m));
        } else {
            this.dataSource.load({
                name: this.name,
                startedAtLevel: level,
            });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.dataSource.disconnect();
    }
}
