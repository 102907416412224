import { Component } from '@angular/core';
import { IconName } from '@fortawesome/free-solid-svg-icons';
import { DarkModeService } from './dark-mode.service';

@Component({
    selector: 'td-dark-mode',
    templateUrl: './dark-mode.component.html',
    styleUrls: ['./dark-mode.component.scss'],
})
export class DarkModeComponent {
    themeStateIcon: IconName = 'sun';
    tooltip = 'app.light';

    constructor(private darkModeService: DarkModeService) {
        darkModeService.theme$.subscribe(theme => {
            this.themeStateIcon = theme === 'light' ? 'sun' : 'moon';
            this.tooltip = theme === 'light' ? 'app.light' : 'app.dark';
        });
    }

    toggleTheme(): void {
        this.darkModeService.toggleTheme();
    }
}
