<ng-container *transloco="let t">
    <td-error *ngIf="errorMessage" [message]="errorMessage" [title]="t(errorTitleKey)"></td-error>
    <ng-container *ngIf="(dataSource.initialLoading$ | async) && !errorMessage">
        <td-data-card-skeleton [rows]="3" [buttons]="0"></td-data-card-skeleton>
        <td-table-skeleton [rows]="8"></td-table-skeleton>
    </ng-container>
    <div class="d-flex flex-column gap-4" *ngIf="auction && (dataSource.initialLoading$ | async) === false">
        <div class="card smooth-shadow d-flex flex-column gap-3">
            <div class="d-flex align-items-center">
                <h2 class="mb-0 me-2">{{ t('domain-detail.auction.subtitle') }}</h2>
                <ng-container *ngIf="isBidder">
                    <td-badge *ngIf="auction.highestBid.bidder !== wallet?.address" color="danger">
                        {{ t('auction-table.status.lost') }}
                    </td-badge>
                    <td-badge *ngIf="auction.highestBid.bidder === wallet?.address" color="success">
                        {{ t('auction-table.status.won') }}
                    </td-badge>
                </ng-container>
            </div>
            <div class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center">
                <div class="mat-body-strong description">{{ t('fields.domain') }}</div>
                <div class="flex-grow-1">
                    <td-domain [domain]="name" [copy]="true"></td-domain>
                </div>
            </div>
            <div class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center">
                <div class="mat-body-strong description">
                    {{ t('fields.auction-end-past') }}
                </div>
                <div class="flex-grow-1"><td-humanized-date [value]="auction.endsAtUtc"></td-humanized-date> ({{ auction.endsAtUtc | dayjsDate }})</div>
            </div>

            <div *ngIf="auction.state === states.CanBeSettled">{{ t('auction-detail.can-be-settled-info') }}</div>
            <div *ngIf="auction.state === states.Settled">{{ t('auction-detail.settled-info') }}</div>
            <div *ngIf="auction.state === states.SettlementExpired">{{ t('auction-detail.settlement-expired-info') }}</div>
        </div>

        <td-bid-table [auction]="$any(auction)" [isEnded]="true"></td-bid-table>
    </div>
</ng-container>
