import { ClaimStatus } from '@/airdrop/models';
import { Component, inject } from '@angular/core';
import { combineLatest, of } from 'rxjs';
import { delay, map, switchMap, tap } from 'rxjs/operators';
import { Configuration } from '../configuration';
import { TezosService } from '../tezos/tezos.service';
import { ClaimInfoService } from './../airdrop/claim-info.service';
import { PoolStatsService } from './pool-stats.service';

@Component({
    selector: 'td-pool-cta',
    templateUrl: './cta.component.html',
    styleUrls: ['./cta.component.scss'],
})
export class PoolCtaComponent {
    private poolStats = inject(PoolStatsService);
    private configuration = inject(Configuration);
    private claimInfo = inject(ClaimInfoService);
    private tezosService = inject(TezosService);

    hidden = true;
    plentyUrl = this.configuration.governance.plentyUrl;
    showAverageApr = this.configuration.governance.showAverageApr;

    readonly stats$ = this.tezosService.activeWallet.pipe(
        switchMap(w => {
            const airdropStarted$ = of(this.configuration.airdrop.start < new Date());
            const canClaim$ = w ? this.claimInfo.canClaimTokens(w.address) : of(null);
            return combineLatest([airdropStarted$, this.poolStats.stats$, canClaim$]);
        }),
        delay(10),
        tap(([airdropStarted, poolStats, claim]) => {
            if (!airdropStarted) {
                return;
            }

            if (!poolStats) {
                this.hidden = false;
                return;
            }

            const alreadyHasTokens = poolStats.tedBalance.gt(0) || poolStats.votesBalance.gt(0);

            this.hidden = alreadyHasTokens || claim?.status === ClaimStatus.Unclaimed;
        }),
        map(([, poolStats]) => poolStats)
    );
    readonly loading$ = this.poolStats.loading$;
}
