import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { SortDirection } from '@angular/material/sort';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface SortOption {
    readonly label: string;
    readonly value: string;
    readonly direction: SortDirection;
}

@Component({
    selector: 'td-sorter',
    templateUrl: './sorter.component.html',
    styleUrls: ['./sorter.component.scss'],
})
export class SorterComponent implements OnInit {
    @Input() defaultSort: SortOption;
    @Input() sortOptions: SortOption[];
    @Output() sortChange = new EventEmitter<SortOption>();

    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    currentSort: SortOption;
    sortIcon: IconProp;

    ngOnInit(): void {
        this.currentSort = this.defaultSort;
        this.sortIcon = this.getSortIcon();
    }

    onClick(): void {
        this.trigger.openMenu();
    }

    sort(option: SortOption) {
        this.currentSort = { ...option };
        this.sortIcon = this.getSortIcon();
        this.trigger.closeMenu();

        this.sortChange.next(this.currentSort);
    }

    isDefaultSort(): boolean {
        return this.defaultSort.direction === this.currentSort.direction && this.defaultSort.value === this.currentSort.value;
    }

    private getSortIcon(): IconProp {
        return this.currentSort.direction === 'asc' ? 'sort-amount-up' : 'sort-amount-down';
    }
}
