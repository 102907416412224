import { NgModule } from '@angular/core';

import { CardSkeletonComponent } from './card-skeleton.component';
import { SharedModule } from '../shared/shared.module';
import { DataCardSkeletonComponent } from './data-card-skeleton.component';
import { TitleSkeletonComponent } from './title-skeleton.component';
import { TableSkeletonComponent } from './table-skeleton.component';
import { FeedSkeletonComponent } from './feed-skeleton.component';
import { BigTitleSkeletonComponent } from './big-title-skeleton.component';

@NgModule({
    declarations: [CardSkeletonComponent, DataCardSkeletonComponent, TitleSkeletonComponent, TableSkeletonComponent, FeedSkeletonComponent, BigTitleSkeletonComponent],
    imports: [SharedModule],
    exports: [TitleSkeletonComponent, DataCardSkeletonComponent, TableSkeletonComponent, FeedSkeletonComponent, BigTitleSkeletonComponent],
})
export class SkeletonModule {}
