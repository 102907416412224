<ng-container *transloco="let t">
    <td-error *ngIf="errorMessage" [message]="errorMessage" [title]="t(errorTitleKey)"></td-error>
    <ng-container *ngIf="(dataSource.initialLoading$ | async) && !errorMessage">
        <td-data-card-skeleton [rows]="3" [buttons]="1"></td-data-card-skeleton>
    </ng-container>
    <ng-container *ngIf="!errorMessage && reverseRecord && (dataSource.initialLoading$ | async) === false">
        <div *ngIf="!reverseRecord.owner" class="card smooth-shadow">
            <div class="m-3 d-flex flex-column gap-4">
                <h1 class="text-wrap mb-0">{{ t('cta.set-reverse-record.title') }}</h1>
                <h4 class="txt-secondary text-wrap">{{ t('cta.set-reverse-record.text') }}</h4>
                <div class="d-flex">
                    <button color="primary" mat-flat-button (click)="edit()">
                        {{ t('cta.set-reverse-record.button') }}
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="reverseRecord.owner" class="card smooth-shadow d-flex flex-column gap-3">
            <h2 *ngIf="reverseRecord.owner" class="mb-0">{{ t('reverse-record-detail.title') }}</h2>
            <span>{{ t('cta.set-reverse-record.text') }}</span>
            <div class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center">
                <div class="mat-body-strong description">{{ t('fields.address') }}</div>
                <div class="w-100">
                    <td-address [address]="reverseRecord.address" [reverseRecord]="reverseRecord" [link]="false" text="address-only"></td-address>
                </div>
            </div>
            <div class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center">
                <div class="mat-body-strong description">{{ t('fields.name') }}</div>
                <div class="w-100">
                    <td-domain *ngIf="reverseRecord.domain" [domain]="reverseRecord.domain.name"></td-domain>
                    <span *ngIf="!reverseRecord.domain">{{ t('general.not-set') }}</span>
                </div>
            </div>
            <div class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center">
                <div class="mat-body-strong description">{{ t('fields.reverse-record-owner') }}</div>
                <div class="w-100">
                    <td-address [address]="reverseRecord.owner" [reverseRecord]="reverseRecord.ownerReverseRecord" text="address-only"></td-address>
                </div>
            </div>
            <div
                class="d-flex flex-column flex-sm-row gap-3 align-items-stretch align-items-sm-start"
                *ngIf="wallet && (wallet.address === reverseRecord.owner || wallet.address === reverseRecord.address)"
            >
                <button mat-flat-button color="primary" (click)="edit()" size="default"><fa-icon icon="edit"></fa-icon>{{ t('actions.edit') }}</button>
            </div>
        </div>
    </ng-container>
</ng-container>
