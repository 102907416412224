import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WalletTransferParams } from '@taquito/taquito';
import { getLabel, getTld, RecordMetadata } from '@tezos-domains/core';
import { ClaimRequest, TLDConfiguration } from '@tezos-domains/manager/dist/src/manager/model';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { indicateLoading } from 'src/app/shared/loading.operator';
import { OperationStatusDoneEvent } from 'src/app/shared/operation-status.component';
import { emptyStringToNull } from 'src/app/utils/convert';
import { TdValidators } from 'src/app/utils/form-validators';
import { TldConfigurationService } from '../../tezos/integration/tld-configuration.service';
import { TezosWallet } from '../../tezos/models';
import { SmartContractOperationEvent, TezosService } from '../../tezos/tezos.service';
import { ReverseRecord, ReverseRecordService } from '../reverse-record.service';
import { DnsVerifyApiService, DomainValidationResponse } from './dns-verify-api.service';

export enum DnsBridgeStep {
    DNSSEC,
    TXTRecord,
    Claim,
    Done,
}
@UntilDestroy()
@Component({
    selector: 'td-dns-bridge',
    templateUrl: './dns-bridge.component.html',
    styleUrls: ['./dns-bridge.component.scss'],
})
export class DnsBridgeComponent implements OnInit {
    @Input() name: string;
    @Output() finished = new EventEmitter<void>();

    @ViewChild(MatStepper) stepper: MatStepper;

    readonly notificationSource = 'domain-registration';
    readonly notificationSubscriptionVisible$: Observable<{ visible: boolean }>;
    readonly TXTAddressPrefix = '_tda';

    wallet: TezosWallet;
    reverseRecord: ReverseRecord | null;
    form: UntypedFormGroup;
    stepperAnimation = false;

    step = DnsBridgeStep.DNSSEC;
    steps = DnsBridgeStep;

    userInterracted = false;
    error: string;
    registrationOperation: Observable<SmartContractOperationEvent> | null;
    dnsResponse?: DomainValidationResponse;

    private label: string;
    private tld: string;

    get txtAddressText(): string {
        return `${this.TXTAddressPrefix}=${this.wallet?.address ?? ''}`;
    }

    dnsCheckInProgress$ = new ReplaySubject<boolean>();

    constructor(
        private tezosService: TezosService,
        private reverseRecordService: ReverseRecordService,
        private dnsVerifyApi: DnsVerifyApiService,
        private translocoService: TranslocoService,
        private tldConfigurationService: TldConfigurationService,
        formBuilder: UntypedFormBuilder
    ) {
        this.form = formBuilder.group({
            address: formBuilder.control('', [TdValidators.tezosAddress()]),
            createReverseRecord: formBuilder.control(false),
            price: formBuilder.control(0),
        });
    }

    ngOnInit(): void {
        this.label = getLabel(this.name);
        this.tld = getTld(this.name);

        combineLatest([this.tezosService.activeWallet, this.reverseRecordService.current, this.tldConfigurationService.get(this.tld)])
            .pipe(
                first(),
                tap(([w, r]) => {
                    this.wallet = w!;
                    this.reverseRecord = r;
                }),
                switchMap(([w, r, tldConfig]) =>
                    this.dnsVerifyApi.checkStatus(this.label, this.tld, w!.address).pipe(
                        indicateLoading(this.dnsCheckInProgress$, 0),
                        map(dnsStats => [w, r, tldConfig, dnsStats] as [TezosWallet, ReverseRecord | null, TLDConfiguration, DomainValidationResponse])
                    )
                ),
                untilDestroyed(this)
            )
            .subscribe(([w, r, tldConfig, dnsState]) => {
                this.setDnsResponse(dnsState);
                this.form.setValue({
                    address: w.address,
                    createReverseRecord: !r?.domain,
                    price: tldConfig.claimPrice!.toNumber() / 1e6,
                });
            });

        let enabledValue: boolean | null = null;
        this.form.get('address')!.valueChanges.subscribe(v => {
            if (this.reverseRecord?.domain) {
                return;
            }

            const createReverseRecord = this.form.get('createReverseRecord')!;
            if (v !== this.wallet.address) {
                enabledValue = createReverseRecord.value;
                createReverseRecord.setValue(false);
                createReverseRecord.disable();
            } else {
                createReverseRecord.enable();
                if (enabledValue !== null) {
                    createReverseRecord.setValue(enabledValue);
                    enabledValue = null;
                }
            }
        });
    }

    setNextStep(targetStep?: DnsBridgeStep) {
        if (targetStep != null && targetStep < this.step) {
            console.error(`targetStep: ${targetStep} cannot be smaller than ${this.step}`);
            return;
        }

        if (targetStep === this.step) return;

        let diff = 1;
        if (targetStep) {
            diff = targetStep - this.step;
        }

        this.step += diff;

        setTimeout(() => {
            for (let i = 0; i < diff; i++) {
                this.stepper.next();
            }
        }, 1);
    }

    async checkDNSStatus() {
        this.userInterracted = true;
        this.error = '';

        await this.dnsVerifyApi
            .checkStatus(this.label, this.tld, this.wallet.address)
            .pipe(
                indicateLoading(this.dnsCheckInProgress$),
                tap(result => this.setDnsResponse(result))
            )
            .toPromise();
    }

    claim(): void {
        if (this.dnsResponse?.status !== 'OK') {
            return;
        }

        const formValue = this.form.value;
        this.form.disable();

        const claimParams: ClaimRequest = {
            label: this.label,
            tld: this.tld,
            owner: this.wallet.address,
            timestamp: this.dnsResponse.signedAt!,
        };

        this.registrationOperation = this.tezosService.execute(client =>
            client.manager.batch(async b => {
                const ops: WalletTransferParams[] = [];
                const claimOp = await b.claim(this.dnsResponse!.signature!, claimParams);

                ops.push(claimOp);

                if (formValue.address) {
                    const updateOp = await b.updateRecord({
                        data: new RecordMetadata(),
                        owner: this.wallet.address,
                        address: emptyStringToNull(formValue.address),
                        name: this.name,
                    });
                    ops.push(updateOp);
                }

                if (formValue.createReverseRecord) {
                    const reverseRecordOp = await b.claimReverseRecord({ name: this.name, owner: this.wallet.address });
                    ops.push(reverseRecordOp);
                }

                return ops;
            })
        );
    }

    claimDone(event: OperationStatusDoneEvent): void {
        if (event.success) {
            this.reverseRecordService.refresh();
            this.setNextStep();
        } else {
            this.form.enable();
        }
    }

    done(): void {
        this.finished.next();
    }

    private setDnsResponse(dnsResponse: DomainValidationResponse): void {
        this.dnsResponse = dnsResponse;

        switch (this.dnsResponse.status) {
            case 'OK':
                this.setNextStep(DnsBridgeStep.Claim);
                break;
            case 'NoDNSSEC':
            case 'FailedDNSSEC':
                this.error = this.translocoService.translate('dns-bridge.failed-dnssec');
                this.setNextStep(DnsBridgeStep.DNSSEC);
                break;
            case 'NoTXT':
                this.error = this.translocoService.translate('dns-bridge.no-txt-record');
                this.setNextStep(DnsBridgeStep.TXTRecord);
                break;
            case 'NoAddressInTXT':
                this.error = this.translocoService.translate('dns-bridge.no-address-txt');
                this.setNextStep(DnsBridgeStep.TXTRecord);
                break;
            case 'MultipleAddressesInTXT':
                this.error = this.translocoService.translate('dns-bridge.multiple-txt-addresses');
                this.setNextStep(DnsBridgeStep.TXTRecord);
                break;
            case 'TXTNoMatch':
                this.error = this.translocoService.translate('dns-bridge.txt-nomatch', { address: this.dnsResponse.txtValue });
                this.setNextStep(DnsBridgeStep.TXTRecord);
                break;
            case 'UnknownFailure':
                this.error = this.translocoService.translate('dns-bridge.error', { error: this.dnsResponse.error });
                break;
            default:
                throw new Error('weird - shouldn`t happen as they say');
        }
    }
}
