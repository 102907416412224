import { DataSource } from '@angular/cdk/table';

import { Unarray } from '../utils/types';
import { DataSourceBase, ProcessedData, DataSourceOptions } from '../data-source-base';
import { AuctionListQuery, AuctionListQueryVariables } from './graphql.generated';

export type AuctionRecord = Unarray<AuctionListQuery['auctions']['edges']>['node'];

export class AuctionTableDataSource extends DataSourceBase<AuctionListQuery, AuctionRecord[], AuctionListQueryVariables> implements DataSource<AuctionRecord> {
    constructor(options: DataSourceOptions<AuctionListQuery, AuctionListQueryVariables>) {
        super({
            ...options,
            defaultVariables: { first: 30 },
        });
    }

    protected transformData(data: AuctionListQuery): ProcessedData<AuctionRecord[]> {
        return {
            data: data.auctions.edges.map(e => e.node),
            pageInfo: data.auctions.pageInfo,
            isEmpty: !data.auctions.edges.length,
        };
    }
}
