<ng-container *transloco="let t">
    <h1>{{ t('settings.title') }}</h1>

    <div class="d-flex flex-column gap-4">
        <div *ngIf="network" class="card smooth-shadow d-flex flex-column gap-3">
            <h2>{{ t('settings.connection-info-title') }}</h2>
            <div *ngIf="!wallet" class="single-setting">
                <div class="mat-body-strong single-setting__text">{{ t('settings.connected-wallet-label') }}</div>
                <span class="ps-3 w-100">{{ t('settings.connected-wallet-not-connected') }}</span>
            </div>
            <div *ngIf="wallet" class="single-setting">
                <div class="mat-body-strong single-setting__text">{{ t('settings.connected-wallet-label') }}</div>
                <td-address class="ps-3 w-100" [address]="wallet.address" [link]="false" [reverseRecord]="reverseRecord"></td-address>
            </div>
            <div class="single-setting">
                <div class="mat-body-strong single-setting__text">{{ t('settings.network-label') }}</div>
                <span class="ps-3">{{ network.name }}</span>
            </div>
            <div class="single-setting">
                <div class="mat-body-strong single-setting__text">{{ t('settings.rpc-url-label') }}</div>
                <td-rpc-url-editor></td-rpc-url-editor>
            </div>
        </div>

        <div class="card smooth-shadow d-flex flex-column gap-3">
            <h2>{{ t('settings.hardware-wallet-title') }}</h2>
            <div class="single-setting">
                <div class="mat-body-strong single-setting__text">{{ t('settings.derivation-path-label') }}</div>
                <td-derivation-path-editor></td-derivation-path-editor>
            </div>
        </div>

        <div class="card smooth-shadow d-flex flex-column gap-3">
            <h2>{{ t('settings.app-title') }}</h2>
            <div class="single-setting">
                <div class="mat-body-strong single-setting__text">{{ t('settings.version-label') }}</div>
                <span class="ps-3 w-100 flex-grow-1">{{ version.app.version }}-{{ version.app.hash | slice : 0 : 6 }}</span>

                <button mat-stroked-button class="flex-shrink-0" (click)="checkForUpdate()">
                    {{ t('actions.check-for-update') }}
                </button>
            </div>
            <div class="single-setting">
                <div class="mat-body-strong single-setting__text">{{ t('settings.language-label') }}</div>
                <td-language-switcher></td-language-switcher>
            </div>
            <div class="single-setting">
                <div class="mat-body-strong single-setting__text">{{ t('settings.timezone-label') }}</div>
                <span class="ps-3 w-100">{{ timezone }}</span>
            </div>
        </div>
    </div>
</ng-container>
