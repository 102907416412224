<ng-container *transloco="let t">
    <div [hidden]="dataSource!.initialLoading$ | async">
        <td-error *ngIf="dataSource!.error$ | async; let error" [message]="error.message" [title]="t('general.error')"></td-error>

        <div class="table-container">
            <mat-table class="table" [dataSource]="dataSource!">
                <ng-container matColumnDef="time">
                    <mat-header-cell class="col-when" *matHeaderCellDef>{{ t('fields.when') }}</mat-header-cell>
                    <mat-cell class="col-when" *matCellDef="let row">
                        <span [matTooltip]="row.block.timestamp | dayjsDate"><td-humanized-date [value]="row.block.timestamp"></td-humanized-date></span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="text">
                    <mat-header-cell *matHeaderCellDef>{{ t('fields.action') }}</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <div class="text-truncate flex-grow"><td-event-text [povAddress]="address" [event]="row"></td-event-text></div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="amount">
                    <mat-header-cell class="col-amount" *matHeaderCellDef>{{ t('fields.balance') }}</mat-header-cell>
                    <mat-cell class="col-amount" *matCellDef="let row">
                        <td-event-balance [povAddress]="address" [event]="row"></td-event-balance>
                    </mat-cell>
                </ng-container>
                <mat-row *matRowDef="let row; columns: columns"></mat-row>
            </mat-table>
        </div>

        <div class="mt-3 ms-3">
            <a [routerLink]="['/address', address, 'activity']">{{ t('general.see-more') }}</a>
        </div>
    </div>
</ng-container>
