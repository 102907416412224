import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ConnectCtaComponent } from './connect-cta.component';
import { DashboardCtaComponent } from './dashboard-cta.component';
import { RegisterBulkCtaComponent } from './register-bulk-cta.component';
import { RegisterCtaComponent } from './register-cta.component';

@NgModule({
    declarations: [ConnectCtaComponent, RegisterCtaComponent, DashboardCtaComponent, RegisterBulkCtaComponent],
    exports: [ConnectCtaComponent, RegisterCtaComponent, DashboardCtaComponent, RegisterBulkCtaComponent],
    imports: [SharedModule],
})
export class CtaModule {}
