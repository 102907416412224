import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { TdValidators } from '../utils/form-validators';
import { wrapFocus } from '../utils/hacks';
import { PriceFilter } from './model';

@Component({
    selector: 'td-price-filter',
    templateUrl: './price-filter.component.html',
    styleUrls: ['./price-filter.component.scss'],
})
export class PriceFilterComponent implements OnInit {
    @Input() title: string;
    @Input() initialPrice: PriceFilter | null = null;
    @Output() priceFilterChange = new EventEmitter<PriceFilter | null>();

    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
    @ViewChild('minInput') minInput!: ElementRef;

    form: UntypedFormGroup;
    priceDisplay: 'lowerBound' | 'upperBound' | 'bounded' | 'noPrice' = 'noPrice';
    crtPrice: PriceFilter | null = null;

    constructor(private formBuilder: UntypedFormBuilder) {}

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            min: this.formBuilder.control('', [TdValidators.number()]),
            max: this.formBuilder.control('', [TdValidators.number()]),
        });

        if (this.initialPrice) {
            this.form.get('min')?.setValue(this.initialPrice.min);
            this.form.get('max')?.setValue(this.initialPrice.max);
            this.crtPrice = this.initialPrice;
            this.priceDisplay = this.getPriceDisplay();
        }
    }

    submit(): void {
        if (this.form.valid) {
            this.crtPrice = {
                min: this.parseNumber(this.form.value.min, 0),
                max: this.parseNumber(this.form.value.max),
            };
            this.priceFilterChange.next(this.crtPrice);
            this.trigger.closeMenu();
        }
    }

    isDisabled() {
        return !this.form.valid || (!this.form.value.min && !this.form.value.max);
    }

    clear() {
        this.form.reset();
        this.crtPrice = null;
        this.priceFilterChange.next(this.crtPrice);
        this.trigger.closeMenu();
    }

    priceClick() {
        this.trigger.openMenu();
    }

    menuClosed() {
        this.priceDisplay = this.getPriceDisplay();
    }

    menuOpened() {
        wrapFocus(() => this.minInput.nativeElement.focus());
    }

    private getPriceDisplay() {
        if (this.crtPrice) {
            if (this.crtPrice.min && this.crtPrice.max) {
                return 'bounded';
            }
            if (this.crtPrice.min) {
                return 'lowerBound';
            }

            return 'upperBound';
        }
        return 'noPrice';
    }

    private parseNumber(value: string | undefined, defaultValue: number | undefined = undefined): number | undefined {
        if (value == null) {
            return defaultValue;
        }

        const parsed = Number(value);
        if (isNaN(parsed)) {
            return defaultValue;
        }

        return parsed;
    }
}
