import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AuctionListComponent } from './auction-list.component';
import { AuctionTableComponent } from './auction-table.component';
import { SharedModule } from '../shared/shared.module';
import { ErrorModule } from '../error/error.module';
import { LayoutModule } from '../layout/layout.module';
import { AuctionSummaryTableComponent } from './auction-summary-table.component';
import { HotAuctionSummaryTableComponent } from './hot-auction-summary-table.component';
import { AuctionHistorySummaryTableComponent } from './auction-history-summary-table.component';
import { HistoryAuctionComponent } from './history-auction.component';
import { BidTableComponent } from './bid-table.component';
import { AuctionHistoryTableComponent } from './auction-history-table.component';
import { AuctionHistoryListComponent } from './auction-history-list.component';
import { SkeletonModule } from '../skeleton/skeleton.module';
import { HotAuctionTableComponent } from './hot-auction-table.component';
import { HotAuctionListComponent } from './hot-auction-list.component';

@NgModule({
    declarations: [
        AuctionListComponent,
        AuctionTableComponent,
        AuctionSummaryTableComponent,
        HotAuctionSummaryTableComponent,
        AuctionHistorySummaryTableComponent,
        HistoryAuctionComponent,
        BidTableComponent,
        AuctionHistoryTableComponent,
        AuctionHistoryListComponent,
        HotAuctionTableComponent,
        HotAuctionListComponent,
    ],
    imports: [SharedModule, ErrorModule, ReactiveFormsModule, LayoutModule, SkeletonModule],
    exports: [AuctionSummaryTableComponent, HotAuctionSummaryTableComponent, AuctionHistorySummaryTableComponent, BidTableComponent],
})
export class AuctionsModule {}
