<ng-container *transloco="let t">
    <td-error *ngIf="dataSource.error$ | async; let error" [message]="error.message" [title]="t('general.error')"></td-error>
    <ng-container>
        <div [hidden]="(initialLoading | async) === true">
            <ng-container *ngFor="let domain of data$ | async; let last = last">
                <td-domain-listing
                    [domainName]="domain.name"
                    [price]="domain.price"
                    [seller]="domain.seller"
                    [buttonText]="domain.buttonText ? t(domain.buttonText) : ''"
                    [buttonDisabled]="domain.buttonDisabled"
                    [expiration]="domain.expiration"
                    [compactX]="true"
                    (buttonAction)="domain.buttonAction()"
                    class="full-card-width"
                >
                    <td-humanized-date
                        *ngIf="domain.expiration && domain.expiration <= now"
                        [readOnly]="true"
                        [matTooltip]="domain.expiration | dayjsDate"
                        class="expired-date"
                        [value]="domain.expiration"
                        [prefix]="t('expired-domains.expired-ago')"
                    ></td-humanized-date>
                </td-domain-listing>
                <mat-divider *ngIf="!last"></mat-divider>
            </ng-container>
        </div>
    </ng-container>
    <div *ngIf="initialLoading | async" class="w-100 my-3">
        <div class="d-flex flex-column align-items-center">
            <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
        </div>
    </div>
</ng-container>
