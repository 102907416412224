import { Component, OnInit, inject } from '@angular/core';

import { AppService } from '@/app-service';
import { GovLatestBlockGQL } from '@/governance-core/governance-graphql.generated';
import { PoolStatsService } from '@/gv-pool/pool-stats.service';
import { catchError, combineLatest, interval, map, of, pairwise, startWith, switchMap } from 'rxjs';

@Component({
    selector: 'td-governance-unavailable',
    templateUrl: './governance-unavailable.component.html',
    styleUrls: ['./governance-unavailable.component.scss'],
})
export class GovernanceUnavailableComponent implements OnInit {
    private readonly poolStatsService = inject(PoolStatsService);
    private readonly appService = inject(AppService);
    private readonly blockGQL = inject(GovLatestBlockGQL);

    show = false;

    ngOnInit(): void {
        const apiStatePolling$ = interval(5 * 60 * 1000).pipe(
            switchMap(() => {
                if (!this.appService.isOnline) {
                    // if we're not online ... assume the best of the api
                    return of({ isDown: false });
                }

                return this.blockGQL.fetch({}, { fetchPolicy: 'network-only' }).pipe(
                    catchError(() => of({ data: { blocks: undefined } })),
                    map(({ data }) => {
                        const isDown = !data.blocks?.length;

                        return { isDown };
                    })
                );
            }),
            startWith({ isDown: false }),
            pairwise(),
            map(([oldState, newState]) => {
                const apiAvailableAgain = oldState.isDown && !newState.isDown;
                const apiDown = newState.isDown;
                return { apiAvailableAgain, apiDown };
            })
        );

        combineLatest([apiStatePolling$, this.appService.online]).subscribe(([apiState, online]) => {
            if (!online) {
                this.show = false;
                return;
            }

            if (apiState.apiAvailableAgain) {
                this.poolStatsService.refresh();
            }

            this.show = apiState.apiDown;
        });
    }
}
