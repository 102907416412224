import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ReverseRecord, ReverseRecordService } from '../domains/reverse-record.service';

@Component({
    selector: 'td-dashboard-title',
    templateUrl: './title.component.html',
    styleUrls: ['./title.component.scss'],
})
export class TitleComponent implements OnInit, OnDestroy {
    reverseRecord: ReverseRecord | null;
    private unsubscribe = new Subject<void>();

    constructor(private reverseRecordService: ReverseRecordService) {}

    ngOnInit(): void {
        this.reverseRecordService.current.pipe(takeUntil(this.unsubscribe)).subscribe(r => (this.reverseRecord = r));
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
