import { PoolStatsService } from '@/gv-pool/pool-stats.service';
import { Injectable } from '@angular/core';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Configuration } from '../configuration';
import { AirdropFileService } from './airdrop-file.service';
import { AirdropClaimState, ClaimMeta, ClaimStatus, EmptyClaimMeta } from './models';

@Injectable()
export class ClaimInfoService {
    private claimCache = new Map<string, Observable<AirdropClaimState | null>>();

    constructor(private airdropFileService: AirdropFileService, private poolStats: PoolStatsService, private config: Configuration) {}

    canClaimTokens(owner: string): Observable<AirdropClaimState | null> {
        const now = new Date(Date.now());
        const airdropInProgress = now > this.config.airdrop.start && now < this.config.airdrop.end;
        const airdropEnded = now > this.config.airdrop.end;

        if (!airdropInProgress) {
            return this.emptyResponse(airdropEnded, owner);
        }

        if (this.claimCache.has(owner)) {
            return this.claimCache.get(owner)!;
        } else {
            // we just want to store the claim for the current user so if not in cache - ensure it's empty
            this.claimCache.clear();
        }

        const claimResult$ = combineLatest([this.airdropFileService.load(owner), this.loadClaimed(owner)]).pipe(
            map(([claimFileData, claimed]) => {
                if (!claimed) {
                    return null;
                }

                const now = dayjs();

                const amountClaimed = BigNumber.sum(...claimed.map(c => c.amount), 0);
                const claimableClaims = claimFileData.claims.filter(c => c.from < now && !claimed.some(cl => cl.from.isSame(c.from)));
                const lockedClaims = claimFileData.claims.filter(c => c.from > now);
                const amountClaimable = BigNumber.sum(...claimableClaims.map(c => c.amount), 0);
                const amountLocked = BigNumber.sum(...lockedClaims.map(c => c.amount), 0);
                const totalAmount = BigNumber.sum(...claimFileData.claims.map(c => c.amount), 0);
                const claimStatus = this.getClaimStatus({ amountClaimed, amountClaimable, amountLocked, meta: claimFileData.meta });

                return {
                    status: claimStatus,
                    hasOwnershipClaimsBlocked: this.hasOwnershipClaimsBlocked(claimFileData.meta),
                    meta: claimFileData.meta ?? EmptyClaimMeta,
                    owner,
                    claimableClaims,
                    futureClaims: lockedClaims,
                    totalClaims: claimFileData.claims.length,
                    amountClaimed,
                    amountLocked,
                    amountClaimable,
                    totalAmount,
                };
            })
        );

        this.claimCache.set(owner, claimResult$);

        return claimResult$;
    }

    private emptyResponse(airdropEnded: boolean, owner: string): Observable<AirdropClaimState> {
        return of({
            status: airdropEnded ? ClaimStatus.AirdropEnded : ClaimStatus.NoClaim,
            meta: EmptyClaimMeta,
            owner,
            claimableClaims: [],
            futureClaims: [],
            totalClaims: 0,
            amountClaimed: BigNumber(0),
            amountLocked: BigNumber(0),
            amountClaimable: BigNumber(0),
            totalAmount: BigNumber(0),
            hasOwnershipClaimsBlocked: false,
        } as AirdropClaimState);
    }

    private loadClaimed(owner: string) {
        return this.poolStats.stats$.pipe(
            filter(state => !state || state?.owner === owner),
            map(stats => stats?.claims)
        );
    }

    private getClaimStatus({
        amountClaimed,
        amountClaimable,
        amountLocked,
        meta,
    }: {
        amountClaimed: BigNumber;
        amountClaimable: BigNumber;
        amountLocked: BigNumber;
        meta?: ClaimMeta;
    }): ClaimStatus {
        if (meta?.blacklistedInFavorOfAddress) {
            if (!this.hasBonusRewards(meta)) {
                return ClaimStatus.Blocked;
            }
        }

        if (amountClaimed.eq(0) && amountLocked.eq(0) && amountClaimable.eq(0)) {
            return ClaimStatus.NoClaim;
        }

        if (amountClaimed.isGreaterThan(0)) {
            return amountLocked.isGreaterThan(0) || amountClaimable.isGreaterThan(0) ? ClaimStatus.ClaimedPartially : ClaimStatus.Claimed;
        }

        return ClaimStatus.Unclaimed;
    }

    private hasOwnershipClaimsBlocked(meta: ClaimMeta | undefined): boolean {
        if (!meta) return false;

        return Boolean(meta.blacklistedInFavorOfAddress) && this.hasBonusRewards(meta);
    }

    private hasBonusRewards(meta: ClaimMeta): boolean {
        return meta.discordOgTokens.gt(0) || meta.translators.gt(0) || meta.integrators.gt(0) || meta.externalContributors.gt(0) || meta.keyHolders.gt(0);
    }
}
