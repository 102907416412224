import { Component, inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import BigNumber from 'bignumber.js';
import { Observable } from 'rxjs';
import { BuyOfferRecord } from '../graphql/buy-offer-table-data-source';
import { OperationStatusDoneEvent } from '../shared/operation-status.component';
import { BuyOfferBrokerService } from '../tezos/nft/buy-offer-broker.service';
import { SmartContractOperationEvent } from '../tezos/tezos.service';

@Component({
    selector: 'td-bulk-remove-buy-offers',
    templateUrl: './bulk-remove-buy-offers.component.html',
    styleUrls: ['./bulk-remove-buy-offers.component.scss'],
})
export class BulkRemoveBuyOffersComponent implements OnInit {
    private data = inject<{ offers: BuyOfferRecord[] }>(MAT_DIALOG_DATA);
    private dialogRef = inject(MatDialogRef);
    private buyOfferBrokerService = inject(BuyOfferBrokerService);

    operation: Observable<SmartContractOperationEvent> | null;
    submit: boolean;

    offers: BuyOfferRecord[];
    totalAmountReturned: BigNumber;

    ngOnInit() {
        this.offers = this.data.offers;
        this.totalAmountReturned = this.offers.reduce((sum, o) => o.price.plus(sum), new BigNumber(0));
    }

    operationDone(event: OperationStatusDoneEvent) {
        if (event.success) {
            this.dialogRef.close(true);
        } else {
            this.submit = false;
        }
    }

    cancel() {
        this.dialogRef.close(false);
    }

    save() {
        this.submit = true;
        this.operation = this.buyOfferBrokerService.removeOffer(this.offers.map(x => x.tokenId));
    }
}
