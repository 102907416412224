import { Component, EventEmitter, inject, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Configuration } from '../configuration';
import { BulkDomainsDataService } from '../file-processing/file-data.service';
import { FileDragService } from '../file-processing/file-drag.service';

@UntilDestroy()
@Component({
    selector: 'td-register-bulk-cta',
    templateUrl: './register-bulk-cta.component.html',
    styleUrls: ['./register-bulk-cta.component.scss'],
})
export class RegisterBulkCtaComponent {
    private fileData = inject(BulkDomainsDataService);
    private fileDrag = inject(FileDragService);
    private config = inject(Configuration);

    @Output() rawDomainsChange = new EventEmitter<string>();

    fileOverWindow = false;
    showTextArea = false;
    domainsInput = '';
    maxDomains = this.config.maxBulkCreate;
    fileName$: Observable<string>;

    constructor() {
        this.fileDrag.overWindow.pipe(untilDestroyed(this)).subscribe(isOver => (this.fileOverWindow = isOver));
        this.fileName$ = this.fileData.domainNames.pipe(
            map(() => this.fileData.fileName),
            untilDestroyed(this)
        );
    }

    async fileChange(e: Event) {
        const file = (e.target as HTMLInputElement).files?.item(0);
        if (!file) return;
        if (!this.fileData.isValidFile(file)) {
            return;
        }

        await this.fileData.setDomains(file);
    }

    toggleTextArea() {
        this.showTextArea = !this.showTextArea;
    }

    cancel() {
        this.showTextArea = false;
        this.domainsInput = '';
    }

    continue() {
        this.rawDomainsChange.next(this.domainsInput);
    }
}
