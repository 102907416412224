import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, merge, Subject } from 'rxjs';
import { debounceTime, filter, map, pairwise, startWith } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'td-horizontal-scroll',
    templateUrl: './horizontal-scroll.component.html',
    styleUrls: ['./horizontal-scroll.component.scss'],
})
export class HorizontalScrollComponent implements AfterViewInit {
    private refreshScroll$ = new Subject<void>();

    @ViewChild('scrollingEl') scrollingEl: ElementRef;

    constructor(private el: ElementRef, private renderer: Renderer2) {}

    ngAfterViewInit() {
        const canScroll = this.scrollingEl.nativeElement.scrollWidth > this.scrollingEl.nativeElement.clientWidth;

        if (!canScroll) {
            return;
        }

        this.renderer.addClass(this.el.nativeElement, 'fade-right');

        const scrollStream$ = fromEvent(this.scrollingEl.nativeElement, 'scroll', { passive: true }).pipe(debounceTime(50));

        merge(scrollStream$, this.refreshScroll$)
            .pipe(
                map(() => {
                    const scrollEl = this.scrollingEl.nativeElement;
                    if (scrollEl.offsetWidth + scrollEl.scrollLeft >= scrollEl.scrollWidth) {
                        return 'fade-left';
                    } else if (scrollEl.scrollLeft > 0) {
                        return 'fade-both';
                    }

                    return 'fade-right';
                }),
                startWith('fade-right'),
                pairwise(),
                filter(([old, newClass]) => old !== newClass),
                untilDestroyed(this)
            )
            .subscribe(([oldClass, newClass]) => {
                this.renderer.removeClass(this.el.nativeElement, oldClass ?? '');
                this.renderer.addClass(this.el.nativeElement, newClass);
            });
    }

    refreshScroll() {
        this.refreshScroll$.next();
    }
}
