import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { CdkAccordionModule } from '@angular/cdk/accordion';
import { ReactiveFormsModule } from '@angular/forms';
import { DelegationModule } from '../delegation/delegation.module';
import { GovPoolModule } from '../gv-pool/gov-pool.module';
import { SkeletonModule } from '../skeleton/skeleton.module';
import { AirdropAnnouncementComponent } from './airdrop-announcement.component';
import { AirdropFileService } from './airdrop-file.service';
import { BlockedUserComponent } from './blocked-user.component';
import { ClaimConstitutionComponent } from './claim-constitution.component';
import { ClaimInfoService } from './claim-info.service';
import { ClaimComponent } from './claim.component';
import { ConstitutionStepperComponent } from './constitution-stepper.component';
import { DelegateListComponent } from './delegate-list.component';
import { DelegateSelectionComponent } from './delegate-selection.component';
import { RejectWarningComponent } from './reject-warning.component';
import { TokensComponent } from './tokens.component';
import { UnclaimedTokensAnnouncementComponent } from './unclaimed-tokens-announcement.component';
import { VestingWidgetComponent } from './vesting-widget.component';
@NgModule({
    declarations: [
        ClaimComponent,
        ClaimConstitutionComponent,
        ConstitutionStepperComponent,
        DelegateListComponent,
        RejectWarningComponent,
        AirdropAnnouncementComponent,
        UnclaimedTokensAnnouncementComponent,
        DelegateSelectionComponent,
        TokensComponent,
        VestingWidgetComponent,
        BlockedUserComponent,
    ],
    providers: [ClaimInfoService, AirdropFileService],
    imports: [SharedModule, ReactiveFormsModule, SkeletonModule, DelegationModule, CdkAccordionModule, GovPoolModule],
    exports: [
        ClaimComponent,
        RejectWarningComponent,
        AirdropAnnouncementComponent,
        UnclaimedTokensAnnouncementComponent,
        VestingWidgetComponent,
        BlockedUserComponent,
    ],
})
export class AirdropModule {}
