import { TezosService } from '@/tezos/tezos.service';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import dayjs from 'dayjs';
import { keyBy } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { first, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { StorageSchema } from '../utils/storage';
import { MessageService } from './message-service.service';

export interface Favourite {
    id: string;
    date: dayjs.Dayjs;
}

@Injectable({
    providedIn: 'root',
})
export class FavouritesService {
    private readonly storageMap = inject(StorageMap);
    private readonly tezosService = inject(TezosService);
    private readonly messageService = inject(MessageService);
    private readonly router = inject(Router);

    readonly favourites$ = this.tezosService.activeWallet.pipe(
        switchMap(w => {
            if (!w) {
                return of({ favs: [], rawFavs: [], address: '' });
            }

            const favSchema = StorageSchema.favourites(w.address);

            return this.storageMap.watch<Favourite[]>(favSchema.key, favSchema.schema).pipe(
                map(data => {
                    if (!data) {
                        return { favs: [], rawFavs: [], address: w.address };
                    }

                    return {
                        favs: data.map(d => ({ ...d, date: dayjs(d.date) })).sort((a, b) => (a.date.isBefore(b.date) ? -1 : 1)),
                        rawFavs: data,
                        address: w.address,
                    };
                })
            );
        }),
        shareReplay(1)
    );

    readonly favouritesMap$ = this.favourites$.pipe(
        map(favourites => keyBy(favourites.favs, f => f.id)),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    isFavourite$(domain: string): Observable<boolean> {
        return this.favouritesMap$.pipe(map(m => Boolean(m[domain])));
    }

    toggleFavourite(id: string): Observable<void> {
        return this.isFavourite$(id).pipe(
            first(),
            switchMap(isFav => (isFav ? this.removeFavourite(id) : this.addFavourite(id)))
        );
    }

    addFavourite(id: string): Observable<void> {
        return this.favourites$.pipe(
            first(),
            switchMap(data => {
                if (data.favs.length >= 50) {
                    throw new Error('too many favourites');
                }

                if (!data.address) {
                    return of(void 0);
                }

                const favStorage = StorageSchema.favourites(data.address);
                return this.storageMap.set(favStorage.key, [...data.rawFavs, { id, date: dayjs().format() }], favStorage.schema).pipe(
                    tap(() => {
                        if (!data.rawFavs.length) {
                            // this was the first favourite added
                            this.messageService.success({
                                messageKey: 'favourites.first-fav',
                                messageParameters: { url: this.router.createUrlTree(['/address', data.address]).toString() },
                            });
                        }
                    })
                );
            })
        );
    }

    removeFavourite(id: string): Observable<void> {
        return this.favourites$.pipe(
            first(),
            switchMap(data => {
                if (!data.address) {
                    return of(void 0);
                }

                const updatedFavs = data.rawFavs.filter(f => f.id !== id);
                const favStorage = StorageSchema.favourites(data.address);

                return this.storageMap.set(favStorage.key, updatedFavs, favStorage.schema);
            })
        );
    }
}
