<td-global-announcement class="global-announcement"></td-global-announcement>
<td-top-nav class="top-bar"></td-top-nav>

<div class="page">
    <td-update-available></td-update-available>
    <td-offline></td-offline>
    <td-outdated-data></td-outdated-data>
    <td-governance-unavailable></td-governance-unavailable>
    <td-file-drag></td-file-drag>
    <router-outlet></router-outlet>
</div>
