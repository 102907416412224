<div *transloco="let t">
    <td-error *ngIf="dataSource.error$ | async; let error" [message]="error.message" [title]="t('general.error')"></td-error>

    <div *ngIf="!someOffersLoaded && !loading" class="empty-message p-3 w-100 text-center smooth-shadow">
        {{ t('buy-offer-list.no-offers') }}
    </div>

    <div
        class="table-container smooth-shadow"
        [ngClass]="{ 'smooth-shadow': showShadow, 'd-flex': someOffersLoaded && !loading }"
        infiniteScroll
        [infiniteScrollDisabled]="!scrollToLoad"
        (scrolled)="scrolled()"
        [hidden]="!someOffersLoaded || loading"
    >
        <div class="flex-grow-1">
            <ng-container *ngFor="let offer of data$ | async; let last = last">
                <td-buy-offer-listing
                    [offer]="offer"
                    [wallet]="wallet"
                    (offerActedOn)="offerActedOn($event)"
                    [allowedToIgnoreOffer]="allowedToIgnoreOffer"
                    [canShowYouBadge]="canShowYouBadge"
                    [showOfferSelectorPlaceholder]="showSelectOffersCheckbox && offer.buyer !== wallet?.address"
                    [canSelectOffer]="canSelectOffers && offer.buyer === wallet?.address"
                    [isSelected]="selection.isSelected(offer)"
                    (offerSelected)="offerSelected($event, offer)"
                ></td-buy-offer-listing>
                <mat-divider *ngIf="!last"></mat-divider>
            </ng-container>
        </div>
    </div>
    <div *ngIf="loading" class="w-100 mt-3 d-flex justify-content-center">
        <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>
</div>
