<ng-container *transloco="let t">
    <div class="d-flex flex-column">
        <div *ngFor="let key of keys" class="d-flex flex-column flex-sm-row align-items-sm-center align-items-start">
            <div class="mat-body-strong text-truncate pe-1 description">
                <span [matTooltip]="key.name">
                    {{ key.displayName }}
                </span>
            </div>
            <div class="d-flex align-items-center w-100" *ngIf="getFieldDescriptor(key.name); let descriptor">
                <div class="d-flex flex-column w-100">
                    <ng-container
                        *ngComponentOutlet="
                            descriptor.editorComponent;
                            ndcDynamicInputs: { key: key.displayName, args: descriptor.editorComponentArgs, control: form.get(key.name) }
                        "
                    ></ng-container>
                </div>
                <button (click)="removeKey(key.name)" mat-icon-button class="flex-shrink-0" [disabled]="form.disabled">
                    <fa-icon icon="times"></fa-icon>
                </button>
            </div>
        </div>
        <form [formGroup]="addKeyForm" class="d-flex flex-column" (submit)="addKey()">
            <mat-form-field class="w-100" appearance="standard">
                <mat-autocomplete #availableKeysAuto="matAutocomplete" [displayWith]="display" (optionSelected)="addKey(); newInput.blur()">
                    <mat-option *ngFor="let key of filteredFields" [value]="key">{{ key.displayName }}</mat-option>
                </mat-autocomplete>
                <input
                    type="text"
                    #newInput
                    [placeholder]="t('data-editor.add-key-label')"
                    matInput
                    formControlName="key"
                    [matAutocomplete]="availableKeysAuto"
                />
            </mat-form-field>
            <div class="d-flex">
                <button
                    class="flex-shrink-0"
                    mat-stroked-button
                    type="submit"
                    [disabled]="!addKeyForm.get('key')!.value || form.disabled || keys.includes(addKeyForm.get('key')!.value)"
                >
                    {{ t('data-editor.add-key-button') }}
                </button>
            </div>
        </form>
    </div>
</ng-container>
