<ng-container *transloco="let t">
    <div *ngIf="dataSource.initialLoading$ | async" class="w-100 mt-3 d-flex justify-content-center">
        <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>

    <div [hidden]="dataSource.initialLoading$ | async">
        <td-error *ngIf="dataSource.error$ | async; let error" [message]="error.message" [title]="t('general.error')"></td-error>

        <div
            class="table-container"
            [ngClass]="{ 'smooth-shadow': noShadow !== true }"
            [hidden]="(dataSource.empty$ | async) || (dataSource.initialLoading$ | async)"
        >
            <ng-container *ngFor="let offer of dataSource.data$ | async; let last = last">
                <td-domain-listing
                    [domainName]="offer.domain?.name"
                    [price]="offer.price"
                    [expiration]="offer.expiration"
                    [seller]="offer.seller"
                    [buttonText]="t('actions.buy')"
                    [requireConnect]="true"
                    (buttonAction)="executeOffer(offer)"
                >
                </td-domain-listing>
                <mat-divider *ngIf="!last"></mat-divider>
            </ng-container>
        </div>
    </div>
</ng-container>
