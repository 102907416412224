<ng-container *transloco="let t">
    <div class="mb-3 me-2 d-flex" [style]="{ overflow: 'hidden' }">
        <td-horizontal-scroll class="flex-grow-1">
            <div class="d-flex gap-2">
                <td-domain-filter
                    (domainChange)="domainChanged($event)"
                    [initialDomain]="initialDomain"
                    [tzDomainsClient]="tzDomainsClient"
                    [validateDomainName]="false"
                ></td-domain-filter>
                <td-list-filter
                    [options]="offerTypeOptions"
                    [buttonLabel]="'buy-offer-list.filters.offer-types.button-label'"
                    [selectedOptions]="initialOfferTypes"
                    (selectedChange)="offerTypesChanged($event)"
                    *ngIf="showOfferTypeFilter"
                ></td-list-filter>
            </div>
        </td-horizontal-scroll>
        <td-sorter [defaultSort]="defaultSort" [sortOptions]="sortOptions" (sortChange)="sortChange($event)"></td-sorter>
    </div>
</ng-container>
