<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" version="1.1" viewBox="0 0 1242.351 1055.997" xmlSpace="preserve" class="gov-icon">
    <defs>
        <clipPath id="clipPath18" clipPathUnits="userSpaceOnUse">
            <path d="M0 791.998h931.763V0H0z" />
        </clipPath>
    </defs>
    <g transform="matrix(1.33333 0 0 -1.33333 0 1055.997)">
        <g>
            <g clipPath="url(#clipPath18)">
                <g transform="translate(195.31 694.383)">
                    <path
                        fill="currentColor"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        d="M0 0v-.046zm-37.37-32.167h-.015zm4.546-3.785l.057-18.919c.005-1.455-.242-2.915-.839-4.242-1.665-3.698-5.254-5.898-9.069-5.898h-14.8a3.781 3.781 0 01-3.781-3.781v-293.66h-19.818v301.524c0 8.69 7.044 15.734 15.734 15.734h8.933a3.782 3.782 0 013.782 3.794l-.042 13.658c0 8.644 6.705 15.393 15.264 15.393l25.521.047-.086 12.556c-.001 8.584 7.212 15.513 16.077 15.513 8.662 0 15.708-7.047 15.708-15.708l.074-12.392 25.272-.016c8.426-.005 15.254-6.838 15.254-15.263v-13.8a3.781 3.781 0 013.781-3.782h9.03c8.689 0 15.733-7.044 15.733-15.733v-301.525H69.143v293.66a3.782 3.782 0 01-3.783 3.781H50.508c-5.473 0-9.909 4.436-9.909 9.909v19.157a3.782 3.782 0 01-3.782 3.782l-65.86.005a3.78 3.78 0 01-3.781-3.794"
                    />
                </g>
                <g transform="translate(501.78 331.931)">
                    <path
                        fill="currentColor"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        d="M0 0h-19.818v135.18a6.71 6.71 0 01-2.649 5.34l-82.644 62.835a3.782 3.782 0 01-4.725-.118l-74.471-62.698a6.706 6.706 0 01-2.386-5.131V0h-19.819v140c0 5.14 2.408 10.041 6.459 13.187l82.005 69.043c.158.132.32.261.487.384 5.926 4.385 14.164 4.335 20.034-.13l90.938-69.141A16.692 16.692 0 000 140.056z"
                    />
                </g>
                <g transform="translate(642.532 331.931)">
                    <path
                        fill="currentColor"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        d="M0 0h-19.818v233.398a2.91 2.91 0 01-2.91 2.909h-9.233a8.929 8.929 0 00-8.929 8.928v15.447a3.085 3.085 0 01-3.085 3.085h-42.026a3.085 3.085 0 01-3.085-3.085v-15.447a8.93 8.93 0 00-8.929-8.928h-9.013a2.908 2.908 0 01-2.909-2.909V0h-19.818v242.379c0 7.592 6.154 13.746 13.746 13.746h4.969c1.18 0 2.136.956 2.136 2.136v11.833c0 7.451 6.04 13.491 13.49 13.491h60.851c7.451 0 13.492-6.041 13.492-13.492v-11.832c0-1.18.956-2.136 2.134-2.136h5.266c7.55 0 13.671-6.12 13.671-13.671z"
                    />
                </g>
                <g transform="translate(661.68 760.45)">
                    <path
                        fill="currentColor"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        d="M0 0l.015-.009zm155.848-428.52h-19.819v320.812c0 3.291-1.538 6.393-4.158 8.385L16.691-11.731c-2.036 1.549-4.965.096-4.965-2.463l.001-168.11c0-.522-.041-1.034-.119-1.535V-428.52H-8.21v246.216c0 .523.041 1.034.118 1.535V-.265l-.045 4.96c-.135 7.733 3.824 11.172 6.2 12.534 3.724 2.135 8.402 2.021 12.228-.293 1.946-1.159 6.619-4.003 6.619-4.003.292-.178.574-.37.847-.577L149.688-87.975c.118-.089.234-.181.347-.276 3.694-3.079 5.813-7.579 5.813-12.346z"
                    />
                </g>
                <g transform="translate(608.071 12.792)">
                    <path
                        fill="currentColor"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        d="M0 0h-284.38a6.052 6.052 0 00-6.052 6.053V45.37a6.051 6.051 0 006.052 6.052H0a6.052 6.052 0 006.053-6.052V6.053A6.053 6.053 0 000 0"
                    />
                </g>
                <g transform="translate(747.904 169.952)">
                    <path
                        fill="currentColor"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        d="M0 0l-40.165-69.367c-3.828-4.413-8.596-6.82-13.512-6.82h-458.844c-5.049 0-9.938 2.539-13.812 7.171L-564.08.041c-1.788 2.138-.732 6.304 1.599 6.304H-1.564C.788 6.345 1.831 2.111 0 0"
                    />
                </g>
                <g transform="translate(894.466 205.847)">
                    <path
                        fill="currentColor"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        d="M0 0h-857.168c-5.432 0-9.834 5.033-9.834 11.24l-18.136 81.364c0 6.208 4.403 11.24 9.834 11.24h893.44c5.43 0 9.834-5.032 9.834-11.24L9.834 11.24C9.834 5.033 5.431 0 0 0"
                    />
                </g>
            </g>
        </g>
    </g>
</svg>
