import { Injectable, inject } from '@angular/core';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { BehaviorSubject, Observable, combineLatest, of, timer } from 'rxjs';
import { catchError, shareReplay, switchMap } from 'rxjs/operators';
import { Configuration } from '../configuration';
import { TezosToolkitService } from '../tezos/integration/tezos-toolkit.service';
import { Logger } from '@/browser/logger';

export interface PoolContractStorage {
    poolSize: BigNumber;
    rewardRate: BigNumber;
    votesMinted: BigNumber;
    lastAccruedRewardsAt: dayjs.Dayjs;
}

@Injectable({
    providedIn: 'root',
})
export class PoolContractStatsService {
    private tezosToolkitService = inject(TezosToolkitService);
    private configuration = inject(Configuration);

    private refresh$ = new BehaviorSubject<void>(void 0);
    private intervalRefresh$ = timer(0, 5 * 60 * 1_000);

    refresh(): void {
        this.refresh$.next();
    }

    readonly storage$: Observable<PoolContractStorage | null> = combineLatest([this.refresh$, this.intervalRefresh$]).pipe(
        switchMap(() =>
            this.tezosToolkitService.current.pipe(
                switchMap(async tezos => {
                    const contract = await tezos.wallet.at(this.configuration.network.tedPoolContract);
                    const balances = await contract.storage<{
                        pool_size: BigNumber;
                        reward_rate: BigNumber;
                        votes_minted: BigNumber;
                        last_accrued_rewards_at: string;
                    }>();

                    return {
                        poolSize: balances.pool_size,
                        rewardRate: balances.reward_rate,
                        votesMinted: balances.votes_minted,
                        lastAccruedRewardsAt: dayjs(balances.last_accrued_rewards_at),
                    };
                }),
                catchError(() => of(null))
            )
        ),
        shareReplay({ bufferSize: 1, refCount: true })
    );
}

export interface TedVContractStorage {
    totalSupply: BigNumber;
}

@Injectable({
    providedIn: 'root',
})
export class TedVContractService {
    private tezosToolkitService = inject(TezosToolkitService);
    private configuration = inject(Configuration);
    private logger = inject(Logger);

    readonly storage$: Observable<TedVContractStorage | null> = this.tezosToolkitService.current.pipe(
        switchMap(async tezos => {
            const contract = await tezos.wallet.at(this.configuration.network.tedVotesContract);
            const storage = await contract.storage<{
                assets: {
                    total_supply: BigNumber;
                };
            }>();

            return {
                totalSupply: storage.assets.total_supply,
            };
        }),
        shareReplay({ bufferSize: 1, refCount: true, windowTime: 60 * 1000 }),
        catchError(err => {
            this.logger.error(err);
            return of(null);
        })
    );
}
