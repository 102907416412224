<form class="d-flex flex-fill flex-column h-100" [formGroup]="form" *transloco="let t">
    <div class="d-flex flex-column">
        <h1 mat-dialog-title class="text-truncate">{{ t('website-form.title') }}</h1>
        <td-operation-status [operation]="operation" (done)="operationDone($event)" #op></td-operation-status>
    </div>
    <mat-dialog-content class="d-flex flex-column flex-grow-1">
        <div class="d-flex flex-column gap-1 justify-content-start">
            <div class="mb-4" [innerHTML]="t('website-form.info', { link: 'about/publishing-websites' | landingPageUrl })"></div>
            <mat-radio-group formControlName="mode">
                <mat-radio-button value="content" class="me-3">{{ t('website-form.mode-content') }}</mat-radio-button>
                <mat-radio-button value="redirect" class="me-3">{{ t('website-form.mode-redirect') }}</mat-radio-button>
                <mat-radio-button value="inactive">{{ t('website-form.mode-inactive') }}</mat-radio-button>
            </mat-radio-group>
            <mat-form-field appearance="standard" *ngIf="form.get('mode')!.value === 'content'">
                <mat-label>{{ t('fields.content-url') }}</mat-label>
                <input formControlName="contentUrl" [placeholder]="t('data-editor.content-url.placeholder')" matInput required />
                <mat-error>
                    <td-validation-messages [control]="form.get('contentUrl')!" [context]="{ name: t('fields.content-url') }"></td-validation-messages>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard" *ngIf="form.get('mode')!.value === 'redirect'">
                <mat-label>{{ t('fields.redirect-url') }}</mat-label>
                <input formControlName="redirectUrl" [placeholder]="t('fields.redirect-url-placeholder')" matInput required />
                <mat-error>
                    <td-validation-messages [control]="form.get('redirectUrl')!" [context]="{ name: t('fields.redirect-url') }"></td-validation-messages>
                </mat-error>
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex justify-content-end align-items-end">
        <button mat-flat-button color="primary" *ngIf="domain.owner" (click)="save()" [disabled]="form.invalid || op.inProgress">
            {{ t('actions.save') }}
        </button>
        <button mat-button (click)="cancel()" [disabled]="op.inProgress">{{ t('actions.cancel') }}</button>
    </mat-dialog-actions>
</form>
