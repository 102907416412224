import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TezosService } from '../tezos/tezos.service';
import { TezosWallet, TezosNetwork } from '../tezos/models';
import { TezosNetworkService } from '../tezos/tezos-network.service';
import { AppVersion, AppService } from '../app-service';
import { ReverseRecordService, ReverseRecord } from '../domains/reverse-record.service';
import { PageService } from '../browser/page.service';

@Component({
    selector: 'td-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {
    wallet: TezosWallet | null;
    network: TezosNetwork;
    reverseRecord: ReverseRecord | null;
    connectionType$: Observable<string | null>;
    timezone: string;
    version: AppVersion;

    private unsubscribe = new Subject<void>();

    constructor(
        private tezosService: TezosService,
        private tezosNetworkService: TezosNetworkService,
        private reverseRecordService: ReverseRecordService,
        private appService: AppService,
        private pageService: PageService
    ) {}

    checkForUpdate() {
        this.appService.checkForUpdate();
    }

    ngOnInit(): void {
        this.pageService.setTitle('settings');
        this.tezosService.activeWallet.pipe(takeUntil(this.unsubscribe)).subscribe(w => (this.wallet = w));
        this.reverseRecordService.current.pipe(takeUntil(this.unsubscribe)).subscribe(r => (this.reverseRecord = r));
        this.tezosNetworkService.activeNetwork.pipe(takeUntil(this.unsubscribe)).subscribe(n => (this.network = n));

        this.timezone = this.appService.timezone;
        this.version = this.appService.version;
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
