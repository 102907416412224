<ng-container *transloco="let t">
    <div *ngIf="dataSource.initialLoading$ | async" class="w-100 mt-3 d-flex justify-content-center">
        <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>

    <div
        *ngIf="enableEmptyMessage && (dataSource.empty$ | async)"
        class="empty-message p-3 w-100 text-center"
        [ngClass]="{ 'smooth-shadow': noShadow !== true }"
    >
        {{ t('offer-table.no-offers') }}
    </div>

    <div [hidden]="dataSource.initialLoading$ | async">
        <td-error *ngIf="dataSource.error$ | async; let error" [message]="error.message" [title]="t('general.error')"></td-error>

        <div
            class="table-container d-flex"
            [ngClass]="{ 'smooth-shadow': noShadow !== true }"
            [hidden]="(dataSource.empty$ | async) || (dataSource.initialLoading$ | async)"
        >
            <mat-table class="table" [dataSource]="dataSource">
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>{{ t('fields.name') }}</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <div class="text-truncate w-100">
                            <td-domain [domain]="row.domain?.name"></td-domain>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="expiration">
                    <mat-header-cell class="flex-150" *matHeaderCellDef>{{ t('fields.listing-expires') }}</mat-header-cell>
                    <mat-cell class="flex-150" *matCellDef="let row">
                        <td-humanized-date *ngIf="row.expiration" [value]="row.expiration"></td-humanized-date>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="price">
                    <mat-header-cell class="flex-120" *matHeaderCellDef>{{ t('fields.price') }}</mat-header-cell>
                    <mat-cell class="flex-120" *matCellDef="let row">
                        <td-tez [value]="row.price"></td-tez>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="status">
                    <mat-header-cell class="flex-120" *matHeaderCellDef>{{ t('fields.status') }}</mat-header-cell>
                    <mat-cell class="flex-120" *matCellDef="let row">
                        <td-offer-state-badge [offer]="row"></td-offer-state-badge>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <mat-header-cell class="flex-230" *matHeaderCellDef></mat-header-cell>
                    <mat-cell class="flex-230 d-flex justify-content-end align-items-center" *matCellDef="let row">
                        <button
                            mat-flat-button
                            color="primary"
                            (click)="edit(row)"
                            class="me-2"
                            *ngIf="[states.Active, states.DomainOperatorsContractMissing].includes(row.state)"
                        >
                            {{ t('actions.edit') }}
                        </button>
                        <button
                            mat-flat-button
                            color="primary"
                            (click)="cancel(row)"
                            *ngIf="
                                [
                                    states.Active,
                                    states.DomainOperatorsContractMissing,
                                    states.DomainExpired,
                                    states.DomainDoesNotExist,
                                    states.DomainOperatorsContractMissing,
                                    states.OfferSellerDomainOwnerMismatch,
                                    states.DomainIsExpiringSoon
                                ].includes(row.state)
                            "
                        >
                            {{ t('actions.cancel') }}
                        </button>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns"></mat-row>
            </mat-table>
        </div>

        <div class="mt-3 ms-3" *ngIf="(dataSource.empty$ | async) === false">
            <a [routerLink]="['/address', address, 'listings']">{{ t('general.see-more') }}</a>
        </div>
    </div>
</ng-container>
