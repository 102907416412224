<div *transloco="let t" class="d-flex">
    <td-badge *ngIf="!statesDict.has(offer.state) && offer.state === states.Executed" color="success">{{ t('offer-table.status.sold') }}</td-badge>
    <td-badge *ngIf="!statesDict.has(offer.state) && offer.state === states.OfferExpired" color="danger">
        {{ t('offer-table.status.expired') }}
    </td-badge>
    <td-badge *ngIf="!statesDict.has(offer.state) && offer.state === states.Active" color="primary">
        {{ t('offer-table.status.active') }}
    </td-badge>
    <!-- t(offer-table.invalid-domain-expired-tooltip) -->
    <!-- t(offer-table.invalid-domain-expiring-soon-tooltip) -->
    <!-- t(offer-table.invalid-domain-does-not-exist) -->
    <!-- t(offer-table.invalid-operator-missing) -->
    <!-- t(offer-table.invalid-seller) -->
    <ng-container
        *ngIf="
            !statesDict.has(offer.state) &&
            [
                states.DomainExpired,
                states.DomainDoesNotExist,
                states.DomainOperatorsContractMissing,
                states.OfferSellerDomainOwnerMismatch,
                states.DomainIsExpiringSoon
            ].includes(offer.state)
        "
    >
        <td-badge color="danger">
            {{ t('offer-table.status.invalid') }}
        </td-badge>
        <fa-icon icon="question-circle" class="accent help ms-1" matTooltipPosition="above" [matTooltip]="t(invalidTooltipKeys[offer.state])"></fa-icon>
    </ng-container>
</div>
