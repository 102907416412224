<ng-container *transloco="let t">
    <div class="mb-3 me-2 d-flex" [style]="{ overflow: 'hidden' }">
        <td-horizontal-scroll class="flex-grow-1">
            <div class="d-flex gap-2">
                <td-date-filter [min]="minDate" [max]="maxDate" [initialFilter]="initialDateFilter" (dateRangeChange)="dateChanged($event)"></td-date-filter>
                <td-list-filter [options]="eventTypeOptions" [selectedOptions]="initialEventTypes" (selectedChange)="typesChanged($event)"></td-list-filter>
            </div>
        </td-horizontal-scroll>
    </div>
</ng-container>
