import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { translocoConfig, TranslocoService, TRANSLOCO_CONFIG, TRANSLOCO_LOADER } from '@ngneat/transloco';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import { TranslocoPersistLangModule, TRANSLOCO_PERSIST_LANG_STORAGE } from '@ngneat/transloco-persist-lang';
import { parseCookieString } from 'ngx-cookie';
import { environment } from '../../environments/environment';
import { TrackingService } from '../browser/tracking.service';
import { DayjsDatePipe } from './dayjs-date.pipe';
import { HumanizeDurationPipe } from './humanize-duration.pipe';
import { HumanizedDateComponent } from './humanized-date.component';
import { ImportLoader } from './import-loader';
import { LocaleService } from './locale.service';

export const languages = [
    { id: 'en', label: 'English', locale: 'en' },
    { id: 'zh', label: '中文', locale: 'zh' },
    { id: 'pt', label: 'Português', locale: 'pt-br' },
    { id: 'de', label: 'Deutsch', locale: 'de' },
    { id: 'es', label: 'Español', locale: 'es' },
    { id: 'it', label: 'Italiano', locale: 'it' },
    { id: 'ru', label: 'русский', locale: 'ru' },
    { id: 'ja', label: '日本語', locale: 'ja' },
    { id: 'fr', label: 'Français', locale: 'fr' },
    { id: 'tr', label: 'Türkçe', locale: 'tr' },
    { id: 'id', label: 'Indonesia', locale: 'id' },
    { id: 'ko', label: '한국어', locale: 'ko' },
];

export function bootstrapLocale(transloco: TranslocoService, localeService: LocaleService, trackingService: TrackingService) {
    return () => {
        const lang = transloco.getActiveLang();
        const locale = languages.find(l => l.id == lang)!.locale;
        localeService.setup();
        trackingService.properties({ app_lang: lang });
        return Promise.all([transloco.load(lang).toPromise(), localeService.loadLocale(locale)]);
    };
}

export const localeInitializer = {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: bootstrapLocale,
    deps: [TranslocoService, LocaleService, TrackingService],
};

export function isSupported(lang: string) {
    return languages.some(l => l.id === lang);
}

function getCookie(name: string) {
    return parseCookieString(document.cookie)?.[name];
}

export function getLang(langs: { cachedLang: string; browserLang: string; cultureLang: string; defaultLang: string }) {
    const webSelectedLanguage = getCookie('user_preferred_language');

    if (isSupported(webSelectedLanguage)) {
        return webSelectedLanguage;
    }

    if (isSupported(langs.cachedLang)) {
        return langs.cachedLang;
    }

    if (isSupported(langs.browserLang)) {
        return langs.browserLang;
    }

    return langs.defaultLang;
}

@NgModule({
    imports: [
        TranslocoPersistLangModule.forRoot({
            getLangFn: getLang,
            storageKey: 'lang',
            storage: {
                provide: TRANSLOCO_PERSIST_LANG_STORAGE,
                useValue: localStorage,
            },
        }),
        TranslocoMessageFormatModule.forRoot(),
    ],
    providers: [
        {
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs: languages,
                defaultLang: 'en',
                reRenderOnLangChange: true,
                prodMode: environment.production,
                flatten: {
                    aot: environment.production,
                },
            }),
        },
        { provide: TRANSLOCO_LOADER, useClass: ImportLoader },
    ],
})
export class TranslocoRootModule {}

@NgModule({
    imports: [CommonModule],
    declarations: [HumanizedDateComponent, DayjsDatePipe, HumanizeDurationPipe],
    exports: [HumanizedDateComponent, DayjsDatePipe, HumanizeDurationPipe],
    providers: [DayjsDatePipe],
})
export class LocaleModule {}
