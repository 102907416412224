<td-address-page scope="domains" #page *transloco="let t" (addressChange)="addressChange($event)">
    <h1 my-title>{{ titles[crtSelectedCategory] }}</h1>
    <span other-subtitle>{{ t('domain-list.title-owned-by') }}</span>
    <div class="mb-3 d-flex gap-3 justify-content-end align-items-center" *ngIf="!!selectedDomains.length">
        <button mat-stroked-button (click)="startBulkTransfer()" [disabled]="!selectedDomains.length || !page.wallet?.address">
            {{ t('domain-list.bulk-transfer', { selected: selectedDomains.length, total: totalDomains }) }}
        </button>
        <button mat-flat-button color="primary" (click)="startBulkRenew()" [disabled]="!selectedDomains.length || !page.wallet?.address">
            {{ t('domain-list.bulk-renewal', { selected: selectedDomains.length, total: totalDomains }) }}
        </button>
    </div>

    <td-domain-table
        #domainTable
        *ngIf="page.validAddress"
        [baseFilter]="{ or: [{ owner: { equalTo: page.address } }, { address: { equalTo: page.address } }, { operators: { include: page.address } }] }"
        [baseLevel]="2"
        [address]="page.address"
        [columns]="page.address === page.wallet?.address ? ['name', 'expires', 'actions'] : ['name', 'expires']"
        [allowedCategories]="['all', 'direct', 'expiring']"
        [connected]="!!page.wallet?.address"
        [selectedCategory]="initialSelectedCategory"
        [selectAllOnLoad]="initialSelectedCategory === 'expiring'"
        [pageSize]="initialSelectedCategory === 'expiring' ? 50 : 30"
        (domainsSelected)="domainsSelected($event)"
        (selectedCategoryChanged)="selectedCategoryChanged($event)"
        [hideFilters]="!!selectedDomains.length"
        [sortingPerCategory]="sortingPerCategory"
    ></td-domain-table>
</td-address-page>
