import { ErrorHandler, Injectable } from '@angular/core';
import { TrackingService } from './browser/tracking.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private trackingService: TrackingService) {}

    handleError(error: Error) {
        this.trackingService.exception(`UNHANDLED ERROR ${error?.message}`);

        throw error;
    }
}
