import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { PageService } from '../browser/page.service';
import { ReverseRecord, ReverseRecordService } from '../domains/reverse-record.service';
import { DataSourceFactory } from '../graphql/data-source-factory';
import { ReverseRecordDetailDataSource } from '../graphql/reverse-record-data-source';
import { TezosWallet } from '../tezos/models';
import { TezosService } from '../tezos/tezos.service';
import { isTezosAddress } from '../utils/validate';

@Component({
    selector: 'td-address-page',
    templateUrl: './address-page.component.html',
    styleUrls: ['./address-page.component.scss'],
})
export class AddressPageComponent implements OnInit, OnDestroy {
    @Input() scope: string;
    @Input() disableBottomTitlePadding: boolean;

    @Output() addressChange = new EventEmitter<string>();

    address: string;
    wallet: TezosWallet | null;
    validAddress: boolean;
    reverseRecord: ReverseRecord | null;
    reverseRecordDataSource: ReverseRecordDetailDataSource;
    currentUserReverseRecord: ReverseRecord | null;

    private unsubscribe = new Subject<void>();
    initialized = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private tezosService: TezosService,
        private pageService: PageService,
        private dataSourceFactory: DataSourceFactory,
        private reverseRecordService: ReverseRecordService
    ) {}

    ngOnInit(): void {
        this.reverseRecordDataSource = this.dataSourceFactory.createReverseRecordDetailDataSource();

        this.reverseRecordService.current.pipe(takeUntil(this.unsubscribe)).subscribe(r => (this.currentUserReverseRecord = r));
        this.reverseRecordDataSource.data$
            .pipe(
                takeUntil(this.unsubscribe),
                map(data => data?.reverseRecord),
                filter(r => !!r)
            )
            .subscribe(r => (this.reverseRecord = r || null));
        this.tezosService.activeWallet.pipe(takeUntil(this.unsubscribe)).subscribe(w => {
            this.initialized = true;
            this.wallet = w;
            this.updateTitle();
        });
        this.activatedRoute.params.pipe(takeUntil(this.unsubscribe)).subscribe(p => this.setAddress(p['address']));
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private setAddress(address: string) {
        this.address = address;
        this.reverseRecord = null;
        this.updateTitle();
        this.validAddress = isTezosAddress(address);

        if (this.validAddress) {
            this.addressChange.next(address);
        }

        if (this.validAddress && this.address !== this.wallet?.address) {
            this.reverseRecordDataSource.load({ address: this.address });
        }
    }

    private updateTitle() {
        this.pageService.setTitle(this.wallet?.address === this.address ? `my-${this.scope}` : `address-${this.scope}`, {
            address: this.address,
        });
    }
}
