import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AdvancedSelectOption } from '../shared/advanced-select.component';
import { TezosNetworkService } from '../tezos/tezos-network.service';
import { TezosNetwork } from '../tezos/models';
import { TdValidators } from '../utils/form-validators';

@Component({
    selector: 'td-rpc-url-editor',
    templateUrl: './rpc-url-editor.component.html',
})
export class RpcUrlEditorComponent implements OnInit, OnDestroy {
    rpcUrl: string;
    currentCustomRpc: string | null;
    network: TezosNetwork;
    builtInRpcUrls: AdvancedSelectOption[];
    validators = [TdValidators.url(true)];

    private unsubscribe = new Subject<void>();

    constructor(private tezosNetworkService: TezosNetworkService) {}

    ngOnInit(): void {
        this.tezosNetworkService.activeNetwork.pipe(takeUntil(this.unsubscribe)).subscribe(n => {
            this.network = n;
            this.currentCustomRpc = this.tezosNetworkService.customRpcUrl;
            this.rpcUrl = this.currentCustomRpc || n.rpcUrl;
            this.builtInRpcUrls = n.rpcUrls.map(r => ({ label: r.name, value: r.url }));
        });
    }

    setRpcUrl() {
        this.tezosNetworkService.setCustomRpcUrl(this.rpcUrl);
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
