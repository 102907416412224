<div
    class="money-input-container d-flex flex-column"
    (click)="input?.nativeElement?.focus()"
    [ngClass]="{ focused: hasFocus, invalid: control.touched && control.invalid, 'display-only': displayOnly }"
>
    <div class="input-label txt-secondary w-100">{{ label }}</div>
    <div class="input-container w-100 d-flex align-items-center">
        <ng-container *ngIf="!displayOnly">
            <input
                #input
                autocapitalize="off"
                autocomplete="off"
                autocorrect="off"
                spellcheck="false"
                type="number"
                class="flex-grow-1"
                [formControl]="$any(control)"
                [placeholder]="0"
                (focus)="hasFocus = true"
                (blur)="hasFocus = false"
            />

            <fa-icon [icon]="[$any('fac'), $any('tez')]"></fa-icon>
            <td-tez [mutez]="false" [value]="control.value" showUsd="only"></td-tez>
        </ng-container>
        <td-tez *ngIf="displayOnly" class="w-100" [mutez]="false" [value]="control.value" [showUsd]="true"></td-tez>
    </div>
    <mat-error class="pt-1 ps-1" *ngIf="control.touched">
        <td-validation-messages [control]="control" [context]="{ name: label }"> </td-validation-messages>
    </mat-error>
</div>
