import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'td-reject-warning',
    templateUrl: './reject-warning.component.html',
    styleUrls: ['./reject-warning.component.scss'],
})
export class RejectWarningComponent {
    constructor(private dialogRef: MatDialogRef<RejectWarningComponent>) {}

    ok() {
        this.dialogRef.close(true);
    }

    cancel() {
        this.dialogRef.close(false);
    }
}
