import { AbstractControl } from '@angular/forms';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import uniq from 'lodash-es/uniq';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, switchMap, first, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'td-validation-messages',
    templateUrl: './validation-messages.component.html',
    styleUrls: ['./validation-messages.component.scss'],
})
export class ValidationMessagesComponent implements OnInit, OnDestroy {
    @Input() control: AbstractControl;
    @Input() context: { [key: string]: string };

    messages: string[];

    private unsubscribe = new Subject<void>();

    constructor(private translation: TranslocoService) {}

    ngOnInit(): void {
        this.control.statusChanges
            .pipe(
                takeUntil(this.unsubscribe),
                switchMap(() => this.generateMessages())
            )
            .subscribe(m => (this.messages = m));

        this.generateMessages()
            .pipe(first())
            .subscribe(m => (this.messages = m));
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private generateMessages() {
        if (this.control.errors) {
            return combineLatest(Object.keys(this.control.errors).map(e => this.getMessage(e))).pipe(map(messages => uniq(messages)));
        } else {
            return of([]);
        }
    }

    private getMessage(error: string): Observable<string> {
        const errorData = this.control.getError(error);

        const context = Object.assign({ name: 'Field', value: this.control.value }, typeof errorData === 'object' ? errorData : {}, this.context || {});

        /**
         * t(validation.required)
         * t(validation.label.tez-INVALID_NAME)
         * t(validation.label.tez-UNSUPPORTED_CHARACTERS)
         * t(validation.label.tez-TOO_SHORT)
         * t(validation.label.tez-TOO_LONG)
         * t(validation.label.flo-INVALID_NAME)
         * t(validation.label.flo-UNSUPPORTED_CHARACTERS)
         * t(validation.label.flo-TOO_SHORT)
         * t(validation.label.flo-TOO_LONG)
         * t(validation.label.a1-INVALID_NAME)
         * t(validation.label.a1-UNSUPPORTED_CHARACTERS)
         * t(validation.label.a1-TOO_SHORT)
         * t(validation.label.a1-TOO_LONG)
         * t(validation.label.a2-INVALID_NAME)
         * t(validation.label.a2-UNSUPPORTED_CHARACTERS)
         * t(validation.label.a2-TOO_SHORT)
         * t(validation.label.a2-TOO_LONG)
         * t(validation.label.a3-INVALID_NAME)
         * t(validation.label.a3-UNSUPPORTED_CHARACTERS)
         * t(validation.label.a3-TOO_SHORT)
         * t(validation.label.a3-TOO_LONG)
         * t(validation.domainName)
         * t(validation.min)
         * t(validation.max)
         * t(validation.required)
         * t(validation.tezosAddress.invalid)
         * t(validation.tezosAddress.entrypoint)
         * t(validation.except)
         * t(validation.url)
         * t(validation.contentUrl)
         * t(validation.json)
         * t(validation.md5)
         * t(validation.derivationPath)
         * t(validation.duplicateSubdomain)
         * t(validation.asyncError)
         * t(validation.validRecipient.no-address)
         * t(validation.validRecipient.invalid-input)
         * t(validation.validRecipient.kt-not-allowed)
         * t(validation.domainWithAddress.invalid-input)
         * t(validation.domainWithAddress.address-mismatch)
         * t(validation.bytesString)
         * t(validation.email)
         * t(validation.number)
         * t(validation.twitterHandle)
         */
        return this.translation.selectTranslate(`validation.${error}${context.type ? `.${context.type}` : ''}`, context);
    }
}
