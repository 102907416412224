import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, AbstractControl, Validators } from '@angular/forms';
import { TaquitoTezosDomainsClient } from '@tezos-domains/taquito-client';
import { TransactionWalletOperation } from '@taquito/taquito';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, Observable } from 'rxjs';
import { takeUntil, distinctUntilChanged, debounceTime, startWith } from 'rxjs/operators';

import { SmartContractOperationEvent, TezosService } from '../tezos/tezos.service';
import { TezosWallet } from '../tezos/models';
import { ReverseRecordDetailQuery } from '../graphql/graphql.generated';
import { TdValidators, TdAsyncValidatorsFactory } from '../utils/form-validators';
import { emptyStringToNull } from '../utils/convert';
import { OperationStatusDoneEvent } from '../shared/operation-status.component';
import { DataSourceFactory } from '../graphql/data-source-factory';
import { DomainTableDataSource } from '../graphql/domain-table-data-source';

@Component({
    selector: 'td-reverse-record-form',
    templateUrl: './reverse-record-form.component.html',
    styleUrls: ['./reverse-record-form.component.scss'],
})
export class ReverseRecordFormComponent implements OnInit, OnDestroy {
    form: UntypedFormGroup;
    operation: Observable<SmartContractOperationEvent> | null;
    wallet: TezosWallet;
    applicableDomainsDataSource: DomainTableDataSource;
    overriddenName?: string;

    reverseRecord: NonNullable<Partial<ReverseRecordDetailQuery['reverseRecord']>>;

    get transferOwnerRequested() {
        const owner = this.form.get('owner')!;

        return this.reverseRecord.owner && this.reverseRecord.address !== this.wallet.address && !owner.invalid && owner.value !== this.reverseRecord.owner;
    }

    get transferOwnerConfirmed() {
        const owner = this.form.get('owner')!;

        return owner.value === this.approvedTransferTarget;
    }

    private approvedTransferTarget: string | null = null;
    private unsubscribe = new Subject<void>();

    constructor(
        private formBuilder: UntypedFormBuilder,
        private tezosService: TezosService,
        @Inject(MAT_DIALOG_DATA) private data: { reverseRecord: NonNullable<Partial<ReverseRecordDetailQuery['reverseRecord']>>; name?: string },
        private dialogRef: MatDialogRef<ReverseRecordFormComponent>,
        private asycnValidatorFactory: TdAsyncValidatorsFactory,
        private dataSourceFactory: DataSourceFactory
    ) {}

    ngOnInit(): void {
        this.applicableDomainsDataSource = this.dataSourceFactory.createDomainsDataSource();
        this.reverseRecord = this.data.reverseRecord;
        this.overriddenName = this.data.name;
        this.tezosService.activeWallet.pipe(takeUntil(this.unsubscribe)).subscribe(w => (this.wallet = w!));

        const controls: { [name: string]: AbstractControl } = {
            name: this.formBuilder.control(
                this.overriddenName || this.reverseRecord.domain?.name,
                [],
                [this.asycnValidatorFactory.domainWithAddress(this.reverseRecord.address!)]
            ),
            owner: this.formBuilder.control(this.reverseRecord.owner || this.wallet.address, [Validators.required, TdValidators.tezosAddress(false)]),
        };

        this.form = this.formBuilder.group(controls);

        this.form
            .get('owner')!
            .valueChanges.pipe(takeUntil(this.unsubscribe), distinctUntilChanged())
            .subscribe(() => (this.approvedTransferTarget = null));

        this.form
            .get('name')!
            .valueChanges.pipe(takeUntil(this.unsubscribe), distinctUntilChanged(), debounceTime(300), startWith(this.reverseRecord.domain?.name))
            .subscribe(filter =>
                this.applicableDomainsDataSource.load({
                    where: { name: { like: filter }, address: { equalTo: this.reverseRecord.address } },
                    first: 50,
                })
            );
    }

    create() {
        this.execute(client =>
            client.manager.claimReverseRecord({
                ...this.form.value,
                name: emptyStringToNull(this.form.value.name),
            })
        );
    }

    save() {
        if (this.reverseRecord.owner === this.wallet.address) {
            this.execute(client =>
                client.manager.updateReverseRecord({
                    ...this.form.value,
                    name: emptyStringToNull(this.form.value.name),
                    address: this.reverseRecord.address,
                })
            );
        } else {
            this.create();
        }
    }

    operationDone(event: OperationStatusDoneEvent) {
        if (event.success) {
            this.dialogRef.close(true);
        } else {
            this.form.enable();
        }
    }

    cancel() {
        this.dialogRef.close(false);
    }

    confirmTransfer(phrase: string) {
        if (phrase === this.form.get('owner')!.value) {
            this.approvedTransferTarget = phrase;
        }
    }

    clearName() {
        this.form.get('name')!.setValue(null);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.applicableDomainsDataSource.disconnect();
    }

    private execute(op: (client: TaquitoTezosDomainsClient) => Promise<TransactionWalletOperation>) {
        this.form.disable();

        const operation = this.tezosService.execute(op);

        this.operation = operation;
    }
}
