<form class="d-flex flex-column flex-fill h-100" [formGroup]="form" *transloco="let t">
    <div class="d-flex flex-column">
        <h1 mat-dialog-title class="text-truncate" *ngIf="!domain.owner">{{ t('domain-form.title-add-subdomain', { parent: parent }) }}</h1>
        <h1 mat-dialog-title class="text-truncate" *ngIf="domain.owner">{{ t('domain-form.title-edit', { name: domain.name }) }}</h1>
        <td-operation-status [operation]="operation" (done)="operationDone($event)" #op></td-operation-status>
    </div>
    <mat-dialog-content class="d-flex flex-column flex-grow-1 gap-2">
        <mat-form-field appearance="standard" *ngIf="!domain.owner">
            <mat-label>{{ t('fields.label') }}</mat-label>
            <input formControlName="label" [placeholder]="t('fields.label-placeholder')" matInput required maxlength="100" />
            <span matSuffix>.{{ parent | truncate: 30 }}</span>
            <mat-error><td-validation-messages [control]="form.get('label')!" [context]="{ name: t('fields.label') }"></td-validation-messages></mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard">
            <mat-label>{{ t('fields.domain-owner') }}</mat-label>
            <input formControlName="owner" [placeholder]="t('fields.owner-placeholder')" matInput required />
            <mat-error>
                <td-validation-messages [control]="form.get('owner')!" [context]="{ name: t('fields.domain-owner') }"></td-validation-messages>
            </mat-error>
        </mat-form-field>
        <td-address-editor
            [control]="form.get('address')!"
            [label]="t('fields.domain-address')"
            [placeholder]="t('fields.address-placeholder')"
        ></td-address-editor>
        <div class="d-flex flex-column gap-2">
            <td-alert
                state="warning"
                *ngIf="reverseRecord?.domain?.name === domain.name && !form.get('address')!.invalid && form.get('address')!.value !== domain.address"
            >
                {{ t('domain-form.reverse-record-loss-warning') }}
            </td-alert>
            <div [@.disabled]="disableAnimation">
                <mat-expansion-panel class="mb-2 mat-elevation-z0 flat-expansion-panel">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ t('fields.data') }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <td-data-editor [form]="$any(form.get('data'))" [data]="domain.data || []"></td-data-editor>
                </mat-expansion-panel>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex justify-content-end align-items-end w-100 min-height-auto">
        <button mat-flat-button color="primary" *ngIf="!domain.owner" (click)="create()" [disabled]="form.invalid || op.inProgress">
            {{ t('actions.add') }}
        </button>
        <button mat-flat-button color="primary" *ngIf="domain.owner" (click)="save()" [disabled]="form.invalid || op.inProgress">
            {{ t('actions.save') }}
        </button>
        <button mat-button (click)="cancel()" [disabled]="op.inProgress">{{ t('actions.cancel') }}</button>
    </mat-dialog-actions>
</form>
