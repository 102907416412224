import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isArray, mergeWith } from 'lodash-es';
import { HistoryStateService } from './history-state.service';

@Injectable({
    providedIn: 'root',
})
export class FilterHistoryStateService extends HistoryStateService {
    constructor(router: Router) {
        super(router);
    }

    merge<T = any>(state: T) {
        mergeWith(this.get(), state, this.customizer);
    }

    private customizer(value: any, srcValue: any) {
        if (isArray(value)) {
            // we want to avoid merging arrays otherwise for multiple choice filters we never remove unselected values
            return srcValue;
        }

        // lodash will use the default merge behavior - leaving this return here for the sake of clarity
        return undefined;
    }
}
