import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

import { DataSourceFactory } from '../graphql/data-source-factory';
import { OfferOrderField, OfferState, OrderDirection } from '../graphql/graphql.generated';
import { OfferRecord, OfferTableDataSource } from '../graphql/offer-table-data-source';
import { OverlayService } from '../overlay.service';

@Component({
    selector: 'td-hot-offers-summary-table',
    templateUrl: './hot-offers-summary-table.component.html',
    styleUrls: ['./hot-offers-summary-table.component.scss'],
})
export class HotOffersSummaryTableComponent implements OnInit, OnChanges, OnDestroy {
    @Input() noShadow: boolean;

    dataSource: OfferTableDataSource;

    private emptyStream = new ReplaySubject<boolean>(1);
    private unsubscribe = new Subject<void>();

    get empty$() {
        return this.emptyStream;
    }

    constructor(private dataSourceFactory: DataSourceFactory, private overlayService: OverlayService) {}

    ngOnInit() {
        this.dataSource = this.dataSourceFactory.createOfferTableDataSource();
        this.dataSource.empty$.pipe(takeUntil(this.unsubscribe), delay(0)).subscribe(this.emptyStream);
        this.load();
    }

    ngOnChanges() {
        if (this.dataSource) {
            this.load();
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    executeOffer(offer: OfferRecord) {
        this.overlayService.openExecuteOffer(offer, () => this.load());
    }

    favs = new Set();

    selected(offer: any) {
        if (this.favs.has(offer)) {
            this.favs.delete(offer);
        } else {
            this.favs.add(offer);
        }
    }

    private load() {
        this.dataSource.load(
            {
                where: {
                    state: { in: [OfferState.Active] },
                },
                order: { field: OfferOrderField.CreatedAt, direction: OrderDirection.Desc },
                first: 5,
            },
            {
                pollInterval: 10 * 60 * 1000,
            }
        );
    }
}
