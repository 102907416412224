import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

import { LocaleService } from './locale.service';

@Pipe({
    name: 'dayjsDate',
})
export class DayjsDatePipe implements PipeTransform {
    constructor(private localeService: LocaleService) {}

    transform(value: dayjs.Dayjs | Date | undefined | null, format: 'short' | 'long' | 'medium' = 'long'): string {
        if (!value) {
            return '';
        }

        if (value instanceof Date) {
            value = dayjs(value);
        }

        return value.locale(this.localeService.getLocale()).format(format === 'short' ? 'L' : format === 'medium' ? 'LL' : 'LLL');
    }
}
