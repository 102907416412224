import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomainAcquisitionInfo, DomainAcquisitionState } from '@tezos-domains/manager';
import dayjs from 'dayjs';
import { interval, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DomainDetailQuery } from '../graphql/graphql.generated';
import { OverlayService } from '../overlay.service';
import { TezosWallet } from '../tezos/models';
import { TezosWalletService } from '../tezos/tezos-wallet.service';
import { TezosService } from '../tezos/tezos.service';
import { BidComponent } from './bid.component';
import { SettleComponent } from './settle.component';

@Component({
    selector: 'td-auction',
    templateUrl: './auction.component.html',
    styleUrls: ['./auction.component.scss'],
})
export class AuctionComponent implements OnInit, OnDestroy {
    @Input() acquisitionInfo: DomainAcquisitionInfo;
    @Input() name: string;
    @Input() auction: DomainDetailQuery['auction'];
    @Output() settled = new EventEmitter<void>();
    @Output() bidPlaced = new EventEmitter<void>();
    @Output() check = new EventEmitter<void>();

    wallet: TezosWallet | null;
    duration: plugin.Duration;
    bidderBalance: ReplaySubject<number> = new ReplaySubject(1);
    states = DomainAcquisitionState;
    get now() {
        return new Date();
    }

    get isBidder() {
        return this.auction?.bids.some(b => b.bidder === this.wallet?.address);
    }

    private unsubscribe = new Subject<void>();

    constructor(
        private tezosService: TezosService,
        private dialog: MatDialog,
        private tezosWalletService: TezosWalletService,
        private overlayService: OverlayService
    ) {}

    ngOnInit(): void {
        this.duration = dayjs.duration(this.acquisitionInfo.auctionDetails.registrationDuration, 'days');

        interval(2 * 60000)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.check.next();
            });

        this.tezosService.activeWallet.pipe(takeUntil(this.unsubscribe)).subscribe(w => (this.wallet = w!));
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    bid() {
        const dialog = this.dialog.open(BidComponent, {
            data: { name: this.name, acquisitionInfo: this.acquisitionInfo },
            height: '340px',
            panelClass: 'big-dialog-pane',
        });

        dialog.afterClosed().subscribe(result => {
            if (result) {
                this.tezosWalletService.refreshBidderBalance();
                this.bidPlaced.next();

                this.overlayService.openNotificationPopup('bid');
            }
        });
    }

    settle() {
        const dialog = this.dialog.open(SettleComponent, {
            data: { name: this.name, acquisitionInfo: this.acquisitionInfo },
            height: '500px',
            panelClass: 'big-dialog-pane',
        });

        dialog.afterClosed().subscribe(result => {
            if (result) {
                this.settled.next();
            }
        });
    }
}
