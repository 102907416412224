import { Component, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DomainNameValidationResult, RecordMetadata, StandardRecordMetadataKey, getLabel, getLevel, getParent, getTld } from '@tezos-domains/core';
import { DomainAcquisitionInfo, DomainAcquisitionState } from '@tezos-domains/manager';
import { TaquitoTezosDomainsClient } from '@tezos-domains/taquito-client';
import { CalendarEvent } from 'calendar-link';
import dayjs from 'dayjs';
import { combineLatest, from, throwError } from 'rxjs';
import { catchError, skip, takeUntil, tap } from 'rxjs/operators';
import { AppService } from '../app-service';
import { Logger } from '../browser/logger';
import { PageService } from '../browser/page.service';
import { WindowRef } from '../browser/window-ref';
import { Configuration } from '../configuration';
import { BuyOfferRecord } from '../graphql/buy-offer-table-data-source';
import { CurrentBuyOfferDataSource } from '../graphql/current-buy-offer-data-source';
import { DataSourceFactory } from '../graphql/data-source-factory';
import { DomainDetailDataSource } from '../graphql/domain-detail-data-source';
import { Domain, DomainDetailQuery, DomainsFilter, OfferState } from '../graphql/graphql.generated';
import { OverlayService } from '../overlay.service';
import { ReverseRecordFormComponent } from '../reverse-records/reverse-record-form.component';
import { MediaObserver } from '../shared/media-observer.service';
import { TezosDomainsClientService } from '../tezos/integration/tezos-domains-client.service';
import { TezosWallet } from '../tezos/models';
import { TezosService } from '../tezos/tezos.service';
import { isSecondLevel } from '../utils/check';
import { dataArrayToObj } from '../utils/convert';
import { DomainRecordFormComponent } from './domain-record-form.component';
import { DomainTableComponent } from './domain-table.component';
import { isExpiring } from './domain.helpers';
import { DomainService } from './domain.service';
import { EditWebsiteComponent } from './edit-website.component';
import { NotificationEventGeneratorService } from './notification-event-generator.service';
import { OperatorPermissionsComponent } from './operator-permissions/operator-permissions.component';
import { RenewComponent } from './renew.component';
import { ReverseRecord, ReverseRecordService } from './reverse-record.service';
import { TransferOwnershipComponent } from './transfer-ownership.component';
import { getExistingAuction } from './utils/aquisition-info';
import { isOperator } from './utils/is-operator';

@UntilDestroy()
@Component({
    selector: 'td-domain-detail',
    templateUrl: './domain-detail.component.html',
    styleUrls: ['./domain-detail.component.scss'],
})
export class DomainDetailComponent implements OnInit, OnDestroy {
    @ViewChild(DomainTableComponent) subdomainsTable: DomainTableComponent;

    private currentBuyOfferDataSource: CurrentBuyOfferDataSource;

    dataSource: DomainDetailDataSource;
    domain: ({ name: Domain['name'] } & Partial<DomainDetailQuery['domain']>) | null;
    buyOffer?: BuyOfferRecord | null;
    walletReverseRecord: ReverseRecord | null;
    auction: DomainDetailQuery['auction'];
    offer: DomainDetailQuery['offer'];
    wallet: TezosWallet | null;
    isSecondLevel: boolean;
    errorMessage: string | null;
    errorTitleKey = '';
    subdomainsFilter: DomainsFilter;
    subdomainLevel: number;
    name: string;
    acquisitionInfo: DomainAcquisitionInfo | null;
    states = DomainAcquisitionState;
    offerStates = OfferState;
    pricePerYear: number;
    nodeError: boolean;
    contentUrl: string | null;
    redirectUrl: string | null;
    decentralizedWebsiteUrl: string | null;
    subscriptionEvent: CalendarEvent;

    tryingToClaim = false;
    domainNameTooLongForWebsite = false;
    isClaimedDomain = false;
    tld = '';

    private tezosDomains: TaquitoTezosDomainsClient;
    private isOperator = false;

    get canEdit(): boolean {
        if (!this.wallet) {
            return false;
        }

        return !!(this.domain && (this.domain.owner === this.wallet.address || this.domain.parentOwner === this.wallet.address || this.isOperator));
    }

    get canClaim(): boolean {
        if (!this.acquisitionInfo) {
            return false;
        }

        return (
            (this.acquisitionInfo.acquisitionState === DomainAcquisitionState.CanBeClaimed && !this.isClaimedDomain) ||
            (this.acquisitionInfo.acquisitionState === DomainAcquisitionState.CanBeClaimed && this.tryingToClaim)
        );
    }

    get canOperate(): boolean {
        if (!this.wallet) {
            return false;
        }

        return !!(this.domain && (this.domain.owner === this.wallet.address || this.isOperator));
    }

    get canSubscribe(): boolean {
        if (!this.wallet) {
            return false;
        }

        return !!(this.domain && this.domain.owner === this.wallet.address);
    }

    get hasActiveWalletAddress(): boolean {
        if (!this.wallet || !this.domain) {
            return false;
        }

        return this.domain.address === this.wallet.address;
    }

    get canSetupReverseRecord(): boolean {
        if (!this.wallet || !this.domain) {
            return false;
        }

        return this.hasActiveWalletAddress && !this.isCurrentReverseRecord;
    }

    get canEditReverseRecord(): boolean {
        if (!this.wallet || !this.domain) {
            return false;
        }

        return (this.hasActiveWalletAddress && this.isCurrentReverseRecord) || this.domain?.reverseRecord?.owner === this.wallet.address;
    }

    get isCurrentReverseRecord(): boolean {
        return Boolean(this.wallet && this.domain?.reverseRecord?.address === this.wallet.address);
    }

    get reverseRecord(): ReverseRecord | null {
        const domainReverseRecord = this.domain?.reverseRecord;
        if (!domainReverseRecord) {
            return null;
        }

        return {
            ...domainReverseRecord,
            domain: this.domain as any,
        };
    }

    get location() {
        return this.windowRef.nativeWindow.location.href;
    }

    get parentName() {
        if (!this.domain) {
            return null;
        }

        return getParent(this.domain.name);
    }

    get isExpiringSoon() {
        return isExpiring(this.domain, { time: 7, units: 'days' });
    }

    get canChangeOperators(): boolean {
        return this.domain?.owner === this.wallet?.address && !!this.domain?.tokenId;
    }

    get canSellDomain(): boolean {
        return !this.offer && this.isSecondLevel && !!this.domain?.tokenId && this.domain?.owner === this.wallet?.address;
    }

    get showDetails(): boolean {
        return (
            !this.isSecondLevel ||
            this.acquisitionInfo?.acquisitionState === DomainAcquisitionState.Taken ||
            (this.acquisitionInfo?.acquisitionState === DomainAcquisitionState.CanBeClaimed && this.isClaimedDomain && !this.tryingToClaim)
        );
    }

    get claimedByOther(): boolean {
        return Boolean(
            this.acquisitionInfo?.acquisitionState === DomainAcquisitionState.CanBeClaimed && this.wallet && this.wallet.address !== this.domain?.owner
        );
    }

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private dataSourceFactory: DataSourceFactory,
        private tezosService: TezosService,
        private reverseRecordService: ReverseRecordService,
        private dialog: MatDialog,
        private translation: TranslocoService,
        private pageService: PageService,
        private domainService: DomainService,
        private tezosDomainsClientService: TezosDomainsClientService,
        private windowRef: WindowRef,
        private log: Logger,
        public configuration: Configuration,
        private overlayService: OverlayService,
        private appService: AppService,
        private notificationEventGenerator: NotificationEventGeneratorService,
        public media: MediaObserver
    ) {}

    ngOnInit(): void {
        this.dataSource = this.dataSourceFactory.createDomainDetailDataSource();
        this.currentBuyOfferDataSource = this.dataSourceFactory.createCurrentBuyOfferDataSource();
        this.currentBuyOfferDataSource.data$.pipe(untilDestroyed(this)).subscribe(buyOffer => (this.buyOffer = buyOffer.currentBuyOffer));

        this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(p => (this.tryingToClaim = p['tryClaim']));

        combineLatest([this.activatedRoute.params, this.tezosDomainsClientService.current, this.tezosService.activeWallet])
            .pipe(
                untilDestroyed(this),
                tap(([_, client, w]) => {
                    this.tezosDomains = client;
                    this.wallet = w;
                })
            )
            .subscribe(() => this.loadData());

        this.reverseRecordService.current.pipe(untilDestroyed(this)).subscribe(r => (this.walletReverseRecord = r));

        this.dataSource.data$.pipe(untilDestroyed(this)).subscribe(data => {
            if (!data) {
                this.domain = null;
                this.auction = null;
                this.acquisitionInfo = null;
                this.offer = null;
                this.isClaimedDomain = false;
                return;
            }

            if (!data.domain) {
                if (this.isSecondLevel) {
                    this.domain = { name: this.name };
                } else {
                    this.errorTitleKey = 'error.not-found-title';
                    this.translation
                        .selectTranslate('domain-detail.domain-not-found', { name: this.name })
                        .pipe(takeUntil(this.activatedRoute.params.pipe(skip(1))))
                        .subscribe(m => (this.errorMessage = m));
                }
            } else {
                if (data.domain.expires && dayjs().isAfter(data.domain.expires)) {
                    this.domain = { name: this.name };
                } else {
                    this.domain = data.domain;
                }
            }

            if (this.canSubscribe && this.domain?.expires) {
                this.subscriptionEvent = this.notificationEventGenerator.generate(this.domain);
            }

            this.auction = data.auction;
            this.offer = data.offer;
            this.isOperator = isOperator(data.domain, this.wallet?.address);
            this.nodeError = false;

            if (this.isSecondLevel) {
                this.domainNameTooLongForWebsite = getLabel(this.name).length > this.configuration.maxWebsiteDomainNameLength;

                if (this.domain!.data) {
                    const metadata = new RecordMetadata(dataArrayToObj(this.domain!.data));
                    this.contentUrl = metadata.getJson(StandardRecordMetadataKey.WEB_CONTENT_URL);
                    this.redirectUrl = metadata.getJson(StandardRecordMetadataKey.WEB_REDIRECT_URL);
                }

                from(this.tezosDomains.manager.getTldConfiguration(this.tld))
                    .pipe(
                        catchError(e => {
                            this.log.error('Error loading acquisition info', e);
                            this.nodeError = true;

                            return throwError(e);
                        })
                    )
                    .subscribe(tldConfiguration => {
                        this.acquisitionInfo = this.tezosDomainsClientService.calculateAcquisitionInfo({
                            name: this.name,
                            tldConfiguration,
                            existingAuction: getExistingAuction(this.auction ?? data.lastAuction.edges[0]?.node),
                            existingDomain: data.domain ? { expiry: data.domain.expires?.toDate() || null } : undefined,
                        });

                        this.isClaimedDomain = !!data.domain && this.acquisitionInfo.acquisitionState === DomainAcquisitionState.CanBeClaimed;

                        if ([DomainAcquisitionState.CanBeBought, DomainAcquisitionState.Taken].includes(this.acquisitionInfo.acquisitionState)) {
                            this.pricePerYear = this.acquisitionInfo.calculatePrice(365);
                        }
                    });
            }
        });

        this.dataSource.error$.pipe(untilDestroyed(this)).subscribe(error => {
            if (error) {
                this.errorTitleKey = 'general.error';
                this.errorMessage = error.message;
            } else {
                this.errorMessage = null;
            }
        });
    }

    ngOnDestroy() {
        this.dataSource.disconnect();
    }

    loadData() {
        this.name = this.activatedRoute.snapshot.params['name'].toLowerCase();
        this.tld = getTld(this.name);
        this.pageService.setTitle('domain', { name: this.name });
        this.isSecondLevel = isSecondLevel(this.name);
        this.errorMessage = null;
        this.domain = null;
        this.auction = null;
        this.acquisitionInfo = null;
        this.offer = null;

        if (this.isSecondLevel) {
            this.decentralizedWebsiteUrl = this.configuration.decentralizedWebsiteUrl
                .replace('{domain}', getLabel(this.name))
                .replace('{tld}', getTld(this.name));
        }

        const validation = this.tezosDomains.validator.isValidWithKnownTld(this.name);
        if (validation !== DomainNameValidationResult.VALID) {
            /** t(domain-detail.title-invalid-domain) */
            this.errorTitleKey = 'domain-detail.title-invalid-domain';
            this.domainService
                .getInvalidDomainMessage(this.name, validation)
                .pipe(takeUntil(this.activatedRoute.params.pipe(skip(1))))
                .subscribe(m => (this.errorMessage = m));
        } else {
            this.subdomainsFilter = {
                ancestors: { include: this.name },
            };
            this.subdomainLevel = getLevel(this.name) + 1;

            if (this.wallet) {
                this.currentBuyOfferDataSource.load({ name: this.name, address: this.wallet.address });
            }

            this.reload();
        }
    }

    reload() {
        this.dataSource.load({
            name: this.name,
        });
    }

    offerActedOn() {
        if (this.wallet) {
            this.currentBuyOfferDataSource.load({ name: this.name, address: this.wallet.address });
        }
    }

    domainClaimed() {
        if (this.tryingToClaim) {
            this.router.navigate([], { queryParams: { tryClaim: null }, queryParamsHandling: 'merge' });
        }

        this.reload();
    }

    addSubdomain() {
        this.openDialog(DomainRecordFormComponent, { data: { parent: this.domain!.name, domain: {} }, height: '600px' }, () => {
            this.reload();
            this.subdomainsTable.reload();
        });
    }

    editOffer() {
        this.overlayService.openPlaceOffer(this.offer!, () => this.reload());
    }

    cancelOffer() {
        this.overlayService.openRemoveOffer(this.offer!, () => this.reload());
    }

    edit() {
        this.openDialog(DomainRecordFormComponent, { data: { domain: this.domain, parent: getParent(this.domain!.name) }, height: '600px' }, () => {
            this.reload();
            this.reverseRecordService.refresh();
        });
    }

    transfer() {
        this.openDialog(TransferOwnershipComponent, { data: { domain: this.domain }, height: '500px' }, () => {
            this.reload();
        });
    }

    permissions() {
        if (!this.canChangeOperators) {
            return;
        }

        this.openDialog(OperatorPermissionsComponent, { data: { domain: this.domain } }, () => this.reload());
    }

    renew() {
        this.openDialog(RenewComponent, { data: { domain: this.domain, acquisitionInfo: this.acquisitionInfo } }, () => this.reload());
    }

    updateWeb() {
        this.openDialog(EditWebsiteComponent, { data: { domain: this.domain }, height: '400px' }, () => {
            this.reload();
        });
    }

    setReverseRecord() {
        this.openDialog(
            ReverseRecordFormComponent,
            {
                data: { reverseRecord: this.domain?.reverseRecord || this.walletReverseRecord || { address: this.domain?.address }, name: this.domain?.name },
                height: '600px',
            },
            () => {
                this.reload();
                this.reverseRecordService.refresh();
            }
        );
    }

    placeOffer() {
        this.overlayService.openPlaceOffer(this.offer || ({ domain: { name: this.domain?.name }, tokenId: this.domain?.tokenId } as any), () => {
            this.reload();

            this.overlayService.openNotificationPopup('place_offer');
        });
    }

    executeOffer() {
        if (this.wallet === null) {
            this.appService.openConnect(() => this.executeOffer());
            return;
        }

        if (this.wallet.address === this.offer?.seller) {
            return;
        }

        this.overlayService.openExecuteOffer(this.offer as any, () => this.reload());
    }

    domainIsExpiring(): boolean {
        return isExpiring(this.domain, this.configuration.expiringDomainThreshold);
    }

    claimDomain(): void {
        this.router.navigate([], { queryParams: { tryClaim: true }, skipLocationChange: true, queryParamsHandling: 'merge' });
    }

    private openDialog(component: Type<any>, config: MatDialogConfig, onSuccess: () => void) {
        config.panelClass = 'big-dialog-pane';
        const modal = this.dialog.open(component, config);

        modal.afterClosed().subscribe(result => {
            if (result) {
                onSuccess();
            }
        });
    }
}
