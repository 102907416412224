import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { OfferState } from '../graphql/graphql.generated';

@Component({
    selector: 'td-offer-state-badge',
    templateUrl: './offer-state-badge.component.html',
    styleUrls: ['./offer-state-badge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferStateBadgeComponent {
    statesDict: Set<OfferState> = new Set();

    @Input() offer: { state: OfferState };

    @Input() set excludedStates(states: OfferState[]) {
        this.statesDict = new Set(states);
    }

    states = OfferState;
    invalidTooltipKeys: Record<string, string> = {
        DOMAIN_DOES_NOT_EXIST: 'offer-table.invalid-domain-does-not-exist',
        DOMAIN_OPERATORS_CONTRACT_MISSING: 'offer-table.invalid-operator-missing',
        DOMAIN_EXPIRED: 'offer-table.invalid-domain-expired-tooltip',
        OFFER_SELLER_DOMAIN_OWNER_MISMATCH: 'offer-table.invalid-seller',
        DOMAIN_IS_EXPIRING_SOON: 'offer-table.invalid-domain-expiring-soon-tooltip',
    };
}
