import { Component, Input } from '@angular/core';
import { RpcResponseData, JsonBytesEncoder } from '@tezos-domains/core';
import isObject from 'lodash-es/isObject';
import { isAddress as isEthAddress } from 'web3-validator';

import { DataItem } from '../graphql/graphql.generated';

@Component({
    selector: 'td-data-viewer',
    templateUrl: './data-viewer.component.html',
    styleUrls: ['./data-viewer.component.scss'],
})
export class DataViewerComponent {
    @Input() data: Pick<DataItem, 'key' | 'rawValue'>[];

    decodeJson(value: string): { value: any; type: string } {
        let json = new RpcResponseData(value).scalar(JsonBytesEncoder);
        let type: string;

        if (!json) {
            type = 'binary';
        } else {
            switch (true) {
                case isObject(json):
                    type = 'json';
                    json = JSON.stringify(json, null, 2);
                    break;
                case isEthAddress(json):
                    type = 'etherlinkAddress';
                    break;
                default:
                    type = 'string';
                    break;
            }
        }

        return { value: json, type };
    }
}
