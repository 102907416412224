import BigNumber from 'bignumber.js';
import { PoolStats } from './models';

export type PoolData = Pick<PoolStats, 'poolSize' | 'sharesMinted'>;

export function convertTedToVotes(tedAmount: BigNumber | number, stats: PoolData): BigNumber {
    return BigNumber(tedAmount).multipliedBy(stats.sharesMinted).idiv(stats.poolSize);
}

export function convertVotesToTed(votesAmount: BigNumber, stats: PoolData): BigNumber {
    return BigNumber(votesAmount).multipliedBy(stats.poolSize).idiv(stats.sharesMinted);
}
