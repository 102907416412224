import { Component, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { StandardRecordMetadataKey } from '@tezos-domains/core';
import { keyBy } from 'lodash-es';
import { BehaviorSubject, Observable, combineLatest, merge, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, pairwise, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { RecipientComponent } from '../shared/recipient.component';
import { EmptyProfile, TzProfile, TzprofilesService } from '../tzprofiles/tzprofiles.service';
import { DelegatesFinderService } from './delegate-finder.service';
import { Delegate, DelegationStatus, ReverseRecord } from './models';

type DelegateVM = {
    rRecord: ReverseRecord;
    profile: TzProfile;
    votingPower: number;
};

@Component({
    selector: 'td-delegate-finder',
    templateUrl: './delegate-finder.component.html',
    styleUrls: ['./delegate-finder.component.scss'],
})
export class DelegateFinderComponent implements OnInit {
    private tzprofilesService = inject(TzprofilesService);
    private delegatesFinderService = inject(DelegatesFinderService);
    private formBuilder = inject(FormBuilder);

    private initialSelectedDelegate$ = new BehaviorSubject<DelegateVM | null>(null);

    @Input() showScroll = true;

    @Input() set existingDelegate(delegationStatus: DelegationStatus | null) {
        if (delegationStatus === null) {
            return;
        }

        combineLatest([this.tzprofilesService.getProfile(delegationStatus.address), this.delegatesFinderService.loadDelegate(delegationStatus.address)])
            .pipe(first())
            .subscribe(([profile, delegate]) => {
                this.selected = {
                    rRecord: delegationStatus.reverseRecord ?? { id: '', owner: delegationStatus.address, address: delegationStatus.address },
                    profile: profile ?? EmptyProfile,
                    votingPower: delegate?.voting_power ?? 0,
                };
                this.initialSelectedDelegate$.next(this.selected);
            });
    }
    @Output() delegateSelected = new EventEmitter<ReverseRecord | null>();
    @ViewChild(RecipientComponent) delegateeInput?: RecipientComponent;

    selected: DelegateVM | null = null;
    emptyProfile = EmptyProfile;

    form = this.formBuilder.group({
        delegateInput: this.formBuilder.control('', { nonNullable: true }),
    });

    readonly loading$ = new BehaviorSubject<boolean>(true);

    private searchInput$ = this.form.controls.delegateInput.valueChanges.pipe(
        debounceTime(250),
        distinctUntilChanged(),
        map(address => (address ? [{ address, voting_power: 0 } as Delegate] : [])),
        startWith([])
    );

    // loads top 200 delegates when no search input is provided
    private delegates$ = this.searchInput$.pipe(
        filter(delegates => delegates.length === 0),
        switchMap(() => this.delegatesFinderService.loadTopPublicDelegates())
    );

    vm$: Observable<DelegateVM[]>;

    ngOnInit() {
        this.vm$ = merge(this.delegates$, this.searchInput$).pipe(
            tap(() => this.loading$.next(true)),
            pairwise(),
            map(([oldDelegates, newDelegates]) => (newDelegates.length ? newDelegates : oldDelegates)),
            switchMap(delegatesPower => {
                const delegateAddresses = delegatesPower.map(d => d.address);
                const reverseRecordQuery = this.delegatesFinderService.loadReverseRecords(delegateAddresses);
                const tzProfilesQuery = this.tzprofilesService.getProfiles(delegateAddresses);

                return combineLatest([reverseRecordQuery, tzProfilesQuery, of(delegatesPower), this.initialSelectedDelegate$]);
            }),
            tap(() => {
                if (this.selected) {
                    this.selected = this.initialSelectedDelegate$.value;
                    this.delegateSelected.next(this.selected?.rRecord ?? null);
                }
            }),
            map(([reverseRecords, profiles, delegatesPower, initialDelegate]) => {
                let list = this.buildDelegatesList(reverseRecords, profiles, delegatesPower);
                list = this.filterDelegatesWithProfile(list);
                return this.prependSelectedDelegate(list, initialDelegate);
            }),
            tap(() => this.loading$.next(false)),
            shareReplay({ bufferSize: 1, refCount: true })
        );
    }
    private filterDelegatesWithProfile(list: DelegateVM[]): DelegateVM[] {
        const searchedDelegate = this.form.controls.delegateInput.value;

        return list.filter(
            d => d.rRecord.address === searchedDelegate || d.rRecord.domain?.data.some(d => d.key === StandardRecordMetadataKey.GOVERNANCE_PROFILE_URL)
        );
    }

    private prependSelectedDelegate(list: DelegateVM[], initialDelegate: DelegateVM | null): DelegateVM[] {
        if (!initialDelegate) {
            return list;
        }

        list = list.filter(d => d.rRecord.address != initialDelegate.rRecord.address);
        list.unshift(initialDelegate);

        return list;
    }

    private buildDelegatesList(reverseRecords: ReverseRecord[], profiles: Record<string, TzProfile>, delegatesPower: Delegate[]): DelegateVM[] {
        const rrByAddress = keyBy(reverseRecords, 'address');

        return delegatesPower.map(dp => ({
            rRecord: rrByAddress[dp.address] ?? this.partialReverseRecord(dp),
            profile: profiles[dp.address] ?? EmptyProfile,
            votingPower: dp.voting_power,
        }));
    }

    private partialReverseRecord(dp: Delegate): ReverseRecord {
        return { id: '', address: dp.address, owner: dp.address };
    }

    select(delegate: DelegateVM): void {
        this.selected = delegate;
        this.delegateSelected.next(delegate.rRecord);
    }
}
