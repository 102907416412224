import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { PoolContractStorage } from './pool-contract-stats.service';

export function calculateDepositedBalance({ poolStorage, votesBalance }: { poolStorage: PoolContractStorage; votesBalance: BigNumber }): BigNumber {
    const secondsSinceLastAccumulation = dayjs().utc().unix() - poolStorage.lastAccruedRewardsAt.utc().unix();
    const accruedRewards = BigNumber(poolStorage.rewardRate).multipliedBy(secondsSinceLastAccumulation).div(1e9);

    const totalPoolSize = BigNumber(poolStorage.poolSize).plus(accruedRewards);
    const totalVotes = votesBalance.multipliedBy(totalPoolSize).idiv(poolStorage.votesMinted);

    return totalVotes;
}
