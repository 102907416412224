<ng-container *transloco="let t">
    <button class="advanced-select-value w-100" mat-button [matMenuTriggerFor]="menu">
        <span *ngIf="selectedOption" class="text-truncate">
            <span>{{ selectedOption.label }}</span>
            <small> ({{ selectedOption.value }})</small>
        </span>
        <span *ngIf="!selectedOption">{{ t('general.not-set') }}</span>
        <fa-icon class="ms-2 me-0" icon="chevron-down"></fa-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button class="advanced-select-option d-flex align-items-center" mat-menu-item *ngFor="let option of allOptions | async" (click)="setOption(option)">
            <div class="d-flex flex-column flex-grow-1" [ngClass]="{ accent: selectedOption === option, 'built-in': option.isBuiltIn }">
                <span class="text-truncate">{{ option.label }}</span>
                <small class="text-truncate">{{ option.value }}</small>
            </div>
            <button class="advanced-select-remove" *ngIf="!option.isBuiltIn" (click)="removeOption(option); $event.stopPropagation()" mat-icon-button>
                <fa-icon icon="times"></fa-icon>
            </button>
        </button>
        <button mat-menu-item class="advanced-select-add d-flex align-items-center" (click)="addOption()">
            <fa-icon icon="plus"></fa-icon>
            <span>{{ t('actions.add') }}</span>
        </button>
    </mat-menu>
</ng-container>
