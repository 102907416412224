import { Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TezosService } from '../tezos/tezos.service';
import { ExpiringDomainsStatsService } from './expiring-domains-stats.service';

@UntilDestroy()
@Component({
    selector: 'td-expiring-domains-warning',
    templateUrl: './expiring-domains-warning.component.html',
    styleUrls: ['./expiring-domains-warning.component.scss'],
})
export class ExpiringDomainsWarningComponent {
    private _address: string;
    private address$ = new ReplaySubject<string>(1);

    @Input() set address(val: string) {
        this.address$.next(val);
        this._address = val;
    }

    get address(): string {
        return this._address;
    }

    stats$: Observable<{ hasExpiringLater: boolean; hasExpiringEarly: boolean } | null>;

    constructor(private expiringDomainsService: ExpiringDomainsStatsService, private tezosService: TezosService) {
        this.stats$ = combineLatest([this.tezosService.activeWallet, this.address$]).pipe(
            switchMap(([w, address]) => {
                if (w?.address === address)
                    return this.expiringDomainsService
                        .combinedStatsFor$(address)
                        .pipe(map(stats => (stats.hasExpiringEarly || stats.hasExpiringLater ? stats : null)));
                return of(null);
            })
        );
    }
}
