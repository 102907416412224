import { Injectable } from '@angular/core';
import { MichelCodecPacker, PollingSubscribeProvider, SetProviderOptions, TezosToolkit } from '@taquito/taquito';
import { Tzip16Module } from '@taquito/tzip16';
import { ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TezosToolkitService {
    tezos: TezosToolkit;

    private toolkitStream: ReplaySubject<TezosToolkit> = new ReplaySubject(1);

    get current() {
        return this.toolkitStream.asObservable();
    }

    new(rpcUrl: string) {
        return new TezosToolkit(rpcUrl);
    }

    setConfig(rpcUrl: string, options: SetProviderOptions) {
        this.tezos = new TezosToolkit(rpcUrl);
        this.tezos.setProvider({ packer: new MichelCodecPacker(), ...options });
        this.tezos.setStreamProvider(this.tezos.getFactory(PollingSubscribeProvider)({ pollingIntervalMilliseconds: 5000 }));
        this.tezos.addExtension(new Tzip16Module());

        this.toolkitStream.next(this.tezos);
    }
}
