import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DelegationStatus, ReverseRecord } from '../delegation/models';

@Component({
    selector: 'td-delegation-selector',
    templateUrl: './delegation-selector.component.html',
    styleUrls: ['./delegation-selector.component.scss'],
})
export class DelegationSelectorComponent {
    @Input() set delegate(value: DelegationStatus | null) {
        this.initialDelegate = value ?? null;
    }

    @Input() showScroll = true;

    @Output() delegationSelected = new EventEmitter<{ wantsToDelegate: boolean; delegate: ReverseRecord | null }>();

    selectedDelegate: ReverseRecord | null = null;
    initialDelegate: DelegationStatus | null = null;
    wantsToDelegate = true;

    onDelegateSelected(delegate: ReverseRecord | null) {
        this.selectedDelegate = delegate;
        this.delegationSelected.next({
            wantsToDelegate: this.wantsToDelegate,
            delegate: this.selectedDelegate,
        });
    }

    onWantsToDelegate() {
        this.wantsToDelegate = true;
        this.selectedDelegate = null;

        this.delegationSelected.next({
            wantsToDelegate: this.wantsToDelegate,
            delegate: this.selectedDelegate,
        });
    }

    onDoesntWantToDelegate() {
        this.wantsToDelegate = false;
        this.selectedDelegate = null;

        this.delegationSelected.next({
            wantsToDelegate: this.wantsToDelegate,
            delegate: this.selectedDelegate,
        });
    }
}
