import { Component, Input, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

import { DataSourceFactory } from '../graphql/data-source-factory';
import { AuctionState, OrderDirection, AuctionOrderField } from '../graphql/graphql.generated';
import { AuctionTableDataSource } from '../graphql/auction-table-data-source';
import { TezosService } from '../tezos/tezos.service';

@Component({
    selector: 'td-auction-summary-table',
    templateUrl: './auction-summary-table.component.html',
    styleUrls: ['./auction-summary-table.component.scss'],
})
export class AuctionSummaryTableComponent implements OnInit, OnChanges, OnDestroy {
    @Input() address: string;
    @Input() enableEmptyMessage: boolean;
    @Input() noShadow: boolean;

    dataSource: AuctionTableDataSource;
    states = AuctionState;
    columns: string[] = [];

    private emptyStream = new ReplaySubject<boolean>(1);
    private unsubscribe = new Subject<void>();

    get empty$() {
        return this.emptyStream;
    }

    constructor(private dataSourceFactory: DataSourceFactory, private tezosService: TezosService) {}

    ngOnInit() {
        this.dataSource = this.dataSourceFactory.createAuctionTableDataSource();
        this.dataSource.empty$.pipe(takeUntil(this.unsubscribe), delay(0)).subscribe(this.emptyStream);

        this.tezosService.activeWallet.pipe(takeUntil(this.unsubscribe)).subscribe(w => {
            if (w?.address === this.address) {
                this.columns = ['name', 'timeLeft', 'status', 'currentBid', 'actions'];
            } else {
                this.columns = ['name', 'timeLeft', 'currentBid'];
            }
        });

        this.load();
    }

    ngOnChanges() {
        if (this.dataSource) {
            this.load();
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private load() {
        this.dataSource.load(
            {
                where: {
                    or: [
                        {
                            bidders: { include: this.address },
                            state: { in: [AuctionState.InProgress] },
                        },
                        {
                            highestBidder: { equalTo: this.address },
                            state: { in: [AuctionState.CanBeSettled] },
                        },
                    ],
                },
                order: { field: AuctionOrderField.EndsAt, direction: OrderDirection.Asc },
                first: 4,
            },
            {
                pollInterval: 15 * 60 * 1000,
            }
        );
    }
}
