<ng-container *transloco="let t">
    <div *ngIf="dataSource.initialLoading$ | async" class="w-100 mt-3 d-flex justify-content-center">
        <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>

    <div *ngIf="!(dataSource.initialLoading$ | async)">
        <td-error *ngIf="dataSource.error$ | async; let error" [message]="error.message" [title]="t('general.error')"></td-error>

        <div
            class="table-container d-flex"
            [ngClass]="{ 'smooth-shadow': noShadow !== true }"
            [hidden]="(dataSource.empty$ | async) || (dataSource.initialLoading$ | async)"
        >
            <mat-table class="table" [dataSource]="dataSource">
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>{{ t('fields.name') }}</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <div class="text-truncate w-100">
                            <td-domain [domain]="row.domainName"></td-domain>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="timeLeft">
                    <mat-header-cell class="col-time" *matHeaderCellDef>{{ t('fields.ends') }}</mat-header-cell>
                    <mat-cell class="col-time" *matCellDef="let row">
                        <span [matTooltip]="row.endsAtUtc | dayjsDate"><td-humanized-date [value]="row.endsAtUtc"></td-humanized-date></span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="bidCount">
                    <mat-header-cell class="col-bid-count" *matHeaderCellDef>{{ t('fields.bid-count') }}</mat-header-cell>
                    <mat-cell class="col-bid-count" *matCellDef="let row">
                        {{ row.bidCount }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="currentBid">
                    <mat-header-cell class="col-bid" *matHeaderCellDef>{{ t('fields.current-bid') }}</mat-header-cell>
                    <mat-cell class="col-bid" *matCellDef="let row">
                        <td-tez [value]="row.highestBid.amount"></td-tez>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="['name', 'timeLeft', 'bidCount', 'currentBid']"></mat-header-row>
                <mat-row *matRowDef="let row; columns: ['name', 'timeLeft', 'bidCount', 'currentBid']"></mat-row>
            </mat-table>
        </div>

        <div class="mt-3 ms-3" *ngIf="(dataSource.empty$ | async) === false">
            <a [routerLink]="['/auctions']">{{ t('general.see-more') }}</a>
        </div>
    </div>
</ng-container>
