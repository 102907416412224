import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { BehaviorSubject, Observable } from 'rxjs';
import { PageService } from '../browser/page.service';
import { DelegateVM, DelegatesLeaderboardDataSource } from '../governance-core/delegates-leaderboard-data-source';
import { Delegates_Bool_Exp, Delegates_Order_By, Order_By } from '../governance-core/governance-graphql.generated';
import { GovernanceDataSourceFactory } from '../governance-core/pool-data-source-factory';
import { DelegatesBoardFilterModel, DelegatesLeaderboardSort, DelegatesLeaderboardType } from './delegates-board-filter.component';

@Component({
    selector: 'td-delegates-board',
    templateUrl: './delegates-board.component.html',
    styleUrls: ['./delegates-board.component.scss'],
})
export class DelegatesBoardComponent implements OnInit {
    private readonly page = inject(PageService);
    private readonly governanceDataSourceFactory = inject(GovernanceDataSourceFactory);

    @ViewChild(InfiniteScrollDirective) infiniteScroll: InfiniteScrollDirective;

    dataSource: DelegatesLeaderboardDataSource;
    vm$: Observable<DelegateVM[]>;

    readonly loading$ = new BehaviorSubject<boolean>(true);

    ngOnInit(): void {
        this.page.setTitle('delegates-leaderboard');
        this.dataSource = this.governanceDataSourceFactory.createDelegatesLeaderboardDataSource();

        this.vm$ = this.dataSource.data$;
    }

    filterChanged(filter: DelegatesBoardFilterModel) {
        if (this.infiniteScroll) {
            this.infiniteScroll.destroyScroller();
            this.infiniteScroll.setup();
        }

        setTimeout(() => this.load(filter));
    }

    private load(filter: DelegatesBoardFilterModel) {
        const where: Delegates_Bool_Exp = {};

        if (filter.address) {
            where.address = { _eq: filter.address };
        }

        where._and = [];

        if (filter.leaderboardTypes.length) {
            const orConditions: Delegates_Bool_Exp[] = [];

            if (filter.leaderboardTypes.includes(DelegatesLeaderboardType.Public)) {
                orConditions.push({ public: { _eq: true } });
            }

            if (filter.leaderboardTypes.includes(DelegatesLeaderboardType.NonPublic)) {
                orConditions.push({ public: { _eq: false } });
            }

            where._and.push(...orConditions);

            if (filter.leaderboardTypes.includes(DelegatesLeaderboardType.OnlyDelegating)) {
                where._and.push({ currentDelegations_aggregate: { count: { predicate: { _gt: 0 } } } });
            }
        }

        const order_by: Delegates_Order_By[] = [];

        if (filter.sorting) {
            if (filter.sorting.value === DelegatesLeaderboardSort.DelegatorCount) {
                order_by.push({ currentDelegations_aggregate: { count: <any>filter.sorting.direction } });
            }

            if (filter.sorting.value === DelegatesLeaderboardSort.Votes) {
                order_by.push({ voting_power: <any>filter.sorting.direction });
            }

            if (filter.sorting.value === DelegatesLeaderboardSort.PublicThenVotes) {
                order_by.push({ public: Order_By.Desc }, { voting_power: Order_By.Desc });
            }
        }

        where._and.push({ voting_power: { _gt: 0 } });

        this.dataSource.load({
            where,
            order_by,
        });
    }

    scrolled(): void {
        this.dataSource.loadMore();
    }
}
