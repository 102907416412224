import { MediaObserver } from '@/shared/media-observer.service';
import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSort, SortDirection } from '@angular/material/sort';
import mergeObject from 'lodash-es/merge';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { BehaviorSubject, merge, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { HistoryStateService } from '../browser/history-state.service';
import { DataSourceFactory } from '../graphql/data-source-factory';
import { ReverseRecordListQueryVariables, ReverseRecordOrder, ReverseRecordOrderField, ReverseRecordsFilter } from '../graphql/graphql.generated';
import { ReverseRecordListRecord, ReverseRecordTableDataSource } from '../graphql/reverse-record-table-data-source';
import { OverlayService } from '../overlay.service';
import { TezosService } from '../tezos/tezos.service';

export type ReverseRecordTableState = {
    reverseRecordTable?: {
        sort: {
            field: string;
            direction: SortDirection;
        };
        form: any;
    };
};

@Component({
    selector: 'td-reverse-record-table',
    templateUrl: './reverse-record-table.component.html',
    styleUrls: ['./reverse-record-table.component.scss'],
})
export class ReverseRecordTableComponent implements OnInit, OnDestroy, OnChanges {
    @Input() address: string;

    dataSource: ReverseRecordTableDataSource;
    form: UntypedFormGroup;

    sortField: string;
    sortDirection: SortDirection;
    hideResults = new BehaviorSubject<boolean>(true);
    columns: string[];

    private unsubscribe = new Subject<void>();

    @ViewChild(MatSort) sorter: MatSort;
    @ViewChild(InfiniteScrollDirective) infiniteScroll: InfiniteScrollDirective;

    constructor(
        private dataSourceFactory: DataSourceFactory,
        private formBuilder: UntypedFormBuilder,
        private historyStateService: HistoryStateService,
        private overlayService: OverlayService,
        private tezosService: TezosService,
        public media: MediaObserver
    ) {}

    ngOnInit(): void {
        this.dataSource = this.dataSourceFactory.createReverseRecordsDataSource();

        this.form = this.formBuilder.group({
            filter: this.formBuilder.control(''),
        });

        merge(this.form.get('filter')!.valueChanges.pipe(debounceTime(300)))
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.hideResults.next(true);
                this.reload();
            });

        this.dataSource.initialLoading$.pipe(takeUntil(this.unsubscribe)).subscribe(l => {
            if (!l) {
                this.hideResults.next(false);
            }
        });

        this.tezosService.activeWallet.pipe(takeUntil(this.unsubscribe)).subscribe(w => {
            if (w?.address === this.address) {
                this.columns = ['address', 'name', 'actions'];
            } else {
                this.columns = ['address', 'name'];
            }
        });

        this.restoreState();
    }

    ngOnChanges() {
        // check because changes fire before init
        if (this.form) {
            this.restoreState();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    scrolled() {
        this.dataSource.loadMore();
    }

    reload() {
        if (this.infiniteScroll) {
            this.infiniteScroll.destroyScroller();
            this.infiniteScroll.setup();
        }

        const where: ReverseRecordsFilter = mergeObject({
            owner: { equalTo: this.address },
            address: { notEqualTo: this.address },
            name: this.form.value.filter ? { like: this.form.value.filter } : null,
        });

        const variables: ReverseRecordListQueryVariables = {
            where,
            order: this.getSort(),
        };

        this.historyStateService.merge<ReverseRecordTableState>({
            reverseRecordTable: { form: this.form.value, sort: { field: this.sorter.active, direction: this.sorter.direction } },
        });

        this.dataSource.load(variables);
    }

    edit(row: ReverseRecordListRecord) {
        const dialog = this.overlayService.openReverseRecordEdit(row);

        dialog.afterClosed().subscribe(() => {
            this.reload();
        });
    }

    private restoreState() {
        this.hideResults.next(true);
        const state = this.historyStateService.get<ReverseRecordTableState>();

        if (state.reverseRecordTable) {
            this.form.setValue(state.reverseRecordTable.form, { emitEvent: false });
            this.sortField = state.reverseRecordTable.sort.field;
            this.sortDirection = state.reverseRecordTable.sort.direction;
        } else {
            this.sortField = ReverseRecordOrderField.Name;
            this.sortDirection = 'asc';
        }

        setTimeout(() => this.reload());
    }

    private getSort(): ReverseRecordOrder {
        return {
            field: this.sorter.active as any,
            direction: (this.sorter.direction.toUpperCase() as any) || null,
        };
    }
}
