import { Component } from '@angular/core';
import { TdValidators } from '../../../utils/form-validators';
import { EditorComponentBase } from './editor-component-base';

@Component({
    selector: 'td-eth-address',
    templateUrl: './eth-address.component.html',
})
export class EhtAddressComponent extends EditorComponentBase<any> {
    protected createValidators() {
        return [TdValidators.etherlinkAddress];
    }
}
