import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { TdValidators } from '../utils/form-validators';

@Component({
    selector: 'td-advanced-select-add',
    templateUrl: './advanced-select-add.component.html',
    styleUrls: ['./advanced-select-add.component.scss'],
})
export class AdvancedSelectAddComponent {
    form: UntypedFormGroup;

    constructor(
        formBuilder: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA)
        public data: { existingValues: string[]; valueValidators: ValidatorFn[]; title: string; labelLabel: string; valueLabel: string },
        private dialogRef: MatDialogRef<AdvancedSelectAddComponent>
    ) {
        this.form = formBuilder.group({
            label: formBuilder.control('', [Validators.required]),
            value: formBuilder.control('', [Validators.required, TdValidators.except(data.existingValues), ...data.valueValidators]),
        });
    }

    add() {
        this.dialogRef.close(this.form.value);
    }

    cancel() {
        this.dialogRef.close(null);
    }
}
