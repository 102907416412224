import { validateAddress, ValidationResult } from '@taquito/utils';

export function isTezosAddress(address: string) {
    const result = validateAddress(address);

    if (result === ValidationResult.VALID) {
        if (address.length > 36 && address.charAt(36) !== '%') {
            return false;
        }

        return true;
    }

    return false;
}
