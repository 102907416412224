import { Injectable } from '@angular/core';
import { from, Observable, ReplaySubject, Subject, timer } from 'rxjs';
import { first, switchMap, takeUntil } from 'rxjs/operators';

import BigNumber from 'bignumber.js';
import { Configuration } from '../configuration';
import { BidderBalanceGQL } from '../graphql/graphql.generated';
import { ErrorPresenterService } from '../utils/error-presenter.service';
import { TezosToolkitService } from './integration/tezos-toolkit.service';
import { BidderBalance, TezosBeaconWallet, TezosWallet } from './models';

class TezosWalletImpl implements TezosBeaconWallet {
    private balance$ = new ReplaySubject<number>(1);
    private _tedBalance$ = new ReplaySubject<BigNumber>(1);
    private _votesBalance$ = new ReplaySubject<BigNumber>(1);
    private low$ = new ReplaySubject<boolean>(1);
    private bidderBalances$ = new ReplaySubject<BidderBalance[]>(1);

    constructor(private wallet: TezosBeaconWallet) {}

    get isBalanceLow(): Observable<boolean> {
        return this.low$.asObservable();
    }

    get balance(): Observable<number> {
        return this.balance$.asObservable();
    }

    get address(): string {
        return this.wallet.address;
    }

    readonly tedBalance$ = this._tedBalance$.asObservable();
    readonly votesBalance$ = this._votesBalance$.asObservable();

    get bidderBalances(): Observable<BidderBalance[]> {
        return this.bidderBalances$.asObservable();
    }

    setBalance(value: number, isLow: boolean) {
        this.balance$.next(value);
        this.low$.next(isLow);
    }

    setTEDBalance(value: BigNumber) {
        this._tedBalance$.next(value);
    }

    setVotesBalance(value: BigNumber) {
        this._votesBalance$.next(value);
    }

    updateBidderBalance(balances: BidderBalance[]) {
        this.bidderBalances$.next(balances);
    }
}

@Injectable()
export class TezosWalletService {
    private currentWallet: TezosWalletImpl | null;
    private teardown = new Subject<void>();

    constructor(
        private tezosToolkitService: TezosToolkitService,
        private configuration: Configuration,
        private bidderBalanceGQL: BidderBalanceGQL,
        private errorPresenterService: ErrorPresenterService
    ) {}

    activateWallet(connectorWallet: TezosBeaconWallet | null): TezosWallet | null {
        this.teardown.next();

        if (!connectorWallet) {
            return null;
        }

        this.currentWallet = new TezosWalletImpl(connectorWallet);

        timer(0, 5 * 60 * 1000)
            .pipe(takeUntil(this.teardown))
            .subscribe(() => {
                this.refreshBalance();
                this.refreshBidderBalance();
                this.refreshTEDBalance();
                this.refreshVotesBalance();
            });

        return this.currentWallet;
    }

    refreshBidderBalance() {
        if (!this.currentWallet) {
            throw new Error('No wallet is currently activated.');
        }

        const address = this.currentWallet.address;

        this.bidderBalanceGQL
            .fetch({ address }, { fetchPolicy: 'network-only', errorPolicy: 'none' })
            .pipe(takeUntil(this.teardown))
            .subscribe({
                next: bidderBalances => {
                    if (this.currentWallet && this.currentWallet.address === address && bidderBalances.data.bidderBalances) {
                        this.currentWallet.updateBidderBalance(
                            bidderBalances.data.bidderBalances.balances.map(b => ({ balance: b.balance.toNumber(), tld: b.tldName }))
                        );
                    }
                },
                error: e => this.errorPresenterService.apiErrorToast('bidder-balance-refresh', e),
            });
    }

    refreshBalance() {
        if (!this.currentWallet) {
            throw new Error('No wallet is currently activated.');
        }

        const address = this.currentWallet.address;

        this.tezosToolkitService.current
            .pipe(
                first(),
                switchMap(tezos => from(tezos.rpc.getBalance(address)))
            )
            .subscribe({
                next: b => {
                    if (this.currentWallet && this.currentWallet.address === address) {
                        const balance = b.toNumber();
                        this.currentWallet.setBalance(balance, balance < this.configuration.safeBalanceThreshold);
                    }
                },
                error: e => this.errorPresenterService.nodeErrorToast('balance-refresh', e),
            });
    }

    refreshTEDBalance(): void {
        if (!this.currentWallet) {
            throw new Error('No wallet is currently activated.');
        }

        const address = this.currentWallet.address;

        this.tezosToolkitService.current
            .pipe(
                first(),
                switchMap(async tezos => {
                    const contract = await tezos.wallet.at(this.configuration.network.tedTokenContract);
                    const balances = await contract.views.balance_of([{ owner: address, token_id: 0 }]).read();

                    if (!balances.length) {
                        return new BigNumber(0);
                    }

                    return balances[0].balance;
                })
            )
            .subscribe({
                next: b => {
                    if (this.currentWallet && this.currentWallet.address === address) {
                        this.currentWallet.setTEDBalance(b);
                    }
                },
                error: e => this.errorPresenterService.nodeErrorToast('ted-balance-refresh', e),
            });
    }

    refreshVotesBalance(): void {
        if (!this.currentWallet) {
            throw new Error('No wallet is currently activated.');
        }

        const address = this.currentWallet.address;

        this.tezosToolkitService.current
            .pipe(
                first(),
                switchMap(async tezos => {
                    const contract = await tezos.wallet.at(this.configuration.network.tedVotesContract);
                    const balances = await contract.views.balance_of([{ owner: address, token_id: 0 }]).read();

                    if (!balances.length) {
                        return new BigNumber(0);
                    }

                    return balances[0].balance;
                })
            )
            .subscribe({
                next: b => {
                    if (this.currentWallet && this.currentWallet.address === address) {
                        this.currentWallet.setVotesBalance(b);
                    }
                },
                error: e => this.errorPresenterService.nodeErrorToast('tedv-balance-refresh', e),
            });
    }

    refreshTokenBalance(): void {
        this.refreshVotesBalance();
        this.refreshTEDBalance();
    }
}
