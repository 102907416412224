<div *transloco="let t">
    <td-error class="mb-3" *ngIf="dataSource.error$ | async; let error" [message]="error.message" [title]="t('general.error')"></td-error>

    <div class="table-filter">
        <form [formGroup]="form" action="." (ngSubmit)="reload()" class="d-flex align-items-center">
            <mat-form-field appearance="standard">
                <mat-label>{{ t('event-table.date-label') }}</mat-label>
                <mat-date-range-input [max]="maxDate" [min]="minDate" [rangePicker]="picker">
                    <input matStartDate formControlName="from" [attr.placeholder]="t('event-table.date-from-label')" />
                    <input matEndDate formControlName="until" [attr.placeholder]="t('event-table.date-until-label')" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>

                <mat-date-range-picker startView="year" [startAt]="minDate" #picker>
                    <mat-date-range-picker-actions>
                        <button mat-stroked-button (click)="form.reset()" matDateRangePickerCancel>{{ t('actions.clear') }}</button>
                        <button mat-flat-button color="primary" matDateRangePickerApply>{{ t('actions.apply') }}</button>
                    </mat-date-range-picker-actions>
                </mat-date-range-picker>
            </mat-form-field>
        </form>
    </div>

    <div *ngIf="(dataSource.empty$ | async) && (hideResults | async) === false" class="empty-message p-3 w-100 text-center smooth-shadow">
        {{ t('event-table.no-events') }}
    </div>

    <div
        *ngIf="network && !(dataSource.empty$ | async) && !(hideResults | async)"
        class="table-container smooth-shadow d-flex"
        infiniteScroll
        (scrolled)="scrolled()"
    >
        <mat-table class="w-100 table" [dataSource]="dataSource">
            <ng-container matColumnDef="time">
                <mat-header-cell class="col-time" *matHeaderCellDef>{{ t('fields.when') }}</mat-header-cell>
                <mat-cell class="col-time" *matCellDef="let row">
                    <span [matTooltip]="row.block.timestamp | dayjsDate"><td-humanized-date [value]="row.block.timestamp"></td-humanized-date></span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="text">
                <mat-header-cell *matHeaderCellDef>{{ t('fields.action') }}</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div class="text-truncate flex-grow-1">
                        <td-event-text [povAddress]="address" [event]="row"></td-event-text>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="amount">
                <mat-header-cell class="col-amount" *matHeaderCellDef>{{ t('fields.balance') }}</mat-header-cell>
                <mat-cell class="col-amount" *matCellDef="let row">
                    <td-event-balance [povAddress]="address" [event]="row"></td-event-balance>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="link">
                <mat-header-cell *matHeaderCellDef class="col-link"></mat-header-cell>
                <mat-cell *matCellDef="let row" class="col-link">
                    <a
                        *ngIf="row.operationGroupHash"
                        class="me-3 txt-primary"
                        [attr.href]="network.statsUrl + '/' + row.operationGroupHash"
                        target="_blank"
                        [matTooltip]="t('general.view-in-explorer')"
                    >
                        <fa-icon icon="external-link-alt"></fa-icon>
                    </a>
                </mat-cell>
            </ng-container>
            <mat-row *matRowDef="let row; columns: columns"></mat-row>
        </mat-table>
    </div>
    <div *ngIf="dataSource.loading$ | async" class="w-100 mt-3 d-flex justify-content-center">
        <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>
</div>
