<ng-container *transloco="let t">
    <button
        [matMenuTriggerFor]="menu"
        mat-stroked-button
        class="select-button"
        type="button"
        (click)="onClick()"
        [ngClass]="{ 'highlighted-stroked': !!selectedCount }"
    >
        <!-- t(filters.list.label) -->
        {{ t(buttonLabel ?? 'filters.list.label', { count: selectedCount }) }}
    </button>

    <mat-menu #menu="matMenu" class="eventtype-filter-menu">
        <form action="." (ngSubmit)="submit()">
            <div
                (click)="$event.stopPropagation()"
                (keydown.tab)="$event.stopPropagation()"
                (keydown.shift.tab)="$event.stopPropagation()"
                class="d-flex flex-column"
                cdkTrapFocus
            >
                <div class="p-2 pb-3 d-flex flex-column">
                    <mat-selection-list [compareWith]="compareWith" name="eventTypes" #selectionList (selectionChange)="selectionChange()">
                        <ng-container *ngFor="let s of allItems">
                            <mat-list-option *ngIf="s.type === optionType.Item" checkboxPosition="before" [value]="s" [selected]="!!initiallySelected[s.id]">{{
                                s.label
                            }}</mat-list-option>
                            <td-divider-with-text *ngIf="s.type === optionType.Separator" [text]="s.label" class="mt-2"></td-divider-with-text>
                        </ng-container>
                    </mat-selection-list>
                </div>
                <mat-divider></mat-divider>
                <div class="p-3 d-flex gap-2">
                    <button type="button" mat-stroked-button class="js-cancel-btn" (click)="clear()">{{ t('actions.clear') }}</button>
                    <button mat-flat-button color="primary" class="js-submit" type="submit">{{ t('actions.filter') }}</button>
                </div>
            </div>
        </form>
    </mat-menu>
</ng-container>
