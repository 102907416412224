import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DomainDetailQuery } from '../../graphql/graphql.generated';
import { OperationStatusDoneEvent } from '../../shared/operation-status.component';
import { TezosNetwork, TezosWallet } from '../../tezos/models';
import { TezosNetworkService } from '../../tezos/tezos-network.service';
import { SmartContractOperationEvent, TezosService } from '../../tezos/tezos.service';
import { Unarray } from '../../utils/types';
import { Observable } from 'rxjs';
import { orderBy } from 'lodash-es';
import { isOperator } from '../utils/is-operator';
import { TranslocoService } from '@ngneat/transloco';

type Operator = Unarray<NonNullable<DomainDetailQuery['domain']>['operators']>;

type OperatorViewModel = Operator & { isDirectBrokerAddress?: boolean };
@UntilDestroy()
@Component({
    selector: 'td-operator-permissions',
    templateUrl: './operator-permissions.component.html',
    styleUrls: ['./operator-permissions.component.scss'],
})
export class OperatorPermissionsComponent implements OnInit {
    domain: NonNullable<DomainDetailQuery['domain']>;
    operators: OperatorViewModel[];

    operation: Observable<SmartContractOperationEvent> | null;

    private wallet: TezosWallet;
    private network: TezosNetwork;
    private wasOperatorOfDomain = false;

    removedSelfAsOperator = false;

    @ViewChild('addressList') addressList?: ElementRef;

    constructor(
        private tezosService: TezosService,
        @Inject(MAT_DIALOG_DATA) private data: { domain: NonNullable<DomainDetailQuery['domain']> },
        private dialogRef: MatDialogRef<OperatorPermissionsComponent>,
        private tezosNetworkService: TezosNetworkService,
        private translation: TranslocoService
    ) {}

    ngOnInit(): void {
        this.tezosService.activeWallet.pipe(untilDestroyed(this)).subscribe(w => (this.wallet = w!));
        this.tezosNetworkService.activeNetwork.pipe(untilDestroyed(this)).subscribe(n => (this.network = n));

        this.domain = this.data.domain;
        this.operators = this.data.domain.operators.map(o => ({ ...o, isDirectBrokerAddress: o.address === this.network.directBrokerContract }));
        this.operators = orderBy(this.operators, o => o.isDirectBrokerAddress, 'desc');

        this.wasOperatorOfDomain = this.data.domain.operators.some(o => o.address === this.wallet?.address);
    }

    removeOperator(operator: OperatorViewModel): void {
        this.operators = this.operators.filter(o => o.address !== operator.address);
        this.removedSelfAsOperator = this.hasRemovedSelfAsOperator();
    }

    cancel() {
        this.dialogRef.close(false);
    }

    canSaveOperators(): boolean {
        return (
            this.operators.length !== this.domain.operators.length ||
            this.operators.some(updatedOperator => !this.inList(updatedOperator, this.domain.operators))
        );
    }

    onAddressEntered(address: string): void {
        const alreadyAdded = this.operators.some(o => o.address === address);
        const isOwner = this.domain.owner === address;

        if (!alreadyAdded && !isOwner) {
            this.operators.push({ address, id: '', isDirectBrokerAddress: address === this.network.directBrokerContract });
            this.removedSelfAsOperator = this.hasRemovedSelfAsOperator();
            setTimeout(() => this.addressList?.nativeElement.scrollTo({ top: this.addressList.nativeElement.scrollHeight, behavior: 'smooth' }));
        }
    }

    removeButtonTooltip(operator: OperatorViewModel): string {
        if (operator.address === this.wallet.address) {
            return this.translation.translate('operator-permissions.remove-self-warning');
        }

        return '';
    }

    removeButtonTooltipDisabled(operator: OperatorViewModel): boolean {
        return operator.address !== this.wallet.address;
    }

    updateOperatorList(): void {
        this.operation = this.tezosService.execute(async (_, tezos) => {
            const tokenContract = await tezos.wallet.at(this.network.tokenContract);

            const { allOperations, addOperationCount } = this.buildOperations(this.operators, this.domain);

            return tokenContract.methods.update_operators(allOperations).send({ storageLimit: 50 + addOperationCount * 50 });
        });
    }

    private hasRemovedSelfAsOperator(): boolean {
        return this.wasOperatorOfDomain && !isOperator({ operators: this.operators }, this.wallet?.address);
    }

    private buildOperations(operators: OperatorViewModel[], domain: NonNullable<DomainDetailQuery['domain']>) {
        const newOperators = operators.filter(updatedOperator => !this.inList(updatedOperator, domain.operators));
        const removedOperators = domain.operators.filter(existingOperator => !this.inList(existingOperator, operators));

        const addOperatorOps = newOperators.map(operator => ({
            add_operator: { owner: this.wallet.address, token_id: domain.tokenId, operator: operator.address },
        }));

        const removedOperatorOps = removedOperators.map(operator => ({
            remove_operator: { owner: this.wallet.address, token_id: domain.tokenId, operator: operator.address },
        }));

        return { allOperations: [...addOperatorOps, ...removedOperatorOps], addOperationCount: addOperatorOps.length };
    }

    operationDone(event: OperationStatusDoneEvent): void {
        if (event.success) {
            this.dialogRef.close(true);
        }
    }

    private inList = (updatedOperator: Operator, list: Operator[]) => list.some(currentOperator => updatedOperator.address === currentOperator.address);
}
