import { DataSource } from '@angular/cdk/table';

import { Unarray } from '../utils/types';
import { DataSourceBase, ProcessedData, DataSourceOptions } from '../data-source-base';
import { ReverseRecordListQuery, ReverseRecordListQueryVariables } from './graphql.generated';

export type ReverseRecordListRecord = Unarray<ReverseRecordListQuery['reverseRecords']['edges']>['node'];

export class ReverseRecordTableDataSource
    extends DataSourceBase<ReverseRecordListQuery, ReverseRecordListRecord[], ReverseRecordListQueryVariables>
    implements DataSource<ReverseRecordListRecord>
{
    constructor(options: DataSourceOptions<ReverseRecordListQuery, ReverseRecordListQueryVariables>) {
        super({
            ...options,
            defaultVariables: { first: 30 },
        });
    }

    protected transformData(data: ReverseRecordListQuery): ProcessedData<ReverseRecordListRecord[]> {
        return {
            data: data.reverseRecords.edges.map(e => e.node),
            pageInfo: data.reverseRecords.pageInfo,
            isEmpty: !data.reverseRecords.edges.length,
        };
    }
}
