import { Component, Input, OnDestroy } from '@angular/core';
import { TaquitoTezosDomainsClient } from '@tezos-domains/taquito-client';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TezosDomainsClientService } from '../tezos/integration/tezos-domains-client.service';
import { SearchService } from '../utils/search.service';

@Component({
    selector: 'td-register-cta',
    templateUrl: './register-cta.component.html',
    styleUrls: ['./register-cta.component.scss'],
})
export class RegisterCtaComponent implements OnDestroy {
    @Input() search: string;

    get defaultTld(): string {
        return this.tezosDomains?.validator?.supportedTLDs[0];
    }

    private tezosDomains: TaquitoTezosDomainsClient;
    private unsubscribe = new Subject<void>();

    constructor(private tezosDomainsClientService: TezosDomainsClientService, private searchService: SearchService) {
        this.tezosDomainsClientService.current.pipe(takeUntil(this.unsubscribe)).subscribe(c => (this.tezosDomains = c));
    }

    go(input: string) {
        this.unsubscribe.next();

        this.searchService.search(input).subscribe();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
