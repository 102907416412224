import { Injectable } from '@angular/core';
import { DomainAcquisitionInfo } from '@tezos-domains/manager';

export interface DomainsForRegistration {
    names: string[];
    acquisitionInfos: DomainAcquisitionInfo[];
}

@Injectable({
    providedIn: 'root',
})
export class RegistrationDomainsStateService {
    private _domains: DomainsForRegistration | null = null;

    get domains() {
        return this._domains;
    }

    setDomains(data: DomainsForRegistration) {
        this._domains = data;
    }

    clear() {
        this._domains = null;
    }
}
