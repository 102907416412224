<ng-container *transloco="let t">
    <button
        [matMenuTriggerFor]="menu"
        mat-stroked-button
        class="select-button"
        type="button"
        (click)="onClick()"
        [ngClass]="{ 'highlighted-stroked': !isDefaultSort() }"
    >
        <fa-icon [icon]="sortIcon" *ngIf="currentSort.direction === 'desc'"></fa-icon>
        <fa-icon icon="sort-amount-up" *ngIf="currentSort.direction === 'asc'"></fa-icon>

        <ng-container *ngIf="!isDefaultSort()">
            <span class="d-none d-sm-inline">{{ this.currentSort.label }}</span>
        </ng-container>
    </button>

    <mat-menu #menu="matMenu" class="eventtype-filter-menu">
        <form action=".">
            <div (click)="$event.stopPropagation()" (keydown.tab)="$event.stopPropagation()" (keydown.shift.tab)="$event.stopPropagation()" cdkTrapFocus>
                <mat-action-list class="px-2 py-3 d-flex flex-column">
                    <button mat-list-item *ngFor="let option of sortOptions" (click)="sort(option)">
                        <fa-icon
                            class="selected"
                            icon="circle"
                            *ngIf="this.currentSort.value === option.value && this.currentSort.direction === option.direction"
                        ></fa-icon>
                        {{ option.label }}
                    </button>
                </mat-action-list>
            </div>
        </form>
    </mat-menu>
</ng-container>
