import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { CookieService } from 'ngx-cookie';
import { combineLatest, merge, of, Subject } from 'rxjs';
import { first, map, shareReplay, startWith, takeUntil } from 'rxjs/operators';
import { WindowRef } from '../../browser/window-ref';
import { StorageSchema } from '../../utils/storage';
import { BrowserDarkModeService } from './browser-dark-mode.service';
import { ThemeType } from './dark-mode.model';

@Injectable({
    providedIn: 'root',
})
export class DarkModeService {
    private crtTheme: ThemeType = 'light';
    private _theme$ = new Subject<ThemeType>();

    private _userSelected$ = merge(of(this.getCookieTheme()), this._theme$).pipe(first(v => !!v));

    //ignore browser changes if user has selected something
    private _browserMode$ = this.browserDarkMode.mode.pipe(takeUntil(this._userSelected$));

    // once cookie value and browser mode have been determined, favor cookie value
    private _userPreferredMode$ = combineLatest([of(this.getCookieTheme()), this._browserMode$.pipe(startWith<ThemeType>('light'))]).pipe(
        map(([cookieTheme, browserTheme]) => cookieTheme || browserTheme)
    );

    readonly html = this.windowRef.nativeWindow.document.documentElement;
    readonly theme$ = merge(this._userPreferredMode$, this._theme$).pipe(shareReplay({ bufferSize: 1, refCount: true }));

    get currentTheme(): ThemeType {
        return this.crtTheme;
    }

    constructor(private windowRef: WindowRef, private cookieService: CookieService, private browserDarkMode: BrowserDarkModeService) {
        this.theme$.subscribe(theme => {
            this.crtTheme = theme;
            if (theme === 'dark') {
                this.setDarkMode();
            } else {
                this.setLightMode();
            }
        });
    }

    toggleTheme(): void {
        const newTheme = this.crtTheme === 'light' ? 'dark' : 'light';
        this._theme$.next(newTheme);
        this.storeSelectedTheme(newTheme);
    }

    private storeSelectedTheme(theme: ThemeType): void {
        this.cookieService.put(StorageSchema.userTheme.key, theme, {
            expires: dayjs().add(10, 'years').toDate(),
        });
    }

    private setDarkMode(): void {
        this.html.classList.add('dark-mode');
    }

    private setLightMode(): void {
        this.html.classList.remove('dark-mode');
    }

    private getCookieTheme(): ThemeType | undefined {
        const theme = this.cookieService.get(StorageSchema.userTheme.key);
        if (theme === 'light') return 'light';
        if (theme === 'dark') return 'dark';
    }
}
