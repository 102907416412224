import { Component, Input, OnInit, inject } from '@angular/core';
import BigNumber from 'bignumber.js';
import { Observable, combineLatest, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Logger } from '../browser/logger';
import { DelegationInfoGQL } from '../governance-core/governance-graphql.generated';
import { PoolStatsService } from './pool-stats.service';

type DelegatorVM = { to: { address: string; name: string; delegatorCount: number }; votes: BigNumber; delegatedVotes?: BigNumber };
type DelegateVM = { delegatorCount: number; votes: BigNumber };

@Component({
    selector: 'td-delegate-info',
    templateUrl: './delegate-info.component.html',
    styleUrls: ['./delegate-info.component.scss'],
})
export class DelegateInfoComponent implements OnInit {
    @Input() address: string;

    private readonly poolStats = inject(PoolStatsService);
    private readonly delegateInfoGQL = inject(DelegationInfoGQL);
    private logger = inject(Logger);

    vm$: Observable<{ delegator?: DelegatorVM; delegate?: DelegateVM; totalSupply: BigNumber; hidden: boolean }>;

    ngOnInit() {
        this.vm$ = combineLatest([this.getDelegatePower(this.address), this.poolStats.stats$]).pipe(
            map(([delegationStatus, poolStats]) => {
                const totalSupply = poolStats?.totalSupply ?? BigNumber(0);
                if (!delegationStatus) {
                    return {
                        totalSupply,
                        hidden: true,
                    };
                }

                if (isDelegator(delegationStatus, this.address)) {
                    return { delegator: delegationStatus, totalSupply, hidden: delegationStatus.votes.eq(0) };
                }

                return { delegate: delegationStatus, totalSupply, hidden: delegationStatus.votes.eq(0) };
            })
        );
    }

    private getDelegatePower(address: string): Observable<DelegatorVM | DelegateVM | null> {
        return this.delegateInfoGQL.fetch({ address }).pipe(
            switchMap(r => {
                const delegatingTo = r.data.delegatingTo[0];
                const delegateInfo = {
                    delegatorCount: r.data.currentDelegationsAggregate.aggregate?.count ?? 0,
                    votes: BigNumber(r.data.delegate?.voting_power ?? 0),
                };

                if (!delegatingTo?.to || delegatingTo?.to === address) {
                    return of(delegateInfo);
                }

                return this.getDelegatorInfo(delegatingTo.to, BigNumber(delegatingTo.amount ?? 0));
            }),
            catchError(e => {
                this.logger.error(e);
                return of(null);
            })
        );
    }

    private getDelegatorInfo(address: string, delegatedVotes: BigNumber): Observable<DelegatorVM | null> {
        return this.delegateInfoGQL.fetch({ address }).pipe(
            map(r => {
                const delegateInfo = {
                    votes: BigNumber(r.data.delegate?.voting_power ?? 0),
                    delegatedVotes,
                    to: {
                        address,
                        name: '',
                        delegatorCount: r.data.currentDelegationsAggregate.aggregate?.count ?? 0,
                    },
                };

                return delegateInfo;
            }),
            catchError(e => {
                this.logger.error(e);
                return of(null);
            })
        );
    }
}

function isDelegator(value: DelegatorVM | DelegateVM, currentAddress: string): value is DelegatorVM {
    return 'to' in value && value.to.address !== currentAddress;
}
