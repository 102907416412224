import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { SharedModule } from '../shared/shared.module';
import { ThemeparkComponent } from './themepark.component';
import { WalletComponent } from './wallet.component';
import { WalletSendComponent } from './wallet-send.component';
import { StateComponent } from './state.component';
import { AuditComponent } from './audit.component';
import { EventsModule } from '../events/events.module';

@NgModule({
    declarations: [ThemeparkComponent, WalletComponent, WalletSendComponent, StateComponent, AuditComponent],
    imports: [SharedModule, ReactiveFormsModule, EventsModule, ReactiveFormsModule, MatDatepickerModule],
})
export class DevModule {}
