import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { OfferRecord } from '../graphql/offer-table-data-source';
import { OperationStatusDoneEvent } from '../shared/operation-status.component';
import { TezosWallet } from '../tezos/models';
import { DirectBrokerService } from '../tezos/nft/direct-broker.service';
import { SmartContractOperationEvent } from '../tezos/tezos.service';

@Component({
    selector: 'td-bulk-offer-cancel',
    templateUrl: './bulk-offer-cancel.component.html',
    styleUrls: ['./bulk-offer-cancel.component.scss'],
})
export class BulkOfferCancelComponent implements OnInit {
    wallet: TezosWallet;

    operation: Observable<SmartContractOperationEvent> | null;
    offers: NonNullable<OfferRecord>[];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { offers: NonNullable<OfferRecord>[]; wallet: TezosWallet },
        private dialogRef: MatDialogRef<BulkOfferCancelComponent>,

        private directBrokerService: DirectBrokerService
    ) {}

    ngOnInit(): void {
        this.offers = this.data.offers;
        this.wallet = this.data.wallet;
    }

    cancelOffers() {
        const items = this.offers.map(offer => ({
            tokenId: offer.tokenId,
            removeOperator: this.wallet.address === offer.domain!.owner && !!offer.domain!.expires && offer.domain!.expires.isAfter(dayjs()),
        }));
        this.operation = this.directBrokerService.removeOffer(items);
    }

    operationDone(event: OperationStatusDoneEvent) {
        if (event.success) {
            this.dialogRef.close(true);
        }
    }

    close() {
        this.dialogRef.close(false);
    }
}
