import { inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, of } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { Configuration } from '../configuration';
import { notNil } from '../utils/types';
import { TezosBeaconWallet, TezosWallet } from './models';
import { TezosWalletService } from './tezos-wallet.service';

@UntilDestroy()
@Injectable()
export class ImpersonatedAddresService {
    private readonly router = inject(Router);
    private readonly config = inject(Configuration);

    private readonly _impersonatedAddress$ = new BehaviorSubject('');

    readonly impersonatedAddress$ = !this.config.allowImpersonation ? of('') : this._impersonatedAddress$.asObservable();

    get currentImpersonatedAddress() {
        return this._impersonatedAddress$.value;
    }

    constructor() {
        const initialUrl = new URL(window.location.href);

        this.router.events
            .pipe(
                filter(e => e instanceof NavigationEnd),
                map(() => {
                    return this.router.routerState.snapshot.root.queryParams['showas'];
                }),
                distinctUntilChanged(),
                filter(notNil),
                untilDestroyed(this),
                startWith(initialUrl.searchParams.get('showas') ?? '')
            )
            .subscribe(addr => this._impersonatedAddress$.next(addr));
    }
}

@Injectable()
export class FakeTezosWalletService extends TezosWalletService {
    private f = inject(ImpersonatedAddresService);

    override activateWallet(connectorWallet: TezosBeaconWallet | null): TezosWallet | null {
        if (this.f.currentImpersonatedAddress) {
            connectorWallet = { ...connectorWallet, address: this.f.currentImpersonatedAddress };
        }
        return super.activateWallet(connectorWallet);
    }
}
