import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import BigNumber from 'bignumber.js';
import { Dayjs } from 'dayjs';
import { AppService } from '../app-service';
import { MediaObserver } from '../shared/media-observer.service';
import { TezosWallet } from '../tezos/models';
import { TezosService } from '../tezos/tezos.service';
@UntilDestroy()
@Component({
    selector: 'td-domain-listing',
    templateUrl: './domain-listing.component.html',
    styleUrls: ['./domain-listing.component.scss'],
})
export class DomainListingComponent implements OnInit {
    @Input() set domainName(value: string | undefined | null) {
        this.name = value || '???';
    }
    @Input() expiration?: Dayjs | null;
    @Input() readonlyExpiration = false;
    @Input() price: BigNumber;
    @Input() seller: string;
    @Input() buttonText: string;
    @Input() buttonHelp?: string;
    @Input() buttonDisabled?: boolean;
    @Input() requireConnect: boolean;
    @Input() maxDomainNameCharacters = 200;
    @Output() buttonAction: EventEmitter<void> = new EventEmitter();

    @Input() buttonsTemplate?: TemplateRef<any>;
    @Input() badgesTemplate?: TemplateRef<any>;
    @Input() layoutSmall: 'row' | 'column' = 'column';
    @Input() responsiveAvatar = false;
    @Input() compactView = false;
    @Input() compactX = false;

    wallet: TezosWallet | null;
    name: string;

    constructor(private tezosService: TezosService, private appService: AppService, public media: MediaObserver) {}

    ngOnInit(): void {
        this.tezosService.activeWallet.pipe(untilDestroyed(this)).subscribe(w => (this.wallet = w));
    }

    action() {
        if (this.requireConnect) {
            if (this.wallet === undefined) {
                return;
            }

            if (this.wallet === null) {
                this.appService.openConnect(() => this.action());
                return;
            }

            if (this.wallet.address === this.seller) {
                return;
            }
        }

        this.buttonAction.emit();
    }
}
