import { ModuleWithProviders, NgModule } from '@angular/core';
import { Configuration } from '../configuration';
import { BidderBalanceGQL } from '../graphql/graphql.generated';
import { ErrorPresenterService } from '../utils/error-presenter.service';
import { FakeTezosWalletService, ImpersonatedAddresService } from './fake-tezos-wallet.service';
import { TezosToolkitService } from './integration/tezos-toolkit.service';
import { TezosWalletService } from './tezos-wallet.service';
import { TezosService } from './tezos.service';

@NgModule()
export class TezosModule {
    static forRoot(): ModuleWithProviders<TezosModule> {
        return {
            ngModule: TezosModule,
            providers: [
                ImpersonatedAddresService,
                TezosWalletService,
                TezosService,
                {
                    provide: TezosWalletService,
                    deps: [TezosToolkitService, Configuration, BidderBalanceGQL, ErrorPresenterService],
                    useFactory: (
                        tezosToolkitService: TezosToolkitService,
                        configuration: Configuration,
                        bidderBalanceGQL: BidderBalanceGQL,
                        errorPresenterService: ErrorPresenterService
                    ) => {
                        if (configuration.allowImpersonation) {
                            return new FakeTezosWalletService(tezosToolkitService, configuration, bidderBalanceGQL, errorPresenterService);
                        }

                        return new TezosWalletService(tezosToolkitService, configuration, bidderBalanceGQL, errorPresenterService);
                    },
                },
            ],
        };
    }
}
