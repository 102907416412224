import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { DomainCommonModule } from '../domain-common/domain-common.module';
import { FiltersModule } from '../filters/filters.module';
import { OffersCoreModule } from '../offers-core/offers-core.module';
import { SharedModule } from '../shared/shared.module';
import { BulkOfferCancelComponent } from './bulk-offer-cancel.component';
import { ExecuteOfferComponent } from './execute-offer.component';
import { HotOfferFilterComponent } from './hot-offer-filter.component';
import { HotOfferListComponent } from './hot-offer-list.component';
import { HotOfferTableComponent } from './hot-offer-table.component';
import { HotOffersSummaryTableComponent } from './hot-offers-summary-table.component';
import { OfferListComponent } from './offer-list.component';
import { OfferSummaryTableComponent } from './offer-summary-table.component';
import { OfferTableComponent } from './offer-table.component';
import { PlaceOfferComponent } from './place-offer.component';
import { RemoveOfferComponent } from './remove-offer.component';

@NgModule({
    declarations: [
        PlaceOfferComponent,
        RemoveOfferComponent,
        ExecuteOfferComponent,
        HotOfferListComponent,
        HotOfferTableComponent,
        HotOffersSummaryTableComponent,
        OfferSummaryTableComponent,
        OfferTableComponent,
        OfferListComponent,
        HotOfferFilterComponent,
        BulkOfferCancelComponent,
    ],
    imports: [SharedModule, ReactiveFormsModule, FiltersModule, MatDatepickerModule, OffersCoreModule, DomainCommonModule],
    exports: [PlaceOfferComponent, RemoveOfferComponent, ExecuteOfferComponent, HotOffersSummaryTableComponent, OfferSummaryTableComponent],
})
export class OffersModule {}
