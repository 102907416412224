import { Component, EventEmitter, OnInit, Output, ViewChild, inject } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { FilterHistoryStateService } from '../browser/filter-history-state.service';
import { ListOption, ListOptionType } from '../filters/list-filter.component';
import { SortOption } from '../filters/sorter.component';
import { HorizontalScrollComponent } from '../shared/horizontal-scroll.component';

export interface DelegatesBoardFilterModel {
    address?: string;
    leaderboardTypes: DelegatesLeaderboardType[];
    sorting?: SortOption;
}

export enum DelegatesLeaderboardType {
    Public = 'public',
    NonPublic = 'non-public',
    OnlyDelegating = 'only-delegating',
}

export enum DelegatesLeaderboardSort {
    PublicThenVotes = 'public-votes',
    Votes = 'votes',
    DelegatorCount = 'delegator-count',
}

@Component({
    selector: 'td-delegates-board-filter',
    templateUrl: './delegates-board-filter.component.html',
    styleUrls: ['./delegates-board-filter.component.scss'],
})
export class DelegatesBoardFilterComponent implements OnInit {
    private readonly historyStateService = inject(FilterHistoryStateService);
    private readonly transloco = inject(TranslocoService);

    private address$: BehaviorSubject<string>;
    private leaderboardTypes$: BehaviorSubject<DelegatesLeaderboardType[]>;
    private sort$: BehaviorSubject<SortOption>;
    private formSubscription: Subscription | null = null;

    @Output() filterChange = new EventEmitter<DelegatesBoardFilterModel>();
    @ViewChild(HorizontalScrollComponent) horizontalScroll: HorizontalScrollComponent;

    sortOptions: SortOption[] = [
        { label: this.transloco.translate('delegates-leaderboard.sort.public-then-votes'), value: DelegatesLeaderboardSort.PublicThenVotes, direction: 'asc' },
        { label: this.transloco.translate('delegates-leaderboard.sort.votes-desc'), value: DelegatesLeaderboardSort.Votes, direction: 'desc' },
        { label: this.transloco.translate('delegates-leaderboard.sort.votes-asc'), value: DelegatesLeaderboardSort.Votes, direction: 'asc' },
        {
            label: this.transloco.translate('delegates-leaderboard.sort.delegator-count-desc'),
            value: DelegatesLeaderboardSort.DelegatorCount,
            direction: 'desc',
        },
        { label: this.transloco.translate('delegates-leaderboard.sort.delegator-count-asc'), value: DelegatesLeaderboardSort.DelegatorCount, direction: 'asc' },
    ];
    defaultSort = this.sortOptions[0];

    initialAddress = '';
    initialSorting = this.defaultSort;
    initialLeaderboardTypes: DelegatesLeaderboardType[] = [];

    private leaderboardListFilters = [DelegatesLeaderboardType.Public, DelegatesLeaderboardType.NonPublic, DelegatesLeaderboardType.OnlyDelegating];
    leaderboardListOptions: ListOption[] = this.leaderboardListFilters.map(c => this.leaderboardTypeToOption(c));

    ngOnInit(): void {
        const state = this.historyStateService.get<DelegatesBoardFilterModel>();

        this.initialAddress = state.address ?? '';
        this.initialLeaderboardTypes = state.leaderboardTypes ?? [];
        this.initialSorting = state.sorting ?? this.defaultSort;

        this.address$ = new BehaviorSubject<string>(this.initialAddress);
        this.leaderboardTypes$ = new BehaviorSubject<DelegatesLeaderboardType[]>(this.initialLeaderboardTypes);
        this.sort$ = new BehaviorSubject<SortOption>(this.initialSorting);

        this.initializeFilter();
    }

    addressChanged(address: string) {
        this.address$.next(address);
    }

    leaderboardTypesChanged(types: string[]) {
        this.leaderboardTypes$.next(types.map(x => <DelegatesLeaderboardType>x));
    }

    sortChange(option: SortOption) {
        this.sort$.next(option);
    }

    private initializeFilter() {
        this.formSubscription?.unsubscribe();
        this.formSubscription = combineLatest([this.address$, this.leaderboardTypes$, this.sort$])
            .pipe(
                debounceTime(300),
                tap(([address, leaderboardTypes, sort]) => this.saveState(address, leaderboardTypes, sort)),
                map(([address, leaderboardTypes, sort]) => this.toFilterModel(address, leaderboardTypes, sort)),
                distinctUntilChanged<DelegatesBoardFilterModel>((p, x) => {
                    return isEqual(p, x);
                })
            )
            .subscribe(filter => {
                this.horizontalScroll?.refreshScroll();
                this.filterChange.next(filter);
            });
    }

    private leaderboardTypeToOption(type: DelegatesLeaderboardType): any {
        return new ListOption(type, this.translateCategory(type), ListOptionType.Item);
    }

    private translateCategory(categ: DelegatesLeaderboardType): string {
        /**
         * t(delegates-leaderboard.filters.list-types.public)
         * t(delegates-leaderboard.filters.list-types.non-public)
         * t(delegates-leaderboard.filters.list-types.only-delegating)
         */
        return this.transloco.translate(`delegates-leaderboard.filters.list-types.${categ}`);
    }

    private toFilterModel(address: string, leaderboardTypes: DelegatesLeaderboardType[], sorting: SortOption): DelegatesBoardFilterModel {
        return {
            address,
            leaderboardTypes,
            sorting,
        };
    }

    private saveState(address: string, leaderboardTypes: DelegatesLeaderboardType[], sorting: SortOption): void {
        this.historyStateService.merge<DelegatesBoardFilterModel>({
            address,
            leaderboardTypes,
            sorting,
        });
    }
}
