import { Injectable, inject } from '@angular/core';

import { BigMapAbstraction } from '@taquito/taquito';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';
import { Logger } from '../browser/logger';
import { Configuration } from '../configuration';
import { DelegationStatus } from '../delegation/models';
import { DelegateGQL } from '../governance-core/governance-graphql.generated';
import { ReverseRecordListGQL } from '../graphql/graphql.generated';
import { TezosToolkitService } from '../tezos/integration/tezos-toolkit.service';
import { TezosService } from '../tezos/tezos.service';
import { ErrorPresenterService } from '../utils/error-presenter.service';

@Injectable({
    providedIn: 'root',
})
export class DelegationStatusService {
    private tezosService = inject(TezosService);
    private tezosToolkitService = inject(TezosToolkitService);
    private errorPresenterService = inject(ErrorPresenterService);
    private configuration = inject(Configuration);
    private logger = inject(Logger);

    private delegateGQL = inject(DelegateGQL);
    private reverseRecordListGQL = inject(ReverseRecordListGQL);

    private refresh$ = new BehaviorSubject<void>(void 0);

    readonly delegationStatus$: Observable<DelegationStatus | null> = this.tezosService.activeWallet.pipe(
        switchMap(w => {
            if (!w) {
                return of(null);
            }

            return combineLatest([this.tezosToolkitService.current, this.refresh$]).pipe(
                switchMap(async ([tzToolkit]) => {
                    const contract = await tzToolkit.wallet.at(this.configuration.network.tedVotesContract);
                    const {
                        assets: { delegates },
                    } = await contract.storage<{ assets: { delegates: BigMapAbstraction } }>();

                    const address = await delegates.get<string>(w.address);

                    if (!address) {
                        return null;
                    }

                    return { address, votingPower: 0, reverseRecord: null } as DelegationStatus;
                }),
                switchMap(data => {
                    if (!data) {
                        return of(data);
                    }

                    const delegationPower$ = this.getDelegationPower(data);
                    const reverseRecord$ = this.getReverseRecord(data);

                    return combineLatest([delegationPower$, reverseRecord$]).pipe(
                        map(([delegationPower, reverseRecord]) => {
                            return { ...data, votingPower: Number(delegationPower?.voting_power ?? 0), reverseRecord } as DelegationStatus;
                        }),
                        catchError(e => {
                            this.logger.error(e);
                            return of(null);
                        })
                    );
                }),
                catchError(e => {
                    this.logger.error(e);
                    this.errorPresenterService.nodeErrorToast('delegate-status', e);
                    return of(null);
                })
            );
        }),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    private getDelegationPower(data: DelegationStatus) {
        return this.delegateGQL.fetch({ address: data.address }).pipe(
            map(r => r.data.delegates[0]),
            catchError(e => {
                this.logger.error(e);
                return of(null);
            })
        );
    }

    private getReverseRecord(data: DelegationStatus) {
        return this.reverseRecordListGQL.fetch({ where: { address: { equalTo: data.address } } }).pipe(
            map(r => r.data.reverseRecords.edges[0]?.node),
            catchError(e => {
                this.logger.error(e);
                return of(null);
            })
        );
    }

    refresh(): void {
        this.refresh$.next();
    }
}
