import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface BreakPoint {
    mediaQuery: string;
    alias: string;
    suffix?: string;
    overlapping?: boolean;
    priority?: number;
}

export const DefaultBreakpoints: Readonly<BreakPoint[]> = [
    {
        alias: 'xs',
        mediaQuery: 'screen and (min-width: 0px) and (max-width: 575.98px)',
        priority: 1000,
    },
    {
        alias: 'sm',
        mediaQuery: 'screen and (min-width: 576px) and (max-width: 767.98px)',
        priority: 900,
    },
    {
        alias: 'md',
        mediaQuery: 'screen and (min-width: 768px) and (max-width: 991.98px)',
        priority: 800,
    },
    {
        alias: 'lg',
        mediaQuery: 'screen and (min-width: 992px) and (max-width: 1199.98px)',
        priority: 700,
    },
    {
        alias: 'xl',
        mediaQuery: 'screen and (min-width: 1200px) and (max-width: 4999.98px)',
        priority: 600,
    },
    {
        alias: 'lt-sm',
        overlapping: true,
        mediaQuery: 'screen and (max-width: 767.98px)',
        priority: 950,
    },
    {
        alias: 'lt-md',
        overlapping: true,
        mediaQuery: 'screen and (max-width: 991.98px)',
        priority: 850,
    },
    {
        alias: 'lt-lg',
        overlapping: true,
        mediaQuery: 'screen and (max-width: 1199.98px)',
        priority: 750,
    },
    {
        alias: 'lt-xl',
        overlapping: true,
        priority: 650,
        mediaQuery: 'screen and (max-width: 4999.98px)',
    },
    {
        alias: 'gt-xs',
        overlapping: true,
        mediaQuery: 'screen and (min-width: 576px)',
        priority: -950,
    },
    {
        alias: 'gt-sm',
        overlapping: true,
        mediaQuery: 'screen and (min-width: 768px)',
        priority: -850,
    },
    {
        alias: 'gt-md',
        overlapping: true,
        mediaQuery: 'screen and (min-width: 992px)',
        priority: -750,
    },
    {
        alias: 'gt-lg',
        overlapping: true,
        mediaQuery: 'screen and (min-width: 1200px)',
        priority: -650,
    },
] as const;

@Injectable({
    providedIn: 'root',
})
export class MediaObserver {
    private readonly breakpoints = new Map<string, BreakPoint>();

    private mediaQuery$: Observable<BreakpointState>;

    constructor(private breakpointObserver: BreakpointObserver) {
        DefaultBreakpoints.forEach(p => this.breakpoints.set(p.alias, p));

        this.mediaQuery$ = breakpointObserver.observe(DefaultBreakpoints.map(p => p.mediaQuery));
    }

    asObservable(): Observable<BreakpointState> {
        return this.mediaQuery$;
    }

    isActive(value: string): boolean {
        const breakPoint = this.breakpoints.get(value);
        if (!breakPoint) return false;

        return this.breakpointObserver.isMatched(breakPoint.mediaQuery);
    }
}
