import { Component, ViewChild } from '@angular/core';

import { HotAuctionSummaryTableComponent } from '../auctions/hot-auction-summary-table.component';

@Component({
    selector: 'td-hot-home-auctions',
    templateUrl: './hot-auctions.component.html',
    styleUrls: ['./hot-auctions.component.scss'],
})
export class HotAuctionsComponent {
    @ViewChild(HotAuctionSummaryTableComponent) table: HotAuctionSummaryTableComponent;
}
