<div class="settings-menu" *transloco="let t">
    <a mat-menu-item [routerLink]="['/multi-registration']"> <fa-icon icon="info-circle" size="lg"></fa-icon>{{ t('topnav.multi-registration') }} </a>
    <mat-divider></mat-divider>
    <a mat-menu-item *ngIf="'' | landingPageUrl; let url" [attr.href]="url" target="_blank">
        <fa-icon icon="info-circle" size="lg"></fa-icon>{{ t('topnav.about') }}
    </a>
    <a mat-menu-item *ngIf="'about/faq' | landingPageUrl; let url" [attr.href]="url" target="_blank">
        <fa-icon icon="question-circle" size="lg"></fa-icon>{{ t('topnav.faq') }}
    </a>
    <a mat-menu-item href="https://developers.tezos.domains" target="_blank"> <fa-icon icon="book" size="lg"></fa-icon>{{ t('topnav.docs') }} </a>
    <a mat-menu-item *ngIf="'about/privacy-policy' | landingPageUrl; let url" [attr.href]="url" target="_blank">
        <fa-icon icon="user-shield" size="lg"></fa-icon>{{ t('topnav.privacy') }}
    </a>
    <a mat-menu-item *ngIf="'about/terms-of-service' | landingPageUrl; let url" [attr.href]="url" target="_blank">
        <fa-icon icon="asterisk" size="lg"></fa-icon>{{ t('topnav.tos') }}
    </a>
    <div class="d-flex justify-content-around align-items-stretch">
        <a mat-flat-button color="primary" href="https://tezos.domains/discord" target="_blank">
            <fa-icon icon="comment-alt" size="lg"></fa-icon>{{ t('topnav.feedback') }}
        </a>
    </div>
</div>
