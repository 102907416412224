<mat-toolbar class="navbar p-0 d-flex flex-column justify-content-center" *transloco="let t">
    <div class="inner d-flex align-items-center">
        <div class="logo flex-grow-1 flex-md-grow-0" *ngIf="!searchbarMode || media.isActive('gt-xs')">
            <a [routerLink]="['/']">
                <img *ngIf="(darkModeService.theme$ | async) === 'dark'; else logo_dark" class="d-none d-sm-block" src="/assets/img/logo_with_white_text.svg" />
                <ng-template #logo_dark>
                    <img class="d-none d-sm-block" src="/assets/img/logo_with_text.svg" />
                </ng-template>
                <img class="d-block d-sm-none" src="/assets/img/logo_notext.svg" />
                <td-badge *ngIf="network && network.name !== 'mainnet'" class="network-badge" color="accent">{{ network.name }}</td-badge>
            </a>
        </div>
        <button *ngIf="searchbarMode && media.isActive('xs')" mat-icon-button color="primary" (click)="setSearchbarMode(false)">
            <fa-icon size="lg" icon="arrow-left"></fa-icon>
        </button>
        <div class="full-searchbar d-flex flex-grow-1 flex-column position-relative" *ngIf="searchbarMode || media.isActive('gt-xs')">
            <td-action-input
                inputType="search"
                buttonType="icon"
                buttonIcon="search"
                [placeholder]="t('topnav.search-placeholder')"
                (action)="search($event)"
                class="w-100"
            ></td-action-input>
        </div>
        <div *ngIf="!searchbarMode || media.isActive('gt-xs')" class="d-flex justify-items-end align-items-center">
            <button *ngIf="media.isActive('xs')" mat-icon-button color="primary" (click)="setSearchbarMode(true)">
                <fa-icon size="lg" icon="search"></fa-icon>
            </button>
            <ng-container *ngIf="wallet">
                <button *ngIf="notificationsService.isEnabled" mat-icon-button class="lg" (click)="openNotifications()">
                    <fa-icon icon="bell" size="lg"></fa-icon>
                </button>
                <td-dark-mode class="me-2"></td-dark-mode>
                <td-account-widget class="me-2" (click)="openAccount()"></td-account-widget>
                <a mat-icon-button class="lg" [routerLink]="['/settings']" class="me-1">
                    <fa-icon icon="cog" size="lg"></fa-icon>
                </a>
                <button mat-icon-button class="lg" [matMenuTriggerFor]="settingsMenu">
                    <fa-icon icon="bars" size="lg"></fa-icon>
                </button>
            </ng-container>
            <ng-container *ngIf="wallet === null">
                <td-dark-mode class="me-2"></td-dark-mode>
                <button *ngIf="media.isActive('gt-sm')" mat-stroked-button color="primary" (click)="openConnect()" class="me-2">
                    {{ t('actions.connect-wallet') }}
                </button>
                <button class="big" mat-icon-button *ngIf="media.isActive('lt-md')" (click)="openConnect()" color="primary">
                    <fa-icon size="2x" icon="user-circle"></fa-icon>
                </button>
                <a mat-icon-button class="lg" [routerLink]="['/settings']">
                    <fa-icon icon="cog" size="lg"></fa-icon>
                </a>
                <button mat-icon-button class="lg" [matMenuTriggerFor]="settingsMenu">
                    <fa-icon icon="bars" size="lg"></fa-icon>
                </button>
            </ng-container>
            <ng-container *ngIf="wallet === undefined">
                <td-dark-mode class="ms-2"></td-dark-mode>
                <span class="ms-2 mat-body" *ngIf="media.isActive('gt-sm')">{{ t('topnav.connecting') }}</span>
                <mat-progress-spinner [diameter]="20" mode="indeterminate"></mat-progress-spinner>
                <a mat-icon-button class="lg" [routerLink]="['/settings']">
                    <fa-icon icon="cog" size="lg"></fa-icon>
                </a>
                <button mat-icon-button class="lg" [matMenuTriggerFor]="settingsMenu">
                    <fa-icon icon="bars" size="lg"></fa-icon>
                </button>
            </ng-container>
        </div>
    </div>
</mat-toolbar>

<mat-menu xPosition="before" #settingsMenu>
    <td-settings-menu></td-settings-menu>
</mat-menu>
