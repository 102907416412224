import { NgModule } from '@angular/core';
import { AirdropModule } from '../airdrop/airdrop.module';

import { AuctionsModule } from '../auctions/auctions.module';
import { BuyOffersModule } from '../buy-offers/buy-offers.module';
import { CtaModule } from '../cta/cta.module';
import { DomainsModule } from '../domains/domains.module';
import { ErrorModule } from '../error/error.module';
import { EventsModule } from '../events/events.module';
import { LayoutModule } from '../layout/layout.module';
import { OffersModule } from '../offers/offers.module';
import { SharedModule } from '../shared/shared.module';
import { SkeletonModule } from '../skeleton/skeleton.module';
import { GovPoolModule } from '../gv-pool/gov-pool.module';
import { AuctionsComponent } from './auctions.component';
import { DomainsComponent } from './domains.component';
import { HomeComponent } from './home.component';
import { HotAuctionsComponent } from './hot-auctions.component';
import { HotOffersComponent } from './hot-offers.component';
import { PurchaseFeedItemComponent } from './purchase-feed-item.component';
import { PurchasesComponent } from './purchases.component';

@NgModule({
    declarations: [HomeComponent, DomainsComponent, PurchasesComponent, PurchaseFeedItemComponent, AuctionsComponent, HotAuctionsComponent, HotOffersComponent],
    imports: [
        SharedModule,
        ErrorModule,
        LayoutModule,
        CtaModule,
        DomainsModule,
        AuctionsModule,
        SkeletonModule,
        EventsModule,
        OffersModule,
        BuyOffersModule,
        AirdropModule,
        GovPoolModule,
    ],
})
export class HomeModule {}
