import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { getLabel, getTld, RecordMetadata } from '@tezos-domains/core';
import { DomainAcquisitionInfo } from '@tezos-domains/manager';
import dayjs from 'dayjs';
import { Subject, Observable, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { OperationStatusDoneEvent } from '../shared/operation-status.component';
import { TezosWallet } from '../tezos/models';
import { SmartContractOperationEvent, TezosService } from '../tezos/tezos.service';
import { emptyStringToNull } from '../utils/convert';
import { TdValidators } from '../utils/form-validators';
import { ReverseRecordService, ReverseRecord } from './reverse-record.service';

@Component({
    selector: 'td-settle',
    templateUrl: './settle.component.html',
    styleUrls: ['./settle.component.scss'],
})
export class SettleComponent implements OnInit, OnDestroy {
    operation: Observable<SmartContractOperationEvent> | null;
    form: UntypedFormGroup;
    wallet: TezosWallet;
    duration: plugin.Duration;
    reverseRecord: ReverseRecord | null;

    private label: string;
    private tld: string;

    private unsubscribe = new Subject<void>();

    constructor(
        private tezosService: TezosService,
        private formBuilder: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: { name: string; acquisitionInfo: DomainAcquisitionInfo },
        private dialogRef: MatDialogRef<SettleComponent>,
        private reverseRecordService: ReverseRecordService
    ) {}

    ngOnInit(): void {
        this.label = getLabel(this.data.name);
        this.tld = getTld(this.data.name);
        this.duration = dayjs.duration(this.data.acquisitionInfo.auctionDetails.registrationDuration, 'days');

        combineLatest([this.tezosService.activeWallet, this.reverseRecordService.current])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(([w, r]) => {
                this.wallet = w!;
                this.reverseRecord = r;

                this.form = this.formBuilder.group({
                    address: this.formBuilder.control(this.wallet.address, [TdValidators.tezosAddress()]),
                    createReverseRecord: this.formBuilder.control(!r?.domain),
                });
            });

        let enabledValue: boolean | null = null;
        this.form.get('address')!.valueChanges.subscribe(v => {
            if (this.reverseRecord?.domain) {
                return;
            }

            const createReverseRecord = this.form.get('createReverseRecord')!;
            if (v !== this.wallet.address) {
                enabledValue = createReverseRecord.value;
                createReverseRecord.setValue(false);
                createReverseRecord.disable();
            } else {
                createReverseRecord.enable();
                if (enabledValue !== null) {
                    createReverseRecord.setValue(enabledValue);
                    enabledValue = null;
                }
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    settle() {
        const form = this.form.value;
        this.form.disable();

        const params = {
            address: emptyStringToNull(form.address),
            label: this.label,
            owner: this.wallet!.address,
            data: new RecordMetadata(),
        };

        if (form.createReverseRecord) {
            this.operation = this.tezosService.execute(client =>
                client.manager.batch(async b => [
                    await b.settle(this.tld, params),
                    await b.claimReverseRecord({ name: `${params.label}.${this.tld}`, owner: this.wallet.address }),
                ])
            );
        } else {
            this.operation = this.tezosService.execute(client => client.manager.settle(this.tld, params));
        }
    }

    cancel() {
        this.dialogRef.close(false);
    }

    operationDone(event: OperationStatusDoneEvent) {
        if (event.success) {
            this.dialogRef.close(true);
        } else {
            this.form.enable();
        }
    }
}
