<div class="smooth-shadow card p-0" *transloco="let t">
    <div class="full-card-width d-flex align-items-center">
        <div class="flex-grow-1">
            <div class="cta-content m-3 d-flex flex-column gap-4">
                <h1 class="mb-0">{{ t('cta.dashboard.title') }}</h1>
                <div>
                    <a mat-flat-button color="primary" [routerLink]="['/address', wallet.address]">
                        <fa-icon icon="layer-group"></fa-icon>
                        {{ t('general.go-to-dashboard') }}
                    </a>
                </div>
            </div>
        </div>
        <div class="graphic mt-4 d-none d-sm-block">
            <img src="/assets/img/register-cta.png" />
        </div>
    </div>
</div>
