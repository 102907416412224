import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NotificationsService } from './notifications.service';

@Component({
    selector: 'td-unsubscribe',
    templateUrl: './unsubscribe.component.html',
    styleUrls: ['./unsubscribe.component.scss'],
})
export class UnsubscribeComponent implements OnInit, OnDestroy {
    key: string;
    status: 'working' | 'success' | 'error' | 'KeyNotFound';

    private unsubscribe = new Subject<void>();

    constructor(private mailApiService: NotificationsService, private activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {
        this.key = this.activatedRoute.snapshot.queryParams['key'];
        this.status = 'working';
        this.mailApiService
            .unsubscribe(this.key)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(r => {
                if (r.status === 'OK') {
                    this.status = 'success';
                } else {
                    if (r.failReason) {
                        this.status = <any>r.failReason;
                    } else {
                        this.status = 'error';
                    }
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
