import { Component, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { interval, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import dayjs from 'dayjs';
import dropWhile from 'lodash-es/dropWhile';

@Component({
    selector: 'td-countdown',
    templateUrl: './countdown.component.html',
    styleUrls: ['./countdown.component.scss'],
})
export class CountdownComponent implements OnDestroy {
    private _value: dayjs.Dayjs;

    @Input() set value(value: dayjs.Dayjs | Date) {
        this.unsubscribe.next();

        if (value instanceof Date) {
            value = dayjs(value);
        }

        this._value = value;
        this.transform();

        interval(1000)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.transform());
    }

    @Output() zero = new EventEmitter<void>();

    displayValue: string;

    private unsubscribe = new Subject<void>();

    constructor(private translate: TranslocoService) {}

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private transform() {
        const value = this._value;
        if (!value) {
            this.displayValue = '';
            return;
        }

        const duration = dayjs.duration(value.diff(dayjs()));
        const days = Math.floor(duration.asDays());
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        /**
         * t(general.countdown.day)
         * t(general.countdown.hour)
         * t(general.countdown.minute)
         * t(general.countdown.second)
         */
        const data = dropWhile(
            [
                { key: 'general.countdown.day', value: days },
                { key: 'general.countdown.hour', value: hours },
                { key: 'general.countdown.minute', value: minutes },
                { key: 'general.countdown.second', value: seconds },
            ],
            x => x.value <= 0
        );

        if (!data.length) {
            this.displayValue = '';
            this.zero.next();
            this.unsubscribe.next();
            return;
        }

        this.translate
            .selectTranslate<string[]>(
                data.map(k => k.key),
                { days, hours, minutes, seconds }
            )
            .pipe(first())
            .subscribe(([...formatted]) => {
                this.displayValue = formatted.join('');
            });
    }
}
