<div class="d-flex flex-column justify-content-center align-items-center gap-2" *transloco="let t">
    <mat-divider class="w-100"></mat-divider>
    <div class="d-flex">
        <div class="me-3 txt-lg d-flex align-items-center">{{ t('share.title') }}</div>
        <button mat-icon-button class="lg" shareButton="twitter" [description]="text" class="me-2" [url]="url">
            <fa-icon [icon]="['fab', 'twitter']" size="lg"></fa-icon>
        </button>
        <button mat-icon-button class="lg" shareButton="telegram" [description]="text" class="me-2" [url]="url">
            <fa-icon [icon]="['fab', 'telegram-plane']" size="lg"></fa-icon>
        </button>
        <button mat-icon-button class="lg" shareButton="copy" class="me-2" [url]="url">
            <fa-icon icon="link" size="lg"></fa-icon>
        </button>
    </div>
</div>
