import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';

import { safeJsonParse } from '../../../utils/convert';
import { TdValidators } from '../../../utils/form-validators';
import { MediaObserver } from '../../media-observer.service';
import { EditorComponentBase } from './editor-component-base';

export type UnknownEditMode = 'bytes' | 'json';

@Component({
    selector: 'td-unknown',
    templateUrl: './unknown.component.html',
    styleUrls: ['./unknown.component.scss'],
})
export class UnknownComponent extends EditorComponentBase<any> implements OnInit {
    mode: UnknownEditMode;

    constructor(public media: MediaObserver) {
        super();
    }
    ngOnInit(): void {
        if (!this.control.value || this.decodeJSON(this.control.value)) {
            this.mode = 'json';
        } else {
            this.mode = 'bytes';
        }

        super.ngOnInit();
    }

    setMode(mode: UnknownEditMode) {
        this.mode = mode;
        this.innerControl.setValidators([Validators.required, ...this.createValidators()]);
        this.innerControl.setValue(this.decode(this.control.value));
    }

    protected encode(value: any) {
        switch (this.mode) {
            case 'bytes':
                return value;
            case 'json':
                return this.encodeJSON(value);
        }
    }

    protected decode(rawValue: string) {
        switch (this.mode) {
            case 'bytes':
                return rawValue;
            case 'json':
                return this.decodeJSON(rawValue);
        }
    }

    protected decodeJSON(rawValue: string) {
        const decoded = super.decode(rawValue);

        return decoded ? JSON.stringify(decoded) : null;
    }

    protected encodeJSON(value: any) {
        const json = safeJsonParse(value);

        return json ? super.encode(json) : null;
    }

    protected createValidators() {
        switch (this.mode) {
            case 'bytes':
                return [TdValidators.bytesString];
            case 'json':
                return [TdValidators.json];
        }
    }
}
