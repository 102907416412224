import { Component, Input } from '@angular/core';

@Component({
    selector: 'td-error',
    templateUrl: './error.component.html',
    styles: [':host { display: block }'],
})
export class ErrorComponent {
    @Input() message: string | null;
    @Input() title: string;
}
