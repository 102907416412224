<ng-container *ngIf="tzDomainsClient$ | async; let tzDomainsClient">
    <div *transloco="let t">
        <td-error *ngIf="dataSource.error$ | async; let error" [message]="error.message" [title]="t('general.error')"></td-error>
        <td-hot-offer-filter [tzDomainsClient]="tzDomainsClient" (filterChange)="onFilterChange($event)"></td-hot-offer-filter>

        <div *ngIf="(dataSource.empty$ | async) && (hideResults | async) === false" class="empty-message p-3 w-100 text-center smooth-shadow">
            {{ t('offer-table.no-offers') }}
        </div>

        <div class="table-container smooth-shadow" infiniteScroll (scrolled)="scrolled()" [hidden]="(dataSource.empty$ | async) || (hideResults | async)">
            <ng-container *ngFor="let offer of dataSource.data$ | async; let last = last">
                <td-domain-listing
                    [domainName]="offer.domain?.name"
                    [price]="offer.price"
                    [expiration]="offer.expiration"
                    [seller]="offer.seller"
                    [buttonText]="t('actions.buy')"
                    [requireConnect]="true"
                    (buttonAction)="executeOffer(offer)"
                ></td-domain-listing>
                <mat-divider *ngIf="!last"></mat-divider>
            </ng-container>
        </div>
        <div *ngIf="dataSource.loading$ | async" class="w-100 mt-3 d-flex justify-content-center">
            <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
        </div>
    </div>
</ng-container>
