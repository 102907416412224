<div *transloco="let t">
    <td-register-cta [search]="term" class="mb-4"></td-register-cta>

    <td-error *ngIf="offersDataSource.error$ | async; let error" [message]="error.message" [title]="t('general.error')"></td-error>
    <td-error *ngIf="domainDataSource.error$ | async; let error" [message]="error.message" [title]="t('general.error')"></td-error>
    <td-error *ngIf="nodeError" [message]="t('domain-detail.node-error-message')" [title]="t('general.error')"></td-error>

    <ng-container *ngIf="(offersDataSource.initialLoading$ | async) === false && exactMatch">
        <!-- t(general.taken) -->
        <!-- t(general.not-available) -->
        <div class="card smooth-shadow p-0 mb-3">
            <td-domain-listing
                *ngIf="!exactMatch.isTaken"
                [domainName]="name"
                [price]="exactMatch.price"
                [seller]="exactMatch.seller"
                [expiration]="exactMatch.expiration"
                [buttonText]="t(exactMatch.buttonText)"
                [buttonHelp]="exactMatch.buttonHelp"
                [buttonDisabled]="exactMatch.buttonDisabled"
                (buttonAction)="exactMatch.buttonAction()"
                class="full-card-width"
            ></td-domain-listing>

            <td-domain-listing
                *ngIf="exactMatch.isTaken"
                [domainName]="name"
                [price]="exactMatch.price"
                [seller]="exactMatch.seller"
                [expiration]="exactMatch.expiration"
                [buttonsTemplate]="exactMatchButtons"
                class="full-card-width"
            ></td-domain-listing>
        </div>

        <div class="card smooth-shadow" infiniteScroll (scrolled)="scrolled()" [hidden]="offersDataSource.empty$ | async">
            <h2>{{ t('search-results.secondary-market-title') }}</h2>
            <ng-container *ngFor="let offer of offersDataSource.data$ | async; let last = last">
                <td-domain-listing
                    [domainName]="offer.domain?.name"
                    [price]="offer.price"
                    [expiration]="offer.expiration"
                    [seller]="offer.seller"
                    [buttonText]="t('actions.buy')"
                    [requireConnect]="true"
                    (buttonAction)="executeOffer(offer)"
                    class="full-card-width"
                ></td-domain-listing>
                <mat-divider *ngIf="!last"></mat-divider>
            </ng-container>
        </div>
        <div *ngIf="offersDataSource.loading$ | async" class="w-100 mt-3">
            <div class="d-flex flex-column align-items-center">
                <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #exactMatchButtons>
    <td-buy-offer-actions [buyOffer]="buyOffer" [wallet]="wallet" [domain]="domain"></td-buy-offer-actions>
</ng-template>
