<form class="d-flex flex-column flex-fill h-100" [formGroup]="form" *transloco="let t">
    <h1 mat-dialog-title class="text-truncate flex-shrink-0">{{ t('transfer-ownership-form.title', { name: domain.name }) }}</h1>
    <td-operation-status class="w-100 flex-shrink-0" [operation]="operation" (done)="operationDone($event)" #op></td-operation-status>
    <mat-dialog-content class="d-flex flex-column flex-grow-1 gap-2">
        <td-recipient
            [control]="$any(form.get('owner'))"
            [required]="true"
            [label]="t('fields.new-owner')"
            [placeholder]="t('fields.new-owner-placeholder')"
            [noKT]="true"
            #recipient
        ></td-recipient>
        <ng-container *ngIf="domain.address">
            <mat-slide-toggle formControlName="clearAddress">
                <span>{{ t('transfer-ownership-form.clear-address-field') }}</span>
            </mat-slide-toggle>
            <td-alert state="warning" *ngIf="!form.get('clearAddress')!.value">
                <div [tdDynamicHtml]="t('transfer-ownership-form.clear-address-warning', { name: domain.name })"></div>
            </td-alert>
        </ng-container>
        <td-transfer-warning
            *ngIf="form.valid"
            [domains]="[domain]"
            [wallet]="wallet"
            [newOwner]="form.value.owner"
            [recipient]="recipient.rawValue"
            (requireTransferConfirmation)="requiresTransferConfirmation($event)"
        ></td-transfer-warning>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex justify-content-end align-items-end w-100 min-height-auto">
        <button
            mat-flat-button
            color="primary"
            *ngIf="domain.owner"
            (click)="transfer()"
            [disabled]="form.invalid || op.inProgress || !isDifferentNewOwner || transferConfirmationPending"
        >
            {{ t('actions.transfer') }}
        </button>
        <button mat-button (click)="cancel()" [disabled]="op.inProgress">{{ t('actions.cancel') }}</button>
    </mat-dialog-actions>
</form>
