import { MediaObserver } from '@/shared/media-observer.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ReverseRecord, ReverseRecordService } from '../domains/reverse-record.service';
import { NotificationsService } from '../notifications/notifications.service';
import { OverlayService } from '../overlay.service';
import { ActionInputComponent } from '../shared/action-input.component';
import { DarkModeService } from '../shared/dark-mode/dark-mode.service';
import { TezosBeaconWalletManager } from '../tezos/connector/tezos-wallet-manager';
import { TezosNetwork, TezosWallet } from '../tezos/models';
import { TezosNetworkService } from '../tezos/tezos-network.service';
import { TezosService } from '../tezos/tezos.service';
import { wrapFocus } from '../utils/hacks';
import { SearchService } from '../utils/search.service';
import { isTezosAddress } from '../utils/validate';
import { AccountComponent } from './account.component';

@Component({
    selector: 'td-top-nav',
    templateUrl: './top-nav.component.html',
    styleUrls: ['./top-nav.component.scss'],
})
export class TopNavComponent implements OnInit, OnDestroy {
    wallet: TezosWallet | null;
    network: TezosNetwork | null;
    reverseRecord: ReverseRecord | null;
    searchbarMode: boolean;

    private unsubscribe = new Subject<void>();

    @ViewChild(ActionInputComponent) searchbar?: ActionInputComponent;

    constructor(
        private tezosService: TezosService,
        public media: MediaObserver,
        private overlayService: OverlayService,
        private tezosNetworkService: TezosNetworkService,
        private router: Router,
        private reverseRecordService: ReverseRecordService,
        private searchService: SearchService,
        private dialog: MatDialog,
        public notificationsService: NotificationsService,
        public darkModeService: DarkModeService,
        private tezosBeaconWalletManager: TezosBeaconWalletManager
    ) {}

    ngOnInit(): void {
        this.tezosService.activeWallet.pipe(takeUntil(this.unsubscribe)).subscribe(w => (this.wallet = w));
        this.tezosNetworkService.activeNetwork.pipe(takeUntil(this.unsubscribe)).subscribe(n => (this.network = n));
        this.reverseRecordService.current.pipe(takeUntil(this.unsubscribe)).subscribe(r => (this.reverseRecord = r));
    }

    async search(term: string) {
        if (isTezosAddress(term)) {
            await this.router.navigate(['/address', term]);
        } else {
            await this.searchService.search(term).toPromise();
        }

        this.searchbar?.clear();
    }

    openConnect() {
        this.tezosBeaconWalletManager.connect().pipe(first()).subscribe();
    }

    openNotifications() {
        this.overlayService.openSubscribe();
    }

    setSearchbarMode(on: boolean) {
        this.searchbarMode = on;
        if (on) {
            wrapFocus(() => this.searchbar?.focus());
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    openAccount() {
        return this.dialog.open(AccountComponent, {
            height: '260px',
            width: '440px',
            disableClose: false,
        });
    }
}
