import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'td-favourites-page',
    templateUrl: './favourites-page.component.html',
    styleUrls: ['./favourites-page.component.scss'],
})
export class FavouritesPageComponent {
    loadedFavouritesCount = 0;
    loading = true;
}
