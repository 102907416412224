import { Injectable } from '@angular/core';
import { Logger } from '../browser/logger';
import { MessageService } from '../shared/message-service.service';

@Injectable({
    providedIn: 'root',
})
export class ErrorPresenterService {
    constructor(private log: Logger, private messageService: MessageService) {}

    nodeErrorToast(context: string, error: any) {
        this.log.error(`Error reaching tezos node in ${context}`, error);

        this.messageService.error({
            titleKey: `error.${context}-title`,
            messageKey: 'domain-detail.node-error-message',
            timeOut: 15000,
            extendedTimeOut: 15000,
        });
    }

    apiErrorToast(context: string, error: any) {
        this.log.error(`Error reaching tezos node in ${context}`, error);

        this.messageService.error({ titleKey: `error.${context}-title`, messageKey: 'error.api-error-message' });
    }
}
