<ng-container *ngIf="form">
    <form class="d-flex flex-column h-100" [formGroup]="form" *transloco="let t">
        <h1 *ngIf="showTitle" mat-dialog-title class="text-truncate flex-shrink-0">{{ t('notifications-subscribe.title') }}</h1>
        <mat-dialog-content class="d-flex flex-column flex-grow-1 gap-2" [ngClass]="{ 'outside-dialog-content': outsideDialog }">
            <ng-container *ngIf="status !== 'success'">
                <div [innerHTML]="t('notifications-subscribe.info')"></div>
                <td-alert *ngIf="status === 'error'" state="danger">{{ t('notifications-subscribe.error') }}</td-alert>
                <td-alert *ngIf="inProgress" state="info" [inProgress]="true">{{ t('notifications-subscribe.in-progress') }}</td-alert>
                <mat-form-field appearance="standard" class="permanently-disabled">
                    <mat-label>{{ t('fields.address') }}</mat-label>
                    <input disabled matInput [value]="wallet.address" required />
                </mat-form-field>
                <mat-form-field appearance="standard">
                    <mat-label>{{ t('fields.email') }}</mat-label>
                    <input type="email" formControlName="email" [placeholder]="t('fields.email-placeholder')" matInput required />
                    <mat-error>
                        <td-validation-messages [control]="form.get('email')!" [context]="{ name: t('fields.email') }"></td-validation-messages>
                    </mat-error>
                </mat-form-field>
                <td-alert *ngIf="subscription.status === 'unconfirmed' && this.form.get('email')!.value === subscription.email" state="info">
                    {{ t('notifications-subscribe.subscription-exists-unconfirmed') }}
                </td-alert>
                <td-alert *ngIf="subscription.status === 'confirmed' && this.form.get('email')!.value === subscription.email" state="info">
                    {{ t('notifications-subscribe.subscription-exists-confirmed') }}
                </td-alert>
            </ng-container>
            <ng-container *ngIf="status === 'success'">
                <td-alert state="success">
                    {{ t('notifications-subscribe.success') }}
                </td-alert>
            </ng-container>
        </mat-dialog-content>
        <mat-dialog-actions
            class="d-flex flex-column flex-md-row gap-3 gap-md-0 justify-content-end align-items-end"
            [ngClass]="{ 'outside-dialog-actions': outsideDialog }"
        >
            <ng-container *ngIf="status !== 'success'">
                <div class="d-flex flex-grow-1 justify-content-end justify-content-md-start"><ng-content></ng-content></div>
                <div class="d-flex justify-content-end">
                    <button mat-flat-button color="primary" (click)="subscribe()" [disabled]="form.invalid || inProgress">
                        <ng-container *ngIf="subscription.status === 'not-subscribed' || this.form.get('email')!.value !== subscription.email">
                            {{ t('actions.subscribe') }}
                        </ng-container>
                        <ng-container *ngIf="this.form.get('email')!.value === subscription.email">
                            <ng-container *ngIf="subscription.status === 'unconfirmed'">
                                {{ t('actions.resend') }}
                            </ng-container>
                            <ng-container *ngIf="subscription.status === 'confirmed'">
                                {{ t('actions.confirm') }}
                            </ng-container>
                        </ng-container>
                    </button>
                    <button mat-button (click)="close()" [disabled]="inProgress">{{ data?.from || from ? t('actions.skip') : t('actions.cancel') }}</button>
                    <button *ngIf="data?.from || from" mat-button (click)="dontShowAgain()" [disabled]="inProgress">
                        {{ t('actions.dont-show-again') }}
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="status === 'success'">
                <button mat-flat-button color="primary" (click)="close()">{{ t('actions.continue') }}</button>
            </ng-container>
        </mat-dialog-actions>
    </form>
</ng-container>
