<ng-container *transloco="let t">
    <div class="d-flex flex-column gap-2">
        <div *ngFor="let item of data" class="d-flex flex-column flex-sm-row">
            <div class="mat-body-strong text-truncate data-key pe-1">
                <span [matTooltip]="item.key">
                    {{ item.key | dataKey | async }}
                </span>
            </div>
            <div class="data-value flex-grow-1" *ngIf="decodeJson(item.rawValue); let json">
                <div *ngIf="json.type === 'binary'">{{ t('data-viewer.binary-data') }}</div>
                <pre *ngIf="json.type === 'json'">{{ json.value }}</pre>
                <div *ngIf="json.type === 'string'" class="data-value-string text-truncate">{{ json.value }}</div>
                <div *ngIf="json.type === 'etherlinkAddress'" class="data-value-string text-truncate d-flex align-items-center gap-2">
                    <a [href]="'https://explorer.etherlink.com/address/' + json.value" target="_blank">{{ json.value }}</a>
                    <td-copy-to-clipboard [text]="json.value"></td-copy-to-clipboard>
                </div>
            </div>
        </div>
    </div>
</ng-container>
