import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

import { MediaObserver } from '@/shared/media-observer.service';
import { DataSourceFactory } from '../graphql/data-source-factory';
import { ReverseRecordListRecord, ReverseRecordTableDataSource } from '../graphql/reverse-record-table-data-source';
import { OverlayService } from '../overlay.service';
import { TezosService } from '../tezos/tezos.service';

@Component({
    selector: 'td-reverse-record-summary-table',
    templateUrl: './reverse-record-summary-table.component.html',
    styleUrls: ['./reverse-record-summary-table.component.scss'],
})
export class ReverseRecordSummaryTableComponent implements OnInit, OnChanges, OnDestroy {
    @Input() address: string;
    @Input() enableEmptyMessage: boolean;
    @Input() noShadow: boolean;

    dataSource: ReverseRecordTableDataSource;
    columns: string[];

    private emptyStream = new ReplaySubject<boolean>(1);
    private unsubscribe = new Subject<void>();

    get empty$() {
        return this.emptyStream;
    }

    constructor(
        private dataSourceFactory: DataSourceFactory,
        private overlayService: OverlayService,
        private tezosService: TezosService,
        public media: MediaObserver
    ) {}

    ngOnInit() {
        this.dataSource = this.dataSourceFactory.createReverseRecordsDataSource();
        this.dataSource.empty$.pipe(takeUntil(this.unsubscribe), delay(0)).subscribe(this.emptyStream);

        this.tezosService.activeWallet.pipe(takeUntil(this.unsubscribe)).subscribe(w => {
            if (w?.address === this.address) {
                this.columns = ['address', 'name', 'actions'];
            } else {
                this.columns = ['address', 'name'];
            }
        });

        this.load();
    }

    ngOnChanges() {
        if (this.dataSource) {
            this.load();
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    edit(row: ReverseRecordListRecord) {
        const dialog = this.overlayService.openReverseRecordEdit(row);

        dialog.afterClosed().subscribe(() => {
            this.load();
        });
    }

    private load() {
        this.dataSource.load({
            where: { owner: { equalTo: this.address }, address: { notEqualTo: this.address } },
            first: 4,
        });
    }
}
