import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { CONFIGURATION } from './app/configuration';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

(async () => {
    try {
        const res = await fetch('app-config.json');
        if (!res.ok) {
            throw new Error('Fetching of app-config.json failed. Make sure this file is generated under /app-config.json.');
        }
        const data = await res.json();

        if (data.minEventsFilterDate) {
            data.minEventsFilterDate = new Date(data.minEventsFilterDate);
        }

        if (data.airdrop.start) {
            data.airdrop.start = new Date(data.airdrop.start);
        }

        if (data.airdrop.end) {
            data.airdrop.end = new Date(data.airdrop.end);
        }

        await platformBrowserDynamic([{ provide: CONFIGURATION, useValue: data }]).bootstrapModule(AppModule);
    } catch (error) {
        console.error(error);
    }
})();
