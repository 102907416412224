import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AppService } from '../app-service';
import { BuyOfferRecord } from '../graphql/buy-offer-table-data-source';
import { DomainDetailQuery, OfferState } from '../graphql/graphql.generated';
import { OverlayService } from '../overlay.service';
import { TezosWallet } from '../tezos/models';
import { OfferAfterActionState } from './buy-offer.models';
import { IgnoredOffersService } from './ignored-offers.service';

@Component({
    selector: 'td-buy-offer-actions',
    templateUrl: './buy-offer-actions.component.html',
    styleUrls: ['./buy-offer-actions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuyOfferActionsComponent implements OnChanges {
    @Input() domain?: ({ name: string } & Partial<DomainDetailQuery['domain']>) | null;
    @Input() buyOffer?: BuyOfferRecord | null;
    @Input() wallet: TezosWallet | null;
    @Input() allowedToIgnoreOffer = false;

    @Input() withdrawText?: string;

    @Output() offerActedOn = new EventEmitter<OfferAfterActionState>();

    private isReceivedOffer = false;
    private isSentOffer = false;

    offerIsExpired = false;
    canAcceptOffer = false;
    canIgnoreOffer = false;
    canWithdrawOffer = false;

    canMakeOffer = false;

    constructor(private overlayService: OverlayService, private ignoredOffersService: IgnoredOffersService, private appService: AppService) {}

    ngOnChanges(): void {
        this.isReceivedOffer = Boolean(this.buyOffer && this.wallet && this.buyOffer.seller === this.wallet.address);
        this.isSentOffer = Boolean(this.buyOffer && this.wallet && this.buyOffer.buyer === this.wallet.address);
        this.canMakeOffer = !this.buyOffer && !!this.domain;

        this.offerIsExpired = Boolean(
            this.buyOffer && [OfferState.DomainExpired, OfferState.DomainIsExpiringSoon, OfferState.OfferExpired].includes(this.buyOffer.state)
        );
        this.canAcceptOffer = this.buyOffer?.state === OfferState.Active && this.isReceivedOffer && !this.isSentOffer;
        this.canIgnoreOffer = Boolean(this.allowedToIgnoreOffer && this.buyOffer && this.canAcceptOffer && !this.ignoredOffersService.isIgnored(this.buyOffer));
        this.canWithdrawOffer = this.isSentOffer;
    }

    makeOffer() {
        if (this.buyOffer) {
            return;
        }

        if (!this.domain) {
            console.warn('no offer and no domain set on BuyOfferActions');
            return;
        }

        if (this.wallet === null) {
            this.appService.openConnect(() => this.makeOffer());
            return;
        }

        this.overlayService.openPlaceBuyOffer(this.domain as any, () => this.offerActedOn.next(OfferAfterActionState.Placed));
    }

    acceptOffer() {
        if (!this.buyOffer) {
            return;
        }

        this.overlayService.openExecuteBuyOffer(this.buyOffer, () => this.offerActedOn.next(OfferAfterActionState.Accepted));
    }

    ignoreOffer() {
        if (!this.buyOffer) {
            return;
        }

        this.ignoredOffersService.ignore(this.buyOffer);
        this.offerActedOn.next(OfferAfterActionState.Ignored);
    }

    withdrawOffer() {
        if (!this.buyOffer) {
            return;
        }

        this.overlayService.openRemoveBuyOffer(this.buyOffer, () => this.offerActedOn.next(OfferAfterActionState.Withdrawn));
    }
}
