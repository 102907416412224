import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { SettingsComponent } from './settings.component';
import { SharedModule } from '../shared/shared.module';
import { DerivationPathEditorComponent } from './derivation-path-editor.component';
import { RpcUrlEditorComponent } from './rpc-url-editor.component';
import { NotificationsModule } from '../notifications/notifications.module';

@NgModule({
    declarations: [SettingsComponent, DerivationPathEditorComponent, RpcUrlEditorComponent],
    imports: [SharedModule, FormsModule, NotificationsModule],
})
export class SettingsModule {}
