import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import BigNumber from 'bignumber.js';
import { Observable } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';
import { GovActionService } from '../airdrop/gov-action.service';
import { DelegationStatus, ReverseRecord } from '../delegation/models';
import { OperationStatusDoneEvent } from '../shared/operation-status.component';
import { SmartContractOperationEvent } from '../tezos/tezos.service';
import { TdValidators } from '../utils/form-validators';
import { PoolStats } from './models';
import { convertTedToVotes } from './pool-exchange';

@UntilDestroy()
@Component({
    selector: 'td-deposit',
    templateUrl: './deposit.component.html',
    styleUrls: ['./deposit.component.scss'],
})
export class DepositComponent implements OnInit {
    private dialogRef = inject(MatDialogRef<DepositComponent>);
    private formBuilder = inject(FormBuilder);
    private govAction = inject(GovActionService);

    data = inject<{ poolStats: PoolStats; delegate: DelegationStatus | null }>(MAT_DIALOG_DATA);

    willReceive = new BigNumber(0);
    showDelegationSelector = false;
    wantsToDelegate = true;
    delegate: ReverseRecord | null;
    operation: Observable<SmartContractOperationEvent> | null;

    maxDeposit = this.data.poolStats.tedBalance.div(1e6).toNumber();

    form = this.formBuilder.group({
        amount: this.formBuilder.control(this.maxDeposit, {
            nonNullable: true,
            validators: [Validators.required, Validators.min(0), Validators.max(this.maxDeposit), TdValidators.number(6)],
        }),
    });

    ngOnInit() {
        const amount$ = this.form.controls.amount.valueChanges.pipe(
            filter(v => Number.isFinite(v)),
            startWith(this.maxDeposit)
        );

        amount$.pipe(untilDestroyed(this)).subscribe(val => (this.willReceive = convertTedToVotes(val * 1e6, this.data.poolStats)));
    }

    maxAmount($event: Event) {
        $event.stopPropagation();
        this.form.controls.amount.setValue(this.maxDeposit);
    }

    operationDone(event: OperationStatusDoneEvent) {
        if (event.success) {
            this.dialogRef.close(true);
        } else {
            this.form.enable();
        }
    }

    delegateSelected({ wantsToDelegate, delegate }: { wantsToDelegate: boolean; delegate: ReverseRecord | null }) {
        this.wantsToDelegate = wantsToDelegate;
        this.delegate = delegate;
    }

    deposit() {
        if (!this.data.delegate) {
            this.showDelegationSelector = true;
            return;
        }

        this.form.disable();

        const amount = this.getDepositedAmount();
        this.operation = this.govAction.q.deposit(amount, this.data.poolStats.owner!).send();
    }

    depositAndDelegate() {
        const amount = this.getDepositedAmount();
        let actions = this.govAction.q.deposit(amount, this.data.poolStats.owner!);

        if (this.wantsToDelegate && this.delegate) {
            actions = actions.delegate(this.delegate.address);
        }

        this.operation = actions.send();
    }

    private getDepositedAmount() {
        let amount = BigNumber(this.form.value.amount!);

        if (amount.eq(this.maxDeposit)) {
            amount = this.data.poolStats.tedBalance.div(1e6);
        }
        return amount.times(1e6);
    }

    cancel() {
        this.dialogRef.close();
    }
}
