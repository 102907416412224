import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable, ReplaySubject, from, of } from 'rxjs';
import { map, switchMap, catchError, first } from 'rxjs/operators';
import cloneDeep from 'lodash-es/cloneDeep';

import { TezosNetwork } from './models';
import { Configuration } from '../configuration';
import { TezosToolkitService } from './integration/tezos-toolkit.service';
import { StorageSchema } from '../utils/storage';

@Injectable({
    providedIn: 'root',
})
export class TezosNetworkService {
    private activeNetworkStream = new ReplaySubject<TezosNetwork>(1);
    private customRpc: string | null;

    get activeNetwork(): Observable<TezosNetwork> {
        return this.activeNetworkStream;
    }

    get customRpcUrl() {
        return this.customRpc;
    }

    constructor(private storageMap: StorageMap, private configuration: Configuration, private tezosToolkitService: TezosToolkitService) {
        this.storageMap
            .watch(StorageSchema.rpcUrl.key, StorageSchema.rpcUrl.schema)
            .pipe(
                switchMap(customRpcUrl => {
                    if (customRpcUrl) {
                        if (this.configuration.network.rpcUrls.some(u => u.url === customRpcUrl)) {
                            return of({ url: customRpcUrl, valid: true });
                        } else {
                            return this.checkChainId(customRpcUrl, this.configuration.network.chainId).pipe(map(valid => ({ url: customRpcUrl, valid })));
                        }
                    } else {
                        return of({ url: null, valid: null });
                    }
                })
            )
            .subscribe(customRpc => {
                const network = cloneDeep(this.configuration.network);
                network.rpcUrls = network.rpcUrls.filter(url => url.enabled === true || url.enabled === 'true');

                this.customRpc = customRpc.url;
                if (customRpc.url && customRpc.valid) {
                    network.rpcUrl = customRpc.url;
                } else {
                    network.rpcUrl = network.rpcUrls[0].url;
                }

                this.activeNetworkStream.next(network);
            });
    }

    setCustomRpcUrl(url: string | null) {
        this.activeNetwork.pipe(first()).subscribe(network => {
            if (url && url !== network.rpcUrls[0].url) {
                this.storageMap.set(StorageSchema.rpcUrl.key, url, StorageSchema.rpcUrl.schema).subscribe();
            } else {
                this.storageMap.delete(StorageSchema.rpcUrl.key).subscribe();
            }
        });
    }

    private checkChainId(url: string, expectedChainId: string): Observable<boolean> {
        return from(this.getChainId(url)).pipe(
            map(chainId => chainId === expectedChainId),
            catchError(() => of(false))
        );
    }

    private async getChainId(url: string) {
        const tezos = this.tezosToolkitService.new(url);
        return await tezos.rpc.getChainId();
    }
}
