import { Query, QueryRef } from 'apollo-angular';
import escape from 'lodash-es/escape';
import { BehaviorSubject, Observable, ReplaySubject, Subject, of } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { WatchQueryOptionsAlone } from 'apollo-angular/types';
import { AppService } from './app-service';
import { PageInfo } from './graphql/graphql.generated';

const QUERY_REF_ERROR = 'Query has not been constructed yet. Please call load() first.';

export interface ProcessedData<T> {
    data: T;
    pageInfo?: PageInfo;
    isEmpty?: boolean;
    totalCount?: number;
}

export interface DataSourceOptions<TData, TVariables> {
    query: Query<TData, TVariables>;
    defaultVariables?: Partial<TVariables>;
    appService: AppService;
}

export interface DataSourcePagination<TVariables> {
    get isLastPageLoaded(): boolean;

    get currentPagingState(): { limit: number; offset: number };

    update(processedData: ProcessedData<any>): void;
    fetchMore(direction: 'up' | 'down'): TVariables;
    reset(variables: TVariables): void;
}

export class DomainsDataSourcePagination<TVariables> implements DataSourcePagination<TVariables> {
    private lastLoadedCursor: string | null;
    private headCursor: string | null;

    isLastPageLoaded = false;

    get currentPagingState(): { limit: number; offset: number } {
        throw new Error('currentPagingState is not applicable for DomainsDataSourcePagination');
    }

    update(processedData: ProcessedData<any>) {
        if (processedData.pageInfo != null) {
            this.lastLoadedCursor = processedData.pageInfo.endCursor || null;
            this.isLastPageLoaded = !processedData.pageInfo.hasNextPage;
            this.headCursor = processedData.pageInfo.startCursor || null;
        }
    }

    fetchMore(direction: 'up' | 'down' = 'down'): TVariables {
        if (direction === 'down') {
            return {
                after: this.lastLoadedCursor,
            } as any;
        }

        return {
            before: this.headCursor,
            last: 50,
            first: null,
        } as any;
    }

    reset() {
        this.lastLoadedCursor = null;
        this.isLastPageLoaded = false;
    }
}

export abstract class DataSourceBase<TData, TProcessedData, TVariables, TExtraData = void> {
    private dataStream = new ReplaySubject<TProcessedData>();
    private loadingStream = new BehaviorSubject(true);
    private initialLoadingStream = new BehaviorSubject(true);
    private emptyStream = new ReplaySubject<boolean>(1);
    private totalCountStream = new ReplaySubject<number>(1);
    private errorStream = new ReplaySubject<Error | null>(1);
    private queryRef: QueryRef<TData, TVariables> | null;
    private initialLoadDone = false;
    private loadOptions?: WatchQueryOptionsAlone<TVariables, TData>;

    protected stopQuery = new Subject<void>();
    protected loadVariables: TVariables;
    protected loadMetadata: Record<string, any>;

    get isLastPageLoaded() {
        return this.pagination.isLastPageLoaded;
    }

    readonly loading$ = this.loadingStream.asObservable();
    readonly initialLoading$ = this.initialLoadingStream.asObservable();
    readonly error$ = this.errorStream.asObservable();
    readonly empty$ = this.emptyStream.asObservable();
    readonly data$ = this.dataStream.asObservable();
    readonly totalCount$ = this.totalCountStream.asObservable();

    protected readonly pagination = this.createPagination();

    constructor(private options: DataSourceOptions<TData, TVariables>) {}

    connect(): Observable<TProcessedData> {
        return this.data$;
    }

    disconnect(): void {
        this.dataStream.complete();
        this.loadingStream.complete();
        this.errorStream.complete();
        this.emptyStream.complete();
        this.totalCountStream.complete();
        this.stopQuery.next();
        this.stopQuery.complete();
    }

    load(variables: TVariables, options?: WatchQueryOptionsAlone<TVariables, TData>, metadata?: Record<string, any>) {
        this.errorStream.next(null);
        this.initialLoadDone = false;

        this.loadVariables = variables;
        this.loadOptions = options;
        this.loadMetadata = metadata || {};

        this.pagination.reset(variables);

        this.stopQuery.next();
        this.createGQL(variables, options || {});

        this.options.appService.goingOnlineFromOffline.pipe(takeUntil(this.stopQuery)).subscribe(() => this.reload());
    }

    reload() {
        this.load(this.loadVariables, this.loadOptions);
    }

    loadMore(direction: 'up' | 'down' = 'down') {
        if (!this.queryRef) {
            throw new Error(QUERY_REF_ERROR);
        }

        if (direction === 'down') {
            if (this.isLastPageLoaded) {
                return;
            }

            this.queryRef.fetchMore({ variables: this.pagination.fetchMore(direction) });
        } else {
            this.queryRef.fetchMore({ variables: this.pagination.fetchMore(direction) });
        }
    }

    protected abstract transformData(data: TData, extraData?: TExtraData | TExtraData[] | undefined | null): ProcessedData<TProcessedData>;

    protected createPagination(): DataSourcePagination<TVariables> {
        return new DomainsDataSourcePagination<TVariables>();
    }

    protected getExtraData(_data: TData): Observable<TExtraData | TExtraData[]> | null {
        return null;
    }

    private createGQL(variables: TVariables, options: WatchQueryOptionsAlone<TVariables, TData>) {
        this.queryRef = this.options.query.watch(
            { ...this.options.defaultVariables, ...variables },
            {
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-first',
                errorPolicy: 'all',
                useInitialLoading: true,
                notifyOnNetworkStatusChange: true,
                ...options,
            }
        );

        this.queryRef.valueChanges
            .pipe(
                switchMap(({ data, loading, errors }) => {
                    const extraData = this.getExtraData(data);
                    if (!extraData) {
                        return of({ data, loading, errors, extraData: null });
                    }

                    return extraData.pipe(map(result => ({ data, loading, errors, extraData: result })));
                }),
                takeUntil(this.stopQuery)
            )
            .subscribe({
                next: ({ data, loading, errors, extraData }) => {
                    if (errors) {
                        const error = errors[0];
                        error.message = escape(error.message);

                        this.errorStream.next(errors ? errors[0] : null);
                    }

                    this.loadingStream.next(loading);

                    if (!this.initialLoadDone) {
                        if (loading && !data && !errors) {
                            this.initialLoadingStream.next(true);
                        } else {
                            this.initialLoadingStream.next(false);
                            this.initialLoadDone = true;
                        }
                    }

                    if (data && Object.keys(data).length > 0 && !loading) {
                        const processedData = this.transformData(data, extraData);
                        this.dataStream.next(processedData.data);

                        if (processedData.totalCount != null) {
                            this.totalCountStream.next(processedData.totalCount);
                        }

                        if (processedData.isEmpty != null) {
                            this.emptyStream.next(processedData.isEmpty);
                        }

                        this.pagination.update(processedData);
                    } else if (!loading) {
                        this.dataStream.next(undefined!); //TODO - shitty hack
                    }
                },
                error: err => {
                    if (!this.initialLoadDone) {
                        this.initialLoadingStream.next(false);
                        this.initialLoadDone = true;
                    }

                    this.loadingStream.next(false);

                    err.message = escape(err.message);

                    this.errorStream.next(err);
                },
            });
    }
}
