import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { map } from 'rxjs/operators';

import { SearchService } from './search.service';

@Injectable({
    providedIn: 'root',
})
export class GoGuard implements CanActivate {
    constructor(private searchService: SearchService) {}

    canActivate(route: ActivatedRouteSnapshot) {
        return this.searchService.search(route.queryParams['domain'], true).pipe(map(() => false));
    }
}
