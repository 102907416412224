import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { ReplaySubject } from 'rxjs';

import { cloneDeep } from 'lodash-es';
import { HardwareWalletSettings } from './models';
import { Configuration } from '../configuration';
import { StorageSchema } from './../utils/storage';

@Injectable({ providedIn: 'root' })
export class HardwareWalletSettingsService {
    private settingsStream = new ReplaySubject<HardwareWalletSettings>(1);

    get activeSettings() {
        return this.settingsStream.asObservable();
    }

    constructor(private storageMap: StorageMap, private configuration: Configuration) {
        this.storageMap.watch(StorageSchema.derivationPath.key, StorageSchema.derivationPath.schema).subscribe(customDerivationPath => {
            const config = cloneDeep(this.configuration.hardwareWallet);

            if (customDerivationPath) {
                config.derivationPath = customDerivationPath;
            }

            this.settingsStream.next(config);
        });
    }

    setDerivationPath(derivationPath: string | null) {
        if (derivationPath && derivationPath !== this.configuration.hardwareWallet.derivationPath) {
            this.storageMap.set(StorageSchema.derivationPath.key, derivationPath, StorageSchema.derivationPath.schema).subscribe();
        } else {
            this.storageMap.delete(StorageSchema.derivationPath.key).subscribe();
        }
    }
}
