import { Component } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'td-latest-expired-domains',
    templateUrl: './latest-expired-domains.component.html',
    styleUrls: ['./latest-expired-domains.component.scss'],
})
export class LatestExpiredDomainsComponent {
    private _loading$ = new ReplaySubject<boolean>(1);
    readonly loading$ = this._loading$.asObservable();

    onDataLoading(loading: boolean) {
        this._loading$.next(loading);
    }
}
