import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TezosService } from '../tezos/tezos.service';
import { TezosWallet } from '../tezos/models';

@Component({
    selector: 'td-dashboard-cta',
    templateUrl: './dashboard-cta.component.html',
    styleUrls: ['./dashboard-cta.component.scss'],
})
export class DashboardCtaComponent implements OnInit, OnDestroy {
    wallet: TezosWallet;

    private unsubscribe = new Subject<void>();

    constructor(private tezosService: TezosService) {}

    ngOnInit(): void {
        this.tezosService.activeWallet.pipe(takeUntil(this.unsubscribe)).subscribe(w => (this.wallet = w!));
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
