import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, ReplaySubject } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
import { OfferOrderField } from '../graphql/graphql.generated';
import { TezosWallet } from '../tezos/models';
import { TezosService } from '../tezos/tezos.service';
import { BuyOfferFilterModel, BuyOfferType } from './buy-offer.models';

@UntilDestroy()
@Component({
    selector: 'td-latest-buy-offers',
    templateUrl: './latest-buy-offers.component.html',
    styleUrls: ['./latest-buy-offers.component.scss'],
})
export class LatestBuyOffersComponent {
    private _loading$ = new ReplaySubject<boolean>(1);
    readonly initialLoading$ = this._loading$.pipe(takeWhile(loading => loading, true));

    filter: BuyOfferFilterModel;

    vm$: Observable<{ wallet: TezosWallet | null }>;

    constructor(public tezosService: TezosService) {
        this.filter = {
            sorting: { label: '', value: OfferOrderField.CreatedAt, direction: 'desc' },
            offerTypes: [BuyOfferType.Sent],
        };

        this.vm$ = this.tezosService.activeWallet.pipe(map(w => ({ wallet: w })));
    }

    onDataLoading(loading: boolean) {
        this._loading$.next(loading);
    }
}
