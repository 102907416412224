<div *transloco="let t" class="d-flex flex-column">
    <mat-form-field *transloco="let t" appearance="standard" class="w-100">
        <mat-label>{{ key }}</mat-label>
        <input matInput [formControl]="innerControl" [placeholder]="t('data-editor.content-url.placeholder')" />
        <mat-error>
            <td-validation-messages [control]="innerControl" [context]="{ name: key }"></td-validation-messages>
        </mat-error>
    </mat-form-field>
    <a [attr.href]="'about/publishing-websites' | landingPageUrl" target="_blank">
        <fa-icon icon="question-circle" class="me-1"></fa-icon>{{ t('data-editor.content-url.faq') }}
    </a>
</div>
