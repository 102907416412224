<div class="smooth-shadow card p-0" *transloco="let t">
    <div class="full-card-width d-flex justify-content-start align-items-center">
        <div class="d-flex flex-grow-1">
            <div class="cta-content m-3 d-flex flex-column gap-4">
                <div>
                    <h1 class="mb-1 mb-lg-2">{{ t('cta.register.title') }}</h1>
                    <h1 class="mb-0">{{ t('cta.register.title2') }}</h1>
                </div>
                <h4 class="txt-secondary mb-0" [tdDynamicHtml]="t('cta.register.text')"></h4>
                <div>
                    <td-action-input
                        inputType="text"
                        buttonType="button"
                        buttonColor="primary"
                        [buttonText]="t('cta.register.button')"
                        buttonIcon="search"
                        [placeholder]="'yourname.' + defaultTld"
                        (action)="go($event)"
                        [value]="search"
                    ></td-action-input>
                </div>
                <a [routerLink]="['/multi-registration']">{{ t('topnav.multi-registration') }}</a>
            </div>
        </div>
        <div class="graphic mt-4 d-none d-sm-flex">
            <img src="/assets/img/register-cta.png" />
        </div>
    </div>
</div>
