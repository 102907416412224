import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { CalendarEvent } from 'calendar-link';
import { Configuration } from '../configuration';
import { Domain, DomainDetailQuery } from '../graphql/graphql.generated';
import { DayjsDatePipe } from '../i18n/dayjs-date.pipe';

type NotificationInput = { name: Domain['name'] } & Partial<DomainDetailQuery['domain']>;

@Injectable({
    providedIn: 'root',
})
export class NotificationEventGeneratorService {
    constructor(private translation: TranslocoService, private dayjsPipe: DayjsDatePipe, private configuration: Configuration) {}

    generate(domain: NotificationInput): CalendarEvent {
        const expiration = this.configuration.expiringDomainThreshold;

        return {
            title: this.translation.translate('domain-detail.notification-event.title'),
            description: this.translation.translate('domain-detail.notification-event.description', {
                name: domain.name,
                date: this.dayjsPipe.transform(domain.expires),
                url: location,
            }),
            start: domain.expires?.subtract(expiration.time, expiration.units).hour(10).minute(0),
            duration: [15, 'minutes'],
        };
    }

    generateBulk(domains: NotificationInput[], url: string): CalendarEvent {
        const expiration = this.configuration.expiringDomainThreshold;

        const expirationDate = domains[0]?.expires;

        return {
            title: this.translation.translate('domain-detail.notification-event-bulk.title'),
            description: this.translation.translate('domain-detail.notification-event-bulk.description', {
                date: this.dayjsPipe.transform(expirationDate),
                url,
            }),
            start: expirationDate?.subtract(expiration.time, expiration.units).hour(10).minute(0),
            duration: [15, 'minutes'],
        };
    }
}
