<td-card-skeleton>
    <div class="d-flex flex-column">
        <div class="d-flex gap-3">
            <ngx-skeleton-loader class="w-100" [theme]="{ height: '56px' }"></ngx-skeleton-loader>
            <ngx-skeleton-loader class="w-100" [theme]="{ height: '56px' }"></ngx-skeleton-loader>
            <ngx-skeleton-loader class="w-100" [theme]="{ height: '56px' }"></ngx-skeleton-loader>
        </div>
        <ng-container *ngFor="let i of [].constructor(rows); let last = last">
            <div class="d-flex gap-3">
                <ngx-skeleton-loader class="w-100" [theme]="{ height: '20px' }"></ngx-skeleton-loader>
                <ngx-skeleton-loader class="w-100" [theme]="{ height: '20px' }"></ngx-skeleton-loader>
                <ngx-skeleton-loader class="w-100" [theme]="{ height: '20px' }"></ngx-skeleton-loader>
            </div>
            <ngx-skeleton-loader *ngIf="!last" [theme]="{ height: '1px' }"></ngx-skeleton-loader>
        </ng-container>

        <ngx-skeleton-loader *ngIf="seeMore" class="w-100" [theme]="{ height: '20px', width: '100px' }"></ngx-skeleton-loader>
    </div>
</td-card-skeleton>
