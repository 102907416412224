import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { getLabel, getTld } from '@tezos-domains/core';
import BigNumber from 'bignumber.js';
import { Configuration } from '../../configuration';
import { MediaObserver } from '../../shared/media-observer.service';

@Component({
    selector: 'td-bulk-domain-item',
    templateUrl: './bulk-domain-item.component.html',
    styleUrls: ['./bulk-domain-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkDomainItemComponent {
    media = inject(MediaObserver);
    configuration = inject(Configuration);

    name: string;
    label: string;
    tld: string;

    @Input() set domainName(value: string | undefined | null) {
        this.name = value || '???';
        this.label = getLabel(this.name);
        this.tld = getTld(this.name);
    }
    @Input() price: BigNumber;
    @Input() buttonText: string;
    @Input() buttonDisabled?: boolean;

    @Input() canSelect: boolean;
    @Input() selected: boolean;

    @Output() selectionChange = new EventEmitter<boolean>();

    toggle(evt: MatCheckboxChange): void {
        this.selectionChange.next(evt.checked);
    }
}
