import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TransactionWalletOperation, WalletOperation, WalletTransferParams } from '@taquito/taquito';
import { RecordMetadata, getLabel, getParent } from '@tezos-domains/core';
import { TezosDomainsOperationFactory } from '@tezos-domains/manager';
import { TaquitoTezosDomainsClient } from '@tezos-domains/taquito-client';
import { Observable } from 'rxjs';
import { DomainDetailQuery } from '../graphql/graphql.generated';
import { OperationStatusDoneEvent } from '../shared/operation-status.component';
import { TezosWallet } from '../tezos/models';
import { SmartContractOperationEvent, TezosService } from '../tezos/tezos.service';
import { dataArrayToObj, emptyStringToNull } from '../utils/convert';
import { isOperator } from './utils/is-operator';

@UntilDestroy()
@Component({
    selector: 'td-transfer-ownership',
    templateUrl: './transfer-ownership.component.html',
    styleUrls: ['./transfer-ownership.component.scss'],
})
export class TransferOwnershipComponent implements OnInit {
    form: FormGroup<{
        owner: FormControl<string>;
        clearAddress: FormControl<boolean>;
    }>;

    operation: Observable<SmartContractOperationEvent> | null;
    wallet: TezosWallet;

    domain: NonNullable<DomainDetailQuery['domain']>;
    transferConfirmationPending = false;

    get isDifferentNewOwner() {
        const owner = this.form.value.owner;
        return owner && owner !== this.domain.owner;
    }

    constructor(
        private formBuilder: NonNullableFormBuilder,
        private tezosService: TezosService,
        @Inject(MAT_DIALOG_DATA) private data: { domain: NonNullable<DomainDetailQuery['domain']> },
        private dialogRef: MatDialogRef<TransferOwnershipComponent>
    ) {}

    ngOnInit(): void {
        this.domain = this.data.domain;
        this.tezosService.activeWallet.pipe(untilDestroyed(this)).subscribe(w => (this.wallet = w!));

        this.form = this.formBuilder.group({
            owner: this.formBuilder.control(''),
            clearAddress: this.formBuilder.control(Boolean(this.domain.address), { validators: [Validators.required] }),
        });
    }

    transfer() {
        const params = {
            owner: this.form.value.owner!,
            address: emptyStringToNull(this.domain.address),
            data: new RecordMetadata(dataArrayToObj(this.domain.data)),
        };

        if (this.domain.owner === this.wallet.address || isOperator(this.domain, this.wallet.address)) {
            if (this.domain.tokenId) {
                this.execute(client => {
                    return client.manager.batch(async b => {
                        const ops: WalletTransferParams[] = [];

                        if (this.form.value.clearAddress) {
                            const addressOp = await this.removeAddress(b, this.domain);
                            ops.push(addressOp);
                        }

                        const transferOp = await b.transfer(this.domain.name, params.owner);
                        ops.push(transferOp);

                        return ops;
                    });
                });
            } else {
                this.execute(client =>
                    client.manager.updateRecord({
                        ...params,
                        address: this.form.value.clearAddress ? null : params.address,
                        name: this.domain.name,
                    })
                );
            }
        } else {
            this.execute(client =>
                client.manager.setChildRecord({
                    ...params,
                    address: this.form.value.clearAddress ? null : params.address,
                    label: getLabel(this.domain.name),
                    parent: getParent(this.domain.name),
                })
            );
        }
    }

    requiresTransferConfirmation(value: boolean) {
        this.transferConfirmationPending = value;
    }

    operationDone(event: OperationStatusDoneEvent) {
        if (event.success) {
            this.dialogRef.close(true);
        } else {
            this.form.enable();
        }
    }

    cancel() {
        this.dialogRef.close(false);
    }

    private removeAddress(builder: TezosDomainsOperationFactory<WalletTransferParams>, domain: NonNullable<DomainDetailQuery['domain']>) {
        return builder.updateRecord({
            address: null,
            data: new RecordMetadata(dataArrayToObj(domain.data)),
            name: domain.name,
            owner: domain.owner,
        });
    }

    private execute(op: (client: TaquitoTezosDomainsClient) => Promise<TransactionWalletOperation | WalletOperation>) {
        this.form.disable();

        const operation = this.tezosService.execute(op);

        this.operation = operation;
    }
}
