<form class="d-flex flex-fill flex-column h-100 gap-1" [formGroup]="form" *transloco="let t">
    <h1 mat-dialog-title class="text-truncate mb-0 flex-shrink-0">{{ t('bulk-transfer.transfer-multiple-domains', { count: domains.length }) }}</h1>
    <td-operation-status class="flex-shrink-0" [operation]="operation" (done)="operationDone($event)" #op></td-operation-status>
    <td-recipient
        [control]="$any(form.get('owner'))"
        [required]="true"
        [label]="t('fields.new-owner')"
        [placeholder]="t('fields.new-owner-placeholder')"
        [noKT]="true"
        #recipient
    ></td-recipient>
    <ng-container *ngIf="true">
        <mat-slide-toggle formControlName="clearAddress">
            <span>{{ t('bulk-transfer.clear-address-field') }}</span>
        </mat-slide-toggle>
        <td-alert state="warning" *ngIf="!form.get('clearAddress')!.value">
            <div [tdDynamicHtml]="t('bulk-transfer.clear-address-warning')"></div>
        </td-alert>
    </ng-container>

    <td-transfer-warning
        *ngIf="form.valid"
        [domains]="domains"
        [wallet]="wallet"
        [newOwner]="form.value.owner"
        [recipient]="recipient.rawValue"
        (requireTransferConfirmation)="requiresTransferConfirmation($event)"
    ></td-transfer-warning>

    <mat-dialog-content class="flex-grow-1">
        <div class="d-flex flex-column h-100 gap-1">
            <mat-list dense>
                <mat-list-item *ngFor="let item of domains">
                    <div class="d-flex w-100 gap-1">
                        <div
                            [matTooltip]="item.name"
                            [matTooltipDisabled]="item.name.length <= 30"
                            class="flex-grow-1 overflow-hidden js-domain-name"
                            [ngClass]="{ 'no-underline': item.name.length <= 30 }"
                        >
                            {{ item.name | truncate : 30 }}
                        </div>
                    </div>
                </mat-list-item>
            </mat-list>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex justify-content-end align-items-end flex-column gap-3 w-100 flex-nowrap min-height-auto">
        <div class="d-flex justify-content-end align-items-end w-100">
            <button mat-flat-button color="primary" (click)="transfer()" [disabled]="form.invalid || op.inProgress || transferConfirmationPending">
                {{ t('actions.transfer') }}
            </button>
            <button mat-button (click)="cancel()" [disabled]="form.disabled">{{ t('actions.cancel') }}</button>
        </div>
    </mat-dialog-actions>
</form>
