import { EventsRecord } from '../graphql/events-table-data-source';
import { EventType } from '../graphql/graphql.generated';

export function getEventType(event: EventsRecord, address?: string) {
    let alt = false;

    if ('isNew' in event) {
        alt = event.isNew;
    }

    if (address) {
        if (
            [
                EventType.AuctionBidEvent,
                EventType.AuctionEndEvent,
                EventType.DomainTransferEvent,
                EventType.OfferExecutedEvent,
                EventType.BuyOfferExecutedEvent,
            ].includes(event.type) &&
            event.sourceAddress !== address
        ) {
            alt = true;
        }
    }

    if ([EventType.ReverseRecordClaimEvent, EventType.ReverseRecordUpdateEvent].includes(event.type) && 'name' in event && !event.name) {
        alt = true;
    }

    return event.type + (alt ? '_ALT' : '');
}
