import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TezosToolkit } from '@taquito/taquito';
import { TaquitoTezosDomainsClient } from '@tezos-domains/taquito-client';
import BigNumber from 'bignumber.js';
import { combineLatest, from, Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DomainNameValidationResult } from '@tezos-domains/core';
import { OperationStatusDoneEvent } from '../shared/operation-status.component';
import { TezosDomainsClientService } from '../tezos/integration/tezos-domains-client.service';
import { TezosToolkitService } from '../tezos/integration/tezos-toolkit.service';
import { TezosWallet } from '../tezos/models';
import { SmartContractOperationEvent, TezosService } from '../tezos/tezos.service';
import { TdAsyncValidatorsFactory } from '../utils/form-validators';

@UntilDestroy()
@Component({
    selector: 'td-wallet-send',
    templateUrl: './wallet-send.component.html',
    styleUrls: ['./wallet-send.component.scss'],
})
export class WalletSendComponent implements OnInit {
    tezos: TezosToolkit;
    tezosDomains: TaquitoTezosDomainsClient;
    wallet: TezosWallet;
    balance: BigNumber;
    form: FormGroup<{
        to: FormControl<string>;
        amount: FormControl<number>;
    }>;
    operation: Observable<SmartContractOperationEvent> | null;
    resolvedAddress: string | null;

    constructor(
        private tezosToolkitService: TezosToolkitService,
        private tezosDomainsClientService: TezosDomainsClientService,
        private tezosService: TezosService,
        private formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<WalletSendComponent>,
        private asyncValidatorsFactory: TdAsyncValidatorsFactory
    ) {}

    ngOnInit(): void {
        combineLatest([this.tezosToolkitService.current, this.tezosService.activeWallet, this.tezosDomainsClientService.current])
            .pipe(untilDestroyed(this))
            .subscribe(([tezos, wallet, tezosDomains]) => {
                this.tezos = tezos;
                this.tezosDomains = tezosDomains;
                if (wallet) {
                    this.wallet = wallet;
                    this.load();
                }
            });
    }

    async load() {
        this.balance = await this.tezos.rpc.getBalance(this.wallet.address);

        this.form = this.formBuilder.group({
            to: this.formBuilder.control('', {
                nonNullable: true,
                validators: [Validators.required],
                asyncValidators: [this.asyncValidatorsFactory.validRecipient()],
            }),
            amount: this.formBuilder.control(0, {
                nonNullable: true,
                validators: [Validators.required, Validators.min(0.000001), Validators.max(this.balance.dividedBy(1e6).toNumber())],
            }),
        });

        this.form
            .get('to')!
            .statusChanges.pipe(
                untilDestroyed(this),
                switchMap(status => {
                    switch (status) {
                        case 'VALID':
                            const value = this.form.get('to')!.value;
                            if (this.tezosDomains.validator.isValidWithKnownTld(value) === DomainNameValidationResult.VALID) {
                                return from(this.tezosDomains.resolver.resolveNameToAddress(value));
                            }

                            return of(null);
                        case 'PENDING':
                        case 'INVALID':
                            return of(null);
                        case 'DISABLED':
                            return of(this.resolvedAddress);
                        default:
                            return throwError(new Error(`Unknown control status ${status}`));
                    }
                })
            )
            .subscribe(to => (this.resolvedAddress = to));
    }

    send() {
        const to = this.resolvedAddress || this.form.value.to!;
        const amount = this.form.value.amount!;

        this.form.disable();

        this.operation = this.tezosService.execute(() => this.tezos.wallet.transfer({ to, amount }).send());
    }

    operationDone(event: OperationStatusDoneEvent) {
        if (event.success) {
            this.dialogRef.close(true);
        } else {
            this.form.enable();
        }
    }

    cancel() {
        this.dialogRef.close(false);
    }
}
