import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../shared/shared.module';
import { FileDragComponent } from './file-drag.component';

@NgModule({
    declarations: [FileDragComponent],
    imports: [SharedModule, BrowserModule],
    exports: [FileDragComponent],
})
export class FileProcessingModule {}
