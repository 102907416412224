import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import dayjs, { Dayjs } from 'dayjs';
import { map, tap } from 'rxjs/operators';
import { DateFilter } from './model';
import { MediaObserver } from '@/shared/media-observer.service';

@UntilDestroy()
@Component({
    selector: 'td-date-filter',
    templateUrl: './date-filter.component.html',
    styleUrls: ['./date-filter.component.scss'],
})
export class DateFilterComponent implements OnInit {
    @Input() min: Dayjs;
    @Input() max?: Dayjs;

    @Input() initialFilter: DateFilter | null;

    @Output() dateRangeChange = new EventEmitter<DateFilter | null>();

    form: UntypedFormGroup;
    model: DateFilter | null;
    showTouchUi = false;

    display: 'noFilter' | 'startDate' | 'interval' = 'noFilter';

    constructor(private formBuilder: UntypedFormBuilder, private media: MediaObserver) {
        media
            .asObservable()
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.showTouchUi = this.media.isActive('lt-md');
            });
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            from: this.formBuilder.control(''),
            to: this.formBuilder.control(''),
        });

        if (this.initialFilter?.from || this.initialFilter?.to) {
            this.form.get('from')?.setValue(this.initialFilter.from?.toDate());
            this.form.get('to')?.setValue(this.initialFilter.to?.toDate());

            this.setDisplayMode(this.initialFilter);
            this.model = { ...this.initialFilter };
        }

        this.form.valueChanges
            .pipe(
                map(data => {
                    if (!data.from && !data.to) {
                        return null;
                    }

                    return {
                        from: data.from ? dayjs(data.from) : this.min,
                        to: data.to ? dayjs(data.to) : this.max,
                    };
                }),
                tap(data => this.setDisplayMode(data)),
                untilDestroyed(this)
            )
            .subscribe(model => {
                this.model = model;
                this.dateRangeChange.next(model);
            });
    }

    clearDateRange(): void {
        this.form.reset();
        this.form.get('from')?.setValue('');
        this.form.get('to')?.setValue('');
    }

    private setDisplayMode(data: DateFilter | null): void {
        if (!data) {
            this.display = 'noFilter';
            return;
        }

        const noMaxDate = !data.to && !this.max;
        const maxDateSelected = data.to && this.max && data.to.isSame(this.max);

        if (data.from.isSame(this.min) && (!noMaxDate || maxDateSelected)) {
            this.display = 'noFilter';
            return;
        }

        if (data.from && !data.to) {
            this.display = 'startDate';
        } else {
            this.display = 'interval';
        }
    }
}
