import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NotificationsService, SubscribeRequest } from './notifications.service';

@Component({
    selector: 'td-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit, OnDestroy {
    key: string;
    status: 'working' | 'success' | 'error' | 'KeyExpired' | 'KeyNotFound';
    data: SubscribeRequest;

    private unsubscribe = new Subject<void>();

    constructor(private mailApiService: NotificationsService, private activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {
        this.key = this.activatedRoute.snapshot.queryParams['key'];
        this.status = 'working';
        this.mailApiService
            .confirm(this.key)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(r => {
                if (r.status === 'OK') {
                    this.status = 'success';
                    this.data = r.data;
                } else {
                    if (r.failReason) {
                        this.status = <any>r.failReason;
                    } else {
                        this.status = 'error';
                    }
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
