<ng-container *transloco="let t">
    <div class="d-flex flex-column gap-3">
        <td-alert state="warning" *ngIf="transferConfirmRequired && isDifferentNewOwner">
            <div class="d-flex flex-column gap-2">
                <ng-container *ngIf="!transferOwnerConfirmed">
                    <div>{{ t('general.owner-transfer-warning') }}</div>
                    <td-action-input
                        buttonType="button"
                        [buttonText]="t('actions.confirm')"
                        inputType="text"
                        [placeholder]="t('general.enter-confirm-phrase')"
                        (action)="confirmTransfer($event)"
                        buttonColor="primary"
                    ></td-action-input>
                </ng-container>
                <div *ngIf="transferOwnerConfirmed">{{ t('general.owner-transfer-confirmed') }}</div>
            </div>
        </td-alert>
        <td-alert state="info" *ngIf="isDifferentNewOwner && !transferConfirmRequired">
            {{ t('general.owner-transfer-still-controls-info') }}
        </td-alert>
        <td-alert state="danger" *ngIf="newOwner && !isDifferentNewOwner">
            {{ t('general.owner-transfer-same-owner') }}
        </td-alert>
    </div>
</ng-container>
