import { Component } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { TezosService } from '../tezos/tezos.service';
import { ClaimInfoService } from './claim-info.service';

@Component({
    selector: 'td-unclaimed-tokens-announcement',
    templateUrl: './unclaimed-tokens-announcement.component.html',
    styleUrls: ['./unclaimed-tokens-announcement.component.scss'],
})
export class UnclaimedTokensAnnouncementComponent {
    vm$: Observable<{ hasVestingTokensToClaim: boolean; address: string } | null>;

    constructor(claimInfo: ClaimInfoService, tezosService: TezosService) {
        this.vm$ = tezosService.activeWallet.pipe(
            switchMap(w => {
                if (!w) {
                    return of(null);
                }

                return claimInfo.canClaimTokens(w.address);
            }),
            map(state => {
                if (!state) {
                    return null;
                }

                const hasVestingTokensToClaim = (state.amountClaimed.gt(0) && state.amountClaimable.gt(0)) ?? false;
                return { hasVestingTokensToClaim: hasVestingTokensToClaim, address: state.owner };
            }),
            shareReplay({ refCount: true, bufferSize: 1 })
        );
    }
}
