import { Component, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { first, switchMap, takeUntil } from 'rxjs/operators';
import { AppService } from '../app-service';
import { DomainListRecord } from '../graphql/domain-table-data-source';
import { DomainOrderField } from '../graphql/graphql.generated';
import { Stats } from '../graphql/stats-data-source';
import { TezosService } from '../tezos/tezos.service';
import { BulkRenewComponent } from './bulk-renew.component';
import { BulkTransferComponent } from './bulk-transfer.component';
import { DomainListSort, DomainTableComponent, DomainTableFilterCategory } from './domain-table.component';
import { ExpiringDomainsStatsService } from './expiring-domains-stats.service';

@Component({
    selector: 'td-domain-list',
    templateUrl: './domain-list.component.html',
    styleUrls: ['./domain-list.component.scss'],
})
export class DomainListComponent implements OnInit, OnDestroy {
    selectedDomains: DomainListRecord[] = [];
    initialSelectedCategory: DomainTableFilterCategory = 'all';
    crtSelectedCategory: DomainTableFilterCategory = 'all';
    totalDomains = 0;

    sort?: DomainListSort;

    @ViewChild(DomainTableComponent) domainTable: DomainTableComponent;

    expirationStats: Stats | null = null;

    private unsubscribe = new Subject<void>();
    private address$ = new Subject<string>();

    sortingPerCategory: { [key in DomainTableFilterCategory as string]: DomainListSort } = {
        expiring: { field: DomainOrderField.ExpiresAt, direction: 'asc' },
    };

    titles = {
        all: this.translocoService.translate('domain-list.title-my-domains'),
        direct: this.translocoService.translate('domain-list.title-my-domains-direct'),
        expiring: this.translocoService.translate('domain-list.title-my-domains-expiring'),
    };

    constructor(
        private dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        public tezosService: TezosService,
        private expiringStatsService: ExpiringDomainsStatsService,
        private translocoService: TranslocoService,
        private appService: AppService
    ) {}

    ngOnInit(): void {
        this.address$
            .pipe(
                switchMap(address => this.expiringStatsService.expiringLaterStatsFor$(address)),
                takeUntil(this.unsubscribe)
            )
            .subscribe(stats => {
                this.expirationStats = stats;
                this.setTotalDomainCount();
            });

        if (this.activatedRoute.snapshot.queryParams['expiring']) {
            this.crtSelectedCategory = this.initialSelectedCategory = 'expiring';
            this.tezosService.activeWallet.pipe(first()).subscribe(w => {
                if (!w) {
                    setTimeout(() => this.appService.openConnect(), 250);
                }
            });
        }

        this.setTotalDomainCount();
    }

    addressChange(address: string) {
        this.address$.next(address);
    }

    domainsSelected(domains: DomainListRecord[]): void {
        this.selectedDomains = domains;
    }

    selectedCategoryChanged(domains: DomainTableFilterCategory): void {
        this.crtSelectedCategory = domains;
        this.setTotalDomainCount();
    }

    startBulkRenew(): void {
        this.openDialog(BulkRenewComponent, { data: { domains: this.selectedDomains } }).subscribe(success => {
            if (success) {
                this.domainTable.clearSelection();
                this.domainTable.reload();
            }
        });
    }

    startBulkTransfer(): void {
        this.openDialog(BulkTransferComponent, { data: { domains: this.selectedDomains } }).subscribe(success => {
            if (success) {
                this.domainTable.clearSelection();
                this.domainTable.reload();
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private setTotalDomainCount(): void {
        this.totalDomains = (this.crtSelectedCategory === 'expiring' ? this.expirationStats?.expiring : this.expirationStats?.bought) || 0;
    }

    private openDialog(component: Type<any>, config: MatDialogConfig) {
        config.panelClass = 'big-dialog-pane';
        const modal = this.dialog.open(component, config);

        return modal.afterClosed();
    }
}
