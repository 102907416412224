import { Component, Input, inject } from '@angular/core';
import { Configuration } from '../configuration';
import { PoolStats } from './models';

@Component({
    selector: 'td-modal-apr',
    templateUrl: './modal-apr.component.html',
    styleUrls: ['./modal-apr.component.scss'],
})
export class ModalAprComponent {
    private readonly config = inject(Configuration);

    @Input() stats: PoolStats;

    showAverageApr = this.config.governance.showAverageApr;
}
