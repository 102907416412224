import { Component } from '@angular/core';
import { TdValidators } from '../../../utils/form-validators';
import { EditorComponentBase } from './editor-component-base';

@Component({
    selector: 'td-content-url',
    templateUrl: './content-url.component.html',
    styleUrls: ['./content-url.component.scss'],
})
export class ContentUrlComponent extends EditorComponentBase<any> {
    protected createValidators() {
        return [TdValidators.contentUrl];
    }
}
