import { NgModule } from '@angular/core';
import { AirdropModule } from '../airdrop/airdrop.module';

import { AuctionsModule } from '../auctions/auctions.module';
import { BuyOffersModule } from '../buy-offers/buy-offers.module';
import { DomainsModule } from '../domains/domains.module';
import { EventsModule } from '../events/events.module';
import { FavouritesModule } from '../favourites/favourites.module';
import { GovPoolModule } from '../gv-pool/gov-pool.module';
import { LayoutModule } from '../layout/layout.module';
import { OffersModule } from '../offers/offers.module';
import { ReverseRecordsModule } from '../reverse-records/reverse-records.module';
import { SharedModule } from '../shared/shared.module';
import { SkeletonModule } from '../skeleton/skeleton.module';
import { AddressDetailComponent } from './address-detail.component';
import { AuctionFundsComponent } from './auction-funds.component';
import { TitleComponent } from './title.component';
import { WithdrawComponent } from './withdraw.component';

@NgModule({
    declarations: [AddressDetailComponent, AuctionFundsComponent, WithdrawComponent, TitleComponent],
    imports: [
        SharedModule,
        LayoutModule,
        DomainsModule,
        ReverseRecordsModule,
        AuctionsModule,
        SkeletonModule,
        EventsModule,
        OffersModule,
        BuyOffersModule,
        AirdropModule,
        GovPoolModule,
        FavouritesModule,
    ],
})
export class AddressModule {}
