<ng-container *ngIf="vm$ | async as vm">
    <div *transloco="let t" class="card smooth-shadow home-item d-flex flex-column" [hidden]="vm.hidden" [ngClass]="{ empty: vm.hidden }">
        <div class="d-flex w-100 mb-2">
            <h2 class="flex-grow-1 mb-0">
                {{ t('gvPool.delegate-info.title') }}
            </h2>
        </div>
        <div class="stats my-2 px-3" *ngIf="vm.delegate">
            <div class="stats-item">
                <div>{{ t('gvPool.delegate-info.voting-power') }}</div>
                <div class="stats-item-value">{{ vm.delegate.votes | formatMutez }}</div>
            </div>
            <div class="stats-item">
                <div>{{ t('gvPool.delegate-info.delegating-accounts') }}</div>
                <div class="stats-item-value">{{ vm.delegate.delegatorCount }}</div>
            </div>
            <div class="stats-item">
                <div>{{ t('gvPool.delegate-info.total-pool-votes') }}</div>
                <div class="stats-item-value">{{ vm.totalSupply | formatMutez }}</div>
            </div>
        </div>
        <div class="stats delegator my-2 px-3" *ngIf="vm.delegator">
            <div class="stats-item">
                <div>
                    {{ t('gvPool.delegate-info.delegating-votes', { votes: vm.delegator.delegatedVotes | formatMutez }) }}
                </div>
                <td-address
                    [route]="['/address', vm.delegator.to.address, 'delegators']"
                    [address]="vm.delegator.to.address"
                    [shorten]="false"
                    [resolveReverseRecord]="true"
                ></td-address>
            </div>
            <div class="stats-item">
                <div>{{ t('gvPool.delegate-info.total-pool-votes') }}</div>
                <div class="stats-item-value">{{ vm.totalSupply | formatMutez }}</div>
            </div>
        </div>
        <div class="d-flex justify-content-start justify-content-sm-end w-100 mt-3">
            <a [routerLink]="['/governance', 'delegates-board']">
                <fa-icon class="gov-icon ranking-star pe-1" icon="ranking-star" size="lg"></fa-icon>
                {{ t('gvPool.widget.delegate-dashboard') }}
            </a>
        </div>
    </div>

    <ng-template #loading>
        <div class="w-100 flex-grow-1">
            <div class="d-flex flex-column justify-content-center align-items-center h-100">
                <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
            </div>
        </div>
    </ng-template>
</ng-container>
