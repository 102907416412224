import { OnInit, Input, OnDestroy, Directive } from '@angular/core';
import { AbstractControl, UntypedFormControl, Validators, ValidatorFn } from '@angular/forms';
import { RpcResponseData, JsonBytesEncoder, RpcRequestData } from '@tezos-domains/core';
import { Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class EditorComponentBase<TArgs> implements OnInit, OnDestroy {
    @Input() key: string;
    @Input() control: AbstractControl;
    @Input() args?: TArgs;

    innerControl: UntypedFormControl;

    protected unsubscribe = new Subject<void>();

    ngOnInit(): void {
        const validators = [Validators.required, ...this.createValidators()];

        this.innerControl = new UntypedFormControl(this.decode(this.control.value), validators);

        this.innerControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
            if (this.innerControl.valid) {
                this.control.setValue(this.encode(value));
            }
        });

        this.innerControl.statusChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            if (this.innerControl.invalid) {
                this.control.setErrors([{ innerValueInvalid: true }]);
            } else {
                this.control.setErrors(null);
            }
        });

        this.control.valueChanges.pipe(takeUntil(this.unsubscribe), distinctUntilChanged()).subscribe(value => {
            this.innerControl.setValue(this.decode(value), { emitEvent: false });
        });

        this.control.statusChanges.pipe(takeUntil(this.unsubscribe)).subscribe(s => {
            if (s === 'DISABLED') {
                this.innerControl.disable({ emitEvent: false });
            } else {
                this.innerControl.enable({ emitEvent: false });
            }
        });

        setTimeout(() => {
            if (this.innerControl.invalid) {
                this.control.setErrors([{ innerValueInvalid: true }]);
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    protected decode(rawValue: string) {
        return new RpcResponseData(rawValue).scalar(JsonBytesEncoder);
    }

    protected encode(value: any) {
        return RpcRequestData.fromValue(value, JsonBytesEncoder).encode();
    }

    protected createValidators(): ValidatorFn[] {
        return [];
    }
}
