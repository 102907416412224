import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DomainNameValidationResult, getLevel, getTld } from '@tezos-domains/core';
import { DomainAcquisitionInfo, DomainAcquisitionState } from '@tezos-domains/manager';
import { TLDConfiguration } from '@tezos-domains/manager/dist/src/manager/model';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { first, map, switchMap } from 'rxjs/operators';
import { PageService } from '../browser/page.service';
import { BuyOfferRecord } from '../graphql/buy-offer-table-data-source';
import { CurrentBuyOfferDataSource } from '../graphql/current-buy-offer-data-source';
import { DataSourceFactory } from '../graphql/data-source-factory';
import { DomainDetailDataSource } from '../graphql/domain-detail-data-source';
import { DomainDetailQuery, OfferListQueryVariables, OfferOrderField, OfferState, OffersFilter } from '../graphql/graphql.generated';
import { OfferRecord, OfferTableDataSource } from '../graphql/offer-table-data-source';
import { OverlayService } from '../overlay.service';
import { TezosDomainsClientService } from '../tezos/integration/tezos-domains-client.service';
import { TldConfigurationService } from '../tezos/integration/tld-configuration.service';
import { TezosWallet } from '../tezos/models';
import { TezosService } from '../tezos/tezos.service';
import { SearchService } from '../utils/search.service';
import { getExistingAuction } from './utils/aquisition-info';

interface ExatchMatchData {
    price: BigNumber;
    seller: string;
    expiration?: dayjs.Dayjs;
    buttonText: string;
    isTaken?: boolean;
    buttonHelp?: string;
    buttonAction: () => void;
    buttonDisabled?: boolean;
}
@UntilDestroy()
@Component({
    selector: 'td-search-results',
    templateUrl: './search-results.component.html',
    styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent implements OnInit {
    private currentBuyOfferDataSource: CurrentBuyOfferDataSource;

    offersDataSource: OfferTableDataSource;
    domainDataSource: DomainDetailDataSource;

    name: string;
    term: string;
    domain: DomainDetailQuery['domain'] | null;
    auction: DomainDetailQuery['auction'] | null;
    exactMatch: ExatchMatchData | null;
    acquisitionInfo?: DomainAcquisitionInfo;
    states = DomainAcquisitionState;

    nodeError: boolean;
    wallet: TezosWallet | null;
    buyOffer?: BuyOfferRecord | null;

    @ViewChild(InfiniteScrollDirective) infiniteScroll: InfiniteScrollDirective;

    get claimedByOther(): boolean {
        return this.acquisitionInfo?.acquisitionState === DomainAcquisitionState.CanBeClaimed && this.domain?.owner !== this.wallet?.address;
    }

    constructor(
        private dataSourceFactory: DataSourceFactory,
        private pageService: PageService,
        private overlayService: OverlayService,
        private activatedRoute: ActivatedRoute,
        private searchService: SearchService,
        private tezosDomainsClientService: TezosDomainsClientService,
        private router: Router,
        private tldConfigurationService: TldConfigurationService,
        private tezosService: TezosService,
        private transloco: TranslocoService
    ) {}

    ngOnInit(): void {
        this.tezosService.activeWallet.pipe(untilDestroyed(this)).subscribe(w => (this.wallet = w));

        this.offersDataSource = this.dataSourceFactory.createOfferTableDataSource();
        this.domainDataSource = this.dataSourceFactory.createDomainDetailDataSource();

        this.currentBuyOfferDataSource = this.dataSourceFactory.createCurrentBuyOfferDataSource();
        this.currentBuyOfferDataSource.data$.pipe(untilDestroyed(this)).subscribe(buyOffer => (this.buyOffer = buyOffer.currentBuyOffer));

        this.domainDataSource.data$.pipe(untilDestroyed(this)).subscribe(data => {
            if (data) {
                this.domain = data.domain;
                this.auction = data.auction;

                const tld = getTld(this.name);
                this.tldConfigurationService.get(tld).subscribe(tldConfig => {
                    this.acquisitionInfo = this.tezosDomainsClientService.calculateAcquisitionInfo({
                        name: this.name,
                        tldConfiguration: tldConfig,
                        existingAuction: getExistingAuction(this.auction ?? data.lastAuction.edges[0]?.node),
                        existingDomain: data.domain ? { expiry: data.domain.expires?.toDate() || null } : undefined,
                    });

                    this.setExactMatchData(tldConfig);
                });

                if (this.domain && this.wallet) {
                    this.currentBuyOfferDataSource.load({ name: this.domain.name, address: this.wallet.address });
                }
            } else {
                this.domain = null;
                this.auction = null;
            }
        });

        this.activatedRoute.queryParams
            .pipe(
                untilDestroyed(this),
                map(p => p['q']),
                switchMap((q: string) =>
                    this.tezosDomainsClientService.current.pipe(
                        map(client => this.searchService.sanitizeDomainName(client, q)),
                        map(s => ({ term: q, sanitizedTerm: s }))
                    )
                )
            )
            .subscribe(search => {
                if (search.term) {
                    this.term = search.term;
                    this.pageService.setTitle('search', { term: search.term });
                    this.load(search);
                } else {
                    this.router.navigate(['/']);
                }
            });
    }

    executeOffer(offer: OfferRecord) {
        this.overlayService.openExecuteOffer(offer, () => this.offersDataSource.reload());
    }

    scrolled() {
        this.offersDataSource.loadMore();
    }

    load(search: { term: string; sanitizedTerm: string }) {
        this.domain = null;
        this.auction = null;
        this.exactMatch = null;

        this.tezosDomainsClientService.current
            .pipe(
                map(c => getLevel(search.term) === 2 && c.validator.isValidWithKnownTld(search.term) === DomainNameValidationResult.VALID),
                first()
            )
            .subscribe(validDomainTerm => {
                this.name = validDomainTerm ? search.term.toLowerCase() : search.sanitizedTerm;

                this.domainDataSource.load({ name: this.name });
            });

        if (this.infiniteScroll) {
            this.infiniteScroll.destroyScroller();
            this.infiniteScroll.setup();
        }

        const where: OffersFilter = {
            state: { in: [OfferState.Active] },
            domainName: { like: search.term },
        };

        const variables: OfferListQueryVariables = {
            where,
            order: { field: OfferOrderField.CreatedAt },
        };

        this.offersDataSource.load(variables);
    }

    private setExactMatchData(tldConfig: TLDConfiguration) {
        const data: Partial<ExatchMatchData> = {};

        data.seller = 'TD';
        data.buttonAction = () => this.goToDetail();

        switch (this.acquisitionInfo?.acquisitionState) {
            case DomainAcquisitionState.CanBeClaimed:
                /**
                 * t(actions.claimed)
                 * t(actions.claim)
                 */
                data.buttonText = this.domain ? 'actions.claimed' : 'actions.claim';
                data.price = tldConfig.claimPrice;
                data.buttonHelp = this.transloco.translate('dns-bridge.claim-search-help', { name: this.name });
                break;
            case DomainAcquisitionState.CanBeBought:
                const pricePerYear = new BigNumber(this.acquisitionInfo.calculatePrice(365));
                data.price = pricePerYear;

                data.buttonText = 'actions.register';
                break;
            case DomainAcquisitionState.Taken:
            case DomainAcquisitionState.CanBeSettled:
                data.buttonText = 'general.taken';
                data.buttonDisabled = true;
                data.isTaken = true;
                break;
            case DomainAcquisitionState.CanBeAuctioned:
            case DomainAcquisitionState.AuctionInProgress:
                data.buttonText = 'actions.go-to-auction';
                data.expiration = dayjs(this.acquisitionInfo.auctionDetails.auctionEnd);
                data.price = new BigNumber(this.acquisitionInfo.auctionDetails.nextMinimumBid);
                break;
            case DomainAcquisitionState.Unobtainable:
                data.buttonText = 'general.not-available';
                data.buttonDisabled = true;
                break;
        }

        this.exactMatch = data as any;
    }

    private goToDetail() {
        const queryParams: any = {};
        if (this.claimedByOther) {
            queryParams['tryClaim'] = true;
        }

        this.router.navigate(['/domain', this.name], { queryParams });
    }
}
