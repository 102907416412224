import { Component } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { TezosService } from './../tezos/tezos.service';
import { ClaimInfoService } from './claim-info.service';
import { ClaimStatus } from './models';

@Component({
    selector: 'td-airdrop-announcement',
    templateUrl: './airdrop-announcement.component.html',
    styleUrls: ['./airdrop-announcement.component.scss'],
})
export class AirdropAnnouncementComponent {
    vm$: Observable<boolean>;

    constructor(claimInfo: ClaimInfoService, tezosService: TezosService) {
        this.vm$ = tezosService.activeWallet.pipe(
            switchMap(w => {
                if (!w) {
                    return of(null);
                }

                return claimInfo.canClaimTokens(w.address);
            }),
            map(state => state?.status === ClaimStatus.Unclaimed),
            shareReplay({ refCount: true, bufferSize: 1 })
        );
    }
}
