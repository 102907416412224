import { Component, OnInit } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReverseRecord, ReverseRecordService } from '../domains/reverse-record.service';
import { MediaObserver } from '../shared/media-observer.service';
import { TezosWallet } from '../tezos/models';
import { TezosService } from '../tezos/tezos.service';

@UntilDestroy()
@Component({
    selector: 'td-account-widget',
    templateUrl: './account-widget.component.html',
    styleUrls: ['./account-widget.component.scss'],
})
export class AccountWidgetComponent implements OnInit {
    wallet: TezosWallet;
    reverseRecord: ReverseRecord | null;

    constructor(private tezosService: TezosService, private reverseRecordService: ReverseRecordService, public media: MediaObserver) {}

    ngOnInit(): void {
        this.tezosService.activeWallet.pipe(untilDestroyed(this)).subscribe(w => (this.wallet = w!));
        this.reverseRecordService.current.pipe(untilDestroyed(this)).subscribe(r => (this.reverseRecord = r));
    }
}
