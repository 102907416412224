import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TezosWallet } from '../tezos/models';
import { TezosService } from '../tezos/tezos.service';
import { StorageSchema } from '../utils/storage';
import { NotificationsService, NotificationsSubscription } from './notifications.service';

@Component({
    selector: 'td-subscribe',
    templateUrl: './subscribe.component.html',
    styleUrls: ['./subscribe.component.scss'],
})
export class SubscribeComponent implements OnInit, OnDestroy {
    @Input() from?: string;
    @Input() showTitle = true;
    @Input() outsideDialog = false;

    @Output() cancel = new EventEmitter<void>();

    subscription: NotificationsSubscription;
    wallet: TezosWallet;
    form: UntypedFormGroup;
    status: 'none' | 'success' | 'error';
    inProgress: boolean;

    private unsubscribe = new Subject<void>();

    constructor(
        private notificationsService: NotificationsService,
        private tezosService: TezosService,
        private formBuilder: UntypedFormBuilder,
        private storageMap: StorageMap,
        @Optional() public dialogRef?: MatDialogRef<SubscribeComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data?: { from: string }
    ) {}

    ngOnInit(): void {
        this.status = 'none';
        this.notificationsService.currentSubscription.pipe(takeUntil(this.unsubscribe)).subscribe(s => {
            this.subscription = s;

            this.form = this.formBuilder.group({
                email: this.formBuilder.control(s.email, [Validators.required, Validators.email]),
            });
        });
        this.tezosService.activeWallet.pipe(takeUntil(this.unsubscribe)).subscribe(w => (this.wallet = w!));
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    subscribe() {
        this.inProgress = true;
        this.status = 'none';

        this.notificationsService.subscribe({ address: this.wallet.address, email: this.form.value.email }).subscribe(r => {
            this.inProgress = false;

            if (r.status === 'OK') {
                this.status = 'success';
            } else {
                this.status = 'error';
            }
        });
    }

    close() {
        this.dialogRef?.close();
        this.cancel.emit();
    }

    dontShowAgain() {
        const storage = StorageSchema.blockNotificationPopup(this.from ?? this.data?.from ?? '');
        this.storageMap.set(storage.key, true, storage.schema).subscribe(() => this.close());
    }
}
