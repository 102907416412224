import { AfterViewInit, Component, OnInit, ViewChild, inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { AppService } from '../app-service';
import { PageService } from '../browser/page.service';
import { Event_Bool_Exp, PoolHistoryQueryVariables } from '../governance-core/governance-graphql.generated';
import { GovernanceDataSourceFactory } from '../governance-core/pool-data-source-factory';
import { PoolHistoryDataSource } from '../governance-core/pool-history-data-source';
import { TezosWallet } from '../tezos/models';
import { TezosService } from '../tezos/tezos.service';
import { RewardHistoryFilter } from './history-filter.component';
import { GovPoolWidgetComponent } from './pool-widget.component';

@UntilDestroy()
@Component({
    selector: 'td-gov-page',
    templateUrl: './gov-page.component.html',
    styleUrls: ['./gov-page.component.scss'],
})
export class GovPageComponent implements OnInit, AfterViewInit {
    private readonly poolDataSourceFactory = inject(GovernanceDataSourceFactory);
    private readonly tezosService = inject(TezosService);
    private readonly pageService = inject(PageService);
    private readonly appService = inject(AppService);

    private filter$ = new BehaviorSubject<RewardHistoryFilter | null>(null);

    @ViewChild(GovPoolWidgetComponent) governancePool: GovPoolWidgetComponent;

    dataSource: PoolHistoryDataSource;
    hideResults = new BehaviorSubject<boolean>(true);
    initialLoading$ = new BehaviorSubject<boolean>(true);
    address: string;

    ngOnInit(): void {
        this.pageService.setTitle('reward-history');

        this.dataSource = this.poolDataSourceFactory.createPoolHistoryDataSource();

        this.dataSource.initialLoading$.pipe(untilDestroyed(this)).subscribe(loading => {
            if (!loading) {
                this.hideResults.next(false);
            }
        });

        combineLatest([this.filter$, this.tezosService.activeWallet])
            .pipe(untilDestroyed(this))
            .subscribe(([filter, wallet]) => {
                if (!wallet) {
                    this.appService.openConnect();
                    return;
                }

                this.address = wallet.address;

                if (!filter) {
                    return;
                }

                this.loadData(filter, wallet);
            });
    }

    ngAfterViewInit() {
        combineLatest([
            this.governancePool.loading$.pipe(first(loading => loading === false)),
            this.dataSource.initialLoading$.pipe(filter(loading => loading === false)),
        ])
            .pipe(first())
            .subscribe(() => {
                this.initialLoading$.next(false);
            });
    }

    filterChanged(filter: RewardHistoryFilter) {
        this.hideResults.next(true);
        this.filter$.next(filter);
    }

    scrolled() {
        this.dataSource.loadMore();
    }

    refreshHistory() {
        setTimeout(() => this.dataSource.reload(), 1000);
    }

    private loadData(filter: RewardHistoryFilter, wallet: TezosWallet) {
        const where = this.buildQueryVariables(filter, wallet);
        const variables: PoolHistoryQueryVariables = { where, limit: 30, offset: 0 };

        this.dataSource.load(variables);
    }

    private buildQueryVariables(filter: RewardHistoryFilter, wallet: TezosWallet): Event_Bool_Exp {
        const where: Event_Bool_Exp = {
            timestamp: { _gte: filter.date.from, _lte: filter.date.to },
            address: { _eq: wallet.address },
        };

        if (filter.types.length) {
            where.type = { _in: filter.types };
        }

        return where;
    }
}
