<div *transloco="let t">
    <td-error *ngIf="dataSource.error$ | async; let error" [message]="error.message" [title]="t('general.error')"></td-error>

    <div class="table-filter" *ngIf="!hideFilters">
        <form [formGroup]="form" action="." (ngSubmit)="reload()" class="d-flex flex-column flex-sm-row justify-content-between">
            <div class="d-flex flex-grow-1 flex-sm-grow-0">
                <mat-form-field class="flex-grow-1 flex-sm-grow-0" appearance="standard" floatLabel="never">
                    <input
                        matInput
                        formControlName="filter"
                        autocapitalize="off"
                        autocomplete="off"
                        autocorrect="off"
                        type="search"
                        type="search"
                        [placeholder]="t('domain-table.filter-placeholder')"
                    />
                    <fa-icon matSuffix class="clear-button primary" icon="times" (click)="form.get('filter')!.setValue('')"></fa-icon>
                </mat-form-field>
            </div>
            <mat-button-toggle-group class="categories-button" formControlName="category">
                <mat-button-toggle *ngFor="let category of categories" class="category-button" color="primary" [value]="category.name">
                    <!-- t(offer-table.filter-category-all) -->
                    <!-- t(offer-table.filter-category-active) -->
                    <!-- t(offer-table.filter-category-sold) -->
                    {{ t('offer-table.filter-category-' + category.name) }}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </form>
    </div>

    <div *ngIf="(dataSource.empty$ | async) && (hideResults | async) === false" class="empty-message p-3 w-100 text-center smooth-shadow">
        {{ t('offer-table.no-offers') }}
    </div>

    <div class="table-container smooth-shadow d-flex" infiniteScroll (scrolled)="scrolled()" [hidden]="(dataSource.empty$ | async) || (hideResults | async)">
        <mat-table
            class="table"
            [dataSource]="dataSource"
            matSort
            (matSortChange)="sortList()"
            [matSortActive]="sortField"
            [matSortDirection]="sortDirection"
            matSortDisableClear
            #sort="matSort"
        >
            <ng-container matColumnDef="selection">
                <mat-header-cell class="col-selection" *matHeaderCellDef>
                    <mat-checkbox
                        (change)="$event ? toggleSelectAll() : null"
                        [checked]="selection.hasValue() && areAllSelected()"
                        [indeterminate]="selection.hasValue() && !areAllSelected()"
                    >
                    </mat-checkbox
                ></mat-header-cell>
                <mat-cell class="col-selection d-flex align-items-center" *matCellDef="let row">
                    <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="toggleSelectOne($event, row)"
                        [checked]="selection.isSelected(row)"
                        *ngIf="cancellableOfferState[row.state]"
                    >
                    </mat-checkbox>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header="DOMAIN_NAME">{{ t('fields.name') }}</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div class="w-100 text-truncate">
                        <td-domain [domain]="row.domain?.name"></td-domain>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="expiration">
                <mat-header-cell class="col-time" *matHeaderCellDef mat-sort-header="ENDS_AT">{{ t('fields.expiration') }}</mat-header-cell>
                <mat-cell class="col-time" *matCellDef="let row">
                    <td-humanized-date *ngIf="row.expiration" [value]="row.expiration"></td-humanized-date>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="price">
                <mat-header-cell class="col-amount" *matHeaderCellDef mat-sort-header="PRICE">{{ t('fields.price') }}</mat-header-cell>
                <mat-cell class="col-amount" *matCellDef="let row">
                    <td-tez [value]="row.price"></td-tez>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="status">
                <mat-header-cell class="col-status" *matHeaderCellDef>{{ t('fields.status') }}</mat-header-cell>
                <mat-cell class="col-status" *matCellDef="let row">
                    <td-offer-state-badge [offer]="row"></td-offer-state-badge>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell class="col-actions" *matHeaderCellDef></mat-header-cell>
                <mat-cell class="col-actions d-flex justify-content-end align-items-center" *matCellDef="let row">
                    <button
                        mat-flat-button
                        color="primary"
                        (click)="edit(row)"
                        class="me-2"
                        *ngIf="[states.Active, states.DomainOperatorsContractMissing].includes(row.state)"
                    >
                        {{ t('actions.edit') }}
                    </button>
                    <button
                        mat-flat-button
                        color="primary"
                        (click)="cancel(row)"
                        *ngIf="
                            [
                                states.Active,
                                states.DomainOperatorsContractMissing,
                                states.DomainExpired,
                                states.DomainDoesNotExist,
                                states.DomainOperatorsContractMissing,
                                states.OfferSellerDomainOwnerMismatch,
                                states.DomainIsExpiringSoon
                            ].includes(row.state)
                        "
                    >
                        {{ t('actions.cancel') }}
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columns"></mat-row>
        </mat-table>
    </div>
    <div *ngIf="dataSource.loading$ | async" class="w-100 mt-3 d-flex justify-content-center">
        <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>
</div>
