import { Component, Input } from '@angular/core';
import { MediaObserver } from '@/shared/media-observer.service';

@Component({
    selector: 'td-data-card-skeleton',
    templateUrl: './data-card-skeleton.component.html',
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
})
export class DataCardSkeletonComponent {
    @Input() rows: number;
    @Input() buttons: number;

    constructor(public media: MediaObserver) {}
}
