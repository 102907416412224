<ng-container *transloco="let t">
    <div class="d-flex flex-column">
        <td-advanced-select
            key="rpc_url"
            [options]="builtInRpcUrls"
            [valueValidators]="validators"
            [(ngModel)]="rpcUrl"
            (ngModelChange)="setRpcUrl()"
            [addConfig]="{ title: t('settings.rpc-url-add-title'), labelLabel: t('settings.rpc-url-add-label'), valueLabel: t('settings.rpc-url-add-value') }"
            class="flex-fill"
        ></td-advanced-select>
        <mat-error class="text-wrap ps-3" matLine *ngIf="currentCustomRpc && currentCustomRpc !== network.rpcUrl">
            {{ t('settings.rpc-url-error', { url: currentCustomRpc, network: network.name }) }}
        </mat-error>
    </div>
</ng-container>
