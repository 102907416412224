import { Pipe, PipeTransform } from '@angular/core';

import { Configuration } from '../configuration';

@Pipe({
    name: 'landingPageUrl',
})
export class LandingPageUrlPipe implements PipeTransform {
    constructor(private configuration: Configuration) {}

    transform(path: string): string | null {
        if (!this.configuration.landingPageUrl) {
            return null;
        }

        return `${this.configuration.landingPageUrl}/${path}`;
    }
}
