<div *transloco="let t">
    <td-error *ngIf="dataSource.error$ | async; let error" [message]="error.message" [title]="t('general.error')"></td-error>
    <td-expired-domains-filter (filterChange)="onFilterChange($event)" *ngIf="showFilter"></td-expired-domains-filter>

    <div *ngIf="!someDomainsLoaded && !loading" class="empty-message p-3 w-100 text-center" [ngClass]="{ 'smooth-shadow': showShadow }">
        {{ t('expired-domains.no-domains') }}
    </div>

    <div
        class="table-container w-100"
        [ngClass]="{ 'smooth-shadow': showShadow }"
        infiniteScroll
        [infiniteScrollDisabled]="!scrollToLoad"
        (scrolled)="scrolled()"
        [hidden]="!someDomainsLoaded && loading"
    >
        <ng-container *ngIf="data$ | async; let events">
            <ng-container *ngFor="let domain of events; trackBy: trackBy; let last = last">
                <td-domain-listing
                    [domainName]="domain.name"
                    [expiration]="domain.expiration"
                    [readonlyExpiration]="true"
                    [buttonText]="t(domain.buttonText)"
                    [buttonDisabled]="domain.buttonDisabled"
                    [maxDomainNameCharacters]="30"
                    (buttonAction)="domain.buttonAction()"
                    [seller]="'TD'"
                    class="full-card-width"
                >
                    <td-humanized-date
                        [readOnly]="true"
                        [matTooltip]="domain.expiredAt | dayjsDate"
                        class="expired-date"
                        [value]="domain.expiredAt"
                        [prefix]="t('expired-domains.expired-ago')"
                    ></td-humanized-date>
                </td-domain-listing>
                <mat-divider *ngIf="!last"></mat-divider>
            </ng-container>
        </ng-container>
    </div>
    <div *ngIf="loading" class="w-100 mt-3 d-flex justify-content-center">
        <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>
</div>
