<ng-container *transloco="let t">
    <button
        [matMenuTriggerFor]="menu"
        (menuOpened)="menuOpened()"
        mat-stroked-button
        (click)="onClick()"
        [ngClass]="{ 'highlighted-stroked': domainDisplay !== 'noDomain' }"
    >
        <ng-container [ngSwitch]="domainDisplay">
            <div *ngSwitchCase="'noDomain'">
                {{ t('filters.domain.name') }}
            </div>
            <div *ngSwitchCase="'domain'">{{ t('filters.domain.name-entered') }} {{ domain | truncate: 15 }}</div>
        </ng-container>
    </button>

    <mat-menu #menu="matMenu" class="domain-filter-menu" (closed)="menuClosed()">
        <form [formGroup]="form" action="." (ngSubmit)="submit()">
            <div
                (click)="$event.stopPropagation()"
                (keydown.tab)="$event.stopPropagation()"
                (keydown.shift.tab)="$event.stopPropagation()"
                class="d-flex flex-column"
                cdkTrapFocus
                [cdkTrapFocusAutoCapture]="true"
            >
                <div class="p-4 d-flex flex-column flex-grow-1">
                    <div class="pb-3">{{ t('filters.domain.title') }}</div>
                    <mat-form-field appearance="standard">
                        <mat-label>{{ t('filters.domain.name') }}</mat-label>
                        <input formControlName="domainFilter" autocapitalize="off" autocomplete="off" autocorrect="off" type="search" matInput #domainInput />
                        <mat-error>
                            <td-validation-messages
                                [control]="form.get('domainFilter')!"
                                [context]="{ name: t('filters.domain.name') }"
                            ></td-validation-messages>
                        </mat-error>
                    </mat-form-field>
                </div>
                <mat-divider></mat-divider>
                <div class="p-3 d-flex gap-2 justify-content-end">
                    <button type="button" mat-stroked-button class="js-cancel-btn" (click)="clear()">{{ t('actions.clear') }}</button>
                    <button mat-flat-button color="primary" class="js-submit" [disabled]="isDisabled()" type="submit">{{ t('actions.filter') }}</button>
                </div>
            </div>
        </form>
    </mat-menu>
</ng-container>
