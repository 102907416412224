<div *transloco="let t" class="card smooth-shadow home-item d-flex flex-column" [hidden]="hidden" [ngClass]="{ empty: false }">
    <div class="d-flex w-100 mb-2">
        <h2 class="flex-grow-1 mb-0">
            {{ t('gvPool.widget.title') }}
        </h2>
        <div class="d-flex align-items-center flex-shrink-0">
            <mat-slide-toggle class="" labelPosition="before" [checked]="!!delegateData" (click)="changeDelegation(); $event.preventDefault()">
                {{ delegateData ? t('gvPool.widget.delegated') : t('gvPool.widget.not-delegated') }}
            </mat-slide-toggle>
        </div>
    </div>
    <div class="stats my-3 px-3" *ngIf="vm$ | async as vm; else loading">
        <div class="stats-item">
            <div>{{ t('gvPool.widget.balance') }}</div>
            <div class="stats-item-value">{{ vm.depositedTedBalance | formatTed }}</div>
            <ng-container *ngIf="convertTedToUSD(vm.depositedTedBalance) | async as priceData">
                <div *ngIf="!priceData.loading; else loadingPriceSmall">{{ priceData.price | tdCurrency }}</div>
            </ng-container>
        </div>
        <div class="stats-item">
            <div>
                TED {{ t('gvPool.widget.rewarded') }}
                <fa-icon
                    icon="question-circle"
                    class="accent help ms-1 no-underline"
                    matTooltipPosition="above"
                    [matTooltip]="t('gvPool.widget.rewarded-help')"
                ></fa-icon>
            </div>
            <div class="stats-item-value txt-success">{{ vm.tedRewarded | formatTed }}</div>
            <ng-container *ngIf="convertTedToUSD(vm.tedRewarded) | async as priceData">
                <div *ngIf="!priceData.loading; else loadingPriceSmall">{{ priceData.price | tdCurrency }}</div>
            </ng-container>
        </div>
        <td-widget-apr [vm]="vm"></td-widget-apr>
        <div class="stats-item">
            <div>
                {{ t('gvPool.widget.token-price') }}
                <a href="https://www.gate.io/" target="_blank">
                    <fa-icon
                        icon="question-circle"
                        class="accent help ms-1 no-underline"
                        matTooltipPosition="above"
                        [matTooltip]="t('gvPool.widget.token-price-help')"
                    ></fa-icon
                ></a>
            </div>
            <ng-container *ngIf="convertTedToUSD(1 * 1e6) | async as priceData">
                <div *ngIf="priceData.loading" class="stats-item-value">
                    <mat-progress-spinner [diameter]="20" color="primary" mode="indeterminate"></mat-progress-spinner>
                </div>
                <div *ngIf="!priceData.loading && !priceData.price" class="stats-item-value">
                    <div class="stats-item-value"><span [matTooltip]="t('gvPool.widget.no-price')">???</span></div>
                </div>
                <div *ngIf="!priceData.loading && priceData.price" class="stats-item-value">
                    <div class="stats-item-value">{{ priceData.price | tdCurrency : 3 }}</div>
                </div>
            </ng-container>
            <div *ngIf="plentyUrl">
                <a [href]="plentyUrl" target="_blank">{{ t('gvPool.widget.buy-ted') }}</a>
            </div>
        </div>
        <div class="stats-action d-flex flex-column gap-2 justify-content-center">
            <button mat-flat-button color="primary" (click)="deposit(vm)" *ngIf="vm.tedBalance.gt(0)" [disabled]="vm.tedBalance.eq(0)">
                {{ t('actions.deposit') }} {{ vm.tedBalance | formatTed }}&nbsp;TED
            </button>
            <button mat-flat-button color="primary" (click)="withdraw(vm)" *ngIf="vm.votesBalance.gt(0)" [disabled]="vm.votesBalance.eq(0)">
                {{ t('actions.withdraw') }}
            </button>
        </div>
    </div>
    <div class="d-flex justify-content-between w-100 mt-2">
        <div class="d-flex align-items-center flex-shrink-0 gap-3">
            <a href="https://talk.tezos.domains/" target="_blank">
                <fa-icon class="gov-icon" [icon]="['fab', 'discourse']" size="lg"></fa-icon>
                {{ t('gvPool.widget.forum') }}
            </a>
            <a href="{{ homebaseUrl }}" target="_blank" class="homebase-url" *ngIf="homebaseUrl">
                <td-homebase-logo class="gov-icon"></td-homebase-logo>
                {{ t('gvPool.widget.proposals') }}
            </a>
        </div>
        <div class="d-flex align-items-center flex-shrink-0">
            <a *ngIf="showSeeMore" [routerLink]="['/governance']">{{ t('general.see-more') }}</a>
        </div>
    </div>

    <ng-template #loadingPriceSmall> </ng-template>
</div>

<ng-template #loading>
    <div *ngIf="loading$ | async" class="w-100 flex-grow-1">
        <div class="d-flex flex-column justify-content-center align-items-center h-100">
            <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
        </div>
    </div>
</ng-template>
