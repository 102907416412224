<div *transloco="let t" class="dashboard-title d-flex align-items-center flex-fill">
    <div class="py-3 w-100 d-flex flex-column gap-1 flex-grow-1">
        <h1 class="m-0">
            <fa-icon icon="layer-group"></fa-icon>
            {{ t('address-detail.title-my') }}
        </h1>
        <div *ngIf="reverseRecord?.domain" class="d-flex flex-column flex-sm-row justify-content-start align-items-sm-center align-items-start">
            <span class="me-2 txt-secondary text-nowrap flex-grow-0">{{ t('address-detail.title-reverse-record') }}</span>
            <td-address [address]="reverseRecord!.address" [reverseRecord]="reverseRecord" text="name-only" class="w-100 flex-grow-0"></td-address>
        </div>
    </div>
    <div class="graphic d-none d-sm-block flex-shrink-0">
        <img src="/assets/img/dashboard.png" />
    </div>
</div>
