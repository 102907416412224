import { Injectable } from '@angular/core';
import { AcquisitionInfoInput, calculateAcquisitionInfo } from '@tezos-domains/manager';
import { TaquitoTezosDomainsClient, TaquitoTezosDomainsConfig } from '@tezos-domains/taquito-client';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class TezosDomainsClientService {
    private clientStream: ReplaySubject<TaquitoTezosDomainsClient> = new ReplaySubject(1);

    readonly defaultTld$ = this.current.pipe(
        map(c => c.validator.supportedTLDs[0]),
        filter(c => !!c),
        distinctUntilChanged(),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    get current() {
        return this.clientStream.asObservable();
    }

    setConfig(config: TaquitoTezosDomainsConfig) {
        this.clientStream.next(new TaquitoTezosDomainsClient(config));
    }

    calculateAcquisitionInfo(input: AcquisitionInfoInput) {
        return calculateAcquisitionInfo(input);
    }
}
