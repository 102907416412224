<div *transloco="let t">
    <div class="d-flex flex-column gap-2" *ngIf="bidderBalance | async; let bb">
        <div>
            <span>{{ t('general.total-balance') }}:</span>
            <td-tez class="ms-2 txt-lg" [value]="bb.balance"></td-tez>
        </div>
        <div class="d-flex flex-column flex-sm-row gap-3 align-items-stretch align-items-sm-start">
            <button mat-flat-button color="primary" (click)="withdraw()" size="default"><fa-icon icon="edit"></fa-icon>{{ t('actions.withdraw') }}</button>
        </div>
    </div>
</div>
