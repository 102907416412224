<ng-container *transloco="let t">
    <ng-container *ngIf="vm$ | async; let userBlocked">
        <td-alert [state]="'info'" *ngIf="userBlocked.blocked">
            <div class="my-1 d-flex align-items-center">
                <span
                    class="fw-500 flex-grow-1"
                    [tdDynamicHtml]="t('airdrop.blocked-message.text', { address: userBlocked.meta?.blacklistedInFavorOfAddress })"
                ></span>
                <button class="mx-2 flex-shrink-0" mat-flat-button color="primary" (click)="dismiss()">
                    {{ t('airdrop.blocked-message.dismiss') }}
                </button>
            </div>
        </td-alert>
    </ng-container>
</ng-container>
