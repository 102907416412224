<ng-container *transloco="let t">
    <ng-container *ngIf="vm$ | async; let vm">
        <div class="p-3" *ngIf="!vm.airdropStarted; else airdropStarted">
            <div class="card smooth-shadow d-flex flex-column align-items-center justify-content-center">
                <h1 class="my-4">{{ t('airdrop.step-not-started.title') }}</h1>
                <a mat-flat-button class="mt-4" color="primary" *ngIf="vm.wallet" [routerLink]="['/address', vm.wallet.address]">
                    <fa-icon icon="layer-group"></fa-icon>
                    {{ t('general.go-to-dashboard') }}
                </a>
                <a mat-flat-button class="mt-4" color="primary" *ngIf="!vm.wallet" [routerLink]="['/']">
                    <fa-icon icon="layer-group"></fa-icon>
                    {{ t('general.go-to-homepage') }}
                </a>
            </div>
        </div>

        <ng-template #airdropStarted>
            <div *ngIf="vm.wallet === undefined">
                <td-title-skeleton></td-title-skeleton>
                <td-data-card-skeleton [rows]="5" [buttons]="1"></td-data-card-skeleton>
            </div>
            <div *ngIf="vm.wallet === null" class="p-3">
                <div class="card smooth-shadow d-flex flex-column align-items-center justify-content-center">
                    <h1 class="mt-4">{{ t('airdrop.connect-cta') }}</h1>
                    <button mat-flat-button color="primary" class="m-4" (click)="connect()">{{ t('actions.connect-wallet') }}</button>
                </div>
            </div>
            <div *ngIf="vm.wallet && vm.claimState?.status === claimStatus.NoClaim" class="p-3">
                <div class="card smooth-shadow d-flex flex-column align-items-center justify-content-center">
                    <h1 class="mt-4">{{ t('airdrop.step-no-airdrop.title') }}</h1>
                    <div class="d-flex flex-column">
                        <div [tdDynamicHtml]="t('airdrop.step-no-airdrop.text', { start: airdropAccumulationStart, end: airdropAccumulationEnd })"></div>
                    </div>
                    <a mat-flat-button class="mt-4" color="primary" [routerLink]="['/address', vm.wallet.address]">
                        <fa-icon icon="layer-group"></fa-icon>
                        {{ t('general.go-to-dashboard') }}
                    </a>
                </div>
            </div>
            <div *ngIf="vm.wallet && vm.claimState?.status === claimStatus.AirdropEnded" class="p-3">
                <div class="card smooth-shadow d-flex flex-column align-items-center justify-content-center">
                    <h1 class="mt-4">{{ t('airdrop.step-ended.title') }}</h1>
                    <a mat-flat-button class="mt-4" color="primary" [routerLink]="['/address', vm.wallet.address]">
                        <fa-icon icon="layer-group"></fa-icon>
                        {{ t('general.go-to-dashboard') }}
                    </a>
                </div>
            </div>
            <div *ngIf="vm.wallet && vm.claimState?.status === claimStatus.Blocked" class="p-3">
                <div class="card smooth-shadow d-flex flex-column align-items-center justify-content-center">
                    <h1 class="mt-4">{{ t('airdrop.step-blocked.title') }}</h1>
                    <div class="d-flex flex-column">
                        <div class="mt-2" [tdDynamicHtml]="t('airdrop.step-blocked.text', { address: vm.claimState?.meta?.blacklistedInFavorOfAddress })"></div>
                    </div>
                    <a mat-flat-button class="mt-4" color="primary" [routerLink]="['/address', vm.wallet.address]">
                        <fa-icon icon="layer-group"></fa-icon>
                        {{ t('general.go-to-dashboard') }}
                    </a>
                </div>
            </div>
            <div
                class="py-3 transparent-stepper"
                *ngIf="
                    vm.wallet &&
                    vm.claimState &&
                    vm.claimState.status !== claimStatus.NoClaim &&
                    vm.claimState.status !== claimStatus.Blocked &&
                    vm.claimState.status !== claimStatus.AirdropEnded
                "
            >
                <mat-stepper orientation="horizontal" [disableRipple]="true" #stepper [linear]="true" [@.disabled]="true" [selectedIndex]="currentStep">
                    <mat-step [completed]="true">
                        <div class="d-flex flex-column flex-sm-row gap-3">
                            <div class="font-16 d-flex flex-column flex-50">
                                <h1>
                                    <td-address
                                        [link]="false"
                                        [copy]="false"
                                        [address]="vm.wallet.address"
                                        [reverseRecord]="reverseRecord"
                                        [shorten]="true"
                                        text="name-or-address"
                                    ></td-address>
                                </h1>
                                <td-alert [state]="'info'" [small]="true" *ngIf="vm.claimState.hasOwnershipClaimsBlocked">
                                    <div class="my-1 d-flex align-items-center">
                                        <span
                                            class="fw-500 flex-grow-1"
                                            [tdDynamicHtml]="
                                                t('airdrop.blocked-message.ownership-claims', { address: vm.claimState.meta.blacklistedInFavorOfAddress })
                                            "
                                        >
                                        </span>
                                    </div>
                                </td-alert>
                                <div class="mt-4 pb-2 bold">{{ t('airdrop.rewards.title') }}</div>
                                <ng-container *ngIf="vm.claimState.meta.historicalAmount.gt(0)">
                                    <mat-divider></mat-divider>
                                    <div class="mt-3 pb-2 d-flex">
                                        <span class="flex-grow-1">{{ t('airdrop.rewards.history') }}</span>
                                        <span class="bold">{{ vm.claimState.meta.historicalAmount | formatTed }} TED</span>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="vm.claimState.meta.futureRegistrationAmount.gt(0)">
                                    <mat-divider></mat-divider>
                                    <div class="mt-3 pb-2 d-flex">
                                        <span class="flex-grow-1">{{ t('airdrop.rewards.future') }}</span>
                                        <span class="bold">{{ vm.claimState.meta.futureRegistrationAmount | formatTed }} TED</span>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="vm.claimState.meta.reverseRecord.gt(0)">
                                    <mat-divider></mat-divider>
                                    <div class="mt-3 pb-2 d-flex">
                                        <span class="flex-grow-1">{{ t('airdrop.fun-facts.reverse-record') }}</span>
                                        <span class="bold">{{ vm.claimState.meta.reverseRecord | formatTed }} TED</span>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="vm.claimState.meta.discordOgTokens.gt(0)">
                                    <mat-divider></mat-divider>
                                    <div class="mt-3 pb-2 d-flex">
                                        <span class="flex-grow-1">{{ t('airdrop.fun-facts.discord-og') }}</span>
                                        <span class="bold">{{ vm.claimState.meta.discordOgTokens | formatTed }} TED</span>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="vm.claimState.meta.translators.gt(0)">
                                    <mat-divider></mat-divider>
                                    <div class="mt-3 pb-2 d-flex">
                                        <span class="flex-grow-1">{{ t('airdrop.fun-facts.translator') }}</span>
                                        <span class="bold">{{ vm.claimState.meta.translators | formatTed }} TED</span>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="vm.claimState.meta.integrators.gt(0)">
                                    <mat-divider></mat-divider>
                                    <div class="mt-3 pb-2 d-flex">
                                        <span class="flex-grow-1">{{ t('airdrop.fun-facts.integrator') }}</span>
                                        <span class="bold">{{ vm.claimState.meta.integrators | formatTed }} TED</span>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="vm.claimState.meta.externalContributors.gt(0)">
                                    <mat-divider></mat-divider>
                                    <div class="mt-3 pb-2 d-flex">
                                        <span class="flex-grow-1">{{ t('airdrop.fun-facts.external-contributor') }}</span>
                                        <span class="bold">{{ vm.claimState.meta.externalContributors | formatTed }} TED</span>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="vm.claimState.meta.keyHolders.gt(0)">
                                    <mat-divider></mat-divider>
                                    <div class="mt-3 pb-2 d-flex">
                                        <span class="flex-grow-1">{{ t('airdrop.fun-facts.keyholder') }}</span>
                                        <span class="bold">{{ vm.claimState.meta.keyHolders | formatTed }} TED</span>
                                    </div>
                                </ng-container>
                                <ng-container>
                                    <mat-divider></mat-divider>
                                    <div class="mt-3 pb-2 d-flex bold">
                                        <span class="flex-grow-1">{{ t('airdrop.fun-facts.total') }}</span>
                                        <span class="bold" [matTooltip]="vm.claimState.totalAmount | formatTed : 7">
                                            {{ vm.claimState.totalAmount | formatTed }} TED
                                        </span>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="vm.claimState.meta.domain">
                                    <div class="mt-5 pb-2 bold">{{ t('airdrop.fun-facts.title') }}</div>
                                    <mat-divider></mat-divider>
                                    <div class="mt-3 pb-2 d-flex">
                                        <span class="flex-grow-1">{{ t('airdrop.fun-facts.most-rewarded') }}</span>
                                        <span class="bold">{{ vm.claimState.meta.domain }}</span>
                                    </div>
                                    <ng-container *ngIf="vm.claimState.meta.memberSince">
                                        <mat-divider></mat-divider>
                                        <div class="mt-3 pb-2 d-flex">
                                            <span class="flex-grow-1">{{ t('airdrop.fun-facts.member-since') }}</span>
                                            <span class="bold"> {{ vm.claimState.meta.memberSince | dayjsDate : 'medium' }} </span>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <div class="d-flex flex-column flex-50">
                                <div class="card smooth-shadow d-flex flex-column flex-grow-1" *ngIf="vm.claimState.status === claimStatus.Unclaimed">
                                    <h1 class="bold">{{ t('airdrop.step-airdrop-start.title') }}</h1>
                                    <div
                                        [tdDynamicHtml]="
                                            t('airdrop.step-airdrop-start.text1', {
                                                airdropStart: config.airdrop.start | dayjsDate : 'long',
                                                link: 'https://blog.tezos.domains/tezos-domains-airdrop-details-33d3a9bb961d'
                                            })
                                        "
                                    ></div>

                                    <td-tokens class="my-4" [label]="t('airdrop.tokens-tooltip')" [value]="tokenAmount"></td-tokens>

                                    <div class="mb-2">
                                        {{ t('airdrop.step-airdrop-start.text2') }}
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <button mat-flat-button color="primary" class="mt-4" (click)="next()">
                                            {{ t('airdrop.step-airdrop-start.start') }}
                                        </button>
                                    </div>
                                </div>
                                <div
                                    class="card smooth-shadow d-flex flex-column align-items-center justify-content-center flex-grow-1"
                                    *ngIf="vm.claimState.status === claimStatus.Claimed || vm.claimState.status === claimStatus.ClaimedPartially"
                                >
                                    <h1 class="bold">
                                        {{
                                            vm.claimState.status === claimStatus.Claimed
                                                ? t('airdrop.step-airdrop-start.already-claimed')
                                                : t('airdrop.step-airdrop-start.partially-claimed')
                                        }}
                                    </h1>
                                    <div class="d-flex align-items-center">
                                        <a mat-flat-button class="mt-4" color="primary" [routerLink]="['/address', vm.wallet.address]">
                                            <fa-icon icon="layer-group"></fa-icon>
                                            {{ t('general.go-to-dashboard') }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-step>
                    <mat-step [completed]="true">
                        <div class="d-flex flex-column">
                            <div class="d-flex flex-column flex-sm-row gap-3 flex-grow-1">
                                <div class="font-16 d-flex flex-column flex-50">
                                    <div class="card smooth-shadow d-flex flex-column h-100">
                                        <h1 class="bold">{{ t('airdrop.what.title') }}</h1>
                                        <div>
                                            {{ t('airdrop.what.description') }}
                                        </div>
                                        <div class="mt-3">
                                            <div class="mt-3 pb-2 d-flex">
                                                <span class="flex-grow-1">{{ t('airdrop.what.stats-names.title') }}</span>
                                                <span class="bold">{{ t('airdrop.what.stats-names.value') }}</span>
                                            </div>
                                            <mat-divider></mat-divider>

                                            <div class="mt-3 pb-2 d-flex">
                                                <span class="flex-grow-1">{{ t('airdrop.what.stats-years-reg.title') }}</span>
                                                <span class="bold">{{ t('airdrop.what.stats-years-reg.value') }}</span>
                                            </div>
                                            <mat-divider></mat-divider>

                                            <div class="mt-3 pb-2 d-flex">
                                                <span class="flex-grow-1">{{ t('airdrop.what.stats-int.title') }}</span>
                                                <span class="bold">{{ t('airdrop.what.stats-int.value') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-column flex-50 h-100">
                                    <div class="card smooth-shadow">
                                        <h1 class="bold">{{ t('airdrop.why.title') }}</h1>
                                        <div>
                                            {{ t('airdrop.why.desc1') }}
                                        </div>
                                        <div class="my-3">
                                            {{ t('airdrop.why.desc2') }}
                                        </div>
                                    </div>
                                    <div class="card smooth-shadow mt-3">
                                        <h1 class="bold">{{ t('airdrop.why.now') }}</h1>
                                        <div [tdDynamicHtml]="t('airdrop.why.because')"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 d-flex justify-content-end gap-3">
                                <button mat-flat-button (click)="back()">{{ t('actions.back') }}</button>
                                <button mat-flat-button color="primary" (click)="next()">{{ t('actions.next') }}</button>
                            </div>
                        </div>
                    </mat-step>
                    <mat-step [completed]="true">
                        <div class="d-flex flex-column">
                            <div class="card smooth-shadow d-flex flex-column">
                                <img src="/assets/img/dao-distribution.png" />
                                <div>
                                    <h1 class="mt-3 bold">{{ t('airdrop.distribution.title') }}</h1>
                                    <div [tdDynamicHtml]="t('airdrop.distribution.description')"></div>
                                </div>
                            </div>
                            <div class="mt-3 d-flex justify-content-end gap-3">
                                <button mat-flat-button (click)="back()">{{ t('actions.back') }}</button>
                                <button mat-flat-button color="primary" (click)="onApprovalChange(true)">{{ t('actions.next') }}</button>
                            </div>
                        </div>
                    </mat-step>
                    <mat-step [completed]="true">
                        <div class="card smooth-shadow">
                            <td-delegate-list
                                (delegateSelected)="delegateSelected($event, vm.claimState, vm.wallet.address)"
                                (delegateStepChanged)="clear()"
                                (back)="back()"
                                [tokenAmount]="vm.claimState.amountClaimable"
                                [operationStatusTemplate]="operationComp"
                                [disabled]="operationInProgress"
                                [currentDelegate]="vm.currentDelegate"
                                class="full-card-width px-1 px-sm-3"
                            >
                            </td-delegate-list>
                            <ng-template #operationComp>
                                <td-operation-status [operation]="operation" (done)="operationDone($event, vm.claimState)" #op></td-operation-status>
                            </ng-template>
                        </div>
                    </mat-step>
                    <mat-step [completed]="true">
                        <div class="card smooth-shadow d-flex flex-column align-items-center justify-content-center">
                            <h1 class="fw-bold">{{ t('airdrop.step-airdrop-success.title') }}</h1>

                            <div [tdDynamicHtml]="t('airdrop.step-airdrop-success.text')"></div>

                            <a mat-flat-button class="mt-4" color="primary" [routerLink]="['/address', vm.wallet.address]">
                                <fa-icon icon="layer-group"></fa-icon>
                                {{ t('general.go-to-dashboard') }}
                            </a>

                            <td-share class="mt-4 pt-4" [text]="t('airdrop.share-text')"></td-share>
                        </div>
                    </mat-step>
                </mat-stepper>
            </div>
        </ng-template>
    </ng-container>
</ng-container>
