import { Component, Input } from '@angular/core';

@Component({
    selector: 'td-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent {
    @Input() color: string;
    @Input() hover: boolean;
    @Input() disabled: boolean;
}
