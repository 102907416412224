import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { Configuration } from '../configuration';
import { AirdropClaimFile, ClaimFileData, EmptyClaimMeta } from './models';

@Injectable()
export class AirdropFileService {
    private readonly config = inject(Configuration);
    private readonly httpClient = inject(HttpClient);

    private readonly fileStream$: Record<string, Observable<ClaimFileData>> = {};

    load(owner: string): Observable<ClaimFileData> {
        if (this.fileStream$[owner]) {
            return this.fileStream$[owner];
        }

        // we don't really want to keep the previous data if another address authenticates;
        delete this.fileStream$[owner];

        const file$ = this.httpClient.get<AirdropClaimFile>(`${this.config.storageUrl}/claims-folder/${owner}.json`).pipe(
            map(data => ({ ...data, v: (data.v ?? []).map(d => ({ ...d, from: new Date(d.fr) })) })),
            map(data => this.mapClaimData(data, owner)),
            shareReplay({ refCount: true, bufferSize: 1 }),
            catchError(() =>
                of({
                    owner,
                    claims: [],
                })
            )
        );

        this.fileStream$[owner] = file$;

        return file$;
    }

    private mapClaimData(data: AirdropClaimFile | null, owner: string): ClaimFileData {
        if (!data)
            return {
                owner,
                meta: EmptyClaimMeta,
                claims: [],
            };

        const sortedClaims = (data.v ?? []).map(d => ({ ...d, fr: dayjs(d.fr) })).sort();

        const airdropClaim: ClaimFileData = {
            meta: {
                historicalAmount: BigNumber(data.m.h || 0),
                futureRegistrationAmount: BigNumber(data.m.f || 0),
                memberSince: data.m.ms ? dayjs(data.m.ms) : undefined,
                reverseRecord: BigNumber(data.m.rr || 0),
                domain: data.m.d,
                discordOgTokens: BigNumber(data.m.og),
                translators: BigNumber(data.m.t || 0),
                integrators: BigNumber(data.m.i || 0),
                externalContributors: BigNumber(data.m.ec || 0),
                keyHolders: BigNumber(data.m.k || 0),
                // if the address is the same as the owner that means this account is receiving the tokens from a blacklisted .group
                blacklistedInFavorOfAddress: data.m.b && data.m.b !== owner ? data.m.b : '',
                blacklistReason: data.m.b && data.m.b !== owner ? data.m.br : '',

                contractTokens: BigNumber(data.m.c || 0),
                contractAddress: data.m.ca || '',
            },
            owner,
            claims: sortedClaims.map(rawClaim => {
                return {
                    amount: BigNumber(rawClaim.t || 0),
                    proof: rawClaim.proof,
                    from: dayjs(rawClaim.fr),
                    owner,
                };
            }),
        };

        return airdropClaim;
    }
}
