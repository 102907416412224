import { DataItem } from '../graphql/graphql.generated';
import { TezosErrorCode } from '../tezos/models';

export function emptyStringToNull(str: string | null | undefined) {
    if (!str) {
        return null;
    }

    return str;
}

export function shorten(s: string, start = 4, end = 4) {
    return `${s.substring(0, start)}...${s.substring(s.length - end, s.length)}`;
}

export function safeJsonParse(jsonString: string): unknown {
    try {
        return JSON.parse(jsonString);
    } catch {
        return null;
    }
}

export function dataArrayToObj(data: Pick<DataItem, 'key' | 'rawValue'>[]) {
    return data.reduce<Record<string, string>>((result, item) => {
        result[item.key] = item.rawValue;

        return result;
    }, {});
}

export function extractErrorCode(errors: any[] | null | undefined): TezosErrorCode | null {
    if (!errors?.length) {
        return null;
    }

    const transactionError = errors.at(-1);

    return (
        parseErrorCode(transactionError?.with?.string) ||
        parseErrorCode(
            transactionError.id
                .replace(/^proto\.\d\d\d-[A-Za-z0-9]+\./, '')
                .replace('.', '_')
                .toUpperCase()
        )
    );
}

export function parseErrorCode(errorCode: string): TezosErrorCode | null {
    if (errorCode && Object.values(TezosErrorCode).includes(errorCode as any)) {
        return errorCode as TezosErrorCode;
    }

    return null;
}

export function replacePlaceholders(value: string, placeholders: Record<string, string>) {
    return value.replace(/{{\s*([\w-]+)\s*}}/g, (_, placeholder) => placeholders[placeholder]);
}
