<div class="account-widget d-flex align-items-center" *ngIf="media.isActive('gt-md')">
    <div class="account-balance">
        <mat-progress-spinner *ngIf="(wallet.balance | async) === null" [diameter]="20" mode="indeterminate"></mat-progress-spinner>
        <td-tez *ngIf="(wallet.balance | async) != null" [value]="wallet.balance | async" [decimals]="2"></td-tez>
    </div>
    <div class="account-widget-divider"></div>
    <div class="account-name">
        <td-address
            [link]="false"
            [copy]="false"
            [address]="wallet.address"
            [reverseRecord]="reverseRecord"
            [shorten]="true"
            [shortenName]="true"
            text="name-or-address"
        ></td-address>
    </div>
</div>

<div class="avatar" *ngIf="media.isActive('lt-md')">
    <td-address
        [iconSize]="32"
        [link]="false"
        [copy]="false"
        [address]="wallet.address"
        [reverseRecord]="reverseRecord"
        [shorten]="true"
        [text]="false"
    ></td-address>
</div>
