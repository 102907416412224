import { MediaObserver } from '@/shared/media-observer.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSort, SortDirection } from '@angular/material/sort';
import dayjs from 'dayjs';
import mergeObject from 'lodash-es/merge';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { HistoryStateService } from '../browser/history-state.service';
import { Configuration } from '../configuration';
import { DataSourceFactory } from '../graphql/data-source-factory';
import { DomainListRecord, DomainTableDataSource } from '../graphql/domain-table-data-source';
import { DomainOrder, DomainOrderField, DomainsFilter, DomainsListQueryVariables, RecordValidity } from '../graphql/graphql.generated';
import { TezosWallet } from '../tezos/models';
import { TezosService } from '../tezos/tezos.service';
import { isExpiring } from './domain.helpers';
import { ReverseRecord, ReverseRecordService } from './reverse-record.service';
import { isOperator } from './utils/is-operator';

type FilterCategory = {
    name: DomainTableFilterCategory;
    predicateBuilder: () => DomainsFilter;
};

export type DomainTableFilterCategory = 'all' | 'direct' | 'expiring';

export interface DomainListSort {
    field: string;
    direction: SortDirection;
}

export type DomainTableState = {
    domainTable?: {
        sort: DomainListSort;
        form: any;
    };
};

@Component({
    selector: 'td-domain-table',
    templateUrl: './domain-table.component.html',
    styleUrls: ['./domain-table.component.scss'],
})
export class DomainTableComponent implements OnInit, OnDestroy, OnChanges {
    @Input() baseFilter: DomainsFilter;
    @Input() baseLevel: number;
    @Input() allowedCategories: DomainTableFilterCategory[];
    @Input() columns: string[];
    @Input() noShadow: boolean;
    @Input() connected = false;
    @Input() hideFilters = false;
    @Input() address = '';
    @Input() selectAllOnLoad = false;
    @Input() pageSize = 30;
    @Input() sortingPerCategory?: { [key in DomainTableFilterCategory as string]: DomainListSort };

    @Output() selectedCategoryChanged = new EventEmitter<DomainTableFilterCategory>();
    @Input() selectedCategory: DomainTableFilterCategory;

    @Output() domainsSelected = new EventEmitter<DomainListRecord[]>();

    dataSource: DomainTableDataSource;
    userReverseRecord: ReverseRecord | null;
    form: UntypedFormGroup;
    categories: FilterCategory[];
    wallet: TezosWallet | null;
    selection = new SelectionModel<DomainListRecord>(true, []);
    totalDomainCount = 0;

    sortField: string;
    sortDirection: SortDirection;
    hideResults = new BehaviorSubject<boolean>(true);
    loadedWithFilter: boolean;

    visibleColumns: string[] = [];
    operatorTooltip = '';

    private unsubscribe = new Subject<void>();
    private currentSecondLevelDomains: DomainListRecord[];
    private shouldSelectAllItems = false;

    @ViewChild(MatSort) sorter: MatSort;
    @ViewChild(InfiniteScrollDirective) infiniteScroll: InfiniteScrollDirective;

    constructor(
        private dataSourceFactory: DataSourceFactory,
        private formBuilder: UntypedFormBuilder,
        public media: MediaObserver,
        private historyStateService: HistoryStateService,
        private reverseRecordService: ReverseRecordService,
        private tezosService: TezosService,
        private configuration: Configuration
    ) {}

    ngOnInit(): void {
        this.media
            .asObservable()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.updateVisibleColumns());

        this.dataSource = this.dataSourceFactory.createDomainsDataSource();
        this.shouldSelectAllItems = this.selectAllOnLoad;

        this.dataSource.data$
            .pipe(
                filter(d => !!d),
                takeUntil(this.unsubscribe)
            )
            .subscribe(allData => {
                this.currentSecondLevelDomains = allData.filter(d => d.level === 2);
                if (this.shouldSelectAllItems) {
                    this.selectAll();
                }
                this.shouldSelectAllItems = false;
            });

        this.categories = [
            { name: 'all', predicateBuilder: () => ({}) },
            { name: 'direct', predicateBuilder: () => ({ level: { equalTo: this.baseLevel } }) },
            {
                name: 'expiring',
                predicateBuilder: () => ({
                    level: { equalTo: this.baseLevel },
                    expiresAtUtc: {
                        lessThan: dayjs().add(
                            this.configuration.expiringDomainEarlyWarningThreshold.time,
                            this.configuration.expiringDomainEarlyWarningThreshold.units
                        ),
                    },
                    validity: RecordValidity.All,
                }),
            },
        ];

        if (this.allowedCategories) {
            this.categories = this.categories.filter(c => this.allowedCategories.includes(c.name));
        }

        this.form = this.formBuilder.group({
            filter: this.formBuilder.control(''),
            category: this.formBuilder.control(this.selectedCategory ?? 'all'),
        });

        this.form.get('category')!.valueChanges.subscribe((val: DomainTableFilterCategory) => {
            this.selectedCategoryChanged.emit(val);
        });

        this.form
            .get('filter')!
            .valueChanges.pipe(debounceTime(300), takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.hideResults.next(true);
                this.reload();
            });

        this.form
            .get('category')!
            .valueChanges.pipe(debounceTime(0), takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.hideResults.next(true);
                this.setDefaultSorting();

                const sorting = this.sortingPerCategory?.[this.form.value.category as DomainTableFilterCategory];
                this.reload(sorting);
            });

        this.dataSource.initialLoading$.pipe(takeUntil(this.unsubscribe)).subscribe(l => {
            this.loadedWithFilter = !!this.form.value.filter;
            if (!l) {
                this.hideResults.next(false);
            }
        });

        this.reverseRecordService.current.pipe(takeUntil(this.unsubscribe)).subscribe(r => (this.userReverseRecord = r));
        this.tezosService.activeWallet.pipe(takeUntil(this.unsubscribe)).subscribe(w => (this.wallet = w));

        this.restoreState();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['hideFilters'] && !changes['hideFilters'].isFirstChange()) {
            return;
        }

        if ((changes['connected'] && !changes['connected'].isFirstChange()) || (changes['address'] && !changes['address'].isFirstChange())) {
            this.shouldSelectAllItems = this.selectAllOnLoad;
        }

        this.visibleColumns = [...this.columns];

        // check because changes fire before init
        if (this.categories && this.form) {
            this.restoreState();
        }

        this.updateVisibleColumns();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    scrolled() {
        this.dataSource.loadMore();
    }

    sortList() {
        this.selection.clear();
        this.domainsSelected.emit(this.selection.selected);

        this.reload();
    }

    clearSelection() {
        this.selection.clear();
        this.domainsSelected.next([]);
    }

    reload(sort?: DomainListSort) {
        this.updateVisibleColumns();

        if (this.infiniteScroll) {
            this.infiniteScroll.destroyScroller();
            this.infiniteScroll.setup();
        }

        const where: DomainsFilter = mergeObject(
            {
                ...this.baseFilter,
                name: { like: this.form.value.filter },
            },
            this.getCategoryFilter()
        );

        const variables: DomainsListQueryVariables = {
            where,
            order: this.getSort(sort),
            first: this.pageSize,
        };

        this.historyStateService.merge<DomainTableState>({
            domainTable: { form: this.form.value, sort: { field: this.sorter.active, direction: this.sorter.direction } },
        });

        this.dataSource.load(variables, undefined, { baseLevel: this.baseLevel });
    }

    toggleSelectAll(): void {
        this.areAllSelected() ? this.selection.clear() : this.selection.select(...this.currentSecondLevelDomains);
        this.domainsSelected.emit(this.selection.selected);
    }

    private selectAll(): void {
        this.selection.clear();
        this.selection.select(...this.currentSecondLevelDomains);
        this.domainsSelected.emit(this.selection.selected);
    }

    toggleSelectOne($event: MatCheckboxChange, row: DomainListRecord): void {
        if ($event) {
            this.selection.toggle(row);
            this.domainsSelected.emit(this.selection.selected);
        }
    }

    areAllSelected(): boolean {
        return this.selection.selected.length === this.currentSecondLevelDomains.length;
    }

    isLoggedOnUserDomain(domain: DomainListRecord): boolean {
        return domain.owner === this.wallet?.address || domain.parentOwner === this.wallet?.address || isOperator(domain, this.wallet?.address);
    }

    shouldShowExpiringDomainWarning(domain: DomainListRecord): boolean {
        return this.isLoggedOnUserDomain(domain) && domain.level === 2 && this.domainIsExpiringSoon(domain);
    }

    domainIsExpiringSoon(domain: DomainListRecord): boolean {
        return isExpiring(domain, this.configuration.expiringDomainThreshold);
    }

    isOperatorFor(domain: DomainListRecord): boolean {
        return isOperator(domain, this.address);
    }

    private updateVisibleColumns(): void {
        if (!this.connected) {
            this.visibleColumns = [...this.columns];
            return;
        }

        this.visibleColumns = ['selection', ...this.columns];
    }

    private restoreState() {
        this.hideResults.next(true);
        const state = this.historyStateService.get<DomainTableState>();

        if (state.domainTable) {
            this.form.setValue(state.domainTable.form, { emitEvent: false });
            this.sortField = state.domainTable.sort.field;
            this.sortDirection = state.domainTable.sort.direction;
        } else {
            this.setDefaultSorting();
        }

        setTimeout(() => this.reload());
    }

    private setDefaultSorting() {
        const sorting = this.sortingPerCategory?.[this.form.value.category as DomainTableFilterCategory];
        this.sortField = sorting?.field ?? DomainOrderField.Domain;
        this.sortDirection = sorting?.direction ?? 'asc';
    }

    private getCategoryFilter() {
        const categoryName = this.form.value.category;
        const category = this.categories.find(c => c.name === categoryName)!;
        return category.predicateBuilder();
    }

    private getSort(sort?: DomainListSort): DomainOrder {
        return {
            field: sort?.field ?? (this.sorter.active as any),
            direction: sort?.direction?.toUpperCase() ?? ((this.sorter.direction.toUpperCase() as any) || null),
        };
    }
}
