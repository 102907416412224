import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { combineLatest, interval, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith } from 'rxjs/operators';
import { Configuration } from '../configuration';
import { DataSourceFactory } from '../graphql/data-source-factory';
import { Stats } from '../graphql/stats-data-source';
import { TezosService } from '../tezos/tezos.service';

@Injectable({
    providedIn: 'root',
})
export class ExpiringDomainsStatsService {
    private expiringSoonDataSource = this.dataSourceFactory.createStatsDataSource();
    private expiringLaterDataSource = this.dataSourceFactory.createStatsDataSource();
    private refresh$ = new Subject<void>();
    private otherAddress$ = new Subject<string>();

    private readonly combinedStats$ = combineLatest([this.expiringLaterDataSource.data$, this.expiringSoonDataSource.data$]).pipe(
        map(([later, early]) => ({
            hasExpiringLater: later?.expiring != null && later?.expiring > 0,
            hasExpiringEarly: early?.expiring != null && early?.expiring > 0,
        })),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    private readonly expiringLaterStats$ = this.expiringLaterDataSource.data$.pipe(shareReplay({ bufferSize: 1, refCount: true }));

    constructor(private tezosService: TezosService, private dataSourceFactory: DataSourceFactory, private configuration: Configuration) {
        const intervalPoll = interval(5 * 60 * 1000).pipe(startWith(0));
        const refresh = this.refresh$.pipe(startWith(0));
        const otherAddress = this.otherAddress$.pipe(startWith(''));

        const addressProvider = combineLatest([this.tezosService.activeWallet, otherAddress]).pipe(
            map(([w, otherAddress]) => otherAddress || w?.address || ''),
            distinctUntilChanged()
        );

        combineLatest([addressProvider, intervalPoll, refresh])
            .pipe(filter(([address]) => !!address))
            .subscribe(([address]) => {
                this.expiringSoonDataSource.load({
                    owner: address,
                    expiringThreshold: dayjs().add(this.configuration.expiringDomainThreshold.time, this.configuration.expiringDomainThreshold.units),
                });

                const earlyWarning = this.configuration.expiringDomainEarlyWarningThreshold;

                this.expiringLaterDataSource.load({
                    owner: address,
                    expiringThreshold: dayjs().add(earlyWarning.time, earlyWarning.units),
                });
            });
    }

    combinedStatsFor$(address: string): Observable<{
        hasExpiringLater: boolean;
        hasExpiringEarly: boolean;
    }> {
        this.otherAddress$.next(address);
        return this.combinedStats$;
    }

    expiringLaterStatsFor$(address: string): Observable<Stats> {
        this.otherAddress$.next(address);
        return this.expiringLaterStats$;
    }

    refresh(): void {
        this.refresh$.next();
    }
}
