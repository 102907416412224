import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TaquitoTezosDomainsClient } from '@tezos-domains/taquito-client';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { FilterHistoryStateService } from '../browser/filter-history-state.service';
import { ListOption, ListOptionType } from '../filters/list-filter.component';
import { SortOption } from '../filters/sorter.component';
import { OfferOrderField } from '../graphql/graphql.generated';
import { HorizontalScrollComponent } from '../shared/horizontal-scroll.component';
import { BuyOfferFilterModel, BuyOfferFilterState, BuyOfferType } from './buy-offer.models';

@Component({
    selector: 'td-buy-offer-filter',
    templateUrl: './buy-offer-filter.component.html',
    styleUrls: ['./buy-offer-filter.component.scss'],
})
export class BuyOfferFilterComponent implements OnInit, OnChanges, OnDestroy {
    @Input() tzDomainsClient: TaquitoTezosDomainsClient;
    @Input() preferredOfferType?: BuyOfferType;
    @Input() showOfferTypeFilter = true;

    @Output() filterChange = new EventEmitter<BuyOfferFilterModel>();
    @ViewChild(HorizontalScrollComponent) horizontalScroll: HorizontalScrollComponent;

    sortOptions: SortOption[] = [
        { label: this.transloco.translate('buy-offer-list.sort.name-asc'), value: OfferOrderField.DomainName, direction: 'asc' },
        { label: this.transloco.translate('buy-offer-list.sort.name-desc'), value: OfferOrderField.DomainName, direction: 'desc' },
        { label: this.transloco.translate('buy-offer-list.sort.price-asc'), value: OfferOrderField.Price, direction: 'asc' },
        { label: this.transloco.translate('buy-offer-list.sort.price-desc'), value: OfferOrderField.Price, direction: 'desc' },
        { label: this.transloco.translate('buy-offer-list.sort.recent'), value: OfferOrderField.CreatedAt, direction: 'desc' },
        { label: this.transloco.translate('buy-offer-list.sort.oldest'), value: OfferOrderField.CreatedAt, direction: 'asc' },
        { label: this.transloco.translate('buy-offer-list.sort.ending-soon'), value: OfferOrderField.EndsAt, direction: 'asc' },
    ];
    defaultSort = this.sortOptions[4];

    initialDomain = '';
    initialSorting = this.defaultSort;
    initialOfferTypes: BuyOfferType[] = [];

    private offerTypeFilters = [BuyOfferType.Received, BuyOfferType.Sent];
    offerTypeOptions: ListOption[] = this.offerTypeFilters.map(c => this.categoryToOption(c));

    private domain$: BehaviorSubject<string>;
    private sorting$: BehaviorSubject<SortOption>;
    private offerTypes$: BehaviorSubject<BuyOfferType[]>;

    private formSubscription: Subscription | null = null;

    constructor(private historyStateService: FilterHistoryStateService, private transloco: TranslocoService) {}

    ngOnInit(): void {
        const state = this.historyStateService.get<BuyOfferFilterState>();

        this.initialDomain = state.buyOfferDomain ?? '';
        this.initialSorting = state.buyOfferSorting ?? this.defaultSort;
        this.initialOfferTypes = state.buyOfferTypes ?? (this.preferredOfferType ? [this.preferredOfferType] : []);
        this.domain$ = new BehaviorSubject<string>(this.initialDomain);
        this.sorting$ = new BehaviorSubject<SortOption>(this.initialSorting);
        this.offerTypes$ = new BehaviorSubject<BuyOfferType[]>(this.initialOfferTypes);

        this.initializeFilter();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes['tzDomainsClient'].isFirstChange()) {
            this.initializeFilter();
        }
    }

    domainChanged(data: string) {
        this.domain$.next(data);
    }

    offerTypesChanged(data: string[]) {
        this.offerTypes$.next(data.map(x => <BuyOfferType>x));
    }

    sortChange(data: SortOption): void {
        this.sorting$.next(data);
    }

    ngOnDestroy(): void {
        this.formSubscription?.unsubscribe();
        this.formSubscription = null;
    }

    private initializeFilter() {
        this.formSubscription?.unsubscribe();
        this.formSubscription = combineLatest([this.domain$, this.offerTypes$, this.sorting$])
            .pipe(
                debounceTime(300),
                tap(([domain, offerTypes, sorting]) => this.saveState(domain, offerTypes, sorting)),
                map(([domain, offerTypes, sorting]) => this.toFilterModel(domain, offerTypes, sorting)),
                distinctUntilChanged<BuyOfferFilterModel>(isEqual)
            )
            .subscribe(filter => {
                this.horizontalScroll?.refreshScroll();
                this.filterChange.next(filter);
            });
    }

    private toFilterModel(domain: string, offerTypes: BuyOfferType[], sorting: SortOption): BuyOfferFilterModel {
        return {
            domainName: domain,
            sorting,
            offerTypes,
            allOfferTypesSelected: !offerTypes.length || offerTypes.length === this.offerTypeOptions.length,
        };
    }

    private categoryToOption(categ: BuyOfferType): ListOption {
        return new ListOption(categ, this.translateCategory(categ), ListOptionType.Item);
    }

    private translateCategory(categ: BuyOfferType): string {
        /**
         * t(buy-offer-list.filters.offer-types.received)
         * t(buy-offer-list.filters.offer-types.sent)
         */
        return this.transloco.translate(`buy-offer-list.filters.offer-types.${categ}`);
    }

    private saveState(domain: string, offerTypes: BuyOfferType[], sorting: SortOption): void {
        this.historyStateService.merge<BuyOfferFilterState>({
            buyOfferDomain: domain,
            buyOfferSorting: sorting,
            buyOfferTypes: offerTypes,
        });
    }
}
