import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { ErrorModule } from '../error/error.module';
import { LayoutModule } from '../layout/layout.module';
import { ReverseRecordDetailComponent } from './reverse-record-detail.component';
import { ReverseRecordListComponent } from './reverse-record-list.component';
import { ReverseRecordTableComponent } from './reverse-record-table.component';
import { ReverseRecordFormComponent } from './reverse-record-form.component';
import { ReverseRecordSummaryTableComponent } from './reverse-record-summary-table.component';
import { SkeletonModule } from '../skeleton/skeleton.module';

@NgModule({
    declarations: [
        ReverseRecordDetailComponent,
        ReverseRecordListComponent,
        ReverseRecordTableComponent,
        ReverseRecordFormComponent,
        ReverseRecordSummaryTableComponent,
    ],
    imports: [SharedModule, ErrorModule, LayoutModule, ReactiveFormsModule, SkeletonModule],
    exports: [ReverseRecordSummaryTableComponent],
})
export class ReverseRecordsModule {}
