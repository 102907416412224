import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { DomainListingComponent } from './domain-listing.component';

@NgModule({
    declarations: [DomainListingComponent],
    exports: [DomainListingComponent],
    imports: [SharedModule],
})
export class DomainCommonModule {}
