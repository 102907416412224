import { Pipe, PipeTransform } from '@angular/core';
import escape from 'lodash-es/escape';

@Pipe({
    name: 'escape',
})
export class EscapePipe implements PipeTransform {
    transform(value: string | null | undefined): string {
        if (!value) {
            return '';
        }

        return escape(value);
    }
}
