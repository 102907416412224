import { ComponentFactory, ComponentFactoryResolver, ComponentRef, Injectable, Injector, Type } from '@angular/core';
import { decode } from 'js-base64';
import { safeJsonParse } from '../utils/convert';

export interface EmbeddableComponentFactoryInfo {
    // eslint-disable-next-line deprecation/deprecation
    factory: ComponentFactory<any>;
    selector: string;
    priority: number;
}

export class DynamicHtml {
    private embeddedComponents: ComponentRef<any>[] = [];

    addEmbeddedComponent(component: ComponentRef<any>) {
        this.embeddedComponents.push(component);
    }

    detectChanges() {
        this.embeddedComponents.forEach(comp => comp.changeDetectorRef.detectChanges());
    }

    destroy() {
        // destroy components otherwise there will be memory leaks
        this.embeddedComponents.forEach(comp => comp.destroy());
        this.embeddedComponents.length = 0;
    }
}

@Injectable({
    providedIn: 'root',
})
export class EmbeddableComponentsService {
    private componentFactories: EmbeddableComponentFactoryInfo[] = [];

    // no good replacement for this yet ...
    // eslint-disable-next-line deprecation/deprecation
    constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

    registerEmbeddableComponent(component: Type<any>, priority = 100) {
        const factory = this.componentFactoryResolver.resolveComponentFactory(component);

        let index = 0;

        for (index = 0; index < this.componentFactories.length; index++) {
            const info = this.componentFactories[index];
            if (priority >= info.priority) {
                break;
            }
        }

        const info = { factory, selector: factory.selector, priority };

        if (index === this.componentFactories.length) {
            this.componentFactories.push(info);
        } else {
            this.componentFactories.splice(index, 0, info);
        }
    }

    createDynamicHtml(element: HTMLElement, injector: Injector, content: string) {
        const dynamicHtml = new DynamicHtml();
        element.innerHTML = content || '';

        if (!content) {
            return dynamicHtml;
        }

        this.componentFactories.forEach(componentFactoryInfo => {
            const embeddedComponentElements = this.onlyTopmostParents(element.querySelectorAll(componentFactoryInfo.selector));

            embeddedComponentElements.forEach((element: any) => {
                if (element['hasEmbeddedComponent']) {
                    return;
                }

                const originalAttributes = new Map<string, string>();
                for (let i = 0; i < element.attributes.length; i++) {
                    const attribute = element.attributes.item(i)!;
                    originalAttributes.set(attribute.name, attribute.value);
                }
                const originalContent = element.innerHTML;

                const component = componentFactoryInfo.factory.create(injector, [], element);

                componentFactoryInfo.factory.inputs.forEach(input => {
                    if (input.templateName === 'content') {
                        component.instance[input.propName] = originalContent;
                    } else {
                        let value = originalAttributes.get(input.templateName.toLowerCase());
                        if (value) {
                            if (value.startsWith('B64:')) {
                                value = decode(value.slice(3));
                            }

                            const json = safeJsonParse(value);

                            component.instance[input.propName] = json != null ? json : value;
                        }
                    }
                });

                dynamicHtml.addEmbeddedComponent(component);

                element['hasEmbeddedComponent'] = true;
            });
        });

        return dynamicHtml;
    }

    private onlyTopmostParents(elements: NodeListOf<Element>) {
        const parents: Element[] = [];

        Array.from(elements).forEach(e => {
            if (!parents.some(p => this.isParentOf(p, e))) {
                parents.push(e);
            }
        });

        return parents;
    }

    private isParentOf(parent: Element, element: Element | null) {
        while (element != null) {
            if (element.parentElement === parent) {
                return true;
            }

            element = element.parentElement;
        }

        return false;
    }
}
