<div class="smooth-shadow card p-0" [ngClass]="{ 'drag-over': fileOverWindow }" *transloco="let t">
    <div class="full-card-width d-flex justify-content-start align-items-center">
        <div class="d-flex flex-grow-1">
            <div class="cta-content m-3 d-flex flex-column gap-4 flex-grow-1">
                <div>
                    <h1 class="mb-1 mb-lg-2">{{ t('buy-bulk.cta.title') }}</h1>
                </div>
                <h4 class="txt-secondary mb-0">
                    {{ showTextArea ? t('buy-bulk.cta.desc-text', { max: maxDomains }) : t('buy-bulk.cta.desc', { max: maxDomains }) }}
                </h4>
                <div class="d-flex flex-column">
                    <div class="d-flex flex-column align-items-end" *ngIf="!showTextArea">
                        <input #csvInput hidden="true" type="file" onclick="this.value=null" (change)="fileChange($event)" accept=".txt" />
                        <div class="d-flex align-items-center gap-3">
                            <span>{{ fileName$ | async | truncate : 18 }}</span>
                            <button mat-flat-button color="primary" (click)="csvInput.click()">
                                <fa-icon icon="file-lines"></fa-icon>
                                {{ t('buy-bulk.cta.choose') }}
                            </button>
                        </div>
                        <a (click)="toggleTextArea()" class="toggle-text-area pt-1">{{ t('buy-bulk.cta.choose-alt') }}</a>
                    </div>
                    <div *ngIf="showTextArea">
                        <textarea
                            rows="5"
                            class="w-100"
                            [value]="domainsInput"
                            (input)="domainsInput = $any($event.target).value ?? ''"
                            spellcheck="false"
                        ></textarea>
                        <div class="d-flex justify-content-end">
                            <button mat-button (click)="cancel()" class="me-2">{{ t('actions.cancel') }}</button>
                            <button mat-flat-button color="primary" (click)="continue()">{{ t('actions.check-domains') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="graphic mt-4 d-none d-sm-flex">
            <img src="/assets/img/register-cta.png" />
        </div>
    </div>
</div>
