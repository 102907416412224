import { ColorMode, NetworkType } from '@airgap/beacon-sdk';
import { Injectable } from '@angular/core';
import { BeaconWallet } from '@taquito/beacon-wallet';
import { Subject } from 'rxjs';

import { Configuration } from '../../configuration';
import { DarkModeService } from '../../shared/dark-mode/dark-mode.service';

export interface BeaconSupportedWallet {
    name: string;
    displayName: string;
    enabled: boolean;
    logo?: string;
    handler: () => void;
    type: string;
    extraData?: Record<string, any>;
}

@Injectable({
    providedIn: 'root',
})
export class BeaconService {
    private pairingStream = new Subject<void>();

    get pairing() {
        return this.pairingStream.asObservable();
    }

    constructor(private configuration: Configuration, private darkModeService: DarkModeService) {}

    createWallet(preferredNetwork?: NetworkType): BeaconWallet {
        return new BeaconWallet({
            colorMode: this.darkModeService.currentTheme === 'dark' ? ColorMode.DARK : ColorMode.LIGHT,
            name: this.configuration.appName,
            preferredNetwork,
            iconUrl: 'https://app.tezos.domains/assets/img/logo_notext.svg',
        });
    }
}
