<!-- t(activity.event.text.AUCTION_BID_EVENT) -->
<!-- t(activity.event.text.AUCTION_SETTLE_EVENT) -->
<!-- t(activity.event.text.DOMAIN_BUY_EVENT) -->
<!-- t(activity.event.text.DOMAIN_RENEW_EVENT) -->
<!-- t(activity.event.text.DOMAIN_GRANT_EVENT) -->
<!-- t(activity.event.text.OFFER_PLACED_EVENT) -->
<!-- t(activity.event.text.OFFER_EXECUTED_EVENT) -->
<!-- t(activity.event.text.OFFER_UPDATED_EVENT) -->

<!-- t(home.event.text.AUCTION_BID_EVENT) -->
<!-- t(home.event.text.AUCTION_SETTLE_EVENT) -->
<!-- t(home.event.text.DOMAIN_BUY_EVENT) -->
<!-- t(home.event.text.DOMAIN_RENEW_EVENT) -->
<!-- t(home.event.text.DOMAIN_GRANT_EVENT) -->
<!-- t(home.event.text.OFFER_PLACED_EVENT) -->
<!-- t(home.event.text.OFFER_EXECUTED_EVENT) -->

<!-- t(event.text.AUCTION_BID_EVENT) -->
<!-- t(event.text.AUCTION_BID_EVENT_ALT) -->
<!-- t(event.text.AUCTION_SETTLE_EVENT) -->
<!-- t(event.text.AUCTION_END_EVENT) -->
<!-- t(event.text.AUCTION_END_EVENT_ALT) -->
<!-- t(event.text.AUCTION_WITHDRAW_EVENT) -->
<!-- t(event.text.DOMAIN_BUY_EVENT) -->
<!-- t(event.text.DOMAIN_GRANT_EVENT) -->
<!-- t(event.text.DOMAIN_RENEW_EVENT) -->
<!-- t(event.text.DOMAIN_UPDATE_EVENT) -->
<!-- t(event.text.DOMAIN_SET_CHILD_RECORD_EVENT) -->
<!-- t(event.text.DOMAIN_SET_CHILD_RECORD_EVENT_ALT) -->
<!-- t(event.text.DOMAIN_COMMIT_EVENT) -->
<!-- t(event.text.REVERSE_RECORD_CLAIM_EVENT) -->
<!-- t(event.text.REVERSE_RECORD_CLAIM_EVENT_ALT) -->
<!-- t(event.text.REVERSE_RECORD_UPDATE_EVENT) -->
<!-- t(event.text.REVERSE_RECORD_UPDATE_EVENT_ALT) -->
<!-- t(event.text.DOMAIN_TRANSFER_EVENT) -->
<!-- t(event.text.DOMAIN_TRANSFER_EVENT_ALT) -->
<!-- t(event.text.DOMAIN_UPDATE_OPERATORS_EVENT) -->
<!-- t(event.text.OFFER_PLACED_EVENT) -->
<!-- t(event.text.OFFER_UPDATED_EVENT) -->
<!-- t(event.text.OFFER_EXECUTED_EVENT) -->
<!-- t(event.text.OFFER_EXECUTED_EVENT_ALT) -->
<!-- t(event.text.OFFER_REMOVED_EVENT) -->
<!-- t(event.text.BUY_OFFER_REMOVED_EVENT) -->
<!-- t(event.text.BUY_OFFER_PLACED_EVENT) -->
<!-- t(event.text.BUY_OFFER_EXECUTED_EVENT) -->
<!-- t(event.text.BUY_OFFER_EXECUTED_EVENT_ALT) -->

<div
    *transloco="let t"
    class="text-wrap"
    [tdDynamicHtml]="
        t((scope ? scope + '.' : '') + 'event.text.' + type, {
            duration: duration | humanizeDuration: false,
            name: domainName,
            amount: amount,
            address: address,
            reverseRecord: reverseRecord,
            tokenId: tokenId
        })
    "
></div>
