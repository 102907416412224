import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { getLabel, getTld, RecordMetadata } from '@tezos-domains/core';
import { CommitmentInfo, CommitmentRequest, DomainAcquisitionInfo } from '@tezos-domains/manager';
import { CalendarEvent } from 'calendar-link';
import dayjs from 'dayjs';
import { minBy, sum, without } from 'lodash-es';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { delay, first, map, retryWhen, switchMap, take } from 'rxjs/operators';
import { WindowRef } from '../../browser/window-ref';
import { Configuration } from '../../configuration';
import { NotificationsService } from '../../notifications/notifications.service';
import { OperationStatusDoneEvent } from '../../shared/operation-status.component';
import { TezosDomainsClientService } from '../../tezos/integration/tezos-domains-client.service';
import { TezosWallet } from '../../tezos/models';
import { SmartContractOperationEvent, TezosService } from '../../tezos/tezos.service';
import { emptyStringToNull } from '../../utils/convert';
import { ErrorPresenterService } from '../../utils/error-presenter.service';
import { TdValidators } from '../../utils/form-validators';
import { ExpiringDomainsStatsService } from '../expiring-domains-stats.service';
import { NonceService } from '../nonce.service';
import { NotificationEventGeneratorService } from '../notification-event-generator.service';
import { ReverseRecord, ReverseRecordService } from '../reverse-record.service';

export enum BuyPhase {
    INIT,
    COMMIT,
    BUY,
    SUBSCRIBE,
    DONE,
}

interface CommitmentState {
    commitment: CommitmentInfo | null;
    label: string;
    params: CommitmentRequest;
}

export interface BuyFormModel {
    duration: FormControl<number>;
    address: FormControl<string>;
    price: FormControl<number>;
}

@Component({
    selector: 'td-bulk-buy',
    templateUrl: './bulk-buy.component.html',
    styleUrls: ['./bulk-buy.component.scss'],
})
export class BulkBuyComponent implements OnInit {
    @Input() names: string[];
    @Input() wallet: TezosWallet;

    @Input() set acquisitionInfo(value: DomainAcquisitionInfo[]) {
        this.aq = value;
        this.updatePrice();
    }
    get acquisitionInfo(): DomainAcquisitionInfo[] {
        return this.aq;
    }

    @Output() bought = new EventEmitter<void>();

    commitOperation: Observable<SmartContractOperationEvent> | null;
    buyOperation: Observable<SmartContractOperationEvent> | null;
    reverseRecord: ReverseRecord | null;
    buyForm: FormGroup<BuyFormModel>;
    pricePerYear: number;
    phase: BuyPhase = BuyPhase.INIT;
    phases: typeof BuyPhase = BuyPhase;
    stepperAnimation = false;
    waitFrom: dayjs.Dayjs;
    waitUntil: dayjs.Dayjs;
    subscriptionEvent: CalendarEvent;
    showDomainNameLengthWarning: boolean;
    tld: string;

    readonly notificationSource = 'domain-registration';
    readonly notificationSubscriptionVisible$: Observable<{ visible: boolean }>;

    @ViewChild(MatStepper) stepper: MatStepper;

    private aq: DomainAcquisitionInfo[];
    private missingCommitments: CommitmentState[] = [];

    constructor(
        private tezosService: TezosService,
        private tezosDomainsClientService: TezosDomainsClientService,
        private reverseRecordService: ReverseRecordService,
        private nonceService: NonceService,
        private errorPresenterService: ErrorPresenterService,
        private expiringStats: ExpiringDomainsStatsService,
        private windowRef: WindowRef,
        private notificationEventGenerator: NotificationEventGeneratorService,
        public configuration: Configuration,
        formBuilder: FormBuilder,
        notificationsService: NotificationsService
    ) {
        this.buyForm = formBuilder.group({
            duration: formBuilder.control(1, {
                nonNullable: true,
                validators: [Validators.required, TdValidators.number(), Validators.min(1), Validators.max(100)],
            }),
            address: formBuilder.control('', { nonNullable: true, validators: [TdValidators.tezosAddress()] }),
            price: formBuilder.control(0, { nonNullable: true }),
        });

        this.notificationSubscriptionVisible$ = combineLatest([
            of(notificationsService.isEnabled),
            notificationsService.currentSubscription,
            notificationsService.promptBlocked(this.notificationSource),
        ]).pipe(map(([enabled, subscription, blocked]) => ({ visible: enabled && subscription.status !== 'confirmed' && !blocked })));
    }

    ngOnInit(): void {
        this.tld = getTld(this.names[0]);

        this.pricePerYear = sum(this.acquisitionInfo.map(info => info.calculatePrice(365)));

        this.reverseRecordService.current.pipe(first()).subscribe(r => {
            this.reverseRecord = r;
            this.checkForExistingCommitment(true);
            this.buyForm.setValue({
                address: this.wallet.address,
                duration: 1,
                price: 0,
            });
        });

        this.buyForm.get('duration')!.valueChanges.subscribe(() => this.updatePrice());
        this.updatePrice();
    }

    commit() {
        this.commitOperation = this.tezosService.execute(client => {
            return client.manager.batch(async b => await Promise.all(this.missingCommitments.map(async c => await b.commit(this.tld, c.params))));
        });
    }

    buy() {
        const form = this.buyForm.value;
        this.buyForm.disable();

        this.buyOperation = this.getMultipleCommitParams(this.names).pipe(
            switchMap(commitParams => {
                return this.tezosService.execute(client => {
                    return client.manager.batch(
                        async b =>
                            await Promise.all(
                                commitParams.map(
                                    async p =>
                                        await b.buy(this.tld, {
                                            ...p,
                                            duration: this.getDuration(),
                                            data: new RecordMetadata(),
                                            address: emptyStringToNull(form.address),
                                        })
                                )
                            )
                    );
                });
            })
        );
    }

    done() {
        this.bought.next();
        this.expiringStats.refresh();
    }

    commitDone(event: OperationStatusDoneEvent) {
        if (event.success) {
            this.checkForExistingCommitment(false);
        }
    }

    buyDone(event: OperationStatusDoneEvent, notificationStepVisible: boolean) {
        if (event.success) {
            this.names.forEach(name => this.nonceService.clearNonce(name, this.wallet.address));
            this.reverseRecordService.refresh();
            this.setNextPhase(notificationStepVisible);

            const domainExpiration = dayjs().add(this.getDuration(), 'days');
            const domains = this.names.map(n => ({ name: n, expires: domainExpiration }));
            this.subscriptionEvent = this.notificationEventGenerator.generateBulk(domains, this.generateReminderUrl());
        } else {
            this.buyForm.enable();
        }
    }

    setNextPhase(notificationStepVisible: boolean) {
        this.phase++;
        if (!notificationStepVisible && this.phase === BuyPhase.SUBSCRIBE) {
            this.phase++;
        }

        setTimeout(() => this.stepper.next());
    }

    skipSubscription() {
        this.setNextPhase(true);
    }

    get location() {
        return this.windowRef.nativeWindow.location.href;
    }

    private async checkForExistingCommitment(init: boolean) {
        let commitments = await combineLatest([this.tezosDomainsClientService.current, this.getMultipleCommitParams(this.names)])
            .pipe(
                first(),
                switchMap(([client, params]) =>
                    combineLatest(
                        params.map(p =>
                            client.manager
                                .getCommitment(this.tld, p)
                                .then(c => ({ commitment: c, label: p.label, params: p }))
                                .catch(() => ({ commitment: null, label: p.label, params: p }))
                        )
                    )
                ),
                switchMap(c => (c ? of(c) : throwError(new Error('NOT_FOUND')))),
                retryWhen(errors => (init ? of() : errors.pipe(delay(2000), take(3))))
            )
            .toPromise();

        setTimeout(() => (this.stepperAnimation = true), 1000);

        this.phase = BuyPhase.COMMIT;

        commitments = this.clearExpiredCommitments(commitments);

        const anyMissingCommitment = !commitments.length || commitments.some(x => !x.commitment);

        if (anyMissingCommitment) {
            if (!init) {
                this.errorPresenterService.nodeErrorToast('commitment-fetch', null);
            }
            this.missingCommitments = commitments.filter(x => !x.commitment);
            // commitment doesn't exist
            return;
        }

        this.waitFrom = dayjs(minBy(commitments, c => c.commitment?.created)?.commitment?.created);
        this.waitUntil = dayjs(minBy(commitments, c => c.commitment?.usableFrom)?.commitment?.usableFrom);
    }

    private updatePrice() {
        const totalPrice = sum(this.acquisitionInfo.map(info => info.calculatePrice(this.getDuration()))) / 1e6;
        this.buyForm.get('price')!.setValue(totalPrice);
    }

    private generateReminderUrl() {
        return `${location.origin}/address/${this.wallet.address}/domains?expiring=true`;
    }

    private getDuration() {
        return this.buyForm.get('duration')!.value * 365;
    }

    private clearExpiredCommitments(commitments: CommitmentState[] | undefined): CommitmentState[] {
        const now = dayjs();
        const expiredCommitments = commitments?.filter(c => now.isAfter(c?.commitment?.usableUntil)) ?? [];
        expiredCommitments.forEach(c => this.nonceService.clearNonce(c.label, this.wallet.address));
        commitments = without(commitments, ...expiredCommitments);

        return commitments;
    }

    private getMultipleCommitParams(names: string[]): Observable<CommitmentRequest[]> {
        return combineLatest(
            names.map(name =>
                this.nonceService.getNonce(getLabel(name), this.wallet.address).pipe(
                    map(nonce => {
                        return {
                            label: getLabel(name),
                            owner: this.wallet.address,
                            nonce,
                        };
                    })
                )
            )
        );
    }
}
