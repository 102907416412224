import { DataSourceBase, ProcessedData, DataSourceOptions } from '../data-source-base';
import { UserDataQuery, UserDataQueryVariables } from './graphql.generated';

export class UserDataDataSource extends DataSourceBase<UserDataQuery, UserDataQuery, UserDataQueryVariables> {
    constructor(options: DataSourceOptions<UserDataQuery, UserDataQueryVariables>) {
        super(options);
    }

    protected transformData(data: UserDataQuery): ProcessedData<UserDataQuery> {
        return {
            data,
        };
    }
}
