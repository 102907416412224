import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subject, Observable } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';

@Pipe({
    name: 'dataKey',
})
export class DataKeyPipe implements PipeTransform, OnDestroy {
    private unsubscribe = new Subject<void>();

    constructor(private translate: TranslocoService) {}

    transform(key: string): Observable<string> {
        return this.translate.selectTranslateObject<Record<string, string>>(`data.keys`).pipe(
            takeUntil(this.unsubscribe),
            map(translations => translations[key] || key)
        );
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
