<div class="card smooth-shadow pb-0" *transloco="let t">
    <h2 class="m-0">{{ t('domain-detail.auction.title-bids') }}</h2>

    <div class="table-container full-card-width">
        <mat-table class="table" [dataSource]="auction.bids">
            <ng-container matColumnDef="time">
                <mat-header-cell class="col-time" *matHeaderCellDef>{{ t('fields.when') }}</mat-header-cell>
                <mat-cell class="col-time" *matCellDef="let row">
                    <span [matTooltip]="row.timestamp | dayjsDate"><td-humanized-date [value]="row.timestamp"></td-humanized-date></span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="bidder">
                <mat-header-cell *matHeaderCellDef>{{ t('fields.who') }}</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div class="text-truncate pe-2 w-100">
                        <td-address [address]="row.bidder" [reverseRecord]="row.bidderReverseRecord"></td-address>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell class="col-badge" *matCellDef="let row">
                    <div class="pe-2" class="d-flex align-items-center gap-1">
                        <td-badge color="primary" *ngIf="row.bidder === wallet?.address">{{ t('badges.you') }}</td-badge>
                        <td-badge
                            color="success"
                            *ngIf="row.bidder === auction.highestBid.bidder && row.amount.toNumber() === auction.highestBid.amount.toNumber()"
                        >
                            {{ isEnded ? t('badges.winner') : t('badges.highest-bid') }}
                        </td-badge>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="amount">
                <mat-header-cell class="col-amount justify-content-center" *matHeaderCellDef>{{ t('fields.amount') }}</mat-header-cell>
                <mat-cell class="col-amount justify-content-center" *matCellDef="let row">
                    <td-tez [value]="row.amount"></td-tez>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="['time', 'bidder', 'status', 'amount']"></mat-header-row>
            <mat-row *matRowDef="let row; columns: ['time', 'bidder', 'status', 'amount']"></mat-row>
        </mat-table>
    </div>
</div>
