<form class="d-flex flex-column flex-fill h-100" [formGroup]="form" *transloco="let t">
    <h1 mat-dialog-title class="text-truncate flex-shrink-0">{{ t('gvPool.deposit.title') }}</h1>

    <mat-dialog-content class="d-flex flex-column flex-grow-1">
        <td-operation-status class="w-100" [operation]="operation" (done)="operationDone($event)" #op></td-operation-status>
        <div class="flex-shrink-0 d-flex flex-column gap-2">
            <ng-container *ngIf="!showDelegationSelector; else delegationSelector">
                <div class="d-flex">
                    <div class="flex-grow-1">{{ t('gvPool.deposit.available-to-deposit') }}</div>
                    <div>{{ data.poolStats.tedBalance | formatTed }} TED</div>
                </div>
                <td-modal-apr [stats]="data.poolStats"></td-modal-apr>
                <mat-form-field appearance="standard" class="amount my-3">
                    <mat-label>{{ t('gvPool.deposit.amount') }}</mat-label>
                    <input formControlName="amount" type="number" matInput min="0" required />
                    <button matSuffix mat-flat-button color="primary" data-testid="max" aria-label="Max" (click)="maxAmount($event)">
                        {{ t('gvPool.deposit.max') }}
                    </button>
                    <mat-error>
                        <td-validation-messages [control]="form.get('amount')!" [context]="{ name: '' }"></td-validation-messages>
                    </mat-error>
                </mat-form-field>
                <div class="d-flex">
                    <div class="flex-grow-1">{{ t('gvPool.deposit.you-receive') }}</div>
                    <div>{{ willReceive | formatTed }} TEDv</div>
                </div>
                <mat-divider class="my-3"></mat-divider>
                <div class="mb-3 d-flex flex-column gap-1">
                    <h2 class="mb-1">{{ t('gvPool.statistics.title') }}</h2>
                    <div class="d-flex">
                        <div class="flex-grow-1">{{ t('gvPool.statistics.total-votes') }}</div>
                        <div>{{ data.poolStats.poolSize | formatMutez }} TEDv</div>
                    </div>
                    <div class="d-flex">
                        <div class="flex-grow-1">{{ t('gvPool.statistics.total-voters') }}</div>
                        <div>{{ data.poolStats.totalVoters }}</div>
                    </div>
                </div>
            </ng-container>
            <ng-template #delegationSelector>
                <td-delegation-selector
                    (delegationSelected)="delegateSelected($event)"
                    [delegate]="data.delegate"
                    [showScroll]="false"
                ></td-delegation-selector>
            </ng-template>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex justify-content-end align-items-end gap-2 w-100 min-height-auto">
        <button mat-button type="button" (click)="cancel()" [disabled]="form.disabled">{{ t('actions.cancel') }}</button>
        <button
            mat-flat-button
            color="primary"
            (click)="deposit()"
            *ngIf="!showDelegationSelector"
            [disabled]="form.invalid || op.inProgress || !form.value.amount"
        >
            {{ data.delegate ? t('actions.deposit') : t('actions.continue') }}
        </button>
        <button
            mat-flat-button
            color="primary"
            (click)="depositAndDelegate()"
            *ngIf="showDelegationSelector"
            [disabled]="form.invalid || op.inProgress || (wantsToDelegate && !delegate)"
        >
            {{ wantsToDelegate ? t('actions.deposit-and-delegate') : t('actions.deposit') }}
        </button>
    </mat-dialog-actions>
</form>
