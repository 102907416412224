<div class="delegate p-2 d-flex align-items-center" [ngClass]="{ 'delegate-item_selected': selected, 'lg-layout': height === 'lg' }" *transloco="let t">
    <div class="ms-1 me-3">
        <img ngxGravatar *ngIf="gravatarHash || gravatarEmail" [md5Hash]="gravatarHash!" [email]="gravatarEmail!" [size]="24" />
        <identicon *ngIf="!gravatarHash && !gravatarEmail" [address]="address" [size]="24"></identicon>
    </div>
    <div [style.minWidth]="'calc(100% - 29px)'" class="d-flex flex-column">
        <span class="text-truncate rr text-monospace no-underline" [matTooltip]="name || ''">
            {{ name | truncate : 20 : 14 : 5 }}
        </span>
        <span class="text-monospace txt" *ngIf="!name">{{ address | truncate : 30 : 10 : 8 }}</span>
        <span class="text-monospace txt fw-bold" *ngIf="votingPower">{{ t('airdrop.delegate.voting-power', { amount: votingPower | formatMutez }) }}</span>
        <span class="text-monospace txt txt-sm" *ngIf="delegatorCount">{{ t('airdrop.delegate.delegator-count', { count: delegatorCount }) }}</span>

        <div class="d-flex gap-2">
            <div *ngIf="isPublic && !governanceUrl" class="d-flex">
                <a target="_blank" [matTooltip]="t('airdrop.delegate.missing-governance-tooltip')" class="no-underline missing-governance-url txt-warning">
                    <fa-icon icon="exclamation-triangle" class="txt-warning"></fa-icon>
                </a>
            </div>
            <div *ngIf="governanceUrl" class="d-flex">
                <a
                    target="_blank"
                    [matTooltip]="t('airdrop.delegate.governance-url-tooltip', { profile: governanceUrl })"
                    [href]="governanceUrl"
                    class="no-underline governance-url"
                >
                    <fa-icon [icon]="'landmark'" class="social-icon"></fa-icon>
                </a>
            </div>
            <td-tzprofile class="pt-1" [showGenericLink]="false" [address]="address" [tzProfile]="tzProfile"></td-tzprofile>
        </div>
    </div>
</div>
