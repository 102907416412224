import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { map, first } from 'rxjs/operators';

import { TezosService } from '../tezos/tezos.service';

@Injectable({
    providedIn: 'root',
})
export class LaunchGuard implements CanActivate {
    constructor(private router: Router, private tezosService: TezosService) {}

    canActivate() {
        return this.tezosService.activeWallet.pipe(
            first(),
            map(wallet => {
                if (!wallet) {
                    this.router.navigate(['/'], { replaceUrl: true });
                } else {
                    this.router.navigate(['/address', wallet.address], { replaceUrl: true });
                }

                return false;
            })
        );
    }
}
