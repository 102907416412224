import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

import { DataSourceFactory } from '../graphql/data-source-factory';
import { OfferState, OfferOrderField, OrderDirection } from '../graphql/graphql.generated';
import { OfferRecord, OfferTableDataSource } from '../graphql/offer-table-data-source';
import { OverlayService } from '../overlay.service';
import { TezosService } from '../tezos/tezos.service';

@Component({
    selector: 'td-offer-summary-table',
    templateUrl: './offer-summary-table.component.html',
    styleUrls: ['./offer-summary-table.component.scss'],
})
export class OfferSummaryTableComponent implements OnInit, OnChanges, OnDestroy {
    @Input() address: string;
    @Input() enableEmptyMessage: boolean;
    @Input() noShadow: boolean;

    dataSource: OfferTableDataSource;
    states = OfferState;
    columns: string[] = [];

    private emptyStream = new ReplaySubject<boolean>(1);
    private unsubscribe = new Subject<void>();

    get empty$() {
        return this.emptyStream;
    }

    constructor(private dataSourceFactory: DataSourceFactory, private tezosService: TezosService, private overlayService: OverlayService) {}

    ngOnInit() {
        this.dataSource = this.dataSourceFactory.createOfferTableDataSource();
        this.dataSource.empty$.pipe(takeUntil(this.unsubscribe), delay(0)).subscribe(this.emptyStream);

        this.tezosService.activeWallet.pipe(takeUntil(this.unsubscribe)).subscribe(w => {
            if (w?.address === this.address) {
                this.columns = ['name', 'expiration', 'price', 'status', 'actions'];
            } else {
                this.columns = ['name', 'expiration', 'price', 'status'];
            }
        });

        this.load();
    }

    ngOnChanges() {
        if (this.dataSource) {
            this.load();
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    edit(offer: OfferRecord) {
        this.overlayService.openPlaceOffer(offer, () => this.load());
    }

    cancel(offer: OfferRecord) {
        this.overlayService.openRemoveOffer(offer, () => this.load());
    }

    private load() {
        this.dataSource.load(
            {
                where: {
                    state: {
                        notIn: [OfferState.Removed],
                    },
                    sellerAddress: { equalTo: this.address },
                },
                order: { field: OfferOrderField.CreatedAt, direction: OrderDirection.Desc },
                first: 4,
            },
            {
                pollInterval: 15 * 60 * 1000,
            }
        );
    }
}
