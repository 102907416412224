import { DataSourceBase, ProcessedData, DataSourceOptions } from '../data-source-base';
import { StatsQuery, StatsQueryVariables } from './graphql.generated';

export type Stats = {
    bought: number | null;
    expiring: number | null;
    total: number;
};

export class StatsDataSource extends DataSourceBase<StatsQuery, Stats, StatsQueryVariables> {
    constructor(options: DataSourceOptions<StatsQuery, StatsQueryVariables>) {
        super(options);
    }

    protected transformData(data: StatsQuery): ProcessedData<Stats> {
        return {
            data: {
                bought: data.bought ? data.bought.totalCount : null,
                expiring: data.expiring ? data.expiring.totalCount : null,
                total: data.total!.totalCount,
            },
        };
    }
}
