<div class="smooth-shadow card p-0" *transloco="let t">
    <div class="full-card-width d-flex align-items-center">
        <div class="graphic mt-4 d-none d-sm-block">
            <img src="/assets/img/connect-cta.png" />
        </div>
        <div class="m-3 d-flex flex-column gap-3">
            <h1 class="text-wrap" [tdDynamicHtml]="title || t('cta.connect.title')"></h1>
            <h4 class="txt-secondary text-wrap" [tdDynamicHtml]="t('cta.connect.text')"></h4>
            <div class="d-flex">
                <button color="primary" mat-flat-button (click)="cta()">{{ t('cta.connect.button') }} <fa-icon icon="arrow-right"></fa-icon></button>
            </div>
        </div>
    </div>
</div>
