import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TdValidators } from '../utils/form-validators';
import { wrapFocus } from '../utils/hacks';

@UntilDestroy()
@Component({
    selector: 'td-address-filter',
    templateUrl: './address-filter.component.html',
    styleUrls: ['./address-filter.component.scss'],
})
export class AddressFilterComponent implements OnInit {
    @Input() initialAddress = '';
    @Output() addressChange = new EventEmitter<string>();

    @ViewChild('addressInput') addressInput!: ElementRef;
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    form: UntypedFormGroup;
    address: string;

    addressDisplay: 'noAddress' | 'address' = 'noAddress';

    constructor(private formBuilder: UntypedFormBuilder) {}

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            addressFilter: this.formBuilder.control('', TdValidators.tezosAddress()),
        });

        if (this.initialAddress) {
            this.form.get('addressFilter')?.setValue(this.initialAddress);
            this.address = this.initialAddress;
            this.addressDisplay = this.getAddressDisplay();
        }
    }

    onClick() {
        this.trigger.openMenu();
    }

    submit(): void {
        if (this.form.valid) {
            this.address = this.form.value.addressFilter;
            this.addressChange.next(this.address);
        }

        this.trigger.closeMenu();
    }

    clear() {
        this.form.reset();
        this.address = '';
        this.addressChange.next(this.address);
        this.trigger.closeMenu();
    }

    menuClosed() {
        this.addressDisplay = this.getAddressDisplay();
    }

    menuOpened() {
        wrapFocus(() => this.addressInput.nativeElement.focus());
    }

    isDisabled() {
        return !this.form.valid || !this.form.value.addressFilter;
    }

    private getAddressDisplay() {
        return this.address ? 'address' : 'noAddress';
    }
}
