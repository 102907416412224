import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import dayjs from 'dayjs';
import { of, ReplaySubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { BuyOfferRecord } from '../graphql/buy-offer-table-data-source';
import { TezosWallet } from '../tezos/models';
import { TezosService } from '../tezos/tezos.service';
import { StorageSchema } from '../utils/storage';

export interface IgnoredOfferValue {
    expiration: string;
}

@Injectable({
    providedIn: 'root',
})
export class IgnoredOffersService {
    private ignoredOffersStream = new ReplaySubject<Map<string, IgnoredOfferValue>>(1);
    private ignoredOffers = new Map<string, IgnoredOfferValue>();
    private wallet: TezosWallet | null = null;

    readonly ignoredOffers$ = this.ignoredOffersStream.asObservable();

    constructor(private storageMap: StorageMap, private tezosService: TezosService) {
        this.tezosService.activeWallet
            .pipe(
                tap(w => (this.wallet = w)),
                switchMap(w => {
                    if (!w) {
                        return of(new Map());
                    }
                    const storage = StorageSchema.ignoredBuyOffers(w.address);
                    return this.storageMap.get<[string, IgnoredOfferValue][]>(storage.key, storage.schema);
                }),
                map(data => new Map(data))
            )
            .subscribe(offers => {
                this.ignoredOffers = this.cleanupOffers(offers);
                this.ignoredOffersStream.next(this.ignoredOffers);

                this.saveIgnoredOffers(this.ignoredOffers);
            });
    }

    ignore(offer: BuyOfferRecord): void {
        const ignoredOfferKey = offer.id;

        //if no expiration date on the buy offer, add a 1 year expiration so we cleanup the local storage at some point
        const ignoredOffersSet = this.ignoredOffers.set(ignoredOfferKey, {
            expiration: offer.expiration?.format() ?? dayjs().add(1, 'year').format(),
        });
        this.saveIgnoredOffers(ignoredOffersSet);
        this.ignoredOffersStream.next(this.ignoredOffers);
    }

    remove(offer: BuyOfferRecord): void {
        const ignoredOfferKey = offer.id;

        this.ignoredOffers.delete(ignoredOfferKey);
        this.saveIgnoredOffers(this.ignoredOffers);

        this.ignoredOffersStream.next(this.ignoredOffers);
    }

    isIgnored(offer: BuyOfferRecord): boolean {
        return this.ignoredOffers.has(offer.id);
    }

    allIgnoredOffers(): string[] {
        return Array.from(this.ignoredOffers.keys());
    }

    private saveIgnoredOffers(data: Map<string, IgnoredOfferValue>): void {
        if (!this.wallet) {
            return;
        }

        const storage = StorageSchema.ignoredBuyOffers(this.wallet.address);
        this.storageMap.set(storage.key, Array.from(data), storage.schema).subscribe();
    }

    private cleanupOffers(offers: Map<string, IgnoredOfferValue>): Map<string, IgnoredOfferValue> {
        const keysToRemove: string[] = [];
        const now = dayjs();
        const result = new Map(offers);

        for (const [key, value] of offers) {
            if (dayjs(value.expiration).isBefore(now)) {
                keysToRemove.push(key);
            }
        }

        keysToRemove.forEach(key => result.delete(key));

        return result;
    }
}
