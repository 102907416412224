import { Component, Input, OnChanges } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { combineLatest, ReplaySubject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { TezosService } from '../tezos/tezos.service';

@Component({
    selector: 'td-operator-badge',
    templateUrl: './operator-badge.component.html',
    styleUrls: ['./operator-badge.component.scss'],
})
export class OperatorBadgeComponent implements OnChanges {
    @Input() address: string;

    private address$ = new ReplaySubject<string>();
    private walletAddress$ = this.tezosService.activeWallet.pipe(map(w => w?.address));

    tooltip$ = combineLatest([this.address$.pipe(shareReplay()), this.walletAddress$]).pipe(
        map(([address, walletAddress]) => this.getOperatorTooltip(address, walletAddress))
    );

    constructor(private tezosService: TezosService, private transloco: TranslocoService) {}

    ngOnChanges(): void {
        this.address$.next(this.address);
    }

    private getOperatorTooltip(address: string, walletAddress: string | undefined): string {
        return address === walletAddress ? this.transloco.translate('domain.operator-tooltip') : this.transloco.translate('domain.other-operator-tooltip');
    }
}
