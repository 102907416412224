<form action="." class="action-input-container" (ngSubmit)="submit()" *transloco="let t">
    <div class="d-flex justify-content-center align-items-center">
        <div class="input-container d-flex flex-grow-1" [ngClass]="{ focused: hasFocus }">
            <input
                #searchInput
                autocapitalize="off"
                autocomplete="off"
                autocorrect="off"
                [attr.type]="inputType"
                name="action"
                class="w-100"
                [(ngModel)]="input"
                (ngModelChange)="inputChange()"
                [placeholder]="placeholder"
                (focus)="hasFocus = true"
                (blur)="hasFocus = false"
            />
        </div>
        <button *ngIf="buttonType === 'icon' && buttonIcon" type="submit" mat-stroked-button [color]="buttonColor">
            <fa-icon [icon]="buttonIcon" class="mx-0"></fa-icon>
        </button>
        <button class="flex-shrink-0" *ngIf="buttonType === 'button'" type="submit" mat-flat-button [color]="buttonColor">
            <fa-icon *ngIf="buttonIcon" [icon]="buttonIcon"></fa-icon>
            <span>{{ buttonText }}</span>
        </button>
    </div>
    <div class="blue"></div>
</form>
