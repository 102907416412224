import { formatNumber } from '@angular/common';
import { LOCALE_ID, Pipe, PipeTransform, inject } from '@angular/core';
import BigNumber from 'bignumber.js';
import { FromMutezPipe } from './from-mutez.pipe';

@Pipe({
    name: 'formatMutez',
})
export class FormatMutezPipe implements PipeTransform {
    private fromMutez = inject(FromMutezPipe);
    private locale = inject(LOCALE_ID);

    transform(value: number | BigNumber | undefined | null, format = '1.0-2'): string {
        if (value == null) {
            return '';
        }

        const val = this.fromMutez.transform(value);
        return formatNumber(val, this.locale, format) ?? '';
    }
}
