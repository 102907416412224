<div class="d-flex flex-column flex-fill h-100" *transloco="let t">
    <div class="d-flex flex-column">
        <h1 mat-dialog-title class="text-truncate">{{ t('bulk-remove-buy-offer-form.title', { count: offers.length }) }}</h1>
        <td-operation-status class="w-100" [operation]="operation" (done)="operationDone($event)" #op></td-operation-status>
    </div>
    <mat-dialog-content class="d-flex flex-column flex-grow-1 gap-2">
        {{ t('bulk-remove-buy-offer-form.info', { count: offers.length }) }}
        <div class="mt-2"><td-tez class="fw-bold" [value]="totalAmountReturned"></td-tez> {{ t('bulk-remove-buy-offer-form.money-returned') }}</div>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex justify-content-end align-items-end w-100 min-height-auto">
        <button mat-flat-button color="primary" (click)="save()" [disabled]="submit || op.inProgress">
            {{ t('actions.buy-offer.withdraw-bulk', { count: offers.length }) }}
        </button>
        <button mat-button (click)="cancel()" [disabled]="op.inProgress">{{ t('actions.cancel') }}</button>
    </mat-dialog-actions>
</div>
