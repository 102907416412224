import { DataSource } from '@angular/cdk/table';

import { Unarray } from '../utils/types';
import { DataSourceBase, DataSourceOptions, ProcessedData } from '../data-source-base';
import { DomainsWithAuctionListQuery, DomainsWithAuctionListQueryVariables } from './graphql.generated';

export type DomainWithAuctionListRecord = Unarray<DomainsWithAuctionListQuery['domains']['edges']>['node'];

export class DomainsWithAuctionListDataSource
    extends DataSourceBase<DomainsWithAuctionListQuery, DomainWithAuctionListRecord[], DomainsWithAuctionListQueryVariables>
    implements DataSource<DomainWithAuctionListRecord>
{
    constructor(options: DataSourceOptions<DomainsWithAuctionListQuery, DomainsWithAuctionListQueryVariables>) {
        super({
            ...options,
            defaultVariables: { first: 30 },
        });
    }

    protected transformData(data: DomainsWithAuctionListQuery): ProcessedData<DomainWithAuctionListRecord[]> {
        return {
            data: data.domains.edges.map(e => e.node),
            pageInfo: data.domains.pageInfo,
            isEmpty: !data.domains.edges.length,
        };
    }
}
