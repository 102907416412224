import { Component, Input, OnInit, ViewChild, inject } from '@angular/core';
import { MatSort, SortDirection } from '@angular/material/sort';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { BehaviorSubject } from 'rxjs';
import { HistoryStateService } from '../browser/history-state.service';
import { DelegatorListDataSource } from '../governance-core/delegators-data-source';
import { CurrentDelegations_Order_By, DelegatorListQueryVariables } from '../governance-core/governance-graphql.generated';
import { GovernanceDataSourceFactory } from '../governance-core/pool-data-source-factory';

export type DelegatorTableState = {
    delegatorTable?: {
        sort: {
            field: string;
            direction: SortDirection;
        };
    };
};

@UntilDestroy()
@Component({
    selector: 'td-delegator-table',
    templateUrl: './delegator-table.component.html',
    styleUrls: ['./delegator-table.component.scss'],
})
export class DelegatorTableComponent implements OnInit {
    private readonly dataSourceFactory = inject(GovernanceDataSourceFactory);
    private readonly historyStateService = inject(HistoryStateService);

    @Input() address: string;

    @ViewChild(MatSort) sorter: MatSort;
    @ViewChild(InfiniteScrollDirective) infiniteScroll: InfiniteScrollDirective;

    hideResults = new BehaviorSubject<boolean>(true);
    dataSource: DelegatorListDataSource;

    sortField: string;
    sortDirection: SortDirection;

    ngOnInit(): void {
        this.dataSource = this.dataSourceFactory.createDelegatorsDataSource();

        this.dataSource.initialLoading$.pipe(untilDestroyed(this)).subscribe(l => {
            if (!l) {
                this.hideResults.next(false);
            }
        });
        this.restoreState();
    }

    scrolled() {
        this.dataSource.loadMore();
    }

    reload() {
        if (this.infiniteScroll) {
            this.infiniteScroll.destroyScroller();
            this.infiniteScroll.setup();
        }

        const variables: DelegatorListQueryVariables = {
            address: this.address,
            order_by: this.getSort(),
        };

        this.historyStateService.merge<DelegatorTableState>({
            delegatorTable: { sort: { field: this.sorter.active, direction: this.sorter.direction } },
        });

        this.dataSource.load(variables);
    }

    sortChanged() {
        this.reload();
    }

    private restoreState() {
        this.hideResults.next(true);
        const state = this.historyStateService.get<DelegatorTableState>();

        if (state.delegatorTable) {
            this.sortField = state.delegatorTable.sort.field;
            this.sortDirection = state.delegatorTable.sort.direction;
        } else {
            this.setDefaultSorting();
        }

        setTimeout(() => this.reload());
    }

    private setDefaultSorting() {
        this.sortField = 'amount';
        this.sortDirection = 'desc';
    }

    private getSort(): CurrentDelegations_Order_By {
        return {
            [this.sorter.active]: this.sorter.direction,
        };
    }
}
