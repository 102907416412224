import { DataSource } from '@angular/cdk/table';
import { assign, keyBy } from 'lodash-es';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DataSourceBase, DataSourceOptions, DataSourcePagination, ProcessedData } from '../data-source-base';
import { ReverseRecordListGQL, ReverseRecordListQuery } from '../graphql/graphql.generated';
import { Unarray } from '../utils/types';
import { GovernanceDataSourcePagination } from './governance-datasource-pagination';
import { DelegatorListQuery, DelegatorListQueryVariables } from './governance-graphql.generated';
export type Delegator = Unarray<DelegatorListQuery['currentDelegations']>;
export type RR = Unarray<ReverseRecordListQuery['reverseRecords']['edges']>;
export type ReverseRecordDomain = RR['node'];
export type ExpandedDelegator = Delegator & { reverseRecord: ReverseRecordDomain | null };

const PageSize = 30;

export class DelegatorListDataSource
    extends DataSourceBase<DelegatorListQuery, ExpandedDelegator[], DelegatorListQueryVariables, ReverseRecordDomain>
    implements DataSource<Delegator>
{
    private rrRecordDictionary: Record<string, ReverseRecordDomain>;

    constructor(options: DataSourceOptions<DelegatorListQuery, DelegatorListQueryVariables>, private rrListGql: ReverseRecordListGQL) {
        super({
            ...options,
            defaultVariables: { limit: PageSize },
        });

        this.stopQuery.subscribe(() => {
            this.rrRecordDictionary = {};
        });
    }

    protected getExtraData(data: DelegatorListQuery): Observable<ReverseRecordDomain[]> | null {
        if (!data) {
            return null;
        }

        const visibleDataSet = data.currentDelegations.slice(0, this.pagination.currentPagingState.limit + this.pagination.currentPagingState.offset);
        const lastPageDelegators = visibleDataSet.slice(-PageSize);

        return this.rrListGql.fetch({ where: { address: { in: lastPageDelegators.map(d => d.from!) } }, first: PageSize }).pipe(
            map(data => {
                return data.data.reverseRecords.edges.filter(e => e.node.domain).map(e => e.node);
            }),
            tap(rrDomains => {
                const rrDict = keyBy(rrDomains, 'address');
                this.rrRecordDictionary = assign(this.rrRecordDictionary, rrDict);
            })
        );
    }

    protected transformData(data: DelegatorListQuery): ProcessedData<ExpandedDelegator[]> {
        return {
            data: data.currentDelegations
                .slice(0, this.pagination.currentPagingState.limit + this.pagination.currentPagingState.offset)
                .map(c => ({ ...c, reverseRecord: this.rrRecordDictionary[c.from!] ?? null })),
            isEmpty: !data.currentDelegations.length,
            totalCount: data.currentDelegations.length,
        };
    }

    protected createPagination(): DataSourcePagination<DelegatorListQueryVariables> {
        return new GovernanceDataSourcePagination<DelegatorListQueryVariables>({ limit: PageSize });
    }
}
