import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { RecordMetadata, StandardRecordMetadataKey } from '@tezos-domains/core';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { GovActionService } from '../airdrop/gov-action.service';
import { WindowRef } from '../browser/window-ref';
import { ReverseRecord, ReverseRecordService } from '../domains/reverse-record.service';
import { OperationStatusDoneEvent } from '../shared/operation-status.component';
import { SmartContractOperationEvent, TezosService } from '../tezos/tezos.service';
import { dataArrayToObj } from '../utils/convert';
import { DelegationStatusService } from './delegation-status.service';

export enum SelfDelegationStatus {
    NoDomain,
    NoProfile,
    NotDelegated,
    SelfDelegated,
}

@Component({
    selector: 'td-self-delegate-page',
    templateUrl: './self-delegate-page.component.html',
    styleUrls: ['./self-delegate-page.component.scss'],
})
export class SelfDelegatePageComponent {
    private readonly tezosService = inject(TezosService);
    private readonly rrService = inject(ReverseRecordService);
    private readonly govActionService = inject(GovActionService);
    private readonly delegationStatusService = inject(DelegationStatusService);
    private readonly windowRef = inject(WindowRef);
    private readonly router = inject(Router);

    operation: Observable<SmartContractOperationEvent> | null = null;
    operationInProgress = false;
    selfDelegationStatus = SelfDelegationStatus;

    vm$ = combineLatest([this.tezosService.activeWallet, this.rrService.current, this.delegationStatusService.delegationStatus$]).pipe(
        map(([wallet, reverseRecord, delegationStatus]) => {
            if (!wallet) {
                return { unauthenticated: true };
            }

            if (!reverseRecord?.domain) {
                return { status: SelfDelegationStatus.NoDomain };
            }

            const domainUrl =
                this.windowRef.nativeWindow.location.origin + this.router.serializeUrl(this.router.createUrlTree(['/domain', reverseRecord.domain.name]));

            if (this.needsProfile(reverseRecord)) {
                return { status: SelfDelegationStatus.NoProfile, domain: reverseRecord.domain.name, domainUrl, address: wallet.address };
            }

            return {
                status: delegationStatus?.address === wallet.address ? SelfDelegationStatus.SelfDelegated : SelfDelegationStatus.NotDelegated,
                domain: reverseRecord.domain.name,
                domainUrl,
                address: wallet.address,
            };
        })
    );

    becomeDelegate(address: string) {
        this.operationInProgress = true;
        this.operation = this.govActionService.q.delegate(address).send();
    }

    removeDelegation() {
        this.operationInProgress = true;
        this.operation = this.govActionService.q.delegate().send();
    }

    operationDone(event: OperationStatusDoneEvent) {
        this.operationInProgress = false;
        if (event.success) {
            this.delegationStatusService.refresh();
        }
    }

    private needsProfile(reverseRecord: ReverseRecord | null): boolean {
        if (!reverseRecord?.domain?.data) {
            return true;
        }

        const metadata = new RecordMetadata(dataArrayToObj(reverseRecord?.domain?.data));
        const profileUrl = metadata.getJson(StandardRecordMetadataKey.GOVERNANCE_PROFILE_URL);

        return !profileUrl;
    }
}
