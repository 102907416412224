import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';

import { DomainRecordFormComponent } from '../domains/domain-record-form.component';
import { OperationStatusDoneEvent } from '../shared/operation-status.component';
import { SmartContractOperationEvent } from '../tezos/tezos.service';
import { TdValidators } from '../utils/form-validators';
import { DirectBrokerService } from '../tezos/nft/direct-broker.service';
import { OfferRecord } from '../graphql/offer-table-data-source';

@Component({
    selector: 'td-place-offer',
    templateUrl: './place-offer.component.html',
    styleUrls: ['./place-offer.component.scss'],
})
export class PlaceOfferComponent implements OnInit {
    form: UntypedFormGroup;
    operation: Observable<SmartContractOperationEvent> | null;

    offer: OfferRecord;
    isEdit: boolean;
    success: boolean;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private directBrokerService: DirectBrokerService,
        @Inject(MAT_DIALOG_DATA) private data: { offer: OfferRecord },
        private dialogRef: MatDialogRef<DomainRecordFormComponent>
    ) {}

    ngOnInit() {
        this.offer = this.data.offer;
        this.isEdit = !!this.offer.seller;

        this.form = this.formBuilder.group({
            price: this.formBuilder.control(this.isEdit ? new BigNumber(this.offer.price).dividedBy(1e6).toNumber() : '', [
                Validators.required,
                TdValidators.number(6),
                Validators.min(1),
            ]),
            expiration: this.formBuilder.control(this.offer?.expiration ? this.offer.expiration.diff(dayjs(), 'days') + 1 : 90, {
                validators: [TdValidators.number()],
            }),
        });
    }

    operationDone(event: OperationStatusDoneEvent) {
        if (event.success) {
            if (this.isEdit) {
                this.dialogRef.close(true);
            } else {
                this.dialogRef.updateSize('600px', '300px');
                this.success = true;
            }
        } else {
            this.form.enable();
        }
    }

    cancel() {
        this.dialogRef.close(false);
    }

    continue() {
        this.dialogRef.close(true);
    }

    save() {
        const params = {
            price: new BigNumber(this.form.value.price).times(1e6).toNumber(),
            expiration: this.form.value.expiration ? dayjs().add(this.form.value.expiration, 'days').toDate() : null,
            tokenId: this.offer.tokenId!,
        };

        this.form.disable();

        this.operation = this.directBrokerService.placeOffer(params);
    }
}
