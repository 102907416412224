<div class="d-flex flex-column">
    <mat-form-field *transloco="let t" appearance="standard" class="w-100">
        <mat-label *ngIf="mode === 'bytes'">{{ t('fields.bytes-value') }}</mat-label>
        <mat-label *ngIf="mode === 'json'">{{ t('fields.json-value') }}</mat-label>
        <input matInput [formControl]="innerControl" type="text" [placeholder]="t('general.enter-value')" />
        <div matSuffix class="d-flex gap-1">
            <td-badge [color]="mode === 'json' ? 'primary' : 'accent'" [disabled]="innerControl.disabled" [hover]="mode !== 'json'">
                <div
                    class="badge-button d-flex align-items-center"
                    (click)="setMode('json'); $event.stopPropagation()"
                    [matTooltip]="t('data-editor.unknown-bytes-tooltip')"
                    [matTooltipDisabled]="control.disabled"
                    [ngClass]="{ 'no-underline': control.disabled }"
                >
                    <span class="font-monospace">{{ '{}' }}</span>
                </div>
            </td-badge>
            <td-badge [color]="mode === 'bytes' ? 'primary' : 'accent'" [disabled]="control.disabled" [hover]="mode !== 'bytes'">
                <div
                    class="badge-button d-flex align-items-center"
                    (click)="setMode('bytes'); $event.stopPropagation()"
                    [matTooltip]="t('data-editor.unknown-json-tooltip')"
                    [matTooltipDisabled]="control.disabled"
                    [ngClass]="{ 'no-underline': control.disabled }"
                >
                    <span class="font-monospace">0x</span>
                </div>
            </td-badge>
        </div>
        <mat-error><td-validation-messages [control]="innerControl" [context]="{ name: key }"></td-validation-messages></mat-error>
    </mat-form-field>
</div>
