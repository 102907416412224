import { Component, OnInit } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { TdValidators } from '../../../utils/form-validators';
import { EditorComponentBase } from './editor-component-base';

export type StringEditorType = 'text' | 'email' | 'url' | 'twitter' | 'governanceProfile';

export interface StringEditorArgs {
    type?: StringEditorType;
    prefix?: string;
}

@Component({
    selector: 'td-string',
    templateUrl: './string.component.html',
    styleUrls: ['./string.component.scss'],
})
export class StringComponent extends EditorComponentBase<StringEditorArgs> implements OnInit {
    type: StringEditorType;
    prefix: string | null;

    ngOnInit() {
        this.type = this.args?.type || 'text';

        this.prefix = this.args?.prefix || null;

        super.ngOnInit();
    }

    protected createValidators() {
        const validators: ValidatorFn[] = [];

        switch (this.args?.type) {
            case 'email':
                validators.push(Validators.email);
                break;
            case 'url':
                validators.push(TdValidators.url());
                break;
            case 'twitter':
                validators.push(TdValidators.twitterHandle);
                this.type = 'text';
                break;
            case 'governanceProfile':
                validators.push(TdValidators.governanceProfile);
                break;
        }

        return validators;
    }
}
