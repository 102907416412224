import { Injectable } from '@angular/core';
import { WindowRef } from './window-ref';

export type TrackingData = Record<string, any>;

@Injectable({
    providedIn: 'root',
})
export class TrackingService {
    constructor(private windowRef: WindowRef) {}

    private get dataLayer(): any[] {
        return this.windowRef.nativeWindow['dataLayer' as any] as any;
    }

    gtag(..._args: any[]): void {
        if (this.dataLayer) {
            // eslint-disable-next-line prefer-rest-params
            this.dataLayer.push(arguments);
        }
    }

    event(eventName: string, eventData: TrackingData | null = null) {
        this.gtag('event', eventName, eventData);
    }

    properties(props: any) {
        this.gtag('set', 'user_properties', props);
    }

    exception(description: string, fatal = false) {
        this.event('exception', {
            description,
            fatal,
        });
    }
}
