import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { map, timeout } from 'rxjs/operators';
import { Configuration } from '../configuration';
import { BlockGQL, BlockQuery } from '../graphql/graphql.generated';
import { GovBlockGQL, GovBlockQuery } from '../governance-core/governance-graphql.generated';
import { Unarray } from '../utils/types';
import { TezosError, TezosErrorCode } from './models';

@Injectable({
    providedIn: 'root',
})
export class IndexerService {
    constructor(private blockGQL: BlockGQL, private govBlockGQL: GovBlockGQL, private configuration: Configuration) {}

    whenBlockIndexed(hash: string, usingGovIndexer: boolean): Observable<void> {
        return new Observable(observer => {
            const domainsIndexer = this.blockGQL
                .watch({ hash }, { fetchPolicy: 'network-only', errorPolicy: 'none', pollInterval: 2000 })
                .valueChanges.pipe(map(({ data }) => this.toUnifiedBlock(data.block)));

            const govIndexer = this.govBlockGQL
                .watch({ hash }, { fetchPolicy: 'network-only', errorPolicy: 'none', pollInterval: 2000 })
                .valueChanges.pipe(map(({ data }) => this.toUnifiedBlock(data.blocks[0])));

            const query = usingGovIndexer ? govIndexer : domainsIndexer;

            observer.add(
                query.pipe(timeout(this.configuration.network.blockIndexedTimeoutMs)).subscribe({
                    next: block => {
                        if (block) {
                            observer.next();
                            observer.complete();
                        }
                    },
                    error: err => {
                        if (err.name === 'TimeoutError') {
                            observer.error(
                                new TezosError(
                                    'Timeout while waiting for indexer.',
                                    usingGovIndexer ? TezosErrorCode.GOV_INDEXER_TIMEOUT : TezosErrorCode.INDEXER_TIMEOUT
                                )
                            );
                        } else {
                            observer.error(err);
                        }
                    },
                })
            );
        });
    }

    private toUnifiedBlock(
        block?: Unarray<GovBlockQuery['blocks']> | BlockQuery['block'] | null
    ): { hash: string; level: number; timestamp: dayjs.Dayjs } | null {
        if (!block) {
            return null;
        }

        return {
            hash: block.hash,
            level: block.level,
            timestamp: block.timestamp,
        };
    }
}
