import { Injectable } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { ThemeType } from './dark-mode.model';

@Injectable({ providedIn: 'root' })
export class BrowserDarkModeService {
    private readonly mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    readonly mode: Observable<ThemeType> = fromEvent(this.mediaQuery, 'change').pipe(
        startWith(this.mediaQuery),
        map((list: MediaQueryList) => (list.matches ? 'dark' : 'light') as ThemeType),
        shareReplay({ bufferSize: 1, refCount: true })
    );
}
