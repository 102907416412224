import { Component, Input } from '@angular/core';
import { AppService } from '../app-service';

@Component({
    selector: 'td-connect-cta',
    templateUrl: './connect-cta.component.html',
    styleUrls: ['./connect-cta.component.scss'],
})
export class ConnectCtaComponent {
    @Input() title: string;

    constructor(private appService: AppService) {}

    cta() {
        this.appService.openConnect();
    }
}
