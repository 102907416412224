<form class="d-flex flex-column flex-fill h-100" [formGroup]="form" *transloco="let t">
    <h1 mat-dialog-title class="text-truncate flex-shrink-0">{{ t('renew.title', { name: domain.name }) }}</h1>
    <mat-dialog-content class="d-flex flex-column flex-grow-1">
        <td-operation-status class="w-100" [operation]="operation" (done)="operationDone($event)" #op></td-operation-status>
        <div class="flex-shrink-0 d-flex flex-column gap-2">
            <span>{{ t('renew.current-expiry', { expires: domain.expires! | dayjsDate }) }}</span>
            <td-alert *ngIf="wallet?.address !== domain.owner" state="warning">{{ t('renew.another-owner-warning') }}</td-alert>
            <mat-form-field appearance="standard">
                <mat-label>{{ t('fields.registration-period') }}</mat-label>
                <input formControlName="duration" type="number" matInput max="100" min="1" required />
                <span matSuffix>{{ t('general.years') }}</span>
                <mat-error>
                    <td-validation-messages [control]="form.get('duration')!" [context]="{ name: t('fields.registration-period') }"></td-validation-messages>
                </mat-error>
            </mat-form-field>
            <td-money-input [label]="t('general.price')" [control]="form.get('price')!" [displayOnly]="true"></td-money-input>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex justify-content-end align-items-end w-100 min-height-auto">
        <button mat-flat-button color="primary" (click)="renew()" [disabled]="form.invalid || op.inProgress">{{ t('actions.renew') }}</button>
        <button mat-button (click)="cancel()" [disabled]="form.disabled">{{ t('actions.cancel') }}</button>
    </mat-dialog-actions>
</form>
