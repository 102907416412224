import { Injectable } from '@angular/core';
import { OpKind } from '@taquito/rpc';
import { RecordMetadata } from '@tezos-domains/core';
import { TezosNetwork, TezosWallet } from '../models';
import { TezosNetworkService } from '../tezos-network.service';
import { TezosService } from '../tezos.service';

export interface PlaceOfferRequest {
    tokenId: number;
    price: number;
    priceWithFee: number;
    expiration: Date | null;
}

export interface ExecuteBuyOfferRequest {
    tokenId: number;
    buyer: string;
    price: number;
    domain?: {
        name: string;
        address: string | null;
        data: RecordMetadata;
        owner: string;
    };
}

@Injectable({
    providedIn: 'root',
})
export class BuyOfferBrokerService {
    private network: TezosNetwork;
    private wallet: TezosWallet;

    constructor(private tezosService: TezosService, private tezosNetworkService: TezosNetworkService) {
        this.tezosService.activeWallet.subscribe(w => (this.wallet = w!));
        this.tezosNetworkService.activeNetwork.subscribe(n => (this.network = n));
    }

    placeOffer(request: PlaceOfferRequest) {
        return this.tezosService.execute(async (_, tezos) => {
            const contract = await tezos.wallet.at(this.network.buyOfferBrokerContract);
            const tokenContract = await tezos.wallet.at(this.network.tokenContract);

            const ops = [
                contract.methods
                    .place_offer(tokenContract.address, request.tokenId, request.price, request.expiration)
                    .toTransferParams({ amount: request.priceWithFee, mutez: true, storageLimit: 100 }),
            ];

            return tezos.wallet.batch(ops.map(o => ({ ...o, kind: OpKind.TRANSACTION }))).send();
        });
    }

    removeOffer(tokenIds: number | number[]) {
        const ids = Array.isArray(tokenIds) ? tokenIds : [tokenIds];

        return this.tezosService.execute(async (_, tezos) => {
            const contract = await tezos.wallet.at(this.network.buyOfferBrokerContract);
            const tokenContract = await tezos.wallet.at(this.network.tokenContract);
            const ops = ids.map(id => contract.methods.remove_offer(tokenContract.address, id, this.wallet.address).toTransferParams({ storageLimit: 0 }));

            return tezos.wallet.batch(ops.map(o => ({ ...o, kind: OpKind.TRANSACTION }))).send();
        });
    }

    executeOffer(request: ExecuteBuyOfferRequest) {
        return this.tezosService.execute(async (client, tezos) => {
            const contract = await tezos.wallet.at(this.network.buyOfferBrokerContract);
            const tokenContract = await tezos.wallet.at(this.network.tokenContract);
            return client.manager.batch(async b => {
                const ops = [
                    tokenContract.methods
                        .update_operators([{ add_operator: { owner: this.wallet.address, token_id: request.tokenId, operator: contract.address } }])
                        .toTransferParams({ storageLimit: 100 }),
                    contract.methods
                        .execute_offer(this.network.tokenContract, request.tokenId, request.buyer, this.wallet.address)
                        .toTransferParams({ storageLimit: 0 }),
                ];

                if (request.domain) {
                    ops.unshift(
                        await b.updateRecord(
                            {
                                name: request.domain.name,
                                address: request.domain.address,
                                owner: request.domain.owner,
                                data: request.domain.data,
                            },
                            { storageLimit: 0 }
                        )
                    );
                }
                return ops;
            });
        });
    }
}
