<ng-container *transloco="let t">
    <div class="d-flex flex-column gap-3">
        <h1 class="d-flex align-items-center mb-0">
            <div class="text-truncated" [style.minWidth]="'calc(100% - 64px)'">{{ t('self-delegate-page.title') }}</div>
        </h1>

        <ng-container *ngIf="vm$ | async as vm; else loading">
            <ng-container *ngIf="!vm.unauthenticated; else unauthenticated">
                <ng-container [ngSwitch]="vm.status">
                    <td-alert [state]="'danger'" *ngSwitchCase="selfDelegationStatus.NoDomain">
                        <div [tdDynamicHtml]="t('self-delegate-page.needs-domain')"></div>
                    </td-alert>
                    <td-alert [state]="'danger'" *ngSwitchCase="selfDelegationStatus.NoProfile">
                        <div [tdDynamicHtml]="t('self-delegate-page.needs-profile', { domainUrl: vm.domainUrl, domain: vm.domain })"></div>
                    </td-alert>
                </ng-container>
                <div class="p-3 py-4 w-100 smooth-shadow d-flex flex-column">
                    <td-operation-status class="flex-shrink-0" [operation]="operation" (done)="operationDone($event)" #op></td-operation-status>
                    <ng-container
                        *ngIf="
                            vm.status === selfDelegationStatus.NotDelegated ||
                                vm.status === selfDelegationStatus.NoDomain ||
                                vm.status === selfDelegationStatus.NoProfile;
                            else alreadyDelegated
                        "
                    >
                        <div class="py-2 pb-4">
                            {{ t('self-delegate-page.delegation-explanation') }}
                        </div>
                        <div class="d-flex w-100">
                            <button
                                mat-flat-button
                                color="primary"
                                (click)="becomeDelegate(vm.address!)"
                                [disabled]="operationInProgress || vm.status !== selfDelegationStatus.NotDelegated"
                            >
                                {{ t('self-delegate-page.self-delegate') }}
                            </button>
                        </div>
                    </ng-container>
                    <ng-template #alreadyDelegated>
                        <div class="py-2">{{ t('self-delegate-page.already-self-delegated') }}</div>
                        <div class="d-flex w-100">
                            <button mat-flat-button color="primary" (click)="removeDelegation()" [disabled]="operationInProgress">
                                {{ t('self-delegate-page.remove-self-delegate') }}
                            </button>
                        </div>
                    </ng-template>
                    <div class="d-flex justify-content-end">
                        <a href="https://blog.tezos.domains/tezos-domains-is-looking-for-delegates-a1949706a8e6" target="_blank">{{
                            t('self-delegate-page.learn-more')
                        }}</a>
                    </div>
                </div>
            </ng-container>
            <ng-template #unauthenticated>
                <td-connect-cta></td-connect-cta>
            </ng-template>
        </ng-container>
        <ng-template #loading>
            <div class="d-flex justify-content-center p-5">
                <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
            </div>
        </ng-template>
    </div>
</ng-container>
