import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { getLabel, getTld, RecordMetadata } from '@tezos-domains/core';
import { BuyRequest, CommitmentRequest, DomainAcquisitionInfo } from '@tezos-domains/manager';
import { CalendarEvent } from 'calendar-link';
import dayjs from 'dayjs';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { delay, first, map, retryWhen, switchMap, take } from 'rxjs/operators';
import { WindowRef } from '../browser/window-ref';
import { Configuration } from '../configuration';
import { NotificationsService } from '../notifications/notifications.service';
import { OperationStatusDoneEvent } from '../shared/operation-status.component';
import { TezosDomainsClientService } from '../tezos/integration/tezos-domains-client.service';
import { TezosWallet } from '../tezos/models';
import { SmartContractOperationEvent, TezosService } from '../tezos/tezos.service';
import { emptyStringToNull } from '../utils/convert';
import { ErrorPresenterService } from '../utils/error-presenter.service';
import { TdValidators } from '../utils/form-validators';
import { ExpiringDomainsStatsService } from './expiring-domains-stats.service';
import { NonceService } from './nonce.service';
import { NotificationEventGeneratorService } from './notification-event-generator.service';
import { ReverseRecord, ReverseRecordService } from './reverse-record.service';

export enum BuyPhase {
    INIT,
    COMMIT,
    BUY,
    SUBSCRIBE,
    DONE,
}

export interface BuyFormModel {
    duration: FormControl<number>;
    address: FormControl<string>;
    createReverseRecord: FormControl<boolean>;
    price: FormControl<number>;
}
@Component({
    selector: 'td-buy',
    templateUrl: './buy.component.html',
    styleUrls: ['./buy.component.scss'],
})
export class BuyComponent implements OnInit {
    @Input() name: string;
    @Input() set acquisitionInfo(value: DomainAcquisitionInfo) {
        this.aq = value;
        this.updatePrice();
    }
    get acquisitionInfo(): DomainAcquisitionInfo {
        return this.aq;
    }
    @Output() bought = new EventEmitter<void>();

    commitOperation: Observable<SmartContractOperationEvent> | null;
    buyOperation: Observable<SmartContractOperationEvent> | null;
    reverseRecord: ReverseRecord | null;
    buyForm: FormGroup<BuyFormModel>;
    pricePerYear: number;
    phase: BuyPhase = BuyPhase.INIT;
    phases: typeof BuyPhase = BuyPhase;
    stepperAnimation = false;
    waitFrom: dayjs.Dayjs;
    waitUntil: dayjs.Dayjs;
    wallet: TezosWallet;
    subscriptionEvent: CalendarEvent;
    showDomainNameLengthWarning: boolean;
    tld: string;

    readonly notificationSource = 'domain-registration';

    readonly notificationSubscriptionVisible$: Observable<{ visible: boolean }>;

    @ViewChild(MatStepper) stepper: MatStepper;

    private label: string;
    private aq: DomainAcquisitionInfo;

    constructor(
        private tezosService: TezosService,
        private tezosDomainsClientService: TezosDomainsClientService,
        private reverseRecordService: ReverseRecordService,
        private nonceService: NonceService,
        private errorPresenterService: ErrorPresenterService,
        private expiringStats: ExpiringDomainsStatsService,
        private windowRef: WindowRef,
        private notificationEventGenerator: NotificationEventGeneratorService,
        public configuration: Configuration,
        formBuilder: FormBuilder,
        notificationsService: NotificationsService
    ) {
        this.buyForm = formBuilder.group({
            duration: formBuilder.control(1, {
                nonNullable: true,
                validators: [Validators.required, TdValidators.number(), Validators.min(1), Validators.max(100)],
            }),
            address: formBuilder.control('', { nonNullable: true, validators: [TdValidators.tezosAddress()] }),
            createReverseRecord: formBuilder.control(false, { nonNullable: true }),
            price: formBuilder.control(0, { nonNullable: true }),
        });

        this.notificationSubscriptionVisible$ = combineLatest([
            of(notificationsService.isEnabled),
            notificationsService.currentSubscription,
            notificationsService.promptBlocked(this.notificationSource),
        ]).pipe(map(([enabled, subscription, blocked]) => ({ visible: enabled && subscription.status !== 'confirmed' && !blocked })));
    }

    ngOnInit(): void {
        this.label = getLabel(this.name);
        this.tld = getTld(this.name);
        this.showDomainNameLengthWarning = this.label.length > this.configuration.maxWebsiteDomainNameLength;

        this.pricePerYear = this.acquisitionInfo.calculatePrice(365);

        combineLatest([this.tezosService.activeWallet, this.reverseRecordService.current])
            .pipe(first())
            .subscribe(([w, r]) => {
                this.wallet = w!;
                this.reverseRecord = r;
                this.checkForExistingCommitment(true);
                this.buyForm.setValue({
                    address: this.wallet.address,
                    createReverseRecord: !r?.domain,
                    duration: 1,
                    price: 0,
                });
            });

        this.buyForm.get('duration')!.valueChanges.subscribe(() => this.updatePrice());

        let enabledValue: boolean | null = null;
        this.buyForm.get('address')!.valueChanges.subscribe(v => {
            if (this.reverseRecord?.domain) {
                return;
            }

            const createReverseRecord = this.buyForm.get('createReverseRecord')!;
            if (v !== this.wallet.address) {
                enabledValue = createReverseRecord.value;
                createReverseRecord.setValue(false);
                createReverseRecord.disable();
            } else {
                createReverseRecord.enable();
                if (enabledValue !== null) {
                    createReverseRecord.setValue(enabledValue);
                    enabledValue = null;
                }
            }
        });

        this.updatePrice();
    }

    commit() {
        this.commitOperation = this.getCommitParams().pipe(switchMap(params => this.tezosService.execute(client => client.manager.commit(this.tld, params))));
    }

    buy() {
        const form = this.buyForm.value;
        this.buyForm.disable();
        this.buyOperation = this.getCommitParams().pipe(
            switchMap(commitParams => {
                const buyParams: BuyRequest = {
                    ...commitParams,
                    duration: this.getDuration(),
                    data: new RecordMetadata(),
                    address: emptyStringToNull(form.address),
                };

                if (form.createReverseRecord) {
                    return this.tezosService.execute(client =>
                        client.manager.batch(async b => [
                            await b.buy(this.tld, buyParams),
                            await b.claimReverseRecord({ name: this.name, owner: this.wallet.address }),
                        ])
                    );
                } else {
                    return this.tezosService.execute(client => client.manager.buy(this.tld, buyParams));
                }
            })
        );
    }

    done() {
        this.bought.next();
        this.expiringStats.refresh();
    }

    commitDone(event: OperationStatusDoneEvent) {
        if (event.success) {
            this.checkForExistingCommitment(false);
        }
    }

    buyDone(event: OperationStatusDoneEvent, notificationStepVisible: boolean) {
        if (event.success) {
            this.nonceService.clearNonce(this.name, this.wallet.address);
            this.reverseRecordService.refresh();
            this.setNextPhase(notificationStepVisible);

            const domainExpiration = dayjs().add(this.getDuration(), 'days');
            this.subscriptionEvent = this.notificationEventGenerator.generate({ name: this.name, expires: domainExpiration });
        } else {
            this.buyForm.enable();
        }
    }

    setNextPhase(notificationStepVisible: boolean) {
        this.phase++;
        if (!notificationStepVisible && this.phase === BuyPhase.SUBSCRIBE) {
            this.phase++;
        }

        setTimeout(() => this.stepper.next());
    }

    skipSubscription() {
        this.setNextPhase(true);
    }

    get location() {
        return this.windowRef.nativeWindow.location.href;
    }

    private async checkForExistingCommitment(init: boolean) {
        const commitment = await combineLatest([this.tezosDomainsClientService.current, this.getCommitParams()])
            .pipe(
                first(),
                switchMap(([client, params]) => client.manager.getCommitment(this.tld, params)),
                switchMap(c => (c ? of(c) : throwError(new Error('NOT_FOUND')))),
                retryWhen(errors => (init ? of() : errors.pipe(delay(2000), take(3))))
            )
            .toPromise();

        setTimeout(() => (this.stepperAnimation = true), 1000);

        this.phase = BuyPhase.COMMIT;

        if (!commitment) {
            if (!init) {
                this.errorPresenterService.nodeErrorToast('commitment-fetch', null);
            }
            // commitment doesn't exist
            return;
        }

        const now = dayjs();

        if (now.isAfter(commitment.usableUntil)) {
            // commitment found, but expired
            this.nonceService.clearNonce(this.name, this.wallet.address);
            return;
        }

        this.waitFrom = dayjs(commitment.created);
        this.waitUntil = dayjs(commitment.usableFrom);
    }

    private updatePrice() {
        this.buyForm.get('price')!.setValue(this.acquisitionInfo.calculatePrice(this.getDuration()) / 1e6);
    }

    private getDuration() {
        return this.buyForm.get('duration')!.value * 365;
    }

    private getCommitParams(): Observable<CommitmentRequest> {
        return this.nonceService.getNonce(this.name, this.wallet.address).pipe(
            map(nonce => {
                return {
                    label: this.label,
                    owner: this.wallet.address,
                    nonce,
                };
            })
        );
    }
}
