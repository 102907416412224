<ng-container *transloco="let t">
    <div class="d-flex flex-fill flex-column h-100 gap-1">
        <div class="d-flex flex-column flex-shrink-0 mb-3">
            <h1 mat-dialog-title class="text-truncate mb-0 flex-shrink-0">{{ t('bulk-offers.cancel-title') }}</h1>
            <td-operation-status class="flex-shrink-0" [operation]="operation" (done)="operationDone($event)" #op></td-operation-status>
        </div>
        <mat-dialog-content class="d-flex flex-column flex-grow-1">
            <div class="d-flex flex-column h-100 gap-1">{{ t('bulk-offers.cancel-description', { count: offers.length }) }}</div>
        </mat-dialog-content>
        <mat-dialog-actions class="d-flex justify-content-end align-items-end flex-column gap-3 w-100 flex-nowrap min-height-auto">
            <div class="d-flex justify-content-end align-items-end w-100">
                <button mat-button (click)="close()">{{ t('actions.close') }}</button>
                <button mat-flat-button color="primary" (click)="cancelOffers()">
                    {{ t('bulk-offers.cancel-offers') }}
                </button>
            </div>
        </mat-dialog-actions>
    </div>
</ng-container>
