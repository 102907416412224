<ng-container *transloco="let t">
    <div class="mb-3 d-flex">
        <div class="me-2 d-flex flex-grow-1" [style]="{ overflow: 'hidden' }">
            <td-horizontal-scroll class="d-flex">
                <div class="d-flex gap-2">
                    <td-radio-list-filter
                        [options]="domainNameTypeOptions"
                        [defaultSelectedOption]="defaultDomainNameType"
                        [initialSelectedOption]="initialDomainNameType"
                        (selectedChange)="domainNameTypeChanged($event)"
                    ></td-radio-list-filter>
                    <td-domain-filter (domainChange)="domainChanged($event)" [initialDomain]="initialDomain" [validateDomainName]="false"></td-domain-filter>
                    <!-- t(expired-domains.filters.categories.button-label) -->
                    <td-list-filter
                        [options]="categoryOptions"
                        [buttonLabel]="'expired-domains.filters.categories.button-label'"
                        [selectedOptions]="initialOfferCategories"
                        (selectedChange)="categoriesChanged($event)"
                    ></td-list-filter>
                    <td-date-filter
                        [min]="minDate"
                        [max]="maxDate"
                        [initialFilter]="initialDateFilter"
                        (dateRangeChange)="dateChanged($event)"
                    ></td-date-filter>
                </div>
            </td-horizontal-scroll>
        </div>
        <td-sorter [defaultSort]="defaultSort" [sortOptions]="sortOptions" (sortChange)="sortChange($event)"></td-sorter>
    </div>
</ng-container>
