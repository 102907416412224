import dayjs, { ManipulateType } from 'dayjs';

export function isExpiring(
    entity: { expires?: dayjs.Dayjs | null | undefined } | null | undefined,
    expirationThreshold: { time: number; units: ManipulateType }
): boolean {
    if (!entity?.expires) {
        return false;
    }

    return entity.expires.isBefore(dayjs().add(expirationThreshold.time, expirationThreshold.units));
}
