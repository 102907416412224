<ng-container *transloco="let t">
    <div class="d-flex">
        <div class="flex-grow-1">
            {{ showAverageApr ? t('gvPool.average-apr') : t('gvPool.current-apr') }}
            <fa-icon
                *ngIf="showAverageApr"
                icon="question-circle"
                class="accent help ms-1 no-underline"
                matTooltipPosition="above"
                [matTooltip]="t('gvPool.average-apr-help')"
            ></fa-icon>
        </div>
        <div class="txt-success">{{ (showAverageApr ? stats.poolAverageAPR : stats.poolCurrentAPR) | apr }}</div>
    </div>
</ng-container>
