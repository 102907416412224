<ng-container *transloco="let t">
    <button
        [matMenuTriggerFor]="menu"
        mat-stroked-button
        class="select-button"
        type="button"
        (click)="onClick()"
        [ngClass]="{ 'highlighted-stroked': !!selectedItem && selectedItem.value !== defaultSelectedOption }"
    >
        <!-- t(filters.list.label) -->
        {{ t(buttonLabel ?? 'filters.radio-list.label', { value: selectedItem.label }) }}
    </button>

    <mat-menu #menu="matMenu" class="eventtype-filter-menu">
        <form action="." (ngSubmit)="submit()">
            <div
                (click)="$event.stopPropagation()"
                (keydown.tab)="$event.stopPropagation()"
                (keydown.shift.tab)="$event.stopPropagation()"
                class="d-flex flex-column"
                cdkTrapFocus
            >
                <div class="p-2 pb-3 d-flex flex-column">
                    <mat-list name="eventTypes">
                        <ng-container *ngFor="let s of allItems; let i = index">
                            <mat-list-item>
                                <mat-radio-button
                                    *ngIf="s.type === optionType.Item"
                                    [value]="s"
                                    [checked]="selectedItem.value === s.value"
                                    (change)="selectionChange($event.value)"
                                    >{{ s.label }}</mat-radio-button
                                >
                                <td-divider-with-text *ngIf="s.type === optionType.Separator" [text]="s.label" class="mt-2"></td-divider-with-text>
                            </mat-list-item>
                        </ng-container>
                    </mat-list>
                </div>
                <mat-divider></mat-divider>
                <div class="p-3 d-flex gap-2">
                    <button type="button" mat-stroked-button class="js-cancel-btn" (click)="clear()">{{ t('actions.clear') }}</button>
                    <button mat-flat-button color="primary" class="js-submit" type="submit">{{ t('actions.filter') }}</button>
                </div>
            </div>
        </form>
    </mat-menu>
</ng-container>
