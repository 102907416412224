import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { OfferStateBadgeComponent } from './offer-state-badge.component';

@NgModule({
    declarations: [OfferStateBadgeComponent],
    imports: [SharedModule],
    exports: [OfferStateBadgeComponent],
})
export class OffersCoreModule {}
