import { NgModule, inject } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';

import { AddressDetailComponent } from './address/address-detail.component';
import { ClaimComponent } from './airdrop/claim.component';
import { AuctionHistoryListComponent } from './auctions/auction-history-list.component';
import { AuctionListComponent } from './auctions/auction-list.component';
import { HistoryAuctionComponent } from './auctions/history-auction.component';
import { HotAuctionListComponent } from './auctions/hot-auction-list.component';
import { BuyOffersPageComponent } from './buy-offers/buy-offers-page.component';
import { Configuration } from './configuration';
import { DelegatesBoardComponent } from './delegation/delegates-board.component';
import { DelegatorListComponent } from './delegation/delegator-page.component';
import { AuditComponent } from './dev/audit.component';
import { StateComponent } from './dev/state.component';
import { ThemeparkComponent } from './dev/themepark.component';
import { WalletComponent } from './dev/wallet.component';
import { BulkFilterComponent } from './domains/bulk-registration/bulk-filter.component';
import { bulkRegistrationDeactivate } from './domains/bulk-registration/bulk-registration.deactivate';
import { RegisterComponent } from './domains/bulk-registration/register.component';
import { DomainDetailComponent } from './domains/domain-detail.component';
import { DomainListComponent } from './domains/domain-list.component';
import { ExpiredDomainsComponent } from './domains/expired-domains.component';
import { SearchResultsComponent } from './domains/search-results.component';
import { NotFoundComponent } from './error/not-found.component';
import { ActivityComponent } from './events/activity/activity.component';
import { EventsListComponent } from './events/events-list.component';
import { FavouritesPageComponent } from './favourites/favourites-page.component';
import { GovPageComponent } from './gv-pool/gov-page.component';
import { SelfDelegatePageComponent } from './gv-pool/self-delegate-page.component';
import { HomeComponent } from './home/home.component';
import { ConfirmComponent } from './notifications/confirm.component';
import { UnsubscribeComponent } from './notifications/unsubscribe.component';
import { HotOfferListComponent } from './offers/hot-offer-list.component';
import { OfferListComponent } from './offers/offer-list.component';
import { ReverseRecordDetailComponent } from './reverse-records/reverse-record-detail.component';
import { ReverseRecordListComponent } from './reverse-records/reverse-record-list.component';
import { SettingsComponent } from './settings/settings.component';
import { GoGuard } from './utils/go.guard';
import { LaunchGuard } from './utils/launch.guard';

const airdropStartedGuard = () => {
    const config = inject(Configuration);
    if (config.airdrop.start > new Date()) {
        const router = inject(Router);
        return router.createUrlTree(['/']);
    }

    return true;
};

const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'settings', component: SettingsComponent },
    { path: 'address/:address/domains', component: DomainListComponent },
    { path: 'address/:address/reverse-records', component: ReverseRecordListComponent },
    { path: 'address/:address/auctions', component: AuctionListComponent },
    { path: 'address/:address/auction-history', component: AuctionHistoryListComponent },
    { path: 'address/:address/activity', component: EventsListComponent },
    { path: 'address/:address/listings', component: OfferListComponent },
    { path: 'address/:address/delegators', component: DelegatorListComponent },
    { path: 'address/:address/favourites', component: FavouritesPageComponent },
    {
        path: 'address/:address/buy-offers',
        children: [
            { path: '', component: BuyOffersPageComponent },
            { path: ':type', component: BuyOffersPageComponent },
        ],
    },
    { path: 'address/:address/buy-offers/sell', component: OfferListComponent },
    { path: 'address/:address', component: AddressDetailComponent },
    { path: 'domain/:name', component: DomainDetailComponent },
    { path: 'domains/expired', component: ExpiredDomainsComponent },
    { path: 'auction/:name/:level', component: HistoryAuctionComponent },
    { path: 'reverse-record/:address', component: ReverseRecordDetailComponent },
    { path: 'activity', component: ActivityComponent },
    { path: 'auctions', component: HotAuctionListComponent },
    { path: 'listings', component: HotOfferListComponent },
    { path: 'buy-offers', component: BuyOffersPageComponent },
    {
        path: 'governance',
        children: [
            {
                path: '',
                component: GovPageComponent,
                canActivate: [airdropStartedGuard],
            },
            { path: 'self-delegate', component: SelfDelegatePageComponent },
            { path: 'delegates-board', component: DelegatesBoardComponent },
        ],
    },
    { path: 'search', component: SearchResultsComponent },
    {
        path: 'multi-registration',
        canDeactivate: [bulkRegistrationDeactivate],
        children: [
            { path: '', component: BulkFilterComponent },
            { path: 'register', component: RegisterComponent },
        ],
    },
    { path: 'confirm', component: ConfirmComponent },
    { path: 'unsubscribe', component: UnsubscribeComponent },
    {
        path: 'airdrop',
        canActivate: [airdropStartedGuard],
        children: [
            {
                path: ':step',
                component: ClaimComponent,
            },
            {
                path: '',
                redirectTo: 'start',
                pathMatch: 'full',
            },
        ],
    },
    { path: 'dev/themepark', component: ThemeparkComponent },
    { path: 'dev/wallet', component: WalletComponent },
    { path: 'dev/state', component: StateComponent },
    { path: 'dev/audit', component: AuditComponent },
    { path: 'go', canActivate: [GoGuard], children: [] },
    { path: 'launch', canActivate: [LaunchGuard], children: [] },
    { path: '**', component: NotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
