import { Component, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from '../shared/message-service.service';

@Component({
    selector: 'td-themepark',
    templateUrl: './themepark.component.html',
    styleUrls: ['./themepark.component.scss'],
})
export class ThemeparkComponent {
    constructor(private dialog: MatDialog, private messageService: MessageService) {}

    openDialog(templateRef: TemplateRef<any>) {
        this.dialog.open(templateRef, {
            width: '700px',
        });
    }

    showMessage(type: 'error' | 'success') {
        this.messageService[type]({
            message: 'test message <a href="link">link</a>',
            timeOut: 60000,
        });
    }
}
