<ng-container *transloco="let t">
    <ng-container *ngIf="stats$ | async; let stats">
        <td-alert [state]="'info'" *ngIf="stats.hasExpiringLater && !stats.hasExpiringEarly">
            <div class="my-1 d-flex justify-content-start align-items-center">
                <span class="expiring-domains-text flex-grow-1">{{ t('domains-summary.you-have-expiring-domains') }}</span>
                <a class="mx-2" mat-flat-button color="primary" [routerLink]="['/address', address, 'domains']" [queryParams]="{ expiring: true }">
                    <fa-icon icon="redo-alt"></fa-icon>{{ t('domains-summary.bulk-renew-cta') }}
                </a>
            </div>
        </td-alert>
        <td-alert [state]="'warning'" *ngIf="stats.hasExpiringEarly">
            <div class="my-1 d-flex justify-content-start align-items-center">
                <div class="expiring-domains-text flex-grow-1">
                    <div>{{ t('domains-summary.you-have-expiring-domains-urgent') }}</div>
                    <div class="subtext">{{ t('domains-summary.you-have-expiring-domains-urgent-subtext') }}</div>
                </div>
                <a
                    class="mx-2 flex-shrink-0"
                    mat-flat-button
                    color="primary"
                    [routerLink]="['/address', address, 'domains']"
                    [queryParams]="{ expiring: true }"
                >
                    <fa-icon icon="redo-alt"></fa-icon>{{ t('domains-summary.bulk-renew-cta') }}
                </a>
            </div>
        </td-alert>
    </ng-container>
</ng-container>
