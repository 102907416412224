<div class="d-flex flex-column flex-fill h-100" *transloco="let t">
    <div class="d-flex flex-column">
        <h1 mat-dialog-title class="text-truncate">{{ t('operator-permissions.title', { name: domain.name }) }}</h1>
        <td-operation-status class="w-100" [operation]="operation" (done)="operationDone($event)" #op></td-operation-status>
        <td-operator-search class="w-100" (address)="onAddressEntered($event)"></td-operator-search>
    </div>
    <mat-dialog-content class="px-4 mt-2 d-flex flex-column flex-grow-1 gap-2" #addressList>
        <td-alert state="warning" *ngIf="removedSelfAsOperator">
            {{ t('operator-permissions.remove-self-warning') }}
        </td-alert>
        <div class="d-flex align-items-center" *ngFor="let operator of operators">
            <ng-container *ngIf="!operator.isDirectBrokerAddress; else directBroker">
                <td-address class="w-100" [address]="operator.address" [copy]="false" [resolveReverseRecord]="true" [link]="false"></td-address>
                <button
                    class="flex-shrink-0"
                    (click)="removeOperator(operator)"
                    mat-icon-button
                    color="warn"
                    [matTooltip]="removeButtonTooltip(operator)"
                    [matTooltipDisabled]="removeButtonTooltipDisabled(operator)"
                    [ngClass]="{ 'no-underline': removeButtonTooltipDisabled(operator) }"
                >
                    <fa-icon icon="times"></fa-icon>
                </button>
            </ng-container>
            <ng-template #directBroker>
                <div class="d-flex flex-column flex-grow-1">
                    <td-address [address]="operator.address" [copy]="false" [customName]="t('operator-permissions.direct-broker-text')" [link]="false">
                        <fa-icon
                            class="accent ms-2 no-underline"
                            matTooltipPosition="above"
                            [matTooltip]="t('operator-permissions.direct-broker-tooltip')"
                            icon="question-circle"
                        ></fa-icon>
                    </td-address>
                </div>
                <button
                    class="flex-shrink-0 no-underline"
                    (click)="removeOperator(operator)"
                    [matTooltip]="t('operator-permissions.direct-broker-tooltip')"
                    mat-icon-button
                    color="warn"
                >
                    <fa-icon icon="times"></fa-icon>
                </button>
            </ng-template>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex justify-content-end align-items-end w-100 min-height-auto">
        <button mat-flat-button color="primary" class="js-submit" [disabled]="!canSaveOperators() || op.inProgress" (click)="updateOperatorList()">
            {{ t('actions.save') }}
        </button>
        <button mat-button (click)="cancel()" [disabled]="op.inProgress" class="js-cancel-btn">{{ t('actions.cancel') }}</button>
    </mat-dialog-actions>
</div>
