import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import BigNumber from 'bignumber.js';

import { HarbingerService } from '../tezos/integration/harbinger.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'td-tez',
    templateUrl: './tez.component.html',
    styleUrls: ['./tez.component.scss'],
})
export class TezComponent implements OnInit, OnChanges {
    @Input() mutez: boolean;
    @Input() value: number | BigNumber | string | null;
    @Input() showUsd: boolean | 'only';
    @Input() decimals: number;

    displayValue: number;
    usdPrice: Observable<number>;

    private initialized: boolean;
    private unsubscribe = new Subject<void>();

    constructor(private harbingerService: HarbingerService) {}

    ngOnInit() {
        this.initialized = true;
        this.update();
    }

    ngOnChanges() {
        this.update();
    }

    private update() {
        if (!this.initialized) {
            return;
        }

        this.unsubscribe.next();

        if (this.value == null) {
            this.displayValue = 0;
        } else {
            let temp = new BigNumber(this.value);

            if (this.mutez !== false) {
                temp = temp.dividedBy(1e6);
            }

            this.displayValue = temp.toNumber();
        }

        if (this.showUsd) {
            this.usdPrice = this.harbingerService.convert(this.displayValue).pipe(takeUntil(this.unsubscribe));
        }
    }
}
