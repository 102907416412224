import { Component, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import BigNumber from 'bignumber.js';
import { Observable } from 'rxjs';
import { GovActionService } from '../airdrop/gov-action.service';
import { OperationStatusDoneEvent } from '../shared/operation-status.component';
import { SmartContractOperationEvent } from '../tezos/tezos.service';
import { TdValidators } from '../utils/form-validators';
import { PoolStats } from './models';
import { convertTedToVotes } from './pool-exchange';

@UntilDestroy()
@Component({
    selector: 'td-withdraw',
    templateUrl: './withdraw.component.html',
    styleUrls: ['./withdraw.component.scss'],
})
export class WithdrawComponent {
    private dialogRef = inject(MatDialogRef<WithdrawComponent>);
    private formBuilder = inject(FormBuilder);
    private govAction = inject(GovActionService);
    data = inject<PoolStats>(MAT_DIALOG_DATA);

    operation: Observable<SmartContractOperationEvent> | null;
    maxWithdraw = this.data.depositedTedBalance.div(1e6).toNumber();

    form = this.formBuilder.group({
        amount: this.formBuilder.control(0, {
            nonNullable: true,
            validators: [Validators.required, Validators.min(0), Validators.max(this.maxWithdraw), TdValidators.number(6)],
        }),
    });

    maxAmount($event: Event) {
        $event.stopPropagation();
        this.form.get('amount')?.setValue(this.maxWithdraw);
    }

    operationDone(event: OperationStatusDoneEvent) {
        if (event.success) {
            this.dialogRef.close(true);
        } else {
            this.form.enable();
        }
    }

    submit() {
        this.form.disable();

        const tedAmount = this.form.value.amount!;
        let votesAmount = BigNumber(0);

        if (tedAmount === this.maxWithdraw) {
            votesAmount = this.data.votesBalance;
        } else {
            votesAmount = convertTedToVotes(tedAmount * 1e6, this.data);
        }

        this.operation = this.govAction.q.withdraw(votesAmount, this.data.owner!).send();
    }

    cancel() {
        this.dialogRef.close();
    }
}
