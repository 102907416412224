<div *transloco="let t" class="d-flex flex-column">
    <mat-form-field appearance="standard" class="w-100">
        <mat-label>{{ key }}</mat-label>
        <img matSuffix ngxGravatar *ngIf="innerControl && !innerControl.invalid" fallback="mp" [md5Hash]="innerControl.value" [size]="24" />
        <input matInput [formControl]="innerControl" [placeholder]="t('data-editor.gravatar.placeholder')" />
        <mat-error>
            <td-validation-messages [control]="innerControl" [context]="{ name: key }"></td-validation-messages>
        </mat-error>
    </mat-form-field>
    <a [attr.href]="'about/faq#how-can-i-add-an-avatar-to-my-domain' | landingPageUrl" target="_blank">
        <fa-icon icon="question-circle" class="me-1"></fa-icon>{{ t('data-editor.gravatar.faq') }}
    </a>
</div>
