import { DataSourceBase, ProcessedData, DataSourceOptions } from '../data-source-base';
import { ReverseRecordDetailQuery, ReverseRecordDetailQueryVariables } from './graphql.generated';

export class ReverseRecordDetailDataSource extends DataSourceBase<ReverseRecordDetailQuery, ReverseRecordDetailQuery, ReverseRecordDetailQueryVariables> {
    constructor(options: DataSourceOptions<ReverseRecordDetailQuery, ReverseRecordDetailQueryVariables>) {
        super(options);
    }

    protected transformData(data: ReverseRecordDetailQuery): ProcessedData<ReverseRecordDetailQuery> {
        return {
            data,
        };
    }
}
