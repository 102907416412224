import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { getTld, DomainNameValidationResult } from '@tezos-domains/core';
import { TaquitoTezosDomainsClient } from '@tezos-domains/taquito-client';
import { Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import escape from 'lodash-es/escape';

import { DomainExistsGQL } from '../graphql/graphql.generated';
import { TezosDomainsClientService } from '../tezos/integration/tezos-domains-client.service';

@Injectable({
    providedIn: 'root',
})
export class DomainService {
    private tezosDomains: TaquitoTezosDomainsClient;

    constructor(private translation: TranslocoService, private domainExistsGQL: DomainExistsGQL, private tezosDomainsClientService: TezosDomainsClientService) {
        this.tezosDomainsClientService.current.subscribe(c => (this.tezosDomains = c));
    }

    getInvalidDomainMessage(name: string, validation: DomainNameValidationResult): Observable<string> {
        const tld = getTld(name);
        switch (validation) {
            case DomainNameValidationResult.INVALID_TLD:
                return this.translation.selectTranslate('domain-detail.invalid-tld-error', {
                    tld: escape(tld),
                    supported: this.tezosDomains.validator.supportedTLDs.join(', '),
                });
            default:
                return this.translation
                    .selectTranslate(`validation.label.${tld}-${validation}`, { name: 'Each part of the domain name' })
                    .pipe(switchMap(error => this.translation.selectTranslate('domain-detail.invalid-name-error', { name: escape(name), error })));
        }
    }

    isDomainAvailable(name: string): Observable<boolean> {
        return this.domainExistsGQL.fetch({ name }, { fetchPolicy: 'no-cache', errorPolicy: 'none' }).pipe(map(({ data }) => !data?.domain));
    }

    hasAddress(name: string, address: string): Observable<boolean> {
        return this.domainExistsGQL
            .fetch({ name }, { fetchPolicy: 'no-cache', errorPolicy: 'none' })
            .pipe(map(({ data }) => data?.domain?.address === address));
    }
}
