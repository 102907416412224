<ng-container *transloco="let t">
    <div class="dns-bridge stepper d-flex flex-column">
        <h2>{{ t('dns-bridge.title') }}</h2>
        <h3 [innerHTML]="t('dns-bridge.sub-title', {name}) | trustAsHtml"></h3>
        <mat-stepper orientation="vertical" [disableRipple]="true" #stepper [linear]="true" [@.disabled]="!stepperAnimation">
            <mat-step [editable]="false" [completed]="step > steps.DNSSEC">
                <ng-template matStepLabel> {{ t('dns-bridge.step-dnssec-title') }}</ng-template>
                <div class="my-3">
                    {{ t('dns-bridge.step-dnssec-description') }}
                    <a *ngIf="'about/dns-bridge' | landingPageUrl; let url" [attr.href]="url">{{ t('general.learn-more') }}</a>
                    <td-alert class="mt-3" state="warning" *ngIf="userInterracted && error"><span [innerHTML]="error | trustAsHtml"></span></td-alert>
                </div>
                <div class="d-flex justify-content-end align-items-end">
                    <td-progress-button
                        [loading]="dnsCheckInProgress$"
                        [idleText]="t('dns-bridge.check-again')"
                        [inProgressText]="t('dns-bridge.checking-dns')"
                        (click)="checkDNSStatus()"
                    >
                    </td-progress-button>
                </div>
            </mat-step>
            <mat-step [editable]="false" [completed]="step > steps.TXTRecord">
                <ng-template matStepLabel>{{ t('dns-bridge.step-txt-title') }}</ng-template>
                <div class="my-3">
                    <span>{{ t('dns-bridge.step-txt-description') }}</span>
                    <div class="copy-txt-address my-3">
                        <code class="ms-2">{{ txtAddressText }}</code>
                        <td-copy-to-clipboard
                            class="ms-2 no-underline"
                            [matTooltip]="t('dns-bridge.copy-to-clipboard')"
                            [text]="txtAddressText"
                        ></td-copy-to-clipboard>
                    </div>
                    <a *ngIf="'about/dns-bridge#how-to-add-a-txt-record-for-verification' | landingPageUrl; let url" [attr.href]="url">{{
                        t('general.learn-more')
                    }}</a>
                    <td-alert class="mt-3" state="warning" *ngIf="userInterracted && error"><span [innerHTML]="error | trustAsHtml"></span></td-alert>
                </div>

                <div class="d-flex justify-content-end align-items-end">
                    <td-progress-button
                        [loading]="dnsCheckInProgress$"
                        [idleText]="t('dns-bridge.check-again')"
                        [inProgressText]="t('dns-bridge.checking-dns')"
                        (click)="checkDNSStatus()"
                    >
                    </td-progress-button>
                </div>
            </mat-step>
            <mat-step [editable]="false" [completed]="step > steps.Claim">
                <ng-template matStepLabel>{{ t('dns-bridge.step-register-title') }}</ng-template>
                <td-operation-status [operation]="registrationOperation" (done)="claimDone($event)" #registrationOp></td-operation-status>
                <div class="d-flex flex-column flex-sm-row gap-2">
                    <div class="description">
                        {{ t('dns-bridge.step-register-description') }}
                    </div>
                    <form class="d-flex flex-column gap-3 action" [formGroup]="form">
                        <td-money-input [label]="t('dns-bridge.fee')" [control]="form.get('price')!" [displayOnly]="true"></td-money-input>
                        <td-address-editor
                            [control]="form.get('address')!"
                            [label]="t('fields.domain-address')"
                            [placeholder]="t('fields.address-placeholder')"
                        ></td-address-editor>
                        <mat-slide-toggle *ngIf="!reverseRecord?.domain" formControlName="createReverseRecord">
                            <span>{{ t('fields.create-reverse-record') }}</span>
                            <fa-icon
                                class="accent ms-2"
                                matTooltipPosition="above"
                                [matTooltip]="t('buy.create-reverse-record-info')"
                                icon="question-circle"
                            ></fa-icon>
                        </mat-slide-toggle>
                        <td-alert state="warning" *ngIf="!reverseRecord?.domain && form.get('address')?.value && form.get('address')?.value !== wallet.address">
                            {{ t('buy.reverse-record-address-mismatch-info') }}
                        </td-alert>
                        <button mat-flat-button (click)="claim()" [disabled]="form.invalid || registrationOp.inProgress" color="primary">
                            {{ t('actions.register') }}
                        </button>
                    </form>
                </div>
            </mat-step>
            <mat-step [editable]="false">
                <ng-template matStepLabel>{{ t('dns-bridge.step-done-title') }}</ng-template>
                <div class="d-flex flex-column flex-sm-row gap-2">
                    <div class="description">
                        {{ t('dns-bridge.step-done-description') }}
                    </div>
                    <div class="d-flex flex-column gap-2 action">
                        <button mat-flat-button (click)="done()" color="primary">
                            {{ t('actions.manage-your-domain') }}
                        </button>
                    </div>
                </div>
                <td-share class="mt-4 pt-4" [text]="t('dns-bridge.share-text', { name })" [domainName]="name"></td-share>
            </mat-step>
            <ng-template matStepperIcon="done">
                <fa-icon icon="check"></fa-icon>
            </ng-template>
        </mat-stepper>
    </div>
</ng-container>
