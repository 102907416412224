<ng-container *transloco="let t">
    <div
        class="w-100 px-3 d-flex gap-3 align-items-center position-relative"
        [ngClass]="{ 'py-2': compactView, 'py-4': !compactView, 'px-3': !compactX, 'px-2': compactX }"
    >
        <ng-content select="[pre-avatar]"></ng-content>
        <div class="avatar-container">
            <td-star [name]="name" class="right"></td-star>
            <ngx-avatars [value]="name | slice : 0 : 2" size="40" class="d-sm-block" [ngClass]="{ 'd-none': responsiveAvatar }"></ngx-avatars>
        </div>
        <div class="hide-overflow flex-grow-1 d-flex flex-sm-row" [ngClass]="{ 'flex-column': media.isActive('lt-sm') && layoutSmall === 'column' }">
            <div [ngClass]="{ 'hide-overflow': media.isActive('gt-xs') }" class="text-truncate d-flex flex-column flex-sm-row gap-0 gap-sm-2 flex-grow-1">
                <div class="d-flex flex-column">
                    <div class="d-flex">
                        <td-domain [domain]="name" class="txt-truncate me-2" [maxChars]="maxDomainNameCharacters"></td-domain>
                        <ng-container *ngTemplateOutlet="badgesTemplate ?? defaultBadges"></ng-container>
                    </div>
                    <ng-content select="[extra-info]"></ng-content>
                </div>
            </div>

            <div *ngIf="!price">
                <ng-content></ng-content>
            </div>
            <div class="txt-lg d-flex align-items-center justify-content-start" *ngIf="price">
                <td-tez [value]="price"></td-tez>
            </div>
        </div>
        <div class="d-flex align-items-center" *ngIf="buttonsTemplate || buttonText">
            <ng-container *ngTemplateOutlet="buttonsTemplate ?? defaultButtons"></ng-container>
        </div>
    </div>
    <div
        class="txt-sm txt-secondary px-3 expiration-row d-flex justify-content-end align-items-center"
        [class.px-3]="!compactX"
        [class.px-2]="compactX"
        *ngIf="expiration"
    >
        <div [matTooltip]="expiration | dayjsDate">
            <fa-icon [icon]="['far', 'clock']" class="me-1"></fa-icon>
            <td-humanized-date [readOnly]="readonlyExpiration" [value]="expiration" [withoutSuffix]="true"></td-humanized-date> {{ t('general.time-left') }}
        </div>
    </div>

    <ng-template #defaultButtons>
        <button mat-flat-button color="primary" [disabled]="wallet?.address === seller || buttonDisabled" (click)="action()" *ngIf="buttonText">
            {{ buttonText }}
        </button>
        <fa-icon *ngIf="buttonHelp" class="ms-1 no-underline" matTooltipPosition="above" [matTooltip]="buttonHelp" icon="question-circle"></fa-icon>
    </ng-template>

    <ng-template #defaultBadges>
        <div class="d-flex flex-shrink-0">
            <td-badge *ngIf="wallet?.address === seller" color="primary">{{ t('domain-listing.your-listing') }}</td-badge>
        </div>
    </ng-template>
</ng-container>
