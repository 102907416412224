<form class="d-flex flex-column flex-fill h-100" [formGroup]="form" *transloco="let t">
    <h1 mat-dialog-title class="text-truncate flex-shrink-0">{{ t('bid.title', { name: data.name }) }}</h1>
    <mat-dialog-content class="d-flex flex-column flex-grow-1 gap-2">
        <td-operation-status class="w-100" [operation]="operation" (done)="operationDone($event)" #op></td-operation-status>
        <div *ngIf="extendedDate">
            <div>{{ t('bid.extension-info', { period: bidAdditionalPeriod }) }}</div>
            <div class="mt-2">
                <span class="mat-body-strong">{{ t('bid.new-auction-end') }}</span>
                {{ extendedDate | dayjsDate }}
            </div>
        </div>
        <td-money-input class="mt-2" [control]="form.get('bid')!" [label]="t('fields.bid-amount')"></td-money-input>
        <div
            *ngIf="bidderBalance | async; let bb"
            [tdDynamicHtml]="t('domain-detail.auction.bidder-balance-info', { bidderBalance: bb, amount: getAmount(bb) })"
        ></div>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex justify-content-end align-items-end w-100 min-height-auto">
        <button mat-flat-button color="primary" (click)="bid()" [disabled]="form.invalid || op.inProgress">{{ t('actions.bid') }}</button>
        <button mat-button (click)="cancel()" [disabled]="form.disabled">{{ t('actions.cancel') }}</button>
    </mat-dialog-actions>
</form>
