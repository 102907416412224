<ng-container *transloco="let t">
    <div *ngIf="dataSource.initialLoading$ | async" class="w-100 mt-3 d-flex justify-content-center">
        <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>

    <div
        *ngIf="enableEmptyMessage && (dataSource.empty$ | async)"
        class="empty-message p-3 w-100 text-center"
        [ngClass]="{ 'smooth-shadow': noShadow !== true }"
    >
        {{ t('reverse-record-table.empty') }}
    </div>

    <div [hidden]="dataSource.initialLoading$ | async">
        <td-error *ngIf="dataSource.error$ | async; let error" [message]="error.message" [title]="t('general.error')"></td-error>

        <div
            class="table-container d-flex"
            [ngClass]="{ 'smooth-shadow': noShadow !== true }"
            [hidden]="(dataSource.empty$ | async) || (dataSource.initialLoading$ | async)"
        >
            <mat-table class="table" [dataSource]="dataSource">
                <ng-container matColumnDef="address">
                    <mat-header-cell *matHeaderCellDef>{{ t('fields.address') }}</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <td-address
                            class="w-100"
                            [address]="row.address"
                            [shorten]="media.isActive('xs')"
                            [copy]="false"
                            [route]="['/reverse-record', row.address]"
                            [reverseRecord]="row"
                            text="address-only"
                        ></td-address>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>{{ t('fields.name') }}</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <div class="text-truncate w-100">
                            <td-domain *ngIf="row.domain" [domain]="row.domain.name"></td-domain>
                            <span *ngIf="!row.domain">{{ t('general.not-set') }}</span>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <mat-header-cell class="flex-120" *matHeaderCellDef></mat-header-cell>
                    <mat-cell class="flex-120 d-flex justify-content-end aligh-items-center" *matCellDef="let row">
                        <button mat-flat-button color="primary" (click)="edit(row)">{{ t('actions.edit') }}</button>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns"></mat-row>
            </mat-table>
        </div>

        <div class="mt-3 ms-3" *ngIf="(dataSource.empty$ | async) === false">
            <a [routerLink]="['/address', address, 'reverse-records']">{{ t('general.see-more') }}</a>
        </div>
    </div>
</ng-container>
