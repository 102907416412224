import { SortOption } from '../filters/sorter.component';

export interface BuyOfferFilterState {
    buyOfferDomain?: string;
    buyOfferSorting?: SortOption;
    buyOfferTypes?: BuyOfferType[];
}

export interface BuyOfferFilterModel {
    domainName?: string;
    offerTypes?: BuyOfferType[];
    sorting: SortOption;
    allOfferTypesSelected?: boolean;
}

export enum BuyOfferType {
    Received = 'received',
    Sent = 'sent',
}

export enum OfferAfterActionState {
    Withdrawn = 'Withdrawn',
    Accepted = 'Accepted',
    Ignored = 'Ignored',
    Placed = 'Placed',
}
