import { MediaObserver } from '@/shared/media-observer.service';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'td-feed-skeleton',
    templateUrl: './feed-skeleton.component.html',
    styles: [
        `
            :host {
                display: block;
            }

            .avatar-row::ng-deep .loader {
                margin: 0;
            }
        `,
    ],
})
export class FeedSkeletonComponent {
    @Input() items: number;

    constructor(public media: MediaObserver) {}
}
