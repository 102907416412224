<div *transloco="let t">
    <td-error *ngIf="dataSource.error$ | async; let error" [message]="error.message" [title]="t('general.error')"></td-error>

    <div class="filter">
        <form [formGroup]="form" action="." (ngSubmit)="reload()" class="d-flex flex-column flex-sm-row justify-content-between">
            <div class="d-flex flex-grow-1 flex-sm-grow-0">
                <mat-form-field class="flex-grow-1 flex-sm-grow-0" appearance="standard" floatLabel="never">
                    <input
                        matInput
                        formControlName="filter"
                        autocapitalize="off"
                        autocomplete="off"
                        autocorrect="off"
                        type="search"
                        type="search"
                        [placeholder]="t('domain-table.filter-placeholder')"
                    />
                    <fa-icon matSuffix class="clear-button primary" icon="times" (click)="form.get('filter')!.setValue('')"></fa-icon>
                </mat-form-field>
            </div>
        </form>
    </div>

    <div *ngIf="(dataSource.empty$ | async) && (hideResults | async) === false" class="empty-message smooth-shadow p-3 w-100 text-center">
        {{ t('reverse-record-table.empty') }}
    </div>

    <div
        class="reverse-record-table table-container smooth-shadow d-flex"
        infiniteScroll
        (scrolled)="scrolled()"
        [hidden]="(dataSource.empty$ | async) || (hideResults | async)"
    >
        <mat-table
            class="table"
            [dataSource]="dataSource"
            matSort
            (matSortChange)="reload()"
            [matSortActive]="sortField"
            [matSortDirection]="sortDirection"
            matSortDisableClear
            #sort="matSort"
        >
            <ng-container matColumnDef="address">
                <mat-header-cell *matHeaderCellDef mat-sort-header="ADDRESS">{{ t('fields.address') }}</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <td-address
                        class="w-100"
                        [address]="row.address"
                        [shorten]="media.isActive('xs')"
                        [copy]="false"
                        [route]="['/reverse-record', row.address]"
                        [reverseRecord]="row"
                        text="address-only"
                    ></td-address>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header="NAME">{{ t('fields.name') }}</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div class="text-truncate w-100">
                        <td-domain *ngIf="row.domain" [domain]="row.domain.name"></td-domain>
                        <span *ngIf="!row.domain">{{ t('general.not-set') }}</span>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell class="col-actions" *matHeaderCellDef></mat-header-cell>
                <mat-cell class="col-actions d-flex align-items-center justify-content-end" *matCellDef="let row">
                    <button mat-flat-button color="primary" (click)="edit(row)">{{ t('actions.edit') }}</button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columns"></mat-row>
        </mat-table>
    </div>
    <div *ngIf="dataSource.loading$ | async" class="w-100 mt-3 d-flex align-content-center">
        <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>
</div>
