<ng-container *transloco="let t">
    <ng-container *ngIf="initialLoading$ | async">
        <td-data-card-skeleton [rows]="3"></td-data-card-skeleton>
        <td-title-skeleton></td-title-skeleton>
        <td-table-skeleton [rows]="10"></td-table-skeleton>
    </ng-container>

    <div [hidden]="(initialLoading$ | async) == true">
        <td-pool-widget [showSeeMore]="false" (depositedAmountChanged)="refreshHistory()"></td-pool-widget>

        <td-delegate-info *ngIf="address" [address]="address" #delegateInfo [hidden]="(delegateInfo.vm$ | async)?.hidden" class="mt-4"></td-delegate-info>

        <div class="d-flex flex-column gap-3 mt-2">
            <h1 class="d-flex align-items-center mt-4 mb-0">
                <div class="text-truncated" [style.minWidth]="'calc(100% - 64px)'">{{ t('gov-page.title') }}</div>
            </h1>

            <td-history-filter (filterChange)="filterChanged($event)"></td-history-filter>

            <div *ngIf="(dataSource.empty$ | async) && (hideResults | async) === false" class="empty-message p-3 w-100 text-center smooth-shadow">
                {{ t('gov-page.reward-history.nothing') }}
            </div>

            <div
                class="table-container d-flex smooth-shadow w-100"
                infiniteScroll
                (scrolled)="scrolled()"
                [class.d-none]="(dataSource.empty$ | async) || (hideResults | async)"
            >
                <mat-table class="table w-100" [dataSource]="dataSource">
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef>{{ t('gov-page.reward-history.date-header') }}</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            {{ row.timestamp | dayjsDate : 'medium' }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="type">
                        <mat-header-cell *matHeaderCellDef>{{ t('gov-page.reward-history.type-header') }}</mat-header-cell>
                        <mat-cell class="justify-content-start" *matCellDef="let row">
                            {{ t('gov-page.reward-type.' + row.type) }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="amount">
                        <mat-header-cell *matHeaderCellDef>{{ t('gov-page.reward-history.change-header') }}</mat-header-cell>
                        <mat-cell
                            class="justify-content-start"
                            [ngClass]="{
                                'txt-success': row.type === 'reward' || row.type === 'deposit' || row.type === 'received',
                                'txt-danger': row.type === 'withdrawal' || row.type === 'sent'
                            }"
                            *matCellDef="let row"
                        >
                            <span [matTooltip]="row.amount | formatTed : 7">{{ row.amount | formatTed }}</span>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="apr">
                        <mat-header-cell *matHeaderCellDef>{{ t('gov-page.reward-history.apr-header') }}</mat-header-cell>
                        <mat-cell class="justify-content-start" *matCellDef="let row">
                            {{ row.event_reward?.Apr | apr }}
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="['name', 'type', 'amount', 'apr']"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: ['name', 'type', 'amount', 'apr']"></mat-row>
                </mat-table>
            </div>
            <div *ngIf="dataSource.loading$ | async" class="w-100 mt-3 d-flex justify-content-center">
                <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
            </div>
        </div>
    </div>
</ng-container>
