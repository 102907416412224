import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { getLabel, getTld } from '@tezos-domains/core';
import { DomainAcquisitionInfo } from '@tezos-domains/manager';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DomainDetailQuery } from '../graphql/graphql.generated';
import { OperationStatusDoneEvent } from '../shared/operation-status.component';
import { TezosWallet } from '../tezos/models';
import { SmartContractOperationEvent, TezosService } from '../tezos/tezos.service';
import { TdValidators } from '../utils/form-validators';
import { ExpiringDomainsStatsService } from './expiring-domains-stats.service';

@Component({
    selector: 'td-renew',
    templateUrl: './renew.component.html',
    styleUrls: ['./renew.component.scss'],
})
export class RenewComponent implements OnInit, OnDestroy {
    operation: Observable<SmartContractOperationEvent> | null;
    form: UntypedFormGroup;
    wallet: TezosWallet;
    domain: NonNullable<DomainDetailQuery['domain']>;

    private label: string;
    private tld: string;

    private unsubscribe = new Subject<void>();

    constructor(
        private tezosService: TezosService,
        formBuilder: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: { domain: NonNullable<DomainDetailQuery['domain']>; acquisitionInfo: DomainAcquisitionInfo },
        private dialogRef: MatDialogRef<RenewComponent>,
        private expiringStats: ExpiringDomainsStatsService
    ) {
        this.form = formBuilder.group({
            duration: formBuilder.control(1, [Validators.required, TdValidators.number(), Validators.min(1), Validators.max(100)]),
            price: formBuilder.control(0),
        });
    }

    ngOnInit(): void {
        this.domain = this.data.domain;
        this.label = getLabel(this.domain.name);
        this.tld = getTld(this.domain.name);

        this.tezosService.activeWallet.pipe(takeUntil(this.unsubscribe)).subscribe(w => (this.wallet = w!));

        this.form
            .get('duration')!
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.updatePrice());

        this.updatePrice();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    renew() {
        this.form.disable();
        this.operation = this.tezosService.execute(client => client.manager.renew(this.tld, { label: this.label, duration: this.getDuration() }));
    }

    cancel() {
        this.dialogRef.close(false);
    }

    operationDone(event: OperationStatusDoneEvent) {
        if (event.success) {
            this.dialogRef.close(true);
            this.expiringStats.refresh();
        } else {
            this.form.enable();
        }
    }

    private updatePrice() {
        this.form.get('price')!.setValue(this.data.acquisitionInfo.calculatePrice(this.getDuration()) / 1e6);
    }

    private getDuration() {
        return this.form.get('duration')!.value * 365;
    }
}
