import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil, skip } from 'rxjs/operators';
import escape from 'lodash-es/escape';

import { ReverseRecordDetailDataSource } from '../graphql/reverse-record-data-source';
import { ReverseRecordDetailQuery } from '../graphql/graphql.generated';
import { TezosWallet } from '../tezos/models';
import { DataSourceFactory } from '../graphql/data-source-factory';
import { TezosService } from '../tezos/tezos.service';
import { PageService } from '../browser/page.service';
import { isTezosAddress } from '../utils/validate';
import { ReverseRecordService } from '../domains/reverse-record.service';
import { OverlayService } from '../overlay.service';

@Component({
    selector: 'td-reverse-record-detail',
    templateUrl: './reverse-record-detail.component.html',
    styleUrls: ['./reverse-record-detail.component.scss'],
})
export class ReverseRecordDetailComponent implements OnInit, OnDestroy {
    dataSource: ReverseRecordDetailDataSource;
    reverseRecord: ReverseRecordDetailQuery['reverseRecord'];
    wallet: TezosWallet | null;
    errorMessage: string | null;
    errorTitleKey = '';
    address: string;

    private unsubscribe = new Subject<void>();

    constructor(
        private activatedRoute: ActivatedRoute,
        private dataSourceFactory: DataSourceFactory,
        private tezosService: TezosService,
        private overlayService: OverlayService,
        private translation: TranslocoService,
        private pageService: PageService,
        private reverseRecordService: ReverseRecordService
    ) {}

    ngOnInit(): void {
        this.dataSource = this.dataSourceFactory.createReverseRecordDetailDataSource();
        this.activatedRoute.params.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.loadData());

        combineLatest([this.tezosService.activeWallet, this.dataSource.data$])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(([wallet, data]) => {
                this.wallet = wallet;
                if (data) {
                    if (!data.reverseRecord) {
                        if (this.address === wallet?.address) {
                            this.reverseRecord = {
                                address: wallet?.address,
                            } as any;
                        } else {
                            /**
                             * t(reverse-record-detail.not-found)
                             */
                            this.setError('error.not-found-title', 'reverse-record-detail.not-found', { address: escape(this.address) });
                        }
                    } else {
                        this.reverseRecord = data.reverseRecord;
                    }
                } else {
                    this.reverseRecord = null;
                }
            });

        this.dataSource.error$.pipe(takeUntil(this.unsubscribe)).subscribe(error => {
            if (error) {
                this.errorTitleKey = 'general.error';
                this.errorMessage = error.message;
            } else {
                this.errorMessage = null;
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.dataSource.disconnect();
    }

    loadData() {
        this.address = this.activatedRoute.snapshot.params['address'];
        this.pageService.setTitle('reverse-record', { address: this.address });
        this.errorMessage = null;
        this.reverseRecord = null;

        if (isTezosAddress(this.address)) {
            this.dataSource.load({ address: this.address });
        } else {
            this.setError('general.title-invalid-address', 'general.invalid-address-error', { address: escape(this.address) });
        }
    }

    edit() {
        const dialog = this.overlayService.openReverseRecordEdit(this.reverseRecord);

        dialog.afterClosed().subscribe(result => {
            if (result) {
                this.loadData();
                this.reverseRecordService.refresh();
            }
        });
    }

    private setError(titleKey: string, messageKey: string, messageParams: any) {
        this.errorTitleKey = titleKey;
        this.translation
            .selectTranslate(messageKey, messageParams)
            .pipe(takeUntil(this.activatedRoute.params.pipe(skip(1))))
            .subscribe(m => (this.errorMessage = m));
    }
}
