import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OfferRecord } from '../graphql/offer-table-data-source';
import { TezosWallet } from '../tezos/models';
import { BulkOfferCancelComponent } from './bulk-offer-cancel.component';
import { OfferTableComponent } from './offer-table.component';

@Component({
    selector: 'td-offer-list',
    templateUrl: './offer-list.component.html',
    styleUrls: ['./offer-list.component.scss'],
})
export class OfferListComponent {
    selected: OfferRecord[] = [];

    @ViewChild(OfferTableComponent) domainTable: OfferTableComponent;

    constructor(private dialog: MatDialog) {}

    domainsSelected(data: any[]) {
        this.selected = data;
    }

    cancelOffers(wallet: TezosWallet) {
        this.dialog
            .open(BulkOfferCancelComponent, { data: { offers: this.selected, wallet }, height: '220px' })
            .afterClosed()
            .subscribe(success => {
                if (success) {
                    this.domainTable.clearSelection();
                    this.domainTable.reload();
                }
            });
    }
}
