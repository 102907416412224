import { DataSourcePagination, ProcessedData } from '../data-source-base';

export class GovernanceDataSourcePagination<T extends { limit?: number | null; offset?: number | null }> implements DataSourcePagination<T> {
    private offset = 0;
    private limit;

    isLastPageLoaded = false;

    constructor({ limit }: { limit: number } = { limit: 30 }) {
        this.limit = limit;
    }

    get currentPagingState(): { limit: number; offset: number } {
        return {
            limit: this.limit,
            offset: this.offset,
        };
    }

    update(processedData: ProcessedData<any>) {
        this.offset = this.offset + this.limit;
        this.isLastPageLoaded = !processedData.data.length;
    }

    fetchMore(direction: 'up' | 'down' = 'down'): T {
        if (direction === 'down') {
            return {
                limit: this.limit,
                offset: this.offset,
            } as T;
        }

        throw new Error('Not implemented');
    }

    reset(variables: T) {
        this.offset = variables.offset ?? 0;
        this.limit = variables.limit ?? this.limit;
        this.isLastPageLoaded = false;
    }
}
