import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { BuyOfferRecord, BuyOfferTableDataSource } from '../graphql/buy-offer-table-data-source';
import { DataSourceFactory } from '../graphql/data-source-factory';
import { BuyOfferListQueryVariables, BuyOffersFilter, OfferOrder, OfferOrderField, OfferState, OrderDirection } from '../graphql/graphql.generated';
import { TezosWallet } from '../tezos/models';
import { BuyOfferFilterModel, BuyOfferType, OfferAfterActionState } from './buy-offer.models';
import { IgnoredOffersService } from './ignored-offers.service';

@UntilDestroy()
@Component({
    selector: 'td-buy-offer-list',
    templateUrl: './buy-offer-list.component.html',
    styleUrls: ['./buy-offer-list.component.scss'],
})
export class BuyOfferListComponent implements OnInit, OnChanges {
    dataSource: BuyOfferTableDataSource;

    @Input() maxCount: number | undefined;
    @Input() scrollToLoad = true;
    @Input() showShadow = true;
    @Input() pollInterval?: number;

    @Input() wallet: TezosWallet | null = null;
    @Input() address?: string;
    @Input() loadAddressSpecificData = true;
    @Input() filter: BuyOfferFilterModel;

    @Input() allowedToIgnoreOffer = false;
    @Input() canShowYouBadge = false;

    @Input() canSelectOffers = false;

    @Output() dataLoading = new EventEmitter<boolean>();
    @Output() offerSelectedChange = new EventEmitter<{ offers: Readonly<BuyOfferRecord[]>; allSelected: boolean }>();

    @ViewChild(InfiniteScrollDirective) infiniteScroll: InfiniteScrollDirective;

    selection = new SelectionModel<BuyOfferRecord>(true, []);

    loading = true;
    someOffersLoaded = false;
    data$: Observable<BuyOfferRecord[]>;
    ownLoadedOffers: BuyOfferRecord[] = [];
    showSelectOffersCheckbox = false;

    constructor(private dataSourceFactory: DataSourceFactory, private ignoredOffersService: IgnoredOffersService) {
        this.dataSource = this.dataSourceFactory.createBuyOfferTableDataSource();
    }

    ngOnInit(): void {
        this.dataSource.initialLoading$
            .pipe(
                first(loading => loading),
                untilDestroyed(this)
            )
            .subscribe(() => (this.loading = true));

        this.data$ = this.dataSource.data$.pipe(
            tap(data => {
                this.someOffersLoaded = !!data?.length;
                this.ownLoadedOffers = data?.filter(d => d.buyer === this.wallet?.address) ?? [];
                this.showSelectOffersCheckbox = this.canSelectOffers && this.ownLoadedOffers.length > 0;
                this.setLoading(false);
            })
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['filter'] && this.filter) {
            this.onFilterChange(this.filter, this.address);
        }
    }

    offerSelected(selected: boolean, offer: BuyOfferRecord): void {
        if (selected) {
            this.selection.select(offer);
        } else {
            this.selection.deselect(offer);
        }
        this.offerSelectedChange.next({ offers: this.selection.selected, allSelected: this.selection.selected.length === this.ownLoadedOffers.length });
    }

    resetSelected(): void {
        this.selection.clear();
    }

    toggleSelectAll(): void {
        if (this.selection.selected.length === this.ownLoadedOffers.length) {
            this.selection.clear();
            this.offerSelectedChange.next({ offers: this.selection.selected, allSelected: false });
        } else {
            this.selection.setSelection(...this.ownLoadedOffers);
            this.offerSelectedChange.next({ offers: this.selection.selected, allSelected: true });
        }
    }

    scrolled() {
        this.dataSource.loadMore();
    }

    offerActedOn(change: OfferAfterActionState) {
        if (change === OfferAfterActionState.Ignored) {
            this.loadOffers(this.filter, this.address);
        } else {
            this.dataSource.reload();
        }
    }

    private onFilterChange(filter: BuyOfferFilterModel, address?: string): void {
        if (this.infiniteScroll) {
            this.infiniteScroll.destroyScroller();
            this.infiniteScroll.setup();
        }

        this.setLoading(true);

        this.ignoredOffersService.ignoredOffers$.pipe(first()).subscribe(() => {
            this.loadOffers(filter, address);
        });
    }

    private loadOffers(filter: BuyOfferFilterModel, address?: string): void {
        const where: BuyOffersFilter = {};

        if (this.loadAddressSpecificData && address) {
            const orConditions: BuyOffersFilter[] = [];

            if (filter.offerTypes?.includes(BuyOfferType.Sent) || !filter.offerTypes?.length) {
                orConditions.push({
                    buyerAddress: { equalTo: address },
                    state: {
                        in: [
                            OfferState.Active,
                            OfferState.OfferExpired,
                            OfferState.DomainExpired,
                            OfferState.DomainIsExpiringSoon,
                            OfferState.DomainDoesNotExist,
                        ],
                    },
                });
            }

            if (filter.offerTypes?.includes(BuyOfferType.Received) || !filter.offerTypes?.length) {
                orConditions.push({ sellerAddress: { equalTo: address }, state: { in: [OfferState.Active] } });
            }

            if (orConditions.length) {
                where.and = [{ or: orConditions }];
            }
        } else {
            // for not connected users we show all offers that are active
            where.state = { in: [OfferState.Active] };
        }

        const ignoredOffers = this.ignoredOffersService.allIgnoredOffers();
        if (this.allowedToIgnoreOffer && ignoredOffers.length) {
            where.and = where.and ?? [];
            where.and.push({ id: { notIn: ignoredOffers } });
        }

        if (filter.domainName) {
            where.domainName = { like: filter.domainName };
        }

        const orderField = <OfferOrderField>filter.sorting.value;
        if (orderField === OfferOrderField.EndsAt) {
            where.endsAtUtc = { isNull: false };
        }

        const order: OfferOrder = {
            field: <OfferOrderField>filter.sorting.value,
            direction: filter.sorting.direction === 'asc' ? OrderDirection.Asc : OrderDirection.Desc,
        };

        const variables: BuyOfferListQueryVariables = {
            where,
            order,
            first: this.maxCount,
        };

        this.dataSource.load(variables, this.pollInterval ? { pollInterval: this.pollInterval } : undefined);
    }
    private setLoading(loading: boolean) {
        this.loading = loading;
        this.dataLoading.next(this.loading);
    }
}
