import { formatNumber } from '@angular/common';
import { LOCALE_ID, Pipe, PipeTransform, inject } from '@angular/core';
import BigNumber from 'bignumber.js';
import { FromMutezPipe } from './from-mutez.pipe';

@Pipe({
    name: 'formatTed',
})
export class FormatTedPipe implements PipeTransform {
    private fromMutez = inject(FromMutezPipe);
    private locale = inject(LOCALE_ID);

    transform(value: number | BigNumber | undefined | null, maxDecimals = 2): string {
        if (value == null) {
            return '';
        }

        if (BigNumber(value).isEqualTo(0)) {
            return '0';
        }

        const format = `1.0-${maxDecimals}`;

        const val = this.fromMutez.transform(value);
        const formatted = formatNumber(val, this.locale, format) ?? '';

        if (formatted === '0') {
            const min = maxDecimals > 0 ? `0.${'0'.repeat(maxDecimals - 1)}1` : '0.1';
            return `< ${min}`;
        }

        return formatted;
    }
}
