import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Configuration } from '../../../app/configuration';

export type ValidationResult =
    | 'OK'
    | 'DomainNonExistant'
    | 'NoDNSSEC'
    | 'FailedDNSSEC'
    | 'NoTXT'
    | 'NoAddressInTXT'
    | 'MultipleAddressesInTXT'
    | 'TXTNoMatch'
    | 'UnknownFailure';

export interface DomainValidationRequest {
    readonly label: string;
    readonly owner: string;
    readonly tld: string;
}

export interface DomainValidationResponse {
    readonly status: ValidationResult;
    readonly signature?: string;
    readonly txtValue?: string;
    readonly signedAt?: string;
    readonly dnsErrorMessage?: string;
    readonly dnsErrorCode?: string;
    readonly error?: string;
}

@Injectable()
export class DnsVerifyApiService {
    constructor(private httpClient: HttpClient, private configuration: Configuration) {}

    checkStatus(label: string, tld: string, owner: string): Observable<DomainValidationResponse> {
        const request: DomainValidationRequest = {
            label,
            owner,
            tld,
        };

        return this.httpClient.post(`${this.configuration.dnsVerifyApiUrl}/api/verify`, request).pipe(
            map(r => <DomainValidationResponse>r),
            catchError((e: HttpErrorResponse) => of(<DomainValidationResponse>{ status: 'UnknownFailure', error: e.error?.error }))
        );
    }
}
