<ng-container *transloco="let t">
    <div>
        <div class="flex-shrink-0">
            <mat-radio-button [checked]="wantsToDelegate" (change)="onWantsToDelegate()">
                <span class="wrap-mat-radio-label text-wrap"> {{ t('airdrop.delegate-list.wants-delegate') }} </span>
            </mat-radio-button>
        </div>
        <ng-container *ngIf="wantsToDelegate">
            <td-delegate-finder
                class="ms-4"
                (delegateSelected)="onDelegateSelected($event)"
                [existingDelegate]="initialDelegate"
                [showScroll]="showScroll"
            ></td-delegate-finder>
        </ng-container>
    </div>
    <div>
        <div class="my-3 flex-shrink-0">
            <mat-radio-button [checked]="!wantsToDelegate" (change)="onDoesntWantToDelegate()">
                <span class="wrap-mat-radio-label text-wrap"> {{ t('airdrop.delegate-list.wants-vote') }} </span>
            </mat-radio-button>
        </div>
        <div class="mx-4" *ngIf="!wantsToDelegate">
            <td-alert [state]="'warning'"> {{ t('airdrop.delegate-list.wants-vote-info') }}</td-alert>
        </div>
    </div>
</ng-container>
