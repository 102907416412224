<form [formGroup]="form" class="d-flex flex-column flex-fill h-100" *transloco="let t">
    <div class="d-flex flex-column">
        <h1 mat-dialog-title class="text-truncate">
            <ng-container *ngIf="!success">{{ t('execute-buy-offer-form.title', { name: offer.domain?.name }) }}</ng-container>
            <ng-container *ngIf="success">{{ t('execute-buy-offer-form.success-title', { name: offer.domain?.name }) }}</ng-container>
        </h1>
        <td-operation-status class="w-100" [operation]="operation" (done)="operationDone($event)" #op></td-operation-status>
    </div>
    <mat-dialog-content class="d-flex flex-column flex-grow-1">
        <div class="d-flex flex-column gap-3" *ngIf="!success">
            <td-money-input [label]="t('fields.you-get')" [control]="form.get('price')!" [displayOnly]="true"></td-money-input>
            <div *ngIf="offer.expiration" class="d-flex flex-column flex-sm-row align-items-sm-center">
                <div class="mat-body-strong offer-info">{{ t('fields.buy-offer-expires') }}</div>

                <div [matTooltip]="offer.expiration | dayjsDate"><td-humanized-date [value]="offer.expiration"></td-humanized-date></div>
            </div>
            <div *ngIf="offer.domain!.expires" class="d-flex flex-column flex-sm-row align-items-sm-center">
                <div class="mat-body-strong offer-info">{{ t('fields.domain-expires') }}</div>
                <div [matTooltip]="offer.domain!.expires | dayjsDate"><td-humanized-date [value]="offer.domain!.expires"></td-humanized-date></div>
            </div>
            <div class="d-flex flex-column flex-sm-row align-items-sm-center">
                <div class="mat-body-strong offer-info">{{ t('fields.buyer') }}</div>
                <div class="flex-grow-1">
                    <td-address
                        [reverseRecord]="offer.buyerReverseRecord"
                        [address]="offer.buyer"
                        [copy]="false"
                        [link]="false"
                        text="name-or-address"
                    ></td-address>
                </div>
            </div>
            <mat-slide-toggle formControlName="clearAddress">
                <span>{{ t('fields.clear-buy-offer-address') }}</span>
            </mat-slide-toggle>
            <td-alert state="warning" *ngIf="!form.get('clearAddress')!.value">
                <div [tdDynamicHtml]="t('execute-buy-offer-form.warning', { name: offer.domain?.name })"></div>
            </td-alert>
        </div>
        <div class="d-flex flex-column justify-content-center gap-2" *ngIf="success">
            <td-share
                class="mt-4 pt-4"
                [text]="t('execute-buy-offer-form.share-text', { name: offer.domain!.name })"
                [domainName]="offer.domain!.name"
            ></td-share>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex justify-content-end align-items-end w-100 min-height-auto">
        <ng-container *ngIf="!success">
            <button mat-flat-button color="primary" (click)="save()" [disabled]="form.disabled || op.inProgress">
                {{ t('actions.buy-offer.accept') }}
            </button>
            <button mat-button (click)="cancel()" [disabled]="op.inProgress">{{ t('actions.cancel') }}</button>
        </ng-container>
        <ng-container *ngIf="success">
            <button mat-flat-button color="primary" (click)="continue()">{{ t('actions.close') }}</button>
        </ng-container>
    </mat-dialog-actions>
</form>
