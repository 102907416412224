<ng-container *transloco="let t">
    <td-recipient
        [control]="$any(form.get('delegateInput'))"
        [label]="t('fields.delegate-search')"
        [placeholder]="t('fields.delegate-search-placeholder')"
        [noKT]="true"
    ></td-recipient>
    <div *ngIf="loading$ | async" class="w-100 flex-grow-1">
        <div class="d-flex flex-column justify-content-center align-items-center h-100">
            <mat-progress-spinner [diameter]="48" color="primary" mode="indeterminate"></mat-progress-spinner>
        </div>
    </div>
    <div class="delegate-list mt-1 flex-grow-1" [ngClass]="{ 'hide-scroll': !showScroll }">
        <ng-container *ngIf="vm$ | async as delegateList">
            <td-delegate-item
                [address]="delegate.rRecord.address"
                [reverseRecord]="delegate.rRecord"
                [tzProfile]="delegate.profile"
                [votingPower]="delegate.votingPower"
                [selected]="delegate === selected"
                *ngFor="let delegate of delegateList"
                (click)="select(delegate)"
            ></td-delegate-item>
        </ng-container>
    </div>
</ng-container>
