import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TaquitoTezosDomainsClient } from '@tezos-domains/taquito-client';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BuyOfferRecord } from '../graphql/buy-offer-table-data-source';
import { TezosDomainsClientService } from '../tezos/integration/tezos-domains-client.service';
import { TezosWallet } from '../tezos/models';
import { TezosService } from '../tezos/tezos.service';
import { isTezosAddress } from '../utils/validate';
import { BulkRemoveBuyOffersComponent } from './bulk-remove-buy-offers.component';
import { BuyOfferListComponent } from './buy-offer-list.component';
import { BuyOfferFilterModel, BuyOfferType } from './buy-offer.models';

@Component({
    selector: 'td-buy-offers-page',
    templateUrl: './buy-offers-page.component.html',
    styleUrls: ['./buy-offers-page.component.scss'],
})
export class BuyOffersPageComponent implements OnInit {
    tzData$: Observable<{
        tzDomainsClient: TaquitoTezosDomainsClient;
        wallet: TezosWallet | null;
        preferredOfferType?: BuyOfferType;
        showAllListings: boolean;
    }>;

    filter: BuyOfferFilterModel;
    selectedOffers: Readonly<BuyOfferRecord[]> = [];
    allSelected = false;

    @ViewChild(BuyOfferListComponent) offersList?: BuyOfferListComponent;

    constructor(
        private tezosDomainsClientService: TezosDomainsClientService,
        private tezosService: TezosService,
        private dialog: MatDialog,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.tzData$ = combineLatest([this.tezosDomainsClientService.current, this.tezosService.activeWallet, this.route.params]).pipe(
            map(([tzDomainsClient, wallet, params]) => {
                const showAllListings = !params.address || !isTezosAddress(params.address);
                const preferredOfferType = this.getBuyOfferTypes(params.type, showAllListings);
                return { tzDomainsClient, wallet, preferredOfferType, showAllListings };
            })
        );
    }

    onFilterChange(filter: BuyOfferFilterModel): void {
        this.filter = filter;
    }

    onOfferSelected(data: { offers: Readonly<BuyOfferRecord[]>; allSelected: boolean }): void {
        this.selectedOffers = data.offers;
        this.allSelected = data.allSelected;
    }

    toggleSelectAll(): void {
        this.offersList?.toggleSelectAll();
    }

    withdrawSelected(): void {
        const dialog = this.dialog.open(BulkRemoveBuyOffersComponent, {
            data: { offers: this.selectedOffers },
            height: '200px',
        });

        dialog.afterClosed().subscribe(result => {
            if (result) {
                this.filter = { ...this.filter };
                this.selectedOffers = [];
                this.offersList?.resetSelected();
            }
        });
    }

    private getBuyOfferTypes(type: string, showAllListings: boolean): BuyOfferType | undefined {
        if (showAllListings) {
            return BuyOfferType.Sent;
        }

        if (type === 'received') {
            return BuyOfferType.Received;
        }

        if (type === 'sent') {
            return BuyOfferType.Sent;
        }
    }
}
