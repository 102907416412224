import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSort, SortDirection } from '@angular/material/sort';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { EventsDataSource } from '../../graphql/events-table-data-source';
import { EventOrderField } from '../../graphql/graphql.generated';
import { TezosNetwork } from '../../tezos/models';
import { ActivitySorting } from './model';

@Component({
    selector: 'td-activity-list',
    templateUrl: './activity-list.component.html',
    styleUrls: ['./activity-list.component.scss'],
})
export class ActivityListComponent {
    @Input() dataSource: EventsDataSource;
    @Input() network: TezosNetwork;
    @Input() hideResults = false;

    @Output() sortChange = new EventEmitter<ActivitySorting>();

    sortField = EventOrderField.Timestamp;
    sortDirection: SortDirection = 'desc';

    @ViewChild(MatSort) sorter: MatSort;
    @ViewChild(InfiniteScrollDirective) infiniteScroll: InfiniteScrollDirective;

    scrolled() {
        this.dataSource.loadMore();
    }

    resetScroll() {
        this.infiniteScroll?.destroyScroller();
        this.infiniteScroll?.setup();
    }
}
