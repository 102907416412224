import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TaquitoTezosDomainsClient } from '@tezos-domains/taquito-client';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { flatMap, isEqual } from 'lodash-es';
import { BehaviorSubject, Subscription, combineLatest, interval } from 'rxjs';
import { debounce, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { ListOption, ListOptionType } from 'src/app/filters/list-filter.component';
import { FilterHistoryStateService } from '../../browser/filter-history-state.service';
import { Configuration } from '../../configuration';
import { DateFilter, PriceFilter } from '../../filters/model';
import { EventType } from '../../graphql/graphql.generated';
import { HorizontalScrollComponent } from '../../shared/horizontal-scroll.component';
import { TezosNetwork } from '../../tezos/models';
import { ActivityFormFilter } from './model';

export interface ActivityFormFilterState {
    activityPrice?: PriceFilter | null;
    activityDate?: DateFilter | null;
    activityEventType?: EventType[];
    activityAddress?: string;
    activityDomain?: string;
}

@Component({
    selector: 'td-activity-form',
    templateUrl: './activity-form.component.html',
    styleUrls: ['./activity-form.component.scss'],
})
export class ActivityFormComponent implements OnInit, OnChanges, OnDestroy {
    @Input() network: TezosNetwork;
    @Input() tzDomainsClient: TaquitoTezosDomainsClient;
    @Output() filterChange = new EventEmitter<ActivityFormFilter>();

    @ViewChild(HorizontalScrollComponent) horizontalScroll: HorizontalScrollComponent;

    maxDate = dayjs().endOf('day');
    minDate = dayjs(this.config.minEventsFilterDate);

    private allowedEventTypes = flatMap(
        this.mapAllowedOptions()
            .filter(x => x.type === ListOptionType.Item)
            .map(x => x.values.map(v => <EventType>v))
    );

    eventTypeOptions: ListOption[] = this.mapAllowedOptions();

    initialDateFilter: DateFilter | null = null;
    initialAddress = '';
    initialDomain = '';
    initialPriceFilter: PriceFilter | null = null;
    initialEventTypes: EventType[] = [];

    private price$: BehaviorSubject<PriceFilter | null>;
    private date$: BehaviorSubject<DateFilter | null>;
    private types$: BehaviorSubject<EventType[]>;
    private address$: BehaviorSubject<string>;
    private domain$: BehaviorSubject<string>;

    private filters$: Subscription | null = null;

    constructor(private historyStateService: FilterHistoryStateService, private config: Configuration, private transloco: TranslocoService) {}

    ngOnInit(): void {
        const state = this.historyStateService.get<ActivityFormFilterState>();

        this.initialDateFilter = state.activityDate ?? null;

        this.initialAddress = state.activityAddress ?? '';
        this.initialDomain = state.activityDomain ?? '';
        this.initialPriceFilter = state.activityPrice ?? null;
        this.initialEventTypes = state.activityEventType ?? [];

        this.price$ = new BehaviorSubject<PriceFilter | null>(this.initialPriceFilter);
        this.date$ = new BehaviorSubject<DateFilter | null>(this.initialDateFilter);
        this.types$ = new BehaviorSubject<EventType[]>(this.initialEventTypes);
        this.address$ = new BehaviorSubject<string>(this.initialAddress);
        this.domain$ = new BehaviorSubject<string>(this.initialDomain);

        this.initializeFilter();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes['tzDomainsClient'].isFirstChange()) {
            this.initializeFilter();
        }
    }

    ngOnDestroy(): void {
        this.filters$?.unsubscribe();
        this.filters$ = null;
    }

    priceChanged(data: PriceFilter | null) {
        this.price$.next(data);
    }

    dateChanged(data: DateFilter | null) {
        this.date$.next(data ?? this.initialDateFilter);
    }

    typesChanged(data: string[]) {
        this.types$.next(data.map(x => <EventType>x));
    }

    addressChanged(data: string) {
        this.address$.next(data);
    }

    domainChanged(data: string) {
        this.domain$.next(data);
    }

    private initializeFilter() {
        this.filters$?.unsubscribe();
        this.filters$ = combineLatest([this.price$, this.date$, this.types$, this.address$, this.domain$])
            .pipe(
                debounce(() => interval(300)), // easier to unittest
                tap(([price, date, types, address, domain]) => this.saveState(price, date, types, address, domain)),
                map(([price, date, types, address, domain]) => this.toFilterModel(price, date, types, address, domain)),
                distinctUntilChanged<ActivityFormFilter>(isEqual)
            )
            .subscribe(filter => {
                this.horizontalScroll?.refreshScroll();
                this.filterChange.next(filter);
            });
    }

    private toFilterModel(price: PriceFilter | null, date: DateFilter | null, types: EventType[], address: string, domain: string): ActivityFormFilter {
        const model: ActivityFormFilter = {
            address: address,
            domainName: domain,
            types: this.getTypes(types),
            date: this.getDateFilter(date),
            price: null,
        };

        if (price && (price.min || price.max)) {
            model.price = {};
            if (price.min) {
                model.price.from = new BigNumber(Number(price.min) * 1e6);
            }

            if (price.max) {
                model.price.to = new BigNumber(Number(price.max) * 1e6);
            }
        }

        return model;
    }

    private getDateFilter(date: DateFilter | null): DateFilter {
        if (!date) {
            return { from: this.minDate, to: this.maxDate };
        }

        return {
            from: date.from.startOf('day'),
            to: date.to?.endOf('day'),
        };
    }

    private getTypes(types: EventType[]): EventType[] {
        //we should always filter by some types otherwise the api returns everything
        if (!types?.length) {
            return this.allowedEventTypes;
        }

        return types.filter(t => this.allowedEventTypes.includes(t));
    }

    private mapAllowedOptions(): ListOption[] {
        return [
            new ListOption(EventType.DomainBuyEvent, this.translateType(EventType.DomainBuyEvent), ListOptionType.Item),
            new ListOption(EventType.DomainRenewEvent, this.translateType(EventType.DomainRenewEvent), ListOptionType.Item),
            new ListOption(EventType.DomainGrantEvent, this.translateType(EventType.DomainGrantEvent), ListOptionType.Item),
            new ListOption(EventType.DomainClaimEvent, this.translateType(EventType.DomainClaimEvent), ListOptionType.Item),
            new ListOption('secondary-market', this.transloco.translate('activity.filters.event-type.secondary-market-title'), ListOptionType.Separator),
            new ListOption(
                [EventType.OfferExecutedEvent, EventType.BuyOfferExecutedEvent],
                this.translateType(EventType.OfferExecutedEvent),
                ListOptionType.Item
            ),
            new ListOption(EventType.BuyOfferPlacedEvent, this.translateType(EventType.BuyOfferPlacedEvent), ListOptionType.Item),
            new ListOption(EventType.OfferPlacedEvent, this.translateType(EventType.OfferPlacedEvent), ListOptionType.Item),
            new ListOption(
                [EventType.OfferUpdatedEvent, EventType.BuyOfferRemovedEvent, EventType.OfferRemovedEvent],
                this.translateType(EventType.OfferUpdatedEvent),
                ListOptionType.Item
            ),
            new ListOption('auctions', this.transloco.translate('activity.filters.event-type.auctions-title'), ListOptionType.Separator),
            new ListOption(EventType.AuctionBidEvent, this.translateType(EventType.AuctionBidEvent), ListOptionType.Item),
            new ListOption(EventType.AuctionSettleEvent, this.translateType(EventType.AuctionSettleEvent), ListOptionType.Item),
        ];
    }

    private translateType(type: EventType): string {
        /**
         * t(event.label.AUCTION_BID_EVENT)
         * t(event.label.AUCTION_SETTLE_EVENT)
         * t(event.label.DOMAIN_BUY_EVENT)
         * t(event.label.DOMAIN_RENEW_EVENT)
         * t(event.label.DOMAIN_GRANT_EVENT)
         * t(event.label.OFFER_EXECUTED_EVENT)
         * t(event.label.OFFER_PLACED_EVENT)
         * t(event.label.OFFER_UPDATED_EVENT)
         * t(event.label.DOMAIN_CLAIM_EVENT)
         */
        return this.transloco.translate(`event.label.${type}`);
    }

    private saveState(price: PriceFilter | null, date: DateFilter | null, types: EventType[], address: string, domain: string): void {
        this.historyStateService.merge<ActivityFormFilterState>({
            activityPrice: price,
            activityDate: date,
            activityEventType: types,
            activityAddress: address,
            activityDomain: domain,
        });
    }
}
