<ng-container *transloco="let t">
    <div class="d-flex flex-column gap-4">
        <div
            *ngIf="
                wallet === null && (acquisitionInfo.acquisitionState === states.AuctionInProgress || acquisitionInfo.acquisitionState === states.CanBeAuctioned)
            "
        >
            <td-connect-cta [title]="t('domain-detail.auction.not-connected-cta-title', { name: name })"></td-connect-cta>
        </div>

        <div class="card smooth-shadow d-flex flex-column gap-3">
            <div class="d-flex align-items-center">
                <h2 class="m-0 flex-grow-1">{{ t('domain-detail.auction.subtitle') }}</h2>
                <td-subscribe-button
                    *ngIf="isBidder"
                    [matTooltip]="t('domain-detail.auction.notifications-tooltip')"
                    class="no-underline"
                ></td-subscribe-button>
            </div>
            <div *ngIf="now < acquisitionInfo.auctionDetails.auctionEnd" class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center">
                <div class="mat-body-strong desc-header">
                    {{ t('fields.auction-end') }}
                </div>
                <div class="flex-grow-1">
                    <td-countdown [value]="acquisitionInfo.auctionDetails.auctionEnd" (zero)="check.next()" class="txt-lg"></td-countdown>
                    ({{ acquisitionInfo.auctionDetails.auctionEnd | dayjsDate }})
                </div>
            </div>
            <div *ngIf="now >= acquisitionInfo.auctionDetails.auctionEnd" class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center">
                <div class="mat-body-strong desc-header">{{ t('fields.auction-end-past') }}</div>
                <div class="flex-grow-1">
                    {{ acquisitionInfo.auctionDetails.auctionEnd | dayjsDate }}
                </div>
            </div>
            <div>
                {{ t('domain-detail.auction.period', { period: duration | humanizeDuration : false }) }}
            </div>
            <div *ngIf="acquisitionInfo.acquisitionState === states.CanBeAuctioned">
                {{ t('domain-detail.auction.no-bids') }}
            </div>
            <div *ngIf="acquisitionInfo.acquisitionState === states.CanBeSettled && wallet?.address === acquisitionInfo.auctionDetails.lastBidder">
                {{ t('domain-detail.auction.settle-info') }}
            </div>
            <div class="d-flex flex-column flex-sm-row gap-3 align-items-stretch align-items-sm-start" *ngIf="wallet">
                <div
                    *ngIf="acquisitionInfo.acquisitionState === states.CanBeAuctioned || acquisitionInfo.acquisitionState === states.AuctionInProgress"
                    [matTooltip]="t('domain-detail.auction.highest-bid-tooltip')"
                    [matTooltipDisabled]="wallet.address !== acquisitionInfo.auctionDetails.lastBidder"
                    class="no-underline"
                >
                    <button
                        [disabled]="wallet.address === acquisitionInfo.auctionDetails.lastBidder"
                        mat-flat-button
                        color="primary"
                        (click)="bid()"
                        size="default"
                        class="w-100"
                    >
                        <fa-icon icon="coins"></fa-icon>{{ t('actions.place-bid') }}
                    </button>
                </div>
                <div
                    *ngIf="acquisitionInfo.acquisitionState === states.CanBeSettled"
                    [matTooltip]="t('domain-detail.auction.settle-tooltip')"
                    [matTooltipDisabled]="wallet.address === acquisitionInfo.auctionDetails.lastBidder"
                    class="no-underline"
                >
                    <button
                        [disabled]="wallet.address !== acquisitionInfo.auctionDetails.lastBidder"
                        mat-flat-button
                        color="primary"
                        (click)="settle()"
                        size="default"
                        class="w-100"
                    >
                        <fa-icon icon="gavel"></fa-icon>{{ t('actions.settle') }}
                    </button>
                </div>
            </div>
        </div>

        <td-bid-table *ngIf="auction" [auction]="$any(auction)" [isEnded]="now >= acquisitionInfo.auctionDetails.auctionEnd"></td-bid-table>
    </div>
</ng-container>
