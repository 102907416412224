import { Component } from '@angular/core';
import { TdValidators } from '../../../utils/form-validators';
import { EditorComponentBase } from './editor-component-base';

@Component({
    selector: 'td-governance-url',
    templateUrl: './governance-url.component.html',
    styleUrls: ['./governance-url.component.scss'],
})
export class GovernanceUrlComponent extends EditorComponentBase<any> {
    protected createValidators() {
        return [TdValidators.governanceProfile];
    }
}
