import { JSONSchema, JSONSchemaArray, JSONSchemaBoolean, JSONSchemaInteger, JSONSchemaObject, JSONSchemaString } from '@ngx-pwa/local-storage';

class StorageMeta<TSchema extends JSONSchema> {
    constructor(public key: string, public schema: TSchema) {}
}

const AnyStringSchema: JSONSchemaString = { type: 'string' };
const AnyBooleanSchema: JSONSchemaBoolean = { type: 'boolean' };
const UInt32Schema: JSONSchemaInteger = { type: 'integer', minimum: 0, maximum: 0xffffffff };
const OptionsSchema: JSONSchemaArray = {
    type: 'array',
    items: { type: 'object', properties: { label: AnyStringSchema, value: AnyStringSchema } },
};
const SubConnectorSchema: JSONSchemaObject = {
    type: 'object',
    properties: {
        name: {
            type: 'string',
        },
        connectorName: {
            type: 'string',
        },
        displayName: {
            type: 'string',
        },
        logo: {
            type: 'string',
        },
        type: {
            type: 'string',
        },
        enabled: {
            type: 'boolean',
        },
    },
};
const NotificationStatusSchema: JSONSchemaObject = {
    type: 'object',
    properties: {
        email: {
            type: 'string',
        },
        status: {
            type: 'string',
            enum: ['unconfirmed', 'confirmed'],
        },
    },
};

const IgnoredBuyOffersSchema: JSONSchemaArray = {
    type: 'array',
    items: {
        type: 'array',
        items: [
            { type: 'string' },
            {
                type: 'object',
                properties: {
                    buyer: { type: 'string' },
                    expiration: { type: 'string' },
                    tokenId: { type: 'number' },
                },
            },
        ],
    },
};

const FavouritesSchema: JSONSchemaArray = {
    type: 'array',
    uniqueItems: true,
    items: {
        type: 'object',
        properties: {
            id: { type: 'string' },
            date: { type: 'string' },
        },
    },
};

export const StorageSchema = {
    rpcUrl: new StorageMeta('rpc_url', AnyStringSchema),
    derivationPath: new StorageMeta('derivation_path', AnyStringSchema),
    subConnector: new StorageMeta('sub_connector', SubConnectorSchema),
    lastSelectedConnector: new StorageMeta('last_selected_connector', AnyStringSchema),
    reverseRecord: new StorageMeta('reverse_record', AnyStringSchema),
    connectStatus: new StorageMeta('td_connect_status', AnyStringSchema),
    userPreferredLanguage: new StorageMeta('user_preferred_language', AnyStringSchema),
    userTheme: new StorageMeta('user_chosen_theme', AnyStringSchema),
    favourites(address: string) {
        return new StorageMeta(`favourites:${address}`, FavouritesSchema);
    },
    nonce(name: string, owner: string) {
        return new StorageMeta(`n|n:${name};o:${owner}`, UInt32Schema);
    },
    customOptions(key: string) {
        return new StorageMeta(`${key}:custom_options`, OptionsSchema);
    },
    notificationStatus(address: string) {
        return new StorageMeta(`notifications:${address}`, NotificationStatusSchema);
    },
    airdropBlockedNotificationStatus(address: string) {
        return new StorageMeta(`airdrop-blocked:${address}`, AnyBooleanSchema);
    },
    blockNotificationPopup(from: string) {
        return new StorageMeta(`block_notification_popup:${from}`, AnyBooleanSchema);
    },
    ignoredBuyOffers(address: string) {
        return new StorageMeta(`ignored-buy-offers:${address}`, IgnoredBuyOffersSchema);
    },
};
