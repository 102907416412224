import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { CalendarEvent, google, ics, office365 } from 'calendar-link';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OverlayService } from '../overlay.service';
import { NotificationsService, SubscriptionStatus } from './notifications.service';

interface CalendarLink {
    link: string;
    icon: IconProp;
    textKey: string;
    type: NotificationType;
}

export type NotificationType = 'email' | 'google' | 'ics' | 'office';

@Component({
    selector: 'td-subscribe-button',
    templateUrl: './subscribe-button.component.html',
    styleUrls: ['./subscribe-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscribeButtonComponent implements OnInit, OnDestroy, OnChanges {
    @Input() event?: CalendarEvent;
    @Input() buttonText?: string;
    @Input() allowedNotificationProviders: NotificationType[] = ['email', 'google', 'ics', 'office'];

    @Input() buttonStyle?: {
        color: string;
        stroked: boolean;
    };

    @Output() calendarOptionSelected = new EventEmitter();

    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
    status: SubscriptionStatus;
    calendarLinks: CalendarLink[];
    enabled: boolean;
    emailIsAllowed: boolean;

    private unsubscribe = new Subject<void>();

    constructor(private notificationsService: NotificationsService, private overlayService: OverlayService) {
        this.enabled = this.notificationsService.isEnabled;
    }

    ngOnInit(): void {
        if (!this.enabled) {
            return;
        }

        this.emailIsAllowed = this.allowedNotificationProviders.some(p => p === 'email');

        this.notificationsService.currentSubscription.pipe(takeUntil(this.unsubscribe)).subscribe(s => (this.status = s.status));

        this.calendarLinks = this.mapCalendarLinks(this.event);
    }

    ngOnChanges(): void {
        if (!this.enabled) {
            return;
        }

        this.calendarLinks = this.mapCalendarLinks(this.event);
    }

    calendarLinkClick(): void {
        this.calendarOptionSelected.emit();
    }

    private mapCalendarLinks(event: CalendarEvent | undefined): CalendarLink[] {
        if (!event) return [];

        return [
            <CalendarLink>{
                link: google(event),
                icon: ['fab', 'google'],
                textKey: 'notifications.subscribe-button.google-calendar',
                type: 'google',
            },
            <CalendarLink>{
                link: office365(event).replace('&rru=addevent', ''),
                icon: ['fab', 'windows'],
                textKey: 'notifications.subscribe-button.office-calendar',
                type: 'office',
            },
            <CalendarLink>{
                link: ics(event),
                icon: ['fab', 'apple'],
                textKey: 'notifications.subscribe-button.ics-calendar',
                type: 'ics',
            },
        ].filter(l => this.allowedNotificationProviders.some(p => l.type === p));
    }

    email() {
        this.overlayService.openSubscribe();
    }

    subscribe() {
        if (!this.event) {
            this.email();
        } else {
            this.trigger.openMenu();
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
