<div class="card smooth-shadow d-flex flex-column" *transloco="let t">
    <div class="d-flex mb-2">
        <h2 class="flex-grow-1 mb-0">{{ t('home.purchases-title') }}</h2>
        <div class="d-flex align-items-center" *ngIf="(dataSource.empty$ | async) === false">
            <a [routerLink]="['/activity']">{{ t('general.see-more') }}</a>
        </div>
    </div>

    <ng-container *ngIf="(dataSource.initialLoading$ | async) === false">
        <td-error *ngIf="dataSource.error$ | async; let error" [message]="error.message" [title]="t('general.error')"></td-error>

        <div class="full-card-width" [hidden]="dataSource.initialLoading$ | async">
            <ng-container *ngFor="let event of dataSource.data$ | async; let last = last">
                <td-home-purchase-feed-item [event]="$any(event)"></td-home-purchase-feed-item>
                <mat-divider *ngIf="!last"></mat-divider>
            </ng-container>
        </div>
        <div class="mt-3 d-flex align-items-center" *ngIf="(dataSource.empty$ | async) === false">
            <a [routerLink]="['/activity']">{{ t('general.see-more') }}</a>
        </div>
    </ng-container>
</div>
