import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DataSourceFactory } from '../graphql/data-source-factory';
import { EventType } from '../graphql/graphql.generated';
import { EventsDataSource } from '../graphql/events-table-data-source';

@Component({
    selector: 'td-event-summary-table',
    templateUrl: './event-summary-table.component.html',
    styleUrls: ['./event-summary-table.component.scss'],
})
export class EventSummaryTableComponent implements OnInit, OnChanges, OnDestroy {
    @Input() address: string;

    dataSource: EventsDataSource | null = null;
    columns: string[];

    private unsubscribe = new Subject<void>();

    constructor(private dataSourceFactory: DataSourceFactory) {}

    ngOnInit() {
        this.dataSource = this.dataSourceFactory.createEventsDataSource();

        this.load();

        interval(2 * 60 * 1000)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.update());

        this.columns = ['time', 'text', 'amount'];
    }

    ngOnChanges() {
        if (this.dataSource) {
            this.load();
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    update() {
        this.dataSource?.loadMore('up');
    }

    private load() {
        this.dataSource?.load(
            {
                where: {
                    type: {
                        in: [
                            EventType.AuctionBidEvent,
                            EventType.AuctionSettleEvent,
                            EventType.DomainBuyEvent,
                            EventType.DomainRenewEvent,
                            EventType.AuctionWithdrawEvent,
                            EventType.AuctionEndEvent,
                            EventType.DomainSetChildRecordEvent,
                            EventType.DomainUpdateEvent,
                            EventType.DomainClaimEvent,
                            EventType.ReverseRecordClaimEvent,
                            EventType.ReverseRecordUpdateEvent,
                            EventType.DomainGrantEvent,
                            EventType.DomainTransferEvent,
                            EventType.OfferPlacedEvent,
                            EventType.OfferUpdatedEvent,
                            EventType.OfferRemovedEvent,
                            EventType.OfferExecutedEvent,
                            EventType.BuyOfferPlacedEvent,
                            EventType.BuyOfferRemovedEvent,
                            EventType.BuyOfferExecutedEvent,
                        ],
                    },
                    address: { equalTo: this.address },
                },
                first: 4,
            },
            {
                pollInterval: 10 * 60 * 1000,
            }
        );
    }
}
