import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { RecordMetadata, StandardRecordMetadataKey } from '@tezos-domains/core';
import { Subject } from 'rxjs';
import { ReverseRecord } from '../governance-core/delegates-leaderboard-data-source';
import { dataArrayToObj } from '../utils/convert';
import { TzProfile } from './../tzprofiles/tzprofiles.service';

@UntilDestroy()
@Component({
    selector: 'td-delegate-item',
    templateUrl: './delegate-item.component.html',
    styleUrls: ['./delegate-item.component.scss'],
})
export class DelegateItemComponent implements OnInit, OnChanges {
    @Input() address: string;
    @Input() reverseRecord?: ReverseRecord;
    @Input() tzProfile?: TzProfile;
    @Input() votingPower?: number | null;
    @Input() delegatorCount?: number | null;
    @Input() isPublic = false;
    @Input() selected = false;
    @Input() height: 'normal' | 'lg' = 'normal';

    private changes = new Subject<void>();

    gravatarHash: string | null;
    gravatarEmail: string | null;
    governanceUrl: string | null;
    name: string | null;

    ngOnInit(): void {
        this.applyGQLReverseRecord(this.reverseRecord);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.address || changes.resolveReverseRecord || changes.reverseRecord || changes.stats) {
            this.changes.next();
        }
    }

    private applyGQLReverseRecord(reverseRecord?: ReverseRecord) {
        if (!reverseRecord || !reverseRecord.domain) {
            return;
        }

        this.name = reverseRecord.domain.name || null;
        this.extractAvatar(new RecordMetadata(dataArrayToObj(reverseRecord.domain.data)));
    }

    private extractAvatar(data: RecordMetadata) {
        this.gravatarHash = data.getJson(StandardRecordMetadataKey.GRAVATAR_HASH);
        this.gravatarEmail = data.getJson(StandardRecordMetadataKey.OPENID_EMAIL);
        this.governanceUrl = data.getJson(StandardRecordMetadataKey.GOVERNANCE_PROFILE_URL);
    }
}
