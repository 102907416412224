import { Component, Input, OnInit } from '@angular/core';

import { EventsRecord } from '../graphql/events-table-data-source';
import BigNumber from 'bignumber.js';

@Component({
    selector: 'td-home-purchase-feed-item',
    templateUrl: './purchase-feed-item.component.html',
    styleUrls: ['./purchase-feed-item.component.scss'],
})
export class PurchaseFeedItemComponent implements OnInit {
    amount: BigNumber;

    @Input() event: EventsRecord;

    ngOnInit(): void {
        if ('amount' in this.event) {
            this.amount = this.event.amount;
        }
    }
}
