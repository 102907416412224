import { combineLatest, defer, Observable, Subject, timer } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

function prepare<T>(callback: () => void): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>): Observable<T> =>
        defer(() => {
            callback();
            return source;
        });
}

export function indicateLoading<T>(indicator: Subject<boolean>, minExecutionTime = 250): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>): Observable<T> =>
        combineLatest([source, timer(minExecutionTime)]).pipe(
            prepare(() => indicator.next(true)),
            map(([result, _]) => result),
            finalize(() => indicator.next(false))
        );
}
