import { SelectionModel } from '@angular/cdk/collections';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { getTld } from '@tezos-domains/core';
import { DomainAcquisitionInfo, DomainAcquisitionState } from '@tezos-domains/manager';
import { TLDConfiguration } from '@tezos-domains/manager/dist/src/manager/model';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Configuration } from '../../configuration';
import { BulkDomainsDataService } from '../../file-processing/file-data.service';
import { DataSourceFactory } from '../../graphql/data-source-factory';
import { RecordValidity } from '../../graphql/graphql.generated';
import { TezosDomainsClientService } from '../../tezos/integration/tezos-domains-client.service';
import { TldConfigurationService } from '../../tezos/integration/tld-configuration.service';
import { getExistingAuction } from '../utils/aquisition-info';
import { RegistrationDomainsStateService } from './registration-domains.state.service';

interface ExatchMatchData {
    name: string;
    price: BigNumber;
    seller: string;
    expiration?: dayjs.Dayjs;
    isTaken?: boolean;
    buttonDisabled?: boolean;
    buttonText?: string;
    acquisitionInfo: DomainAcquisitionInfo;
}

@UntilDestroy()
@Component({
    selector: 'td-bulk-filter',
    templateUrl: './bulk-filter.component.html',
    styleUrls: ['./bulk-filter.component.scss'],
})
export class BulkFilterComponent implements OnInit {
    private fileData = inject(BulkDomainsDataService);
    private tldConfigService = inject(TldConfigurationService);
    private tezosDomainsClientService = inject(TezosDomainsClientService);
    private stateService = inject(RegistrationDomainsStateService);
    private router = inject(Router);
    private activatedRoute = inject(ActivatedRoute);

    configuration = inject(Configuration);

    private tld = '';
    private requestedDomains: string[] = [];

    names: string[] = [];
    acquisitionInfos: DomainAcquisitionInfo[] = [];
    showMaxDomainsWarning = false;

    domainList = inject(DataSourceFactory).createDomainsWithAuctionDataSource();
    data$: Observable<ExatchMatchData[]>;
    selection = new SelectionModel<ExatchMatchData>(true, []);

    ngOnInit(): void {
        this.fileData.domainNames.pipe(filter(d => !!d.length)).subscribe(names => {
            this.tld = getTld(names[0]);
            this.showMaxDomainsWarning = false;

            if (names.length > this.configuration.maxBulkCreate) {
                names = names.slice(0, this.configuration.maxBulkCreate);
                this.showMaxDomainsWarning = true;
            }

            this.requestedDomains = names;

            this.domainList.load({
                where: {
                    name: { in: names },
                    validity: RecordValidity.All,
                },
                first: this.configuration.maxBulkCreate,
            });
        });

        this.data$ = this.domainList.data$.pipe(
            switchMap(existingDomains => this.tldConfigService.get(this.tld).pipe(map(tldConfig => ({ existingDomains, tldConfig })))),
            map(({ existingDomains, tldConfig }) => {
                return this.requestedDomains.map(name => {
                    const existingDomain = existingDomains.find(d => d.name === name);

                    const acquisitionInfo = this.tezosDomainsClientService.calculateAcquisitionInfo({
                        name,
                        tldConfiguration: tldConfig,
                        existingAuction: existingDomain ? getExistingAuction(existingDomain.lastAuction) : undefined,
                        existingDomain: existingDomain ? { expiry: existingDomain.expires?.toDate() || null } : undefined,
                    });

                    const domains = this.buildDomainData(name, acquisitionInfo, tldConfig);
                    return domains;
                });
            }),
            tap(domains => {
                const alreadySelected = this.stateService.domains?.names;
                const selected = domains.filter(d => {
                    if (alreadySelected) {
                        return alreadySelected.some(sel => sel === d.name);
                    }

                    return !d.buttonDisabled;
                });

                this.selection.setSelection(...selected);
            })
        );
    }

    ctaDomainsChange(data: string) {
        this.fileData.setDomains(data);
    }

    toggleDomainSelection(domain: ExatchMatchData) {
        this.selection.toggle(domain);
    }

    continueRegistration() {
        this.names = this.selection.selected.map(s => s.name);
        this.acquisitionInfos = this.selection.selected.map(s => s.acquisitionInfo);

        this.stateService.setDomains({ names: this.names, acquisitionInfos: this.acquisitionInfos });
        this.router.navigate(['register'], { relativeTo: this.activatedRoute });
    }

    private buildDomainData(name: string, acquisitionInfo: DomainAcquisitionInfo, tldConfig: TLDConfiguration): ExatchMatchData {
        const data: Partial<ExatchMatchData> = {};

        data.seller = 'TD';
        data.name = name;
        data.acquisitionInfo = acquisitionInfo;

        switch (acquisitionInfo?.acquisitionState) {
            case DomainAcquisitionState.CanBeClaimed:
                /**
                 * t(actions.claimed)
                 * t(actions.claim)
                 */
                data.price = tldConfig.claimPrice;
                data.buttonDisabled = true;
                break;
            case DomainAcquisitionState.CanBeBought:
                const pricePerYear = new BigNumber(acquisitionInfo.calculatePrice(365));
                data.price = pricePerYear;
                break;
            case DomainAcquisitionState.Taken:
            case DomainAcquisitionState.CanBeSettled:
                data.buttonText = 'general.taken';
                data.buttonDisabled = true;
                data.isTaken = true;
                break;
            case DomainAcquisitionState.CanBeAuctioned:
            case DomainAcquisitionState.AuctionInProgress:
                data.buttonText = 'actions.auction';
                data.expiration = dayjs(acquisitionInfo.auctionDetails.auctionEnd);
                data.buttonDisabled = true;
                break;
            case DomainAcquisitionState.Unobtainable:
                data.buttonText = 'general.not-available';
                data.buttonDisabled = true;
                break;
        }

        return data as ExatchMatchData;
    }
}
