import BigNumber from 'bignumber.js';
import { Dayjs } from 'dayjs';

export interface ClaimMeta {
    historicalAmount: BigNumber;
    futureRegistrationAmount: BigNumber;
    memberSince?: Dayjs;
    domain?: string;
    reverseRecord: BigNumber;
    discordOgTokens: BigNumber;
    translators: BigNumber;
    integrators: BigNumber;
    externalContributors: BigNumber;
    keyHolders: BigNumber;
    blacklistedInFavorOfAddress?: string;
    blacklistReason?: string;
    contractTokens: BigNumber;
    contractAddress?: string;
}

export const EmptyClaimMeta: ClaimMeta = {
    historicalAmount: BigNumber(0),
    futureRegistrationAmount: BigNumber(0),
    discordOgTokens: BigNumber(0),
    translators: BigNumber(0),
    integrators: BigNumber(0),
    externalContributors: BigNumber(0),
    keyHolders: BigNumber(0),
    contractTokens: BigNumber(0),
    reverseRecord: BigNumber(0),
};

export interface ClaimFileData {
    meta?: ClaimMeta;
    owner: string;
    claims: AirdropClaim[];
}

export interface AirdropClaim {
    owner: string;
    amount: BigNumber;
    from: Dayjs;
    proof: { [key: string]: string }[];
}

export interface AirdropClaimFile {
    v?: { fr: string; ad: boolean; t: number; proof: { [key: string]: string }[] }[];
    t: number;
    m: { [key: string]: any };
}

export interface AirdropClaimKey {
    owner: string;
    from: string;
    airdrop: boolean;
}

export interface AirdropClaimState {
    meta: ClaimMeta;
    owner: string;
    status: ClaimStatus;
    claimableClaims: AirdropClaim[];
    futureClaims: AirdropClaim[];
    totalClaims: number;
    amountClaimed: BigNumber;
    amountLocked: BigNumber;
    amountClaimable: BigNumber;
    totalAmount: BigNumber;
    hasOwnershipClaimsBlocked: boolean;
}

export enum ClaimStatus {
    AirdropEnded = 'AirdropEnded',
    NoClaim = 'NoClaim',
    Claimed = 'Claimed',
    Unclaimed = 'Unclaimed',
    ClaimedPartially = 'ClaimedPartially',
    Blocked = 'Blocked',
}
